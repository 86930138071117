import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboardButton } from "../../../common/components/copy-to-clipboard-button.component";
import { ChevronDownIcon } from "../../../common/constants/tabler-icon.constants";
import { StakeholderLinksUtilities } from "../../../stakeholder/stakeholder-links.utilities";
import { ICollectedStakeholderFeedbackEntry } from "../../common/dma-category-feedback.utilities";
import { DMACategoryFeedbackIndicator } from "./dma-category-feedback-indicator.component";
import { DMACategoryFeedbackView } from "./dma-category-feedback-view.component";
import { DMACategoryUtilities } from "./dma-category.utilities";
import { HOVER_BACKGROUND_COLOR } from "../../../../theme/theme";

interface IDMACategoryFeedbackListProps {
  feedbackEntries: ICollectedStakeholderFeedbackEntry[];
  recordingPeriodId: string;
}

export const DMACategoryFeedbackList: FC<IDMACategoryFeedbackListProps> = ({
  feedbackEntries,
  recordingPeriodId,
}) => {
  const { t } = useTranslation("dma_stakeholder_feedback_list");

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell />
          <TableCell>{t("header_stakeholder", { ns: "stakeholder_feedback_common" })}</TableCell>
          <TableCell>{t("header_organization", { ns: "stakeholder_feedback_common" })}</TableCell>
          <TableCell align="center">
            {t("header_material", { ns: "stakeholder_feedback_common" })}
          </TableCell>
          <TableCell align="center">
            {t("header_financial", { ns: "stakeholder_feedback_common" })}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {feedbackEntries.map((feedbackEntry) => (
          <DMACategoryFeedbackListItem
            key={feedbackEntry.stakeholderId}
            feedbackEntry={feedbackEntry}
            recordingPeriodId={recordingPeriodId}
          />
        ))}
      </TableBody>
    </Table>
  );
};

// Feedback Entry

interface IDMACategoryFeedbackListItemProps {
  feedbackEntry: ICollectedStakeholderFeedbackEntry;
  recordingPeriodId: string;
}

const DMACategoryFeedbackListItem: FC<IDMACategoryFeedbackListItemProps> = ({
  feedbackEntry,
  recordingPeriodId,
}) => {
  const { t } = useTranslation("dma_stakeholder_feedback_list");

  const [expanded, setExpanded] = useState(false);

  const materialFeedbackState = DMACategoryUtilities.getFeedbackState(
    feedbackEntry.materialFeedback,
  );
  const financialFeedbackState = DMACategoryUtilities.getFeedbackState(
    feedbackEntry.financialFeedback,
  );

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: HOVER_BACKGROUND_COLOR,
          },
        }}
        onClick={() => setExpanded((curr) => !curr)}
      >
        <TableCell>
          <IconButton
            // this prevents the circle from showing up
            // icon is still wrapped in button as this applies some preferable styling
            disableRipple={true}
            size="small"
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 300ms",
            }}
          >
            <ChevronDownIcon />
          </IconButton>
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            {feedbackEntry.stakeholder?.name ?? "?"}
            <CopyToClipboardButton
              value={StakeholderLinksUtilities.getStakeholderFeedbackLink(
                feedbackEntry.stakeholderId,
                recordingPeriodId,
              )}
              tooltip={t("tooltip_copy_link", { ns: "stakeholder_feedback_common" })}
              tooltipSuccess={t("tooltip_copy_link_success", {
                ns: "buttons",
              })}
              tooltipPlacement="right"
            />
          </Box>
        </TableCell>
        <TableCell>{feedbackEntry.stakeholder?.organization ?? "-"}</TableCell>
        <TableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <DMACategoryFeedbackIndicator feedbackState={materialFeedbackState} />
          </Box>
        </TableCell>
        <TableCell align="center">
          <Box display="flex" alignItems="center" justifyContent="center">
            <DMACategoryFeedbackIndicator feedbackState={financialFeedbackState} />
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={expanded}>
            <Box display="flex" flexDirection="row" gap={2} py={2} mx={12}>
              {feedbackEntry.materialFeedback && (
                <Box flexGrow={1}>
                  <Typography fontWeight="bold" mb={1}>
                    {t("title_material", { ns: "stakeholder_feedback_common" })}:
                  </Typography>
                  <DMACategoryFeedbackView feedback={feedbackEntry.materialFeedback} />
                </Box>
              )}
              {feedbackEntry.financialFeedback && (
                <Box flexGrow={1}>
                  <Typography fontWeight="bold" mb={1}>
                    {t("title_financial", { ns: "stakeholder_feedback_common" })}:
                  </Typography>
                  <DMACategoryFeedbackView feedback={feedbackEntry.financialFeedback} />
                </Box>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
