export class DataTransformationUtilities {
  public static convertListToLookupMap<K, T>(data: T[], identifierKey: keyof T) {
    const lookup = new Map<K, T>();

    data.forEach((item) => {
      lookup.set(item[identifierKey] as K, item);
    });

    return lookup;
  }

  public static convertListToLookupMapCb<K, T>(data: T[], getIdentifier: (item: T) => K) {
    const lookup = new Map<K, T>();

    data.forEach((item) => {
      lookup.set(getIdentifier(item), item);
    });

    return lookup;
  }
}
