import { FC, useEffect, useMemo, useState } from "react";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";
import { FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import { MaskedInput } from "../../common/components/masked-input.component";
import { IUpdateDistributionCriteriaValueData } from "../applied-distribution-criteria.mutations";
import * as React from "react";
import { useTranslation } from "react-i18next";

export interface IAppliedDistributionCriteriaTableValueCellComponentProps {
  dataEntryObject: IDataEntryObject;
  currentCriteria: IDistributionCriterionWithApplicationStatus;
  disabled?: boolean;
  onUpdateValue: (
    values: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId" | "payload">,
  ) => void;
  onDeleteValue: (values: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId">) => void;
}

const getDefaultValue = (value: number | null): string => value?.toString() ?? "";

export const AppliedDistributionCriteriaTableValueCellComponent: FC<
  IAppliedDistributionCriteriaTableValueCellComponentProps
> = ({ dataEntryObject, currentCriteria, disabled, onUpdateValue, onDeleteValue }) => {
  const { t } = useTranslation("applied_distribution_criteria_table_cell_component");

  const existingValue = useMemo(() => {
    return currentCriteria.application[dataEntryObject.id] ?? null;
  }, [currentCriteria.application, dataEntryObject.id]);

  const [inputValue, setInputValue] = useState<string>(getDefaultValue(existingValue));

  const [validationError, setValidationError] = useState<string | null>(null);

  // keep up with changes from parent
  useEffect(() => {
    setInputValue(getDefaultValue(existingValue));
  }, [existingValue, setInputValue]);

  return (
    <FormControl variant="outlined" size="medium" error={validationError !== null} fullWidth>
      <OutlinedInput
        size="medium"
        required={currentCriteria.applied}
        value={inputValue}
        disabled={disabled}
        error={validationError !== null}
        inputProps={{
          mask: Number,
          scale: 2,
          thousandsSeparator: ".",
          padFractionalZeros: false,
          radix: ",",
          unmask: true,
          eager: true,
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent={MaskedInput as any}
        onChange={(event) => {
          // clear previous errors
          setValidationError(null);
          setInputValue(event as unknown as string);
        }}
        onBlur={() => {
          // value has changed! fire on blur
          if (inputValue !== getDefaultValue(existingValue)) {
            // distinguish between empty and non-empty values; also consider "applied" of current criteria
            if (inputValue === "") {
              // no value was provided + criteria is applied --> value is now required, display error message
              if (currentCriteria.applied) {
                setValidationError(t("error_required"));
              } else {
                onDeleteValue({ dataEntryObjectId: dataEntryObject.id });
              }
            } else {
              // update is always allowed
              onUpdateValue({
                dataEntryObjectId: dataEntryObject.id,
                payload: {
                  value: parseFloat(inputValue),
                },
              });
            }
          }
        }}
      />
      {validationError !== null && <FormHelperText>{validationError}</FormHelperText>}
    </FormControl>
  );
};
