import { IMDRInput } from "./mdr-input.types";
import {
  NullPropertiesToOptionalRecursive,
  OptionalPropertiesToNullRecursive,
} from "@netcero/netcero-entities";

type ValueType = string | boolean | string[];

export class MdrUtilities {
  /**
   * Get the default values for a given configuration.
   */
  public static getDefaultValuesForConfiguration<T extends object>(
    inputsMetaData: Record<keyof T, IMDRInput>,
    entity?: T,
  ) {
    const result: Partial<Record<keyof T, ValueType>> = {};
    const keys = Object.keys(inputsMetaData) as (keyof T)[];

    keys.forEach((key) => {
      const inputMetaData = inputsMetaData[key];
      result[key] = (entity?.[key] as never) ?? inputMetaData.initialValue;
    });

    return result as OptionalPropertiesToNullRecursive<T>;
  }

  /**
   * Convert the data to an API payload. This will remove any optional fields that are not set.
   * Empty string (for non-required fields) will be converted to undefined.
   * @param data The data to convert
   * @param inputsMetaData The metadata for the inputs
   */
  public static convertToApiPayload<T>(data: T, inputsMetaData: Record<keyof T, IMDRInput>) {
    const result: Partial<Record<keyof T, ValueType>> = {};
    const keys = Object.keys(inputsMetaData) as (keyof T)[];

    keys.forEach((key) => {
      const inputMetaData = inputsMetaData[key];
      if (inputMetaData.type === "text" && !inputMetaData.required) {
        result[key] = (data[key] as string).trim() || undefined;
        return;
      }

      // Replace null with undefined
      result[key] = (data[key] ?? undefined) as ValueType;
    });

    return result as NullPropertiesToOptionalRecursive<T>;
  }
}
