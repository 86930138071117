import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getBaseStakeholderRecordingPeriodsQueryKey = () => ["external-stakeholder"];

export const getStakeholderRecordingPeriodsQueryKey = (stakeholderId: string) => [
  ...getBaseStakeholderRecordingPeriodsQueryKey(),
  stakeholderId,
  "recording-period",
];

export const getStakeholderFeedbackQueryKey = (
  stakeholderId: string,
  recordingPeriodId: string,
) => [...getStakeholderRecordingPeriodsQueryKey(stakeholderId), recordingPeriodId, "feedback"];

/**
 * Fetches the feedback for a given stakeholder
 * @param stakeholderId Stakeholder for which to fetch the feedback details
 * @param recordingPeriodId Recording period to fetch feedback for
 * @param enabled Whether the query should be enabled (run) or not. Defaults to true
 * @returns Feedback details for the given stakeholder
 */
export const useStakeholderFeedbackQuery = (
  stakeholderId: string,
  recordingPeriodId: string,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: getStakeholderFeedbackQueryKey(stakeholderId, recordingPeriodId),
    queryFn: async () => {
      return CoreApiService.StakeholdersApi.getStakeholderFeedbackDetails(
        stakeholderId,
        recordingPeriodId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled,
  });
};

export const useRecordingPeriodsForStakeholderQuery = (stakeholderId: string) => {
  return useQuery({
    queryKey: getStakeholderRecordingPeriodsQueryKey(stakeholderId),
    queryFn: async () => {
      return CoreApiService.StakeholdersApi.getStakeholderRecordingPeriods(stakeholderId)
        .then((req) => req())
        .then((res) => res.data);
    },
  });
};
