import { FC, useRef, useState, ReactNode } from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButtonProps,
  Tooltip,
} from "@mui/material";
import { DotsIcon } from "../constants/tabler-icon.constants";

interface IDotMenuItem {
  // The label of the menu item.
  label: string;
  // The icon to be displayed alongside the menu item label.
  icon: ReactNode;
  // Optional click handler for the menu item.
  onClick?: () => void;
  // Optional boolean to disable the menu item.
  disabled?: boolean;
  // Optional boolean to add a divider after the menu item.
  dividerAfter?: boolean;
  // Optional string to display as a tooltip when the menu item is disabled.
  disabledTooltip?: string;
}

interface IDotMenuProps {
  // Optional boolean to disable the menu button.
  disabled?: boolean;
  // Optional props to be passed to the menu button (not the menu itself)
  menuButtonProps?: IconButtonProps;
  // Array of menu items to be displayed in the menu.
  menuItems: IDotMenuItem[];
}

export const DotMenu: FC<IDotMenuProps> = ({ disabled = false, menuItems, menuButtonProps }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  return (
    <>
      <IconButton
        ref={buttonRef}
        onClick={() => setShowMenu(true)}
        disabled={disabled}
        sx={{ backgroundColor: (theme) => (showMenu ? theme.palette.action.hover : undefined) }}
        {...menuButtonProps}
      >
        <DotsIcon />
      </IconButton>

      <Menu
        open={showMenu}
        anchorEl={buttonRef.current}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {menuItems.map((item, index) => [
          item.disabled && item.disabledTooltip ? (
            <Tooltip title={item.disabledTooltip} key={index}>
              <span>
                <MenuItem
                  onClick={() => {
                    if (item.onClick) {
                      item.onClick();
                    }
                    handleMenuClose();
                  }}
                  disabled={item.disabled || !item.onClick}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem
              key={index}
              onClick={() => {
                if (item.onClick) {
                  item.onClick();
                }
                handleMenuClose();
              }}
              disabled={item.disabled || !item.onClick}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ),
          item.dividerAfter ? <Divider key={`divider-${index}`} /> : null,
        ])}
      </Menu>
    </>
  );
};
