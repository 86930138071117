import { FC, useMemo } from "react";
import {
  isInMaxDateDiffForAgo,
  useFormatHumanReadableDateOrDiff,
} from "../hooks/format-human-readable-date-or-diff.hook";
import { Tooltip } from "@mui/material";
import { useFormatHumanReadableDate } from "../hooks/format-human-readable-date.hook";

interface IHumanReadableDateProps {
  date: Date;
}

export const HumanReadableDateOrDiff: FC<IHumanReadableDateProps> = ({ date }) => {
  const formatHumanReadableDateOrDiff = useFormatHumanReadableDateOrDiff();
  const formatHumanReadableDate = useFormatHumanReadableDate();
  const dateElement = useMemo(
    () => <time dateTime={date.toISOString()}>{formatHumanReadableDateOrDiff(date)}</time>,
    [formatHumanReadableDateOrDiff, date],
  );

  return isInMaxDateDiffForAgo(date) ? (
    // Add tooltip to show the full date when hovering over the date
    <Tooltip title={formatHumanReadableDate(date)}>{dateElement}</Tooltip>
  ) : (
    dateElement
  );
};
