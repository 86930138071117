import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseChart,
  EChartClickHandler,
  EChartsOption,
} from "../../common/charts/base-chart.component";
import { IGroupedESRSTopics } from "../dma-dashboard.utilities";
import { DMAMatrixSeriesElement, DMAMatrixUtilities } from "./dma-matrix.utilities";
import { Box, Typography } from "@mui/material";
import { useRenderESRSTopicName } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";

interface IDMAMatrixChartAllTopicsProps {
  groupedESRSTopics: IGroupedESRSTopics;
  height?: string;
  onSelectTopic: (topic: string) => void;
}

export const DMAMatrixChartAllTopics: FC<IDMAMatrixChartAllTopicsProps> = ({
  groupedESRSTopics,
  height,
  onSelectTopic,
}) => {
  const { t } = useTranslation("dma_dashboard");
  const renderTopicName = useRenderESRSTopicName();

  const chartData: EChartsOption | null = useMemo(() => {
    const environmentalData = DMAMatrixUtilities.filterSeriesToDisplayableSeries(
      groupedESRSTopics.environmental.map((t) =>
        DMAMatrixUtilities.convertESRSTopicToSeriesData(t, renderTopicName),
      ),
    );
    const socialData = DMAMatrixUtilities.filterSeriesToDisplayableSeries(
      groupedESRSTopics.social.map((t) =>
        DMAMatrixUtilities.convertESRSTopicToSeriesData(t, renderTopicName),
      ),
    );
    const governanceData = DMAMatrixUtilities.filterSeriesToDisplayableSeries(
      groupedESRSTopics.governance.map((t) =>
        DMAMatrixUtilities.convertESRSTopicToSeriesData(t, renderTopicName),
      ),
    );
    const otherData = DMAMatrixUtilities.filterSeriesToDisplayableSeries(
      groupedESRSTopics.other.map((t) =>
        DMAMatrixUtilities.convertESRSTopicToSeriesData(t, renderTopicName),
      ),
    );

    const allValuesArray = [...environmentalData, ...socialData, ...governanceData, ...otherData];

    if (allValuesArray.length === 0) {
      return null;
    }

    const maxMaterialityDegreeSum = Math.max(...allValuesArray.map((data) => data[2]));

    return {
      // title: {
      //   text: t("title_overview"),
      // },
      legend: {
        data: [
          t("label_topic_environmental"),
          t("label_topic_social"),
          t("label_topic_governance"),
          t("label_topic_other"),
        ],
      },
      xAxis: {
        ...DMAMatrixUtilities.DEFAULT_CHART_PROPS.AXIS_PROPS,
        name: t("label_axis_financial_materiality"),
      },
      yAxis: {
        ...DMAMatrixUtilities.DEFAULT_CHART_PROPS.AXIS_PROPS,
        name: t("label_axis_material_materiality"),
      },
      series: [
        DMAMatrixUtilities.getScatterChartSeriesData(
          t("label_topic_environmental"),
          environmentalData,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.COLORS.ESRS_TOPICS.ENVIRONMENTAL,
        ),
        DMAMatrixUtilities.getScatterChartSeriesData(
          t("label_topic_social"),
          socialData,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.COLORS.ESRS_TOPICS.SOCIAL,
        ),
        DMAMatrixUtilities.getScatterChartSeriesData(
          t("label_topic_governance"),
          governanceData,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.COLORS.ESRS_TOPICS.GOVERNANCE,
        ),
        DMAMatrixUtilities.getScatterChartSeriesData(
          t("label_topic_other"),
          otherData,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.COLORS.ESRS_TOPICS.OTHER,
        ),
      ],
    } as EChartsOption;
  }, [groupedESRSTopics, t, renderTopicName]);

  if (chartData === null) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Typography textAlign="center">{t("no_categories_to_display_notice")}</Typography>
      </Box>
    );
  }

  const handleChartClick: EChartClickHandler = (params) => {
    const data = params.data as DMAMatrixSeriesElement;
    onSelectTopic(data[5]);
  };

  return (
    <Box flex={1}>
      <Typography variant="h5" component="h3">
        {t("title_overview")}
      </Typography>
      <BaseChart options={chartData} height={height} onClick={handleChartClick} />
    </Box>
  );
};
