import { Box, DialogContentText, TextField } from "@mui/material";
import { IDMACategory, IOptOutOfDmaCategoryRequest } from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";
import { ConfirmDialog } from "../../../common/dialogs/variants/confirm.dialog";

interface IDMACategoryOptOutDialogProps {
  open: boolean;
  category: IDMACategory | null;
  loading?: boolean;
  disabled?: boolean;
  error?: Error | null;
  onClose: (data: IOptOutOfDmaCategoryRequest | null) => void;
}

export const DMACategoryOptOutDialog: FC<IDMACategoryOptOutDialogProps> = ({
  open,
  category,
  loading,
  disabled,
  error,
  onClose,
}) => {
  const { t } = useTranslation("dma_category_opt_out_dialog");

  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<IOptOutOfDmaCategoryRequest>({
    defaultValues: {
      optOut: true,
      optOutReason: "",
    },
  });

  useEffect(() => {
    if (open) {
      reset({
        optOut: true,
        optOutReason: category?.optOutReason || "",
      });
    }
  }, [open, category, reset]);

  const isInEditMode = !!category?.optOut;
  const renderName = useRenderDMACategoryName();

  return (
    <ConfirmDialog
      open={open}
      title={t(isInEditMode ? "title_edit" : "title_opt_out")}
      confirmOverride={t(isInEditMode ? "button_edit" : "button_opt_out")}
      loading={loading}
      disabled={disabled}
      error={error}
      onConfirm={handleSubmit(onClose)}
      onCancel={() => onClose(null)}
      actionType="confirm"
      dialogProps={{ maxWidth: "lg", fullWidth: true }}
      hasChanges={isDirty}
      content={
        <Box display="flex" flexDirection="column" gap={2} pt={1}>
          {/* Only show Text if not yet opt out (not editing) */}
          {!isInEditMode && (
            <DialogContentText>
              {t(`content_text`, { name: renderName(category) })}
            </DialogContentText>
          )}

          {/* Reason Input */}
          <Controller
            control={control}
            name="optOutReason"
            rules={{
              required: t("error_opt_out_reason_required"),
            }}
            render={({ field, fieldState: { error: fieldError } }) => (
              <TextField
                label={t("label_opt_out_reason")}
                {...field}
                multiline
                minRows={3}
                error={!!fieldError}
                helperText={fieldError?.message}
                disabled={disabled}
              />
            )}
          />
        </Box>
      }
    />
  );
};
