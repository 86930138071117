import { Box, Checkbox, SvgIcon, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { IDataEntryObjectRecordingInputParameter } from "../data-entry-object-input-parameters.utilities";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface IDataEntryObjectInputParameterConfigurationComponentProps {
  inputParameterConfiguration: IDataEntryObjectRecordingInputParameter;
  onChange: (newInputParameterConfiguration: IDataEntryObjectRecordingInputParameter) => void;
  disabled: boolean;
}

export const DataEntryObjectInputParameterConfigurationComponent: FC<
  IDataEntryObjectInputParameterConfigurationComponentProps
> = ({ inputParameterConfiguration, onChange, disabled }) => {
  const { inputParameter, isActive, contributingUserIds } = inputParameterConfiguration;
  const translateContent = useTranslateContent();

  const handleUpdate = (active: boolean) => {
    onChange({
      inputParameter,
      isActive: active,
      contributingUserIds,
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Checkbox
        checked={isActive}
        onChange={(_, active) => handleUpdate(active)}
        disabled={disabled}
        size="medium"
      />
      <Typography variant="subtitle1">{translateContent(inputParameter.name)}</Typography>
      {inputParameter.description && (
        <Tooltip title={translateContent(inputParameter.description)} placement="right">
          <SvgIcon color="action" fontSize="medium">
            <InfoIcon />
          </SvgIcon>
        </Tooltip>
      )}
      <Box sx={{ flex: 1 }} />
    </Box>
  );
};
