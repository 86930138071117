import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { IMDRInput } from "../../mdr-input.types";
import { ReactElement } from "react";
import { UseFormStateReturn } from "react-hook-form/dist/types";
import {
  ControllerFieldState,
  ControllerRenderProps,
  UseControllerProps,
} from "react-hook-form/dist/types/controller";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { TextInput } from "./mdr-text-input.component";
import { BooleanInput } from "./mdr-boolean-input.component";
import { EnumInput } from "./mdr-enum-input.component";
import { NumberInput } from "./mdr-number-input.component";
import { NumberWithUnitInput } from "./mdr-number-with-unit-input.component";
import { CurrencyInput } from "./mdr-currency-input.component";
import { YearInput } from "./mdr-year-input.component";

export const NON_FULL_WIDTH_WIDTH = 700;

export interface IMDRControlInputProps<T extends FieldValues> extends IMDRInputProps {
  propertyPath: Path<T>;
  control: Control<T>;
  rules?: UseControllerProps<T>["rules"];
  getRuleTranslation: (property: Path<T>, rule: "required") => string;
}

export interface IMDRInputProps {
  label: string;
  isDependedUponByAnother: boolean;
  isConditional: boolean;
  disabled: boolean;
}

export interface IControlRenderProps<T extends FieldValues> {
  field: ControllerRenderProps<T, Path<T>>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}

export type IMDRControlledInputProps<
  DataType extends FieldValues,
  InputMetaDataType,
> = IMDRInputProps &
  IControlRenderProps<DataType> & {
    valueMetaData: InputMetaDataType;
  };

export const MdrInputComponentFactory = {
  createComponent<T extends FieldValues>(
    valueMetaData: IMDRInput,
    {
      control,
      propertyPath,
      getRuleTranslation,
      rules: rulesOverrides,
      ...props
    }: IMDRControlInputProps<T>,
    translateEnumValue: (value: string) => string,
  ) {
    let rules: Omit<
      RegisterOptions<T, Path<T>>,
      "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    > = {};

    // Required Rule
    if (valueMetaData.required) {
      if (valueMetaData.type === "boolean" || valueMetaData.type === "text") {
        rules.required = getRuleTranslation(propertyPath, "required");
      } else if (valueMetaData.type === "enum") {
        rules.validate = (value: string[]) => {
          return value.length > 0 || getRuleTranslation(propertyPath, "required");
        };
      }
    }

    if (rulesOverrides) {
      rules = { ...rules, ...rulesOverrides };
    }

    return (
      <Controller
        control={control}
        name={propertyPath}
        rules={rules}
        render={MdrInputComponentFactory.createInputComponentForControl(
          valueMetaData,
          props,
          translateEnumValue,
        )}
      />
    );
  },
  createInputComponentForControl<T extends FieldValues>(
    valueMetaData: IMDRInput,
    props: IMDRInputProps,
    translateEnumValue: (value: string) => string,
  ) {
    return (fieldData: IControlRenderProps<T>): ReactElement => {
      switch (valueMetaData.type) {
        case "text":
          return TextInput<T>({ valueMetaData, ...props, ...fieldData });
        case "boolean":
          return BooleanInput<T>({ valueMetaData, ...props, ...fieldData });
        case "enum":
          return EnumInput<T>({ valueMetaData, ...props, ...fieldData, translateEnumValue });
        case "number":
          return NumberInput<T>({ valueMetaData, ...props, ...fieldData });
        case "number-with-unit":
          return NumberWithUnitInput<T>({ valueMetaData, ...props, ...fieldData });
        case "currency":
          return CurrencyInput<T>({ valueMetaData, ...props, ...fieldData });
        case "year":
          return YearInput<T>({ valueMetaData, ...props, ...fieldData });
      }
    };
  },
};
