import { Dispatch, FC } from "react";
import { IPolicy } from "@netcero/netcero-core-api-client";
import { PoliciesSelectDialog } from "./policies-select.dialog";
import { useDialogStateWithoutData } from "../../../common/dialogs/dialog-state.hook";
import { PoliciesMultipleSelectChip } from "./policies-multiple-select-chip.component";

interface IPoliciesSelectInputProps {
  label: string;
  policies: IPolicy[];
  policiesIds: string[];
  onChange: Dispatch<string[]>;
}

export const PoliciesInputSelect: FC<IPoliciesSelectInputProps> = ({
  label,
  policies,
  policiesIds,
  onChange,
}) => {
  const {
    isOpen: showSelectDialog,
    openDialog: openSelectDialog,
    closeDialog: closeSelectDialog,
  } = useDialogStateWithoutData();

  return (
    <>
      {/* Dialog */}
      <PoliciesSelectDialog
        open={showSelectDialog}
        title={label}
        onClose={closeSelectDialog}
        policies={policies}
        selectedPoliciesIds={policiesIds}
        onChange={onChange}
      />

      {/* Chip Select */}
      <PoliciesMultipleSelectChip
        label={label}
        policies={policies}
        selectedPolicyIds={policiesIds}
        onSelectClick={openSelectDialog}
      />
    </>
  );
};
