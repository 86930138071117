import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IPolicyDataS,
} from "@netcero/netcero-core-api-client";
import { WithoutNestedObjects } from "@netcero/netcero-common";

export type PolicySDataOnly = WithoutNestedObjects<IPolicyDataS>;

export type IPolicyEditDialogSectionKey =
  | "e1"
  | "e2"
  | "e3"
  | "e4"
  | "e5"
  | "s1"
  | "s2"
  | "s3"
  | "s4"
  | "g1";

export class PoliciesAppUtilities {
  public static getIdentifierKeyForTopic(
    topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ): IPolicyEditDialogSectionKey {
    switch (topicIdentifier) {
      case "esrs_e1":
        return "e1";
      case "esrs_e2":
        return "e2";
      case "esrs_e3":
        return "e3";
      case "esrs_e4":
        return "e4";
      case "esrs_e5":
        return "e5";
      case "esrs_s1":
        return "s1";
      case "esrs_s2":
        return "s2";
      case "esrs_s3":
        return "s3";
      case "esrs_s4":
        return "s4";
      case "esrs_g1":
        return "g1";
    }
  }

  public static isSTopic(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "s1" | "s2" | "s3" | "s4" {
    return identifier.startsWith("s");
  }

  public static isETopic(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "e1" | "e2" | "e3" | "e4" | "e5" {
    return identifier.startsWith("e");
  }

  public static hasSpecificInputs(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "e1" | "e2" | "e3" | "e4" | "e5" | "s1" | "s2" | "s3" | "s4" {
    return PoliciesAppUtilities.isSTopic(identifier) || PoliciesAppUtilities.isETopic(identifier);
  }
}
