import {
  Badge,
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDataEntryObject,
  IDataEntryObjectInputParameterValueValueForKey,
  IDistributionCriterionWithApplicationStatus,
  IEvaluationsEnum,
  IInputParameter,
  IOrganization,
  ITranslatedProperty,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ErrorDialog } from "../../common/dialogs/variants/error.dialog";
import { TreeGroupContainer } from "../../common/components/tree-group-container.component";
import {
  ChevronDownIcon,
  EditIcon,
  FilterIcon,
} from "../../common/constants/tabler-icon.constants";
import {
  useSearchParamArrayStateBasic,
  useSearchParamState,
} from "../../common/hooks/use-search-param-state.hook";
import { DataEntryObjectInputParametersDialog } from "../../data-entry-objects-input-parameters/dialogs/data-entry-object-input-parameters.dialog";
import { useUpdateInputParameterConfigurationMutation } from "../../data-entry-objects-input-parameters/data-entry-object-input-parameters.mutations";
import { IHydratedInputParametersModelStructureTreeThgGroup } from "../../input-parameter-recording-structures/thg/input-parameter-recording-thg-structures.interfaces";
import { OrganizationStructureBreadcrumbs } from "../../organization-structures/navigation/organization-structure-breadcrumbs.component";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { PopupMenuFilterUsersSectionComponent } from "../../user/components/popup-menu-filter-users-section.component";
import { DataEntryObjectTHGValuesGroupComponent } from "../data-entry-object-values-group.component";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValueData,
} from "../interfaces/local-data-entry-object-values.interfaces";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useEvaluationLink } from "../../evaluation/hooks/evaluation-link.hook";
import { EvaluationIcon } from "../../evaluation/evaluation-icon.component";
import { ILocalInputParameterRecordingStructureTHG } from "../../input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import { InputParameterRecordingThgStructuresUtilities } from "../../input-parameter-recording-structures/thg/input-parameter-recording-thg-structures.utilities";
import {
  useCreateDataEntryObjectInputParameterValueMutation,
  useDeleteDataEntryObjectInputParameterValueMutation,
  useUpdateDataEntryObjectInputParameterValueMutation,
} from "../mutations/data-entry-object-input-parameter.mutations";

const SELECTED_GROUP_QUERY_KEY = "sustainabilityCategory";
const SELECTED_SUB_GROUP_QUERY_KEY = "subCategory";

interface IDataEntryObjectValuesTHGComponentProps {
  organization: IOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  recordingStructure: ILocalInputParameterRecordingStructureTHG;
  organizationStructure: IDataEntryObject;
  dataEntryObject: IDataEntryObject;
  dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[];
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  onChangeDataEntryObject: (dataEntryObjectId: string) => void;
}

export const DataEntryObjectValuesOverviewThgComponent: FC<
  IDataEntryObjectValuesTHGComponentProps
> = ({
  organization,
  recordingPeriod,
  recordingStructure,
  organizationStructure,
  dataEntryObject,
  dataEntryObjectInputParameters,
  availableDistributionCriteria,
  onChangeDataEntryObject,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_thg_component");
  const translateContent = useTranslateContent();

  // TODO: remove this once old structures are no longer used as THG structures
  const isEmissionsStructure = useMemo(
    () => recordingStructure.evaluations.includes(IEvaluationsEnum.Emissions),
    [recordingStructure],
  );

  /** Contains Index of the currently manually expanded TOC Section */
  const [expandedTOCSectionIndex, setExpandedTOCSectionIndex] = useState<number | null>(null);

  const [showDataEntryObjectInputParametersDialog, setShowDataEntryObjectInputParametersDialog] =
    useState(false);

  // Filter Button

  const filterButtonRef = useRef<HTMLButtonElement>(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const availableUserIds = useMemo(
    () => Array.from(new Set(dataEntryObjectInputParameters.flatMap((d) => d.contributingUserIds))),
    [dataEntryObjectInputParameters],
  );

  const [currentFilteredUserIds, setCurrentFilteredUserIds] =
    useSearchParamArrayStateBasic("filteredUserId");
  // Cleanup Filtered UserIds if not available anymore
  useEffect(() => {
    const newFilteredUserIds = currentFilteredUserIds.filter((id) => availableUserIds.includes(id));
    if (newFilteredUserIds.length !== currentFilteredUserIds.length) {
      setCurrentFilteredUserIds(newFilteredUserIds, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilteredUserIds, availableUserIds]);
  // Reset Open Group Index if Filtered UserIds change
  const lastFilteredUserIds = useRef(currentFilteredUserIds);
  useEffect(() => {
    if (
      lastFilteredUserIds.current.length !== currentFilteredUserIds.length ||
      (lastFilteredUserIds.current.length > 0 &&
        lastFilteredUserIds.current.every((id) => !currentFilteredUserIds.includes(id)))
    ) {
      setCurrentlyOpenGroupIndex(0);
      lastFilteredUserIds.current = currentFilteredUserIds;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilteredUserIds]);

  const filteredDataEntryObjectInputParameters = useMemo(() => {
    if (currentFilteredUserIds.length === 0) {
      return dataEntryObjectInputParameters;
    }
    return dataEntryObjectInputParameters.filter((d) =>
      d.contributingUserIds.some((uid) => currentFilteredUserIds.includes(uid)),
    );
  }, [dataEntryObjectInputParameters, currentFilteredUserIds]);

  const dependencyMap = useMemo(() => {
    const data = filteredDataEntryObjectInputParameters;

    // Create a hashmap of all recorded values (<inputParamId, recordedValue>)
    const recordedValuesMap = data.reduce<
      Record<string, { [key: string]: IDataEntryObjectInputParameterValueValueForKey | undefined }>
    >((acc, entry) => {
      const ip = entry.inputParameter;
      if (entry.recordedValues.length > 0) {
        const firstRecord = entry.recordedValues[0];
        acc[ip.id] = firstRecord.valuesPerKey;
      }
      return acc;
    }, {});

    const recordedNamesMap = data.reduce<{ [key: string]: ITranslatedProperty | undefined }>(
      (acc, entry) => {
        const ip = entry.inputParameter;
        acc[ip.id] = entry.inputParameter.name;
        return acc;
      },
      {},
    );

    // Check conditions and determine if IP should be rendered
    return data.reduce<
      Record<
        string,
        { shouldRender: boolean; showAlert: boolean; name: ITranslatedProperty | undefined }
      >
    >((acc, entry) => {
      const ip = entry.inputParameter;
      // If there are no conditions, the IP should be shown
      let shouldRender = true;
      let showAlert = false;
      let name;

      if (ip.condition) {
        const condition = ip.condition;
        const checkIpId = condition.checkInputParameterId;
        // Set shouldRender based on the existence and truth of the condition check
        shouldRender =
          checkIpId in recordedValuesMap &&
          recordedValuesMap[checkIpId] &&
          recordedValuesMap[checkIpId]?.[condition.check.key]?.value === condition.check.value;
        showAlert =
          shouldRender === false &&
          recordedValuesMap[ip.id]?.[condition.check.key]?.value !== undefined;
        name = recordedNamesMap[checkIpId];
      }

      acc[ip.id] = { shouldRender, showAlert, name };
      return acc;
    }, {});
  }, [filteredDataEntryObjectInputParameters]);

  // TODO: To improve performance possibly replace with reactQuery (to cache trees already calculated)
  // IF DONE - DO NOT FORGET TO INVALIDATE QueryKey on refetch of values or structure !!!
  // (or use dependencies if feature exists/is available)
  const hydratedInputParameterModelStructure = useMemo(() => {
    const fullTree =
      InputParameterRecordingThgStructuresUtilities.hydrateInputParameterRecordingStructureThgTree(
        recordingStructure.structure.rootSustainabilityCategory,
        filteredDataEntryObjectInputParameters,
      );

    return InputParameterRecordingThgStructuresUtilities.removeEmptyRecordingGroupsFromThgTree(
      fullTree,
      true,
    );
  }, [recordingStructure, filteredDataEntryObjectInputParameters]);

  const [currentlyOpenGroupIndex, setCurrentlyOpenGroupIndex] = useSearchParamState(
    SELECTED_GROUP_QUERY_KEY,
    (index) => +(index ?? 0),
    undefined,
    0,
  );
  const currentlyOpenGroup: IHydratedInputParametersModelStructureTreeThgGroup | null = useMemo(
    () => hydratedInputParameterModelStructure.structure.children[currentlyOpenGroupIndex] ?? null,
    [hydratedInputParameterModelStructure, currentlyOpenGroupIndex],
  );

  const [currentlyOpenSubGroupIndex] = useSearchParamState(
    SELECTED_SUB_GROUP_QUERY_KEY,
    (index) => +(index ?? 0),
    undefined,
    0,
  );
  const currentlyOpenSubGroup: IHydratedInputParametersModelStructureTreeThgGroup | null = useMemo(
    () => currentlyOpenGroup?.children[currentlyOpenSubGroupIndex] ?? null,
    [currentlyOpenGroup, currentlyOpenSubGroupIndex],
  );

  const addValueMutation = useCreateDataEntryObjectInputParameterValueMutation();
  const updateValueMutation = useUpdateDataEntryObjectInputParameterValueMutation();
  const deleteValueMutation = useDeleteDataEntryObjectInputParameterValueMutation();

  const updateRecordingConfigurationMutation = useUpdateInputParameterConfigurationMutation();
  const handleUpdateRecordingConfiguration = useCallback(
    async (inputParameter: ILocalDataEntryObjectInputParameter, values: string[]) => {
      await updateRecordingConfigurationMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: recordingPeriod.id,
        dataEntryObjectId: dataEntryObject.id,
        recordingStructureId: recordingStructure.id,
        updates: {
          ...inputParameter,
          contributingUserIds: values,
        },
      });
    },
    [
      dataEntryObject.id,
      organization.id,
      recordingPeriod.id,
      recordingStructure.id,
      updateRecordingConfigurationMutation,
    ],
  );

  // Render Component

  const loading = useMemo(() => {
    return (
      addValueMutation.isPending ||
      updateValueMutation.isPending ||
      deleteValueMutation.isPending ||
      updateRecordingConfigurationMutation.isPending
    );
  }, [
    addValueMutation.isPending,
    updateValueMutation.isPending,
    deleteValueMutation.isPending,
    updateRecordingConfigurationMutation.isPending,
  ]);

  const isError = useMemo(() => {
    return (
      addValueMutation.isError ||
      updateValueMutation.isError ||
      deleteValueMutation.isError ||
      updateRecordingConfigurationMutation.isError
    );
  }, [
    addValueMutation.isError,
    updateValueMutation.isError,
    deleteValueMutation.isError,
    updateRecordingConfigurationMutation.isError,
  ]);

  // ToC Logic

  const location = useLocation();
  const getLinkForCategory = useCallback(
    (selectedGroupIndex: number, selectedSubGroupIndex?: number) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(SELECTED_GROUP_QUERY_KEY, selectedGroupIndex.toString());
      searchParams.set(SELECTED_SUB_GROUP_QUERY_KEY, selectedSubGroupIndex?.toString() ?? "0");
      return `${location.pathname}?${searchParams.toString()}`;
    },
    [location.pathname, location.search],
  );

  // Handlers

  const handleCreateValueEntry = useCallback(
    async (
      inputParameter: IInputParameter,
      value: ILocalDataEntryObjectInputParameterValueData,
    ) => {
      await addValueMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: recordingPeriod.id,
        dataEntryObjectId: dataEntryObject.id,
        recordingStructureId: recordingStructure.id,
        inputParameter,
        valueData: value,
      });
    },
    [
      addValueMutation,
      organization.id,
      recordingPeriod.id,
      dataEntryObject.id,
      recordingStructure.id,
    ],
  );

  const handleUpdateValueEntry = useCallback(
    async (
      inputParameter: IInputParameter,
      valueId: string,
      value: ILocalDataEntryObjectInputParameterValueData,
    ) => {
      await updateValueMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: recordingPeriod.id,
        dataEntryObjectId: dataEntryObject.id,
        recordingStructureId: recordingStructure.id,
        inputParameter,
        valueId,
        valueData: value,
      });
    },
    [
      updateValueMutation,
      organization.id,
      recordingPeriod.id,
      dataEntryObject.id,
      recordingStructure.id,
    ],
  );

  const handleDeleteValueEntry = useCallback(
    async (inputParameter: IInputParameter, valueId: string) => {
      await deleteValueMutation.mutateAsync({
        organizationId: organization.id,
        recordingPeriodId: recordingPeriod.id,
        dataEntryObjectId: dataEntryObject.id,
        recordingStructureId: recordingStructure.id,
        inputParameter,
        valueId,
      });
    },
    [
      deleteValueMutation,
      organization.id,
      recordingPeriod.id,
      dataEntryObject.id,
      recordingStructure.id,
    ],
  );

  // Emission Evaluation

  const evaluationLinkUrl = useEvaluationLink({
    organizationId: organization.id,
    recordingPeriodId: recordingPeriod.id,
    evaluation: IEvaluationsEnum.Emissions,
    recordingStructureId: recordingStructure.id,
    dataEntryObjectId: dataEntryObject.id,
  });

  // Render

  return (
    <>
      {/* Edit Recorded Parameters Dialog */}
      <DataEntryObjectInputParametersDialog
        open={showDataEntryObjectInputParametersDialog}
        onClose={() => setShowDataEntryObjectInputParametersDialog(false)}
        organization={organization}
        recordingPeriod={recordingPeriod}
        dataEntryObject={dataEntryObject}
        dataEntryObjectInputParameters={dataEntryObjectInputParameters}
        recordingStructure={recordingStructure}
      />
      {/* Error Dialog */}
      <ErrorDialog
        open={isError}
        onClose={() => {
          addValueMutation.reset();
          updateValueMutation.reset();
          deleteValueMutation.reset();
          updateRecordingConfigurationMutation.reset();
        }}
        error={
          (addValueMutation.error ||
            updateValueMutation.error ||
            deleteValueMutation.error ||
            updateRecordingConfigurationMutation.error)!
        }
      />

      {/* Filter Menu */}
      <Menu
        open={showFilterMenu}
        onClose={() => setShowFilterMenu(false)}
        anchorEl={filterButtonRef.current}
        slotProps={{ paper: { sx: { mt: 2 } } }}
      >
        <Box px={1}>
          <PopupMenuFilterUsersSectionComponent
            organizationId={organization.id}
            filterableUserIds={availableUserIds}
            filterByUserIds={currentFilteredUserIds}
            onChangeFilterByUserIds={(newUserIds) => {
              setCurrentFilteredUserIds(newUserIds);
            }}
            noUsersAvailableMessage={t("no_filterable_users_available")}
          />
        </Box>
      </Menu>

      {/* Component Content */}
      <Box>
        <Box mb={4}>
          <Typography variant="h3" component="h1" mb={1}>
            {translateContent(recordingStructure.structure.rootSustainabilityCategory.name)}
          </Typography>
          <Box>
            {isEmissionsStructure && (
              <Button
                variant="contained"
                startIcon={<EvaluationIcon isEvaluation />}
                sx={{ mb: 2, mr: "auto" }}
                component={RouterLink}
                to={evaluationLinkUrl}
              >
                {t(`evaluation_name.${IEvaluationsEnum.Emissions}`, {
                  ns: "evaluation_list_item",
                })}
              </Button>
            )}
          </Box>
          <Box display="flex" gap={2}>
            <Tooltip title={t("tooltip_button_filter")}>
              <span>
                <IconButton
                  ref={filterButtonRef}
                  onClick={() => setShowFilterMenu(true)}
                  size="small"
                >
                  <Badge
                    badgeContent={currentFilteredUserIds.length}
                    variant="dot"
                    overlap="circular"
                    color="primary"
                  >
                    <FilterIcon />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
            <Divider flexItem orientation="vertical" sx={{ my: 1 }} />
            <Box display="flex">
              <OrganizationStructureBreadcrumbs
                organizationStructure={organizationStructure}
                onChange={onChangeDataEntryObject}
                dataEntryObjectId={dataEntryObject.id}
              />
              {/* Edit Recorded Parameters Button */}
              {isEmissionsStructure && (
                <Tooltip title={t("edit_data_entry_object_input_parameters")} placement="right">
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => setShowDataEntryObjectInputParametersDialog(true)}
                      disabled={loading}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap={6}>
          <Box maxWidth="16vw">
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              position="sticky"
              top={0}
              height="100vh"
              px={2}
              py={2}
              sx={{ overflowY: "auto" }}
            >
              {/* Table of Contents */}
              {/* TODO: If this code is touched --> refactor to use the common and reusable component from table-of-contents.component.tsx */}
              {hydratedInputParameterModelStructure.structure.children.map(
                (firstLevelCategory, index) => (
                  // Index is fine here since structure does not change unless everything changes
                  <Box key={index}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => {
                          if (currentlyOpenGroupIndex !== index) {
                            setExpandedTOCSectionIndex((curr) => (curr === index ? null : index));
                          }
                        }}
                        sx={{
                          transform: `rotate(${
                            expandedTOCSectionIndex === index || currentlyOpenGroupIndex === index
                              ? 180
                              : 0
                          }deg)`,
                          transition: "transform 300ms",
                          cursor: currentlyOpenGroupIndex === index ? "default" : undefined,
                        }}
                        disableRipple={currentlyOpenGroupIndex === index}
                      >
                        <ChevronDownIcon />
                      </IconButton>
                      <Link
                        component={RouterLink}
                        to={getLinkForCategory(index)}
                        color="inherit"
                        underline="none"
                      >
                        <Typography
                          fontWeight={currentlyOpenGroupIndex === index ? "bolder" : "normal"}
                        >
                          {translateContent(firstLevelCategory.recordingGroupInfo.name)}
                        </Typography>
                      </Link>
                    </Box>
                    <Collapse
                      in={expandedTOCSectionIndex === index || currentlyOpenGroupIndex === index}
                      sx={{ pl: 1.25 }}
                    >
                      <TreeGroupContainer
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        my={1}
                        sx={{ borderColor: (theme) => theme.palette.primary.light }}
                      >
                        {firstLevelCategory.children.map((secondLevelCategory, subIndex) => (
                          <Link
                            key={subIndex}
                            component={RouterLink}
                            to={getLinkForCategory(index, subIndex)}
                            underline="none"
                            color="inherit"
                            onClick={() => {
                              // Always close currently expanded group
                              setExpandedTOCSectionIndex(null);
                            }}
                          >
                            <Typography
                              variant="body2"
                              fontWeight={
                                currentlyOpenGroupIndex === index &&
                                currentlyOpenSubGroupIndex === subIndex
                                  ? "bold"
                                  : undefined
                              }
                            >
                              {translateContent(secondLevelCategory.recordingGroupInfo.name)}
                            </Typography>
                          </Link>
                        ))}
                      </TreeGroupContainer>
                    </Collapse>
                  </Box>
                ),
              )}
            </Box>
          </Box>
          <Box flex={1} pl={0} pr={2} pb={2}>
            {/* Top Loading Bar */}
            {loading && <LinearProgress />}

            {hydratedInputParameterModelStructure.structure.children.length > 0 ? (
              currentlyOpenSubGroup ? (
                <DataEntryObjectTHGValuesGroupComponent
                  dataEntryObject={dataEntryObject}
                  rootDataEntryObjectId={organizationStructure.id}
                  availableDistributionCriteria={availableDistributionCriteria}
                  group={currentlyOpenSubGroup}
                  recordingPeriod={recordingPeriod}
                  initialExpanded={true}
                  disabled={loading}
                  onCreateValueEntry={handleCreateValueEntry}
                  onUpdateValueEntry={handleUpdateValueEntry}
                  onDeleteValueEntry={handleDeleteValueEntry}
                  organizationId={organization.id}
                  onAssignedUsersChange={handleUpdateRecordingConfiguration}
                  dependencyMap={dependencyMap}
                />
              ) : (
                <Typography>{t("no_results_found")}</Typography>
              )
            ) : (
              <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1.5}>
                <Typography>
                  {t("category_empty", { ns: "data_entry_object_values_group" })}
                </Typography>
                <Button
                  onClick={() => setShowDataEntryObjectInputParametersDialog(true)}
                  variant="outlined"
                >
                  {t("redirect_button")}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
