import { useAppSnackbar } from "../app-snackbar/app-snackbar.hook";
import {
  APPROVE_DR_ACTION,
  REJECT_DR_ACTION,
  RESET_DR_ACTION,
  SUBMIT_DR_ACTION,
  UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER_ID_ACTION,
  UPDATE_GAP_ANALYSIS_AVAILABILITY_ACTION,
  UPDATE_GAP_ANALYSIS_DATA_COLLECTION_ACTION,
} from "../data-entry-object-values/esrs/esrs-api-actions.constants";
import {
  useApproveDataEntryObjectInputParameterMutation,
  useRejectDataEntryObjectInputParameterMutation,
  useResetDataEntryObjectInputParameterMutation,
  useSubmitDataEntryObjectInputParameterMutation,
  useUpdateInputParameterConfigurationMutation,
} from "../data-entry-objects-input-parameters/data-entry-object-input-parameters.mutations";
import { useCallback, useMemo } from "react";
import { ILocalDataEntryObjectInputParameter } from "../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObjectInputParameterGapAnalysisAvailability,
  IDataEntryObjectInputParameterGapAnalysisDataCollection,
  IInputParameter,
} from "@netcero/netcero-core-api-client";
import { useUpdateGapAnalysisInformation } from "../data-entry-object-values/mutations/data-entry-object-input-parameter.mutations";

export const useGapAnalysisApiActions = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  recordingStructureId: string,
) => {
  const { wrapApiPromise } = useAppSnackbar();

  const baseMutationsData = useMemo(
    () => ({
      organizationId: organizationId,
      recordingPeriodId: recordingPeriodId,
      dataEntryObjectId: dataEntryObjectId,
      recordingStructureId: recordingStructureId,
    }),
    [organizationId, recordingPeriodId, dataEntryObjectId, recordingStructureId],
  );

  // DEO IP Configuration Mutations

  const updateInputParameterConfigurationMutation = useUpdateInputParameterConfigurationMutation();

  const handleUpdateResponsibleUser = useCallback(
    (
      dataEntryObjectInputParameter: ILocalDataEntryObjectInputParameter,
      newResponsibleUserId: string | null,
    ) =>
      wrapApiPromise(
        updateInputParameterConfigurationMutation.mutateAsync({
          ...baseMutationsData,
          updates: {
            ...dataEntryObjectInputParameter,
            responsibleUserId: newResponsibleUserId ?? undefined,
          },
        }),
        UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER_ID_ACTION,
      ),
    [baseMutationsData, updateInputParameterConfigurationMutation, wrapApiPromise],
  );

  // DR Status

  const resetInputParameterMutation = useResetDataEntryObjectInputParameterMutation();
  const handleResetDisclosureRequirement = useCallback(
    (inputParameter: IInputParameter) =>
      wrapApiPromise(
        resetInputParameterMutation.mutateAsync({
          ...baseMutationsData,
          inputParameterId: inputParameter.id,
        }),
        RESET_DR_ACTION,
      ),
    [baseMutationsData, resetInputParameterMutation, wrapApiPromise],
  );

  const submitInputParameterMutation = useSubmitDataEntryObjectInputParameterMutation();
  const handleSubmitDisclosureRequirement = useCallback(
    (inputParameter: IInputParameter) =>
      wrapApiPromise(
        submitInputParameterMutation.mutateAsync({
          ...baseMutationsData,
          inputParameterId: inputParameter.id,
        }),
        SUBMIT_DR_ACTION,
      ),
    [baseMutationsData, submitInputParameterMutation, wrapApiPromise],
  );

  const approveInputParameterMutation = useApproveDataEntryObjectInputParameterMutation();
  const handleApproveDisclosureRequirement = useCallback(
    (inputParameter: IInputParameter) =>
      wrapApiPromise(
        approveInputParameterMutation.mutateAsync({
          ...baseMutationsData,
          inputParameterId: inputParameter.id,
        }),
        APPROVE_DR_ACTION,
      ),
    [approveInputParameterMutation, baseMutationsData, wrapApiPromise],
  );

  const rejectInputParameterMutation = useRejectDataEntryObjectInputParameterMutation();
  const handleRejectDisclosureRequirement = useCallback(
    (inputParameter: IInputParameter) =>
      wrapApiPromise(
        rejectInputParameterMutation.mutateAsync({
          ...baseMutationsData,
          inputParameterId: inputParameter.id,
        }),
        REJECT_DR_ACTION,
      ),
    [rejectInputParameterMutation, baseMutationsData, wrapApiPromise],
  );

  // Gap Analysis specific values

  const updateGapAnalysisMutation = useUpdateGapAnalysisInformation();
  const handleUpdateGapAnalysisAvailability = useCallback(
    (
      inputParameter: ILocalDataEntryObjectInputParameter,
      availability: IDataEntryObjectInputParameterGapAnalysisAvailability | null,
    ) =>
      wrapApiPromise(
        updateGapAnalysisMutation.mutateAsync({
          ...baseMutationsData,
          inputParameterId: inputParameter.inputParameter.id,
          payload: {
            ...inputParameter.gapAnalysis,
            availability: availability ?? undefined,
          },
        }),
        UPDATE_GAP_ANALYSIS_AVAILABILITY_ACTION,
      ),
    [baseMutationsData, updateGapAnalysisMutation, wrapApiPromise],
  );
  const handleUpdateGapAnalysisDataCollection = useCallback(
    (
      inputParameter: ILocalDataEntryObjectInputParameter,
      dataCollection: IDataEntryObjectInputParameterGapAnalysisDataCollection | null,
    ) =>
      wrapApiPromise(
        updateGapAnalysisMutation.mutateAsync({
          ...baseMutationsData,
          inputParameterId: inputParameter.inputParameter.id,
          payload: {
            ...inputParameter.gapAnalysis,
            dataCollection: dataCollection ?? undefined,
          },
        }),
        UPDATE_GAP_ANALYSIS_DATA_COLLECTION_ACTION,
      ),
    [baseMutationsData, updateGapAnalysisMutation, wrapApiPromise],
  );

  // aggregate mutations state

  const isLoading = useMemo(
    () =>
      updateInputParameterConfigurationMutation.isPending ||
      resetInputParameterMutation.isPending ||
      submitInputParameterMutation.isPending ||
      approveInputParameterMutation.isPending ||
      rejectInputParameterMutation.isPending ||
      updateGapAnalysisMutation.isPending,
    [
      updateInputParameterConfigurationMutation.isPending,
      resetInputParameterMutation.isPending,
      submitInputParameterMutation.isPending,
      approveInputParameterMutation.isPending,
      rejectInputParameterMutation.isPending,
      updateGapAnalysisMutation.isPending,
    ],
  );
  const isError = useMemo(
    () =>
      updateInputParameterConfigurationMutation.isError ||
      resetInputParameterMutation.isError ||
      submitInputParameterMutation.isError ||
      approveInputParameterMutation.isError ||
      rejectInputParameterMutation.isError ||
      updateGapAnalysisMutation.isError,
    [
      updateInputParameterConfigurationMutation.isError,
      resetInputParameterMutation.isError,
      submitInputParameterMutation.isError,
      approveInputParameterMutation.isError,
      rejectInputParameterMutation.isError,
      updateGapAnalysisMutation.isError,
    ],
  );

  // Return aggregate object of all actions
  return useMemo(
    () => ({
      // Actions
      handleUpdateResponsibleUser,
      handleResetDisclosureRequirement,
      handleSubmitDisclosureRequirement,
      handleApproveDisclosureRequirement,
      handleRejectDisclosureRequirement,
      handleUpdateGapAnalysisAvailability,
      handleUpdateGapAnalysisDataCollection,
      // State
      isLoading,
      isError,
    }),
    [
      handleUpdateResponsibleUser,
      handleResetDisclosureRequirement,
      handleSubmitDisclosureRequirement,
      handleApproveDisclosureRequirement,
      handleRejectDisclosureRequirement,
      handleUpdateGapAnalysisAvailability,
      handleUpdateGapAnalysisDataCollection,
      isLoading,
      isError,
    ],
  );
};
