import { Box, Typography } from "@mui/material";
import { IESRSTopic } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  BaseChart,
  EChartClickHandler,
  EChartsOption,
} from "../../common/charts/base-chart.component";
import { DMAMatrixSeriesElement, DMAMatrixUtilities } from "./dma-matrix.utilities";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { useRenderESRSTopicName } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";

interface IDMAMatrixChartTopicProps {
  esrsTopic: IESRSTopic;
  height?: string;
  onSelectCategory: (categoryId: string) => void;
}

export const DMAMatrixChartTopic: FC<IDMAMatrixChartTopicProps> = ({
  esrsTopic,
  height,
  onSelectCategory,
}) => {
  const { t } = useTranslation("dma_dashboard");
  const renderName = useRenderDMACategoryName();
  const renderTopicName = useRenderESRSTopicName();

  const chartData: EChartsOption | null = useMemo(() => {
    if (!esrsTopic.recordedESRSTopic) {
      return null;
    }

    const data = DMAMatrixUtilities.filterSeriesToDisplayableSeries(
      esrsTopic.recordedESRSTopic?.dmaCategories.map((c) =>
        DMAMatrixUtilities.convertCategoryToSeriesData(c, renderName),
      ),
    );

    if (data.length === 0) {
      return null;
    }

    const maxMaterialityDegreeSum = Math.max(...data.map((data) => data[2]));

    return {
      // title: {
      //   text: esrsTopic.topic,
      //   subtext: esrsTopic.name,
      // },
      legend: {
        data: [
          t("label_topic_environmental"),
          t("label_topic_social"),
          t("label_topic_governance"),
          t("label_topic_other"),
        ],
      },
      xAxis: {
        ...DMAMatrixUtilities.DEFAULT_CHART_PROPS.AXIS_PROPS,
        name: t("label_axis_financial_materiality"),
      },
      yAxis: {
        ...DMAMatrixUtilities.DEFAULT_CHART_PROPS.AXIS_PROPS,
        name: t("label_axis_material_materiality"),
      },
      series: [
        DMAMatrixUtilities.getScatterChartSeriesData(
          undefined,
          data,
          maxMaterialityDegreeSum,
          DMAMatrixUtilities.getColorForTopic(esrsTopic),
        ),
      ],
    } as EChartsOption;
  }, [esrsTopic, renderName, t]);

  if (chartData === null) {
    return (
      <Box flex={1} display="flex" alignItems="center" justifyContent="center">
        <Typography textAlign="center">{t("no_categories_to_display_notice")}</Typography>
      </Box>
    );
  }

  const handleChartClick: EChartClickHandler = (params) => {
    const data = params.data as DMAMatrixSeriesElement;
    onSelectCategory(data[5]);
  };

  return (
    <Box flex={1}>
      <Typography variant="h5">
        {esrsTopic.topic} - {renderTopicName(esrsTopic)}
      </Typography>
      <BaseChart options={chartData} height={height} onClick={handleChartClick} />
    </Box>
  );
};
