import {
  IDataEntryObject,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { EvaluationUtilities } from "../utilities/evaluation.utilities";
import { GroupedCalculationResult, ScopeKeys } from "@netcero/netcero-common";

export interface IEmissionsScopesSumsResult {
  scope1: number;
  scope2: number;
  scope3: number;
  total: number;
}

export class EmissionsUtilities {
  public static sumUpEmissionsResults(resultsMap: Map<string, number>) {
    return (
      (resultsMap.get(ScopeKeys.Scope1) ?? 0) +
      (resultsMap.get(ScopeKeys.Scope2) ?? 0) +
      (resultsMap.get(ScopeKeys.Scope3) ?? 0)
    );
  }

  /**
   * Sum up all emissions by scope for a data entry object and a structure group.
   * @param generalResult The entire result
   * @param currentDataEntryObject The current DEO
   * @param structureGroup The structure group to sum up the values for.
   * @param considerChildDEOs If true, the values of all children of the data entry object will be included. Default: true
   * @param considerChildStructureGroups If true, the values of all children of the structure group will be included. Default: true
   * @returns The sums for scope 1, 2, 3 and total.
   */
  public static getEmissionScopesSumsForResultAndGroup(
    generalResult: GroupedCalculationResult,
    currentDataEntryObject: IDataEntryObject,
    structureGroup: IInputParameterRecordingStructureGroupTHGSC,
    considerChildDEOs: boolean = true,
    considerChildStructureGroups: boolean = true,
  ): IEmissionsScopesSumsResult {
    const structureResult = EvaluationUtilities.sumUpStructureGroupFromRawResult(
      generalResult,
      currentDataEntryObject,
      considerChildDEOs,
      structureGroup,
      considerChildStructureGroups,
    );

    return {
      scope1: structureResult.get(ScopeKeys.Scope1) ?? 0,
      scope2: structureResult.get(ScopeKeys.Scope2) ?? 0,
      scope3: structureResult.get(ScopeKeys.Scope3) ?? 0,
      total: EmissionsUtilities.sumUpEmissionsResults(structureResult),
    };
  }
}
