/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IAddEsrsCategory404Response } from "../models";
// @ts-ignore
import { IAddEsrsCategory409Response } from "../models";
// @ts-ignore
import { ICreateDMACategoryRequest } from "../models";
// @ts-ignore
import { ICreateDMACategoryResponse } from "../models";
// @ts-ignore
import { IDMACategory } from "../models";
// @ts-ignore
import { IDMACategoryInformation } from "../models";
// @ts-ignore
import { IDeleteEsrsCategory409Response } from "../models";
// @ts-ignore
import { IGetDataEntryObject404Response } from "../models";
// @ts-ignore
import { IOptOutOfDmaCategory409Response } from "../models";
// @ts-ignore
import { IOptOutOfDmaCategoryRequest } from "../models";
// @ts-ignore
import { IUpdateDMACategoryRequest } from "../models";
// @ts-ignore
import { IUpdateDmaCategoryInformation404Response } from "../models";
// @ts-ignore
import { IUpdateDmaCategoryInformation409Response } from "../models";
// @ts-ignore
import { IUpdateEsrsCategory409Response } from "../models";
// @ts-ignore
import { IVerifyStakeholderFeedback404Response } from "../models";
/**
 * DoubleMaterialityAssessmentCategoryApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentCategoryApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEsrsCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      iCreateDMACategoryRequest: ICreateDMACategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("addEsrsCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("addEsrsCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("addEsrsCategory", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("addEsrsCategory", "esrsTopicId", esrsTopicId);
      // verify required parameter 'iCreateDMACategoryRequest' is not null or undefined
      assertParamExists("addEsrsCategory", "iCreateDMACategoryRequest", iCreateDMACategoryRequest);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iCreateDMACategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes a single DMA category.
     * @summary Deletes a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
     * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
     * @param {string} dmaCategoryId The id of the category to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEsrsCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteEsrsCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("deleteEsrsCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("deleteEsrsCategory", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("deleteEsrsCategory", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("deleteEsrsCategory", "dmaCategoryId", dmaCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to opt out of.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optOutOfDmaCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("optOutOfDmaCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("optOutOfDmaCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("optOutOfDmaCategory", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("optOutOfDmaCategory", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("optOutOfDmaCategory", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iOptOutOfDmaCategoryRequest' is not null or undefined
      assertParamExists(
        "optOutOfDmaCategory",
        "iOptOutOfDmaCategoryRequest",
        iOptOutOfDmaCategoryRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/opt-out`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOptOutOfDmaCategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the information for a given DMA category.
     * @summary Updates the information for a given DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IDMACategoryInformation} iDMACategoryInformation Used to update the information of a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDmaCategoryInformation: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iDMACategoryInformation: IDMACategoryInformation,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateDmaCategoryInformation", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateDmaCategoryInformation", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("updateDmaCategoryInformation", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("updateDmaCategoryInformation", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("updateDmaCategoryInformation", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iDMACategoryInformation' is not null or undefined
      assertParamExists(
        "updateDmaCategoryInformation",
        "iDMACategoryInformation",
        iDMACategoryInformation,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/information`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDMACategoryInformation,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates a single DMA category.
     * @summary Updates a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
     * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
     * @param {string} dmaCategoryId The id of the category to update.
     * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEsrsCategory: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iUpdateDMACategoryRequest: IUpdateDMACategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateEsrsCategory", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateEsrsCategory", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("updateEsrsCategory", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("updateEsrsCategory", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("updateEsrsCategory", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iUpdateDMACategoryRequest' is not null or undefined
      assertParamExists(
        "updateEsrsCategory",
        "iUpdateDMACategoryRequest",
        iUpdateDMACategoryRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUpdateDMACategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DoubleMaterialityAssessmentCategoryApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentCategoryApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DoubleMaterialityAssessmentCategoryApiAxiosParamCreator(configuration);
  return {
    /**
     * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addEsrsCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      iCreateDMACategoryRequest: ICreateDMACategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ICreateDMACategoryResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addEsrsCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        iCreateDMACategoryRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Deletes a single DMA category.
     * @summary Deletes a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
     * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
     * @param {string} dmaCategoryId The id of the category to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteEsrsCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEsrsCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to opt out of.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async optOutOfDmaCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.optOutOfDmaCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iOptOutOfDmaCategoryRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the information for a given DMA category.
     * @summary Updates the information for a given DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IDMACategoryInformation} iDMACategoryInformation Used to update the information of a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDmaCategoryInformation(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iDMACategoryInformation: IDMACategoryInformation,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMACategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDmaCategoryInformation(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iDMACategoryInformation,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates a single DMA category.
     * @summary Updates a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
     * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
     * @param {string} dmaCategoryId The id of the category to update.
     * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateEsrsCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iUpdateDMACategoryRequest: IUpdateDMACategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMACategory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateEsrsCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iUpdateDMACategoryRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DoubleMaterialityAssessmentCategoryApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentCategoryApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DoubleMaterialityAssessmentCategoryApiFp(configuration);
  return {
    /**
     * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addEsrsCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      iCreateDMACategoryRequest: ICreateDMACategoryRequest,
      options?: any,
    ): AxiosPromise<ICreateDMACategoryResponse> {
      return localVarFp
        .addEsrsCategory(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          iCreateDMACategoryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes a single DMA category.
     * @summary Deletes a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
     * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
     * @param {string} dmaCategoryId The id of the category to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteEsrsCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteEsrsCategory(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to opt out of.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optOutOfDmaCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .optOutOfDmaCategory(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iOptOutOfDmaCategoryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the information for a given DMA category.
     * @summary Updates the information for a given DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IDMACategoryInformation} iDMACategoryInformation Used to update the information of a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDmaCategoryInformation(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iDMACategoryInformation: IDMACategoryInformation,
      options?: any,
    ): AxiosPromise<IDMACategory> {
      return localVarFp
        .updateDmaCategoryInformation(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iDMACategoryInformation,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates a single DMA category.
     * @summary Updates a single DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
     * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
     * @param {string} dmaCategoryId The id of the category to update.
     * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateEsrsCategory(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iUpdateDMACategoryRequest: IUpdateDMACategoryRequest,
      options?: any,
    ): AxiosPromise<IDMACategory> {
      return localVarFp
        .updateEsrsCategory(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iUpdateDMACategoryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DoubleMaterialityAssessmentCategoryApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentCategoryApi
 */
export interface DoubleMaterialityAssessmentCategoryApiInterface {
  /**
   * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
   * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApiInterface
   */
  addEsrsCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    iCreateDMACategoryRequest: ICreateDMACategoryRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<ICreateDMACategoryResponse>;

  /**
   * Deletes a single DMA category.
   * @summary Deletes a single DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
   * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
   * @param {string} dmaCategoryId The id of the category to delete.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApiInterface
   */
  deleteEsrsCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Frees the user from having to specify effects for the specified data entry object and DMA category.
   * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
   * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
   * @param {string} dmaCategoryId The id of the category to opt out of.
   * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApiInterface
   */
  optOutOfDmaCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Updates the information for a given DMA category.
   * @summary Updates the information for a given DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {IDMACategoryInformation} iDMACategoryInformation Used to update the information of a DMA category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApiInterface
   */
  updateDmaCategoryInformation(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iDMACategoryInformation: IDMACategoryInformation,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDMACategory>;

  /**
   * Updates a single DMA category.
   * @summary Updates a single DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
   * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
   * @param {string} dmaCategoryId The id of the category to update.
   * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApiInterface
   */
  updateEsrsCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iUpdateDMACategoryRequest: IUpdateDMACategoryRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDMACategory>;
}

/**
 * DoubleMaterialityAssessmentCategoryApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentCategoryApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentCategoryApi
  extends BaseAPI
  implements DoubleMaterialityAssessmentCategoryApiInterface
{
  /**
   * Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
   * @summary Adds a category to the given ESRS topic. If a parent is specified, the tree is added as a child of the parent. Otherwise, it will be added to the root node.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {ICreateDMACategoryRequest} iCreateDMACategoryRequest Used to create a new DMA category, optionally as a sub-category of an already existing one.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApi
   */
  public addEsrsCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    iCreateDMACategoryRequest: ICreateDMACategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryApiFp(this.configuration)
      .addEsrsCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        iCreateDMACategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes a single DMA category.
   * @summary Deletes a single DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to delete the category.
   * @param {string} esrsTopicId The id of the ESRS topic to delete the category for.
   * @param {string} dmaCategoryId The id of the category to delete.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApi
   */
  public deleteEsrsCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryApiFp(this.configuration)
      .deleteEsrsCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Frees the user from having to specify effects for the specified data entry object and DMA category.
   * @summary Frees the user from having to specify effects for the specified data entry object and DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
   * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
   * @param {string} dmaCategoryId The id of the category to opt out of.
   * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApi
   */
  public optOutOfDmaCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryApiFp(this.configuration)
      .optOutOfDmaCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iOptOutOfDmaCategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the information for a given DMA category.
   * @summary Updates the information for a given DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {IDMACategoryInformation} iDMACategoryInformation Used to update the information of a DMA category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApi
   */
  public updateDmaCategoryInformation(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iDMACategoryInformation: IDMACategoryInformation,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryApiFp(this.configuration)
      .updateDmaCategoryInformation(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iDMACategoryInformation,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates a single DMA category.
   * @summary Updates a single DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to update the category.
   * @param {string} esrsTopicId The id of the ESRS topic to update the category for.
   * @param {string} dmaCategoryId The id of the category to update.
   * @param {IUpdateDMACategoryRequest} iUpdateDMACategoryRequest Used to update a DMA category.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryApi
   */
  public updateEsrsCategory(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iUpdateDMACategoryRequest: IUpdateDMACategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryApiFp(this.configuration)
      .updateEsrsCategory(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iUpdateDMACategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
