import * as React from "react";
import { useCallback, useMemo } from "react";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormHelperText, ListSubheader } from "@mui/material";

// From https://mui.com/material-ui/react-select/#multiple-select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
type SelectItemValue = string;
type SelectItemLabel = string;

export type GetSelectItemValue<T> = (item: T) => SelectItemValue;
export type GetSelectItemLabel<T> = (item: T) => SelectItemLabel;
export type ToSelectItem<T> = (item: T) => ISelectItem;

interface ISelectItem {
  label: SelectItemLabel;
  value: SelectItemValue;
}

interface IDisplayConfigSimple<T> {
  type: "simple";
  toSelectItem: ToSelectItem<T>;
}

interface IDisplayConfigNested<T> {
  type: "nested";
  getHeading: GetSelectItemLabel<T>;
  getChildren: (item: T) => ISelectItem[];
}

type IDisplayConfig<T> = IDisplayConfigSimple<T> | IDisplayConfigNested<T>;

interface IMultiSelectComponentProps<T> {
  options: T[];
  selectedOptions: SelectItemValue[];
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  shrinkInputLabel?: boolean;
  label: string;
  config: IDisplayConfig<T>;
  onValuesChange: (newValues: SelectItemValue[]) => void;
  required?: boolean;
  sx?: SelectProps["sx"];
  size?: SelectProps["size"];
}

export const MultiSelectComponent = <T,>({
  options,
  errorMessage,
  error,
  shrinkInputLabel,
  selectedOptions,
  label,
  onValuesChange,
  disabled,
  required,
  config,
  sx,
  size,
}: IMultiSelectComponentProps<T>) => {
  const lookup = useMemo(() => {
    if (config.type === "simple") {
      return options.reduce((acc, curr) => {
        const selectItem = config.toSelectItem(curr);
        return { ...acc, [selectItem.value]: selectItem };
      }, {} as Record<string, ISelectItem>);
    }
    return options
      .flatMap((o) => config.getChildren(o))
      .reduce((acc, curr) => ({ ...acc, [curr.value]: curr }), {} as Record<string, ISelectItem>);
  }, [config, options]);

  const generateOption = useCallback(
    (item: ISelectItem) => (
      <MenuItem key={item.value} value={item.value}>
        <Checkbox checked={selectedOptions.indexOf(item.value) > -1} />
        <ListItemText primary={item.label} />
      </MenuItem>
    ),
    [selectedOptions],
  );

  const generatedOptions = useMemo(() => {
    if (config.type === "nested") {
      return options.map((option) => {
        const children = config.getChildren(option);

        if (children.length === 0) {
          return [];
        }

        return [
          <ListSubheader>{config.getHeading(option)}</ListSubheader>,
          ...config.getChildren(option).map((i) => generateOption(i)),
        ];
      });
    }

    return options.map((option) => config.toSelectItem(option)).map((i) => generateOption(i));
  }, [config, generateOption, options]);

  return (
    <FormControl fullWidth error={!!error} disabled={disabled} required={required}>
      <InputLabel shrink={shrinkInputLabel || undefined}>{label}</InputLabel>
      <Select
        sx={sx}
        size={size}
        value={selectedOptions}
        notched={shrinkInputLabel || undefined}
        label={label}
        error={error}
        multiple
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.map((s) => lookup[s].label ?? "").join(", ")}
        MenuProps={MenuProps}
        onChange={({ target: { value } }: SelectChangeEvent<string[]>) => {
          const newValue = typeof value === "string" ? value.split(",") : value;
          onValuesChange(newValue);
        }}
      >
        {generatedOptions}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};
