import { ReactNode } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckboxProps } from "@mui/material/Checkbox/Checkbox";

interface ICheckboxWithArrayValueProps<T> {
  value: T[];
  checkboxValue: T;
  onChange: (value: T[]) => void;
  checkboxProps?: CheckboxProps;
  disabled?: boolean;
}

export function CheckboxWithArrayValue<T>({
  value,
  checkboxValue,
  onChange,
  checkboxProps,
  disabled,
}: ICheckboxWithArrayValueProps<T>): ReactNode {
  return (
    <Checkbox
      onChange={(_, checked) => {
        if (checked) {
          onChange([...value, checkboxValue]);
        } else {
          onChange(value.filter((value) => value !== checkboxValue));
        }
      }}
      checked={value.includes(checkboxValue)}
      disabled={disabled}
      {...checkboxProps}
    />
  );
}

interface ICheckboxWithArrayValueAndLabelProps<T> extends ICheckboxWithArrayValueProps<T> {
  label: string;
}

export function CheckboxWithArrayValueAndLabel<T>({
  label,
  ...cbProps
}: ICheckboxWithArrayValueAndLabelProps<T>): ReactNode {
  return <FormControlLabel label={label} control={<CheckboxWithArrayValue {...cbProps} />} />;
}
