import { Icon } from "@iconify/react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";
import { IDataEntryObjectData, IOrganization } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { DataEntryObjectsAppUtilities } from "../../data-entry-objects/data-entry-objects-app.utilities";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { useInputParameterRecordingStructureInputParametersQuery } from "../../recording-structures/recording-structures.queries";
import { DataEntryObjectInputParametersSelectGroupComponent } from "../components/data-entry-object-input-parameters-select-group.component";
import {
  DataEntryObjectInputParametersUtilities,
  IDataEntryObjectRecordingInputParameter,
} from "../data-entry-object-input-parameters.utilities";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import { useUpdateInputParameterConfigurationMutation } from "../data-entry-object-input-parameters.mutations";
import { ILocalInputParameterRecordingStructureTHG } from "../../input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import { CenteredCircularProgress } from "../../common/components/centered-circular-progress.component";
import { DialogCloseButton } from "../../common/dialogs/dialog-button.components";

interface IDataEntryObjectInputParametersDialogProps {
  open: boolean;
  onClose: () => void;
  organization: IOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  recordingStructure: ILocalInputParameterRecordingStructureTHG;
  dataEntryObject: IDataEntryObjectData;
  dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[];
}

export const DataEntryObjectInputParametersDialog: FC<
  IDataEntryObjectInputParametersDialogProps
> = ({
  open,
  onClose,
  organization,
  dataEntryObject,
  recordingPeriod,
  recordingStructure,
  dataEntryObjectInputParameters,
}) => {
  const { t } = useTranslation("data_entry_object_input_parameters_dialog");

  const recordingStructureInputParametersQuery =
    useInputParameterRecordingStructureInputParametersQuery(
      organization.id,
      recordingPeriod.id,
      recordingStructure.id,
    );

  const structureWithInputParameters = useMemo(() => {
    if (recordingStructureInputParametersQuery.data) {
      const inputParameterModelInputParameters =
        recordingStructureInputParametersQuery.data.inputParameters;

      return DataEntryObjectInputParametersUtilities.hydrateStructureWithDataEntryObjectInputParameterSettings(
        recordingStructure.structure.rootSustainabilityCategory,
        dataEntryObjectInputParameters,
        inputParameterModelInputParameters,
      );
    }
    return null;
  }, [
    recordingStructureInputParametersQuery.data,
    recordingStructure,
    dataEntryObjectInputParameters,
  ]);

  const updateInputParameterConfigurationMutation = useUpdateInputParameterConfigurationMutation();

  const handleUpdateDataEntryObjectRecordingConfiguration = async (
    newInputParameterConfigurations:
      | IDataEntryObjectRecordingInputParameter[]
      | IDataEntryObjectRecordingInputParameter,
  ) => {
    await updateInputParameterConfigurationMutation.mutateAsync({
      updates: newInputParameterConfigurations,
      organizationId: organization.id,
      recordingPeriodId: recordingPeriod.id,
      dataEntryObjectId: dataEntryObject.id,
      recordingStructureId: recordingStructure.id,
    });
  };

  const handleClose = () => {
    if (updateInputParameterConfigurationMutation.isPending) {
      return;
    }
    onClose();
  };

  useResetMutationsOnOpen(open, updateInputParameterConfigurationMutation);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Icon
            icon={DataEntryObjectsAppUtilities.getIconForObjectType(dataEntryObject.objectType)}
            height={12}
          />
          <Box marginLeft={1}>
            <Typography>{dataEntryObject.name}</Typography>
          </Box>
        </Box>
        {t("dialog_title")}
      </DialogTitle>
      <DialogContent>
        {updateInputParameterConfigurationMutation.isError && (
          <ErrorTextComponent error={updateInputParameterConfigurationMutation.error} />
        )}
        <QueryWrapper
          query={recordingStructureInputParametersQuery}
          loadingOverride={() => <CenteredCircularProgress />}
          build={() => (
            <DataEntryObjectInputParametersSelectGroupComponent
              inputParametersGroup={structureWithInputParameters!}
              onChange={handleUpdateDataEntryObjectRecordingConfiguration}
              disabled={updateInputParameterConfigurationMutation.isPending}
              isRootStructure
            />
          )}
        />
      </DialogContent>
      {updateInputParameterConfigurationMutation.isPending && <LinearProgress sx={{ mt: -0.5 }} />}
      <DialogActions>
        <DialogCloseButton
          onClick={handleClose}
          disabled={updateInputParameterConfigurationMutation.isPending}
        >
          {t("close_dialog")}
        </DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};
