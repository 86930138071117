import { ITargetPath } from "@netcero/netcero-core-api-client";
import {
  ITargetPathLabels,
  ResultPerCalculationKey,
  ScopeKeys,
  TargetPathCalculations,
  TargetPathGoalsCalculations,
  TargetPathUtilities,
} from "@netcero/netcero-common";
import { BarSeriesOption, LineSeriesOption } from "echarts/charts";
import { CommonAxisPointerOption, OptionDataValue } from "echarts/types/src/util/types";
import { EvaluationFormatUtilities } from "../../evaluation/utilities/evaluation-format.utilities";
import { TargetPathValueGetter, TargetPathValueGetterSum } from "./target-path-value-getter";

type LabelsPerScope = { [key in ScopeKeys]: string };

export const TargetPathDiagramAxisPointerLabel: CommonAxisPointerOption["label"] = {
  backgroundColor: "#6a7985",
  formatter: (params): string => {
    if (params.axisDimension === "x" && typeof params.value === "string") {
      return params.value;
    }
    return EvaluationFormatUtilities.formatCO2TonValueUnicode(params.value as number);
  },
};

export const TargetPathDiagramValueFormatter: (
  value: OptionDataValue | OptionDataValue[],
  dataIndex: number,
) => string = (value) => EvaluationFormatUtilities.formatCO2TonValueUnicode(value as number);

export class TargetPathDiagramUtilities {
  private static capValueAtZero(value: number) {
    return value < 0 ? 0 : value;
  }

  public static generateLabels(...targetPaths: ITargetPath[]): ITargetPathLabels {
    if (targetPaths.length === 0) {
      return { referenceYear: 0, years: [], offsetToReferenceYear: {} };
    }

    const minReferenceYear = Math.min(...targetPaths.map((t) => t.referenceBudget.referenceYear));
    const maxGoalYear = TargetPathUtilities.getMaxGoalYear(targetPaths);

    const offsetToReferenceYear: Record<string, number> = targetPaths.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.referenceBudget.referenceYear - minReferenceYear }),
      {} as Record<string, number>,
    );

    return {
      referenceYear: minReferenceYear,
      years: TargetPathCalculations.generateNumbersBetween(minReferenceYear, maxGoalYear),
      offsetToReferenceYear,
    };
  }

  public static constructSeriesPerScopeForTargetPath(
    targetPath: ITargetPath,
    stackName: string,
    offset: number,
    labelsPerScope: LabelsPerScope,
  ): LineSeriesOption[] {
    const emissionsForGoals = targetPath.goals.map((g) => ({
      emissions: TargetPathGoalsCalculations.convertGoalToEmissions(g, targetPath.referenceBudget),
      index: g.targetYear - targetPath.referenceBudget.referenceYear + offset,
    }));

    const constructDataForScope = (scope: ScopeKeys): LineSeriesOption => {
      return {
        type: "line",
        name: labelsPerScope[scope],
        stack: stackName,
        areaStyle: {},
        data: [
          [offset, targetPath.referenceBudget.calculatedValues.sumsPerScope[scope] ?? 0],
          ...emissionsForGoals.map((eg) => [
            eg.index,
            this.capValueAtZero(eg.emissions.perScope[scope]),
          ]),
        ],
      };
    };

    return [
      constructDataForScope(ScopeKeys.Scope1),
      constructDataForScope(ScopeKeys.Scope2),
      constructDataForScope(ScopeKeys.Scope3),
    ];
  }

  public static constructSingleSeriesForValueOfTargetPath(
    targetPath: ITargetPath,
    offset: number,
    translationForOrganizationPath: string,
    valueGetter: TargetPathValueGetter = TargetPathValueGetterSum,
  ): LineSeriesOption[] {
    const emissionsForGoals = targetPath.goals.map((g) => ({
      emissions: valueGetter({
        type: "forTargetGoal",
        emissions: TargetPathGoalsCalculations.convertGoalToEmissions(
          g,
          targetPath.referenceBudget,
        ),
      }),
      index: g.targetYear - targetPath.referenceBudget.referenceYear + offset,
    }));

    return [
      {
        type: "line",
        name: translationForOrganizationPath,
        data: [
          [
            offset,
            valueGetter({
              type: "forReferenceBudget",
              emissions: targetPath.referenceBudget.calculatedValues,
            }),
          ],
          ...emissionsForGoals.map((eg) => [eg.index, this.capValueAtZero(eg.emissions)]),
        ],
      },
    ];
  }

  public static constructSeriesForProgress(
    emissionsPerYear: Record<number, ResultPerCalculationKey>,
    labelsPerScope: LabelsPerScope,
    stackName: string,
    referenceYear: number,
  ) {
    const constructDataForScope = (scope: ScopeKeys): BarSeriesOption => {
      return {
        type: "bar",
        name: labelsPerScope[scope],
        stack: stackName,
        barMaxWidth: 15,
        data: Object.entries(emissionsPerYear).map(([key, value]) => {
          const numberKey = parseInt(key);
          return [numberKey - referenceYear, this.capValueAtZero(value[scope] ?? 0)];
        }),
      };
    };

    return [
      constructDataForScope(ScopeKeys.Scope1),
      constructDataForScope(ScopeKeys.Scope2),
      constructDataForScope(ScopeKeys.Scope3),
    ];
  }
}
