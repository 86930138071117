import { Location } from "@remix-run/router/history";

export class RouterUtilities {
  /**
   * Reads the UUID from the location fragment.
   * This wrapper is necessary to avoid the keycloak fragment information in the location.
   * @param location
   */
  public static readUuidFromLocationFragment(location: Location): string | null {
    return location.hash ? location.hash.slice(1, 37) : null;
  }
}
