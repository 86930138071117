import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface IResult {
  translation: string;
  multiplyBy: number;
}

/**
 * This function is passed the reduction - if the reduction is negative, it is an increase!
 * @param value
 */
export const useReductionTerm = (value: number) => {
  const { t } = useTranslation("target_path_general", { keyPrefix: "terms" });
  return useMemo((): IResult => {
    return value > 0
      ? { translation: t("reduction"), multiplyBy: 1 }
      : {
          translation: t("increase"),
          multiplyBy: -1,
        };
  }, [t, value]);
};
