import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../components/error-text.component";
import { DialogCloseButton } from "../dialog-button.components";
import { IDialogWithError } from "../types/common.types";

interface IErrorDialogProps extends IDialogWithError {
  open: boolean;
  onClose: () => void;
}

export const ErrorDialog: FC<IErrorDialogProps> = ({ open, onClose, error }) => {
  const { t } = useTranslation("error_dialog");

  // Use internal Error to not hide Error during close animation
  const [internalError, setInternalError] = useState(error);
  useEffect(() => {
    if (error) {
      setInternalError(error);
    }
  }, [error]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("dialog_title")}</DialogTitle>
      <DialogContent>{internalError && <ErrorTextComponent error={internalError} />}</DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={onClose}>{t("button_close")}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};
