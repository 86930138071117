import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { useOrganizations } from "./organizations.hook";

export const useCurrentOrganization = () => {
  const organizations = useOrganizations();

  const { organizationId } = useParams();

  return useMemo(() => {
    return organizations.find((organization) => organization.id === organizationId) ?? null;
  }, [organizations, organizationId]);
};
