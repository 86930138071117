import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { SourcesEditDialog } from "../sources-edit.dialog";
import {
  IBaseSourceData,
  IOrganization,
  ISource,
  ISourceFile,
} from "@netcero/netcero-core-api-client";
import {
  useCreateSourceMutation,
  useDeleteSourceMutation,
  useEditSourceMutation,
} from "../sources.mutations";
import { SourceEditListComponent } from "./sources-list.component";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import {
  useCreateSourceFileMutation,
  useDeleteSourceFileMutation,
} from "../../source-files/source-files.mutations";
import {
  multipleFileToCreateSourceFilesRequest,
  UploadDialogComponent,
} from "../../source-files/source-files.dialog";
import { downloadSourceFile } from "../sources.queries";
import { useLocation, useNavigate } from "react-router-dom";
import { RouterUtilities } from "../../common/utilities/router.utilities";
import { AllowedMimeTypes } from "@netcero/netcero-common";
import { ITranslatableError } from "../../common/hooks/translate-error.hook";

export interface ISourcesConfigurationComponentProps {
  organization: IOrganization;
  sources: ISource[];
}

export const SourcesConfigurationComponent: FC<ISourcesConfigurationComponentProps> = ({
  sources,
  organization,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const focusedSourceId = useMemo(
    () => RouterUtilities.readUuidFromLocationFragment(location),
    [location],
  );

  const { t } = useTranslation("sources_configuration_component");

  const [downloadError, setDownloadError] = useState<Error | null>(null);
  const [mimeTypeError, setMimeTypeError] = useState<ITranslatableError | null>(null);

  const createSourceMutation = useCreateSourceMutation();
  const editSourceMutation = useEditSourceMutation();
  const deleteSourceMutation = useDeleteSourceMutation();
  const createSourceFileMutation = useCreateSourceFileMutation();
  const deleteSourceFileMutation = useDeleteSourceFileMutation();

  const resetMutations = useCallback(() => {
    createSourceMutation.reset();
    editSourceMutation.reset();
    deleteSourceMutation.reset();
    createSourceFileMutation.reset();
    deleteSourceFileMutation.reset();
    setDownloadError(null);
    setMimeTypeError(null);
  }, [
    createSourceMutation,
    editSourceMutation,
    deleteSourceMutation,
    createSourceFileMutation,
    deleteSourceFileMutation,
  ]);

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();

  const {
    isOpen: isEditOpen,
    openDialog: openEdit,
    updateDialogData: updateEditDialogData,
    closeDialog: closeEdit,
    data: editDialogData,
  } = useDialogState<ISource>();
  useEffect(() => {
    if (editDialogData !== undefined && isEditOpen) {
      const sourceBeingEdited = sources.find((source) => source.id === editDialogData.id);
      if (sourceBeingEdited) {
        updateEditDialogData(sourceBeingEdited);
      }
    }
    // This is fine since we only want to trigger this effect on sources (API response/data) changing
    // eslint-disable-next-line
  }, [sources]);

  const {
    isOpen: isDeleteOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    data: deleteDialogData,
  } = useDialogState<ISource>();

  const {
    isOpen: isUploadOpen,
    openDialog: openUploadDialog,
    closeDialog: closeUploadDialog,
    data: uploadDialogData,
    updateDialogData,
  } = useDialogState<ISource>();

  // Scroll to viewed source and open edit dialog
  useEffect(() => {
    if (!focusedSourceId) {
      return;
    }

    const viewedSource = sources.find((s) => s.id === focusedSourceId);
    if (!viewedSource) {
      return;
    }
    document.getElementById(focusedSourceId)?.scrollIntoView({ behavior: "smooth" });
    openEdit(viewedSource);
    // This is fine since effect should only trigger on focusedSourceId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedSourceId]);

  useEffect(() => {
    if (uploadDialogData !== undefined && isUploadOpen) {
      const uploadedSource = sources.find((source) => source.id === uploadDialogData.id)!;
      updateDialogData(uploadedSource);
    }
  }, [uploadDialogData, isUploadOpen, sources, updateDialogData]);

  const isLoading = createSourceMutation.isPending;

  const handleCreate = useCallback(
    async (data: IBaseSourceData | null, uploadFiles: boolean) => {
      if (data !== null) {
        const response = await createSourceMutation.mutateAsync({
          organizationId: organization.id,
          payload: data,
        });
        if (uploadFiles) {
          openEdit(response);
          openUploadDialog(response);
        }
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createSourceMutation, organization.id, openEdit, openUploadDialog],
  );

  const handleEdit = useCallback(
    async (source: IBaseSourceData | null) => {
      if (source !== null && editDialogData !== undefined) {
        await editSourceMutation.mutateAsync({
          organizationId: organization.id,
          sourceId: editDialogData.id,
          payload: source,
        });
      }
      closeEdit();
      navigate(location.pathname, { replace: true });
    },
    [editDialogData, closeEdit, navigate, location.pathname, editSourceMutation, organization.id],
  );

  const handleDelete = useCallback(
    async (confirm: boolean) => {
      if (confirm && deleteDialogData !== undefined) {
        await deleteSourceMutation.mutateAsync({
          organizationId: organization.id,
          sourceId: deleteDialogData.id!,
        });
      }
      closeDeleteDialog();
    },
    [deleteSourceMutation, deleteDialogData, closeDeleteDialog, organization.id],
  );

  const handleMultipleUpload = useCallback(
    async (files: FileList) => {
      if (!uploadDialogData) {
        return;
      }
      // Verify that all files have a valid mime type
      for (let i = 0; i < files.length; i++) {
        if (!Object.values(AllowedMimeTypes).includes(files[i].type as AllowedMimeTypes)) {
          setMimeTypeError({
            errorCode: "source_file.invalid_mime_type",
          });
          return;
        }
      }

      await createSourceFileMutation.mutateAsync({
        organizationId: organization.id,
        id: uploadDialogData.id,
        payload: multipleFileToCreateSourceFilesRequest(files),
        files: files,
      });
    },
    [createSourceFileMutation, uploadDialogData, organization.id],
  );

  const handleDeleteFile = useCallback(
    async (file: ISourceFile) => {
      if (uploadDialogData !== undefined) {
        await deleteSourceFileMutation.mutateAsync({
          organizationId: organization.id,
          id: uploadDialogData.id,
          payload: {
            fileIds: [file.id],
          },
        });
      }
    },
    [deleteSourceFileMutation, uploadDialogData, organization.id],
  );

  async function handleFileDownload(sourceFile: ISourceFile): Promise<void> {
    // TODO: Refactor this to use mutation or lazy query instead of direct API call (to ease error handling)
    try {
      const response = await downloadSourceFile(
        organization.id,
        sourceFile.sourceId,
        sourceFile.id,
      );

      window.open(response.downloadLink, "download");
    } catch (error) {
      console.error("Failed to download file:", error);
      setDownloadError(error as Error);
    }
  }

  return (
    <Box>
      <SourcesEditDialog
        open={isCreateOpen}
        loading={createSourceMutation.isPending}
        onClose={handleCreate}
        error={createSourceMutation.error}
        disabled={isLoading}
      />

      {(editDialogData && isEditOpen) !== undefined && (
        <SourcesEditDialog
          open={isEditOpen}
          loading={editSourceMutation.isPending}
          onClose={handleEdit}
          error={editSourceMutation.error}
          disabled={editSourceMutation.isPending}
          editDialogData={editDialogData ?? null}
          onOpenFileDialog={() => openUploadDialog(editDialogData)}
        />
      )}

      {/* The actual page content */}
      <Box display="flex" flexDirection="column" gap={2} p={2}>
        {/* Create Button */}
        <Button
          variant="contained"
          onClick={() => {
            resetMutations();
            openCreateDialog();
          }}
          sx={{ mr: "auto" }}
        >
          {t("buttons.create")}
        </Button>

        {/* Delete Confirm Dialog */}
        <ConfirmDialogTextBody
          open={isDeleteOpen}
          error={deleteSourceMutation.error}
          loading={deleteSourceMutation.isPending}
          disabled={deleteSourceMutation.isPending}
          onClose={handleDelete}
          title={t("confirm.title")}
          content={t("confirm.body")}
        />

        {/* Upload Dialog */}
        {uploadDialogData && (
          <UploadDialogComponent
            open={isUploadOpen}
            error={
              createSourceFileMutation.error ||
              deleteSourceFileMutation.error ||
              downloadError ||
              mimeTypeError
            }
            createSourceFilePending={createSourceFileMutation.isPending}
            deleteSourceFilePending={deleteSourceFileMutation.isPending}
            disabled={isLoading}
            onClose={closeUploadDialog}
            onFileDelete={handleDeleteFile}
            onMultipleFileUpload={handleMultipleUpload}
            text={{
              buttons: {
                close: t("close", { ns: "buttons" }),
              },
            }}
            source={uploadDialogData}
            handleFileDownload={handleFileDownload}
          />
        )}

        {sources.length === 0 ? (
          <Typography>{t("empty_notice")}</Typography>
        ) : (
          <SourceEditListComponent
            sources={sources}
            onEdit={(source) => {
              resetMutations();
              openEdit(source);
            }}
            onDelete={(source) => {
              resetMutations();
              openDeleteDialog(source);
            }}
            onUpload={(source) => {
              resetMutations();
              openUploadDialog(source);
            }}
          />
        )}
      </Box>
    </Box>
  );
};
