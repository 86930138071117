export class StakeholderLinksUtilities {
  private static get BaseUrl() {
    return window.location.origin;
  }

  public static getStakeholderFeedbackLink(stakeholderId: string, recordingPeriodId?: string) {
    const url = new URL(
      `${StakeholderLinksUtilities.BaseUrl}/stakeholder-feedbacks/${stakeholderId}`,
    );
    if (recordingPeriodId !== undefined) {
      url.searchParams.set("recordingPeriodId", recordingPeriodId);
    }
    return url.toString();
  }
}
