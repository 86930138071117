import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { ReferenceBudgetUtilities } from "@netcero/netcero-common";
import { useCallback } from "react";
import { IRecordingPeriodData } from "@netcero/netcero-core-api-client";

export const useMergeRecordingPeriods = (recordingPeriodsToMergeInto: ILocalRecordingPeriod[]) =>
  useCallback(
    (recordingPeriodsToMerge: Record<string, IRecordingPeriodData>) => {
      const cloned = recordingPeriodsToMergeInto.map((rp) => ({
        ...rp,
        startDate: new Date(rp.startDate.getTime()),
        endDate: new Date(rp.endDate.getTime()),
      }));
      ReferenceBudgetUtilities.mergeValuesIntoRecordingPeriods(cloned, recordingPeriodsToMerge);
      return cloned;
    },
    [recordingPeriodsToMergeInto],
  );
