import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { LinkedSourcesChipsListEditor } from "./linked-sources-chips-list-editor.component";
import { useTranslation } from "react-i18next";

interface ILinkedSourcesDialogInputFormData {
  sourceIds: string[];
}

interface ILinkedSourcesDialogInputProps {
  organizationId: string;
  disabled: boolean | undefined;
}

/**
 * Reusable Component for Linked Sources Dialog Input. Expects sources to be stored in `sourceIds` field. Internally makes use of useFormContext.
 * @param organizationId
 * @param disabled
 * @constructor
 */
export const LinkedSourcesDialogInput: FC<ILinkedSourcesDialogInputProps> = ({
  organizationId,
  disabled,
}) => {
  const { t } = useTranslation("linked_sources");

  const { control } = useFormContext<ILinkedSourcesDialogInputFormData>();

  return (
    <Box>
      <Typography variant="subtitle1" mb={0.75}>
        {t("labels.linked_sources")}
      </Typography>
      <Controller
        control={control}
        name="sourceIds"
        render={({ field }) => (
          <LinkedSourcesChipsListEditor
            organizationId={organizationId}
            sourceIds={field.value}
            onChange={field.onChange}
            noLinkedSourcesText={t("no_linked_sources")}
            addButtonTooltip={t("add_linked_source_tooltip")}
            disabled={disabled}
          />
        )}
      />
    </Box>
  );
};
