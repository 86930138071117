/* eslint-disable */
import {
  IBaseTargetPathGoalData,
  ITargetPath,
  ITargetPathGoalTemplateType,
  ITargetPathGoalValueChange,
} from "@netcero/netcero-core-api-client";
import { SumPerScope } from "../reference-budgets/reference-budget.types";
import { ScopeKeys } from "../emissions";
import { alwaysArray } from "../common";

const PercentagesForScopes: { [key in ScopeKeys]: number } = {
  [ScopeKeys.Scope1]: 0.042,
  [ScopeKeys.Scope2]: 0.042,
  [ScopeKeys.Scope3]: 0.025,
};

const MaxReductionInPercent = 0.9;

const GoalYearsWithCalculatedPercentages = [2030, 2040] as const;
const GoalYearsWithAbsolutePercentages = [2050] as const;

export class TargetPathUtilities {
  public static generateGoalsForGoalTemplateAndReferenceScopes(
    goal: ITargetPathGoalTemplateType,
    sums: SumPerScope,
    referenceYear: number,
  ): IBaseTargetPathGoalData[] {
    switch (goal) {
      case ITargetPathGoalTemplateType.ParisConform:
        return this.generateParisGoalsForReferenceScopes(sums, referenceYear);
    }
  }

  private static generateParisGoalsForReferenceScopes(
    sums: SumPerScope,
    referenceYear: number,
  ): IBaseTargetPathGoalData[] {
    const getValueForScope = (scope: ScopeKeys): number | undefined => sums[scope];

    const mapScopeIfNotEmpty = (
      scope: ScopeKeys,
      mapper: (scope: ScopeKeys, value: number) => ITargetPathGoalValueChange,
    ): ITargetPathGoalValueChange => {
      const valueForScope = getValueForScope(scope);

      if (valueForScope === undefined) {
        return {
          type: "byValue",
          value: 0,
        };
      }

      return mapper(scope, valueForScope);
    };

    const getValueChangeForYearWithCalculatedPercentage = (
      scope: ScopeKeys,
      targetYear: number,
    ): ITargetPathGoalValueChange =>
      mapScopeIfNotEmpty(scope, (scope) => ({
        type: "inPercent",
        value: Math.min(
          // if calculated reduction is bigger than the max reduction, cap at max reduction
          PercentagesForScopes[scope] * (targetYear - referenceYear),
          MaxReductionInPercent,
        ),
      }));

    const getValueChangeForYearWithAbsolutePercentage = (
      scope: ScopeKeys,
    ): ITargetPathGoalValueChange =>
      mapScopeIfNotEmpty(scope, (scope) => ({
        value: MaxReductionInPercent,
        type: "inPercent",
      }));

    return [
      ...GoalYearsWithCalculatedPercentages.map((gy) => ({
        targetYear: gy,
        targetValues: {
          type: "absolute" as const,
          reductionScope1: getValueChangeForYearWithCalculatedPercentage(ScopeKeys.Scope1, gy),
          reductionScope2: getValueChangeForYearWithCalculatedPercentage(ScopeKeys.Scope2, gy),
          reductionScope3: getValueChangeForYearWithCalculatedPercentage(ScopeKeys.Scope3, gy),
        },
      })),
      ...GoalYearsWithAbsolutePercentages.map((gy) => ({
        targetYear: gy,
        targetValues: {
          type: "absolute" as const,
          reductionScope1: getValueChangeForYearWithAbsolutePercentage(ScopeKeys.Scope1),
          reductionScope2: getValueChangeForYearWithAbsolutePercentage(ScopeKeys.Scope2),
          reductionScope3: getValueChangeForYearWithAbsolutePercentage(ScopeKeys.Scope3),
        },
      })),
    ].filter(({ targetYear }) => targetYear >= referenceYear);
  }

  public static getMaxGoalYear(targetPath: ITargetPath | ITargetPath[]): number {
    const targetPathArray = alwaysArray(targetPath);
    const goals = targetPathArray.flatMap((t) => t.goals);

    if (goals.length === 0) {
      return -1;
    }

    return Math.max(...goals.map((g) => g.targetYear));
  }
}
