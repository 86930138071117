import { Predicate } from "../interfaces/predicate.type";

type GetPropertyOfTypeCallback<T, U> = (node: T) => U;
export type GetChildrenCallback<T> = GetPropertyOfTypeCallback<T, T[]>;

export class TreeUtilities {
  /**
   * Gets the path of tree elements from the root to the selected child.
   * Returns null if the child is not found.
   * @param tree the tree to look for the child in
   * @param getChildren a function that returns the children of the node
   * @param isSelectedChild a function that returns true if the child is the selected child
   * @returns the path of tree elements from the root to the selected child
   */
  public static getPathToTreeChild<T>(
    tree: T,
    getChildren: (node: T) => T[],
    isSelectedChild: Predicate<T>,
  ): T[] | null {
    if (isSelectedChild(tree)) {
      return [tree];
    }

    for (const child of getChildren(tree)) {
      const result = TreeUtilities.getPathToTreeChild(child, getChildren, isSelectedChild);

      if (result) {
        return [tree, ...result];
      }
    }

    return null;
  }

  /**
   * Gets the path of tree elements from the root to the selected child.
   * @param trees the trees to look for the child in
   * @param getChildren a function that returns the children of the node
   * @param isSelectedChild a function that returns true if the child is the selected child
   */
  public static getPathToTreeChildMultiple<T>(
    trees: T[],
    getChildren: (node: T) => T[],
    isSelectedChild: Predicate<T>,
  ) {
    for (const tree of trees) {
      const result = TreeUtilities.getPathToTreeChild(tree, getChildren, isSelectedChild);
      if (result !== null) {
        return result;
      }
    }

    return null;
  }

  /**
   * Gets the selected child in the tree.
   * @param tree the tree to look for the child in
   * @param getChildren a function that returns the children of the node
   * @param isSelectedChild a function that returns true if the child is the selected child
   * @returns the selected child or null if the child is not found
   */
  public static getChildInTree<T>(
    tree: T,
    getChildren: (node: T) => T[],
    isSelectedChild: Predicate<T>,
  ): T | null {
    if (isSelectedChild(tree)) {
      return tree;
    }

    for (const child of getChildren(tree)) {
      const result = TreeUtilities.getChildInTree(child, getChildren, isSelectedChild);

      if (result) {
        return result;
      }
    }

    return null;
  }

  /**
   * Converts a tree to a flat array.
   * @param tree The tree to convert to a flat array
   * @param getChildren A function that returns the children of the node
   * @param map The function to map the tree item to the flat array item
   * @param level The current level of the tree (initially 0)
   * @returns The flat array
   */
  public static flatMap<T, U>(
    tree: T,
    getChildren: GetChildrenCallback<T>,
    map: (item: T, level: number) => U,
    level = 0,
  ): U[] {
    const result: U[] = [];

    result.push(map(tree, level));

    for (const child of getChildren(tree)) {
      result.push(...TreeUtilities.flatMap(child, getChildren, map, level + 1));
    }

    return result;
  }

  /**
   * Converts multiple trees to a flat array. Wrapper around flatMap.
   * @param trees The trees to convert to a flat array
   * @param getChild A function that returns the children of the node
   * @param map The function to map the tree item to the flat array item
   * @param level The current level of the tree (initially 0)
   */
  public static flatMapMultiple<T, U>(
    trees: T[],
    getChild: GetChildrenCallback<T>,
    map: (item: T, level: number) => U,
    level = 0,
  ): U[] {
    const result: U[] = [];

    for (const child of trees) {
      result.push(...TreeUtilities.flatMap(child, getChild, map, level));
    }

    return result;
  }

  /**
   * Counts the number of nodes in a tree.
   * @param tree The tree to count the nodes in
   * @param getChildren A function that returns the children of the node
   */
  public static countNodes<T>(tree: T, getChildren: GetChildrenCallback<T>): number {
    return TreeUtilities.flatMap(tree, getChildren, (c) => c).length;
  }

  public static createRecursiveLookup<T, K extends string | number | symbol = string>(
    item: T,
    getId: GetPropertyOfTypeCallback<T, K>,
    getChildren: GetChildrenCallback<T>,
    lookup: Record<K, T> = {} as Record<K, T>,
  ): Record<K, T> {
    lookup[getId(item)] = item;

    for (const child of getChildren(item)) {
      TreeUtilities.createRecursiveLookup(child, getId, getChildren, lookup);
    }

    return lookup;
  }

  /**
   * Returns an array of all IDs from a tree structure.
   * @param tree The array of tree nodes to traverse.
   * @returns Array of all IDs in the tree.
   */
  public static getAllIds<T extends { id: string }>(
    trees: T[],
    getChildren: GetChildrenCallback<T>,
  ): string[] {
    const ids = trees.flatMap((tree) =>
      TreeUtilities.flatMap(tree, getChildren, (node) => node.id),
    );

    return ids;
  }
}
