import { FC } from "react";
import { Icon } from "@iconify/react";

interface IEvaluationIconComponentProps {
  isEvaluation: boolean;
  size?: string;
}

// TODO: Migrate to tabler icons or put icons in iconify constants file or use svgs
export const EvaluationIcon: FC<IEvaluationIconComponentProps> = ({
  isEvaluation,
  size = "2rem",
}) => {
  const icon = isEvaluation ? "mdi:gas-co2" : "iconoir:file-not-found";
  return <Icon icon={icon} height={size} width={size} />;
};
