import { useCallback, useMemo, useState } from "react";

export const useClickToEditInputState = () => {
  const [isEditing, setIsEditing] = useState(false);

  const startEditing = useCallback(() => {
    setIsEditing(true);
  }, []);

  const stopEditing = useCallback(() => {
    setIsEditing(false);
  }, []);

  return useMemo(
    () => ({
      isEditing,
      startEditing,
      stopEditing,
      setIsEditing,
    }),
    [isEditing, startEditing, stopEditing, setIsEditing],
  );
};
