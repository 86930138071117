import { ReactNode } from "react";

export interface IFilterConfig {
  name: string;
  label?: string;
  type: FilterType;
  options?: IOption[];
  multiple?: boolean;
  defaultValue?: string;
  buildMenuItem?: (option: IOption) => ReactNode;
}

export interface IOption {
  value: string;
  name: string;
}

export enum FilterType {
  SearchOpen = "searchOpen",
  SearchMenu = "searchMenu",
  Select = "select",
  Checkbox = "checkbox",
}
