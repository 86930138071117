import { FC, useMemo } from "react";
import { LinearProgress, SxProps, Theme } from "@mui/material";

interface ILinearProgressWithPlaceholderComponentProps {
  shown: boolean;
  sx?: SxProps<Theme>;
}

export const LinearProgressWithPlaceholderComponent: FC<
  ILinearProgressWithPlaceholderComponentProps
> = ({ shown, sx }) => {
  const opacity = useMemo(() => (shown ? undefined : 0), [shown]);
  return <LinearProgress sx={sx ? { opacity, ...sx } : { opacity }} />;
};
