import { FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { IDistributionCriterion } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { KeyOffIcon } from "../../common/constants/tabler-icon.constants";

export interface IDataEntryObjectDistributionCriteriaDropdownProps {
  availableDistributionCriteria: IDistributionCriterion[];
  onRemoveDistributionCriteria?: () => void;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const DataEntryObjectDistributionCriteriaDropdown: FC<
  IDataEntryObjectDistributionCriteriaDropdownProps
> = ({
  availableDistributionCriteria,
  onRemoveDistributionCriteria,
  value,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_values_edit");
  return (
    <FormControl variant="outlined" size="medium" fullWidth sx={{ maxWidth: 250, marginLeft: 1 }}>
      <InputLabel>{t("label_dc")}</InputLabel>
      <Select
        startAdornment={
          onRemoveDistributionCriteria ? (
            <Tooltip title={t("value_undo_distribution_criterion")}>
              <span>
                <IconButton
                  disabled={disabled}
                  size="medium"
                  onClick={onRemoveDistributionCriteria}
                >
                  <KeyOffIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : undefined
        }
        variant="outlined"
        size="medium"
        value={value ?? ""}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        sx={{ textAlign: "right" }}
        label={t("label_dc")}
      >
        <MenuItem value="">
          <em>{t("no_dc_selected")}</em>
        </MenuItem>
        {availableDistributionCriteria.map((dc) => (
          <MenuItem key={dc.id} value={dc.id}>
            {dc.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
