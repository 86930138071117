import { TextField } from "@mui/material";
import { FC, useMemo } from "react";
import { TreeUtilities } from "../../../common/utilities/tree.utilities";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { HierarchicalDropdownSingle } from "./hierarchical-dropdown-single.component";
import { HierarchicalDropdownMultiple } from "./hierarchical-dropdown-multiple.component";

export type IHierarchyDropdownType = "single" | "multiple";

interface IHierarchyDropdownPropsCommon {
  type: IHierarchyDropdownType;
  label: string;
  translateValueLabel: (value: string) => string;
  values: string[];
  disabled: boolean;
}

interface IHierarchyDropdownPropsSingle extends IHierarchyDropdownPropsCommon {
  type: "single";
  value: string;
  onChange: (newValue: string | null) => void;
  emptyOption?: boolean;
}

interface IHierarchyDropdownPropsMultiple extends IHierarchyDropdownPropsCommon {
  type: "multiple";
  value: string[];
  onChange: (newValue: string[]) => void;
  emptyOption?: undefined;
}

type IHierarchyDropdownProps = IHierarchyDropdownPropsSingle | IHierarchyDropdownPropsMultiple;

interface IHierarchyEntry {
  value: string;
  label: string;
  children: IHierarchyEntry[];
}

export interface IFlattenedHierarchyEntry {
  depth: number;
  item: IHierarchyEntry;
}

const buildHierarchy = (
  flatData: string[],
  getLabel: (value: string) => string,
): IHierarchyEntry[] => {
  const roots: IHierarchyEntry[] = [];

  flatData.forEach((path) => {
    const parts = path.split(".");
    let currentLevel = roots;

    parts.forEach((_, index) => {
      const value = parts.slice(0, index + 1).join(".");
      let existingPath = currentLevel.find((p) => p.value === value);

      if (!existingPath) {
        const newPart: IHierarchyEntry = {
          value: value,
          label: getLabel(value),
          children: [],
        };

        currentLevel.push(newPart);
        existingPath = newPart;
      }

      currentLevel = existingPath.children;
    });
  });

  return roots;
};

export const HierarchicalDropdownEnum: FC<IHierarchyDropdownProps> = ({
  type,
  label,
  translateValueLabel,
  values,
  value,
  onChange,
  disabled,
  emptyOption,
}) => {
  const itemsHierarchy = useMemo(
    () => buildHierarchy(values, translateValueLabel),
    [values, translateValueLabel],
  );

  const flattenedHierarchy: IFlattenedHierarchyEntry[] = useMemo(
    () =>
      itemsHierarchy.flatMap((rootNode) =>
        TreeUtilities.flatMap(
          rootNode,
          (entry) => entry.children,
          (entry, depth) => ({
            item: entry,
            depth,
          }),
        ),
      ),
    [itemsHierarchy],
  );

  return type === "single" ? (
    <HierarchicalDropdownSingle
      label={label}
      flattenedOptions={flattenedHierarchy}
      value={value}
      emptyOption={emptyOption}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <HierarchicalDropdownMultiple
      label={label}
      flattenedOptions={flattenedHierarchy}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

interface IAutocompleteTextInputProps
  extends Pick<IHierarchyDropdownPropsCommon, "label" | "disabled"> {
  params: AutocompleteRenderInputParams;
}

export const AutocompleteTextInput: FC<IAutocompleteTextInputProps> = ({ label, params }) => {
  return (
    <TextField
      {...params}
      label={label}
      InputProps={{
        ...params.InputProps,
        sx: {
          minWidth: 260,
        },
      }}
    />
  );
};
