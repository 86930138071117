import { FC, forwardRef, useMemo, useState } from "react";
import {
  BeautifulMentionsMenuItemProps,
  BeautifulMentionsMenuProps,
  BeautifulMentionsPlugin,
} from "lexical-beautiful-mentions";
import { BeautifulMentionsItem } from "lexical-beautiful-mentions/BeautifulMentionsPluginProps";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UserUtilities } from "@netcero/netcero-common";
import { Box, MenuItem, Paper, Popper } from "@mui/material";
import { UserAvatar } from "../../user/components/user-avatar.component";

interface IUsersMentionsPluginProps {
  users: IUserRepresentation[];
}

export const UsersMentionsPlugin: FC<IUsersMentionsPluginProps> = ({ users }) => {
  const mentionItems: Record<string, BeautifulMentionsItem[]> = useMemo(() => {
    const areUsersUnique =
      new Set(users.map((user) => UserUtilities.formatUserFullName(user))).size === users.length;

    const formatUserName = areUsersUnique
      ? UserUtilities.formatUserFullName
      : (user: IUserRepresentation) => `${UserUtilities.formatUserFullName(user)} (${user.email})`;

    return {
      "@": users.map<BeautifulMentionsItem>((user) => ({
        value: formatUserName(user),
        userId: user.id ?? null,
      })),
    };
  }, [users]);

  return (
    <BeautifulMentionsPlugin
      items={mentionItems}
      menuAnchorClassName="editor-mentions-menu-anchor"
      menuComponent={CustomMenu}
      menuItemComponent={CustomMenuItem}
    />
  );
};

function CustomMenu({ loading, ...props }: BeautifulMentionsMenuProps) {
  const [anchorRef, setAnchorRef] = useState<HTMLDivElement | null>(null);

  return (
    <>
      {/* Anchor */}
      <Box
        ref={setAnchorRef}
        sx={{
          height: "24px",
          transform: "translateY(-100%)",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      />

      {/* Actual Menu */}
      {anchorRef && (
        <Popper
          open
          disablePortal
          anchorEl={anchorRef}
          placement="bottom-start"
          sx={{ zIndex: 1310 }}
        >
          <Paper
            {...props}
            sx={{
              maxHeight: "50dvh",
              overflowY: "auto",
            }}
          />
        </Popper>
      )}
    </>
  );
}

const CustomMenuItem = forwardRef<HTMLLIElement, BeautifulMentionsMenuItemProps>(
  ({ selected, item, itemValue, userId, ...props }, ref) => {
    const [firstName, lastName] = item.value.split(" ");

    return (
      <MenuItem
        selected={selected}
        className="editor-mentions-menu-item"
        {...props}
        ref={ref}
        sx={{ display: "flex", gap: 1 }}
      >
        <UserAvatar user={{ firstName, lastName }} avatarDiameter={28} />
        <span>{item.value}</span>
      </MenuItem>
    );
  },
);
