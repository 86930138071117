import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getDialogSxPropsForEditDialogCommentsAndChangelogPaper } from "../components/edit-dialog-comments-and-audit-log-paper.component";
import { useCommentsAndAuditLogState } from "./comments-and-audit-log-state.hook";
import { useSearchParams } from "react-router-dom";
import { VIEWED_COMMENT_QUERY_PARAM } from "@netcero/netcero-common";

/**
 * Hook to manage the state of the Edit Dialog Comments and Audit Log Paper
 * @param dialogOpenState
 */
export const useEditDialogCommentsAndAuditLogPaperState = (dialogOpenState: boolean) => {
  const {
    showCommentsAndAuditLogSideSection,
    toggleCommentsAndAuditLogSideSection: toggleCommentsAndAuditLogSideSectionInternal,
    openCommentsAndAuditLogSideSection,
    closeCommentsAndAuditLogSideSection: closeCommentsAndAuditLogSideSectionInternal,
  } = useCommentsAndAuditLogState();

  const [searchParams, setSearchParams] = useSearchParams();
  const isViewedCommentSet = useMemo(
    () => searchParams.get(VIEWED_COMMENT_QUERY_PARAM) !== null,
    [searchParams],
  );

  const resetViewedCommentAndExecuteAction = useCallback(
    (action?: VoidFunction) => () => {
      if (searchParams.has(VIEWED_COMMENT_QUERY_PARAM)) {
        // Execute once js thread is free again (avoid collisions with other setSearchParams calls)
        // This has already caused issues --> therefore this mitigation is necessary
        // We may want to consider a different approach in the future (different router library or v7 upgrade)
        setTimeout(
          () =>
            setSearchParams(
              (params) => {
                params.delete(VIEWED_COMMENT_QUERY_PARAM);
                return params;
              },
              { replace: true },
            ),
          10,
        );
      }
      action?.();
    },
    [searchParams, setSearchParams],
  );

  const wasOpened = useRef(false);
  useEffect(() => {
    if (!dialogOpenState && wasOpened.current) {
      resetViewedCommentAndExecuteAction()();
    }
    wasOpened.current = dialogOpenState;
  }, [dialogOpenState, resetViewedCommentAndExecuteAction]);

  // Close Paper with Dialog change
  useEffect(() => {
    if (!dialogOpenState) {
      setTimeout(closeCommentsAndAuditLogSideSectionInternal, 300);
    } else if (isViewedCommentSet) {
      openCommentsAndAuditLogSideSection();
    }
  }, [
    dialogOpenState,
    closeCommentsAndAuditLogSideSectionInternal,
    isViewedCommentSet,
    openCommentsAndAuditLogSideSection,
  ]);

  const dialogSxProps = useMemo(
    () =>
      getDialogSxPropsForEditDialogCommentsAndChangelogPaper(showCommentsAndAuditLogSideSection),
    [showCommentsAndAuditLogSideSection],
  );

  // Create Dialog Size Observer
  const [dialogElement, setDialogElement] = useState<HTMLDivElement | null>(null);

  return useMemo(
    () => ({
      showCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        toggleCommentsAndAuditLogSideSectionInternal,
      ),
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSection: resetViewedCommentAndExecuteAction(
        closeCommentsAndAuditLogSideSectionInternal,
      ),
      dialogProps: {
        sx: dialogSxProps,
        PaperProps: {
          ref: setDialogElement,
        },
        disableEnforceFocus: true,
      },
      dialogElement,
    }),
    [
      showCommentsAndAuditLogSideSection,
      resetViewedCommentAndExecuteAction,
      toggleCommentsAndAuditLogSideSectionInternal,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSectionInternal,
      dialogSxProps,
      dialogElement,
      setDialogElement,
    ],
  );
};
