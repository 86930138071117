import { FC, useMemo } from "react";
import MultipleSelectChip from "../../../common/components/multiple-chip.component";
import { IPolicy } from "@netcero/netcero-core-api-client";

interface IPoliciesMultipleSelectChipProps {
  label?: string;
  onSelectClick: VoidFunction;
  policies: IPolicy[];
  selectedPolicyIds: string[];
}

export const PoliciesMultipleSelectChip: FC<IPoliciesMultipleSelectChipProps> = ({
  label,
  onSelectClick,
  policies,
  selectedPolicyIds,
}) => {
  const selectedPoliciesNames = useMemo(
    () =>
      /** Policies are already sorted by name by the api - no sorting needed therefore */
      policies
        .filter((policy) => selectedPolicyIds.includes(policy.id))
        .map((policy) => policy.general.name),
    [policies, selectedPolicyIds],
  );

  return (
    <MultipleSelectChip
      label={label}
      onSelectClick={onSelectClick}
      chipNames={selectedPoliciesNames}
      fullWidth
      noMargin
    />
  );
};
