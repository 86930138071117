import { IconButton, SxProps, Theme, Tooltip, TooltipProps } from "@mui/material";
import { ButtonBaseOwnProps } from "@mui/material/ButtonBase/ButtonBase";
import { IconButtonOwnProps } from "@mui/material/IconButton/IconButton";
import { IconProps } from "@tabler/icons-react";
import { FC, MouseEvent, useRef, useState } from "react";
import { ClipboardCheckIcon, ClipboardIcon } from "../constants/tabler-icon.constants";

export const CopyToClipboardButtonAnimationProps = (buttonClassName: string): SxProps<Theme> => ({
  [buttonClassName]: {
    opacity: 0,
    transform: "translateX(-25%)",
    transition: "opacity 150ms, transform 150ms",
  },
  [`:hover ${buttonClassName}`]: {
    opacity: 1,
    transform: "translateX(0)",
  },
});

interface ICopyToClipboardButtonProps {
  size?: IconButtonOwnProps["size"];
  disableRipple?: ButtonBaseOwnProps["disableRipple"];
  value: string;
  tooltip?: string;
  tooltipSuccess?: string;
  tooltipPlacement?: TooltipProps["placement"];
  iconProps?: IconProps;
  disabled?: boolean;
}

export const CopyToClipboardButton: FC<ICopyToClipboardButtonProps> = ({
  size,
  disableRipple,
  value,
  tooltip,
  tooltipSuccess,
  tooltipPlacement,
  iconProps,
  disabled,
}) => {
  const [copied, setCopied] = useState(false);
  const currentTimeoutId = useRef<NodeJS.Timeout>();

  const handleCopyToClipboard = async (event: MouseEvent) => {
    // prevent bubbling up the event to other handlers
    event.stopPropagation();

    await navigator.clipboard.writeText(value);

    // Init timeout to reset the copied state
    setCopied(true);
    clearTimeout(currentTimeoutId.current);
    currentTimeoutId.current = setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Tooltip title={copied ? tooltipSuccess ?? tooltip : tooltip} placement={tooltipPlacement}>
      <span>
        <IconButton
          size={size}
          onClick={handleCopyToClipboard}
          disabled={disabled}
          disableRipple={disableRipple}
        >
          {copied ? <ClipboardCheckIcon {...iconProps} /> : <ClipboardIcon {...iconProps} />}
        </IconButton>
      </span>
    </Tooltip>
  );
};
