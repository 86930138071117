import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BaseStatusIndicator } from "../../common/components/base-status-indicator.component";
import { IDataEntryObjectInputParameterGapAnalysisAvailability } from "@netcero/netcero-core-api-client";
import { AVAILABILITY_COLORS } from "../gap-analysis.constants";
import { SUPPORT_COLORS } from "../../../theme/theme";

interface IAvailabilityIndicatorProps {
  onClick?: VoidFunction;
  disabled?: boolean;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
  status: IDataEntryObjectInputParameterGapAnalysisAvailability | null;
}

export const AvailabilityIndicator = forwardRef<HTMLDivElement, IAvailabilityIndicatorProps>(
  function DataEntryObjectInputParameterStatusIndicator(
    { status, onClick, disabled, isMenuOpen, shouldRenderChevronIcon },
    ref,
  ) {
    const { t } = useTranslation("gap_analysis", { keyPrefix: "availability" });
    const statusColor = useMemo(
      () => (status ? AVAILABILITY_COLORS[status] : SUPPORT_COLORS.darkGray),
      [status],
    );

    return (
      <BaseStatusIndicator
        ref={ref}
        onClick={onClick}
        color={statusColor}
        label={t(status ?? "null")}
        isFilled={false} // Never filled
        disabled={disabled}
        isMenuOpen={isMenuOpen}
        shouldRenderChevronIcon={shouldRenderChevronIcon}
      />
    );
  },
);
