import {
  IInputParameter,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameter } from "../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures/input-parameter-recording-structures.utilities";
import { DataTransformationUtilities } from "../common/utilities/data-transformation.utilities";
import { IRecordingGroupInfo } from "../input-parameter-recording-structures/recording-group-info.interface";

export interface IDataEntryObjectRecordingInputParameter {
  inputParameter: IInputParameter;
  isActive: boolean;
  contributingUserIds: string[];
  responsibleUserId?: string;
}

export interface IDataEntryObjectInputParameterModelRecordingGroup {
  groupInfo: IRecordingGroupInfo;
  inputParameterConfigurations: IDataEntryObjectRecordingInputParameter[];
  children: IDataEntryObjectInputParameterModelRecordingGroup[];
}

export class DataEntryObjectInputParametersUtilities {
  public static hydrateStructureWithDataEntryObjectInputParameterSettings(
    structure: IInputParameterRecordingStructureGroupTHGSC,
    dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
    inputParameterModelInputParameters: IInputParameter[],
  ): IDataEntryObjectInputParameterModelRecordingGroup {
    const dataEntryObjectInputParameterLookup =
      InputParameterRecordingStructuresUtilities.getInputParameterEntryLookupMap(
        dataEntryObjectInputParameters,
      );
    const inputParameterModelInputParametersLookup =
      DataTransformationUtilities.convertListToLookupMap<string, IInputParameter>(
        inputParameterModelInputParameters,
        "id",
      );

    return DataEntryObjectInputParametersUtilities.convertRecordingGroupToTreeNode(
      structure,
      dataEntryObjectInputParameterLookup,
      inputParameterModelInputParametersLookup,
    );
  }

  private static convertRecordingGroupToTreeNode(
    recordingGroup: IInputParameterRecordingStructureGroupTHGSC,
    trackedInputParameterLookup: Map<string, ILocalDataEntryObjectInputParameter>,
    inputParameterModelInputParametersLookup: Map<string, IInputParameter>,
  ): IDataEntryObjectInputParameterModelRecordingGroup {
    const groupInfo = {
      name: recordingGroup.name,
      description: recordingGroup.description,
    };

    const inputParameterConfigurations: IDataEntryObjectRecordingInputParameter[] = [];
    recordingGroup.inputParameterIds.forEach((inputParameterId) => {
      const trackedInputParameterEntry = trackedInputParameterLookup.get(inputParameterId);
      if (trackedInputParameterEntry) {
        inputParameterConfigurations.push({
          inputParameter: trackedInputParameterEntry.inputParameter,
          isActive: trackedInputParameterEntry.isActive,
          contributingUserIds: trackedInputParameterEntry.contributingUserIds,
          responsibleUserId: trackedInputParameterEntry.responsibleUserId,
          // TODO: add those back once they are needed
          // TODO: they are provided by the ILocalDataEntryObjectInputParameter, but not used
          // TODO: in any API calls as of yet (no need to submit them)
          // attributionFactor: trackedInputParameterEntry.attributionFactor,
          // attributionReason: trackedInputParameterEntry.attributionReason,
        });
      } else {
        const inputParameterModelInputParameter =
          inputParameterModelInputParametersLookup.get(inputParameterId);
        if (inputParameterModelInputParameter) {
          inputParameterConfigurations.push({
            inputParameter: inputParameterModelInputParameter,
            isActive: false,
            contributingUserIds: [],
            responsibleUserId: undefined,
          });
        } else {
          console.warn(
            `Input Parameter with id ${inputParameterId} was specified in input parameter model but was ` +
              `not found in input parameter models associated input parameters!`,
          );
        }
      }
    });

    return {
      groupInfo,
      inputParameterConfigurations,
      children: recordingGroup.children.map((childGroup) =>
        DataEntryObjectInputParametersUtilities.convertRecordingGroupToTreeNode(
          childGroup,
          trackedInputParameterLookup,
          inputParameterModelInputParametersLookup,
        ),
      ),
    };
  }

  /**
   * Collects all input parameter configurations of a group and its children.
   */
  public static collectAllInputParameterConfigurationsOfGroup(
    group: IDataEntryObjectInputParameterModelRecordingGroup,
  ): IDataEntryObjectRecordingInputParameter[] {
    return [
      group.inputParameterConfigurations,
      group.children
        .map((childGroup) =>
          DataEntryObjectInputParametersUtilities.collectAllInputParameterConfigurationsOfGroup(
            childGroup,
          ),
        )
        .flat(),
    ].flat();
  }
}
