import {
  IDataEntryObjectInputParameterGapAnalysisAvailability,
  IDataEntryObjectInputParameterGapAnalysisDataCollection,
} from "@netcero/netcero-core-api-client";
import { SUPPORT_COLORS } from "../../theme/theme";

export const GAP_ANALYSIS_TOPIC_QUERY_PARAM_KEY = "viewedTopicStructureId";

export const SORTED_DATA_COLLECTION_OPTIONS = [
  IDataEntryObjectInputParameterGapAnalysisDataCollection.Central,
  IDataEntryObjectInputParameterGapAnalysisDataCollection.Local,
] as const;

export const SORTED_AVAILABILITY_OPTIONS = [
  IDataEntryObjectInputParameterGapAnalysisAvailability.Not,
  IDataEntryObjectInputParameterGapAnalysisAvailability.Unclear,
  IDataEntryObjectInputParameterGapAnalysisAvailability.Partial,
  IDataEntryObjectInputParameterGapAnalysisAvailability.Full,
] as const;

export const AVAILABILITY_COLORS: Record<
  IDataEntryObjectInputParameterGapAnalysisAvailability,
  string
> = {
  [IDataEntryObjectInputParameterGapAnalysisAvailability.Not]: SUPPORT_COLORS.red,
  [IDataEntryObjectInputParameterGapAnalysisAvailability.Unclear]: SUPPORT_COLORS.darkGray,
  [IDataEntryObjectInputParameterGapAnalysisAvailability.Partial]: SUPPORT_COLORS.yellow,
  [IDataEntryObjectInputParameterGapAnalysisAvailability.Full]: SUPPORT_COLORS.green,
};
