import { useKeycloak } from "@react-keycloak/web";
import { FC } from "react";

// TODO: This should probably rewritten or removed
export function requiresAuthentication<T>(component: FC<T>): FC<T> {
  return (props: T) => {
    const { keycloak } = useKeycloak();
    if (!keycloak.authenticated) {
      void keycloak.login();
    }

    return component(props);
  };
}
