import { Dispatch, FC, ReactNode } from "react";
import { Box, Button, Typography } from "@mui/material";

interface IMdrInputHandlerProps {
  value: boolean | undefined;
  onChange: Dispatch<boolean>;
  mdrList: ReactNode;
  buttonPresentText: string;
  buttonNotPresentText: string;
  noticeChosenToNotDefine: string;
  buttonChooseToAdoptText: string;
  disabled: boolean | undefined;
}

export const MdrInputHandler: FC<IMdrInputHandlerProps> = ({
  value,
  onChange,
  mdrList,
  buttonPresentText,
  buttonNotPresentText,
  noticeChosenToNotDefine,
  buttonChooseToAdoptText,
  disabled,
}) => {
  if (value === undefined) {
    return (
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => onChange(true)}
          disabled={disabled}
          sx={{ minWidth: 124 }}
        >
          {buttonPresentText}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => onChange(false)}
          disabled={disabled}
          sx={{ minWidth: 124 }}
        >
          {buttonNotPresentText}
        </Button>
      </Box>
    );
  }

  return value ? (
    mdrList
  ) : (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography>{noticeChosenToNotDefine}</Typography>
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={() => onChange(true)}
        disabled={disabled}
        sx={{ minWidth: 124 }}
      >
        {buttonChooseToAdoptText}
      </Button>
    </Box>
  );
};
