import { IDrValueEditProps } from "./dr-value-editor.component";
import { type FC } from "react";
import { DataEntryObjectValuesInputTable } from "../../input-components/table/data-entry-object-value-input-fields-table.component";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AddRowIcon } from "../../../common/constants/tabler-icon.constants";
import { useManageDataEntryObjectTableValue } from "../../input-components/table/manage-data-entry-object-table-value.hook";

export const DrValueEditorTable: FC<IDrValueEditProps> = ({
  deoInputParameter,
  // organizationId,
  // recordingPeriod,
  // rootDataEntryObjectId,
  // dataEntryObjectId,
  // availableDistributionCriteria,
  // onCreate,
  // onUpdate,
  // onDelete,
  onTableUpdate,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_esrs_component");

  const {
    state: { isEditing, values, rowsErrors, duplicateValuesErrors },
    formState: { isStaticTable, staticIdentifierColumnValueDefinition, hasErrors, hasChanges },
    handlers: {
      handleAddRow,
      handleDeleteRow,
      handleValueChange,
      handleReset,
      handleSubmit,
      handleStartEditing,
    },
  } = useManageDataEntryObjectTableValue({
    inputParameter: deoInputParameter.inputParameter,
    recordedValues: deoInputParameter.recordedValues,
    subscribeToRecordedValues: true,
  });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <DataEntryObjectValuesInputTable
        inputParameter={deoInputParameter.inputParameter}
        staticTableIdentifierColumnValueDefinition={staticIdentifierColumnValueDefinition}
        values={values}
        disabled={disabled ?? false}
        onValueChange={handleValueChange}
        onDeleteRow={handleDeleteRow}
        rowErrors={rowsErrors}
        duplicateValuesErrors={duplicateValuesErrors}
        editMode={isEditing}
        onStartEditing={handleStartEditing}
      />
      {/* Actions Bar */}
      <Box display="flex" gap={2}>
        {/* Add Row Button */}
        {!isStaticTable && (
          <Button variant="text" color="inherit" onClick={handleAddRow} sx={{ gap: 1 }}>
            <AddRowIcon />
            {t("button_add_table_row")}
          </Button>
        )}
        {/* Spacer */}
        <Box flex={1} />
        {/* Save & Cancel Buttons */}
        <Button
          color="inherit"
          onClick={handleReset}
          disabled={disabled || (!hasChanges && !isEditing)}
        >
          {t("cancel", { ns: "buttons" })}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit(onTableUpdate)}
          disabled={disabled || hasErrors || !hasChanges}
        >
          {t("save", { ns: "buttons" })}
        </Button>
      </Box>
    </Box>
  );
};
