import {
  IDMACategoryWithEffectsAndChildren,
  IDMAConfiguration,
  IESRSTopic,
} from "@netcero/netcero-core-api-client";
import { IGroupedESRSTopics } from "../dma-dashboard.utilities";
import {
  DmaConfigurationUtilities,
  FinancialEffectCalculator,
  MaterialImpactCalculator,
} from "@netcero/netcero-dma";
import { TreeUtilities } from "../../common/utilities/tree.utilities";
import { IDMACategoryNameRenderer } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { IESRSTopicNameRenderer } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";

export interface IDMAOverviewTableItemMaterialityDegreeData {
  shortTerm: number | null;
  mediumTerm: number | null;
  longTerm: number | null;
  maxValue: number | null;
}

export interface IDMAOverviewTableItemData {
  id: string;
  name: string;
  materialMaterialityDegree: IDMAOverviewTableItemMaterialityDegreeData;
  financialMaterialityDegree: IDMAOverviewTableItemMaterialityDegreeData;
  isOptOut: boolean;
  maxMaterialityDegree: number | null;
  isMaterial: boolean;
  children: IDMAOverviewTableItemData[];
}

// Internal Interfaces

interface IGroupedESRSNames {
  environmental: string;
  social: string;
  governance: string;
  other: string;
}

interface IOwnMateriality {
  materialMaterialityDegreeData: IDMAOverviewTableItemMaterialityDegreeData;
  financialMaterialityDegreeData: IDMAOverviewTableItemMaterialityDegreeData;
  materialityDegree: number | null;
  isMaterial: boolean;
}

export class DMAOverviewTableUtilities {
  /**
   * Converts the grouped ESRSTopics to a table data structure
   * @param groupedESRSTopics The grouped ESRSTopics
   * @param groupNames The names to use for the groups
   * @param dmaConfiguration
   * @param render
   * @param renderTopic
   * @returns The table data
   */
  public static convertGroupedESRSTopicsToTableData(
    groupedESRSTopics: IGroupedESRSTopics,
    groupNames: IGroupedESRSNames,
    dmaConfiguration: IDMAConfiguration,
    render: IDMACategoryNameRenderer,
    renderTopic: IESRSTopicNameRenderer,
  ): IDMAOverviewTableItemData[] {
    return [
      DMAOverviewTableUtilities.convertESRSTopicsGroupToTableData(
        "environmental",
        groupedESRSTopics.environmental,
        groupNames.environmental,
        dmaConfiguration,
        render,
        renderTopic,
      ),
      DMAOverviewTableUtilities.convertESRSTopicsGroupToTableData(
        "social",
        groupedESRSTopics.social,
        groupNames.social,
        dmaConfiguration,
        render,
        renderTopic,
      ),
      DMAOverviewTableUtilities.convertESRSTopicsGroupToTableData(
        "governance",
        groupedESRSTopics.governance,
        groupNames.governance,
        dmaConfiguration,
        render,
        renderTopic,
      ),
      DMAOverviewTableUtilities.convertESRSTopicsGroupToTableData(
        "other",
        groupedESRSTopics.other,
        groupNames.other,
        dmaConfiguration,
        render,
        renderTopic,
      ),
    ];
  }

  /**
   * Converts a single group of ESRSTopics to a table data structure
   * @param id The id to use for the table item
   * @param esrsTopics The topics in the group
   * @param groupName The name to use for the table item
   * @param dmaConfiguration
   * @param render
   * @param renderTopic
   * @returns The table data
   */
  private static convertESRSTopicsGroupToTableData(
    id: string,
    esrsTopics: IESRSTopic[],
    groupName: string,
    dmaConfiguration: IDMAConfiguration,
    render: IDMACategoryNameRenderer,
    renderTopic: IESRSTopicNameRenderer,
  ): IDMAOverviewTableItemData {
    const childTopicsItems = esrsTopics.map((t) =>
      DMAOverviewTableUtilities.convertESRSTopicToTableData(
        t,
        dmaConfiguration,
        render,
        renderTopic,
      ),
    );
    return DMAOverviewTableUtilities.createTableItem(id, groupName, childTopicsItems);
  }

  /**
   * Converts a single ESRSTopic to a table data structure
   * @param esrsTopic The topic to convert
   * @param dmaConfiguration
   * @param render
   * @param renderTopic
   * @returns The table data
   */
  public static convertESRSTopicToTableData(
    esrsTopic: IESRSTopic,
    dmaConfiguration: IDMAConfiguration,
    render: IDMACategoryNameRenderer,
    renderTopic: IESRSTopicNameRenderer,
  ): IDMAOverviewTableItemData {
    const childCategoriesItems =
      esrsTopic.recordedESRSTopic?.dmaCategories.map((c) =>
        DMAOverviewTableUtilities.convertDMACategoryToTableData(c, dmaConfiguration, render),
      ) ?? [];
    return DMAOverviewTableUtilities.createTableItem(
      esrsTopic.id,
      `${esrsTopic.topic} ${renderTopic(esrsTopic)}`,
      childCategoriesItems,
      undefined,
      esrsTopic.recordedESRSTopic?.optOut,
    );
  }

  /**
   * Converts a single DMACategory to a table data structure
   * @param dmaCategory The category to convert
   * @param dmaConfiguration
   * @param render
   * @returns The table data
   */
  public static convertDMACategoryToTableData(
    dmaCategory: IDMACategoryWithEffectsAndChildren,
    dmaConfiguration: IDMAConfiguration,
    render: IDMACategoryNameRenderer,
  ): IDMAOverviewTableItemData {
    const materialityData = DMAOverviewTableUtilities.getCategoryMaterialityDegreeData(
      dmaCategory,
      dmaConfiguration,
    );
    const childCategoryItems = dmaCategory.children.map((c) =>
      DMAOverviewTableUtilities.convertDMACategoryToTableData(c, dmaConfiguration, render),
    );

    return DMAOverviewTableUtilities.createTableItem(
      dmaCategory.id,
      render(dmaCategory),
      childCategoryItems,
      materialityData,
      dmaCategory.optOut,
    );
  }

  /**
   * Gets the materiality degree data for a category
   * @param dmaCategory The category to get the data for
   * @param dmaConfiguration
   * @returns The materiality degree data
   */
  public static getCategoryMaterialityDegreeData(
    dmaCategory: IDMACategoryWithEffectsAndChildren,
    dmaConfiguration: IDMAConfiguration,
  ): IOwnMateriality {
    const contextMaterial = DmaConfigurationUtilities.materialContextFromConfig(dmaConfiguration);
    const contextFinancial = DmaConfigurationUtilities.financialContextFromConfig(dmaConfiguration);

    // Material Materiality
    const materialShortTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent(
      dmaCategory.materialImpacts.map((i) =>
        new MaterialImpactCalculator(
          i.horizons.shortTerm,
          contextMaterial,
        ).calculateMaterialityDegree(),
      ),
    );
    const materialMediumTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent(
      dmaCategory.materialImpacts.map((i) =>
        new MaterialImpactCalculator(
          i.horizons.mediumTerm,
          contextMaterial,
        ).calculateMaterialityDegree(),
      ),
    );
    const materialLongTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent(
      dmaCategory.materialImpacts.map((i) =>
        new MaterialImpactCalculator(
          i.horizons.longTerm,
          contextMaterial,
        ).calculateMaterialityDegree(),
      ),
    );

    // Financial Materiality
    const financialShortTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent(
      dmaCategory.financialEffects.map((e) =>
        new FinancialEffectCalculator(
          e.horizons.shortTerm,
          contextFinancial,
        ).calculateMaterialityDegree(),
      ),
    );
    const financialMediumTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent(
      dmaCategory.financialEffects.map((e) =>
        new FinancialEffectCalculator(
          e.horizons.mediumTerm,
          contextFinancial,
        ).calculateMaterialityDegree(),
      ),
    );
    const financialLongTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent(
      dmaCategory.financialEffects.map((e) =>
        new FinancialEffectCalculator(
          e.horizons.longTerm,
          contextFinancial,
        ).calculateMaterialityDegree(),
      ),
    );

    const totalMaterialityDegree = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      dmaCategory.materiality.materialityDegreeMaterial ?? null,
      dmaCategory.materiality.materialityDegreeFinancial ?? null,
    ]);

    return {
      materialMaterialityDegreeData: {
        shortTerm: materialShortTerm,
        mediumTerm: materialMediumTerm,
        longTerm: materialLongTerm,
        maxValue: dmaCategory.materiality.materialityDegreeMaterial ?? null,
      },
      financialMaterialityDegreeData: {
        shortTerm: financialShortTerm,
        mediumTerm: financialMediumTerm,
        longTerm: financialLongTerm,
        maxValue: dmaCategory.materiality.materialityDegreeFinancial ?? null,
      },
      materialityDegree: totalMaterialityDegree,
      isMaterial:
        (dmaCategory.materiality.materialityMaterial ||
          dmaCategory.materiality.materialityFinancial) ??
        false,
    };
  }

  // Common Utilities

  /**
   * Creates a table item
   * @param id The id of the item
   * @param name The name of the item
   * @param children The children of the item
   * @param ownMateriality The materiality degree data for the item itself (may overwrite the children's data)
   * @param isOptOut Whether the item is opted out
   * @returns The table item
   */
  private static createTableItem(
    id: string,
    name: string,
    children: IDMAOverviewTableItemData[],
    ownMateriality?: IOwnMateriality,
    isOptOut: boolean = false,
  ): IDMAOverviewTableItemData {
    // Material Materiality Degree

    const materialShortTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      ...children.map((c) => c.materialMaterialityDegree.shortTerm),
      ownMateriality?.materialMaterialityDegreeData.shortTerm ?? null,
    ]);
    const materialMediumTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      ...children.map((c) => c.materialMaterialityDegree.mediumTerm),
      ownMateriality?.materialMaterialityDegreeData.mediumTerm ?? null,
    ]);
    const materialLongTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      ...children.map((c) => c.materialMaterialityDegree.longTerm),
      ownMateriality?.materialMaterialityDegreeData.longTerm ?? null,
    ]);
    const materialMax = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      materialShortTerm,
      materialMediumTerm,
      materialLongTerm,
      // ownMateriality is not included here, as it is not relevant for the max value
      // (it is already included in the short, medium and long term values)
    ]);

    // Financial Materiality Degree

    const financialShortTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      ...children.map((c) => c.financialMaterialityDegree.shortTerm),
      ownMateriality?.financialMaterialityDegreeData.shortTerm ?? null,
    ]);
    const financialMediumTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      ...children.map((c) => c.financialMaterialityDegree.mediumTerm),
      ownMateriality?.financialMaterialityDegreeData.mediumTerm ?? null,
    ]);
    const financialLongTerm = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      ...children.map((c) => c.financialMaterialityDegree.longTerm),
      ownMateriality?.financialMaterialityDegreeData.longTerm ?? null,
    ]);
    const financialMax = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      financialShortTerm,
      financialMediumTerm,
      financialLongTerm,
      // ownMateriality is not included here, as it is not relevant for the max value
      // (it is already included in the short, medium and long term values)
    ]);

    const maxMaterialityDegree = DMAOverviewTableUtilities.getMaxMaterialityDegreeIfPresent([
      materialMax,
      financialMax,
    ]);

    return {
      id,
      name,
      materialMaterialityDegree: {
        shortTerm: materialShortTerm,
        mediumTerm: materialMediumTerm,
        longTerm: materialLongTerm,
        maxValue: materialMax,
      },
      financialMaterialityDegree: {
        shortTerm: financialShortTerm,
        mediumTerm: financialMediumTerm,
        longTerm: financialLongTerm,
        maxValue: financialMax,
      },
      // Either use the ownMateriality value or check if any of the children is material
      isMaterial: ownMateriality?.isMaterial ?? children.some((c) => c.isMaterial),
      isOptOut,
      maxMaterialityDegree,
      children,
    };
  }

  /**
   * Gets the maximum materiality degree if present
   * @param values The values to get the maximum from
   * @returns The maximum value or null if no values are present
   */
  private static getMaxMaterialityDegreeIfPresent(values: (number | null)[]): number | null {
    const presentValues = values.filter((v) => v !== null) as number[];
    return presentValues.length > 0 ? Math.max(...presentValues) : null;
  }

  // Other utilities

  public static flatMapAllItems = (
    tableData: IDMAOverviewTableItemData[],
  ): IDMAOverviewTableItemData[] => {
    const result: IDMAOverviewTableItemData[] = [];

    for (const item of tableData) {
      result.push(
        ...TreeUtilities.flatMap(
          item,
          (i) => i.children,
          (i) => i,
        ),
      );
    }

    return result;
  };

  public static getPathToItem(
    items: IDMAOverviewTableItemData[],
    viewedItemId: string,
  ): IDMAOverviewTableItemData[] | null {
    for (const item of items) {
      const path = TreeUtilities.getPathToTreeChild(
        item,
        (i) => i.children,
        (i) => i.id === viewedItemId,
      );
      if (path) {
        return path;
      }
    }

    return null;
  }
}
