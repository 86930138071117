import { FC, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameter,
  IInputParameterUIConfigurationColumnItemInput,
  IInputParameterValue,
} from "@netcero/netcero-core-api-client";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../../content-translation/hooks/translate-content.hook";
import {
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import { DataEntryObjectValuesInputTableValueRow } from "./data-entry-object-values-input-table-value-row.component";
import { InputParametersUtilities } from "@netcero/netcero-common";
import { COLUMN_ALIGNMENTS_BY_TYPE } from "./data-entry-object-value-input-fields-table.constants";
import { IValuesErrorsPerKey } from "../../utilities/data-entry-object-values.utilities";
import { useTranslation } from "react-i18next";
import { TranslatedStaticUnitName } from "../../components/translated-static-unit-name.component";
import { IconSize, InfoIcon } from "../../../common/constants/tabler-icon.constants";
import { IDuplicateValuesErrors } from "./manage-data-entry-object-table-value.hook";
import { ITableStaticColumnValueDefinition } from "../../esrs/value-editing/esrs-value-editing.utilities";

interface IDataEntryObjectValuesInputFieldsTableProps {
  inputParameter: IInputParameter;
  staticTableIdentifierColumnValueDefinition: ITableStaticColumnValueDefinition | null | undefined;
  values: (
    | ILocalDataEntryObjectInputParameterValue
    | ILocalDataEntryObjectInputParameterValueData
  )[];
  rowErrors: Partial<IValuesErrorsPerKey[]>;
  duplicateValuesErrors: IDuplicateValuesErrors;
  onValueChange: (
    rowIndex: number,
    valueKey: string,
    newValue: IDataEntryObjectInputParameterValueValueForKey,
  ) => void;
  onDeleteRow: (rowIndex: number) => void;
  disabled: boolean;
  editMode: boolean;
  onStartEditing: VoidFunction;
}

export const DataEntryObjectValuesInputTable: FC<IDataEntryObjectValuesInputFieldsTableProps> = ({
  inputParameter,
  staticTableIdentifierColumnValueDefinition,
  values,
  rowErrors,
  duplicateValuesErrors,
  onValueChange,
  onDeleteRow,
  disabled,
  editMode,
  onStartEditing,
}) => {
  const { t } = useTranslation("data_entry_object_values_input_table");
  const flatInputs = useMemo(
    () => inputParameter.uiConfiguration.rows.flatMap((row) => row.columns),
    [inputParameter.uiConfiguration.rows],
  );

  const valueConfigurationsLookup = useMemo(
    () =>
      InputParametersUtilities.getInputParameterValuesConfigurationLookup(inputParameter.values),
    [inputParameter.values],
  );

  const inputAlignments = useMemo(() => {
    return flatInputs.map((value) => {
      const config = valueConfigurationsLookup[value.valueKey];
      return config.valueConfiguration.type === "simple"
        ? COLUMN_ALIGNMENTS_BY_TYPE[config.valueConfiguration.configuration.type]
        : "center";
    });
  }, [flatInputs, valueConfigurationsLookup]);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {flatInputs.map((value, index) => (
                <TableHeadCell
                  key={value.valueKey}
                  value={value}
                  configuration={valueConfigurationsLookup[value.valueKey]}
                  align={inputAlignments[index]}
                />
              ))}
              {/* Actions */}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Table Rows */}
            {values.map((enteredValue, rowIndex) => (
              <DataEntryObjectValuesInputTableValueRow
                key={rowIndex}
                inputParameterValuesPerKey={valueConfigurationsLookup}
                inputs={flatInputs}
                staticTableIdentifierColumnValueDefinition={
                  staticTableIdentifierColumnValueDefinition
                }
                inputAlignments={inputAlignments}
                enteredValue={enteredValue}
                rowErrors={rowErrors[rowIndex] ?? {}}
                duplicateValuesErrors={duplicateValuesErrors}
                editMode={editMode}
                disabled={disabled}
                onDelete={() => onDeleteRow(rowIndex)}
                onValueChange={(valueKey, newValue) => onValueChange(rowIndex, valueKey, newValue)}
                onStartEditing={onStartEditing}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Empty Message */}
      {values.length === 0 && (
        <Typography variant="body2" textAlign="center">
          {t("notice_empty")}
        </Typography>
      )}
    </>
  );
};

interface ITableHeadCellProps {
  value: IInputParameterUIConfigurationColumnItemInput;
  configuration: IInputParameterValue;
  align: TableCellProps["align"];
}

const TableHeadCell: FC<ITableHeadCellProps> = ({ value, configuration, align }) => {
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  const staticUnit = useMemo(() => {
    if (
      configuration.valueConfiguration.type === "simple" &&
      configuration.valueConfiguration.configuration.type === "number" &&
      configuration.valueConfiguration.configuration.valueLimits.staticUnit
    ) {
      return configuration.valueConfiguration.configuration.valueLimits.staticUnit.identifier;
    }

    return null;
  }, [configuration]);

  return (
    <TableCell key={value.valueKey}>
      <Box display="flex" alignItems="center" justifyContent={align} gap={1}>
        {/* Header Text */}
        <Box>
          {translateOptionalContent(value.label)}
          {staticUnit && (
            <>
              {" "}
              <TranslatedStaticUnitName unit={staticUnit} withBrackets />
            </>
          )}
        </Box>
        {/* Info Icon */}
        {value.tooltip && (
          <Tooltip title={translateContent(value.tooltip)}>
            <InfoIcon size={IconSize.Small} style={{ minWidth: IconSize.Small }} />
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};
