import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { IInputParameterValueMetaDataValueWithUnit } from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForValueWithUnit,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnitGroups,
  DataEntryObjectInputParameterValueDefinitionForValueWithUnitDefaultUnits,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { SelectProps } from "@mui/material/Select";

interface IValueWithUnitInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  label?: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForValueWithUnit>;
  onChange: (
    value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForValueWithUnit>,
  ) => void;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataValueWithUnit;
  disableMaxWidth?: boolean;
  required?: boolean;
  autoFocus?: boolean;
}

export const ValueWithUnitInputComponent: FC<IValueWithUnitInputComponentProps> = ({
  variant,
  label,
  value,
  onChange,
  onSubmit,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  required,
  autoFocus,
}) => {
  const { t } = useTranslation("value_with_unit_input");
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  const [selectedUnit, setSelectedUnit] = useState(
    value?.unit ??
      DataEntryObjectInputParameterValueDefinitionForValueWithUnitDefaultUnits[metaData.unitGroup],
  );
  useEffect(() => {
    if (value?.unit) {
      setSelectedUnit(value.unit);
    }
  }, [value?.unit]);

  const handleValueChange = useCallback(
    (newValue: string) => {
      const numericValue = +newValue;
      onChange(
        newValue === "" || Number.isNaN(numericValue)
          ? undefined
          : {
              unit: selectedUnit,
              value: numericValue,
            },
      );
    },
    [onChange, selectedUnit],
  );

  const handleUnitChange = useCallback(
    (unit: DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit) => {
      setSelectedUnit(unit);

      // Only emit onChange if value is valid
      if (value?.value !== undefined) {
        onChange({
          unit,
          value: value.value,
        });
      }
    },
    [onChange, value?.value],
  );

  return (
    <FormControl
      {...inputVariantProps}
      error={!!error}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined, minWidth: 250 }}
    >
      <TextField
        required={required}
        label={label}
        {...inputVariantProps}
        type="number"
        placeholder={t("placeholder")}
        value={value?.value}
        disabled={disabled}
        autoFocus={autoFocus}
        error={!!error}
        onChange={(e) => handleValueChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            onSubmit?.();
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box display="flex" gap={2}>
                <Divider orientation="vertical" flexItem sx={{ borderColor: "#C1C1C1" }} />
                {/* This box exists to prevent flex spacing between select and monetary indicator */}
                <Box display="flex" alignItems="center">
                  <UnitAdornment
                    metaData={metaData}
                    size={inputVariantProps.size}
                    selectedUnit={selectedUnit}
                    onChange={handleUnitChange}
                    disabled={disabled}
                  />
                  {/* Render monetary indicator if necessary */}
                  {metaData.perMonetary && <Box> / {t("per_monetary")}</Box>}
                </Box>
              </Box>
            </InputAdornment>
          ),
          sx: stylesSx,
        }}
        sx={{ input: { textAlign: !disableMaxWidth ? "right" : undefined } }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

interface IUnitAdornmentProps {
  size: SelectProps["size"];
  metaData: IInputParameterValueMetaDataValueWithUnit;
  selectedUnit: DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit;
  onChange: (
    unit: DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit,
  ) => void;
  disabled: boolean | undefined;
}

const UnitAdornment: FC<IUnitAdornmentProps> = ({
  size,
  selectedUnit,
  onChange,
  disabled,
  metaData,
}) => {
  return (
    <Select
      size={size}
      variant="standard"
      disableUnderline
      value={selectedUnit}
      onChange={(evt) =>
        onChange(
          evt.target
            .value as DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnit,
        )
      }
      disabled={disabled}
    >
      {DataEntryObjectInputParameterValueDefinitionForValueWithUnitAvailableUnitGroups[
        metaData.unitGroup
      ].map((unit) => (
        <MenuItem key={unit} value={unit}>
          {unit}
        </MenuItem>
      ))}
    </Select>
  );
};
