import {
  IOrganizationUrlData,
  IRecordingPeriodDmaUrlData,
  IRecordingPeriodUrlData,
} from "./app-url.types";
import {
  DMA_CATEGORY_QUERY_PARAM,
  EDIT_FINANCIAL_EFFECT_QUERY_PARAM,
  EDIT_MATERIAL_IMPACT_QUERY_PARAM,
  ESRS_TOPIC_QUERY_PARAM,
} from "./app-url.constants";

export class AppUrlUtilities {
  public static getUrlForOrganization({ origin, organizationId }: IOrganizationUrlData): URL {
    return new URL(`${origin}/organizations/${organizationId}`);
  }

  public static getUrlForRecordingPeriod({
    recordingPeriodId,
    ...orgData
  }: IRecordingPeriodUrlData): URL {
    return new URL(
      `${AppUrlUtilities.getUrlForOrganization(orgData)}/recording-periods/${recordingPeriodId}`,
    );
  }

  public static getUrlForRecordingPeriodDma({
    esrsTopicId,
    dmaCategoryId,
    editMaterialImpactId,
    editFinancialEffectId,
    ...recordingPeriodData
  }: IRecordingPeriodDmaUrlData): URL {
    const url = new URL(
      `${AppUrlUtilities.getUrlForRecordingPeriod(
        recordingPeriodData,
      )}/double-materiality-assessment`,
    );

    if (esrsTopicId) {
      url.searchParams.set(ESRS_TOPIC_QUERY_PARAM, esrsTopicId);
    }
    if (dmaCategoryId) {
      url.searchParams.set(DMA_CATEGORY_QUERY_PARAM, dmaCategoryId);
    }
    if (editMaterialImpactId) {
      url.searchParams.set(EDIT_MATERIAL_IMPACT_QUERY_PARAM, editMaterialImpactId);
    }
    if (editFinancialEffectId) {
      url.searchParams.set(EDIT_FINANCIAL_EFFECT_QUERY_PARAM, editFinancialEffectId);
    }

    return url;
  }
}
