import { FC, ReactNode } from "react";
import { Input, InputAdornment } from "@mui/material";
import { Search } from "@mui/icons-material";
import { IFilterConfig } from "./filter.type";
import { useTranslation } from "react-i18next";

interface IFilterOpenSearchProps {
  filter: IFilterConfig;
  searchParams: URLSearchParams;
  handleChange: (name: string, value: string | string[]) => void;
  endIcon?: ReactNode;
}

export const FilterOpenSearch: FC<IFilterOpenSearchProps> = ({
  filter,
  searchParams,
  handleChange,
  endIcon,
}) => {
  const { t } = useTranslation("filter_bar");
  return (
    <Input
      key={filter.name}
      placeholder={filter.label ?? t("search")}
      value={searchParams.get(filter.name) || ""}
      onChange={(e) => handleChange(filter.name, e.target.value)}
      size="small"
      endAdornment={
        <InputAdornment position="end">
          {endIcon ?? <Search sx={{ color: "rgba(0, 0, 0, 0.5)" }} />}
        </InputAdornment>
      }
      sx={{
        alignItems: "center",
        justifyContent: "center",
        height: "32px",
        maxWidth: "140px",
        "& input": {
          fontSize: "14px",
          padding: 0,
          marginBottom: -0.5,
        },
      }}
    />
  );
};
