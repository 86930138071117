import { ILocalDataEntryObjectInputParameterValueData } from "../interfaces/local-data-entry-object-values.interfaces";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { DateErrorType } from "../input-components/data-entry-object-value-date-range-edit.component";
import { DateTime } from "luxon";
import { FormatUtilities } from "../../common/utilities/format.utilities";

export const useGenerateDateError = (value: ILocalDataEntryObjectInputParameterValueData) => {
  const { t } = useTranslation("data_entry_object_values_edit");

  return useCallback(
    (error: DateErrorType | null, minDate: DateTime, maxDate: DateTime) => {
      if (error === null) {
        return null;
      }

      if (
        error !== "invalidDate" &&
        value.enteredForDateStart !== null &&
        value.enteredForDateEnd !== null &&
        value.enteredForDateEnd < value.enteredForDateStart
      ) {
        return t("date_error_end_before_start");
      }

      const translatedError: { [key in DateErrorType]: string } = {
        minDate: t("date_error_before_start", {
          minDate: FormatUtilities.formatDate(minDate.toJSDate()),
        }),
        maxDate: t("date_error_after_end", {
          maxDate: FormatUtilities.formatDate(maxDate.toJSDate()),
        }),
        invalidDate: t("date_error_invalid"),
      };

      return translatedError[error];
    },
    [t, value.enteredForDateStart, value.enteredForDateEnd],
  );
};
