/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IAddressedMatterS2Enum = {
  WorkingConditionsForValueChainWorkersMemberEsrsSecureEmploymentForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SecureEmploymentForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsWorkingTimeForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorkingTimeForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsAdequateWagesForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:AdequateWagesForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsSocialDialogueForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:SocialDialogueForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsFreedomOfAssociationForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:FreedomOfAssociationForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsCollectiveBargainingForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:CollectiveBargainingForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsWorklifeBalanceForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:WorklifeBalanceForValueChainWorkersMember",
  WorkingConditionsForValueChainWorkersMemberEsrsHealthAndSafetyForValueChainWorkersMember:
    "esrs:WorkingConditionsForValueChainWorkersMember.esrs:HealthAndSafetyForValueChainWorkersMember",
  EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMemberEsrsGenderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:GenderEqualityAndEqualPayForWorkOfEqualValueForValueChainWorkersMember",
  EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMemberEsrsTrainingAndSkillsDevelopmentForValueChainWorkersMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:TrainingAndSkillsDevelopmentForValueChainWorkersMember",
  EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMemberEsrsEmploymentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:EmploymentAndInclusionOfPersonsWithDisabilitiesForValueChainWorkersMember",
  EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMemberEsrsMeasuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:MeasuresAgainstViolenceAndHarassmentInWorkplaceForValueChainWorkersMember",
  EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMemberEsrsDiversityForValueChainWorkersMember:
    "esrs:EqualTreatmentAndOpportunitiesForAllForValueChainWorkersMember.esrs:DiversityForValueChainWorkersMember",
  OtherWorkrelatedRightsForValueChainWorkersMemberEsrsChildLabourForValueChainWorkersMember:
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ChildLabourForValueChainWorkersMember",
  OtherWorkrelatedRightsForValueChainWorkersMemberEsrsForcedLabourForValueChainWorkersMember:
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:ForcedLabourForValueChainWorkersMember",
  OtherWorkrelatedRightsForValueChainWorkersMemberEsrsAdequateHousingForValueChainWorkersMember:
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:AdequateHousingForValueChainWorkersMember",
  OtherWorkrelatedRightsForValueChainWorkersMemberEsrsWaterAndSanitationForValueChainWorkersMember:
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:WaterAndSanitationForValueChainWorkersMember",
  OtherWorkrelatedRightsForValueChainWorkersMemberEsrsPrivacyForValueChainWorkersMember:
    "esrs:OtherWorkrelatedRightsForValueChainWorkersMember.esrs:PrivacyForValueChainWorkersMember",
  OtherWorkersInValueChainSubtopicMember: "esrs:OtherWorkersInValueChainSubtopicMember",
} as const;

export type IAddressedMatterS2Enum =
  (typeof IAddressedMatterS2Enum)[keyof typeof IAddressedMatterS2Enum];
