import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getActionsQueryKey = (organizationId: string, recordingPeriodId: string) => [
  "actions",
  organizationId,
  recordingPeriodId,
];

export const useActionsQuery = (organizationId: string, recordingPeriodId: string) => {
  return useQuery({
    queryKey: getActionsQueryKey(organizationId, recordingPeriodId),
    queryFn: () =>
      CoreApiService.ActionsApi.getActions(organizationId, recordingPeriodId)
        .then((req) => req())
        .then((res) => res.data.actions),
  });
};
