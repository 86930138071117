import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Box, Divider, Menu, MenuItem } from "@mui/material";
import { SearchIcon } from "../constants/tabler-icon.constants";
import { useDebounce } from "@uidotdev/usehooks";
import { SearchTextInput } from "./search-text-input.component";

interface ISearchableMenuProps<T> {
  show: boolean;
  anchorEl: HTMLElement | null;
  options: T[];
  filterOptions: (search: string, options: T[]) => T[];
  /** Set key property on outer most element and DO NOT use fragments (MUI will shout at you) */
  buildMenuItem: (item: T) => ReactNode;
  onClose: VoidFunction;
  // Texts
  noOptionsPresentText: string;
  noOptionsFoundText: string;
  searchPlaceholderOverride?: string;
  // Menu extra Sections
  bottomSection?: ReactNode;
}

export function SearchableMenu<T>({
  show,
  anchorEl,
  options,
  filterOptions,
  buildMenuItem,
  onClose,
  // Texts
  noOptionsPresentText,
  noOptionsFoundText,
  searchPlaceholderOverride,
  bottomSection,
}: ISearchableMenuProps<T>) {
  const textInputRef = useRef<HTMLInputElement | null>(null);

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (show) {
      setTimeout(() => textInputRef.current?.focus(), 100);
    } else {
      setTimeout(() => setSearch(""), 100);
    }
  }, [show]);

  const debouncedSearch = useDebounce(search, 250);

  const filteredOptions = useMemo(
    () => filterOptions(debouncedSearch, options),
    [debouncedSearch, filterOptions, options],
  );

  const builtItems = useMemo(
    () => filteredOptions.map(buildMenuItem),
    [filteredOptions, buildMenuItem],
  );

  return (
    <Menu open={show} anchorEl={anchorEl} onClose={onClose}>
      <SearchTextInput
        search={search}
        onSearchChange={setSearch}
        placeholderOverride={searchPlaceholderOverride}
        textFieldProps={{
          inputRef: textInputRef,
          InputProps: {
            endAdornment: <SearchIcon />,
            sx: { px: 2, pb: 1 },
          },
        }}
      />
      <Box sx={{ overflowY: "auto", maxHeight: "55dvh", minWidth: 360, pt: 1 }}>
        {/* Show empty message if no options to show */}
        {options.length === 0 && <MenuItem disabled>{noOptionsPresentText}</MenuItem>}
        {/* Show empty message if no options found for search */}
        {options.length > 0 && filteredOptions.length === 0 && (
          <MenuItem disabled>{noOptionsFoundText}</MenuItem>
        )}

        {/* List Users as Items */}
        {builtItems}
      </Box>
      {bottomSection && (
        <Box>
          <Divider sx={{ mb: 1.5 }} />
          {bottomSection}
        </Box>
      )}
    </Menu>
  );
}
