import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useStakeholdersQuery } from "../../modules/stakeholder/stakeholder.queries";
import { StakeholdersConfiguration } from "../../modules/stakeholder/stakeholders-configuration.component";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";

interface IStakeholderConfigurationPageProps {}
export const StakeholderConfigurationPage: FC<IStakeholderConfigurationPageProps> = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/stakeholder`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/stakeholder`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const stakeholderQuery = useStakeholdersQuery(organizationId);

  return (
    <Box p={4}>
      <QueryWrapper
        query={stakeholderQuery}
        loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
        build={(stakeholder) => (
          <StakeholdersConfiguration
            organizationId={organizationId ?? ""}
            stakeholdersResponse={stakeholder}
          />
        )}
      />
    </Box>
  );
};
