import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useOrganizations } from "../../modules/organizations/organizations.hook";

interface IOrganizationsRedirectPageProps {}

export const OrganizationsRedirectPage: FC<IOrganizationsRedirectPageProps> = () => {
  const { t } = useTranslation("organizations_redirect_page");

  const organizations = useOrganizations();

  return organizations.length > 0 ? (
    <Navigate to={`/organizations/${organizations[0].id}`} replace />
  ) : (
    <Typography variant="body1">{t("no_associated_organizations")}</Typography>
  );
};
