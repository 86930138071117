import {
  Box,
  Divider,
  Fade,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { useUserContext } from "../../user/user.context";
import { SIDE_BAR_TRANSITION_DURATION } from "../components/side-bar.component";
import {
  ChevronDownIcon,
  IconSize,
  LanguageIcon,
  LogoutIcon,
  UserIcon,
} from "../constants/tabler-icon.constants";
import { AVAILABLE_LANGUAGE, AVAILABLE_LANGUAGE_KEY } from "@netcero/netcero-common";

interface ISideBarUserSectionProps {
  expanded: boolean;
}

export const SideBarUserSection: FC<ISideBarUserSectionProps> = ({ expanded }) => {
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const { user } = useUserContext();
  const intercom = useIntercom();

  const userMenuAnchorElement = useRef<null | HTMLButtonElement>(null);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const handleOpenUserMenu = () => {
    setUserMenuOpen(true);
  };
  const handleCloseUserMenu = () => {
    setUserMenuOpen(false);
  };

  const [languageSelectOpen, setLanguageSelectOpen] = useState(false);
  const handleOpenLanguageSelect = useCallback(() => setLanguageSelectOpen(true), []);
  const handleCloseLanguageSelect = useCallback(() => setLanguageSelectOpen(false), []);
  const toggleLanguageSelect = useCallback(
    // Required to prevent the menu from closing and reopening when clicking the select (--> NOT callback variant used)
    () => setLanguageSelectOpen(!languageSelectOpen),
    [languageSelectOpen],
  );

  const handleLogout = async () => {
    void keycloak.logout();
    intercom.shutdown();
    handleCloseUserMenu();
  };

  const onChangeLanguage = async (language: string) => {
    await i18n.changeLanguage(language);
  };

  const userFullName = useMemo(() => {
    return `${user?.userProfile.firstName} ${user?.userProfile.lastName}`;
  }, [user]);

  return (
    user && (
      <>
        <Fade appear in>
          {/* User Display */}
          <Box
            ref={userMenuAnchorElement}
            display="flex"
            alignItems="center"
            gap={1}
            mx="auto"
            py={1}
            px={1.25}
            onClick={handleOpenUserMenu}
            sx={{
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <UserIcon />
            <Typography
              whiteSpace="nowrap"
              overflow="hidden"
              sx={{
                opacity: expanded ? 1 : 0,
                transition: `opacity ${SIDE_BAR_TRANSITION_DURATION}ms`,
              }}
            >
              {userFullName}
            </Typography>
          </Box>
        </Fade>
        {/* Menu */}
        <Menu
          id="user-menu"
          anchorEl={userMenuAnchorElement.current}
          open={userMenuOpen}
          onClose={handleCloseUserMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            dense: true,
          }}
        >
          <Box display="flex" alignItems="center" px={2}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <UserIcon />
            </ListItemIcon>
            <ListItemText>{userFullName}</ListItemText>
          </Box>

          <MenuItem onClick={toggleLanguageSelect}>
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <Select
              open={languageSelectOpen}
              onOpen={handleOpenLanguageSelect}
              onClose={handleCloseLanguageSelect}
              value={i18n.language as AVAILABLE_LANGUAGE_KEY}
              onChange={(event) => onChangeLanguage(event?.target.value)}
              variant="standard"
              fullWidth
              disableUnderline
              IconComponent={() => (
                <Box display="flex" sx={{ cursor: "pointer" }}>
                  <ChevronDownIcon size={IconSize.Small} />
                </Box>
              )}
              sx={{
                pr: 0,
              }}
            >
              {Object.entries(AVAILABLE_LANGUAGE).map(([lng, lngDisplayName]) => (
                <MenuItem key={lng} value={lng}>
                  <Box mt={0.5}>
                    <ListItemText>{lngDisplayName}</ListItemText>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </MenuItem>
          <Divider sx={{ my: 1 }} />
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t("logout_button_text")}</ListItemText>
          </MenuItem>
        </Menu>
      </>
    )
  );
};
