import { forwardRef } from "react";
import {
  CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES,
  ClickableIndicatorWhiteOverlay,
} from "./clickable-indicator-white-overlay.component";
import { Box, Stack, Typography } from "@mui/material";
import { ChevronDownIcon, ChevronUpIcon } from "../constants/tabler-icon.constants";

interface IBaseStatusIndicatorProps {
  onClick?: VoidFunction;
  disabled?: boolean;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
  isFilled?: boolean;
  color: string;
  label: string;
}

export const BaseStatusIndicator = forwardRef<HTMLDivElement, IBaseStatusIndicatorProps>(
  function BaseStatusIndicator(
    { onClick, disabled, isMenuOpen, shouldRenderChevronIcon, isFilled, color, label },
    ref,
  ) {
    return (
      <Box
        ref={ref}
        onClick={!disabled ? onClick : undefined}
        bgcolor={isFilled ? color : undefined}
        px={1}
        py={0.25}
        borderRadius={2}
        border={isFilled ? undefined : `1px solid ${color}`}
        overflow="hidden"
        sx={{ ...CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES, flexShrink: 0 }}
      >
        <Stack direction="row" gap={0.5} alignItems="center" style={{ opacity: 0.6 }} color="black">
          <Typography
            flex={1}
            textAlign="center"
            style={{
              fontSize: "12px",
              lineHeight: "24px",
              fontWeight: 400,
              letterSpacing: "0.15px",
              whiteSpace: "nowrap",
            }}
          >
            {label}
          </Typography>
          {shouldRenderChevronIcon && (
            <Box flexShrink={0} lineHeight={0}>
              {isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </Box>
          )}
        </Stack>
        <ClickableIndicatorWhiteOverlay disabled={disabled || !onClick} />
      </Box>
    );
  },
);
