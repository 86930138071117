import { useMemo } from "react";
import { AuthenticationUtilities } from "@netcero/netcero-common";
import { useUserContext } from "../user.context";

export const useKeycloakOrganizationId = (organizationId: string) => {
  const { user } = useUserContext();

  const keycloakOrganizationId = useMemo(() => {
    if (!user) {
      return null;
    }

    return AuthenticationUtilities.findKeycloakOrganizationIdForNetCeroOrganizationId(
      user.jwtPayload,
      organizationId,
    );
  }, [organizationId, user]);

  return keycloakOrganizationId;
};
