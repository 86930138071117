import { UUID } from "../common";

export interface IJwtOrganizationInfo {
  name: string;
  attributes: {
    // TODO: This is wrongly typed
    netcero_id: UUID[];
    deo_count_max: number;
    full_access?: string[];
    esrs_access?: string[];
    dma_access?: string[];
    carbon_accounting_access?: string[];
  };
}

// TODO: The payload format is a Work in Progress
export interface IJwtPayload {
  exp: number;
  iat: number;
  auth_time: number;
  jti: UUID;
  iss: string;
  aud: string;
  sub: UUID;
  typ: string;
  azp: string;
  session_state: UUID;
  acr: string;
  "allowed-origins": string[];
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    };
  };
  scope: string;
  sid: UUID;
  email_verified: boolean;
  organization_mapping: Record<UUID, IJwtOrganizationInfo>;
  organizations: {
    [key: UUID]: {
      roles: string[];
      name: string;
    };
  };
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

/**
 * This class provides a thin wrapper over the raw JWT data
 * to rename properties / make them more developer-friendly
 */
export class ParsedUser {
  /**
   * The ID of the user within keycloak.
   */
  public readonly userId: string;

  /**
   * A combination of firstname + lastname which can be used for displaying purposes
   */
  public readonly displayName: string;

  constructor(from: IJwtPayload) {
    this.userId = from.sub;
    this.displayName = from.name;
  }
}

export enum AppModule {
  DMA = "dma",
  ESRS = "esrs",
  CARBON_ACCOUNTING = "carbon_accounting",
}
