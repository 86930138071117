import { useMemo } from "react";
import { useDmaStatusQuery } from "../queries/data-entry-object-dma-status.queries";
import { DMAUtilities } from "@netcero/netcero-dma";

interface ITopicMaterialityHookParams {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  categoryIdentifier: string;
  allowIncomplete: boolean;
}

export const useTopicMateriality = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  categoryIdentifier,
  allowIncomplete,
}: ITopicMaterialityHookParams) => {
  const dmaStatusQuery = useDmaStatusQuery(organizationId, recordingPeriodId, dataEntryObjectId);

  return useMemo(() => {
    return dmaStatusQuery.data
      ? DMAUtilities.isTopicInDmaStatusMaterial(
          dmaStatusQuery.data,
          categoryIdentifier,
          allowIncomplete,
        )
      : null;
  }, [dmaStatusQuery.data, categoryIdentifier, allowIncomplete]);
};
