import { FieldValues } from "react-hook-form";
import { IMDRNumberWithUnitInput } from "../../mdr-input.types";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import { IMdrUnit } from "@netcero/netcero-core-api-client";
import {
  DEFAULT_MDR_UNITS_BY_TYPE,
  MdrUnitGroup,
  NumberUtilities,
  ORDERED_MDR_UNITS_ALL,
  ORDERED_MDR_UNITS_BY_TYPE,
} from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";

export function NumberWithUnitInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
  disabled,
}: IMDRControlledInputProps<T, IMDRNumberWithUnitInput>) {
  const unitGroup = valueMetaData.unitGroup;

  const [selectedUnit, setSelectedUnit] = useState(
    field.value?.unit ??
      (unitGroup ? DEFAULT_MDR_UNITS_BY_TYPE[unitGroup] : ORDERED_MDR_UNITS_ALL[0]),
  );

  useEffect(() => {
    if (field.value?.unit) {
      setSelectedUnit(field.value.unit);
    }
  }, [field.value?.unit]);

  const handleValueChange = useCallback(
    (newValue: string) => {
      const numericValue = +newValue;
      field.onChange(
        newValue === "" || Number.isNaN(numericValue)
          ? null
          : {
              unit: selectedUnit,
              value: numericValue,
            },
      );
    },
    [field, selectedUnit],
  );

  const handleUnitChange = useCallback(
    (unit: IMdrUnit) => {
      setSelectedUnit(unit);

      // Only emit onChange if value is valid
      if (field.value?.value !== undefined) {
        field.onChange({
          unit,
          value: field.value.value,
        });
      }
    },
    [field],
  );

  return (
    <FormControl
      error={!!error}
      fullWidth
      sx={{
        maxWidth: NON_FULL_WIDTH_WIDTH,
        minWidth: 250,
        pl: isConditional ? 6 : 0,
      }}
    >
      <TextField
        required={valueMetaData.required}
        label={label}
        value={field.value?.value ?? ""}
        type="number"
        disabled={disabled}
        error={!!error}
        onChange={(e) =>
          handleValueChange(
            NumberUtilities.extractNumberFromStringWithPrecision(e.target.value, 4) ?? "",
          )
        }
        inputProps={{
          step: Math.pow(10, -4), // Limit precision to 4 decimal places
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Box display="flex">
                <Divider orientation="vertical" flexItem sx={{ borderColor: "#C1C1C1" }} />
                <UnitAdornment
                  selectedUnit={selectedUnit}
                  onChange={handleUnitChange}
                  group={unitGroup}
                  disabled={disabled}
                />
              </Box>
            </InputAdornment>
          ),
        }}
        sx={{ input: { textAlign: "right" } }}
      />
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
}

interface IUnitAdornmentProps {
  group: MdrUnitGroup | undefined;
  selectedUnit: IMdrUnit;
  onChange: (unit: IMdrUnit) => void;
  disabled?: boolean;
}

const UnitAdornment: FC<IUnitAdornmentProps> = ({ group, selectedUnit, onChange, disabled }) => {
  const { t } = useTranslation("mdr_common");

  const availableUnits = useMemo(
    () => (group ? ORDERED_MDR_UNITS_BY_TYPE[group] : ORDERED_MDR_UNITS_ALL),
    [group],
  );

  const unitDisplayText: Record<IMdrUnit, string> = useMemo(() => {
    const perMonetarySuffix = ` / ${t("mdr_unit_per_monetary_suffix")}`;
    return {
      // Mass
      [IMdrUnit.G]: "g",
      [IMdrUnit.Kg]: "kg",
      [IMdrUnit.T]: "t",
      // Energy
      [IMdrUnit.J]: "J",
      [IMdrUnit.KJ]: "kJ",
      [IMdrUnit.Mj]: "MJ",
      [IMdrUnit.KWh]: "kWh",
      [IMdrUnit.Mwh]: "MWh",
      [IMdrUnit.Gwh]: "GWh",
      [IMdrUnit.Twh]: "TWh",
      // Energy Per Monetary
      [IMdrUnit.JPerMmEuro]: "J" + perMonetarySuffix,
      [IMdrUnit.KJPerMmEuro]: "kJ" + perMonetarySuffix,
      [IMdrUnit.MjPerMmEuro]: "MJ" + perMonetarySuffix,
      [IMdrUnit.KWhPerMmEuro]: "kWh" + perMonetarySuffix,
      [IMdrUnit.MwhPerMmEuro]: "MWh" + perMonetarySuffix,
      [IMdrUnit.GwhPerMmEuro]: "GWh" + perMonetarySuffix,
      [IMdrUnit.TwhPerMmEuro]: "TWh" + perMonetarySuffix,
      // GHG Emissions
      [IMdrUnit.TCo2e]: "tCO₂e",
      [IMdrUnit.KtCo2e]: "ktCO₂e",
      [IMdrUnit.MtCo2e]: "MtCO₂e",
      [IMdrUnit.GtCo2e]: "GtCO₂e",
      // GHG Emissions Per Monetary
      [IMdrUnit.TCo2ePerMmEuro]: "tCO₂e" + perMonetarySuffix,
      [IMdrUnit.KtCo2ePerMmEuro]: "ktCO₂e" + perMonetarySuffix,
      [IMdrUnit.MtCo2ePerMmEuro]: "MtCO₂e" + perMonetarySuffix,
      [IMdrUnit.GtCo2ePerMmEuro]: "GtCO₂e" + perMonetarySuffix,
      // Volume
      [IMdrUnit.L]: "l",
      [IMdrUnit.M3]: "m³",
      // Volume Per Monetary
      [IMdrUnit.LPerMmEuro]: "l" + perMonetarySuffix,
      [IMdrUnit.M3PerMmEuro]: "m³" + perMonetarySuffix,
      // Area
      [IMdrUnit.M2]: "m²",
      [IMdrUnit.Ha]: "ha",
      [IMdrUnit.Km2]: "km²",
      // Monetary
      [IMdrUnit.Euro]: "€",
    };
  }, [t]);

  const getUnitOptionLabel = useCallback(
    (option: IMdrUnit) => {
      return unitDisplayText[option];
    },
    [unitDisplayText],
  );

  const groupBy = useMemo(() => {
    if (group) {
      return undefined;
    }

    return (option: IMdrUnit) => {
      const translationKey =
        Object.entries(ORDERED_MDR_UNITS_BY_TYPE).find(([_, value]) =>
          value.includes(option),
        )?.[0] ?? "other";
      return t(`mdr_unit_groups.${translationKey}`);
    };
  }, [t, group]);

  return (
    <Autocomplete
      size="small"
      disableClearable
      autoHighlight
      autoSelect
      value={selectedUnit}
      onChange={(_, newValue) => onChange(newValue)}
      options={availableUnits}
      getOptionLabel={getUnitOptionLabel}
      groupBy={groupBy}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          sx={{ minWidth: 200 }}
        />
      )}
      disabled={disabled}
    />
  );
};
