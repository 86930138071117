import { createContext, useContext } from "react";
import { useGapAnalysisApiActions } from "./gap-analysis-api-actions.hook";

export interface IGapAnalysisContextData extends ReturnType<typeof useGapAnalysisApiActions> {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
}

const GapAnalysisContext = createContext<IGapAnalysisContextData | null>(null);
export const useGapAnalysisContext = () => {
  const context = useContext(GapAnalysisContext);
  if (!context) {
    throw new Error("useGapAnalysisContext must be used within a GapAnalysisProvider");
  }
  return context;
};
export const GapAnalysisProvider = GapAnalysisContext.Provider;
