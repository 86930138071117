import { Alert } from "@mui/material";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { IDependencyMap } from "../utilities/data-entry-object-values.utilities";

interface IDataEntryObjectValueInfoComponent {
  inputParameterId: string;
  dependencyMap: IDependencyMap;
}

export const DataEntryObjectValueInfoComponent = ({
  inputParameterId,
  dependencyMap,
}: IDataEntryObjectValueInfoComponent) => {
  const { t } = useTranslation("data_entry_object_values_group");
  const translateContent = useTranslateContent();

  const affectedParentName = useMemo(() => {
    const result = dependencyMap[inputParameterId]?.name ?? null;
    if (result === null) {
      console.error("Affected parent name not found for input parameter id: ", inputParameterId);
    }
    return result;
  }, [dependencyMap, inputParameterId]);

  // Catch not found (shouldn't occur)
  if (affectedParentName === null) {
    return null;
  }

  return (
    dependencyMap[inputParameterId]?.showAlert && (
      <Alert key={inputParameterId} severity="info" sx={{ my: 1 }}>
        {t("dependency_mismatch_info", {
          ns: "data_entry_object_values_overview_common",
          parameterName: translateContent(affectedParentName),
        })}
      </Alert>
    )
  );
};
