import { FC, PropsWithChildren } from "react";
import { Box, Collapse, Divider } from "@mui/material";
import { useLexicalEditable } from "@lexical/react/useLexicalEditable";

interface ILexicalToolbarPluginProps {}

export const LexicalToolbarPlugin: FC<PropsWithChildren<ILexicalToolbarPluginProps>> = ({
  children,
}) => {
  const isEditable = useLexicalEditable();

  return (
    <Collapse in={isEditable} unmountOnExit timeout={200}>
      <Box>
        <Box display="flex" flexWrap="wrap" alignItems="center" gap={0.5} p={0.5}>
          {children}
        </Box>
        <Divider />
      </Box>
    </Collapse>
  );
};
