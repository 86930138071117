import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ISubmitStakeholderFeedbackRequest } from "@netcero/netcero-core-api-client";
import { CoreApiService } from "../core-api/core-api.service";
import { getStakeholderFeedbackQueryKey } from "./stakeholder-feedbacks.queries";

interface ISubmitStakeholderFeedbackMutationData {
  stakeholderId: string;
  recordingPeriodId: string;
  dmaCategoryId: string;
  payload: ISubmitStakeholderFeedbackRequest;
}

/**
 * Mutation for submitting the stakeholder feedback for a given category.
 * @returns Mutation to submit stakeholder feedback.
 */
export const useSubmitStakeholderFeedbackMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      stakeholderId,
      dmaCategoryId,
      payload,
      recordingPeriodId,
    }: ISubmitStakeholderFeedbackMutationData) =>
      CoreApiService.StakeholdersApi.submitStakeholderFeedback(
        stakeholderId,
        dmaCategoryId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getStakeholderFeedbackQueryKey(
          variables.stakeholderId,
          variables.recordingPeriodId,
        ),
      }),
  });
};
