import { useMemo } from "react";
import { HORIZONS_VALUES } from "./dma-horizons.constants";
import { Control, useFormState } from "react-hook-form";
import { IHorizonsEnum } from "@netcero/netcero-core-api-client";

type ICurrentHorizonsData = Record<IHorizonsEnum, { inheritsFromHorizon: IHorizonsEnum | null }>;

interface IHorizonDataForIsDirty {
  inheritsFromHorizon: IHorizonsEnum | null;
}

interface IDirtyFieldsForIRO {
  horizons: {
    shortTerm: IHorizonDataForIsDirty;
    mediumTerm: IHorizonDataForIsDirty;
    longTerm: IHorizonDataForIsDirty;
  };
}

export function useIroEditIsDirtyHook<T extends IDirtyFieldsForIRO>(
  control: Control<T>,
  currentHorizons: ICurrentHorizonsData,
) {
  const { dirtyFields } = useFormState({ control });

  const dirtyFieldsString = JSON.stringify(dirtyFields);
  return useMemo(() => {
    const keysWithoutHorizons = Object.keys(dirtyFields).filter((key) => key !== "horizons");
    if (keysWithoutHorizons.length > 0) {
      return true;
    }

    // Check if any horizon has been changed
    for (const horizonName of HORIZONS_VALUES) {
      // Always has changes if inheritsFromHorizon has changed
      if (dirtyFields.horizons?.[horizonName]?.inheritsFromHorizon) {
        return true;
      }

      // Skip if inheritsFromHorizon is set
      if (currentHorizons[horizonName].inheritsFromHorizon) {
        continue;
      }

      const horizonChanges = Object.keys(dirtyFields.horizons?.[horizonName] ?? {});
      if (horizonChanges.length > 0) {
        return true;
      }
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHorizons, dirtyFieldsString]);
}
