import { FC, useMemo } from "react";
import MultipleSelectChip from "../../../common/components/multiple-chip.component";
import { DMAUtilities } from "../../../double-materiality-assessment/utilities/dma.utilities";
import { AssociatedIROsDialog } from "./associated-iros.dialog";
import { useDialogStateWithoutData } from "../../../common/dialogs/dialog-state.hook";
import { IDMACategoryWithEffectsAndChildren } from "@netcero/netcero-core-api-client";

interface IAssociatedIROsSelectComponentProps {
  // Base Data
  label: string;
  disabled?: boolean;
  dmaCategories: IDMACategoryWithEffectsAndChildren[];
  // Value of component
  financialEffectIds: string[];
  materialImpactIds: string[];
  // Value change handlers of component
  onMaterialImpactIdsChange: (ids: string[]) => void;
  onFinancialEffectIdsChange: (ids: string[]) => void;
}

export const AssociatedIROsSelectComponent: FC<IAssociatedIROsSelectComponentProps> = ({
  label,
  disabled,
  dmaCategories,
  financialEffectIds,
  materialImpactIds,
  onMaterialImpactIdsChange,
  onFinancialEffectIdsChange,
}) => {
  const {
    isOpen: isIRODialogOpen,
    openDialog: openIRODialog,
    closeDialog: closeIRODialog,
  } = useDialogStateWithoutData();

  const chipNames = useMemo(
    () => DMAUtilities.mapIROIdsToTitles(dmaCategories, financialEffectIds, materialImpactIds),
    [dmaCategories, financialEffectIds, materialImpactIds],
  );

  return (
    <>
      <MultipleSelectChip
        label={label}
        onSelectClick={openIRODialog}
        fullWidth
        noMargin
        chipNames={chipNames}
        disabled={disabled}
      />
      <AssociatedIROsDialog
        open={isIRODialogOpen}
        selectedFinancialEffectIds={financialEffectIds}
        selectedMaterialImpactIds={materialImpactIds}
        onChangeFinancialEffectIds={onFinancialEffectIdsChange}
        onChangeMaterialImpactIds={onMaterialImpactIdsChange}
        onClose={closeIRODialog}
        // disable if any mutations are running
        disabled={disabled}
        dmaCategories={dmaCategories}
      />
    </>
  );
};
