import { IMDRInput } from "./mdr-input.types";
import {
  IAddressedMatterE1Enum,
  IAddressedMatterE2Enum,
  IAddressedMatterE3Enum,
  IAddressedMatterE4Enum,
  IAddressedMatterE5Enum,
  IAddressedMatterS1Enum,
  IAddressedMatterS2Enum,
  IAddressedMatterS3Enum,
  IAddressedMatterS4Enum,
} from "@netcero/netcero-core-api-client";

type IAddressedMattersTopics = "e1" | "e2" | "e3" | "e4" | "e5" | "s1" | "s2" | "s3" | "s4";
const ADDRESSED_MATTERS_PER_TOPIC: Record<IAddressedMattersTopics, Record<string, string>> = {
  e1: IAddressedMatterE1Enum,
  e2: IAddressedMatterE2Enum,
  e3: IAddressedMatterE3Enum,
  e4: IAddressedMatterE4Enum,
  e5: IAddressedMatterE5Enum,
  s1: IAddressedMatterS1Enum,
  s2: IAddressedMatterS2Enum,
  s3: IAddressedMatterS3Enum,
  s4: IAddressedMatterS4Enum,
};

export class MdrInputUtilities {
  private static getAddressedMattersInputConfiguration(topic: IAddressedMattersTopics): IMDRInput {
    return {
      type: "enum",
      mode: "multiple",
      initialValue: [],
      values: Object.values(ADDRESSED_MATTERS_PER_TOPIC[topic]),
    };
  }

  public static ADDRESSED_MATTERS_INPUT: Record<IAddressedMattersTopics, IMDRInput> = {
    e1: MdrInputUtilities.getAddressedMattersInputConfiguration("e1"),
    e2: MdrInputUtilities.getAddressedMattersInputConfiguration("e2"),
    e3: MdrInputUtilities.getAddressedMattersInputConfiguration("e3"),
    e4: MdrInputUtilities.getAddressedMattersInputConfiguration("e4"),
    e5: MdrInputUtilities.getAddressedMattersInputConfiguration("e5"),
    s1: MdrInputUtilities.getAddressedMattersInputConfiguration("s1"),
    s2: MdrInputUtilities.getAddressedMattersInputConfiguration("s2"),
    s3: MdrInputUtilities.getAddressedMattersInputConfiguration("s3"),
    s4: MdrInputUtilities.getAddressedMattersInputConfiguration("s4"),
  };

  public static TITLE_INPUT_CONFIGURATION: IMDRInput = {
    type: "text",
    initialValue: "",
    multiline: false,
    required: true,
  };

  public static TEXT_AREA_INPUT_CONFIGURATION: IMDRInput = {
    type: "text",
    initialValue: "",
    multiline: true,
  };

  public static BOOLEAN_INPUT_CONFIGURATION: IMDRInput = {
    type: "boolean",
    initialValue: false,
  };

  public static NUMBER_WITH_UNIT_INPUT_CONFIGURATION: IMDRInput = {
    type: "number-with-unit",
    initialValue: null,
  };
}
