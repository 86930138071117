import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IStakeholder } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "../common/constants/tabler-icon.constants";
import { CopyToClipboardButton } from "../common/components/copy-to-clipboard-button.component";
import { StakeholderLinksUtilities } from "./stakeholder-links.utilities";

interface IStakeholdersEditListProps {
  stakeholders: IStakeholder[];
  onDelete: (stakeholder: IStakeholder) => void;
  onEdit: (stakeholder: IStakeholder) => void;
}

export const StakeholdersEditList: FC<IStakeholdersEditListProps> = ({
  stakeholders,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation("stakeholders_configuration");

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("header_name")}</TableCell>
          <TableCell>{t("header_organization")}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {stakeholders.map((stakeholder) => (
          <TableRow key={stakeholder.id}>
            <TableCell>{stakeholder.name}</TableCell>
            <TableCell>{stakeholder.organization ?? "-"}</TableCell>
            <TableCell align="right">
              <Box>
                <CopyToClipboardButton
                  value={StakeholderLinksUtilities.getStakeholderFeedbackLink(stakeholder.id)}
                  tooltip={t("tooltip_copy_link", { ns: "stakeholder_feedback_common" })}
                  tooltipSuccess={t("tooltip_copy_link_success", {
                    ns: "buttons",
                  })}
                  tooltipPlacement="right"
                />
                <IconButton size="small" onClick={() => onEdit(stakeholder)}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(stakeholder)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
