import { useSearchUsersQueryInternal } from "../users.queries";
import { useMemo } from "react";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UseQueryResult } from "@tanstack/react-query";

export function sortUsers(users: IUserRepresentation[]): IUserRepresentation[] {
  return [...users].sort((a, b) => {
    if (a.firstName && b.firstName) {
      return a.firstName.localeCompare(b.firstName);
    }

    return 0;
  });
}

// TODO: Refactor to use select property
export function useSortedOrganizationUsersHook(
  organizationId: string,
): [IUserRepresentation[] | undefined, UseQueryResult<IUserRepresentation[], Error>] {
  const usersQuery = useSearchUsersQueryInternal(organizationId);

  // TODO: Quick and dirty, maybe refactor?
  // Sort users by first name
  const sortedUsers = useMemo(() => {
    if (usersQuery.data) {
      return sortUsers(usersQuery.data);
    }

    return usersQuery.data;
  }, [usersQuery.data]);

  return [sortedUsers, usersQuery];
}
