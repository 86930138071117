import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getStakeholdersQueryKey = (organizationId: string) => ["stakeholders", organizationId];

/**
 * Fetches all stakeholders for a given organization
 * @param organizationId Organization Id to get stakeholders for
 */
export const useStakeholdersQuery = (organizationId?: string) => {
  return useQuery({
    queryKey: getStakeholdersQueryKey(organizationId!),
    queryFn: () =>
      CoreApiService.StakeholdersApi.getAllStakeholders(organizationId!)
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!organizationId,
  });
};
