import { IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

interface ITranslatedStaticUnitNameProps {
  unit: IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum;
  withBrackets?: boolean;
}

export const TranslatedStaticUnitName: FC<ITranslatedStaticUnitNameProps> = ({
  unit,
  withBrackets,
}) => {
  const { t } = useTranslation("static_unit_name");
  const translated = useMemo(() => t(unit), [t, unit]);

  return withBrackets ? `[${translated}]` : translated;
};
