import {
  IDataEntryObject,
  IInputParameterRecordingStructureGroupTHGSC,
  ITranslatedProperty,
} from "@netcero/netcero-core-api-client";
import { GroupedCalculationResult } from "@netcero/netcero-common";

export interface IStructureResult {
  name: ITranslatedProperty;
  description: ITranslatedProperty | undefined;
  sums: Map<string, number>;
}

export interface IStructureOverviewResult {
  structure: IStructureResult;
  firstLevelChildren: IStructureResult[];
  orphanValues: Map<string, number>;
}

export class EvaluationUtilities {
  /**
   * Sum up all values for a data entry object and a structure group.
   * @param generalResult The entire result
   * @param currentDataEntryObject The current DEO
   * @param structureGroup The structure group to sum up the values for.
   * @param includeChildDeos If true, the values of all children of the data entry object will be included. Default: true
   * @param includeChildStructureGroups If true, the values of all children of the structure group will be included. Default: true
   * @returns A map with the calculation keys as keys and the sums as values.
   */
  public static sumUpTotalsForDataEntryObjectAndRecordingStructureGroup(
    generalResult: GroupedCalculationResult,
    currentDataEntryObject: IDataEntryObject,
    structureGroup: IInputParameterRecordingStructureGroupTHGSC,
    includeChildDeos: boolean = true,
    includeChildStructureGroups: boolean = true,
  ) {
    const inputParameterSums = EvaluationUtilities.sumUpCalculationResultsPerParameterId(
      generalResult,
      currentDataEntryObject,
      includeChildDeos,
    );

    return EvaluationUtilities.sumUpStructureGroup(
      inputParameterSums,
      structureGroup,
      includeChildStructureGroups,
      new Set<string>(),
    );
  }

  /**
   * Returns the total sums for all calculated values per input parameter id.
   */
  public static sumUpCalculationResultsPerParameterId(
    generalResult: GroupedCalculationResult,
    currentDataEntryObject: IDataEntryObject,
    includeChildren: boolean = true,
  ) {
    const calculationResult = generalResult[currentDataEntryObject.id] ?? {};
    const result = new Map<string, Map<string, number>>();

    for (const [inputParameterId, valuesForIP] of Object.entries(calculationResult)) {
      if (!result.has(inputParameterId)) {
        result.set(inputParameterId, new Map<string, number>());
      }

      const inputParameterResultsMap = result.get(inputParameterId)!;
      for (const [key, value] of Object.entries(valuesForIP)) {
        inputParameterResultsMap.set(key, (inputParameterResultsMap.get(key) ?? 0) + (value ?? 0));
      }
    }

    // Add Children
    if (includeChildren) {
      for (const child of currentDataEntryObject.children) {
        const childResult = EvaluationUtilities.sumUpCalculationResultsPerParameterId(
          generalResult,
          child,
        );

        childResult.forEach((resultsForChild, ipIdForChild) => {
          if (!result.has(ipIdForChild)) {
            result.set(ipIdForChild, new Map<string, number>());
          }

          const inputParameterResultsMap = result.get(ipIdForChild)!;
          resultsForChild.forEach((value, calculationKey) => {
            inputParameterResultsMap.set(
              calculationKey,
              (inputParameterResultsMap.get(calculationKey) ?? 0) + value,
            );
          });
        });
      }
    }

    return result;
  }

  /**
   * Sums up all values for a structure group and the groups first level children.
   */
  public static sumUpCalculationResultsForStructureGroupOverview(
    generalResult: GroupedCalculationResult,
    currentDataEntryObject: IDataEntryObject,
    structure: IInputParameterRecordingStructureGroupTHGSC,
    includeChildren: boolean,
  ): IStructureOverviewResult {
    const resultsPerParameter = EvaluationUtilities.sumUpCalculationResultsPerParameterId(
      generalResult,
      currentDataEntryObject,
      includeChildren,
    );

    const usedInputParameterIds = new Set<string>();

    const resultOfStructureGroup = {
      name: structure.name,
      description: structure.description,
      sums: EvaluationUtilities.sumUpStructureGroup(
        resultsPerParameter,
        structure,
        false,
        usedInputParameterIds,
      ),
    };

    const resultFirstLevelChildren = structure.children.map((child) => ({
      name: child.name,
      description: child.description,
      sums: EvaluationUtilities.sumUpStructureGroup(
        resultsPerParameter,
        child,
        true,
        usedInputParameterIds,
      ),
    }));

    // Get sum of unused (orphan) input parameters

    const unusedInputParameterIds = Array.from(resultsPerParameter.keys()).filter(
      (parameterId) => !usedInputParameterIds.has(parameterId),
    );
    const orphansResult = EvaluationUtilities.sumUpInputParametersResults(
      resultsPerParameter,
      unusedInputParameterIds,
    );

    return {
      structure: resultOfStructureGroup,
      firstLevelChildren: resultFirstLevelChildren,
      orphanValues: orphansResult,
    };
  }

  /**
   * Utility function to sum up all values (calculation keys) for a raw result and optionally including
   * child deo results and a given structure group and optionally its child groups.
   * @param generalResult The entire result
   * @param currentDataEntryObject The current DEO
   * @param includeChildDEOs If true, the values of all children of the data entry object will be included.
   * @param structureToSumUp The structure group to sum up the values for.
   * @param includeChildStructureGroups If true, the values of all children of the structure group will be included.
   * @returns A map with the calculation keys as keys and the sums as values.
   */
  public static sumUpStructureGroupFromRawResult(
    generalResult: GroupedCalculationResult,
    currentDataEntryObject: IDataEntryObject,
    includeChildDEOs: boolean,
    structureToSumUp: IInputParameterRecordingStructureGroupTHGSC,
    includeChildStructureGroups: boolean,
  ) {
    const sumsPerInputParameter = EvaluationUtilities.sumUpCalculationResultsPerParameterId(
      generalResult,
      currentDataEntryObject,
      includeChildDEOs,
    );

    return EvaluationUtilities.sumUpStructureGroup(
      sumsPerInputParameter,
      structureToSumUp,
      includeChildStructureGroups,
      new Set<string>(), // Orphan values are not needed here
    );
  }

  /**
   * Sum up all values for a structure group and its children.
   * @param sumsPerInputParameter The sums per input parameter id.
   * @param structureToSumUp The structure group to sum up the values for.
   * @param includeChildren Whether to include the children of the structure group.
   * @param usedInputParameterIds Reference to a set that will be filled with the ids of all input parameters that were used.
   * @returns A map with the calculation keys as keys and the sums as values.
   */
  public static sumUpStructureGroup(
    sumsPerInputParameter: Map<string, Map<string, number>>,
    structureToSumUp: IInputParameterRecordingStructureGroupTHGSC,
    includeChildren: boolean,
    usedInputParameterIds: Set<string>,
  ) {
    const resultMap = new Map<string, number>();

    // Values for current level/data entry object
    structureToSumUp.inputParameterIds.forEach((inputParameterId) => {
      const inputParameterResults = sumsPerInputParameter.get(inputParameterId);
      if (inputParameterResults) {
        inputParameterResults.forEach((value, calculationKey) => {
          resultMap.set(calculationKey, (resultMap.get(calculationKey) ?? 0) + value);
        });
      }
      usedInputParameterIds.add(inputParameterId);
    });

    // Children
    if (includeChildren) {
      structureToSumUp.children.forEach((child) => {
        const childResult = EvaluationUtilities.sumUpStructureGroup(
          sumsPerInputParameter,
          child,
          includeChildren,
          usedInputParameterIds,
        );
        childResult.forEach((value, calculationKey) => {
          resultMap.set(calculationKey, (resultMap.get(calculationKey) ?? 0) + value);
        });
      });
    }

    return resultMap;
  }

  /**
   * Sums up all values for a list of input parameter ids.
   */
  public static sumUpInputParametersResults(
    sumsPerInputParameter: Map<string, Map<string, number>>,
    inputParameterIds: string[],
  ) {
    const results = new Map<string, number>();

    inputParameterIds.forEach((inputParameterId) => {
      const inputParameterResults = sumsPerInputParameter.get(inputParameterId);
      if (inputParameterResults) {
        inputParameterResults.forEach((value, calculationKey) => {
          results.set(calculationKey, (results.get(calculationKey) ?? 0) + value);
        });
      }
    });

    return results;
  }
}
