import { FC, useMemo } from "react";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { DisclosureRequirementInput } from "./disclosure-requirement-input.component";
import { useDeoEsrsInputContext } from "./deo-esrs-input.context";
import { useTranslation } from "react-i18next";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  IconSize,
} from "../../common/constants/tabler-icon.constants";

interface IDeoEsrsValuesOverviewContentProps {
  section: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection;
  onPreviousSection?: VoidFunction;
  onNextSection?: VoidFunction;
  materiality: boolean | null;
}

export const DeoEsrsValuesOverviewContent: FC<IDeoEsrsValuesOverviewContentProps> = ({
  section,
  onPreviousSection,
  onNextSection,
  materiality,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_common");
  const translateContent = useTranslateContent();
  const { conditionalDisplayInputParametersLookup } = useDeoEsrsInputContext();

  const filteredDisclosureRequirements = useMemo(() => {
    return section.disclosureRequirements.filter(
      (dr) =>
        !conditionalDisplayInputParametersLookup[dr.id] ||
        conditionalDisplayInputParametersLookup[dr.id]!.displayMode !== "hide",
    );
  }, [conditionalDisplayInputParametersLookup, section.disclosureRequirements]);

  return (
    <Box display="flex" flexDirection="column" gap={2} pt={2}>
      {/*Materiality Info Box*/}
      {materiality === false && (
        <Alert severity="warning">
          <AlertTitle>{t("materiality_info_box_not_material.title")}</AlertTitle>
          {t("materiality_info_box_not_material.text")}
        </Alert>
      )}
      {/* Section Title */}
      <Typography variant="h2" component="h2">
        {translateContent(section.name)}
      </Typography>
      {/* DRs */}
      {filteredDisclosureRequirements.map((disclosureRequirement, index) => {
        const isLastItem = index === section.disclosureRequirements.length - 1;
        return (
          <Box
            key={disclosureRequirement.id}
            minHeight={isLastItem ? "calc(100dvh - 96px)" : undefined}
          >
            <DisclosureRequirementInput disclosureRequirement={disclosureRequirement} />
            {isLastItem && (
              <Box display="flex" alignItems="center" mt={4}>
                {onPreviousSection && (
                  <Button variant="outlined" onClick={onPreviousSection} sx={{ gap: 0.5 }}>
                    <ChevronLeftIcon size={IconSize.Small} />
                    {t("buttons.previous_section")}
                  </Button>
                )}
                {onNextSection && (
                  <Button variant="outlined" onClick={onNextSection} sx={{ gap: 0.5, ml: "auto" }}>
                    {t("buttons.next_section")}
                    <ChevronRightIcon size={IconSize.Small} />
                  </Button>
                )}
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
