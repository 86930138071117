import { FC } from "react";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { useTranslation } from "react-i18next";
import { UndefinedOrNull, UserUtilities } from "@netcero/netcero-common";

interface IUserFullNameProps {
  user: UndefinedOrNull<IUserRepresentation>;
}

export const UserFullName: FC<IUserFullNameProps> = ({ user }) => {
  const { t } = useTranslation("users");
  return UserUtilities.formatUserFullName(user, t("anonymous_name"));
};
