import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useUpdateLastUsedRecordingPeriodLocalStorageFromRouter } from "./use-last-used-recording-period.hooks";

/**
 * Layout for the recording periods module. Updates lastUsedRecordingPeriod in local storage.
 */
export const RecordingPeriodLayout: FC = () => {
  useUpdateLastUsedRecordingPeriodLocalStorageFromRouter();
  return <Outlet />;
};
