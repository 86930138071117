import { IconButton, Tooltip } from "@mui/material";
import { IntercomReferencesIdentity } from "@netcero/netcero-common";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useIntercom } from "react-use-intercom";
import { HelpIcon, IconSize } from "../common/constants/tabler-icon.constants";
import { useIntercomReferenceForEntity } from "./intercom-references.context";

type IIntercomReferenceWidgetProps = IntercomReferencesIdentity & {
  renderIfNoArticleFound?: ReactNode;
  iconSize?: IconSize;
};

export const IntercomReferenceWidget: FC<IIntercomReferenceWidgetProps> = ({
  identifier,
  entityType,
  renderIfNoArticleFound,
  iconSize = IconSize.Medium,
}) => {
  const { t } = useTranslation("intercom_reference_widget_component");
  const getReference = useIntercomReferenceForEntity(entityType);
  const reference = useMemo(() => getReference(identifier), [identifier, getReference]);
  const { showArticle } = useIntercom();

  return reference === null ? (
    renderIfNoArticleFound ?? null
  ) : (
    <Tooltip title={t("tooltip")}>
      <span>
        <IconButton size="small" onClick={() => showArticle(reference)}>
          <HelpIcon size={iconSize} />
        </IconButton>
      </span>
    </Tooltip>
  );
};
