import { useMemo, FC } from "react";
import { Box, Button, Checkbox, Divider, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "../common/constants/tabler-icon.constants";
import { IFilterConfig } from "./filter.type";
import { NumberBadgeInline } from "../common/components/number-badge-inline.component";
import { LineClampTypographyWithTooltip } from "../common/components/line-clamp-typography.component";

interface IFilterSelectProps {
  filter: IFilterConfig;
  searchParams: URLSearchParams;
  handleChange: (name: string, value: string | string[]) => void;
}

export const FilterSelect: FC<IFilterSelectProps> = ({ filter, searchParams, handleChange }) => {
  const { t } = useTranslation("filter_bar");

  const filterParams = useMemo(() => searchParams.getAll(filter.name), [searchParams, filter.name]);

  return (
    <Select
      id={filter.name}
      value={filterParams}
      multiple={filter.multiple}
      onChange={(e) => {
        const value = e.target.value as string[];
        handleChange(filter.name, value);
      }}
      IconComponent={ChevronDownIcon}
      variant="standard"
      disableUnderline
      sx={{
        "& .MuiSelect-select": { backgroundColor: "transparent", py: 0.5 },
      }}
      displayEmpty
      renderValue={() => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            fontSize: "14px",
            boxSizing: "border-box",
          }}
        >
          {filter.label}
          <Box>
            <NumberBadgeInline count={filterParams.length} />
          </Box>
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          sx: {
            "& .Mui-selected": {
              backgroundColor: "transparent !important",
            },
            boxShadow:
              "rgba(9, 30, 66, 0.15) 0px 8px 12px 0px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px",
          },
        },
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
    >
      {filter.options?.map((option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ width: "300px", backgroundColor: "transparent", gap: 1 }}
        >
          <Checkbox
            size="small"
            checked={filterParams.includes(option.value)}
            sx={{ p: 0, ":hover": { backgroundColor: "transparent" } }}
          />

          <LineClampTypographyWithTooltip>{option.name}</LineClampTypographyWithTooltip>
        </MenuItem>
      ))}
      {filterParams.length > 0 && (
        <Box>
          <Divider sx={{ mb: 1.5 }} />
          <Button
            onClick={() => {
              handleChange(filter.name, []);
            }}
            sx={{ ml: 2 }}
          >
            {t("clear")}
          </Button>
        </Box>
      )}
    </Select>
  );
};
