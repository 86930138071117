import { IMaterialImpactHorizonCalculatorData } from "../types/material-impact-horizon-calculator-data.interface";
import { IMaterialImpactCalculatorData } from "../types/material-impact-calculator-data.type";
import { MaterialImpactCalculator } from "./material-impact.calculator";
import { IBaseCalculatorContext } from "../types/base-calculator-context.interface";

export class MaterialImpactHorizonsCalculator {
  private readonly shortTerm: IMaterialImpactCalculatorData;
  private readonly mediumTerm: IMaterialImpactCalculatorData;
  private readonly longTerm: IMaterialImpactCalculatorData;

  constructor(
    horizons: IMaterialImpactHorizonCalculatorData,
    private readonly context: IBaseCalculatorContext,
  ) {
    Object.assign(this, horizons);
  }

  private getCalculators(): MaterialImpactCalculator[] {
    return [this.shortTerm, this.mediumTerm, this.longTerm].map(
      (horizon) => new MaterialImpactCalculator(horizon, this.context),
    );
  }

  public calculateSeverity(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateSeverity()));
  }

  public calculateMaterialityDegree(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateMaterialityDegree()));
  }
}
