import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { AxiosError } from "axios";
import { IErrorModel } from "@netcero/netcero-core-api-client";

export type ITranslatableError = AxiosError | Partial<IErrorModel> | Error;

/**
 * Hook to translate an API error into a human-readable message.
 */
export const useTranslateError = () => {
  const { t } = useTranslation("error");

  return useCallback(
    (error: ITranslatableError) => {
      if (error instanceof AxiosError) {
        if (error.status === 0) {
          return t("connection", { keyPrefix: "axios" });
        }
        if (error.response?.status) {
          return t(`${error.response?.status}`, { keyPrefix: "generic" });
        }
        return `${t("unknown")}${"message" in error ? `: "${error.message}"` : ""}`;
      } else if ("errorCode" in error) {
        return t(`${error.errorCode}`);
      } else if ("statusCode" in error) {
        return `${t(`${error.statusCode}`, { keyPrefix: "generic" })}${
          "message" in error ? `: "${error.message}"` : ""
        }`;
      }

      return t("unknown");
    },
    [t],
  );
};
