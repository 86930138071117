import {
  DEPENDENCIES_FOR_TARGET,
  KeysForTarget,
  TARGET_INPUTS_DEPENDED_UPON,
} from "./dependencies";
import { IBaseTargetData, ITarget } from "@netcero/netcero-core-api-client";
import { MDRUtilities } from "../mdrs/mdrs.utilities";
import { pick } from "lodash";
import { WithEqualUndefinedAndNullRecursive } from "../common";

export class TargetsUtilities {
  /**
   * This method determines whether the input in question should be shown, based on the current state of the dialog.
   * @param inputInQuestion The input for which the state should be determined
   * @param value The current state of the dialog to base decision on
   */
  public static shouldInputBeShown(
    inputInQuestion: KeysForTarget,
    value: WithEqualUndefinedAndNullRecursive<IBaseTargetData>,
  ) {
    return MDRUtilities.shouldInputBeShown<KeysForTarget>(
      inputInQuestion,
      value,
      DEPENDENCIES_FOR_TARGET,
    );
  }

  /**
   * This method determines whether the input in question is depended upon by another input.
   * @param inputInQuestion The input for which to check whether other inputs depend on it
   */
  public static isInputDependedUponByAnother(inputInQuestion: KeysForTarget) {
    return TARGET_INPUTS_DEPENDED_UPON.includes(inputInQuestion);
  }

  /**
   * This method determines whether the input in question is conditional.
   * @param inputInQuestion The input for which to check whether it is conditional
   */
  public static isInputConditional(inputInQuestion: KeysForTarget) {
    return DEPENDENCIES_FOR_TARGET[inputInQuestion] !== undefined;
  }

  /**
   * This method converts a target to target data.
   * @param target The target to convert
   */
  public static convertTargetToTargetData(target: ITarget): IBaseTargetData {
    return pick(target, [
      "associatedTopics",
      "contributingUserIds",
      "responsibleUserId",
      "sourceIds",
      "general",
      "e",
      "s",
      "materialImpactIds",
      "financialEffectIds",
      "policyIds",
      "actionIds",
    ]);
  }
}
