import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getPoliciesQueryKey = (organizationId: string, recordingPeriodId: string) => [
  "policies",
  organizationId,
  recordingPeriodId,
];

export const usePoliciesQuery = (organizationId: string, recordingPeriodId: string) => {
  return useQuery({
    queryKey: getPoliciesQueryKey(organizationId, recordingPeriodId),
    queryFn: () =>
      CoreApiService.PoliciesApi.getPolicies(organizationId, recordingPeriodId)
        .then((req) => req())
        .then((res) => res.data.policies),
  });
};
