/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { IEnum } from "./ienum";

/**
 *
 * @export
 * @interface IInputParameterValueMetaDataNumberStaticUnit
 */
export interface IInputParameterValueMetaDataNumberStaticUnit {
  /**
   * Identifies the unit for the export
   * @type {IEnum}
   * @memberof IInputParameterValueMetaDataNumberStaticUnit
   */
  identifier: IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum;
}

export const IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum = {
  KgPerYear: "kg_per_year",
  Hours: "hours",
} as const;

export type IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum =
  (typeof IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum)[keyof typeof IInputParameterValueMetaDataNumberStaticUnitIdentifierEnum];
