import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getDistributionCriteriaQueryKey = (organizationId: string) => [
  "distribution-criteria",
  organizationId,
];

/**
 * Fetches all distribution criteria for a given organization
 * @param organizationId Organization Id to get distribution criteria for
 */
export const useDistributionCriteriaQuery = (organizationId?: string) => {
  return useQuery({
    queryKey: getDistributionCriteriaQueryKey(organizationId!),
    queryFn: () =>
      CoreApiService.DistributionCriteriaApi.listDistributionCriteria(organizationId!)
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!organizationId,
  });
};
