import { ITargetPath, ITargetPathState } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import ListItemText from "@mui/material/ListItemText";

export interface ISetTargetPathAsOrganizationTargetPathConfirmDialogContentComponentProps {
  targetPath: ITargetPath;
  currentTargetPath: ITargetPath | null;
}

export const SetTargetPathAsOrganizationTargetPathConfirmDialogContentComponent: FC<
  ISetTargetPathAsOrganizationTargetPathConfirmDialogContentComponentProps
> = ({ currentTargetPath, targetPath }) => {
  const { t } = useTranslation("target_path_dialogs");
  return (
    <>
      {/* Display the text for the target path to be toggled*/}
      {t(`confirm_toggle.for_state.${ITargetPathState.Draft}.body_1`, {
        targetPathName: targetPath.name,
      })}

      {/* If another path is currently active for the organization, also display it*/}
      {currentTargetPath !== null && (
        <>
          {" "}
          {t(`confirm_toggle.for_state.${ITargetPathState.Draft}.body_2`)}
          <List sx={{ width: "100%" }}>
            <ListItem>
              <ListItemText primary={currentTargetPath.name} />
            </ListItem>
          </List>
        </>
      )}
    </>
  );
};
