import { Box, Typography } from "@mui/material";
import { IStakeholderFeedback } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormatUtilities } from "../../../common/utilities/format.utilities";
import { DMACategoryUtilities } from "./dma-category.utilities";
import { DMA_MATERIALITY_MULTIPLIER } from "@netcero/netcero-dma";

interface IDMACategoryFeedbackViewProps {
  feedback: IStakeholderFeedback;
}
export const DMACategoryFeedbackView: FC<IDMACategoryFeedbackViewProps> = ({ feedback }) => {
  const { t } = useTranslation("dma_stakeholder_feedback_view");

  const feedbackState = DMACategoryUtilities.getFeedbackState(feedback);

  return (
    <Box>
      {feedbackState === "pending" ? (
        <Typography variant="body2">
          {t("notice_feedback_pending", { ns: "stakeholder_feedback_common" })}
        </Typography>
      ) : (
        <table>
          <tbody>
            <tr>
              <Box component="td" fontWeight="bold" sx={{ verticalAlign: "top", px: 2 }}>
                {t("prefix_last_changed")}
              </Box>
              <Box component="td" sx={{ px: 2 }}>
                {FormatUtilities.formatDateTime(new Date(feedback.updatedDate))}
              </Box>
            </tr>
            <tr>
              <Box component="td" fontWeight="bold" sx={{ verticalAlign: "top", px: 2 }}>
                {t("prefix_assessment")}
              </Box>
              <Box component="td" sx={{ px: 2 }}>
                {feedback.assessment !== undefined
                  ? FormatUtilities.formatNumber(feedback.assessment * DMA_MATERIALITY_MULTIPLIER)
                  : "-"}
              </Box>
            </tr>
            <tr>
              <Box component="td" fontWeight="bold" sx={{ verticalAlign: "top", px: 2 }}>
                {t("prefix_comment")}
              </Box>
              <Box component="td" sx={{ px: 2 }}>
                {feedback.feedback ?? "-"}
              </Box>
            </tr>
          </tbody>
        </table>
      )}
    </Box>
  );
};
