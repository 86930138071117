import { useMemo } from "react";
import { AuthenticationUtilities } from "@netcero/netcero-common";
import { useUserContext } from "../user/user.context";

export function useOrganizationModules(netCeroOrganizationId: string) {
  const { user } = useUserContext();
  return useMemo(() => {
    return user?.jwtPayload
      ? AuthenticationUtilities.getOrganizationAvailableModulesNetCero(
          user.jwtPayload,
          netCeroOrganizationId,
        )
      : null;
  }, [user?.jwtPayload, netCeroOrganizationId]);
}
