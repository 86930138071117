import { Box, TextField } from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ILocalRecordingPeriod, RecordingPeriodsUtilities } from "./recording-periods.utilities";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { FormatUtilities } from "../common/utilities/format.utilities";
import { EditDialogWrapper } from "../common/dialogs/variants/edit-dialog.wrapper";

export interface IRecordingPeriodEditDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error;
  recordingPeriod?: ILocalRecordingPeriod | null;
  previousRecordingPeriod?: ILocalRecordingPeriod | null;
  nextRecordingPeriod?: ILocalRecordingPeriod | null;
  onClose: (data?: ILocalRecordingPeriod) => void;
  disabled?: boolean;
}

/**
 *
 * @param open Whether the dialog is open.
 * @param loading Whether the dialog is loading.
 * @param error Used to display an error message. (Using the ErrorTextComponent)
 * @param organization Organization to which the recording period belongs. (Used to calculate default values for a new recording period)
 * @param recordingPeriod Recording period to edit. (If null, dialog will be in create mode)
 * @param previousRecordingPeriod Previous recording period. (Used to calculate default values for a new recording period and limit the startDate)
 * @param nextRecordingPeriod Next recording period. (Used to limit the endDate)
 * @param onClose Callback when the dialog is closed. (Returns the edited recording period data or null if the dialog was canceled)
 * @param disabled Whether the dialog is disabled.
 */
export const RecordingPeriodEditDialog: FC<IRecordingPeriodEditDialogProps> = ({
  open,
  loading,
  error,
  recordingPeriod,
  previousRecordingPeriod,
  nextRecordingPeriod,
  onClose,
  disabled,
}) => {
  const { t } = useTranslation("recording_period_edit_dialog");

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
    watch,
    trigger,
  } = useForm<ILocalRecordingPeriod>({
    defaultValues: {
      name: "",
      description: "",
      startDate: new Date(),
      endDate: new Date(),
    },
  });

  useEffect(() => {
    if (open) {
      const boundaries = RecordingPeriodsUtilities.getDefaultRecordingPeriodBoundaries(
        previousRecordingPeriod?.endDate,
      );

      reset(
        getRecordingPeriodValues(recordingPeriod, previousRecordingPeriod, {
          name: t("default_name", { year: boundaries.startDate.getFullYear() }),
        }),
        // Keep default values if in create mode
        { keepDefaultValues: !recordingPeriod },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, recordingPeriod]);

  const handleClose = (data?: ILocalRecordingPeriod) => {
    onClose(
      data
        ? {
            ...data,
            startDate: DateTime.fromJSDate(data.startDate).startOf("day").toJSDate(),
            endDate: DateTime.fromJSDate(data.endDate).endOf("day").toJSDate(),
          }
        : undefined,
    );
  };

  // Revalidate both Date Values when either change (effect each other)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void trigger("startDate"), [watch("endDate")]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void trigger("endDate"), [watch("startDate")]);

  // Render Component

  const isCreateMode = recordingPeriod === null || recordingPeriod === undefined;

  const minStartDate = previousRecordingPeriod
    ? DateTime.fromJSDate(previousRecordingPeriod.endDate) /*.plus({ day: 1 })*/
    : undefined;
  const maxStartDate = DateTime.fromJSDate(watch("endDate"));

  const minEndDate = DateTime.fromJSDate(watch("startDate"));
  const maxEndDate = nextRecordingPeriod
    ? DateTime.fromJSDate(nextRecordingPeriod.startDate) /*.minus({ day: 1 })*/
    : undefined;

  return (
    <EditDialogWrapper
      open={open}
      title={isCreateMode ? t("title_create") : t("title_edit", { name: recordingPeriod.name })}
      mode={isCreateMode ? "create" : "edit"}
      loading={!!loading}
      error={error}
      hasChanges={isDirty}
      onCancel={() => onClose()}
      onSave={handleSubmit(handleClose)}
      disabled={disabled}
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleClose)}
        display="flex"
        flexDirection="column"
        gap={2}
        pt={1}
      >
        <Controller
          control={control}
          name="name"
          rules={{ required: t("name_required") }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t("name_label")}
              fullWidth
              error={errors.name !== undefined}
              helperText={errors.name?.message}
              disabled={disabled}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <TextField
              {...field}
              label={t("description_label")}
              fullWidth
              multiline={true}
              rows={3}
              error={errors.description !== undefined}
              helperText={errors.description?.message}
              disabled={disabled}
            />
          )}
        />

        <Box display="flex" gap={2} flexDirection={{ xs: "column", sm: "row" }}>
          <Controller
            control={control}
            name="startDate"
            rules={{
              required: t("start_date_required"),
              validate: (value) => {
                // Handle invalid date
                if (Number.isNaN(value.getTime())) {
                  return t("start_date_invalid");
                }

                const minStartDateJS = minStartDate?.toJSDate();
                const maxStartDateJS = maxStartDate.toJSDate();

                if (minStartDateJS && value < minStartDateJS) {
                  return t("start_date_min", {
                    minDate: FormatUtilities.formatDate(minStartDateJS),
                  });
                } else if (maxStartDateJS < value) {
                  return t("start_date_max", {
                    maxDate: FormatUtilities.formatDate(maxStartDateJS),
                  });
                }
                return undefined;
              },
            }}
            render={({ field }) => (
              <DatePicker
                minDate={minStartDate}
                maxDate={maxStartDate}
                value={DateTime.fromJSDate(field.value)}
                onChange={(date) => {
                  field.onChange(date?.toJSDate());
                }}
                label={t("start_date_label")}
                disabled={disabled}
                slotProps={{
                  textField: {
                    error: !!errors.startDate,
                    helperText: errors.startDate?.message,
                    fullWidth: true,
                  },
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="endDate"
            rules={{
              required: t("end_date_required"),
              validate: (value) => {
                if (Number.isNaN(value.getTime())) {
                  return t("end_date_invalid");
                }

                const minEndDateJS = minEndDate.toJSDate();
                const maxEndDateJS = maxEndDate?.toJSDate();

                if (value < minEndDateJS) {
                  return t("end_date_min", { minDate: FormatUtilities.formatDate(minEndDateJS) });
                } else if (maxEndDateJS && maxEndDateJS < value) {
                  return t("end_date_max", { maxDate: FormatUtilities.formatDate(maxEndDateJS) });
                }
              },
            }}
            render={({ field }) => (
              <DatePicker
                minDate={minEndDate}
                maxDate={maxEndDate}
                value={DateTime.fromJSDate(field.value)}
                onChange={(date) => {
                  field.onChange(date?.toJSDate());
                }}
                label={t("end_date_label")}
                disabled={disabled}
                slotProps={{
                  textField: {
                    error: !!errors.endDate,
                    helperText: errors.endDate?.message,
                    fullWidth: true,
                  },
                }}
              />
            )}
          />
        </Box>
      </Box>
    </EditDialogWrapper>
  );
};

// Utilities

/**
 * Creates default values for the recording period form.
 * @param recordingPeriod Recording period to edit. (Uses values from this recording period to fill the form if present.)
 * @param previousRecordingPeriod Previous recording period. (Uses endDate from this recording period to calculate default values for the recording period.)
 * @param defaultValues Default values for the recording period form. (Used if recordingPeriod is null or undefined.)
 * @returns Default values for the recording period form.
 */
const getRecordingPeriodValues = (
  recordingPeriod?: ILocalRecordingPeriod | null,
  previousRecordingPeriod?: ILocalRecordingPeriod | null,
  defaultValues?: Partial<ILocalRecordingPeriod>,
) => {
  const boundaries = RecordingPeriodsUtilities.getDefaultRecordingPeriodBoundaries(
    previousRecordingPeriod?.endDate,
  );

  return {
    name: recordingPeriod?.name ?? defaultValues?.name ?? "",
    description: recordingPeriod?.description ?? defaultValues?.description ?? "",
    startDate:
      recordingPeriod?.startDate ?? // Use the recording period's start date if present (in edit mode)
      defaultValues?.startDate ?? // Use the default values if in create mode and no recording period is present
      boundaries.startDate, // Use the calculated start date as a fallback
    endDate: recordingPeriod?.endDate ?? defaultValues?.endDate ?? boundaries.endDate,
  };
};
