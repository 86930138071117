import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Dispatch, useCallback, useEffect } from "react";
import { $getSelection, BaseSelection } from "lexical";

/**
 * Listen for selection changes and call the provided callback with the new selection.
 * @param cb Callback to call with the new selection. Should be memoized.
 */
export const useListenToSelection = (cb: Dispatch<BaseSelection | null>) => {
  const [editor] = useLexicalComposerContext();

  const handleEditorStateChange = useCallback(() => {
    const selection = $getSelection();
    cb(selection);
  }, [cb]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        handleEditorStateChange();
      });
    });
  }, [editor, handleEditorStateChange]);
};
