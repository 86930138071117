/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * esrs:LayerInMitigationHierarchyForBiodiversityAndEcosystemsMember
 * @export
 * @enum {string}
 */

export const ILayerInMitigationHierarchyForBiodiversityEcosystemsEnum = {
  AvoidanceMemberEsrsAvoidanceThroughSiteSelectionMember:
    "esrs:AvoidanceMember.esrs:AvoidanceThroughSiteSelectionMember",
  AvoidanceMemberEsrsAvoidanceThroughProjectDesignMember:
    "esrs:AvoidanceMember.esrs:AvoidanceThroughProjectDesignMember",
  AvoidanceMemberEsrsAvoidanceThroughSchedulingMember:
    "esrs:AvoidanceMember.esrs:AvoidanceThroughSchedulingMember",
  MinimisationMember: "esrs:MinimisationMember",
  RestorationAndRehabilitationMember: "esrs:RestorationAndRehabilitationMember",
  CompensationOrOffsetsMember: "esrs:CompensationOrOffsetsMember",
} as const;

export type ILayerInMitigationHierarchyForBiodiversityEcosystemsEnum =
  (typeof ILayerInMitigationHierarchyForBiodiversityEcosystemsEnum)[keyof typeof ILayerInMitigationHierarchyForBiodiversityEcosystemsEnum];
