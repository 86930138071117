import { ITargetPathForVerification } from "./target-path.types";
import { ITargetPathState } from "@netcero/netcero-core-api-client";

export class TargetPathVerification {
  public static canTargetPathBeEdited(targetPath: ITargetPathForVerification): boolean;
  public static canTargetPathBeEdited(
    targetPath: ITargetPathForVerification,
    errorIfInvalid: Error,
  ): void;

  public static canTargetPathBeEdited(
    targetPath: ITargetPathForVerification,
    errorIfInvalid?: Error,
  ): boolean | void {
    // determine whether the path can be edited
    const canTargetPathBeEdited = targetPath.state === ITargetPathState.Draft;

    // no error was supplied --> return result
    if (errorIfInvalid === undefined) {
      return canTargetPathBeEdited;
    }

    // error was supplied + target path can not be edited --> throw error
    if (!canTargetPathBeEdited) {
      throw errorIfInvalid;
    }
  }

  public static canTargetPathBeCommitted(targetPath: ITargetPathForVerification): boolean;
  public static canTargetPathBeCommitted(
    targetPath: ITargetPathForVerification,
    errorIfInvalid: Error,
  ): void;

  public static canTargetPathBeCommitted(
    targetPath: ITargetPathForVerification,
    errorIfInvalid?: Error,
  ): boolean | void {
    // determine whether the path can be edited
    const canTargetPathBeCommitted = targetPath.goals.length > 0;

    // no error was supplied --> return result
    if (errorIfInvalid === undefined) {
      return canTargetPathBeCommitted;
    }

    // error was supplied + target path can not be edited --> throw error
    if (!canTargetPathBeCommitted) {
      throw errorIfInvalid;
    }
  }
}
