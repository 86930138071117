/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * esrs:AdaptationSolutionTypeMember
 * @export
 * @enum {string}
 */

export const IAdaptationSolutionTypesEnum = {
  NaturebasedMember: "esrs:NaturebasedMember",
  EngineeringMember: "esrs:EngineeringMember",
  TechnologicalMember: "esrs:TechnologicalMember",
  OtherThanNaturebasedEngineeringOrTechnologicalMember:
    "esrs:OtherThanNaturebasedEngineeringOrTechnologicalMember",
} as const;

export type IAdaptationSolutionTypesEnum =
  (typeof IAdaptationSolutionTypesEnum)[keyof typeof IAdaptationSolutionTypesEnum];
