import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import {
  IDataEntryObject,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { EmissionsUtilities } from "./emissions.utilities";
import { EvaluationFormatUtilities } from "../utilities/evaluation-format.utilities";
import { GroupedCalculationResult } from "@netcero/netcero-common";

interface IEmissionsDirectAndUpstreamComponentProps {
  generalResult: GroupedCalculationResult;
  currentDataEntryObject: IDataEntryObject;
  structureGroup: IInputParameterRecordingStructureGroupTHGSC;
}
export const EmissionsDirectAndUpstreamComponent: FC<IEmissionsDirectAndUpstreamComponentProps> = ({
  generalResult,
  currentDataEntryObject,
  structureGroup,
}) => {
  const { t } = useTranslation("emissions_direct_and_upstream_component");

  const [directEmissions, upstreamEmissions] = useMemo(() => {
    const scopeSums = EmissionsUtilities.getEmissionScopesSumsForResultAndGroup(
      generalResult,
      currentDataEntryObject,
      structureGroup,
    );

    return [
      // direct emissions
      scopeSums.scope1 + scopeSums.scope2,
      // upstream emissions
      scopeSums.scope3,
    ];
  }, [currentDataEntryObject, generalResult, structureGroup]);

  const [directEmissionsSelf, upstreamEmissionsSelf] = useMemo(() => {
    const scopeSums = EmissionsUtilities.getEmissionScopesSumsForResultAndGroup(
      generalResult,
      currentDataEntryObject,
      structureGroup,
      false,
    );

    return [
      // direct emissions
      scopeSums.scope1 + scopeSums.scope2,
      // upstream emissions
      scopeSums.scope3,
    ];
  }, [currentDataEntryObject, generalResult, structureGroup]);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography>{t("title_direct_emissions")}</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>
              {EvaluationFormatUtilities.formatCO2TonValueOnly(directEmissions)}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>
              {EvaluationFormatUtilities.formatCO2TonValueOnly(directEmissionsSelf)}
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography>{t("title_upstream_emissions")}</Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>
              {EvaluationFormatUtilities.formatCO2TonValueOnly(upstreamEmissions)}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Typography>
              {EvaluationFormatUtilities.formatCO2TonValueOnly(upstreamEmissionsSelf)}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
