import { FC } from "react";
import { Box } from "@mui/material";

interface IBaseStatusIndicatorChipProps {
  backgroundColor: string;
  /** When provided weill be used for label. Otherwise Label will be black with opacity 0.6 */
  color?: string;
  /** Defaults to 700 */
  fontWeight?: number;
  label: string;
}

export const BaseStatusIndicatorChip: FC<IBaseStatusIndicatorChipProps> = ({
  backgroundColor,
  color,
  fontWeight = 700,
  label,
}) => {
  return (
    <Box py={0.25} px={1} borderRadius={4} sx={{ backgroundColor }}>
      <Box
        fontSize="12px"
        fontWeight={fontWeight}
        color={color ?? "black"}
        sx={{ opacity: color ? undefined : 0.6, textWrap: "nowrap" }}
      >
        {label}
      </Box>
    </Box>
  );
};
