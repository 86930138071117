import { ReactNode, useCallback, useMemo } from "react";
import { IAppSnackbarEnqueueApiMessageAction } from "./app-snackbar.interfaces";
import { useTranslation } from "react-i18next";
import { closeSnackbar, SharedProps, useSnackbar } from "notistack";
import { ITranslatableError, useTranslateError } from "../common/hooks/translate-error.hook";
import { IconButton, Typography } from "@mui/material";
import { CloseIcon } from "../common/constants/tabler-icon.constants";

export const DEFAULT_SNACKBAR_CONFIGURATIONS: SharedProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  autoHideDuration: 3_000,
  action: (snackbarId) => (
    <IconButton onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon style={{ color: "white" }} />
    </IconButton>
  ),
};

const renderContent = (content: ReactNode) => <Typography variant="body2">{content}</Typography>;

export const useAppSnackbar = () => {
  const { t } = useTranslation("snack_bar_messages");
  const translateError = useTranslateError();
  const { enqueueSnackbar } = useSnackbar();

  const translateAction = useCallback(
    (data: IAppSnackbarEnqueueApiMessageAction) =>
      t(`actions.${data.type}`, { ...(data.data ?? {}) }),
    [t],
  );

  const enqueueSuccessMessage = useCallback(
    (action: IAppSnackbarEnqueueApiMessageAction) => {
      const translatedAction = translateAction(action);
      enqueueSnackbar(renderContent(translatedAction), {
        variant: "success",
        ...DEFAULT_SNACKBAR_CONFIGURATIONS,
      });
    },
    [translateAction, enqueueSnackbar],
  );

  const enqueueErrorMessage = useCallback(
    (data: { error: ITranslatableError; action: IAppSnackbarEnqueueApiMessageAction }) => {
      const translatedAction = translateAction(data.action);
      const translatedError = translateError(data.error);
      const message = t("error_message", { translatedAction, translatedError });
      enqueueSnackbar(renderContent(message), {
        variant: "error",
        ...DEFAULT_SNACKBAR_CONFIGURATIONS,
      });
    },
    [translateAction, translateError, t, enqueueSnackbar],
  );

  return useMemo(
    () => ({
      enqueueSuccessMessage,
      enqueueErrorMessage,
      wrapApiPromise: async function <T>(
        promise: Promise<T> | (() => Promise<T>),
        action: IAppSnackbarEnqueueApiMessageAction,
      ) {
        try {
          const res = typeof promise === "function" ? await promise() : await promise;
          enqueueSuccessMessage(action);
          return res;
        } catch (error) {
          enqueueErrorMessage({
            error: error as ITranslatableError,
            action,
          });
          throw error;
        }
      },
    }),
    [enqueueSuccessMessage, enqueueErrorMessage],
  );
};
