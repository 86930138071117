import { IDMACategoryWithEffectsAndChildren, IESRSTopic } from "@netcero/netcero-core-api-client";
import { useMemo } from "react";
import { DMAUtilities } from "../utilities/dma.utilities";

export const useIroEditDialogMainDmaCategory = (
  esrsTopic: IESRSTopic | null,
  dmaCategory: IDMACategoryWithEffectsAndChildren | null,
) => {
  return useMemo(
    () =>
      esrsTopic && dmaCategory
        ? DMAUtilities.findParentsOfDmaCategoryInTopic(esrsTopic, dmaCategory.id)?.[0] ?? null
        : null,
    [esrsTopic, dmaCategory],
  );
};
