import { useCallback, useEffect, useMemo } from "react";
import { NavigateOptions, useSearchParams } from "react-router-dom";

/**
 * Utility hook to manage state in the URL search params.
 *
 * The function parameters passed to this hook should not change!
 * (unexpected behavior may occur if they do - only changes of the searchParams are considered and acted upon)
 * @param queryKey The key to use in the URL search params. This should not change
 * @param deserialize The function to convert the string value from the URL to the desired type
 * @param serialize The function to convert the value to a string to be used in the URL. Defaults to value.toString()
 * @param defaultValue Default value to use if the key is not found in the URL and
 * to initialize/reset the state if the key is not found in the URL (this will use the replace
 * option of the setSearchParams function)
 * @param initDefaultValue Whether to initialize the default value if the key is not found in the URL
 */
export function useSearchParamState<T extends { toString: () => string }>(
  queryKey: string,
  deserialize: (value: string | null) => T,
  serialize: (value: T) => string = (value: T) => value.toString(),
  defaultValue: T,
  initDefaultValue = true,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = useMemo(() => {
    return searchParams.get(queryKey) ? deserialize(searchParams.get(queryKey)) : defaultValue;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setValue = useCallback(
    (newValue: T, navigateOptions?: NavigateOptions) => {
      setSearchParams((params) => {
        if (newValue === null) {
          params.delete(queryKey);
        } else {
          params.set(queryKey, serialize(newValue));
        }
        return params;
      }, navigateOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchParams],
  );

  // Init Block

  useEffect(() => {
    if (initDefaultValue && !searchParams.get(queryKey) && defaultValue) {
      setSearchParams(
        (params) => {
          params.set(queryKey, serialize(defaultValue));
          return params;
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return [value, setValue] as const;
}

/**
 * Basic version of useSearchParamState that uses the value as the string directly
 * @param queryKey The key to use in the URL search params. This should not change
 * @param defaultValue Default value to use if the key is not found in the URL and
 * to initialize/reset the state if the key is not found in the URL (this will use the replace
 * option of the setSearchParams function)
 * @param initDefaultValue Whether to initialize the default value if the key is not found in the URL
 */
export function useSearchParamStateBasic<T extends string>(
  queryKey: string,
  defaultValue: T,
  initDefaultValue = true,
) {
  return useSearchParamState<T>(
    queryKey,
    (value) => value as T,
    (value) => value,
    defaultValue,
    initDefaultValue,
  );
}

/**
 * Utility hook to manage state in the URL search params.
 *
 * The function parameters passed to this hook should not change!
 * (unexpected behavior may occur if they do - only changes of the searchParams are considered and acted upon)
 * @param queryKey The key to use in the URL search params. This should not change
 * @param deserialize The function to convert the string value from the URL to the desired type
 * @param serialize The function to convert the value to a string to be used in the URL. Defaults to value.toString()
 * @param defaultValues Default value to use if the key is not found in the URL and
 * to initialize/reset the state if the key is not found in the URL (this will use the replace
 * option of the setSearchParams function)
 * @param initDefaultValue Whether to initialize the default value if the key is not found in the URL
 */
export function useSearchParamArrayState<T extends { toString: () => string }>(
  queryKey: string,
  deserialize: (value: string) => T,
  serialize: (value: T) => string = (value: T) => value.toString(),
  defaultValues: T[] = [],
  initDefaultValue = true,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = useMemo(() => {
    const values = searchParams.getAll(queryKey);
    return values.length > 0 ? values.map(deserialize) : defaultValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const setValue = useCallback(
    (newValues: T[], navigateOptions?: NavigateOptions) => {
      setSearchParams((params) => {
        params.delete(queryKey);
        newValues.map(serialize).forEach((val) => params.append(queryKey, val));
        return params;
      }, navigateOptions);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSearchParams],
  );

  // Init Block

  useEffect(() => {
    if (
      initDefaultValue &&
      searchParams.getAll(queryKey).length === 0 &&
      defaultValues.length > 0
    ) {
      setSearchParams(
        (params) => {
          defaultValues.map(serialize).forEach((val) => params.set(queryKey, val));
          return params;
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return [value, setValue] as const;
}

/**
 * Basic version of useSearchParamArrayState that uses the value as the string directly
 * @param queryKey The key to use in the URL search params. This should not change
 * @param defaultValues Default value to use if the key is not found in the URL and
 * to initialize/reset the state if the key is not found in the URL (this will use the replace
 * option of the setSearchParams function)
 * @param initDefaultValue Whether to initialize the default value if the key is not found in the URL
 */
export function useSearchParamArrayStateBasic(
  queryKey: string,
  defaultValues: string[] = [],
  initDefaultValue = true,
) {
  return useSearchParamArrayState<string>(
    queryKey,
    (value) => value,
    (value) => value,
    defaultValues,
    initDefaultValue,
  );
}
