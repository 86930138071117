import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useCallback, useMemo } from "react";
import { IInputParameterValueMetaDataOptions } from "@netcero/netcero-core-api-client";

export const useGenerateOptionsLookup = () => {
  const translateContent = useTranslateContent();
  return useCallback(
    (metaData: IInputParameterValueMetaDataOptions) =>
      metaData.options.reduce((acc, curr) => {
        acc[curr.value] = translateContent(curr.name);
        return acc;
      }, {} as Record<string, string>),
    [translateContent],
  );
};

export const useOptionsLookup = (metaData: IInputParameterValueMetaDataOptions) => {
  const generate = useGenerateOptionsLookup();
  return useMemo(() => generate(metaData), [metaData, generate]);
};
