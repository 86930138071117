import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntercom } from "react-use-intercom";

/**
 * Hook to manage the state of the comments and audit log side section
 */
export const useCommentsAndAuditLogState = () => {
  const [showCommentsAndAuditLogSideSection, setShowCommentsAndAuditLogSideSection] =
    useState(false);

  const openCommentsAndAuditLogSideSection = useCallback(
    () => setShowCommentsAndAuditLogSideSection(true),
    [],
  );

  const closeCommentsAndAuditLogSideSection = useCallback(
    () => setShowCommentsAndAuditLogSideSection(false),
    [],
  );

  const toggleCommentsAndAuditLogSideSection = useCallback(
    () => setShowCommentsAndAuditLogSideSection((prev) => !prev),
    [],
  );

  // Sync Dialog with Intercom
  const intercom = useIntercom();
  useEffect(() => {
    if (showCommentsAndAuditLogSideSection) {
      intercom.hide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCommentsAndAuditLogSideSection]);

  useEffect(() => {
    if (intercom.isOpen) {
      setShowCommentsAndAuditLogSideSection(false);
    }
  }, [intercom.isOpen]);

  return useMemo(
    () => ({
      showCommentsAndAuditLogSideSection,
      setShowCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSection,
    }),
    [
      showCommentsAndAuditLogSideSection,
      toggleCommentsAndAuditLogSideSection,
      openCommentsAndAuditLogSideSection,
      closeCommentsAndAuditLogSideSection,
    ],
  );
};
