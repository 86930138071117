import { autocompleteClasses, Popper, styled } from "@mui/material";
import { createContext, forwardRef, useContext, useEffect, useRef } from "react";
import { VariableSizeList } from "react-window";

export const VirtualizedAutocompleteStyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export const OuterElementContext = createContext({});

export const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

/**
 * Hook to reset the cache of a VariableSizeList when the data changes.
 * @returns A ref to assign to the VariableSizeList.
 */
export const useResetVariableSizeListCache = (data: unknown) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};
