import { IconButton, Menu, MenuItem, Skeleton, Tooltip } from "@mui/material";
import { IStakeholder } from "@netcero/netcero-core-api-client";
import { FC, useMemo, useRef, useState } from "react";
import { ErrorTextComponent } from "../common/components/error-text.component";
import { AddIcon } from "../common/constants/tabler-icon.constants";
import { useStakeholdersQuery } from "./stakeholder.queries";

interface IStakeholderAddButtonProps {
  organizationId: string;
  emptyMessage: string;
  tooltip?: string;
  excludeStakeholderIds?: string[];
  disabled?: boolean;
  onSelect: (stakeholder: IStakeholder) => void;
}
export const StakeholderAddButton: FC<IStakeholderAddButtonProps> = ({
  organizationId,
  emptyMessage,
  tooltip,
  excludeStakeholderIds,
  disabled,
  onSelect,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuAnchorElementRef = useRef<HTMLButtonElement | null>(null);

  const stakeholdersQuery = useStakeholdersQuery(organizationId);

  // TODO: Quick and dirty, maybe refactor?
  // Sort stakeholders by first name
  const sortedStakeholders = useMemo(() => {
    if (stakeholdersQuery.data) {
      return stakeholdersQuery.data.stakeholders.sort((a, b) => a.name.localeCompare(b.name));
    }
    return [];
  }, [stakeholdersQuery.data]);

  const shownStakeholders = useMemo(
    () =>
      excludeStakeholderIds
        ? sortedStakeholders?.filter(
            (stakeholder) => !excludeStakeholderIds?.includes(stakeholder.id!),
          )
        : sortedStakeholders,
    [sortedStakeholders, excludeStakeholderIds],
  );

  const handleSelectItem = (stakeholder: IStakeholder) => {
    onSelect(stakeholder);
    setShowMenu(false);
  };

  if (stakeholdersQuery.isLoading) {
    return <Skeleton variant="circular" width={24} height={24} />;
  }

  if (stakeholdersQuery.isError) {
    return <ErrorTextComponent error={stakeholdersQuery.error} />;
  }

  return (
    <>
      {/* Menu */}
      <Menu
        open={showMenu}
        anchorEl={menuAnchorElementRef.current}
        onClose={() => setShowMenu(false)}
      >
        {/* Show empty message if no stakeholders to show */}
        {shownStakeholders && shownStakeholders.length < 1 && (
          <MenuItem disabled>{emptyMessage}</MenuItem>
        )}

        {/* List Stakeholders as Items */}
        {shownStakeholders?.map((stakeholder) => (
          <MenuItem key={stakeholder.id} onClick={() => handleSelectItem(stakeholder)}>
            {stakeholder.name} {stakeholder.organization && `(${stakeholder.organization})`}
          </MenuItem>
        ))}
      </Menu>

      {/* Button */}
      <Tooltip title={tooltip} placement="right">
        <span>
          <IconButton
            ref={menuAnchorElementRef}
            onClick={() => setShowMenu(true)}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
