import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { IGetAuditLogs200Response } from "@netcero/netcero-core-api-client";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IUsePaginationStateResult } from "../../pagination/hooks/pagination-state.hook";
import { useBuildUserLookup } from "../../user/hooks/build-user-lookup.hook";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { AuditLoggingComparisonViewComponent } from "./audit-logging-comparison-view.component";

interface IAuditLoggingProps {
  auditLogs: IGetAuditLogs200Response;
  users: IUserRepresentation[];
  pagination: IUsePaginationStateResult;
}

export const AuditLoggingEditList: FC<IAuditLoggingProps> = ({ users, auditLogs, pagination }) => {
  const { t } = useTranslation("audit_logging_edit_list_component");

  const [openRowId, setOpenRowId] = useState<string | null>(null);

  const toggleRow = useCallback(
    (newId: string) => setOpenRowId(newId === openRowId ? null : newId),
    [openRowId],
  );

  const userLookUp = useBuildUserLookup(users);

  return (
    <>
      <Table size="small" sx={{ "& > *": { borderBottom: "none" } }}>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("headers.timeStamp")}</TableCell>
            <TableCell>{t("headers.userName")}</TableCell>
            <TableCell>{t("headers.stakeholderName")}</TableCell>
            <TableCell>{t("headers.action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ "& > *": { border: "none" } }}>
          {auditLogs.items.map((auditLog, index) => (
            <React.Fragment key={auditLog.id}>
              {/* Render the basic information of the log*/}
              <TableRow onClick={() => toggleRow(auditLog.id)} sx={{ "& > *": { border: "none" } }}>
                <TableCell>
                  <IconButton>
                    {openRowId === auditLog.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </TableCell>
                <TableCell>{FormatUtilities.formatDateTime(auditLog.timestamp)}</TableCell>
                <TableCell>
                  {auditLog.userId !== undefined
                    ? userLookUp[auditLog.userId]?.username ?? "-"
                    : "-"}
                </TableCell>
                <TableCell>{auditLog?.stakeholder?.name ?? "-"}</TableCell>
                <TableCell>{t(`events.${auditLog.event}`) ?? "-"}</TableCell>
              </TableRow>

              {/* Render the comparison view */}
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={openRowId === auditLog.id} timeout="auto" unmountOnExit>
                    <AuditLoggingComparisonViewComponent auditLog={auditLog} />
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={auditLogs.total}
        page={pagination.state.currentPage}
        rowsPerPage={pagination.state.rowsPerPage}
        onPageChange={(event, page) => {
          pagination.setCurrentPage(page);
        }}
        onRowsPerPageChange={(event) => {
          pagination.setRowsPerPage(parseInt(event.target.value));
        }}
      />
    </>
  );
};
