import { DmaFormatUtilities as CommonDmaFormatUtilities } from "@netcero/netcero-dma";

export class DmaFormatUtilities {
  private static formatNotPresentValue(
    value: number | null | undefined,
    cb: (value: number) => string,
  ) {
    // If null, undefined or 0, return "-"
    if (!value) {
      return "-";
    }
    // Else render the value
    return cb(value);
  }

  /**
   * Formats the given severity
   * @param severityAsFraction The fraction to format - has to be between 0 and 1!
   */
  public static formatSeverity(severityAsFraction: number | null | undefined) {
    return DmaFormatUtilities.formatNotPresentValue(
      severityAsFraction,
      CommonDmaFormatUtilities.formatSeverityValue,
    );
  }

  /**
   * Formats the given materiality degree
   * @param materialityDegree The degree to format (value between 0 and 5)
   */
  public static formatMaterialityDegree(materialityDegree: number | null | undefined): string {
    return DmaFormatUtilities.formatNotPresentValue(
      materialityDegree,
      CommonDmaFormatUtilities.formatMaterialityDegreeValue,
    );
  }
}
