import {
  getDMAConfigurationQueryKey,
  getDmaDataEntryObjectConfigurationQueryKey,
  getESRSTopicsQueryKey,
  getESRSTopicsQueryKeyWithoutDEO,
} from "../dma.queries";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import {
  IBaseDMAFinancialEffectData,
  IBaseDMAMaterialImpactData,
  ICreateDmaConfigurationRequest,
  IDMAConfigurationData,
  IIROState,
  IOptOutOfDmaCategoryRequest,
} from "@netcero/netcero-core-api-client";
import { IRecordingPeriodIdentity } from "../../recording-periods/recording-period-identity.interface";
import { useInvalidateQueriesByKeys } from "../../common/hooks/invalidate-queries-by-keys.hook";
import { IDataEntryObjectIdentity } from "../../data-entry-objects/data-entry-objects.mutations";
import { getRecordingPeriodRecordingStructuresQueryKey } from "../../recording-structures/recording-structures.queries";

export interface IESRSTopicIdentificationData extends IDataEntryObjectIdentity {
  esrsTopicId: string;
}

export interface IDMACategoryIdentificationData extends IESRSTopicIdentificationData {
  dmaCategoryId: string;
}

export const useInitDMAMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Omit<IESRSTopicIdentificationData, "esrsTopicId">) => {
      const req = await CoreApiService.ESRSTopicsApi.initializeDma(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

export const useInitDMAForTopicMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IESRSTopicIdentificationData) => {
      const req = await CoreApiService.ESRSTopicsApi.initializeDmaForTopic(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

export const useInitEsrsRecordingMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IRecordingPeriodIdentity) => {
      const req = await CoreApiService.ESRSTopicsApi.initializeEsrsRecording(
        data.organizationId,
        data.recordingPeriodId,
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getRecordingPeriodRecordingStructuresQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};

/**
 * DMA Configuration
 */

interface ISetDMAConfigurationData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  payload: IDMAConfigurationData;
}

/**
 * Mutation for setting the DMA Configuration for a given Recording Period
 * @returns Mutation to set the DMA configuration
 */
export const useSetDMAConfigurationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ISetDMAConfigurationData) => {
      return CoreApiService.DMAApi.updateDmaConfiguration(
        data.organizationId,
        data.recordingPeriodId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: getESRSTopicsQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
            variables.dataEntryObjectId,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: getDMAConfigurationQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
          ),
        }),
      ]);
    },
  });
};

interface ICreateDMAConfigurationData extends IRecordingPeriodIdentity {
  payload: ICreateDmaConfigurationRequest;
}

export const useCreateDMAConfigurationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ICreateDMAConfigurationData) => {
      return CoreApiService.DMAApi.createDmaConfiguration(
        data.organizationId,
        data.recordingPeriodId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: getESRSTopicsQueryKeyWithoutDEO(
            variables.organizationId,
            variables.recordingPeriodId,
          ),
        }),
        queryClient.invalidateQueries({
          queryKey: getDMAConfigurationQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
          ),
        }),
      ]);
    },
  });
};

/**
 * SUBMIT DMA MUTATION
 */

interface ISubmitDMAData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

/**
 * Mutation for executing the DMA
 * @param successCallback Callback to execute after the DMA is executed successfully
 * @returns Mutation to execute the DMA
 */
export const useExecuteDMAMutation = (
  successCallback: (variables: ISubmitDMAData) => Promise<void>,
) => {
  const invalidateQueries = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: (data: ISubmitDMAData) => {
      return CoreApiService.DMAApi.executeDoubleMaterialityAssessment(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        {
          // 15 Minutes Timeout
          timeout: 15 * 60 * 1000,
        },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      await invalidateQueries({
        awaitQueryKeys: [
          getDmaDataEntryObjectConfigurationQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
            variables.dataEntryObjectId,
          ),
        ],
      });
      return successCallback(variables);
    },
  });
};

/**
 * OPT OUT MUTATIONS
 */

interface IOptOutOfCategoryData extends IESRSTopicIdentificationData {
  payload: IOptOutOfDmaCategoryRequest;
}

/**
 * Mutation for opting out of a ESRS topic
 * @returns Mutation to opt out of a ESRS topic
 */
export const useOptOutOfESRSTopicMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IOptOutOfCategoryData) => {
      const req = await CoreApiService.DMAApi.optOutOfEsrsTopic(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.payload,
      );
      const res = await req();
      return res.data;
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

/**
 * MATERIAL IMPACTS
 */

interface ICreateMaterialImpactData extends IDMACategoryIdentificationData {
  payload: IBaseDMAMaterialImpactData;
}

/**
 * Mutation for creating a material impact
 * @returns Mutation to create a material impact
 */
export const useCreateMaterialImpactMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICreateMaterialImpactData) => {
      return CoreApiService.DMAMaterialImpactsApi.addMaterialImpact(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IUpdateMaterialImpactData extends IDMACategoryIdentificationData {
  materialImpactId: string;
  payload: IBaseDMAMaterialImpactData;
}

/**
 * Mutation for updating a material impact
 * @returns Mutation to update a material impact
 */
export const useUpdateMaterialImpactMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateMaterialImpactData) => {
      return CoreApiService.DMAMaterialImpactsApi.updateMaterialImpact(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.materialImpactId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IDeleteMaterialImpactData extends IDMACategoryIdentificationData {
  materialImpactId: string;
}

/**
 * Mutation for deleting a material impact
 * @returns Mutation to delete a material impact
 */
export const useDeleteMaterialImpactMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IDeleteMaterialImpactData) => {
      return CoreApiService.DMAMaterialImpactsApi.deleteMaterialImpact(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.materialImpactId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IUpdateMaterialImpactIROStateData extends IDMACategoryIdentificationData {
  materialImpactId: string;
  iroState: IIROState;
}

/**
 * Mutation for updating the IRO state of a material impact
 * @returns Mutation to update the IRO state of a material impact
 */
export const useUpdateMaterialImpactIROStateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateMaterialImpactIROStateData) => {
      return CoreApiService.DMAMaterialImpactsApi.updateMaterialImpactIroState(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.materialImpactId,
        { iroState: data.iroState },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

export interface IMoveMaterialImpactToOtherTopicData extends IDMACategoryIdentificationData {
  materialImpactId: string;
  targetDMACategoryId: string;
}

/**
 * Mutation for moving a material impact to another topic
 * @returns Mutation to move a material impact to another topic
 */
export const useMoveMaterialImpactToOtherTopicMutation = () => {
  const invalidateQueriesByKeys = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: (data: IMoveMaterialImpactToOtherTopicData) => {
      return CoreApiService.DMAMaterialImpactsApi.moveMaterialImpactToCategory(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.materialImpactId,
        { targetDMACategoryId: data.targetDMACategoryId },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      await invalidateQueriesByKeys({
        awaitQueryKeys: [
          getESRSTopicsQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
            variables.dataEntryObjectId,
          ),
        ],
      });
    },
  });
};

/**
 * FINANCIAL EFFECTS
 */

interface ICreateFinancialEffectData extends IDMACategoryIdentificationData {
  payload: IBaseDMAFinancialEffectData;
}

/**
 * Mutation for creating a financial effect
 * @returns Mutation to create a financial effect
 */
export const useCreateFinancialEffectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICreateFinancialEffectData) => {
      return CoreApiService.DMAFinancialEffectsApi.addFinancialEffect(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IUpdateFinancialEffectData extends IDMACategoryIdentificationData {
  financialEffectId: string;
  payload: IBaseDMAFinancialEffectData;
}

/**
 * Mutation for updating a financial effect
 * @returns Mutation to update a financial effect
 */
export const useUpdateFinancialEffectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateFinancialEffectData) => {
      return CoreApiService.DMAFinancialEffectsApi.updateFinancialEffect(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.financialEffectId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IDeleteFinancialEffectData extends IDMACategoryIdentificationData {
  financialEffectId: string;
}

/**
 * Mutation for deleting a financial effect
 * @returns Mutation to delete a financial effect
 */
export const useDeleteFinancialEffectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IDeleteFinancialEffectData) => {
      return CoreApiService.DMAFinancialEffectsApi.deleteFinancialEffect(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.financialEffectId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

interface IUpdateFinancialEffectIROStateData extends IDMACategoryIdentificationData {
  financialEffectId: string;
  iroState: IIROState;
}

/**
 * Mutation for updating the IRO state of a financial effect
 * @returns Mutation to update the IRO state of a financial effect
 */
export const useUpdateFinancialEffectIROStateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateFinancialEffectIROStateData) => {
      return CoreApiService.DMAFinancialEffectsApi.updateFinancialEffectIroState(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.financialEffectId,
        { iroState: data.iroState },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getESRSTopicsQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
        ),
      });
    },
  });
};

export interface IMoveFinancialEffectToOtherTopicData extends IDMACategoryIdentificationData {
  financialEffectId: string;
  targetDMACategoryId: string;
}

/**
 * Mutation for moving a financial effect to another topic
 * @returns Mutation to move a financial effect to another topic
 */
export const useMoveFinancialEffectToOtherTopicMutation = () => {
  const invalidateQueriesByKeys = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: (data: IMoveFinancialEffectToOtherTopicData) => {
      return CoreApiService.DMAFinancialEffectsApi.moveFinancialEffectToCategory(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.financialEffectId,
        { targetDMACategoryId: data.targetDMACategoryId },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      await invalidateQueriesByKeys({
        awaitQueryKeys: [
          getESRSTopicsQueryKey(
            variables.organizationId,
            variables.recordingPeriodId,
            variables.dataEntryObjectId,
          ),
        ],
      });
    },
  });
};
