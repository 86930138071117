import { DialogContentText } from "@mui/material";
import { FC, ReactNode, useCallback, useMemo } from "react";
import { DialogCancelButton, DialogConfirmButton } from "../dialog-button.components";
import { BaseAsyncActionDialog } from "./base-async-action.dialog";
import { IDialogWithOptionalError, OverrideDialogProps } from "../types/common.types";
import { useTranslation } from "react-i18next";

/**
 * Type of action that the confirm dialog is asking for.
 * This is used to determine the text of the confirm button.
 */
// Make sure that the new type has a translation under "buttons" before adding it here
export type IConfirmDialogType = "confirm" | "delete" | "remove" | "publish";

/**
 * Base interface for common props of the ConfirmDialog component.
 * @interface
 * @extends {IDialogWithOptionalError}
 */
interface IConfirmDialogPropsBase extends IDialogWithOptionalError {
  open: boolean;
  loading?: boolean;
  disabled?: boolean;
  title: ReactNode;
  content: ReactNode;
  confirmOverride?: ReactNode;
  cancelOverride?: ReactNode;
  actionType?: IConfirmDialogType;
  dialogProps?: OverrideDialogProps;
  /**
   * Beware these properties will not only add new ones
   * but also override the default ones set in the wrapper!
   * In most cases you do not want to do this!
   **/
  hasChanges?: boolean;
}

/**
 * Interface for ConfirmDialog props when using a single event handler for closing the dialog.
 * @interface
 * @extends {IConfirmDialogPropsBase}
 */
interface IConfirmDialogPropsSingleEvent extends IConfirmDialogPropsBase {
  onClose: (confirm: boolean) => void;
  onConfirm?: undefined;
  onCancel?: undefined;
}

/**
 * Interface for ConfirmDialog props when using separate event handlers for confirm and cancel actions.
 * @interface
 * @extends {IConfirmDialogPropsBase}
 */
interface IConfirmDialogPropsSeparateEvents extends IConfirmDialogPropsBase {
  onClose?: undefined;
  onConfirm: () => void;
  onCancel: () => void;
}

type IConfirmDialogProps = IConfirmDialogPropsSingleEvent | IConfirmDialogPropsSeparateEvents;

/**
 * ConfirmDialog component.
 * @param {IConfirmDialogProps} props - Props for the ConfirmDialog component.
 * @returns The ConfirmDialog component.
 */
export const ConfirmDialog: FC<IConfirmDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  onConfirm,
  onCancel,
  onClose,
  title,
  content,
  confirmOverride,
  cancelOverride,
  actionType,
  dialogProps,
  hasChanges,
}: IConfirmDialogProps) => {
  const { t } = useTranslation("buttons");

  const disableCloseOnBackdrop = loading || hasChanges || disabled;

  const handleCancel = useCallback(() => {
    if (onCancel) {
      onCancel();
    } else if (onClose) {
      onClose(false);
    }
  }, [onCancel, onClose]);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    } else if (onClose) {
      onClose(true);
    }
  }, [onConfirm, onClose]);

  return (
    <BaseAsyncActionDialog
      open={open}
      onClose={disableCloseOnBackdrop ? undefined : handleCancel}
      loading={!!loading}
      error={error}
      title={title}
      content={content}
      dialogProps={dialogProps}
      actions={
        <>
          <DialogCancelButton onClick={handleCancel} disabled={disabled}>
            {cancelOverride ?? t("cancel")}
          </DialogCancelButton>
          <DialogConfirmButton onClick={handleConfirm} disabled={disabled}>
            {confirmOverride ?? t(actionType ? actionType : "confirm")}
          </DialogConfirmButton>
        </>
      }
    />
  );
};

/**
 * ConfirmDialogTextBody component.
 * Memoized version of the ConfirmDialog component with text content.
 * @param content
 * @param props
 * @constructor
 */
export const ConfirmDialogTextBody: FC<IConfirmDialogProps> = ({ content, ...props }) => {
  const contentWithWrappedText = useMemo(
    () => <DialogContentText>{content}</DialogContentText>,
    [content],
  );

  return <ConfirmDialog content={contentWithWrappedText} {...props} />;
};
