import { FC } from "react";
import { Chip, SvgIcon } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { OpenInNewTabIcon } from "../constants/tabler-icon.constants";

interface ILinkChipProps {
  to: string;
  label: string;
}

export const LinkChip: FC<ILinkChipProps> = ({ to, label }) => {
  return (
    <Chip
      component={RouterLink}
      to={to}
      target="_blank"
      icon={<SvgIcon component={OpenInNewTabIcon} fontSize="small" sx={{ fill: "transparent" }} />}
      label={label}
      onClick={() => {}} // No-Op so that Chip shows as clickable
      sx={{ px: 1 }}
    />
  );
};
