import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getEsrsPhaseInQueryKey, IEsrsPhaseInQueryData } from "./esrs-phase-in.queries";
import { IBaseESRSTopicPhaseInDecisionData } from "@netcero/netcero-core-api-client";
import { CoreApiService } from "../core-api/core-api.service";

interface IEsrsPhaseInMutationData extends IEsrsPhaseInQueryData {
  payload: IBaseESRSTopicPhaseInDecisionData;
}

export const useUpdatePhaseInDecisionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      payload,
    }: IEsrsPhaseInMutationData) => {
      return CoreApiService.ESRSTopicPhaseInDecisionsApi.modifyPhaseInDecisionForDeo(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (_, { organizationId, recordingPeriodId, dataEntryObjectId }) => {
      return queryClient.invalidateQueries({
        queryKey: getEsrsPhaseInQueryKey(organizationId, recordingPeriodId, dataEntryObjectId),
      });
    },
  });
};
