import { Dispatch, FC, useCallback, useRef, useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { AddIcon } from "../../common/constants/tabler-icon.constants";
import { ISource } from "@netcero/netcero-core-api-client";
import { useSourcesQuery } from "../sources.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { SelectSourceMenu } from "./select-source-menu.component";

interface IAddSourceButtonProps {
  organizationId: string;
  selectedSourceIds: string[];
  tooltip: string;
  onAddSource: Dispatch<ISource>;
  onlyAllowReferencableSources?: boolean;
  disabled?: boolean;
}

export const AddSourceButton: FC<IAddSourceButtonProps> = ({
  organizationId,
  selectedSourceIds,
  tooltip,
  onAddSource,
  onlyAllowReferencableSources,
  disabled,
}) => {
  const menuAnchorElementRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const sourcesQuery = useSourcesQuery(organizationId);

  const handleAddSource = useCallback(
    (source: ISource) => {
      onAddSource(source);
      setShowMenu(false);
    },
    [onAddSource],
  );

  return (
    <QueryWrapper
      query={sourcesQuery}
      loadingOverride={() => false} // Do not show spinner
      build={(sources) => (
        <>
          {/* Menu */}
          <SelectSourceMenu
            organizationId={organizationId}
            show={showMenu}
            anchorEl={menuAnchorElementRef.current}
            sources={sources}
            selectedSourceIds={selectedSourceIds}
            onAddSource={handleAddSource}
            onlyAllowReferencableSources={onlyAllowReferencableSources}
            onClose={() => setShowMenu(false)}
          />

          {/* Add Button */}
          <Tooltip title={tooltip} placement="right">
            <span>
              <IconButton
                ref={menuAnchorElementRef}
                onClick={() => setShowMenu(true)}
                disabled={disabled}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
      )}
    />
  );
};
