import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { useCallback } from "react";
import { IInputParameterValueMetaDataNestedOptions } from "@netcero/netcero-core-api-client";
import { RecursiveUtilities } from "@netcero/netcero-common";

export const useGenerateNestedOptionsLookup = () => {
  const translateContent = useTranslateContent();
  return useCallback(
    (metaData: IInputParameterValueMetaDataNestedOptions) =>
      RecursiveUtilities.flattenRecursiveStructureDown(metaData.options).reduce((acc, curr) => {
        acc[curr.value] = translateContent(curr.name);
        return acc;
      }, {} as Record<string, string>),
    [translateContent],
  );
};
