import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { SourcesUtilities } from "./sources.utilities";

export const getSourcesQueryKey = (organizationId: string) => ["sources", organizationId];

export const useSourcesQuery = (organizationId: string | undefined) => {
  return useQuery({
    queryKey: getSourcesQueryKey(organizationId!),
    queryFn: () =>
      CoreApiService.SourcesApi.getSourcesForOrganization(organizationId!)
        .then((req) => req())
        .then((res) => SourcesUtilities.convertAllSourcesResponseToLocal(res.data)),
    enabled: !!organizationId,
  });
};

export const downloadSourceFile = async (
  organizationId: string,
  sourceId: string,
  sourceFileId: string,
) => {
  return CoreApiService.SourceFilesApi.downloadSourceFile(organizationId, sourceId, sourceFileId)
    .then((req) => req())
    .then((res) => res.data);
};
