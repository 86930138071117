import { Box, styled, ToggleButton } from "@mui/material";

export const EditorContainer = styled(Box)(({ theme }) => ({
  ":focus-within": {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    margin: -1,
  },
}));

export const TextEditorToggleButton = styled(ToggleButton)({
  border: "none",
  borderRadius: 8,
});
