import { Box, Chip, IconButton, TextField } from "@mui/material";
import { FC, useRef, useState } from "react";
import { EnterIcon } from "../constants/tabler-icon.constants";

interface IChipListInputProps {
  value: string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
}
/**
 * Input for unique string values.
 */
export const ChipListInput: FC<IChipListInputProps> = ({ value, disabled, onChange }) => {
  const textfieldInputRef = useRef<HTMLInputElement>(null);
  const [textFieldValue, setTextFieldValue] = useState("");

  const handleAddValue = () => {
    const newValue = textFieldValue.trim();
    if (newValue) {
      const newValues = [...value, newValue];
      const sanitizedValues = newValues.filter((val, idx) => newValues.indexOf(val) === idx);
      onChange(sanitizedValues);
      setTextFieldValue("");
    }
  };

  const handleInputKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      evt.preventDefault();
      handleAddValue();
    }
  };

  const removeValue = (additionalType: string) => {
    onChange(value.filter((value) => value !== additionalType));
  };

  const handleEditValue = (additionalType: string) => {
    if (!textFieldValue.trim()) {
      removeValue(additionalType);
      setTextFieldValue(additionalType);
      textfieldInputRef.current?.focus();
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
      {value.map((listItem) => (
        <Chip
          key={listItem}
          label={listItem}
          size="small"
          onClick={() => handleEditValue(listItem)}
          onDelete={() => removeValue(listItem)}
          variant="outlined"
          disabled={disabled}
        />
      ))}
      <TextField
        inputRef={textfieldInputRef}
        variant="standard"
        value={textFieldValue}
        onChange={(evt) => setTextFieldValue(evt.target.value)}
        onKeyDown={handleInputKeyDown}
        onBlur={handleAddValue}
        InputProps={{
          endAdornment: (
            <IconButton size="small" onClick={handleAddValue}>
              <EnterIcon />
            </IconButton>
          ),
        }}
        disabled={disabled}
      />
    </Box>
  );
};
