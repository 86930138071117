import { Dispatch, FC, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { ISource } from "@netcero/netcero-core-api-client";
import { AddSourceButton } from "./add-source-button.component";
import { sorted } from "@netcero/netcero-common";
import { useSourcesQuery } from "../sources.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { SourceChip, SourceChipSkeleton } from "./source-chip.component";

interface ILinkedSourcesChipsListEditorProps {
  organizationId: string;
  sourceIds: string[];
  onChange: Dispatch<string[]>;
  noLinkedSourcesText: string;
  addButtonTooltip: string;
  disabled?: boolean;
}

export const LinkedSourcesChipsListEditor: FC<ILinkedSourcesChipsListEditorProps> = ({
  noLinkedSourcesText,
  addButtonTooltip,
  organizationId,
  sourceIds,
  onChange,
  disabled,
}) => {
  const sourcesQuery = useSourcesQuery(organizationId);

  const handleAddSource = useCallback(
    (source: ISource) => {
      onChange(sorted([...sourceIds, source.id]));
    },
    [onChange, sourceIds],
  );

  const handleRemove = useCallback(
    (source: ISource) => {
      onChange(sorted(sourceIds.filter((id) => id !== source.id)));
    },
    [sourceIds, onChange],
  );

  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      {sourceIds.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          {noLinkedSourcesText}
        </Typography>
      )}
      {/* Chips */}
      <QueryWrapper
        query={sourcesQuery}
        loadingOverride={() => sourceIds.map((sourceId) => <SourceChipSkeleton key={sourceId} />)}
        build={(sources) =>
          sources
            .filter((s) => sourceIds.includes(s.id))
            .map((source) => (
              <SourceChip
                key={source.id}
                organizationId={organizationId}
                source={source}
                onDelete={handleRemove}
                disabled={disabled}
              />
            ))
        }
      />
      {/* Add Button */}
      <AddSourceButton
        organizationId={organizationId}
        selectedSourceIds={sourceIds}
        onAddSource={handleAddSource}
        tooltip={addButtonTooltip}
        onlyAllowReferencableSources
        disabled={disabled}
      />
    </Box>
  );
};
