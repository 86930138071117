import { Box, SvgIcon, Typography } from "@mui/material";
import { IIROState } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DMA_IRO_STATE_COLORS, DMA_IRO_STATE_ICONS } from "./dma.constants";

interface IIROStateIndicatorInlineProps {
  state: IIROState;
}
export const IROStateIndicatorInline: FC<IIROStateIndicatorInlineProps> = ({ state }) => {
  const { t } = useTranslation("dma_common");

  const [color, Icon] = useMemo(() => {
    return [DMA_IRO_STATE_COLORS[state], DMA_IRO_STATE_ICONS[state]];
  }, [state]);

  return (
    <Box display="flex" alignItems="center">
      <SvgIcon color={color}>
        <Icon />
      </SvgIcon>
      <Typography variant="body2">{t(`iro_state_${state}`)}</Typography>
    </Box>
  );
};
