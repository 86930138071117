import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useMemo } from "react";

export const useQuerySyncedSelectItem = <T extends { id: string }>(
  queryParamName: string,
  items: T[],
  alwaysRequireSelection: boolean = true,
  defaultItemIndex: number = 0,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentItemId = useMemo(() => {
    const param = searchParams.get(queryParamName);
    // ensure that the param actually exists; treat it as null if it doesn't
    return items.find((i) => i.id === param) !== undefined ? param : null;
  }, [queryParamName, searchParams, items]);

  useEffect(() => {
    // make sure to make default selection for query parameter
    if (currentItemId === null && items.length > defaultItemIndex && alwaysRequireSelection) {
      setSearchParams({ [queryParamName]: items[defaultItemIndex].id }, { replace: true });
    } else if (
      // item is null + get is not null --> not found among current items; no selection required --> remove param
      currentItemId === null &&
      searchParams.get(queryParamName) !== null &&
      !alwaysRequireSelection
    ) {
      searchParams.delete(queryParamName);
      setSearchParams(searchParams, { replace: true });
    }
  }, [
    items,
    setSearchParams,
    defaultItemIndex,
    currentItemId,
    queryParamName,
    alwaysRequireSelection,
    searchParams,
  ]);

  const currentItem = useMemo(() => {
    if (currentItemId === null) {
      return null;
    }
    return items.find((item) => item.id === currentItemId)!;
  }, [items, currentItemId]);

  const setCurrentItem = useCallback(
    (itemId: string | null) => {
      if (itemId === null) {
        searchParams.delete(queryParamName);
        setSearchParams(searchParams, { replace: true });
      } else {
        setSearchParams({ [queryParamName]: itemId }, { replace: true });
      }
    },
    [queryParamName, searchParams, setSearchParams],
  );

  return {
    currentItemId,
    currentItem,
    setCurrentItem,
  };
};
