import { useCallback, useState } from "react";

export interface IDialogState<TData> {
  open: boolean;
  data: TData;
}

export type OpenDialogHandler<T> = (withNewState: T | null) => void;

export const useWrapOpenDialogHandlerWithReset = (resetMutations: () => void) =>
  useCallback(
    <T>(handler: OpenDialogHandler<T>) =>
      (withNewState: T | null = null, shouldResetMutations = true) => {
        if (shouldResetMutations) {
          resetMutations();
        }
        handler(withNewState);
      },
    [resetMutations],
  );

/**
 * Hook to manage the state of a dialog
 * @param initialState Initial state of the dialog
 * @param initiallyOpen Whether the dialog should be open initially
 * @returns state of the dialog and functions to open and close it
 */
export const useDialogState = <T extends object | string>(
  initialState?: T,
  initiallyOpen = false,
) => {
  const [state, setState] = useState<IDialogState<T | undefined>>({
    data: initialState,
    open: initiallyOpen,
  });

  const openDialog = useCallback(
    (withNewState: T | null = null) => {
      if (withNewState !== null) {
        setState({ data: withNewState, open: true });
      } else {
        setState((s) => ({ ...s, open: true }));
      }
    },
    [setState],
  );

  const closeDialog = useCallback(
    (resetToInitial = false) => {
      if (resetToInitial) {
        setState({ data: initialState, open: false });
      } else {
        setState((s) => ({ ...s, open: false }));
      }
    },
    [setState, initialState],
  );

  const updateDialogData = useCallback(
    (updatedData: T) => {
      setState((prevState) => ({
        ...prevState,
        data: updatedData,
      }));
    },
    [setState],
  );

  return {
    isOpen: state.open,
    data: state.data,
    openDialog,
    closeDialog,
    updateDialogData,
  };
};

export const useDialogStateWithoutData = (initiallyOpen = false) =>
  useDialogState<never>(undefined, initiallyOpen);
