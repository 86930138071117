import { useTranslation } from "react-i18next";
import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/system";
import { IroType } from "@netcero/netcero-common";
import { IRO_STATE_COLORS } from "../../../theme/theme";

interface IProps extends BoxProps {
  iroType: IroType;
}

// Use `shouldForwardProp` to prevent `iroType` from being passed to the DOM element.
const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "iroType",
})<IProps>(({ iroType }) => ({
  // Layout
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  // Sizing
  maxWidth: "fit-content",
  whiteSpace: "nowrap",
  // Spacing
  padding: "4px 12px 4px 12px",
  borderRadius: "8px",
  // Color
  color: "rgba(0, 0, 0, 0.6)",
  backgroundColor: IRO_STATE_COLORS[iroType],
  // Text
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 700,
}));

export const IroTypeIndicator = ({ iroType, ...props }: IProps) => {
  const { t } = useTranslation("iroTypes");
  const iroTypeLabel = t(`singular.${iroType}`, { ns: "iro_types" });

  return (
    <StyledBox iroType={iroType} {...props}>
      {iroTypeLabel}
    </StyledBox>
  );
};
