import { FC, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  ConfigureIcon,
  DistributionCriteriaIcon,
  EntryDataIcon,
  RecordingPeriodIcon,
  SourcesIcon,
  StakeholderIcon,
  TargetPathsIcon,
  TargetPathsReferenceBudgetsIcon,
  UsersIcon,
} from "../constants/tabler-icon.constants";
import { ISideBarNavigationItemTopLevel } from "../side-bar-sections/navigation-items-side-bar-section.component";
import { EnvironmentUtilities } from "../utilities/environment.utilities";
import { useHasOrganizationModuleAccess } from "../../organization-module-access/has-organization-module-access.hook";
import { useHasOrganizationFullAccess } from "../../organization-module-access/has-organization-full-access.hook";
import { AppModule } from "@netcero/netcero-common";
import { useIsFeatureFlagEnabled } from "./is-feature-flag-enabled.hook";
import { FeatureFlag } from "../constants/feature-flags.constants";

function setNavbarIconProps<T>(Icon: FC<T>) {
  return forwardRef((props: T, ref) => {
    return <Icon size={24} {...props} ref={ref} />;
  });
}

export const useOrganizationSideBarNavItems = () => {
  const { organizationId } = useParams();

  const doesCurrentOrgHaveFullAccess = useHasOrganizationFullAccess(organizationId ?? "");
  const hasAccessToDMA = useHasOrganizationModuleAccess(organizationId ?? "", AppModule.DMA);
  const isUserManagementEnabled = useIsFeatureFlagEnabled(FeatureFlag.USER_MANAGEMENT);

  const { t } = useTranslation("side_bar_nav_items");

  return useMemo<ISideBarNavigationItemTopLevel[]>(() => {
    const result: ISideBarNavigationItemTopLevel[] = [
      {
        id: "value_input",
        name: t("value_input"),
        Icon: setNavbarIconProps(EntryDataIcon),
        path: `/organizations/${organizationId}`,
        children: [],
      },
    ];

    if (!EnvironmentUtilities.IS_PRODUCTION || doesCurrentOrgHaveFullAccess) {
      result.push({
        id: "target_paths",
        name: t("target_paths"),
        Icon: setNavbarIconProps(TargetPathsIcon),
        path: `/organizations/${organizationId}/target-paths`,
        children: [
          {
            id: "target_paths_reference_budgets",
            name: t("target_paths_reference_budgets"),
            Icon: setNavbarIconProps(TargetPathsReferenceBudgetsIcon),
            path: `/organizations/${organizationId}/target-paths/reference-budgets`,
          },
        ],
      });
    }

    // Always add Configuration
    result.push({
      id: "configuration",
      name: t("configuration"),
      Icon: setNavbarIconProps(ConfigureIcon),
      path: `/organizations/${organizationId}/configuration`,
      children: [
        {
          id: "configuration_recording_periods",
          name: t("configuration_recording_periods"),
          Icon: setNavbarIconProps(RecordingPeriodIcon),
          path: `/organizations/${organizationId}/configuration/recording-periods`,
        },
        ...(hasAccessToDMA
          ? [
              {
                id: "configuration_stakeholder",
                name: t("configuration_stakeholder"),
                Icon: setNavbarIconProps(StakeholderIcon),
                path: `/organizations/${organizationId}/configuration/stakeholder`,
              },
            ]
          : []),
        ...(!EnvironmentUtilities.IS_PRODUCTION || doesCurrentOrgHaveFullAccess
          ? [
              {
                id: "configuration_distribution_criteria",
                name: t("configuration_distribution_criteria"),
                Icon: setNavbarIconProps(DistributionCriteriaIcon),
                path: `/organizations/${organizationId}/configuration/distribution-criteria`,
              },
            ]
          : []),
        {
          id: "configuration_sources",
          name: t("configuration_sources"),
          Icon: setNavbarIconProps(SourcesIcon),
          path: `/organizations/${organizationId}/configuration/sources`,
        },
        ...(isUserManagementEnabled
          ? [
              {
                id: "configuration_users",
                name: t("configuration_users"),
                Icon: setNavbarIconProps(UsersIcon),
                path: `/organizations/${organizationId}/configuration/users`,
              },
            ]
          : []),
      ],
    });

    return result;
  }, [t, organizationId, doesCurrentOrgHaveFullAccess, hasAccessToDMA, isUserManagementEnabled]);
};
