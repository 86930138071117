import { IEmissionsForTargetGoal, ScopeKeys } from "@netcero/netcero-common";
import { IReferenceBudgetStoredValuesCalculations } from "@netcero/netcero-core-api-client";

interface IEmissionsForTargetGoalWrapper {
  type: "forTargetGoal";
  emissions: IEmissionsForTargetGoal;
}

interface IReferenceBudgetStoredValuesCalculationsWrapper {
  type: "forReferenceBudget";
  emissions: IReferenceBudgetStoredValuesCalculations;
}

type Emissions = IEmissionsForTargetGoalWrapper | IReferenceBudgetStoredValuesCalculationsWrapper;

export type TargetPathValueGetter = (emissions: Emissions) => number;

export const TargetPathValueGetterSum: TargetPathValueGetter = (emissions: Emissions) =>
  emissions.emissions.sum;

export const TargetPathValueGetterPerScopes = (scopes: ScopeKeys[]): TargetPathValueGetter => {
  return (emissions: Emissions) => {
    const values =
      emissions.type === "forTargetGoal"
        ? emissions.emissions.perScope
        : emissions.emissions.sumsPerScope;

    return Object.entries(values).reduce(
      (acc, [currentScope, currentScopeValue]) =>
        scopes.find((s) => s === currentScope) ? acc + currentScopeValue! : acc,
      0,
    );
  };
};
