import { Box, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { SIDE_BAR_TRANSITION_DURATION } from "../components/side-bar.component";
import { IconSize, SelectorIcon } from "../constants/tabler-icon.constants";
import { FormatUtilities } from "../utilities/format.utilities";
import { useOrganizations } from "../../organizations/organizations.hook";

interface IOrganizationSelectSideBarSectionProps {
  organizationId?: string;
  expanded: boolean;
  onChange: (organizationId: string) => void;
}

export const OrganizationSelectSideBarSection: FC<IOrganizationSelectSideBarSectionProps> = ({
  organizationId,
  expanded,
  onChange,
}) => {
  const organizations = useOrganizations();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      borderRadius={2}
      px={2}
      py={1}
      sx={{ backgroundColor: "secondary.950" }}
    >
      <Select
        value={organizationId}
        onChange={(event) => onChange(event.target.value)}
        renderValue={(value) => {
          const organization = organizations.find((organization) => organization.id === value);

          return (
            <Box
              color={(theme) => theme.palette.secondary.contrastText}
              overflow="hidden"
              width={expanded ? "190px" : "0px"}
              sx={{ transition: `width ${SIDE_BAR_TRANSITION_DURATION}ms` }}
            >
              {/* cutTextAfterN still makes sense here since LineClampTypography exhibits unexpected behaviour */}
              {/* eslint-disable-next-line deprecation/deprecation */}
              {FormatUtilities.cutTextAfterN(organization?.name ?? "", 22)}
            </Box>
          );
        }}
        variant="standard"
        fullWidth
        disableUnderline
        IconComponent={() => (
          <Box
            display="flex"
            color={(theme) => theme.palette.secondary.contrastText}
            ml={expanded ? undefined : -5.125}
            sx={{
              transition: `margin-left ${SIDE_BAR_TRANSITION_DURATION}ms`,
              pointerEvents: "none",
            }}
          >
            <SelectorIcon size={IconSize.Small} />
          </Box>
        )}
        sx={{
          pr: 0,
        }}
      >
        {organizations.map((organization) => (
          <MenuItem key={organization.id} value={organization.id}>
            {organization.name}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
