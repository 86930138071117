import React, { FC } from "react";
import { TargetsUtilities } from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import {
  IMdrTopicSectionCommonProps,
  MdrTopicSection,
} from "../../minimum-disclosure-requirements-common/components/mdr-topic-section.component";
import { ITargetEditDialogData } from "../targets-app.utilities";

type ITargetTopicComponentProps = IMdrTopicSectionCommonProps<ITargetEditDialogData>;

export const TargetTopicComponent: FC<ITargetTopicComponentProps> = (prop) => {
  const { t } = useTranslation("data_entry_object_target_component");

  return (
    <MdrTopicSection
      {...prop}
      t={t}
      isInputConditional={(propertyPath) => TargetsUtilities.isInputConditional(propertyPath)}
      isInputDependedUponByAnother={TargetsUtilities.isInputDependedUponByAnother}
      isInputAvailable={TargetsUtilities.shouldInputBeShown}
    />
  );
};
