import { Box, Divider, IconButton, Tooltip, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HideChildrenInProductionAndNoFullAccessComponent } from "../../modules/common/components/hide-children-in-production-and-no-full-access.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { AuditLogIcon, IconSize } from "../../modules/common/constants/tabler-icon.constants";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ConfigurationSection } from "./configuration-section.component";
import { RequireFeatureFlag } from "../../modules/common/components/require-feature-flag.component";
import { FeatureFlag } from "../../modules/common/constants/feature-flags.constants";

export const ConfigurationDashboardPage: FC = () => {
  const { t } = useTranslation("configuration_dashboard_page");

  const { organizationId } = useParams();
  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  return (
    <Box p={4} maxWidth={1600} width="100%" mx="auto">
      <Box display="flex" justifyContent="space-between" gap={2} mt={2}>
        <Typography variant="h1" mb={4}>
          {t("title")}
        </Typography>
        <Link to={`/organizations/${organizationId}/configuration/audit-logging`}>
          <Tooltip title={t("title_audit_logging", { ns: "common" })}>
            <IconButton>
              <AuditLogIcon size={IconSize.Large} />
            </IconButton>
          </Tooltip>
        </Link>
      </Box>
      {/* Organization */}
      {/* <ConfigurationOverviewSectionContainer>
        <Typography variant="h2">{t("title_organization")}</Typography>
        <Box display="flex" gap={2} mt={2}>
          <Typography flex={1}>{t("description_organization")}</Typography>
          <Link to={`/organizations/${organizationId}/configuration/organization`}>
            <Button variant="contained">{t("button_organization")}</Button>
          </Link>
        </Box>
      </ConfigurationOverviewSectionContainer>

      <Divider sx={{ my: 2 }} /> */}

      <ConfigurationSection
        title={t("title_recording_periods")}
        description={t("description_recording_periods")}
        url={`/organizations/${organizationId}/configuration/recording-periods`}
        buttonLabel={t("button_recording_periods")}
      />
      <Divider sx={{ my: 2 }} />

      <ConfigurationSection
        title={t("title_stakeholder")}
        description={t("description_stakeholder")}
        url={`/organizations/${organizationId}/configuration/stakeholder`}
        buttonLabel={t("button_stakeholder")}
      />
      <Divider sx={{ my: 2 }} />

      <HideChildrenInProductionAndNoFullAccessComponent>
        <ConfigurationSection
          title={t("title_distribution_criteria")}
          description={t("description_distribution_criteria")}
          url={`/organizations/${organizationId}/configuration/distribution-criteria`}
          buttonLabel={t("button_distribution_criteria")}
        />
        <Divider sx={{ my: 2 }} />
      </HideChildrenInProductionAndNoFullAccessComponent>

      <ConfigurationSection
        title={t("title_sources")}
        description={t("description_sources")}
        url={`/organizations/${organizationId}/configuration/sources`}
        buttonLabel={t("button_sources")}
      />
      <Divider sx={{ my: 2 }} />

      <RequireFeatureFlag featureFlag={FeatureFlag.USER_MANAGEMENT}>
        <ConfigurationSection
          title={t("title_users")}
          description={t("description_users")}
          url={`/organizations/${organizationId}/configuration/users`}
          buttonLabel={t("button_users")}
        />
        <Divider sx={{ my: 2 }} />
      </RequireFeatureFlag>
    </Box>
  );
};
