import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { IconButton, TableCell, TableCellProps, TableRow, Typography } from "@mui/material";
import {
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameterUIConfigurationColumnItemInput,
  IInputParameterValue,
} from "@netcero/netcero-core-api-client";
import { DataEntryObjectValueInputComponent } from "../data-entry-object-value-input.component";
import {
  DataEntryObjectInputParameterValueType,
  InputParameterValuesPerKey,
} from "@netcero/netcero-common";
import { DeleteIcon } from "../../../common/constants/tabler-icon.constants";
import {
  IValuesErrorsEntry,
  IValuesErrorsPerKey,
} from "../../utilities/data-entry-object-values.utilities";
import { useTranslateValueError } from "../../hooks/translate-values-errors.hooks";
import { RenderTableValueReadonly } from "../render-value-readonly/render-table-value-readonly.component";
import { Property } from "csstype";
import {
  IDuplicateValueError,
  IDuplicateValuesErrors,
} from "./manage-data-entry-object-table-value.hook";
import { useTranslation } from "react-i18next";
import { useTranslateOptionalContent } from "../../../content-translation/hooks/translate-content.hook";
import { ITableStaticColumnValueDefinition } from "../../esrs/value-editing/esrs-value-editing.utilities";

function getCursorForInputType(type: DataEntryObjectInputParameterValueType): Property.Cursor {
  switch (type) {
    case "text":
      return "text";
    case "number":
      return "text";
    default:
      return "pointer";
  }
}

interface ICommonProps {
  editMode: boolean;
  disabled: boolean;
  onValueChange: (
    valueKey: string,
    newValue: IDataEntryObjectInputParameterValueValueForKey,
  ) => void;
  onStartEditing: VoidFunction;
}

interface IDataEntryObjectValuesInputTableEnteredValueRowProps extends ICommonProps {
  inputParameterValuesPerKey: InputParameterValuesPerKey;
  inputs: IInputParameterUIConfigurationColumnItemInput[];
  staticTableIdentifierColumnValueDefinition: ITableStaticColumnValueDefinition | undefined | null;
  /** Ordered list of alignments for each input - same order as in `ìnputs` property */
  inputAlignments: TableCellProps["align"][];
  enteredValue:
    | ILocalDataEntryObjectInputParameterValue
    | ILocalDataEntryObjectInputParameterValueData;
  rowErrors: IValuesErrorsPerKey;
  duplicateValuesErrors: IDuplicateValuesErrors;
  onDelete: VoidFunction;
}

export const DataEntryObjectValuesInputTableValueRow: FC<
  IDataEntryObjectValuesInputTableEnteredValueRowProps
> = ({
  inputParameterValuesPerKey,
  inputs,
  staticTableIdentifierColumnValueDefinition,
  inputAlignments,
  enteredValue,
  disabled,
  rowErrors,
  duplicateValuesErrors,
  editMode,
  onDelete,
  onValueChange,
  onStartEditing,
}) => {
  const { t } = useTranslation("data_entry_object_values_input_table");
  const translateOptionalContent = useTranslateOptionalContent();

  const duplicateError = useMemo(
    () =>
      duplicateValuesErrors.find((duplicateValuesError) =>
        Object.entries(duplicateValuesError).every(
          ([key, value]) => enteredValue.valuesPerKey[key]?.value === value,
        ),
      ),
    [duplicateValuesErrors, enteredValue],
  );

  const duplicateErrorFieldNames = useMemo(() => {
    return duplicateError
      ? inputs
          .filter((input) => duplicateError[input.valueKey] !== undefined)
          .map((input) => translateOptionalContent(input.label))
      : [];
  }, [duplicateError, inputs, translateOptionalContent]);

  return (
    <>
      <TableRow
        sx={{
          ...(duplicateError ? { "& > *": { borderBottom: "none" } } : {}),
          ":hover .table-row-actions-cell": {
            opacity: 1,
          },
        }}
      >
        {inputs.map((input, index) => (
          <RowCell
            key={input.valueKey}
            input={input}
            alignment={inputAlignments[index]}
            config={inputParameterValuesPerKey[input.valueKey]}
            value={enteredValue.valuesPerKey[input.valueKey]}
            error={rowErrors[input.valueKey]}
            duplicateError={duplicateError}
            isStaticCell={input.valueKey === staticTableIdentifierColumnValueDefinition?.key}
            editMode={editMode}
            onStartEditing={onStartEditing}
            onValueChange={onValueChange}
            disabled={disabled}
          />
        ))}
        {/* Actions Cell */}
        <TableCell
          className="table-row-actions-cell"
          align="right"
          sx={{ opacity: editMode ? 1 : 0, transition: "all 150ms ease" }}
        >
          <IconButton size="small" onClick={onDelete} disabled={disabled}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {duplicateError && (
        <TableRow>
          <TableCell colSpan={inputs.length + 1}>
            <Typography variant="body2" color="error">
              {t("duplicate_error", { fields: duplicateErrorFieldNames.join(", ") })}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

interface IRowCellProps extends ICommonProps {
  input: IInputParameterUIConfigurationColumnItemInput;
  alignment: TableCellProps["align"];
  config: IInputParameterValue;
  value: IDataEntryObjectInputParameterValueValueForKey | undefined;
  error: IValuesErrorsEntry | undefined;
  duplicateError: IDuplicateValueError | undefined;
  isStaticCell: boolean;
}

const RowCell: FC<IRowCellProps> = ({
  input,
  editMode,
  alignment,
  config,
  value,
  error,
  duplicateError,
  isStaticCell,
  onStartEditing,
  onValueChange,
  disabled,
}) => {
  const translateValueError = useTranslateValueError();
  const internalError = useMemo(() => {
    if (error) {
      return error;
    }
    if (duplicateError && duplicateError[input.valueKey] !== undefined) {
      return "any.not_unique";
    }

    return undefined;
  }, [error, duplicateError, input.valueKey]);

  // Autofocus used when cell is clicked --> so input is automatically focused and ready to edit
  const [initialAutoFocus, setInitialAutoFocus] = useState(false);
  // Reset autofocus when immediately after the first rerender
  useEffect(() => {
    if (initialAutoFocus) {
      setInitialAutoFocus(false);
    }
  }, [initialAutoFocus]);

  const cursorType = useMemo(
    () =>
      config.valueConfiguration.type === "simple"
        ? getCursorForInputType(config.valueConfiguration.configuration.type)
        : undefined,
    [config.valueConfiguration],
  );

  const canStartEditMode = useMemo(() => !editMode && !disabled, [editMode, disabled]);
  const handleClickCell = useCallback(() => {
    if (canStartEditMode) {
      setInitialAutoFocus(true);
      onStartEditing();
    }
  }, [canStartEditMode, onStartEditing]);

  if (config.valueConfiguration.type === "esrs-topic-phase-in") {
    return "Unsupported";
  }

  return (
    <TableCell
      key={input.valueKey}
      align={alignment}
      onClick={handleClickCell}
      sx={{
        cursor: canStartEditMode ? cursorType : undefined,
        transition: "background-color 150ms ease",
        ":hover": canStartEditMode
          ? {
              backgroundColor: "rgba(0, 0, 0, 0.04)",
            }
          : undefined,
      }}
    >
      {!isStaticCell && editMode ? (
        <DataEntryObjectValueInputComponent
          key={input.valueKey}
          variant="table"
          valueMetaData={config.valueConfiguration.configuration}
          value={value?.value}
          onChange={(value) => onValueChange(input.valueKey, { type: "simple", value })}
          // No labels for table
          required={config.required}
          disabled={disabled}
          error={translateValueError(internalError)}
          autoFocus={initialAutoFocus}
        />
      ) : (
        <RenderTableValueReadonly
          valueMetaData={config.valueConfiguration.configuration}
          value={value?.value}
        />
      )}
    </TableCell>
  );
};
