import { FC, useMemo } from "react";
import MultipleSelectChip from "../../../common/components/multiple-chip.component";
import { IAction } from "@netcero/netcero-core-api-client";

interface IActionsMultipleSelectChipProps {
  label?: string;
  onSelectClick: VoidFunction;
  actions: IAction[];
  selectedActionIds: string[];
}

export const ActionsMultipleSelectChip: FC<IActionsMultipleSelectChipProps> = ({
  label,
  onSelectClick,
  actions,
  selectedActionIds,
}) => {
  const selectedActionsNames = useMemo(
    () =>
      /** Actions are already sorted by name by the api - no sorting needed therefore */
      actions
        .filter((action) => selectedActionIds.includes(action.id))
        .map((action) => action.general.name),
    [actions, selectedActionIds],
  );

  return (
    <MultipleSelectChip
      label={label}
      onSelectClick={onSelectClick}
      chipNames={selectedActionsNames}
      fullWidth
      noMargin
    />
  );
};
