import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";

export const useRenderESRSTopicNameForIdentifier = () => {
  const { t } = useTranslation("esrs_topics");
  return useCallback(
    (identifier: IESRSTopicIdentifier | null | undefined) => {
      if (identifier === null || identifier === undefined) {
        return "";
      }
      return t(identifier);
    },
    [t],
  );
};
