import { Box } from "@mui/material";
import { FC } from "react";
import { SearchTextInput } from "../../modules/common/components/search-text-input.component";
import { Controller, useFormContext } from "react-hook-form";

export type UserTableFilters = {
  searchValue: string;
};

export const UserTableFilters: FC = () => {
  const { control } = useFormContext<UserTableFilters>();

  return (
    <Box maxWidth="360px">
      <Controller
        name="searchValue"
        control={control}
        render={({ field }) => (
          <SearchTextInput search={field.value} onSearchChange={field.onChange} />
        )}
      />
    </Box>
  );
};
