import Joi from "joi";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { FormatUtilities } from "../common/utilities/format.utilities";
import { DataEntryObjectInputParameterValueType } from "@netcero/netcero-common";

const ContextInterceptors: {
  [key in DataEntryObjectInputParameterValueType]?: (context: Joi.Context) => void;
} = {
  currency: (context) => {
    const typedContext = context as { limit?: number };
    // limit property was violated
    if (typedContext.limit !== undefined) {
      context.limit = FormatUtilities.formatCurrency(typedContext.limit / 100);
    }
  },
  date: (context: Joi.Context) => {
    const typedContext = context as { limit?: Date };
    if (typedContext.limit !== undefined) {
      context.limit = FormatUtilities.formatDate(typedContext.limit);
    }
  },
  "date-range": (context: Joi.Context) => {
    const typedContext = context as { limit?: Date };
    if (typedContext.limit !== undefined) {
      context.limit = FormatUtilities.formatDate(typedContext.limit);
    }
  },
};

export const useTranslateValidationError = () => {
  const { t } = useTranslation("value_validation_errors");

  const translateValidationError = useCallback(
    (error: Joi.ValidationError, valueType: DataEntryObjectInputParameterValueType) => {
      const details = error.details[0];
      const interceptor = ContextInterceptors[valueType];

      if (interceptor !== undefined && details.context !== undefined) {
        interceptor(details.context);
      }

      return t(details.type, details.message, details.context).toString();
    },
    [t],
  );

  return {
    translateValidationError,
  };
};
