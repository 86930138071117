/* tslint:disable */
/* eslint-disable */
/**
 * Phase Two Admin REST API
 * This is a REST API reference for the Phase Two Keycloak custom resources. These are extensions to the standard [Keycloak Admin REST API](https://www.keycloak.org/docs-api/17.0/rest-api/index.html).  ### Base URI format Paths specified in the documentation are relative to the the base URI. - Format: `https://<host>:<port>/auth/realms` - Example: `https://app.phasetwo.io/auth/realms`  ### Authentication Authentication is achieved by using the `Authentication: Bearer <token>` header in all requests. This is either the access token received from a normal authentication, or by a request directly to the OpenID Connect token endpoint.  It is recommended that you use a Keycloak Admin Client, such as [this one for Javascript](https://github.com/keycloak/keycloak-nodejs-admin-client), as they take care of authentication, getting an access token, and refreshing it when it expires.  #### Client credentials grant example ``` POST /auth/realms/test-realm/protocol/openid-connect/token Host: app.phasetwo.io Accept: application/json Content-type: application/x-www-form-urlencoded  grant_type=client_credentials&client_id=admin-cli&client_secret=fd649804-3a74-4d69-acaa-8f065c6b7da1 ```  #### Password grant example ``` POST /auth/realms/test-realm/protocol/openid-connect/token Host: app.phasetwo.io Accept: application/json Content-type: application/x-www-form-urlencoded  grant_type=password&username=uname@foo.com&password=pwd123AZY&client_id=admin-cli ```  ### SDKs Modern API libraries are available for several common languages. These are available as open source at the links below, or you can choose to generate your own using our [OpenAPI spec file](https://raw.githubusercontent.com/p2-inc/phasetwo-docs/main/openapi.yaml).  | Language | Library | | --- | --- | | Java (and other JVM langs) | https://github.com/p2-inc/phasetwo-java | | JavaScript/TypeScript | https://github.com/p2-inc/phasetwo-js | | Python | https://github.com/p2-inc/phasetwo-python |
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IUserRepresentation } from "../models";
/**
 * OrganizationMembershipsApi - axios parameter creator
 * @export
 */
export const OrganizationMembershipsApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Add the specified user to the specified organization as a member
     * @summary Add an organization member
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganizationMember: async (
      realm: string,
      orgId: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("addOrganizationMember", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("addOrganizationMember", "orgId", orgId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("addOrganizationMember", "userId", userId);
      const localVarPath = `/{realm}/orgs/{orgId}/members/{userId}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Check if a user is a member of an organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkOrganizationMembership: async (
      realm: string,
      orgId: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("checkOrganizationMembership", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("checkOrganizationMembership", "orgId", orgId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("checkOrganizationMembership", "userId", userId);
      const localVarPath = `/{realm}/orgs/{orgId}/members/{userId}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a paginated list of users who are a member of the specified organization.
     * @summary Get organization memberships
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} [search]
     * @param {number} [first]
     * @param {number} [max]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationMemberships: async (
      realm: string,
      orgId: string,
      search?: string,
      first?: number,
      max?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("getOrganizationMemberships", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("getOrganizationMemberships", "orgId", orgId);
      const localVarPath = `/{realm}/orgs/{orgId}/members`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (search !== undefined) {
        localVarQueryParameter["search"] = search;
      }

      if (first !== undefined) {
        localVarQueryParameter["first"] = first;
      }

      if (max !== undefined) {
        localVarQueryParameter["max"] = max;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get total number of members of a given organization
     * @summary Get organization members count
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationMembershipsCount: async (
      realm: string,
      orgId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("getOrganizationMembershipsCount", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("getOrganizationMembershipsCount", "orgId", orgId);
      const localVarPath = `/{realm}/orgs/{orgId}/members/count`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove the specified user from the specified organization as a member
     * @summary Remove an organization member
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationMember: async (
      realm: string,
      orgId: string,
      userId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'realm' is not null or undefined
      assertParamExists("removeOrganizationMember", "realm", realm);
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists("removeOrganizationMember", "orgId", orgId);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists("removeOrganizationMember", "userId", userId);
      const localVarPath = `/{realm}/orgs/{orgId}/members/{userId}`
        .replace(`{${"realm"}}`, encodeURIComponent(String(realm)))
        .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
        .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication access_token required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * OrganizationMembershipsApi - functional programming interface
 * @export
 */
export const OrganizationMembershipsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OrganizationMembershipsApiAxiosParamCreator(configuration);
  return {
    /**
     * Add the specified user to the specified organization as a member
     * @summary Add an organization member
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addOrganizationMember(
      realm: string,
      orgId: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addOrganizationMember(
        realm,
        orgId,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Check if a user is a member of an organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkOrganizationMembership(
      realm: string,
      orgId: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkOrganizationMembership(
        realm,
        orgId,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get a paginated list of users who are a member of the specified organization.
     * @summary Get organization memberships
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} [search]
     * @param {number} [first]
     * @param {number} [max]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationMemberships(
      realm: string,
      orgId: string,
      search?: string,
      first?: number,
      max?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IUserRepresentation>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationMemberships(
        realm,
        orgId,
        search,
        first,
        max,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get total number of members of a given organization
     * @summary Get organization members count
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrganizationMembershipsCount(
      realm: string,
      orgId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationMembershipsCount(
        realm,
        orgId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Remove the specified user from the specified organization as a member
     * @summary Remove an organization member
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeOrganizationMember(
      realm: string,
      orgId: string,
      userId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeOrganizationMember(
        realm,
        orgId,
        userId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * OrganizationMembershipsApi - factory interface
 * @export
 */
export const OrganizationMembershipsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = OrganizationMembershipsApiFp(configuration);
  return {
    /**
     * Add the specified user to the specified organization as a member
     * @summary Add an organization member
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addOrganizationMember(
      realm: string,
      orgId: string,
      userId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .addOrganizationMember(realm, orgId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Check if a user is a member of an organization
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkOrganizationMembership(
      realm: string,
      orgId: string,
      userId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .checkOrganizationMembership(realm, orgId, userId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a paginated list of users who are a member of the specified organization.
     * @summary Get organization memberships
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} [search]
     * @param {number} [first]
     * @param {number} [max]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationMemberships(
      realm: string,
      orgId: string,
      search?: string,
      first?: number,
      max?: number,
      options?: any,
    ): AxiosPromise<Array<IUserRepresentation>> {
      return localVarFp
        .getOrganizationMemberships(realm, orgId, search, first, max, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get total number of members of a given organization
     * @summary Get organization members count
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrganizationMembershipsCount(
      realm: string,
      orgId: string,
      options?: any,
    ): AxiosPromise<number> {
      return localVarFp
        .getOrganizationMembershipsCount(realm, orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove the specified user from the specified organization as a member
     * @summary Remove an organization member
     * @param {string} realm realm name (not id!)
     * @param {string} orgId organization id
     * @param {string} userId user id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeOrganizationMember(
      realm: string,
      orgId: string,
      userId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .removeOrganizationMember(realm, orgId, userId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * OrganizationMembershipsApi - interface
 * @export
 * @interface OrganizationMembershipsApi
 */
export interface OrganizationMembershipsApiInterface {
  /**
   * Add the specified user to the specified organization as a member
   * @summary Add an organization member
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApiInterface
   */
  addOrganizationMember(
    realm: string,
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Check if a user is a member of an organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApiInterface
   */
  checkOrganizationMembership(
    realm: string,
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Get a paginated list of users who are a member of the specified organization.
   * @summary Get organization memberships
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} [search]
   * @param {number} [first]
   * @param {number} [max]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApiInterface
   */
  getOrganizationMemberships(
    realm: string,
    orgId: string,
    search?: string,
    first?: number,
    max?: number,
    options?: AxiosRequestConfig,
  ): AxiosPromise<Array<IUserRepresentation>>;

  /**
   * Get total number of members of a given organization
   * @summary Get organization members count
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApiInterface
   */
  getOrganizationMembershipsCount(
    realm: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<number>;

  /**
   * Remove the specified user from the specified organization as a member
   * @summary Remove an organization member
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApiInterface
   */
  removeOrganizationMember(
    realm: string,
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * OrganizationMembershipsApi - object-oriented interface
 * @export
 * @class OrganizationMembershipsApi
 * @extends {BaseAPI}
 */
export class OrganizationMembershipsApi
  extends BaseAPI
  implements OrganizationMembershipsApiInterface
{
  /**
   * Add the specified user to the specified organization as a member
   * @summary Add an organization member
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApi
   */
  public addOrganizationMember(
    realm: string,
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationMembershipsApiFp(this.configuration)
      .addOrganizationMember(realm, orgId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Check if a user is a member of an organization
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApi
   */
  public checkOrganizationMembership(
    realm: string,
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationMembershipsApiFp(this.configuration)
      .checkOrganizationMembership(realm, orgId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a paginated list of users who are a member of the specified organization.
   * @summary Get organization memberships
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} [search]
   * @param {number} [first]
   * @param {number} [max]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApi
   */
  public getOrganizationMemberships(
    realm: string,
    orgId: string,
    search?: string,
    first?: number,
    max?: number,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationMembershipsApiFp(this.configuration)
      .getOrganizationMemberships(realm, orgId, search, first, max, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get total number of members of a given organization
   * @summary Get organization members count
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApi
   */
  public getOrganizationMembershipsCount(
    realm: string,
    orgId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationMembershipsApiFp(this.configuration)
      .getOrganizationMembershipsCount(realm, orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove the specified user from the specified organization as a member
   * @summary Remove an organization member
   * @param {string} realm realm name (not id!)
   * @param {string} orgId organization id
   * @param {string} userId user id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OrganizationMembershipsApi
   */
  public removeOrganizationMember(
    realm: string,
    orgId: string,
    userId: string,
    options?: AxiosRequestConfig,
  ) {
    return OrganizationMembershipsApiFp(this.configuration)
      .removeOrganizationMember(realm, orgId, userId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
