import { FC } from "react";
import { Box, Button, SxProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface INoticeComponentProps {
  text: string;
  buttonText: string;
  buttonLink: string;
  sx?: SxProps;
}

export const NoticeComponent: FC<INoticeComponentProps> = ({
  buttonLink,
  buttonText,
  text,
  sx,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      gap={1.5}
      sx={sx}
    >
      <Typography>{text}</Typography>
      <Button component={Link} to={buttonLink} variant="outlined">
        {buttonText}
      </Button>
    </Box>
  );
};
