import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getAuditLoggingQueryKey = (
  organizationId: string,
  page: number,
  rowsPerPage: number,
) => ["auditLogs", organizationId, page, rowsPerPage];

/**
 * Fetches all audit logs for a given organization
 */
export const useAuditLoggingQuery = (
  organizationId?: string,
  page: number = 1,
  rowsPerPage: number = 10,
) => {
  return useQuery({
    placeholderData: keepPreviousData,
    queryKey: getAuditLoggingQueryKey(organizationId!, page, rowsPerPage),
    queryFn: async () => {
      const offset = (page - 1) * rowsPerPage;
      return CoreApiService.AuditApi.getAuditLogs(organizationId!, rowsPerPage, offset)
        .then((r) => r())
        .then((res) => res.data);
    },
    enabled: !!organizationId, // Ensure the query only runs when organizationId is provided
  });
};
