import { FC, PropsWithChildren } from "react";
import { Box } from "@mui/material";

interface ICustomTabPanelComponentProps {
  index: number;
  value: number;
}

export const CustomTabPanelComponent: FC<PropsWithChildren<ICustomTabPanelComponentProps>> = ({
  index,
  value,
  children,
}) => {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};
