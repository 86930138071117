import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getPoliciesQueryKey } from "./policies.queries";
import { IPolicyData } from "@netcero/netcero-core-api-client";

interface IPolicyDataCommon {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

interface ICreatePolicyData extends IPolicyDataCommon {
  payload: IPolicyData;
}

interface IUpdatePolicyData extends IPolicyDataCommon {
  policyId: string;
  payload: IPolicyData;
}

interface IDeletePolicyData extends IPolicyDataCommon {
  policyId: string;
}

export const useCreatePolicyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      payload,
    }: ICreatePolicyData) => {
      return CoreApiService.PoliciesApi.createPolicy(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getPoliciesQueryKey(variables.organizationId, variables.recordingPeriodId),
      }),
  });
};

export const useUpdatePolicyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      policyId,
      payload,
    }: IUpdatePolicyData) => {
      return CoreApiService.PoliciesApi.updatePolicy(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        policyId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getPoliciesQueryKey(variables.organizationId, variables.recordingPeriodId),
      }),
  });
};

export const useDeletePolicyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      policyId,
    }: IDeletePolicyData) => {
      return CoreApiService.PoliciesApi.deletePolicy(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        policyId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getPoliciesQueryKey(variables.organizationId, variables.recordingPeriodId),
      }),
  });
};
