/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IContentLanguage } from "../models";
// @ts-ignore
import { ICreateDmaConfiguration400Response } from "../models";
// @ts-ignore
import { ICreateDmaConfigurationRequest } from "../models";
// @ts-ignore
import { ICreateReferenceBudget404Response } from "../models";
// @ts-ignore
import { IDMAConfiguration } from "../models";
// @ts-ignore
import { IDMAConfigurationData } from "../models";
// @ts-ignore
import { IDMAConfigurationExistence } from "../models";
// @ts-ignore
import { IDataEntryObjectDMAConfiguration } from "../models";
// @ts-ignore
import { IExecuteDoubleMaterialityAssessment404Response } from "../models";
// @ts-ignore
import { IExecuteDoubleMaterialityAssessment409Response } from "../models";
// @ts-ignore
import { IGetDataEntryObject404Response } from "../models";
// @ts-ignore
import { IGetDmaConfiguration400Response } from "../models";
// @ts-ignore
import { IInitializeDma404Response } from "../models";
// @ts-ignore
import { IOptOutOfDmaCategoryRequest } from "../models";
// @ts-ignore
import { IOptOutOfEsrsTopic409Response } from "../models";
/**
 * DoubleMaterialityAssessmentApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates the DMA configuration for the given recording period.
     * @summary Creates the DMA configuration for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {ICreateDmaConfigurationRequest} iCreateDmaConfigurationRequest Create a new DMA configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDmaConfiguration: async (
      organizationId: string,
      recordingPeriodId: string,
      iCreateDmaConfigurationRequest: ICreateDmaConfigurationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createDmaConfiguration", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("createDmaConfiguration", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'iCreateDmaConfigurationRequest' is not null or undefined
      assertParamExists(
        "createDmaConfiguration",
        "iCreateDmaConfigurationRequest",
        iCreateDmaConfigurationRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/dma-configuration`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iCreateDmaConfigurationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
     * @summary Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeDoubleMaterialityAssessment: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("executeDoubleMaterialityAssessment", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "executeDoubleMaterialityAssessment",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "executeDoubleMaterialityAssessment",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/execute-dma`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exports all IROs for the given DEO and returns them as CSV
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDmaIros: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      language: IContentLanguage,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("exportDmaIros", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("exportDmaIros", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("exportDmaIros", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'language' is not null or undefined
      assertParamExists("exportDmaIros", "language", language);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/export-iros`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (language !== undefined) {
        localVarQueryParameter["language"] = language;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the DMA configuration for the given recording period. If no configuration exists, a default configuration will be returned.
     * @summary Get the DMA configuration for the given recording period. I no configuration exists, a default configuration will be returned.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDmaConfiguration: async (
      organizationId: string,
      recordingPeriodId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getDmaConfiguration", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getDmaConfiguration", "recordingPeriodId", recordingPeriodId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/dma-configuration`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
     * @summary Get the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDmaConfigurationForDataEntryObject: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getDmaConfigurationForDataEntryObject", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getDmaConfigurationForDataEntryObject",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "getDmaConfigurationForDataEntryObject",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/dma-configuration`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Frees the user from having to specify effects for the specified data entry object and ESRS topic.
     * @summary Frees the user from having to specify effects for the specified data entry object and ESRS topic.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to execute the DMA.
     * @param {string} esrsTopicId The id of the ESRS topic for which to opt out of the DMA.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optOutOfEsrsTopic: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("optOutOfEsrsTopic", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("optOutOfEsrsTopic", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("optOutOfEsrsTopic", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("optOutOfEsrsTopic", "esrsTopicId", esrsTopicId);
      // verify required parameter 'iOptOutOfDmaCategoryRequest' is not null or undefined
      assertParamExists(
        "optOutOfEsrsTopic",
        "iOptOutOfDmaCategoryRequest",
        iOptOutOfDmaCategoryRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/opt-out`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iOptOutOfDmaCategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the DMA configuration for the given recording period.
     * @summary Update the DMA configuration for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IDMAConfigurationData} [iDMAConfigurationData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDmaConfiguration: async (
      organizationId: string,
      recordingPeriodId: string,
      iDMAConfigurationData?: IDMAConfigurationData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateDmaConfiguration", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateDmaConfiguration", "recordingPeriodId", recordingPeriodId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/dma-configuration`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDMAConfigurationData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DoubleMaterialityAssessmentApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DoubleMaterialityAssessmentApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates the DMA configuration for the given recording period.
     * @summary Creates the DMA configuration for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {ICreateDmaConfigurationRequest} iCreateDmaConfigurationRequest Create a new DMA configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDmaConfiguration(
      organizationId: string,
      recordingPeriodId: string,
      iCreateDmaConfigurationRequest: ICreateDmaConfigurationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAConfiguration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDmaConfiguration(
        organizationId,
        recordingPeriodId,
        iCreateDmaConfigurationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
     * @summary Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async executeDoubleMaterialityAssessment(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.executeDoubleMaterialityAssessment(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Exports all IROs for the given DEO and returns them as CSV
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportDmaIros(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      language: IContentLanguage,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportDmaIros(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        language,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the DMA configuration for the given recording period. If no configuration exists, a default configuration will be returned.
     * @summary Get the DMA configuration for the given recording period. I no configuration exists, a default configuration will be returned.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDmaConfiguration(
      organizationId: string,
      recordingPeriodId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAConfigurationExistence>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDmaConfiguration(
        organizationId,
        recordingPeriodId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
     * @summary Get the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDmaConfigurationForDataEntryObject(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDataEntryObjectDMAConfiguration>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDmaConfigurationForDataEntryObject(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Frees the user from having to specify effects for the specified data entry object and ESRS topic.
     * @summary Frees the user from having to specify effects for the specified data entry object and ESRS topic.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to execute the DMA.
     * @param {string} esrsTopicId The id of the ESRS topic for which to opt out of the DMA.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async optOutOfEsrsTopic(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.optOutOfEsrsTopic(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        iOptOutOfDmaCategoryRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the DMA configuration for the given recording period.
     * @summary Update the DMA configuration for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IDMAConfigurationData} [iDMAConfigurationData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDmaConfiguration(
      organizationId: string,
      recordingPeriodId: string,
      iDMAConfigurationData?: IDMAConfigurationData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IDMAConfiguration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDmaConfiguration(
        organizationId,
        recordingPeriodId,
        iDMAConfigurationData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DoubleMaterialityAssessmentApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DoubleMaterialityAssessmentApiFp(configuration);
  return {
    /**
     * Creates the DMA configuration for the given recording period.
     * @summary Creates the DMA configuration for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {ICreateDmaConfigurationRequest} iCreateDmaConfigurationRequest Create a new DMA configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDmaConfiguration(
      organizationId: string,
      recordingPeriodId: string,
      iCreateDmaConfigurationRequest: ICreateDmaConfigurationRequest,
      options?: any,
    ): AxiosPromise<IDMAConfiguration> {
      return localVarFp
        .createDmaConfiguration(
          organizationId,
          recordingPeriodId,
          iCreateDmaConfigurationRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
     * @summary Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    executeDoubleMaterialityAssessment(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .executeDoubleMaterialityAssessment(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Exports all IROs for the given DEO and returns them as CSV
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {IContentLanguage} language
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDmaIros(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      language: IContentLanguage,
      options?: any,
    ): AxiosPromise<File> {
      return localVarFp
        .exportDmaIros(organizationId, recordingPeriodId, dataEntryObjectId, language, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the DMA configuration for the given recording period. If no configuration exists, a default configuration will be returned.
     * @summary Get the DMA configuration for the given recording period. I no configuration exists, a default configuration will be returned.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDmaConfiguration(
      organizationId: string,
      recordingPeriodId: string,
      options?: any,
    ): AxiosPromise<IDMAConfigurationExistence> {
      return localVarFp
        .getDmaConfiguration(organizationId, recordingPeriodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
     * @summary Get the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDmaConfigurationForDataEntryObject(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      options?: any,
    ): AxiosPromise<IDataEntryObjectDMAConfiguration> {
      return localVarFp
        .getDmaConfigurationForDataEntryObject(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Frees the user from having to specify effects for the specified data entry object and ESRS topic.
     * @summary Frees the user from having to specify effects for the specified data entry object and ESRS topic.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to execute the DMA.
     * @param {string} esrsTopicId The id of the ESRS topic for which to opt out of the DMA.
     * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    optOutOfEsrsTopic(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .optOutOfEsrsTopic(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          iOptOutOfDmaCategoryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the DMA configuration for the given recording period.
     * @summary Update the DMA configuration for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IDMAConfigurationData} [iDMAConfigurationData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDmaConfiguration(
      organizationId: string,
      recordingPeriodId: string,
      iDMAConfigurationData?: IDMAConfigurationData,
      options?: any,
    ): AxiosPromise<IDMAConfiguration> {
      return localVarFp
        .updateDmaConfiguration(organizationId, recordingPeriodId, iDMAConfigurationData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DoubleMaterialityAssessmentApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentApi
 */
export interface DoubleMaterialityAssessmentApiInterface {
  /**
   * Creates the DMA configuration for the given recording period.
   * @summary Creates the DMA configuration for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {ICreateDmaConfigurationRequest} iCreateDmaConfigurationRequest Create a new DMA configuration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  createDmaConfiguration(
    organizationId: string,
    recordingPeriodId: string,
    iCreateDmaConfigurationRequest: ICreateDmaConfigurationRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDMAConfiguration>;

  /**
   * Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
   * @summary Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  executeDoubleMaterialityAssessment(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Exports all IROs for the given DEO and returns them as CSV
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {IContentLanguage} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  exportDmaIros(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    language: IContentLanguage,
    options?: AxiosRequestConfig,
  ): AxiosPromise<File>;

  /**
   * Returns the DMA configuration for the given recording period. If no configuration exists, a default configuration will be returned.
   * @summary Get the DMA configuration for the given recording period. I no configuration exists, a default configuration will be returned.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  getDmaConfiguration(
    organizationId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDMAConfigurationExistence>;

  /**
   * Returns the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
   * @summary Get the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  getDmaConfigurationForDataEntryObject(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDataEntryObjectDMAConfiguration>;

  /**
   * Frees the user from having to specify effects for the specified data entry object and ESRS topic.
   * @summary Frees the user from having to specify effects for the specified data entry object and ESRS topic.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to execute the DMA.
   * @param {string} esrsTopicId The id of the ESRS topic for which to opt out of the DMA.
   * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  optOutOfEsrsTopic(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Updates the DMA configuration for the given recording period.
   * @summary Update the DMA configuration for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IDMAConfigurationData} [iDMAConfigurationData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApiInterface
   */
  updateDmaConfiguration(
    organizationId: string,
    recordingPeriodId: string,
    iDMAConfigurationData?: IDMAConfigurationData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDMAConfiguration>;
}

/**
 * DoubleMaterialityAssessmentApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentApi
  extends BaseAPI
  implements DoubleMaterialityAssessmentApiInterface
{
  /**
   * Creates the DMA configuration for the given recording period.
   * @summary Creates the DMA configuration for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {ICreateDmaConfigurationRequest} iCreateDmaConfigurationRequest Create a new DMA configuration
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public createDmaConfiguration(
    organizationId: string,
    recordingPeriodId: string,
    iCreateDmaConfigurationRequest: ICreateDmaConfigurationRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .createDmaConfiguration(
        organizationId,
        recordingPeriodId,
        iCreateDmaConfigurationRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
   * @summary Executes the DMA for the specified data entry object and creates a structure for every category that has at least one relevant input parameter linked.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public executeDoubleMaterialityAssessment(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .executeDoubleMaterialityAssessment(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Exports all IROs for the given DEO and returns them as CSV
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {IContentLanguage} language
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public exportDmaIros(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    language: IContentLanguage,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .exportDmaIros(organizationId, recordingPeriodId, dataEntryObjectId, language, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the DMA configuration for the given recording period. If no configuration exists, a default configuration will be returned.
   * @summary Get the DMA configuration for the given recording period. I no configuration exists, a default configuration will be returned.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public getDmaConfiguration(
    organizationId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .getDmaConfiguration(organizationId, recordingPeriodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
   * @summary Get the DMA configuration for the given data entry object. If no configuration exists, a default configuration will be returned.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public getDmaConfigurationForDataEntryObject(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .getDmaConfigurationForDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Frees the user from having to specify effects for the specified data entry object and ESRS topic.
   * @summary Frees the user from having to specify effects for the specified data entry object and ESRS topic.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to execute the DMA.
   * @param {string} esrsTopicId The id of the ESRS topic for which to opt out of the DMA.
   * @param {IOptOutOfDmaCategoryRequest} iOptOutOfDmaCategoryRequest Used to opt out of a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public optOutOfEsrsTopic(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    iOptOutOfDmaCategoryRequest: IOptOutOfDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .optOutOfEsrsTopic(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        iOptOutOfDmaCategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the DMA configuration for the given recording period.
   * @summary Update the DMA configuration for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IDMAConfigurationData} [iDMAConfigurationData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentApi
   */
  public updateDmaConfiguration(
    organizationId: string,
    recordingPeriodId: string,
    iDMAConfigurationData?: IDMAConfigurationData,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentApiFp(this.configuration)
      .updateDmaConfiguration(organizationId, recordingPeriodId, iDMAConfigurationData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
