import {
  IBaseTargetData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ITargetDataE,
  ITargetDataS,
} from "@netcero/netcero-core-api-client";
import { IPolicyEditDialogSectionKey } from "../policies/policies-app.utilities";
import { WithEqualUndefinedAndNullRecursive, WithoutNestedObjects } from "@netcero/netcero-common";

export type ITargetEditDialogSectionKey =
  | "e1"
  | "e2"
  | "e3"
  | "e4"
  | "e5"
  | "s1"
  | "s2"
  | "s3"
  | "s4"
  | "g1";

export type TargetEDataOnly = WithoutNestedObjects<ITargetDataE>;

export type TargetSDataOnly = WithoutNestedObjects<ITargetDataS>;

export type ITargetEditDialogData = WithEqualUndefinedAndNullRecursive<IBaseTargetData>;

export class TargetsAppUtilities {
  public static getIdentifierKeyForTopic(
    topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ): ITargetEditDialogSectionKey {
    switch (topicIdentifier) {
      case "esrs_e1":
        return "e1";
      case "esrs_e2":
        return "e2";
      case "esrs_e3":
        return "e3";
      case "esrs_e4":
        return "e4";
      case "esrs_e5":
        return "e5";
      case "esrs_s1":
        return "s1";
      case "esrs_s2":
        return "s2";
      case "esrs_s3":
        return "s3";
      case "esrs_s4":
        return "s4";
      case "esrs_g1":
        return "g1";
    }
  }

  public static isSTopic(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "s1" | "s2" | "s3" | "s4" {
    return identifier.startsWith("s");
  }

  public static isETopic(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "e1" | "e2" | "e3" | "e4" | "e5" {
    return identifier.startsWith("e");
  }

  public static isE2ToE5(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "e2" | "e3" | "e4" | "e5" {
    return identifier === "e2" || identifier === "e3" || identifier === "e4" || identifier === "e5";
  }

  public static hasSpecificInputs(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "e1" | "e2" | "e3" | "e4" | "e5" | "s1" | "s2" | "s3" | "s4" {
    return TargetsAppUtilities.isSTopic(identifier) || TargetsAppUtilities.isETopic(identifier);
  }
}
