import { IMaterialImpactCalculatorData } from "../types/material-impact-calculator-data.type";
import { IBaseCalculator } from "./base-calculator.interface";
import { DMA_MATERIALITY_MULTIPLIER } from "../constants/dma.constants";
import { IBaseCalculatorContext } from "../types/base-calculator-context.interface";
import { BaseCalculator } from "./base-calculator";

export class MaterialImpactCalculator extends BaseCalculator implements IBaseCalculator {
  private static weightingSeverity = {
    extent: 1 / 3,
    scope: 1 / 3,
    irreversibility: 1 / 3,
  };

  constructor(
    private readonly data: IMaterialImpactCalculatorData,
    context: IBaseCalculatorContext,
  ) {
    super(context);
  }

  private get materialityMultiplier() {
    return DMA_MATERIALITY_MULTIPLIER;
  }

  private get resolvedProbability() {
    return this.data.probability === undefined
      ? undefined
      : this.resolveProbabilityValue(this.data.probability, "without-custom");
  }

  public calculateSeverity(): number {
    let result: number;
    if (this.data.irreversibility === undefined) {
      result =
        (this.data.extent * MaterialImpactCalculator.weightingSeverity.extent +
          this.data.scope * MaterialImpactCalculator.weightingSeverity.scope) /
        (MaterialImpactCalculator.weightingSeverity.extent +
          MaterialImpactCalculator.weightingSeverity.scope);
    } else {
      result =
        (this.data.extent * MaterialImpactCalculator.weightingSeverity.extent +
          this.data.scope * MaterialImpactCalculator.weightingSeverity.scope +
          this.data.irreversibility * MaterialImpactCalculator.weightingSeverity.irreversibility) /
        (MaterialImpactCalculator.weightingSeverity.extent +
          MaterialImpactCalculator.weightingSeverity.scope +
          MaterialImpactCalculator.weightingSeverity.irreversibility);
    }

    return BaseCalculator.normalizeValue(result);
  }

  public calculateMaterialityDegree(): number {
    const severity = this.calculateSeverity();
    if (this.resolvedProbability === undefined || this.data.hasNegativeEffectOnHumanRights) {
      return severity * this.materialityMultiplier;
    }

    const calculatedMateriality = (severity + this.resolvedProbability) / 2;

    const factorForMultiplication = Math.min(severity, calculatedMateriality);

    return BaseCalculator.normalizeValue(factorForMultiplication * this.materialityMultiplier);
  }

  public calculateMateriality(): boolean {
    return this.calculateMaterialityDegree() >= this.context.materialityThreshold;
  }
}
