import { FC } from "react";
import { LinkedSourcesChipsListEditor } from "../../sources/components/linked-sources-chips-list-editor.component";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterSourcesHandlingWrapper,
  IDataEntryObjectInputParameterSourcesHandlingWrapperPublicProps,
} from "./data-entry-object-input-parameter-sources-handling-wrapper.component";

interface IInputParameterLinkedSourcesPickerProps
  extends IDataEntryObjectInputParameterSourcesHandlingWrapperPublicProps {
  disabled?: boolean;
}

export const DataEntryObjectInputParameterLinkedSourcesPicker: FC<
  IInputParameterLinkedSourcesPickerProps
> = ({ disabled, ...props }) => {
  const { t } = useTranslation("linked_sources");

  return (
    <DataEntryObjectInputParameterSourcesHandlingWrapper
      {...props}
      render={(sourceIds, handleUpdateLinkedSources, isPending) => {
        return (
          <LinkedSourcesChipsListEditor
            organizationId={props.organizationId}
            noLinkedSourcesText={t("no_linked_sources")}
            addButtonTooltip={t("add_linked_source_tooltip")}
            sourceIds={sourceIds.sourceIds}
            onChange={handleUpdateLinkedSources}
            disabled={disabled || isPending}
          />
        );
      }}
    />
  );
};
