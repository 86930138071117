export interface ICommonLogger {
  log(message: unknown, ...optionalParams: unknown[]): void;
  error(message: unknown, ...optionalParams: unknown[]): void;
  warn(message: unknown, ...optionalParams: unknown[]): void;
  debug(message: unknown, ...optionalParams: unknown[]): void;
  verbose?(message: unknown, ...optionalParams: unknown[]): void;
}

export const CommonLogger: ICommonLogger = {
  log: console.log,
  error: console.error,
  warn: console.warn,
  debug: console.debug,
  verbose: console.debug,
};
