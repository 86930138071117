import { FC, ReactNode, useEffect, useState } from "react";
import { ITargetPathGoalValueChange } from "@netcero/netcero-core-api-client";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getVariantOfLiteral, TargetPathGoalChangeType } from "@netcero/netcero-common";
import { MaskedInput } from "../../common/components/masked-input.component";
import Decimal from "decimal.js-light";

type Translations = { [key in TargetPathGoalChangeType]: string };

type Unit = "tco2eq" | "eur";

const getEndAdornmentForChange = (unit: Unit, change: ITargetPathGoalValueChange) => {
  if (change.type === "inPercent") {
    return "%";
  }
  return unit === "tco2eq" ? "tCO\u2082eq" : "€";
};

const toInputValue = (value: Decimal, change: ITargetPathGoalValueChange) => {
  return change.type === "inPercent" ? value.mul(100) : value;
};

const toApiValue = (value: Decimal, change: ITargetPathGoalValueChange) => {
  return change.type === "inPercent" ? value.div(100) : value;
};

export interface ITargetPathGoalValueChangeInputComponentProps {
  change: ITargetPathGoalValueChange;
  translations?: Translations;
  onChangeTypeChange: (
    oldChangeType: TargetPathGoalChangeType,
    newChangeType: TargetPathGoalChangeType,
  ) => void;
  onValueChange: (newValue: number) => void;
  unit: Unit;
  disabled?: boolean;
  dropdownDisabled?: boolean;
  helperTextTextField?: ReactNode;
  tooltipTextField?: ReactNode;
  tooltipDropdown?: ReactNode;
  labelTextField?: string;
}

export const TargetPathGoalValueChangeInputComponent: FC<
  ITargetPathGoalValueChangeInputComponentProps
> = ({
  change,
  translations,
  onChangeTypeChange,
  unit,
  disabled,
  onValueChange,
  helperTextTextField,
  labelTextField,
  tooltipTextField,
  dropdownDisabled,
  tooltipDropdown,
}) => {
  const { t } = useTranslation("target_path_goal_value_change_input_component");

  // Component State
  const [value, setValue] = useState<Decimal>(new Decimal(change.value));

  // observe change to the value from the outside
  useEffect(() => {
    setValue(new Decimal(change.value));
  }, [setValue, change.value]);

  return (
    <Box display="flex" flexDirection="row" gap={2}>
      {/* Input for change as number */}
      <Tooltip
        title={tooltipTextField}
        placement="left"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-10, 0],
                },
              },
            ],
          },
        }}
      >
        <TextField
          fullWidth
          value={toInputValue(value, change).toString()}
          onChange={(value) => {
            const typedValue = value as unknown as string;
            if (typedValue !== "") {
              setValue(toApiValue(new Decimal(typedValue), change));
            } else {
              setValue(new Decimal(0));
            }
          }}
          helperText={helperTextTextField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getEndAdornmentForChange(unit, change)}
              </InputAdornment>
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: MaskedInput as any,
            inputProps: {
              mask: Number,
              scale: 2,
              unmask: true,
              eager: true,
              thousandsSeparator: ".",
              padFractionalZeros: false,
            },
          }}
          label={labelTextField ?? t("labels.change")}
          disabled={disabled}
          onBlur={() => {
            const changeValueTruncated = new Decimal(change.value).toDecimalPlaces(4);
            const currentValueTruncated = value.toDecimalPlaces(4);

            if (!changeValueTruncated.eq(currentValueTruncated)) {
              // 4 decimal places since scale is 2 (for percent values, which are always a fraction of 1)
              onValueChange(value.toDecimalPlaces(4).toNumber());
            }
          }}
        />
      </Tooltip>

      {/* Select for type of change*/}
      <Tooltip
        title={tooltipDropdown}
        placement="right"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-10, 0],
                },
              },
            ],
          },
        }}
      >
        <FormControl
          size="medium"
          fullWidth
          disabled={disabled || dropdownDisabled}
          sx={{ minWidth: 320 }}
        >
          <InputLabel>{t("labels.change_type")}</InputLabel>
          <Select
            label={t("labels.change_type")}
            onChange={(event) => {
              onChangeTypeChange(change.type, event.target.value as TargetPathGoalChangeType);
            }}
            value={change.type}
          >
            <MenuItem value={getVariantOfLiteral<TargetPathGoalChangeType>("inPercent")}>
              {translations
                ? translations["inPercent"]
                : getVariantOfLiteral<TargetPathGoalChangeType>("inPercent")}
            </MenuItem>
            <MenuItem value={getVariantOfLiteral<TargetPathGoalChangeType>("byValue")}>
              {translations
                ? translations["byValue"]
                : getVariantOfLiteral<TargetPathGoalChangeType>("byValue")}
            </MenuItem>
            <MenuItem value={getVariantOfLiteral<TargetPathGoalChangeType>("toValue")}>
              {translations
                ? translations["toValue"]
                : getVariantOfLiteral<TargetPathGoalChangeType>("toValue")}
            </MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
    </Box>
  );
};
