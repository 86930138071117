import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useRecordingPeriodsQuery } from "./recording-periods.queries";
import { LS_RECORDING_PERIODS_KEY } from "../common/constants/localstorage.constants";
import { useLocalStorage } from "@uidotdev/usehooks";

/**
 * Hook to get the last used recording period for an organization.
 * @param organizationId
 * @param lastUpdate The last update timestamp of the local storage (optional). Can be used to trigger updates.
 */
export const useLastUsedRecordingPeriod = (
  organizationId: string,
  lastUpdate: Date | null = null,
) => {
  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const [lastUsedRecordingPeriods] = useLocalStorage<Record<string, string>>(
    LS_RECORDING_PERIODS_KEY,
    {},
  );

  const lastUsedRecordingPeriodId: string | null = useMemo(
    () => lastUsedRecordingPeriods[organizationId],

    // lastUpdate is used to trigger updates therefore lint rule is disabled
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationId, lastUpdate, lastUsedRecordingPeriods],
  );

  const lastUsedRecordingPeriod = useMemo(() => {
    if (recordingPeriodsQuery.data && lastUsedRecordingPeriodId) {
      return (
        recordingPeriodsQuery.data.recordingPeriods.find(
          (recordingPeriod) => recordingPeriod.id === lastUsedRecordingPeriodId,
        ) ?? null
      );
    }
    return null;
  }, [lastUsedRecordingPeriodId, recordingPeriodsQuery.data]);

  return lastUsedRecordingPeriod ?? null;
};

/**
 * Hook to automatically update the last used recording period in local storage on changes.
 * @param organizationId
 * @param recordingPeriodId
 * @return The last update timestamp.
 */
export const useUpdateLastUsedRecordingPeriodLocalStorage = (
  organizationId: string,
  recordingPeriodId: string,
) => {
  const [lastUpdate, setLastUpdate] = useState(new Date());

  const [lastUsedRecordingPeriods, setLastUsedRecordingPeriods] = useLocalStorage<
    Record<string, string>
  >(LS_RECORDING_PERIODS_KEY, {});

  useEffect(() => {
    if (!organizationId || !recordingPeriodId) {
      return;
    }

    if (lastUsedRecordingPeriods[organizationId] === recordingPeriodId) {
      return;
    }

    setLastUsedRecordingPeriods((prev) => ({
      ...prev,
      [organizationId]: recordingPeriodId,
    }));

    setLastUpdate(new Date());
  }, [lastUsedRecordingPeriods, organizationId, recordingPeriodId, setLastUsedRecordingPeriods]);

  return lastUpdate;
};

/**
 * Hook to get the last used recording period from the router parameters.
 */
export const useLastUsedRecordingPeriodFromRouter = () => {
  const { organizationId } = useParams();
  return useLastUsedRecordingPeriod(organizationId!);
};

/**
 * Hook to update the last used recording period in local storage from the router parameters.
 */
export const useUpdateLastUsedRecordingPeriodLocalStorageFromRouter = () => {
  const { organizationId, recordingPeriodId } = useParams();
  return useUpdateLastUsedRecordingPeriodLocalStorage(organizationId!, recordingPeriodId!);
};

/**
 * Hook to get the last used recording period from the router parameters.
 * Updates to the local storage will also trigger updates of the return value.
 * (this hook is a full setup - updates only considered when occurring inside this hook - no instances of the update hook)
 */
export const useLastUsedRecordingPeriodSetupFromRouter = () => {
  const { organizationId, recordingPeriodId } = useParams();

  const lastUpdate = useUpdateLastUsedRecordingPeriodLocalStorage(
    organizationId!,
    recordingPeriodId!,
  );

  return useLastUsedRecordingPeriod(organizationId!, lastUpdate);
};
