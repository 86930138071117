import { Box, Typography } from "@mui/material";
import { IDMACategoryWithEffectsAndChildren, IESRSTopic } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { DMAMatrixChart } from "./dma-matrix/dma-matrix-chart.component";
import { IGroupedESRSTopics } from "./dma-dashboard.utilities";
import { useTranslation } from "react-i18next";

export interface IDMADashboardChartsSectionProps {
  groupedESRSTopics: IGroupedESRSTopics | null;
  esrsTopic: IESRSTopic | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  onSelectTopic: (topicId: string) => void;
  onSelectCategory: (topicId: string, categoryId: string) => void;
}

/**
 * Depending on the parameter set (last param takes highest prio)
 * @param groupedESRSTopics
 * @param esrsTopic
 * @param dmaCategory
 * @param onSelectTopic
 * @param onSelectCategory
 */
export const DMADashboardChartsSection: FC<IDMADashboardChartsSectionProps> = ({
  groupedESRSTopics,
  esrsTopic,
  dmaCategory,
  onSelectTopic,
  onSelectCategory,
}) => {
  const { t } = useTranslation("dma_dashboard");

  return (
    <Box>
      <Typography variant="h3" component="h2">
        {t("title_materiality_matrix")}
      </Typography>
      <Box minHeight={500} display="flex">
        <DMAMatrixChart
          groupedESRSTopics={groupedESRSTopics}
          esrsTopic={esrsTopic}
          dmaCategory={dmaCategory}
          height="55vh"
          onSelectTopic={onSelectTopic}
          onSelectCategory={onSelectCategory}
        />
      </Box>
    </Box>
  );
};
