import { EditorThemeClasses, SerializedEditorState, SerializedLexicalNode } from "lexical";
import { BeautifulMentionsTheme } from "lexical-beautiful-mentions";

export enum LexicalPriority {
  LOW = 1,
}

export const LEXICAL_EMPTY_EDITOR_STATE: SerializedEditorState = {
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
        textFormat: 0,
        textStyle: "",
      } as SerializedLexicalNode, // This is needed since otherwise the editor will not render the empty paragraph
    ],
    direction: null,
    format: "",
    indent: 0,
    type: "root",
    version: 1,
  },
};

const LEXICAL_BEAUTIFUL_MENTIONS_THEME: BeautifulMentionsTheme = {
  "@": {
    trigger: "editor-at-user-mention-trigger",
    value: "editor-at-user-mention-value",
    container: "editor-at-user-mention-container",
    containerFocused: "editor-at-user-mention-container-focused",
  },
};

export const LEXICAL_THEME: EditorThemeClasses = {
  code: "editor-code",
  heading: {
    h1: "editor-heading-h1",
    h2: "editor-heading-h2",
    h3: "editor-heading-h3",
    h4: "editor-heading-h4",
    h5: "editor-heading-h5",
  },
  image: "editor-image",
  link: "editor-link",
  list: {
    listitem: "editor-listitem",
    nested: {
      listitem: "editor-nested-listitem",
    },
    ol: "editor-list-ol",
    ul: "editor-list-ul",
  },
  ltr: "ltr",
  paragraph: "editor-paragraph",
  placeholder: "editor-placeholder",
  quote: "editor-quote",
  rtl: "rtl",
  text: {
    bold: "editor-text-bold",
    code: "editor-text-code",
    hashtag: "editor-text-hashtag",
    italic: "editor-text-italic",
    overflowed: "editor-text-overflowed",
    strikethrough: "editor-text-strikethrough",
    underline: "editor-text-underline",
    underlineStrikethrough: "editor-text-underlineStrikethrough",
  },
  // Plugins
  beautifulMentions: LEXICAL_BEAUTIFUL_MENTIONS_THEME,
};
