import axios from "axios";

const BEARER_TOKEN_HEADER = "Authorization";

export class AuthenticationService {
  static setBearerTokenOnAxios(token: string) {
    axios.defaults.headers.common[BEARER_TOKEN_HEADER] = `Bearer ${token}`;
  }

  static clearBearerTokenOnAxios() {
    delete axios.defaults.headers.common[BEARER_TOKEN_HEADER];
  }
}
