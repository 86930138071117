import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { OrganizationUserChip } from "./organization-user-chip.component";
import { OrganizationUserAddButton } from "./organization-user-add-button.component";
import { sorted } from "@netcero/netcero-common";

interface IOrganizationUsersComponentProps {
  values: string[];
  organizationId: string;
  onChange: (newValues: string[]) => void;
  disabled?: boolean;
  emptyMessage?: string;
  tooltipAddButton?: string;
  emptyMessageAddButton: string;
}

export const OrganizationUsersComponent: FC<IOrganizationUsersComponentProps> = ({
  values,
  organizationId,
  onChange,
  disabled,
  emptyMessage,
  tooltipAddButton,
  emptyMessageAddButton,
}) => {
  return (
    <Box display="flex" alignItems="center" flexWrap="wrap" gap={1}>
      {/* Empty Message */}
      {values.length === 0 && emptyMessage !== undefined && (
        <Typography variant="body2" color="textSecondary">
          {emptyMessage}
        </Typography>
      )}
      {/* User Chips */}
      {values.map((userId) => (
        <OrganizationUserChip
          key={userId}
          organizationId={organizationId}
          userId={userId}
          onDelete={() => onChange(values.filter((id) => id !== userId))}
          disabled={disabled}
        />
      ))}
      {/* Add Button */}
      <OrganizationUserAddButton
        organizationId={organizationId}
        tooltip={tooltipAddButton}
        disabled={disabled}
        excludeUserIds={values}
        onSelect={(user) => {
          if (user.id !== undefined) {
            onChange(sorted([...values, user.id]));
          }
        }}
        emptyMessage={emptyMessageAddButton}
      />
    </Box>
  );
};
