import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IAction } from "@netcero/netcero-core-api-client";
import { DialogCloseButton } from "../../../common/dialogs/dialog-button.components";
import { sorted } from "@netcero/netcero-common";
import { useDebounce } from "@uidotdev/usehooks";
import { MdrEsrsTopicIndicators } from "../../../minimum-disclosure-requirements-common/components/mdr-esrs-topic-indicator.component";
import { SearchTextInput } from "../../../common/components/search-text-input.component";
import { LineClampTypographyWithTooltip } from "../../../common/components/line-clamp-typography.component";

interface IActionsSelectDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  actions: IAction[];
  selectedActionsIds: string[];
  onChange: Dispatch<string[]>;
}

export const ActionsSelectDialog: FC<IActionsSelectDialogProps> = ({
  open,
  title,
  onClose,
  actions,
  selectedActionsIds,
  onChange,
}) => {
  const { t } = useTranslation("actions_select_dialog");

  const handleChangeAction = useCallback(
    (action: IAction) => {
      if (selectedActionsIds.includes(action.id)) {
        onChange(selectedActionsIds.filter((id) => id !== action.id));
      } else {
        onChange(sorted([...selectedActionsIds, action.id]));
      }
    },
    [selectedActionsIds, onChange],
  );

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!open) {
      setTimeout(() => setSearch(""), 100);
    }
  }, [open]);
  const debouncedSearch = useDebounce(search, 250);

  const filteredActions = useMemo(
    () =>
      actions.filter((action) =>
        action.general.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
      ),
    [actions, debouncedSearch],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* Search */}
        <SearchTextInput
          search={search}
          onSearchChange={setSearch}
          textFieldProps={{
            sx: { mt: 0.5, mb: 2 },
          }}
        />

        {/* Table */}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("table_header_name")}</TableCell>
              <TableCell align="right">{t("table_header_topics")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredActions.map((action) => (
              <ActionsSelectDialogTableRow
                key={action.id}
                action={action}
                selectedActionsIds={selectedActionsIds}
                onChange={() => handleChangeAction(action)}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={onClose}>{t("close", { ns: "buttons" })}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};

interface IActionsSelectDialogTableRowProps {
  action: IAction;
  selectedActionsIds: string[];
  onChange: VoidFunction;
}

const ActionsSelectDialogTableRow: FC<IActionsSelectDialogTableRowProps> = ({
  action,
  selectedActionsIds,
  onChange,
}) => {
  const isSelected = useMemo(
    () => selectedActionsIds.includes(action.id),
    [action.id, selectedActionsIds],
  );

  return (
    <TableRow>
      <TableCell onClick={onChange} sx={{ width: 74, cursor: "pointer" }}>
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell onClick={onChange} sx={{ cursor: "pointer" }}>
        <LineClampTypographyWithTooltip maxLines={3}>
          {action.general.name}
        </LineClampTypographyWithTooltip>
      </TableCell>
      <TableCell onClick={onChange} sx={{ cursor: "pointer" }}>
        <Box display="flex" flexWrap="wrap" gap={1} justifyContent="end">
          <MdrEsrsTopicIndicators topics={action.associatedTopics} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
