import { FC } from "react";
import { Box, Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { createSourcesConfigurationPageLink } from "../../../pages/configuration/sources-configuration.page";
import { OpenInNewTabIcon } from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";

interface ISourcesMenuBottomSectionProps {
  organizationId: string;
}

export const SourcesMenuBottomSection: FC<ISourcesMenuBottomSectionProps> = ({
  organizationId,
}) => {
  const { t } = useTranslation("sources");

  return (
    <Box display="flex" justifyContent="center">
      <Button
        variant="text"
        component={RouterLink}
        to={createSourcesConfigurationPageLink(organizationId).toString()}
        target="_blank"
        sx={{ gap: 1 }}
      >
        {t("manage_sources")}
        <OpenInNewTabIcon />
      </Button>
    </Box>
  );
};
