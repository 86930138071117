import { IconButton, Skeleton, Tooltip } from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { FC, useMemo, useRef, useState } from "react";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { AddIcon } from "../../common/constants/tabler-icon.constants";
import { useSortedOrganizationUsersHook } from "../hooks/sorted-organization-users.hook";
import { UserSelectMenu } from "./user-select-menu.component";

interface IOrganizationUserAddButtonProps {
  organizationId: string;
  emptyMessage: string;
  tooltip?: string;
  excludeUserIds?: string[];
  disabled?: boolean;
  onSelect: (userData: IUserRepresentation) => void;
}

export const OrganizationUserAddButton: FC<IOrganizationUserAddButtonProps> = ({
  organizationId,
  emptyMessage,
  tooltip,
  excludeUserIds,
  disabled,
  onSelect,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuAnchorElementRef = useRef<HTMLButtonElement | null>(null);

  const [sortedUsers, users] = useSortedOrganizationUsersHook(organizationId);

  const shownUsers = useMemo(
    () =>
      excludeUserIds
        ? sortedUsers?.filter((user) => !excludeUserIds?.includes(user.id!))
        : sortedUsers,
    [sortedUsers, excludeUserIds],
  );

  const handleSelectItem = (userData: IUserRepresentation) => {
    onSelect(userData);
    setShowMenu(false);
  };

  if (users.isLoading) {
    return <Skeleton variant="circular" width={24} height={24} />;
  }

  if (users.isError) {
    return <ErrorTextComponent error={users.error} />;
  }

  return (
    <>
      {/* Menu */}
      <UserSelectMenu
        show={showMenu}
        anchorEl={menuAnchorElementRef.current}
        onClose={() => setShowMenu(false)}
        users={shownUsers}
        noUsersAvailableText={emptyMessage}
        onSelect={handleSelectItem}
      />

      {/* Button */}
      <Tooltip title={tooltip} placement="right">
        <span>
          <IconButton
            ref={menuAnchorElementRef}
            onClick={() => setShowMenu(true)}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};
