export interface IDownwardsRecursiveType<T> {
  children: Array<T>;
}

export interface IUpwardsRecursiveType<T> {
  parent: T | null;
}

export interface IRecursiveStructureResult<T> {
  item: T;
  recursionLevel: number;
}

export class RecursiveUtilities {
  public static flattenRecursiveStructureDownWithLevelCreateParentMap<
    T extends IDownwardsRecursiveType<T>,
  >(
    recursiveStructure: T | T[],
    retrieveIdentifier: (item: T) => string,
    parents: Record<string, string> = {},
    result: IRecursiveStructureResult<T>[] = [],
    level: number = 0,
    parent: T | null = null,
  ): [IRecursiveStructureResult<T>[], Record<string, string>] {
    const parentIdentifier = parent !== null ? retrieveIdentifier(parent) : null;

    if (Array.isArray(recursiveStructure)) {
      for (const item of recursiveStructure) {
        result.push({ item, recursionLevel: level });

        if (parentIdentifier !== null) {
          parents[retrieveIdentifier(item)] = parentIdentifier;
        }

        RecursiveUtilities.flattenRecursiveStructureDownWithLevelCreateParentMap(
          item.children,
          retrieveIdentifier,
          parents,
          result,
          level + 1,
          item,
        );
      }
    } else {
      result.push({ item: recursiveStructure, recursionLevel: level });
      RecursiveUtilities.flattenRecursiveStructureDownWithLevelCreateParentMap(
        recursiveStructure.children,
        retrieveIdentifier,
        parents,
        result,
        level + 1,
        recursiveStructure,
      );
    }

    return [result, parents];
  }

  public static flattenRecursiveStructureDownWithLevel<T extends IDownwardsRecursiveType<T>>(
    recursiveStructure: T | T[],
  ): IRecursiveStructureResult<T>[] {
    return RecursiveUtilities.flattenRecursiveStructureDownWithLevelCreateParentMap(
      recursiveStructure,
      // retrieving the identifier is not necessary here
      () => "",
    )[0];
  }

  public static flattenRecursiveStructureDown<T extends IDownwardsRecursiveType<T>>(
    recursiveStructure: T | T[],
  ): T[] {
    return RecursiveUtilities.flattenRecursiveStructureDownWithLevel(recursiveStructure).map(
      (r) => r.item,
    );
  }

  public static flattenRecursiveStructureUpWithLevel<T extends IUpwardsRecursiveType<T>>(
    recursiveStructure: T,
    result: IRecursiveStructureResult<T>[] = [],
    level: number = 0,
  ): IRecursiveStructureResult<T>[] {
    result.push({ item: recursiveStructure, recursionLevel: level });

    // even though undefined is not in the type signature, still account for it!
    if (recursiveStructure.parent !== null && recursiveStructure.parent !== undefined) {
      RecursiveUtilities.flattenRecursiveStructureUpWithLevel(
        recursiveStructure.parent,
        result,
        level + 1,
      );
    }

    return result;
  }

  public static flattenRecursiveStructureUp<T extends IUpwardsRecursiveType<T>>(
    recursiveStructure: T,
  ): T[] {
    return RecursiveUtilities.flattenRecursiveStructureUpWithLevel(recursiveStructure).map(
      (r) => r.item,
    );
  }
}
