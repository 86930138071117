import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { OrganizationStructureDraftWrapper } from "../../modules/organization-structures/organization-structure-draft-wrapper.component";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";

interface IEvaluationSelectDataEntryObjectPageProps {
  evaluationKey: string;
}
export const RedirectEvaluationToRootDEOPage: FC<IEvaluationSelectDataEntryObjectPageProps> = ({
  evaluationKey,
}) => {
  const { organizationId, recordingPeriodId, recordingStructureId } = useParams();

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  return (
    <QueryWrapper
      query={organizationStructureQuery}
      loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
      build={(organizationStructure) => (
        <OrganizationStructureDraftWrapper
          organizationId={organizationId!}
          recordingPeriodId={recordingPeriodId!}
          organizationStructure={organizationStructure}
          build={(structure) => (
            <Navigate
              to={
                `/organizations/${organizationId}/recording-periods/${recordingPeriodId}` +
                `/evaluations/${evaluationKey}/recording-structures/${recordingStructureId}` +
                `/data-entry-objects/${structure.id}`
              }
              replace
            />
          )}
        />
      )}
    />
  );
};
