import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { UndefinedOrNull } from "@netcero/netcero-common";

export const useInputParameterRecordingStructureTemplatesQuery = (
  organizationId: string,
  recordingModelId: UndefinedOrNull<string>,
  templatesGroupId: UndefinedOrNull<string>,
) => {
  return useQuery({
    queryKey: [
      "inputParameterRecordingStructureTemplates",
      organizationId,
      recordingModelId,
      templatesGroupId,
    ],
    queryFn: () =>
      CoreApiService.InputParameterRecordingStructureTemplateApi.getAllTemplates(
        organizationId,
        recordingModelId!,
        templatesGroupId!,
      )
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!recordingModelId && !!templatesGroupId,
  });
};

export const useInputParameterRecordingStructureDetailsQuery = (
  organizationId: string,
  recordingPeriodId: string,
  recordingStructureId: UndefinedOrNull<string>,
) => {
  return useQuery({
    queryKey: [
      "inputParameterRecordingStructureDetails",
      organizationId,
      recordingPeriodId,
      recordingStructureId!,
    ],
    queryFn: () =>
      CoreApiService.InputParameterRecordingStructureApi.getInputParameterRecordingStructureDetails(
        organizationId,
        recordingPeriodId,
        recordingStructureId!,
      )
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!recordingStructureId,
  });
};
