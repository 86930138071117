import { IIntercomEntity } from "@netcero/netcero-core-api-client";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IntercomReferencesForEntity } from "@netcero/netcero-common";

export const getIntercomReferencesQueryKey = (entity: IIntercomEntity) => [
  "intercom-references",
  entity,
];

export const useIntercomReferencesForEntityQuery = (
  entity: IIntercomEntity,
): UseQueryResult<IntercomReferencesForEntity> => {
  return useQuery({
    queryKey: getIntercomReferencesQueryKey(entity),
    queryFn: async () => {
      return CoreApiService.IntercomReferencesApi.getIntercomReferences(entity)
        .then((req) => req())
        .then((r) => r.data);
    },
  });
};
