import { Box } from "@mui/material";
import { IHorizonsEnum } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { TabErrorIcon } from "../../common/components/tab-error-indiciator.component";
import { useTranslation } from "react-i18next";

interface IEditDialogHorizonTabContent {
  horizon: IHorizonsEnum;
  error: boolean;
}
export const EditDialogHorizonTabContent: FC<IEditDialogHorizonTabContent> = ({
  horizon,
  error,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  return (
    <Box display="flex" alignItems="center" gap={2} color={error ? "error.main" : undefined}>
      {/* Spacer for Error */}
      {error && <Box width={20} />}
      <Box>
        <Box fontWeight="bold">
          {t(`horizon_${horizon}_name`, {
            ns: "dma_impact_or_effect_edit_dialog_common",
          })}
        </Box>
        {t(`horizon_${horizon}_time_span`, {
          ns: "dma_impact_or_effect_edit_dialog_common",
        })}
      </Box>
      {error && <TabErrorIcon />}
    </Box>
  );
};
