import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormatUtilities } from "../../common/utilities/format.utilities";

interface IDistributionCriteriaRecordingPeriodDropdownComponentProps {
  handleSelectRecordingPeriod: (event: SelectChangeEvent<string>) => void;
  selectedRecordingPeriod: ILocalRecordingPeriod;
  recordingPeriods: ILocalRecordingPeriod[];
}

export const DistributionCriteriaRecordingPeriodDropdownComponent: FC<
  IDistributionCriteriaRecordingPeriodDropdownComponentProps
> = ({ selectedRecordingPeriod, handleSelectRecordingPeriod, recordingPeriods }) => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_component", {
    keyPrefix: "dropdowns.recording_period_dropdown",
  });
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      flexWrap={{ xs: "wrap", lg: "nowrap" }}
      gap={2}
    >
      {/* Structure Select */}
      <FormControl fullWidth>
        <InputLabel id="recording-period-select-label">
          {t("recording_period_select_label")}
        </InputLabel>
        <Select
          labelId="recording-period-select-label"
          id="recording-period-select"
          value={selectedRecordingPeriod.id}
          label={t("recording_period_select_label")}
          onChange={handleSelectRecordingPeriod}
        >
          {recordingPeriods.map((recordingPeriod) => (
            <MenuItem key={recordingPeriod.id} value={recordingPeriod.id}>
              <Box display="flex" gap={1}>
                <span>{FormatUtilities.formatDate(recordingPeriod.startDate)}</span>
                <span>-</span>
                <span>{FormatUtilities.formatDate(recordingPeriod.endDate)}</span>
                <Box>
                  <Divider orientation="vertical" />
                </Box>
                <Box display="flex" gap={0.5}>
                  <Tooltip title={recordingPeriod.description} placement="right">
                    <span>{recordingPeriod.name}</span>
                  </Tooltip>
                </Box>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
