import { IBaseManualStakeholderFeedbackData } from "@netcero/netcero-core-api-client";
import { IDMACategoryIdentificationData } from "./dma.mutations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAllManualFeedbacksForDmaCategoryQueryKey } from "../dma.queries";
import { CoreApiService } from "../../core-api/core-api.service";

interface IFeedbackIdentifier extends IDMACategoryIdentificationData {
  feedbackId: string;
}

// Create Mutation

interface ICreateManualFeedbackData extends IDMACategoryIdentificationData {
  payload: IBaseManualStakeholderFeedbackData;
}

/**
 * Query hook to create a manual feedback entry for a DMA category.
 */
export const useCreateManualFeedbackMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: ICreateManualFeedbackData) => {
      return CoreApiService.DMACategoryManualFeedbackApi.addManualFeedback(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAllManualFeedbacksForDmaCategoryQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
          variables.esrsTopicId,
          variables.dmaCategoryId,
        ),
      });
    },
  });
};

// Update Mutation

interface IUpdateManualFeedbackData extends ICreateManualFeedbackData, IFeedbackIdentifier {}

/**
 * Query hook to update a manual feedback entry for a DMA category.
 */
export const useUpdateManualFeedbackMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IUpdateManualFeedbackData) => {
      return CoreApiService.DMACategoryManualFeedbackApi.updateManualFeedback(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.feedbackId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAllManualFeedbacksForDmaCategoryQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
          variables.esrsTopicId,
          variables.dmaCategoryId,
        ),
      });
    },
  });
};

// Delete Mutation

/**
 * Query hook to delete a manual feedback entry for a DMA category.
 */
export const useDeleteManualFeedbackMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IFeedbackIdentifier) => {
      return CoreApiService.DMACategoryManualFeedbackApi.deleteManualFeedback(
        data.organizationId,
        data.recordingPeriodId,
        data.dataEntryObjectId,
        data.esrsTopicId,
        data.dmaCategoryId,
        data.feedbackId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAllManualFeedbacksForDmaCategoryQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
          variables.dataEntryObjectId,
          variables.esrsTopicId,
          variables.dmaCategoryId,
        ),
      });
    },
  });
};
