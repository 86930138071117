import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IReferenceBudget } from "@netcero/netcero-core-api-client";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { TargetPathGoalsCalculations } from "@netcero/netcero-common";

export interface IReferenceIntensityComponentProps {
  referenceBudget: IReferenceBudget;
  totalEmissions: number;
  noFormat: boolean;
  isSum: boolean;
}

export const ReferenceIntensityComponent: FC<IReferenceIntensityComponentProps> = ({
  referenceBudget,
  totalEmissions,
  noFormat,
  isSum,
}) => {
  const { t } = useTranslation("reference_intensity_component");

  const referenceIntensity = useMemo(() => {
    if (referenceBudget.referenceRevenue === undefined) {
      return "-";
    }

    return FormatUtilities.formatFixedDecimal(
      TargetPathGoalsCalculations.calculateIntensity(
        totalEmissions,
        referenceBudget.referenceRevenue,
      ),
      2,
    );
  }, [referenceBudget.referenceRevenue, totalEmissions]);

  const translated = useMemo(
    () =>
      t("content", {
        referenceIntensity,
        textSum: isSum ? "gesamte" : "",
      }),
    [isSum, referenceIntensity, t],
  );

  return noFormat ? (
    translated
  ) : (
    <Typography variant="body2" color="action.active" marginTop={-1.5}>
      {translated}
    </Typography>
  );
};
