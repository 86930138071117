import { RecursiveKeyOf } from "../common";
import { IActionData } from "@netcero/netcero-core-api-client";
import { MDRDependencies } from "../mdrs/mdrs.public-types";
import { MDRUtilities } from "../mdrs/mdrs.utilities";

export type KeysForAction = RecursiveKeyOf<
  Omit<
    IActionData,
    | "materialImpactIds"
    | "financialEffectIds"
    | "policyIds"
    | "dataEntryObjectId"
    | "recordingPeriodId"
  >
>;
type IDependenciesForActions = MDRDependencies<KeysForAction>;

const createBooleanEntry = MDRUtilities.createBooleanMDRDependencyFactory<KeysForAction>();

export const DEPENDENCIES_FOR_ACTION: IDependenciesForActions = {
  "e4.disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory":
    createBooleanEntry("e4.biodiversityOffsetsWereUsedInActionPlan"),
  "e4.financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets": createBooleanEntry(
    "e4.biodiversityOffsetsWereUsedInActionPlan",
  ),
  "e4.descriptionOfBiodiversityOffsetsExplanatory": createBooleanEntry(
    "e4.biodiversityOffsetsWereUsedInActionPlan",
  ),
  "e4.disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsExplanatory":
    createBooleanEntry(
      "e4.keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributes",
    ),
  "e5.descriptionOfContributionToCircularEconomy": createBooleanEntry(
    "e5.actionContributesToCollectiveAction",
  ),
  "e5.descriptionOfOtherStakeholdersInvolved": createBooleanEntry(
    "e5.actionContributesToCollectiveAction",
  ),
  "e5.descriptionOfOrganisationOfProject": createBooleanEntry(
    "e5.actionContributesToCollectiveAction",
  ),
};

export const ACTION_INPUTS_DEPENDED_UPON: KeysForAction[] = Array.from(
  new Set(Object.values(DEPENDENCIES_FOR_ACTION).map((d) => d.dependsUpon)),
);
