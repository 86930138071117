import { FC } from "react";
import { Alert } from "@mui/material";
import { IInputParameterDisplayInformation } from "../hooks/conditional-display-input-parameters.hook";
import { useTranslation } from "react-i18next";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface IConditionalRecordingInfoProps {
  displayInformation: IInputParameterDisplayInformation | null | undefined;
}

export const ConditionalRecordingInfo: FC<IConditionalRecordingInfoProps> = ({
  displayInformation,
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_common");
  const translateContent = useTranslateContent();

  return (
    displayInformation?.displayMode === "hidden_but_show_value" && (
      <Alert severity="info" sx={{ borderRadius: 4 }}>
        {t("dependency_mismatch_info", {
          parameterName: translateContent(
            displayInformation.dependentUponInputParameter.inputParameter.name,
          ),
        })}
      </Alert>
    )
  );
};
