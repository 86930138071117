import { ITargetPath } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BaseChart, EChartsOption } from "../../common/charts/base-chart.component";
import { EvaluationFormatUtilities } from "../../evaluation/utilities/evaluation-format.utilities";
import {
  TargetPathDiagramAxisPointerLabel,
  TargetPathDiagramUtilities,
  TargetPathDiagramValueFormatter,
} from "../../target-paths/utilities/target-path-diagram.utilities";
import { ScopeKeys } from "@netcero/netcero-common";

export interface ITargetPathDiagramComponentProps {
  targetPath: ITargetPath;
  organizationTargetPath: ITargetPath | null;
}

const StackName = "total";

export const TargetPathDiagramComponent: FC<ITargetPathDiagramComponentProps> = ({
  targetPath,
  organizationTargetPath,
}) => {
  const { t } = useTranslation("target_path_diagram_component");
  const labels = useMemo(
    () =>
      organizationTargetPath !== null
        ? TargetPathDiagramUtilities.generateLabels(targetPath, organizationTargetPath)
        : TargetPathDiagramUtilities.generateLabels(targetPath),
    [organizationTargetPath, targetPath],
  );

  const options: EChartsOption = useMemo(() => {
    // always construct series for current target path
    const seriesForTargetPath = TargetPathDiagramUtilities.constructSeriesPerScopeForTargetPath(
      targetPath,
      StackName,
      labels.offsetToReferenceYear[targetPath.id],
      {
        [ScopeKeys.Scope1]: t(`scopes.${ScopeKeys.Scope1}`),
        [ScopeKeys.Scope2]: t(`scopes.${ScopeKeys.Scope2}`),
        [ScopeKeys.Scope3]: t(`scopes.${ScopeKeys.Scope3}`),
      },
    );

    // optionally also construct series for current organization target path
    const seriesForCurrentTargetPath =
      organizationTargetPath !== null
        ? TargetPathDiagramUtilities.constructSingleSeriesForValueOfTargetPath(
            organizationTargetPath,
            labels.offsetToReferenceYear[organizationTargetPath.id],
            t("title_for_organization_path"),
          )
        : [];

    return {
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: TargetPathDiagramAxisPointerLabel,
        },
        valueFormatter: TargetPathDiagramValueFormatter,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: labels.years,
      },
      yAxis: {
        type: "value",
        name: `[${EvaluationFormatUtilities.CO2TonValueUnit}]`,
        nameLocation: "end",
        nameTextStyle: { align: "right" },
      },
      series: [...seriesForTargetPath, ...seriesForCurrentTargetPath],
    };
  }, [labels, organizationTargetPath, t, targetPath]);

  return <BaseChart options={options} height="30vh" />;
};
