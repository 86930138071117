import { FC, useMemo } from "react";
import { IGetTargetPathProgressResponse, ITargetPath } from "@netcero/netcero-core-api-client";
import {
  TargetPathDiagramAxisPointerLabel,
  TargetPathDiagramUtilities,
  TargetPathDiagramValueFormatter,
} from "../utilities/target-path-diagram.utilities";
import { ScopeKeys, TargetPathCalculations } from "@netcero/netcero-common";
import { BaseChart, EChartsOption } from "../../common/charts/base-chart.component";
import { EvaluationFormatUtilities } from "../../evaluation/utilities/evaluation-format.utilities";
import { useTranslation } from "react-i18next";
import { TargetPathValueGetterPerScopes } from "../utilities/target-path-value-getter";
import { useManageScopeLabels } from "../hooks/manage-scope-labels.hook";

interface ITargetPathProgressDiagramComponentProps {
  targetPathToCompare: ITargetPath;
  result: IGetTargetPathProgressResponse;
}

export const TargetPathProgressDiagramComponent: FC<ITargetPathProgressDiagramComponentProps> = ({
  targetPathToCompare,
  result,
}) => {
  const labels = useMemo(
    () => TargetPathDiagramUtilities.generateLabels(targetPathToCompare),
    [targetPathToCompare],
  );

  const values = useMemo(
    () => TargetPathCalculations.computeValuesPerYear(labels, result),
    [labels, result],
  );

  const { setScopeValue, activeScopes } = useManageScopeLabels();

  const { t } = useTranslation("target_path_progress_diagram_component");

  const options: EChartsOption = useMemo(() => {
    const seriesForCurrentTargetPath =
      TargetPathDiagramUtilities.constructSingleSeriesForValueOfTargetPath(
        targetPathToCompare,
        labels.offsetToReferenceYear[targetPathToCompare.id],
        t("title_for_organization_path"),
        TargetPathValueGetterPerScopes(activeScopes),
      );

    const seriesForProgress = TargetPathDiagramUtilities.constructSeriesForProgress(
      values,
      {
        [ScopeKeys.Scope1]: t(`scopes.${[ScopeKeys.Scope1]}`),
        [ScopeKeys.Scope2]: t(`scopes.${[ScopeKeys.Scope2]}`),
        [ScopeKeys.Scope3]: t(`scopes.${[ScopeKeys.Scope3]}`),
      },
      "total",
      labels.referenceYear,
    );

    return {
      legend: {},
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
          label: TargetPathDiagramAxisPointerLabel,
        },
        valueFormatter: TargetPathDiagramValueFormatter,
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: labels.years,
      },
      yAxis: {
        type: "value",
        name: `[${EvaluationFormatUtilities.CO2TonValueUnit}]`,
        nameLocation: "end",
        nameTextStyle: { align: "right" },
      },
      series: [...seriesForProgress, ...seriesForCurrentTargetPath],
    };
  }, [
    activeScopes,
    labels.offsetToReferenceYear,
    labels.referenceYear,
    labels.years,
    t,
    targetPathToCompare,
    values,
  ]);

  return <BaseChart options={options} height="30vh" onLabelToggle={setScopeValue} />;
};
