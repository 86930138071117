import { useCallback } from "react";
import { useStakeholdersQuery } from "./stakeholder.queries";

export const useResolveStakeholder = (organizationId: string) => {
  const stakeholdersQuery = useStakeholdersQuery(organizationId);

  return useCallback(
    (stakeholderId: string) => {
      return stakeholdersQuery.data?.stakeholders.find((s) => s.id === stakeholderId) ?? null;
    },
    [stakeholdersQuery.data],
  );
};
