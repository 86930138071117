import {
  AbsoluteTargetPathGoalScopes,
  RelativeTargetPathGoalScopes,
  TargetPathGoalChangeType,
} from "./target-path-goals.public-types";
import Decimal from "decimal.js-light";
import {
  IAbsoluteTargetPathGoal,
  IReferenceBudget,
  IRelativeTargetPathGoal,
  IRelativeTargetPathGoalIntensities,
  ITargetPathGoal,
  ITargetPathGoalValueChange,
  ITargetPathGoalValueChangeInPercent,
  ITargetPathGoalValueChangeToValue,
} from "@netcero/netcero-core-api-client";
import { IEmissionsForTargetGoal } from "./target-path-goals.public-types";
import { ScopeKeys } from "../emissions";
import { TargetPathGoalsUtilities } from "./target-path-goals.utilities";

export class TargetPathGoalsCalculations {
  public static calculateIntensity(emissions: number, revenue: number) {
    return emissions / revenue;
  }

  public static calculateAbsoluteChangeTypeTransition(
    value: number,
    sumOfEmissions: number,
    fromType: TargetPathGoalChangeType,
    toType: TargetPathGoalChangeType,
  ) {
    if (fromType === "inPercent" && toType === "byValue") {
      return new Decimal(sumOfEmissions).mul(value).toNumber();
    } else if (fromType === "inPercent" && toType === "toValue") {
      return new Decimal(sumOfEmissions).mul(1 - value).toNumber();
    } else if (fromType === "byValue" && toType === "inPercent") {
      return sumOfEmissions === 0 ? 0 : new Decimal(value).div(sumOfEmissions).toNumber();
    } else if (fromType === "byValue" && toType === "toValue") {
      return sumOfEmissions - value;
    } else if (fromType === "toValue" && toType === "inPercent") {
      return sumOfEmissions === 0 ? 0 : 1 - new Decimal(value).div(sumOfEmissions).toNumber();
    } else if (fromType === "toValue" && toType === "byValue") {
      return sumOfEmissions - value;
    }

    return value;
  }

  /**
   * CAREFUL: referenceRevenue has to be in the same dimension as the value!
   */
  public static calculateRelativeChangeTypeTransition(
    value: number,
    referenceRevenue: number,
    fromType: TargetPathGoalChangeType,
    toType: TargetPathGoalChangeType,
    targetYear: number,
    referenceYear: number,
  ) {
    // with values that aren't a percentage, this obviously yields unreasonably high values...
    const referenceRevenueAfterTime = TargetPathGoalsCalculations.calculateRevenueIncreaseOverTime(
      referenceRevenue,
      value,
      targetYear,
      referenceYear,
    );

    if (fromType === "inPercent" && toType === "byValue") {
      return referenceRevenueAfterTime - referenceRevenue;
    } else if (fromType === "inPercent" && toType === "toValue") {
      return referenceRevenueAfterTime;
    } else if (fromType === "byValue" && toType === "inPercent") {
      if (referenceRevenue === 0) {
        return 0;
      }

      const targetRevenue = referenceRevenue + value;
      return TargetPathGoalsCalculations.calculateIncreaseInPercent(
        targetRevenue,
        referenceRevenue,
        targetYear,
        referenceYear,
      );
    } else if (fromType === "byValue" && toType === "toValue") {
      return referenceRevenue + value;
    } else if (fromType === "toValue" && toType === "inPercent") {
      return TargetPathGoalsCalculations.calculateIncreaseInPercent(
        value,
        referenceRevenue,
        targetYear,
        referenceYear,
      );
    } else if (fromType === "toValue" && toType === "byValue") {
      return value - referenceRevenue;
    }

    return value;
  }

  public static calculateAbsoluteTargetRevenue(
    targetRevenue: ITargetPathGoalValueChange,
    referenceRevenue: number,
    targetYear: number,
    referenceYear: number,
  ) {
    return TargetPathGoalsCalculations.calculateRelativeChangeTypeTransition(
      targetRevenue.value,
      referenceRevenue,
      targetRevenue.type,
      "toValue",
      targetYear,
      referenceYear,
    );
  }

  public static convertRelativeToAbsoluteGoal(
    relativeValues: IRelativeTargetPathGoal,
    referenceRevenue: number,
    targetYear: number,
    referenceYear: number,
  ): IAbsoluteTargetPathGoal {
    // Calculate the "absolute" revenue of the target year using the reference revenue +
    const targetRevenue = TargetPathGoalsCalculations.calculateAbsoluteTargetRevenue(
      relativeValues.targetRevenue,
      referenceRevenue,
      targetYear,
      referenceYear,
    );

    return {
      type: "absolute",
      reductionScope1: this.convertIntensityToAbsoluteScopeValue(
        relativeValues.intensities,
        "intensityScope1",
        targetRevenue,
      ),
      reductionScope2: this.convertIntensityToAbsoluteScopeValue(
        relativeValues.intensities,
        "intensityScope2",
        targetRevenue,
      ),
      reductionScope3: this.convertIntensityToAbsoluteScopeValue(
        relativeValues.intensities,
        "intensityScope3",
        targetRevenue,
      ),
    };
  }

  public static convertIntensityToAbsoluteScopeValue(
    intensities: IRelativeTargetPathGoalIntensities,
    scope: RelativeTargetPathGoalScopes,
    targetRevenue: number,
  ): ITargetPathGoalValueChangeToValue {
    return {
      type: "toValue",
      value: intensities[scope] * targetRevenue,
    };
  }

  public static convertIntensityToRelativeScopeValue(
    intensities: IRelativeTargetPathGoalIntensities,
    scope: RelativeTargetPathGoalScopes,
    targetRevenue: number,
    emissionsSum: number,
  ): ITargetPathGoalValueChangeInPercent {
    // first, calculate absolute emissions for the relative value
    const absoluteEmissions = this.convertIntensityToAbsoluteScopeValue(
      intensities,
      scope,
      targetRevenue,
    );

    // then, convert absolute emissions to percental change
    const valueInPercent = this.calculateAbsoluteChangeTypeTransition(
      absoluteEmissions.value,
      emissionsSum,
      absoluteEmissions.type,
      "inPercent",
    );

    return {
      type: "inPercent",
      value: valueInPercent,
    };
  }

  public static convertGoalToEmissions(
    goal: ITargetPathGoal,
    referenceBudget: IReferenceBudget,
  ): IEmissionsForTargetGoal {
    // make sure that the target value are absolute
    const targetValues =
      goal.targetValues.type === "absolute"
        ? (JSON.parse(JSON.stringify(goal.targetValues)) as IAbsoluteTargetPathGoal)
        : this.convertRelativeToAbsoluteGoal(
            goal.targetValues,
            referenceBudget.referenceRevenue ?? 0,
            goal.targetYear,
            referenceBudget.referenceYear,
          );

    // helper function to determine the emissions for a given absolute scope
    const emissionsForScope = (scope: AbsoluteTargetPathGoalScopes) =>
      TargetPathGoalsUtilities.getEmissionsSumForAbsoluteTargetPathGoalScope(
        referenceBudget.calculatedValues.sumsPerScope,
        scope,
      );

    // helper function that retrieves the emissions for the scope and calculates the final emissions,
    // depending on whether emissions for the scope were found or not
    const getAbsoluteEmissionsForScope = (scope: AbsoluteTargetPathGoalScopes) => {
      const emissionsScope = emissionsForScope(scope);
      return emissionsScope === 0
        ? 0
        : this.calculateAbsoluteChangeTypeTransition(
            targetValues[scope].value,
            emissionsScope,
            targetValues[scope].type,
            "toValue",
          );
    };

    const result: IEmissionsForTargetGoal = {
      sum: 0,
      perScope: {
        [ScopeKeys.Scope1]: getAbsoluteEmissionsForScope("reductionScope1"),
        [ScopeKeys.Scope2]: getAbsoluteEmissionsForScope("reductionScope2"),
        [ScopeKeys.Scope3]: getAbsoluteEmissionsForScope("reductionScope3"),
      },
    };

    result.sum = Object.values(result.perScope).reduce((acc, curr) => acc + curr, 0);

    return result;
  }

  public static getTotalEmissionsChangeForGoal(
    goal: ITargetPathGoal,
    referenceBudget: IReferenceBudget,
  ) {
    const totalEmissions = this.convertGoalToEmissions(goal, referenceBudget).sum;
    return 1 - totalEmissions / referenceBudget.calculatedValues.sum;
  }

  private static calculateRevenueIncreaseOverTime(
    referenceRevenue: number,
    increaseInPercent: number,
    targetYear: number,
    referenceYear: number,
  ) {
    return referenceRevenue * (1 + increaseInPercent) ** (targetYear - referenceYear);
  }

  private static calculateIncreaseInPercent(
    targetRevenue: number,
    referenceRevenue: number,
    targetYear: number,
    referenceYear: number,
  ) {
    if (targetRevenue / referenceRevenue < 0) {
      return 0;
    }

    return (
      Math.E ** (Math.log(targetRevenue / referenceRevenue) / (targetYear - referenceYear)) - 1
    );
  }
}
