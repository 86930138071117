import { FC } from "react";
import { BaseStatusIndicatorChip } from "../../common/components/base-status-indicator-chip.component";
import { useTranslation } from "react-i18next";
import { PHASE_IN_COLORS } from "../../../theme/theme";

export const EsrsPhaseInChip: FC = () => {
  const { t } = useTranslation("phase_in");

  return (
    <BaseStatusIndicatorChip
      fontWeight={500}
      color={PHASE_IN_COLORS.color}
      backgroundColor={PHASE_IN_COLORS.backgroundColor}
      label={t("chip_label")}
    />
  );
};
