import {
  IRecordingPeriodForMerge,
  IRecordingPeriodForReferenceBudgetValidation,
} from "./reference-budget.types";
import { ReferenceBudgetValues } from "./reference-budget.public-types";
import { IRecordingPeriodData } from "@netcero/netcero-core-api-client";

export class ReferenceBudgetUtilities {
  public static getMaxYear(recordingPeriods: IRecordingPeriodForReferenceBudgetValidation[]) {
    return recordingPeriods.reduce(
      (max, period) => Math.max(max, period.endDate.getFullYear()),
      Number.NEGATIVE_INFINITY,
    );
  }

  public static getMinYear(recordingPeriods: IRecordingPeriodForReferenceBudgetValidation[]) {
    return recordingPeriods.reduce(
      (min, period) => Math.min(min, period.startDate.getFullYear()),
      Number.POSITIVE_INFINITY,
    );
  }

  public static mergeValuesIntoRecordingPeriods(
    recordingPeriods: IRecordingPeriodForMerge[],
    values: Record<string, IRecordingPeriodData>,
  ) {
    for (const rp of recordingPeriods) {
      const rpFromValues = values[rp.id];

      if (rpFromValues === undefined) {
        continue;
      }

      rp.endDate = new Date(rpFromValues.endDate);
      rp.startDate = new Date(rpFromValues.startDate);
      rp.name = rpFromValues.name;
      rp.description = rpFromValues.description;
    }
  }

  public static mapReferenceBudgetValuesToRecordingPeriods(
    values: ReferenceBudgetValues,
  ): Record<string, IRecordingPeriodData> {
    return Object.entries(values).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: {
          name: v.name,
          description: v.description,
          startDate: v.startDate,
          endDate: v.endDate,
        },
      }),
      {} as Record<string, IRecordingPeriodData>,
    );
  }
}
