import { Box, Breadcrumbs, IconButton, Menu, MenuItem, styled } from "@mui/material";
import { IDMACategoryWithChildren, IESRSTopic } from "@netcero/netcero-core-api-client";
import { FC, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "../../common/constants/tabler-icon.constants";
import { TreeUtilities } from "../../common/utilities/tree.utilities";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { useRenderESRSTopicName } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";

const BreadcrumbText = styled(Box)({
  cursor: "pointer",
  ":hover": {
    textDecoration: "underline",
  },
});

interface IDMADashboardBreadcrumbsFilterProps {
  esrsTopics: IESRSTopic[];
  viewedESRSTopic: IESRSTopic | null;
  viewedDMACategory: IDMACategoryWithChildren | null;
  onChangeFilter: (esrsTopicId: string | undefined, dmaCategoryId: string | undefined) => void;
}

export const DMADashboardBreadcrumbsFilter: FC<IDMADashboardBreadcrumbsFilterProps> = ({
  esrsTopics,
  viewedESRSTopic,
  viewedDMACategory,
  onChangeFilter,
}) => {
  const { t } = useTranslation("dma_dashboard");

  const pathToCategory = useMemo(() => {
    if (viewedDMACategory === null || !viewedESRSTopic?.recordedESRSTopic) {
      return [];
    }

    for (const category of viewedESRSTopic.recordedESRSTopic.dmaCategories) {
      const path = TreeUtilities.getPathToTreeChild(
        category,
        (c) => c.children,
        (c) => c.id === viewedDMACategory.id,
      );

      if (path) {
        return path;
      }
    }

    return [];
  }, [viewedDMACategory, viewedESRSTopic]);

  const renderName = useRenderDMACategoryName();
  const renderTopicName = useRenderESRSTopicName();

  return (
    <Breadcrumbs>
      {/* Root item */}
      <BreadcrumbText onClick={() => onChangeFilter(undefined, undefined)}>
        {t("breadcrumbs_item_total")}
      </BreadcrumbText>
      {/* ESRS Topic */}
      <DMADashboardBreadcrumb
        value={
          viewedESRSTopic
            ? {
                id: viewedESRSTopic.id,
                name: `${viewedESRSTopic.topic} - ${renderTopicName(viewedESRSTopic)}`,
              }
            : undefined
        }
        dropdownOptions={esrsTopics.map((topic) => ({
          id: topic.id,
          name: `${topic.topic} - ${renderTopicName(topic)}`,
        }))}
        onChange={(option) => onChangeFilter(option.id, undefined)}
      />
      {/* DMA Category - Lv. 1 */}
      {viewedESRSTopic &&
        viewedESRSTopic.recordedESRSTopic &&
        viewedESRSTopic.recordedESRSTopic.dmaCategories.length > 0 && (
          <DMADashboardBreadcrumb
            value={
              pathToCategory[0]
                ? { id: pathToCategory[0].id, name: renderName(pathToCategory[0]) }
                : undefined
            }
            dropdownOptions={
              viewedESRSTopic.recordedESRSTopic?.dmaCategories.map((category) => ({
                id: category.id,
                name: renderName(category),
              })) ?? []
            }
            onChange={(option) => onChangeFilter(viewedESRSTopic.id, option.id)}
          />
        )}
      {/* Loop over rest of path */}
      {pathToCategory.map((category, index) =>
        index === 0 ? null : (
          <DMADashboardBreadcrumb
            key={category.id}
            value={{ id: category.id, name: renderName(category) }}
            dropdownOptions={pathToCategory[index - 1].children.map((child) => ({
              id: child.id,
              name: renderName(child),
            }))}
            onChange={(option) => onChangeFilter(viewedESRSTopic?.id, option.id)}
          />
        ),
      )}
      {/* Select Next Child */}
      {viewedDMACategory && viewedDMACategory.children.length > 0 && (
        <DMADashboardBreadcrumb
          value={undefined}
          dropdownOptions={viewedDMACategory.children.map((category) => ({
            id: category.id,
            name: renderName(category),
          }))}
          onChange={(option) => onChangeFilter(viewedESRSTopic?.id, option.id)}
        />
      )}
    </Breadcrumbs>
  );
};

// Breadcrumbs Item

interface IDMADashboardBreadcrumbOption {
  id: string;
  name: string;
}

interface IDMADashboardBreadcrumbProps {
  value?: IDMADashboardBreadcrumbOption;
  dropdownOptions: IDMADashboardBreadcrumbOption[];
  onChange: (value: IDMADashboardBreadcrumbOption) => void;
}

const DMADashboardBreadcrumb: FC<IDMADashboardBreadcrumbProps> = ({
  value,
  dropdownOptions,
  onChange,
}) => {
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);

  const [showMenu, setShowMenu] = useState(false);

  const handleShowDropdown = () => {
    setShowMenu(true);
  };

  const handleClose = (option: IDMADashboardBreadcrumbOption | null) => {
    if (option) {
      onChange(option);
    }
    setShowMenu(false);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        {value && <BreadcrumbText onClick={() => onChange(value)}>{value.name}</BreadcrumbText>}
        {dropdownOptions.length > 0 && (
          <IconButton size="small" onClick={handleShowDropdown} ref={dropdownButtonRef}>
            <ChevronDownIcon />
          </IconButton>
        )}
      </Box>

      <Menu
        anchorEl={dropdownButtonRef.current}
        open={showMenu}
        onClose={() => handleClose(null)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        {dropdownOptions?.map((dropdownOption) => (
          <MenuItem
            key={dropdownOption.id}
            onClick={() => handleClose(dropdownOption)}
            selected={dropdownOption.id === value?.id}
            sx={{ display: "flex", gap: 1 }}
          >
            {dropdownOption.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
