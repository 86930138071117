import { FC } from "react";
import { ExpandRightIconButton } from "../../common/components/expand-right-icon-button.component";

interface IEditDialogExpandCommentsAndAuditLogPaperButtonProps {
  showSideSection: boolean;
  onToggle: VoidFunction;
}

export const EditDialogExpandCommentsAndAuditLogPaperButton: FC<
  IEditDialogExpandCommentsAndAuditLogPaperButtonProps
> = ({ showSideSection, onToggle }) => {
  return <ExpandRightIconButton expanded={showSideSection} onClick={onToggle} />;
};
