import React, { FC } from "react";
import { PoliciesUtilities } from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import {
  IMdrTopicSectionCommonProps,
  MdrTopicSection,
} from "../../minimum-disclosure-requirements-common/components/mdr-topic-section.component";
import { IPolicyDataInternal } from "../policies.types";

type IPolicyTopicComponentProps = IMdrTopicSectionCommonProps<IPolicyDataInternal>;

export const PolicyTopicComponent: FC<IPolicyTopicComponentProps> = (prop) => {
  const { t } = useTranslation("data_entry_object_policy_component");

  return (
    <MdrTopicSection
      {...prop}
      t={t}
      isInputConditional={(propertyPath) => PoliciesUtilities.isInputConditional(propertyPath)}
      isInputDependedUponByAnother={PoliciesUtilities.isInputDependedUponByAnother}
      isInputAvailable={PoliciesUtilities.isInputAvailable}
    />
  );
};
