import { FC, ReactNode, useCallback } from "react";
import { IAppSnackbarEnqueueBasicSuccessMessageData } from "../../app-snackbar/app-snackbar.interfaces";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { useUpdateSourcesForInputParameterMutation } from "../../data-entry-objects-input-parameters/data-entry-object-input-parameters.mutations";
import { useSourcesForInputParameterQuery } from "../../data-entry-objects-input-parameters/data-entry-object-input-parameter.query";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { IWithSources } from "@netcero/netcero-core-api-client";
import { UseQueryResult } from "@tanstack/react-query";

export interface IDataEntryObjectInputParameterSourcesHandlingWrapperPublicProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  inputParameterId: string;
  snackbarMessage: IAppSnackbarEnqueueBasicSuccessMessageData;
  loadingOverride?: (loadingResult: UseQueryResult | null) => ReactNode;
}

type InternalProps = IDataEntryObjectInputParameterSourcesHandlingWrapperPublicProps & {
  render: (
    sourcesResponse: IWithSources,
    handleUpdateLinkedSources: (sourceIds: string[]) => Promise<void>,
    isPending: boolean,
  ) => ReactNode;
};

export const DataEntryObjectInputParameterSourcesHandlingWrapper: FC<InternalProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  inputParameterId,
  snackbarMessage,
  render,
  loadingOverride,
}) => {
  const { wrapApiPromise } = useAppSnackbar();
  const useUpdateSourcesForInputParameterRecordingMutation =
    useUpdateSourcesForInputParameterMutation();

  const handleUpdateLinkedSources = useCallback(
    async (sourceIds: string[]) => {
      await wrapApiPromise(
        useUpdateSourcesForInputParameterRecordingMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          sourceIds: { sourceIds },
        }),
        snackbarMessage,
      );
    },
    [
      wrapApiPromise,
      useUpdateSourcesForInputParameterRecordingMutation,
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      snackbarMessage,
    ],
  );

  const sourcesQuery = useSourcesForInputParameterQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    inputParameterId,
  );

  return (
    <QueryWrapper
      query={sourcesQuery}
      loadingOverride={loadingOverride}
      build={(response) =>
        render(
          response,
          handleUpdateLinkedSources,
          useUpdateSourcesForInputParameterRecordingMutation.isPending,
        )
      }
    />
  );
};
