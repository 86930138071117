import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ICalculationFormular,
  ICalculationParameter,
  ILocalEvaluationResultError,
} from "@netcero/netcero-common";
import { isEqual } from "lodash";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface IEmissionEvaluationErrorComponentProps {
  error: ILocalEvaluationResultError;
}

const recursivelyRenderFormulasToString = (formulas: ICalculationParameter[]): string => {
  return formulas
    .map((f) => {
      if (typeof f === "string") {
        return `"${f}"`;
      } else if (typeof f === "object") {
        return `${f.operation}(${recursivelyRenderFormulasToString(f.parameters)})`;
      } else {
        return `${f}`;
      }
    })
    .join(", ");
};

export const EmissionsEvaluationErrorComponent: FC<IEmissionEvaluationErrorComponentProps> = ({
  error,
}) => {
  const { t } = useTranslation("emissions_evaluation_error_component");
  const translateContent = useTranslateContent();
  return (
    <Alert severity="error">
      <AlertTitle>{t("title")}</AlertTitle>
      {/* Content */}
      <Box display="flex" gap={2} flexDirection="column">
        {/* Error message */}
        <Box>
          <Typography variant="subtitle1">{t("subtitle_error_message")}</Typography>
          <Typography variant="body2">{t(`errorMessages.${error.errorCode}`)}</Typography>
        </Box>

        {/* Details */}
        {error.details !== undefined && (
          <>
            {/* IP + CF */}
            <Box>
              <Typography variant="subtitle1">{t("affected.subtitle")}</Typography>
              <Box>
                <Typography variant="body2">
                  <FormatTranslation
                    t={t}
                    i18nKey="affected.inputParameter"
                    values={{
                      inputParameterName: translateContent(error.details.inputParameter.name),
                      id: error.details.inputParameter.id,
                    }}
                  />
                </Typography>

                <Typography variant="body2">
                  <FormatTranslation
                    t={t}
                    i18nKey="affected.calculationFormula"
                    values={{
                      calculationFormulaName: error.details.calculationFormula.name,
                      id: error.details.calculationFormula.id,
                    }}
                  />
                </Typography>

                <Typography variant="body2">
                  <FormatTranslation
                    t={t}
                    i18nKey="affected.value"
                    values={{
                      id: error.details.calculationValue.id,
                    }}
                  />
                </Typography>
              </Box>
            </Box>

            {/* Calculation Step*/}
            <Box>
              <Typography variant="subtitle1">{t("step.subtitle")}</Typography>
              <Box>
                <Typography variant="body2">
                  {t("step.subtitle_value_key")} {error.details.calculationStep.valueKey}{" "}
                  {t("step.subtitle_index", { count: error.details.calculationStepIndex + 1 })}
                </Typography>

                {/* Recursively render formula */}
                <Paper variant="outlined" sx={{ padding: 2 }}>
                  <EvaluationErrorFormulaComponent
                    currentFormula={error.details.calculationStep.calculationFormular}
                    failedFormula={error.details.failedFormula}
                    failedFormulaParameterIndex={error.details.failedFormulaParameterIndex}
                  />
                </Paper>

                {/* Render detailed information on failed formula */}
                <Box display="flex" flexDirection="row" gap={0.5}>
                  <Typography variant="body2">Fehlgeschlagene Formel:</Typography>
                  <Typography variant="body2" color="error.main" fontWeight="bold">
                    {error.details.failedFormula.operation}(...)
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" gap={0.5}>
                  <Typography variant="body2">Ungültige Parameter:</Typography>
                  <Typography variant="body2" color="text.primary" fontWeight="bold">
                    {recursivelyRenderFormulasToString(error.details.failedFormula.parameters)}
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* Context */}
            <Box>
              <Typography variant="subtitle1">{t("context.subtitle")}</Typography>
              {Object.keys(error.details.context).length > 0 && (
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: "50%" }}>{t("context.headers.key")}</TableCell>
                        <TableCell sx={{ width: "50%" }}>{t("context.headers.value")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(error.details.context).map(([key, value], index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {key}
                          </TableCell>
                          <TableCell>{value}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </>
        )}
      </Box>
    </Alert>
  );
};

interface IEvaluationErrorFormulaComponentProps {
  currentFormula: ICalculationFormular;
  failedFormula: ICalculationFormular;
  failedFormulaParameterIndex?: number;
  displayBold?: boolean;
}

const EvaluationErrorFormulaComponent: FC<IEvaluationErrorFormulaComponentProps> = ({
  currentFormula,
  failedFormula,
  failedFormulaParameterIndex,
  displayBold,
}) => {
  const hasCurrentFormulaFailed = useMemo(
    () => isEqual(currentFormula, failedFormula),
    [currentFormula, failedFormula],
  );

  return (
    <Box display="inline-block">
      {/* Render operation*/}
      <Typography
        component="span"
        fontWeight={displayBold || hasCurrentFormulaFailed ? "bold" : undefined}
        color={hasCurrentFormulaFailed ? "error.main" : undefined}
      >
        {currentFormula.operation}(
      </Typography>

      {/* Recursively render parameters */}
      {currentFormula.parameters.map((p, index, array) => {
        const suffix = index === array.length - 1 ? "" : ", ";
        const isBold =
          hasCurrentFormulaFailed &&
          (index === failedFormulaParameterIndex || failedFormulaParameterIndex === undefined);

        if (typeof p === "object") {
          return (
            <>
              <EvaluationErrorFormulaComponent
                currentFormula={p}
                failedFormula={failedFormula}
                displayBold={isBold || displayBold}
                failedFormulaParameterIndex={failedFormulaParameterIndex}
              />
              {suffix}
            </>
          );
        }
        return (
          <Typography fontWeight={isBold || displayBold ? "bold" : undefined} component="span">
            {typeof p === "string" ? `"${p}"` : p}
            {suffix}
          </Typography>
        );
      })}

      {/* Render closing bracket*/}
      <Typography
        component="span"
        fontWeight={displayBold || hasCurrentFormulaFailed ? "bold" : undefined}
        color={hasCurrentFormulaFailed ? "error.main" : undefined}
      >
        )
      </Typography>
    </Box>
  );
};
