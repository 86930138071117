/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IIntercomEntity = {
  DisclosureRequirement: "disclosure-requirement",
  Policy: "policy",
  Action: "action",
  Target: "target",
  Impact: "impact",
  Effect: "effect",
} as const;

export type IIntercomEntity = (typeof IIntercomEntity)[keyof typeof IIntercomEntity];
