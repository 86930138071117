import { Box, Button, Drawer, Link, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ChevronLeftIcon, IconSize } from "../constants/tabler-icon.constants";
import {
  ISideBarNavigationItemTopLevel,
  NavigationItemsSideBarSection,
} from "../side-bar-sections/navigation-items-side-bar-section.component";
import { OrganizationSelectSideBarSection } from "../side-bar-sections/organization-select-side-bar-section.component";
import { SideBarUserSection } from "../side-bar-sections/side-bar-user-section.component";
import { NetCeroLogoIconWhite, NetCeroLogoTextWhite } from "./netcero-logo.component";

export const SIDE_BAR_SHRUNK_WIDTH = 54;
export const SIDE_BAR_EXPANDED_WIDTH = 272;
export const SIDE_BAR_CORNER_RADIUS = 16;
/** The duration the open/close transition takes in ms */
export const SIDE_BAR_TRANSITION_DURATION = 200;

/**
 * Configuration for the Side Bar.
 * organizationSelect: Configuration for the Organization Select Side Bar Section. If organizationId is undefined, the Organization Select Side Bar Section will not be rendered.
 */
export interface ISideBarConfiguration {
  organizationSelect?: {
    organizationId: string;
    onChange: (organizationId: string) => void;
  };
  navigationItems?: {
    activePath: string;
    items: ISideBarNavigationItemTopLevel[];
  };
}

export interface ISideBarProps {
  open: boolean;
  configuration?: ISideBarConfiguration;
  onOpen: () => void;
  onClose: () => void;
}

export const SideBar: FC<ISideBarProps> = ({ open, configuration, onOpen, onClose }) => {
  const logoLink = useMemo(() => {
    const organizationId = configuration?.organizationSelect?.organizationId ?? null;
    return `/organizations${organizationId ? `/${organizationId}` : ""}`;
  }, [configuration?.organizationSelect?.organizationId]);

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          overflowX: "unset",
          overflowY: "unset",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: SIDE_BAR_CORNER_RADIUS,
          borderBottomRightRadius: SIDE_BAR_CORNER_RADIUS,
          border: "none",
          p: 0,
        },
      }}
    >
      <Box
        width={`${
          (open ? SIDE_BAR_EXPANDED_WIDTH : SIDE_BAR_SHRUNK_WIDTH) + SIDE_BAR_CORNER_RADIUS
        }px`}
        flex={1}
        display="flex"
        flexDirection="column"
        sx={{
          transition: `width ${SIDE_BAR_TRANSITION_DURATION}ms`,
          transitionTimingFunction: "ease-in-out",
        }}
      >
        {/* Main Sidebar Content */}
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          px={open ? 3 : 1.5}
          py={1}
          sx={{
            transition: `padding ${SIDE_BAR_TRANSITION_DURATION}ms`,
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <Link
            component={RouterLink}
            to={logoLink}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 64,
              gap: 1,
            }}
          >
            <NetCeroLogoIconWhite />
            <Box
              display="flex"
              alignItems="center"
              width={open ? "112px" : "0px"}
              overflow="hidden"
              sx={{
                opacity: open ? 1 : 0,
                transition:
                  `width ${SIDE_BAR_TRANSITION_DURATION}ms, ` +
                  `opacity ${SIDE_BAR_TRANSITION_DURATION}ms`,
                transitionTimingFunction: "ease-in-out",
              }}
            >
              <NetCeroLogoTextWhite />
            </Box>
          </Link>

          {/* Side Bar Content */}

          {/* Navigation Items */}
          {configuration?.navigationItems?.items.length && (
            <Box my={2} px={0} sx={{ overflowY: "auto" }}>
              <NavigationItemsSideBarSection
                activePath={configuration.navigationItems.activePath}
                items={configuration.navigationItems.items}
                expanded={open}
              />
            </Box>
          )}

          {/* Expand/Close Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={open ? onClose : onOpen}
            sx={{
              py: 1,
              borderRadius: 2.5,
              mt: "auto",
              minWidth: "unset",
              transition: `background padding ${SIDE_BAR_TRANSITION_DURATION}ms`,
              transitionTimingFunction: "ease-in-out",
            }}
          >
            <SvgIcon
              sx={{
                transform: `rotate(${open ? 0 : 180}deg)`,
                transition: `transform ${SIDE_BAR_TRANSITION_DURATION}ms`,
                transitionTimingFunction: "ease-in-out",
              }}
            >
              <ChevronLeftIcon size={IconSize.Medium} />
            </SvgIcon>
          </Button>
        </Box>

        {/* Bottom Container (dark background) */}
        <Box
          display="flex"
          flexDirection="column"
          gap={3}
          bgcolor="secondary.900"
          sx={{
            borderTopRightRadius: SIDE_BAR_CORNER_RADIUS,
            borderBottomRightRadius: SIDE_BAR_CORNER_RADIUS,
            transition: `padding ${SIDE_BAR_TRANSITION_DURATION}ms`,
            transitionTimingFunction: "ease-in-out",
          }}
          pt={4}
          px={open ? 3 : 1.5}
          pb={1.5}
        >
          {/* Organization Select */}
          {configuration?.organizationSelect && (
            <OrganizationSelectSideBarSection
              organizationId={configuration.organizationSelect.organizationId}
              onChange={configuration.organizationSelect.onChange}
              expanded={open}
            />
          )}

          {/* User Info */}
          <SideBarUserSection expanded={open} />

          {/* Branding Text */}
          <Typography
            variant="caption"
            textAlign="center"
            width={240} // Always keep width (only hide by overflow)
            whiteSpace="nowrap"
            overflow="hidden"
            sx={{
              opacity: open ? 1 : 0,
              transition: `width ${SIDE_BAR_TRANSITION_DURATION}ms, opacity ${SIDE_BAR_TRANSITION_DURATION}ms`,
              transitionTimingFunction: "ease-in-out",
            }}
          >
            © {new Date().getFullYear()} NetCero GmbH
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
};
