export class ApiDataCleanupUtilities {
  /**
   * Trims the value and returns undefined if the value is empty
   * @param value
   */
  public static cleanUpString(value?: string | null): string | undefined {
    return value?.trim() || undefined;
  }

  /**
   * Removes empty strings and trims the values in the array
   * @param value
   */
  public static cleanUpStringArray(value: string[]): string[] {
    return value.map((item) => item.trim()).filter((item) => item);
  }
}
