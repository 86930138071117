/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IAddManualFeedback404Response } from "../models";
// @ts-ignore
import { IAddManualFeedback409Response } from "../models";
// @ts-ignore
import { IAllManualStakeholderFeedbacksResponse } from "../models";
// @ts-ignore
import { IBaseManualStakeholderFeedbackData } from "../models";
// @ts-ignore
import { IGetAllManualFeedbacks404Response } from "../models";
// @ts-ignore
import { IManualStakeholderFeedback } from "../models";
// @ts-ignore
import { IUpdateManualFeedback404Response } from "../models";
/**
 * DoubleMaterialityAssessmentManualStakeholderFeedbacksApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentManualStakeholderFeedbacksApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Adds a new manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Adds a new manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to add the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to add the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to add the manual feedback.
     * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addManualFeedback: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("addManualFeedback", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("addManualFeedback", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("addManualFeedback", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("addManualFeedback", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("addManualFeedback", "dmaCategoryId", dmaCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/manual-stakeholder-feedbacks`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseManualStakeholderFeedbackData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes a manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Deletes a manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to delete the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to delete the manual feedback.
     * @param {string} manualStakeholderFeedbackId The id of the manual feedback to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManualFeedback: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      manualStakeholderFeedbackId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteManualFeedback", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("deleteManualFeedback", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("deleteManualFeedback", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("deleteManualFeedback", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("deleteManualFeedback", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'manualStakeholderFeedbackId' is not null or undefined
      assertParamExists(
        "deleteManualFeedback",
        "manualStakeholderFeedbackId",
        manualStakeholderFeedbackId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/manual-stakeholder-feedbacks/{manualStakeholderFeedbackId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
          .replace(
            `{${"manualStakeholderFeedbackId"}}`,
            encodeURIComponent(String(manualStakeholderFeedbackId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all manual feedbacks for the specified data entry object and double materiality assessment category.
     * @summary Get all manual feedbacks for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the manual feedbacks.
     * @param {string} esrsTopicId The id of the ESRS topic for which to fetch the manual feedbacks.
     * @param {string} dmaCategoryId The id of the category for which to fetch the manual feedbacks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManualFeedbacks: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getAllManualFeedbacks", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getAllManualFeedbacks", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("getAllManualFeedbacks", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("getAllManualFeedbacks", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("getAllManualFeedbacks", "dmaCategoryId", dmaCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/manual-stakeholder-feedbacks`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates a manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Updates a manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to update the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to update the manual feedback.
     * @param {string} manualStakeholderFeedbackId The id of the manual feedback to update.
     * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManualFeedback: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      manualStakeholderFeedbackId: string,
      iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateManualFeedback", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateManualFeedback", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("updateManualFeedback", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("updateManualFeedback", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("updateManualFeedback", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'manualStakeholderFeedbackId' is not null or undefined
      assertParamExists(
        "updateManualFeedback",
        "manualStakeholderFeedbackId",
        manualStakeholderFeedbackId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/manual-stakeholder-feedbacks/{manualStakeholderFeedbackId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)))
          .replace(
            `{${"manualStakeholderFeedbackId"}}`,
            encodeURIComponent(String(manualStakeholderFeedbackId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseManualStakeholderFeedbackData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DoubleMaterialityAssessmentManualStakeholderFeedbacksApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DoubleMaterialityAssessmentManualStakeholderFeedbacksApiAxiosParamCreator(configuration);
  return {
    /**
     * Adds a new manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Adds a new manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to add the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to add the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to add the manual feedback.
     * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addManualFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManualStakeholderFeedback>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addManualFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iBaseManualStakeholderFeedbackData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Deletes a manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Deletes a manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to delete the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to delete the manual feedback.
     * @param {string} manualStakeholderFeedbackId The id of the manual feedback to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteManualFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      manualStakeholderFeedbackId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteManualFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        manualStakeholderFeedbackId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get all manual feedbacks for the specified data entry object and double materiality assessment category.
     * @summary Get all manual feedbacks for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the manual feedbacks.
     * @param {string} esrsTopicId The id of the ESRS topic for which to fetch the manual feedbacks.
     * @param {string} dmaCategoryId The id of the category for which to fetch the manual feedbacks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllManualFeedbacks(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IAllManualStakeholderFeedbacksResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllManualFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates a manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Updates a manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to update the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to update the manual feedback.
     * @param {string} manualStakeholderFeedbackId The id of the manual feedback to update.
     * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateManualFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      manualStakeholderFeedbackId: string,
      iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IManualStakeholderFeedback>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateManualFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        manualStakeholderFeedbackId,
        iBaseManualStakeholderFeedbackData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DoubleMaterialityAssessmentManualStakeholderFeedbacksApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp(configuration);
  return {
    /**
     * Adds a new manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Adds a new manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to add the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to add the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to add the manual feedback.
     * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addManualFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
      options?: any,
    ): AxiosPromise<IManualStakeholderFeedback> {
      return localVarFp
        .addManualFeedback(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iBaseManualStakeholderFeedbackData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes a manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Deletes a manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to delete the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to delete the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to delete the manual feedback.
     * @param {string} manualStakeholderFeedbackId The id of the manual feedback to delete.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteManualFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      manualStakeholderFeedbackId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteManualFeedback(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          manualStakeholderFeedbackId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all manual feedbacks for the specified data entry object and double materiality assessment category.
     * @summary Get all manual feedbacks for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the manual feedbacks.
     * @param {string} esrsTopicId The id of the ESRS topic for which to fetch the manual feedbacks.
     * @param {string} dmaCategoryId The id of the category for which to fetch the manual feedbacks.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllManualFeedbacks(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options?: any,
    ): AxiosPromise<IAllManualStakeholderFeedbacksResponse> {
      return localVarFp
        .getAllManualFeedbacks(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates a manual feedback for the specified data entry object and double materiality assessment category.
     * @summary Updates a manual feedback for the specified data entry object and double materiality assessment category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object for which to update the manual feedback.
     * @param {string} esrsTopicId The id of the ESRS topic for which to update the manual feedback.
     * @param {string} dmaCategoryId The id of the category for which to update the manual feedback.
     * @param {string} manualStakeholderFeedbackId The id of the manual feedback to update.
     * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateManualFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      manualStakeholderFeedbackId: string,
      iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
      options?: any,
    ): AxiosPromise<IManualStakeholderFeedback> {
      return localVarFp
        .updateManualFeedback(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          manualStakeholderFeedbackId,
          iBaseManualStakeholderFeedbackData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DoubleMaterialityAssessmentManualStakeholderFeedbacksApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
 */
export interface DoubleMaterialityAssessmentManualStakeholderFeedbacksApiInterface {
  /**
   * Adds a new manual feedback for the specified data entry object and double materiality assessment category.
   * @summary Adds a new manual feedback for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to add the manual feedback.
   * @param {string} esrsTopicId The id of the ESRS topic for which to add the manual feedback.
   * @param {string} dmaCategoryId The id of the category for which to add the manual feedback.
   * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApiInterface
   */
  addManualFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IManualStakeholderFeedback>;

  /**
   * Deletes a manual feedback for the specified data entry object and double materiality assessment category.
   * @summary Deletes a manual feedback for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to delete the manual feedback.
   * @param {string} esrsTopicId The id of the ESRS topic for which to delete the manual feedback.
   * @param {string} dmaCategoryId The id of the category for which to delete the manual feedback.
   * @param {string} manualStakeholderFeedbackId The id of the manual feedback to delete.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApiInterface
   */
  deleteManualFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    manualStakeholderFeedbackId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Get all manual feedbacks for the specified data entry object and double materiality assessment category.
   * @summary Get all manual feedbacks for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the manual feedbacks.
   * @param {string} esrsTopicId The id of the ESRS topic for which to fetch the manual feedbacks.
   * @param {string} dmaCategoryId The id of the category for which to fetch the manual feedbacks.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApiInterface
   */
  getAllManualFeedbacks(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IAllManualStakeholderFeedbacksResponse>;

  /**
   * Updates a manual feedback for the specified data entry object and double materiality assessment category.
   * @summary Updates a manual feedback for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to update the manual feedback.
   * @param {string} esrsTopicId The id of the ESRS topic for which to update the manual feedback.
   * @param {string} dmaCategoryId The id of the category for which to update the manual feedback.
   * @param {string} manualStakeholderFeedbackId The id of the manual feedback to update.
   * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApiInterface
   */
  updateManualFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    manualStakeholderFeedbackId: string,
    iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IManualStakeholderFeedback>;
}

/**
 * DoubleMaterialityAssessmentManualStakeholderFeedbacksApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
  extends BaseAPI
  implements DoubleMaterialityAssessmentManualStakeholderFeedbacksApiInterface
{
  /**
   * Adds a new manual feedback for the specified data entry object and double materiality assessment category.
   * @summary Adds a new manual feedback for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to add the manual feedback.
   * @param {string} esrsTopicId The id of the ESRS topic for which to add the manual feedback.
   * @param {string} dmaCategoryId The id of the category for which to add the manual feedback.
   * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
   */
  public addManualFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp(this.configuration)
      .addManualFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iBaseManualStakeholderFeedbackData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes a manual feedback for the specified data entry object and double materiality assessment category.
   * @summary Deletes a manual feedback for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to delete the manual feedback.
   * @param {string} esrsTopicId The id of the ESRS topic for which to delete the manual feedback.
   * @param {string} dmaCategoryId The id of the category for which to delete the manual feedback.
   * @param {string} manualStakeholderFeedbackId The id of the manual feedback to delete.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
   */
  public deleteManualFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    manualStakeholderFeedbackId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp(this.configuration)
      .deleteManualFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        manualStakeholderFeedbackId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all manual feedbacks for the specified data entry object and double materiality assessment category.
   * @summary Get all manual feedbacks for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to fetch the manual feedbacks.
   * @param {string} esrsTopicId The id of the ESRS topic for which to fetch the manual feedbacks.
   * @param {string} dmaCategoryId The id of the category for which to fetch the manual feedbacks.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
   */
  public getAllManualFeedbacks(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp(this.configuration)
      .getAllManualFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates a manual feedback for the specified data entry object and double materiality assessment category.
   * @summary Updates a manual feedback for the specified data entry object and double materiality assessment category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object for which to update the manual feedback.
   * @param {string} esrsTopicId The id of the ESRS topic for which to update the manual feedback.
   * @param {string} dmaCategoryId The id of the category for which to update the manual feedback.
   * @param {string} manualStakeholderFeedbackId The id of the manual feedback to update.
   * @param {IBaseManualStakeholderFeedbackData} [iBaseManualStakeholderFeedbackData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentManualStakeholderFeedbacksApi
   */
  public updateManualFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    manualStakeholderFeedbackId: string,
    iBaseManualStakeholderFeedbackData?: IBaseManualStakeholderFeedbackData,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentManualStakeholderFeedbacksApiFp(this.configuration)
      .updateManualFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        manualStakeholderFeedbackId,
        iBaseManualStakeholderFeedbackData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
