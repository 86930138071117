/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IBaseDataEntryObjectInputParameterRecordingConfiguration } from "../models";
// @ts-ignore
import { IDataEntryObjectInputParameterExclusion } from "../models";
// @ts-ignore
import { IDataEntryObjectInputParameterGapAnalysis } from "../models";
// @ts-ignore
import { IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration } from "../models";
// @ts-ignore
import { IDataEntryObjectInputParameterRecordingConfiguration } from "../models";
// @ts-ignore
import { IExcludeInputParameter409Response } from "../models";
// @ts-ignore
import { IGetDataEntryObjectInputParameterSources404Response } from "../models";
// @ts-ignore
import { IGetDataEntryObjectInputParameterSourcesResponse } from "../models";
// @ts-ignore
import { IGetDataEntryObjectInputParameters404Response } from "../models";
// @ts-ignore
import { IGetDataEntryObjectInputParametersResponse } from "../models";
// @ts-ignore
import { IIncludeInputParameter409Response } from "../models";
// @ts-ignore
import { IRejectInputParameterData409Response } from "../models";
// @ts-ignore
import { IResetInputParameterData409Response } from "../models";
// @ts-ignore
import { ISetDataEntryObjectInputParameterData400Response } from "../models";
// @ts-ignore
import { ISetDataEntryObjectInputParameterData404Response } from "../models";
// @ts-ignore
import { IUpdateDataEntryObjectInputParameterSources404Response } from "../models";
// @ts-ignore
import { IUpdateDataEntryObjectInputParameterSources409Response } from "../models";
// @ts-ignore
import { IUpdateSourcesOfDataEntryObjectInputParameterRequest } from "../models";
/**
 * DataEntryObjectInputParameterRecordingApi - axios parameter creator
 * @export
 */
export const DataEntryObjectInputParameterRecordingApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     *
     * @summary Used to end review process by moving it to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveInputParameterData: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("approveInputParameterData", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("approveInputParameterData", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("approveInputParameterData", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("approveInputParameterData", "inputParameterId", inputParameterId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/approve`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to exclude an input parameter (or DR)
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IDataEntryObjectInputParameterExclusion} iDataEntryObjectInputParameterExclusion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excludeInputParameter: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterExclusion: IDataEntryObjectInputParameterExclusion,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("excludeInputParameter", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("excludeInputParameter", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("excludeInputParameter", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("excludeInputParameter", "inputParameterId", inputParameterId);
      // verify required parameter 'iDataEntryObjectInputParameterExclusion' is not null or undefined
      assertParamExists(
        "excludeInputParameter",
        "iDataEntryObjectInputParameterExclusion",
        iDataEntryObjectInputParameterExclusion,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/exclude`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDataEntryObjectInputParameterExclusion,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
     * @summary Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataEntryObjectInputParameterSources: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "getDataEntryObjectInputParameterSources",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getDataEntryObjectInputParameterSources",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "getDataEntryObjectInputParameterSources",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "getDataEntryObjectInputParameterSources",
        "inputParameterId",
        inputParameterId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/sources`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns the input parameters that are set to be recorded for a data entry object. This may be missing some input parameters (if they have never been recorded for the given data entry object) and may also include InputParameters which are currently not being recorded.
     * @summary Get the input parameters that are set to be recorded for a data entry object.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} [inputParameterRecordingStructureId] Only Parameters that are part of one of the given input parameter recording structures will be returned. Can be set multiple times.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataEntryObjectInputParameters: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterRecordingStructureId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getDataEntryObjectInputParameters", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getDataEntryObjectInputParameters",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "getDataEntryObjectInputParameters",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (inputParameterRecordingStructureId !== undefined) {
        localVarQueryParameter["inputParameterRecordingStructureId"] =
          inputParameterRecordingStructureId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to include an input parameter (or DR) if it has previously been excluded
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    includeInputParameter: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("includeInputParameter", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("includeInputParameter", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("includeInputParameter", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("includeInputParameter", "inputParameterId", inputParameterId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/include`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to end review process by moving it back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectInputParameterData: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("rejectInputParameterData", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("rejectInputParameterData", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("rejectInputParameterData", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("rejectInputParameterData", "inputParameterId", inputParameterId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/reject`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to reset the status for a DEOIP back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetInputParameterData: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("resetInputParameterData", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("resetInputParameterData", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("resetInputParameterData", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("resetInputParameterData", "inputParameterId", inputParameterId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/reset`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates the attribution factors for a Data Entry Object Input Parameter and all its child Data Entry Objects.
     * @summary Sets the attribution factor for a Data Entry Object Input Parameter and all its child Data Entry Objects.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
     * @param {string} inputParameterId The id of the Input Parameter for recording.
     * @param {IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration} [iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDataEntryObjectInputParameterAttributionFactors: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration?: IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterAttributionFactors",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterAttributionFactors",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterAttributionFactors",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterAttributionFactors",
        "inputParameterId",
        inputParameterId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/attribution-factors`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * <b>DEVNOTE: This Endpoint will probably stay a PUT Endpoint since data about how a value should be recorded will probably be saved as json and only be overwritten.</b>  Sets whether or not an input parameter is recorded for a data entry object.
     * @summary Updates the information within a Data Entry Object Input Parameter, namely the `isActive` and `attributionFactor`/`attributionReason` properties.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
     * @param {string} inputParameterId The id of the Input Parameter for recording.
     * @param {IBaseDataEntryObjectInputParameterRecordingConfiguration} [iBaseDataEntryObjectInputParameterRecordingConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDataEntryObjectInputParameterData: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iBaseDataEntryObjectInputParameterRecordingConfiguration?: IBaseDataEntryObjectInputParameterRecordingConfiguration,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("setDataEntryObjectInputParameterData", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterData",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterData",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "setDataEntryObjectInputParameterData",
        "inputParameterId",
        inputParameterId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseDataEntryObjectInputParameterRecordingConfiguration,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to trigger the review process for a DEOIP
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitInputParameterData: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("submitInputParameterData", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("submitInputParameterData", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("submitInputParameterData", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("submitInputParameterData", "inputParameterId", inputParameterId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/submit`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
     * @summary Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IUpdateSourcesOfDataEntryObjectInputParameterRequest} iUpdateSourcesOfDataEntryObjectInputParameterRequest Used to update sources of an input parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObjectInputParameterSources: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iUpdateSourcesOfDataEntryObjectInputParameterRequest: IUpdateSourcesOfDataEntryObjectInputParameterRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterSources",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterSources",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterSources",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterSources",
        "inputParameterId",
        inputParameterId,
      );
      // verify required parameter 'iUpdateSourcesOfDataEntryObjectInputParameterRequest' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterSources",
        "iUpdateSourcesOfDataEntryObjectInputParameterRequest",
        iUpdateSourcesOfDataEntryObjectInputParameterRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/sources`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iUpdateSourcesOfDataEntryObjectInputParameterRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to update the gap-analysis specific values of an DEOIP
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IDataEntryObjectInputParameterGapAnalysis} iDataEntryObjectInputParameterGapAnalysis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGapAnalysis: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterGapAnalysis: IDataEntryObjectInputParameterGapAnalysis,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateGapAnalysis", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("updateGapAnalysis", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("updateGapAnalysis", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists("updateGapAnalysis", "inputParameterId", inputParameterId);
      // verify required parameter 'iDataEntryObjectInputParameterGapAnalysis' is not null or undefined
      assertParamExists(
        "updateGapAnalysis",
        "iDataEntryObjectInputParameterGapAnalysis",
        iDataEntryObjectInputParameterGapAnalysis,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/gap-analysis`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDataEntryObjectInputParameterGapAnalysis,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataEntryObjectInputParameterRecordingApi - functional programming interface
 * @export
 */
export const DataEntryObjectInputParameterRecordingApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DataEntryObjectInputParameterRecordingApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Used to end review process by moving it to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async approveInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.approveInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to exclude an input parameter (or DR)
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IDataEntryObjectInputParameterExclusion} iDataEntryObjectInputParameterExclusion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async excludeInputParameter(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterExclusion: IDataEntryObjectInputParameterExclusion,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.excludeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iDataEntryObjectInputParameterExclusion,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
     * @summary Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataEntryObjectInputParameterSources(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetDataEntryObjectInputParameterSourcesResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDataEntryObjectInputParameterSources(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns the input parameters that are set to be recorded for a data entry object. This may be missing some input parameters (if they have never been recorded for the given data entry object) and may also include InputParameters which are currently not being recorded.
     * @summary Get the input parameters that are set to be recorded for a data entry object.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} [inputParameterRecordingStructureId] Only Parameters that are part of one of the given input parameter recording structures will be returned. Can be set multiple times.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDataEntryObjectInputParameters(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterRecordingStructureId?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetDataEntryObjectInputParametersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDataEntryObjectInputParameters(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterRecordingStructureId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to include an input parameter (or DR) if it has previously been excluded
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async includeInputParameter(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.includeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to end review process by moving it back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async rejectInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.rejectInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to reset the status for a DEOIP back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates the attribution factors for a Data Entry Object Input Parameter and all its child Data Entry Objects.
     * @summary Sets the attribution factor for a Data Entry Object Input Parameter and all its child Data Entry Objects.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
     * @param {string} inputParameterId The id of the Input Parameter for recording.
     * @param {IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration} [iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setDataEntryObjectInputParameterAttributionFactors(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration?: IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setDataEntryObjectInputParameterAttributionFactors(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * <b>DEVNOTE: This Endpoint will probably stay a PUT Endpoint since data about how a value should be recorded will probably be saved as json and only be overwritten.</b>  Sets whether or not an input parameter is recorded for a data entry object.
     * @summary Updates the information within a Data Entry Object Input Parameter, namely the `isActive` and `attributionFactor`/`attributionReason` properties.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
     * @param {string} inputParameterId The id of the Input Parameter for recording.
     * @param {IBaseDataEntryObjectInputParameterRecordingConfiguration} [iBaseDataEntryObjectInputParameterRecordingConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setDataEntryObjectInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iBaseDataEntryObjectInputParameterRecordingConfiguration?: IBaseDataEntryObjectInputParameterRecordingConfiguration,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IDataEntryObjectInputParameterRecordingConfiguration>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.setDataEntryObjectInputParameterData(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iBaseDataEntryObjectInputParameterRecordingConfiguration,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to trigger the review process for a DEOIP
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
     * @summary Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IUpdateSourcesOfDataEntryObjectInputParameterRequest} iUpdateSourcesOfDataEntryObjectInputParameterRequest Used to update sources of an input parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataEntryObjectInputParameterSources(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iUpdateSourcesOfDataEntryObjectInputParameterRequest: IUpdateSourcesOfDataEntryObjectInputParameterRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDataEntryObjectInputParameterSources(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iUpdateSourcesOfDataEntryObjectInputParameterRequest,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to update the gap-analysis specific values of an DEOIP
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IDataEntryObjectInputParameterGapAnalysis} iDataEntryObjectInputParameterGapAnalysis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateGapAnalysis(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterGapAnalysis: IDataEntryObjectInputParameterGapAnalysis,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateGapAnalysis(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iDataEntryObjectInputParameterGapAnalysis,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DataEntryObjectInputParameterRecordingApi - factory interface
 * @export
 */
export const DataEntryObjectInputParameterRecordingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataEntryObjectInputParameterRecordingApiFp(configuration);
  return {
    /**
     *
     * @summary Used to end review process by moving it to \"done\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    approveInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .approveInputParameterData(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to exclude an input parameter (or DR)
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IDataEntryObjectInputParameterExclusion} iDataEntryObjectInputParameterExclusion
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    excludeInputParameter(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterExclusion: IDataEntryObjectInputParameterExclusion,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .excludeInputParameter(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iDataEntryObjectInputParameterExclusion,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
     * @summary Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataEntryObjectInputParameterSources(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: any,
    ): AxiosPromise<IGetDataEntryObjectInputParameterSourcesResponse> {
      return localVarFp
        .getDataEntryObjectInputParameterSources(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns the input parameters that are set to be recorded for a data entry object. This may be missing some input parameters (if they have never been recorded for the given data entry object) and may also include InputParameters which are currently not being recorded.
     * @summary Get the input parameters that are set to be recorded for a data entry object.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} [inputParameterRecordingStructureId] Only Parameters that are part of one of the given input parameter recording structures will be returned. Can be set multiple times.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDataEntryObjectInputParameters(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterRecordingStructureId?: string,
      options?: any,
    ): AxiosPromise<IGetDataEntryObjectInputParametersResponse> {
      return localVarFp
        .getDataEntryObjectInputParameters(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterRecordingStructureId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to include an input parameter (or DR) if it has previously been excluded
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    includeInputParameter(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .includeInputParameter(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to end review process by moving it back to \"in progress\"
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    rejectInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .rejectInputParameterData(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to reset the status for a DEOIP back to in-progress
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .resetInputParameterData(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates the attribution factors for a Data Entry Object Input Parameter and all its child Data Entry Objects.
     * @summary Sets the attribution factor for a Data Entry Object Input Parameter and all its child Data Entry Objects.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
     * @param {string} inputParameterId The id of the Input Parameter for recording.
     * @param {IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration} [iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDataEntryObjectInputParameterAttributionFactors(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration?: IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .setDataEntryObjectInputParameterAttributionFactors(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * <b>DEVNOTE: This Endpoint will probably stay a PUT Endpoint since data about how a value should be recorded will probably be saved as json and only be overwritten.</b>  Sets whether or not an input parameter is recorded for a data entry object.
     * @summary Updates the information within a Data Entry Object Input Parameter, namely the `isActive` and `attributionFactor`/`attributionReason` properties.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
     * @param {string} inputParameterId The id of the Input Parameter for recording.
     * @param {IBaseDataEntryObjectInputParameterRecordingConfiguration} [iBaseDataEntryObjectInputParameterRecordingConfiguration]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setDataEntryObjectInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iBaseDataEntryObjectInputParameterRecordingConfiguration?: IBaseDataEntryObjectInputParameterRecordingConfiguration,
      options?: any,
    ): AxiosPromise<IDataEntryObjectInputParameterRecordingConfiguration> {
      return localVarFp
        .setDataEntryObjectInputParameterData(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iBaseDataEntryObjectInputParameterRecordingConfiguration,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to trigger the review process for a DEOIP
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitInputParameterData(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .submitInputParameterData(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
     * @summary Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IUpdateSourcesOfDataEntryObjectInputParameterRequest} iUpdateSourcesOfDataEntryObjectInputParameterRequest Used to update sources of an input parameter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObjectInputParameterSources(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iUpdateSourcesOfDataEntryObjectInputParameterRequest: IUpdateSourcesOfDataEntryObjectInputParameterRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateDataEntryObjectInputParameterSources(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iUpdateSourcesOfDataEntryObjectInputParameterRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to update the gap-analysis specific values of an DEOIP
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} inputParameterId The id of the input parameter to use.
     * @param {IDataEntryObjectInputParameterGapAnalysis} iDataEntryObjectInputParameterGapAnalysis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateGapAnalysis(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterGapAnalysis: IDataEntryObjectInputParameterGapAnalysis,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .updateGapAnalysis(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iDataEntryObjectInputParameterGapAnalysis,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DataEntryObjectInputParameterRecordingApi - interface
 * @export
 * @interface DataEntryObjectInputParameterRecordingApi
 */
export interface DataEntryObjectInputParameterRecordingApiInterface {
  /**
   *
   * @summary Used to end review process by moving it to \"done\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  approveInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Used to exclude an input parameter (or DR)
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {IDataEntryObjectInputParameterExclusion} iDataEntryObjectInputParameterExclusion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  excludeInputParameter(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterExclusion: IDataEntryObjectInputParameterExclusion,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
   * @summary Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  getDataEntryObjectInputParameterSources(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetDataEntryObjectInputParameterSourcesResponse>;

  /**
   * Returns the input parameters that are set to be recorded for a data entry object. This may be missing some input parameters (if they have never been recorded for the given data entry object) and may also include InputParameters which are currently not being recorded.
   * @summary Get the input parameters that are set to be recorded for a data entry object.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} [inputParameterRecordingStructureId] Only Parameters that are part of one of the given input parameter recording structures will be returned. Can be set multiple times.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  getDataEntryObjectInputParameters(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterRecordingStructureId?: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetDataEntryObjectInputParametersResponse>;

  /**
   *
   * @summary Used to include an input parameter (or DR) if it has previously been excluded
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  includeInputParameter(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Used to end review process by moving it back to \"in progress\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  rejectInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Used to reset the status for a DEOIP back to in-progress
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  resetInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Updates the attribution factors for a Data Entry Object Input Parameter and all its child Data Entry Objects.
   * @summary Sets the attribution factor for a Data Entry Object Input Parameter and all its child Data Entry Objects.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
   * @param {string} inputParameterId The id of the Input Parameter for recording.
   * @param {IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration} [iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  setDataEntryObjectInputParameterAttributionFactors(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration?: IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * <b>DEVNOTE: This Endpoint will probably stay a PUT Endpoint since data about how a value should be recorded will probably be saved as json and only be overwritten.</b>  Sets whether or not an input parameter is recorded for a data entry object.
   * @summary Updates the information within a Data Entry Object Input Parameter, namely the `isActive` and `attributionFactor`/`attributionReason` properties.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
   * @param {string} inputParameterId The id of the Input Parameter for recording.
   * @param {IBaseDataEntryObjectInputParameterRecordingConfiguration} [iBaseDataEntryObjectInputParameterRecordingConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  setDataEntryObjectInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iBaseDataEntryObjectInputParameterRecordingConfiguration?: IBaseDataEntryObjectInputParameterRecordingConfiguration,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDataEntryObjectInputParameterRecordingConfiguration>;

  /**
   *
   * @summary Used to trigger the review process for a DEOIP
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  submitInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
   * @summary Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {IUpdateSourcesOfDataEntryObjectInputParameterRequest} iUpdateSourcesOfDataEntryObjectInputParameterRequest Used to update sources of an input parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  updateDataEntryObjectInputParameterSources(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iUpdateSourcesOfDataEntryObjectInputParameterRequest: IUpdateSourcesOfDataEntryObjectInputParameterRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Used to update the gap-analysis specific values of an DEOIP
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {IDataEntryObjectInputParameterGapAnalysis} iDataEntryObjectInputParameterGapAnalysis
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApiInterface
   */
  updateGapAnalysis(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterGapAnalysis: IDataEntryObjectInputParameterGapAnalysis,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * DataEntryObjectInputParameterRecordingApi - object-oriented interface
 * @export
 * @class DataEntryObjectInputParameterRecordingApi
 * @extends {BaseAPI}
 */
export class DataEntryObjectInputParameterRecordingApi
  extends BaseAPI
  implements DataEntryObjectInputParameterRecordingApiInterface
{
  /**
   *
   * @summary Used to end review process by moving it to \"done\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public approveInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .approveInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to exclude an input parameter (or DR)
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {IDataEntryObjectInputParameterExclusion} iDataEntryObjectInputParameterExclusion
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public excludeInputParameter(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterExclusion: IDataEntryObjectInputParameterExclusion,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .excludeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iDataEntryObjectInputParameterExclusion,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
   * @summary Used to retrieve the linked sources of a data entry object input parameter. Returns a list of sourceIds.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public getDataEntryObjectInputParameterSources(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .getDataEntryObjectInputParameterSources(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns the input parameters that are set to be recorded for a data entry object. This may be missing some input parameters (if they have never been recorded for the given data entry object) and may also include InputParameters which are currently not being recorded.
   * @summary Get the input parameters that are set to be recorded for a data entry object.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} [inputParameterRecordingStructureId] Only Parameters that are part of one of the given input parameter recording structures will be returned. Can be set multiple times.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public getDataEntryObjectInputParameters(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterRecordingStructureId?: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .getDataEntryObjectInputParameters(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterRecordingStructureId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to include an input parameter (or DR) if it has previously been excluded
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public includeInputParameter(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .includeInputParameter(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to end review process by moving it back to \"in progress\"
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public rejectInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .rejectInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to reset the status for a DEOIP back to in-progress
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public resetInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .resetInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates the attribution factors for a Data Entry Object Input Parameter and all its child Data Entry Objects.
   * @summary Sets the attribution factor for a Data Entry Object Input Parameter and all its child Data Entry Objects.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
   * @param {string} inputParameterId The id of the Input Parameter for recording.
   * @param {IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration} [iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public setDataEntryObjectInputParameterAttributionFactors(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration?: IDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .setDataEntryObjectInputParameterAttributionFactors(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iDataEntryObjectInputParameterRecordingAttributionFactorConfiguration,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * <b>DEVNOTE: This Endpoint will probably stay a PUT Endpoint since data about how a value should be recorded will probably be saved as json and only be overwritten.</b>  Sets whether or not an input parameter is recorded for a data entry object.
   * @summary Updates the information within a Data Entry Object Input Parameter, namely the `isActive` and `attributionFactor`/`attributionReason` properties.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the configuration is for.
   * @param {string} inputParameterId The id of the Input Parameter for recording.
   * @param {IBaseDataEntryObjectInputParameterRecordingConfiguration} [iBaseDataEntryObjectInputParameterRecordingConfiguration]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public setDataEntryObjectInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iBaseDataEntryObjectInputParameterRecordingConfiguration?: IBaseDataEntryObjectInputParameterRecordingConfiguration,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .setDataEntryObjectInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iBaseDataEntryObjectInputParameterRecordingConfiguration,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to trigger the review process for a DEOIP
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public submitInputParameterData(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .submitInputParameterData(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
   * @summary Used to link sources to a data entry object input parameter. This endpoint updates (sets) the sources of the input parameter to the given `sourceIds`. If not specified in `sourceIds`, already linked sources will be unlinked.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {IUpdateSourcesOfDataEntryObjectInputParameterRequest} iUpdateSourcesOfDataEntryObjectInputParameterRequest Used to update sources of an input parameter
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public updateDataEntryObjectInputParameterSources(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iUpdateSourcesOfDataEntryObjectInputParameterRequest: IUpdateSourcesOfDataEntryObjectInputParameterRequest,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .updateDataEntryObjectInputParameterSources(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iUpdateSourcesOfDataEntryObjectInputParameterRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to update the gap-analysis specific values of an DEOIP
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} inputParameterId The id of the input parameter to use.
   * @param {IDataEntryObjectInputParameterGapAnalysis} iDataEntryObjectInputParameterGapAnalysis
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterRecordingApi
   */
  public updateGapAnalysis(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterGapAnalysis: IDataEntryObjectInputParameterGapAnalysis,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterRecordingApiFp(this.configuration)
      .updateGapAnalysis(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iDataEntryObjectInputParameterGapAnalysis,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
