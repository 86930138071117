import {
  IActionDataE1,
  IActionDataE1And4,
  IActionDataE2,
  IActionDataE3,
  IActionDataE4,
  IActionDataE5,
  IActionDataGeneral,
  IActionDataS1,
  IActionDataS2,
  IActionDataS3,
  IActionDataS4,
  IAdaptationSolutionTypesEnum,
  IDecarbonisationLeverTypeEnum,
  IKeyActionCoverageEnum,
  ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum,
  ILayerInMitigationHierarchyForPollutionEnum,
  ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum,
  IStatusOfKeyActionEnum,
  ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum,
} from "@netcero/netcero-core-api-client";
import { IMDRInput } from "../minimum-disclosure-requirements-common/mdr-input.types";
import { ISpecialTopicSectionInputIdentifier } from "../minimum-disclosure-requirements-common/components/mdr-topic-section.component";
import { MdrUnitGroup } from "@netcero/netcero-common";
import { MdrInputUtilities } from "../minimum-disclosure-requirements-common/mdr-input.utilities";

type GENERAL_KEY = keyof IActionDataGeneral;
export const GENERAL_ORDER: (GENERAL_KEY | ISpecialTopicSectionInputIdentifier)[] = [
  "name",
  "iros",
  "policies",
  "disclosureOfKeyActionExplanatory",
  "statusOfKeyAction",
  "timeHorizonsUnderWhichKeyActionIsToBeCompleted",
  "disclosureOfExpectedOutcomesOfKeyActionAndHowItsImplementationContributesToAchievementOfPolicyObjectivesAndTargetsExplanatory",
  "descriptionOfScopeOfKeyActionCoverageInTermsOfActivitiesUpstreamAndOrDownstreamValueChainGeographiesAndAffectedStakeholderGroupsExplanatory",
  "keyActionCoverage",
  "keyActionIsTakenToEitherProvideForCooperateInOrInSupportProvisionOfRemedyForThoseHarmedByActualMaterialImpacts",
  "disclosureOfQuantitativeAndQualitativeInformationRegardingProgressOfActionsOrActionPlansDisclosedInPriorPeriodsExplanatory",
  "descriptionOfTypeOfCurrentAndFutureFinancialAndOtherResourcesAllocatedToActionPlanExplanatory",
  "abilityToImplementActionOrActionPlanDependsOnSpecificPreconditions",
  "explanationOfHowCurrentFinancialResourcesRelateToMostRelevantAmountsPresentedInFinancialStatementsExplanatory",
  "financialResourcesAllocatedToActionPlanOpEx",
  "financialResourcesAllocatedToActionPlanCapEx",
];
export const GENERAL: { [key in GENERAL_KEY]: IMDRInput } = {
  name: {
    type: "text",
    initialValue: "",
    required: true,
    multiline: false,
  },
  disclosureOfKeyActionExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  statusOfKeyAction: {
    type: "enum",
    mode: "single",
    initialValue: null,
    values: Object.values(IStatusOfKeyActionEnum),
  },
  timeHorizonsUnderWhichKeyActionIsToBeCompleted: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum),
  },
  disclosureOfExpectedOutcomesOfKeyActionAndHowItsImplementationContributesToAchievementOfPolicyObjectivesAndTargetsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfScopeOfKeyActionCoverageInTermsOfActivitiesUpstreamAndOrDownstreamValueChainGeographiesAndAffectedStakeholderGroupsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  keyActionCoverage: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IKeyActionCoverageEnum),
  },
  keyActionIsTakenToEitherProvideForCooperateInOrInSupportProvisionOfRemedyForThoseHarmedByActualMaterialImpacts:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfQuantitativeAndQualitativeInformationRegardingProgressOfActionsOrActionPlansDisclosedInPriorPeriodsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfTypeOfCurrentAndFutureFinancialAndOtherResourcesAllocatedToActionPlanExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  abilityToImplementActionOrActionPlanDependsOnSpecificPreconditions: {
    type: "boolean",
    initialValue: false,
  },
  explanationOfHowCurrentFinancialResourcesRelateToMostRelevantAmountsPresentedInFinancialStatementsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  financialResourcesAllocatedToActionPlanOpEx: {
    type: "currency",
    initialValue: null,
  },
  financialResourcesAllocatedToActionPlanCapEx: {
    type: "currency",
    initialValue: null,
  },
};

export type E1_KEY = keyof IActionDataE1;
export const E1_ORDER: E1_KEY[] = [
  "addressedMatters",
  "decarbonisationLeverTypes",
  "adaptationSolutionTypes",
  "achievedGHGEmissionReductions",
  "expectedGHGEmissionReductions",
  "explanationOfExtentToWhichAbilityToImplementActionDependsOnAvailabilityAndAllocationOfResourcesExplanatory",
  "explanationOfAnyPotentialDifferencesBetweenSignificantOpExAndCapExDisclosedUnderESRSE1AndKeyPerformanceIndicatorsDisclosedUnderCommissionDelegatedRegulationEU2021NO2178TextBlockExplanatory",
];
export const E1: { [key in keyof IActionDataE1]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e1,
  decarbonisationLeverTypes: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IDecarbonisationLeverTypeEnum),
  },
  adaptationSolutionTypes: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IAdaptationSolutionTypesEnum),
  },
  achievedGHGEmissionReductions: {
    type: "number-with-unit",
    unitGroup: MdrUnitGroup.GHG_EMISSIONS,
    initialValue: null,
  },
  expectedGHGEmissionReductions: {
    type: "number-with-unit",
    unitGroup: MdrUnitGroup.GHG_EMISSIONS,
    initialValue: null,
  },
  explanationOfExtentToWhichAbilityToImplementActionDependsOnAvailabilityAndAllocationOfResourcesExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfAnyPotentialDifferencesBetweenSignificantOpExAndCapExDisclosedUnderESRSE1AndKeyPerformanceIndicatorsDisclosedUnderCommissionDelegatedRegulationEU2021NO2178TextBlockExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E2_KEY = keyof IActionDataE2;
export const E2_ORDER: E2_KEY[] = [
  "addressedMatters",
  "actionPlanHasBeenImplementedAtSiteLevel",
  "layersInMitigationHierarchyToWhichActionCanBeAllocatedPollution",
];
export const E2: { [key in keyof IActionDataE2]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e2,
  actionPlanHasBeenImplementedAtSiteLevel: {
    type: "boolean",
    initialValue: false,
  },
  layersInMitigationHierarchyToWhichActionCanBeAllocatedPollution: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForPollutionEnum),
  },
};

export type E3_KEY = keyof IActionDataE3;
export const E3_ORDER: E3_KEY[] = [
  "addressedMatters",
  "layersInMitigationHierarchyToWhichActionCanBeAllocatedWaterAndMarineResources",
  "actionRelatesToAreasAtWaterRisk",
  "actionRelatesToAreasOfHighwaterStress",
  "informationAboutSpecificCollectiveActionExplanatory",
];
export const E3: { [key in keyof IActionDataE3]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e3,
  layersInMitigationHierarchyToWhichActionCanBeAllocatedWaterAndMarineResources: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum),
  },
  actionRelatesToAreasAtWaterRisk: {
    type: "boolean",
    initialValue: false,
  },
  actionRelatesToAreasOfHighwaterStress: {
    type: "boolean",
    initialValue: false,
  },
  informationAboutSpecificCollectiveActionExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
};

export type E4_KEY = keyof IActionDataE4;
export const E4_ORDER: E4_KEY[] = [
  "addressedMatters",
  "layersInMitigationHierarchyToWhichActionCanBeAllocatedBiodiversityAndEcosystems",
  "disclosureOfHowMitigationHierarchyHasBeenAppliedWithRegardToActionsExplanatory",
  "biodiversityOffsetsWereUsedInActionPlan",
  "disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory",
  "financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets",
  "descriptionOfBiodiversityOffsetsExplanatory",
  "localAndIndigenousKnowledgeAndOrNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction",
  "disclosureOfListOfKeyStakeholdersInvolvedInKeyActionAndHowTheyAreInvolvedKeyStakeholdersNegativelyOrPositivelyImpactedByKeyActionAndHowTheyAreImpactedExplanatory",
  "explanationOfNeedForAppropriateConsultationsAndNeedToRespectDecisionsOfAffectedCommunitiesExplanatory",
  "descriptionOfWhetherKeyActionMayInduceSignificantNegativeSustainabilityImpactsExplanatory",
  "keyActionOccurrenceBiodiversityAndEcosystems",
  "keyActionPlanIsCarriedOutOnlyByUndertakingUsingItsResources",
  "keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributes",
  "disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsExplanatory",
  "descriptionOfHowKeyActionContributesToSystemwideChangeExplanatory",
];
export const E4: { [key in keyof IActionDataE4]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e4,
  layersInMitigationHierarchyToWhichActionCanBeAllocatedBiodiversityAndEcosystems: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum),
  },
  disclosureOfHowMitigationHierarchyHasBeenAppliedWithRegardToActionsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  biodiversityOffsetsWereUsedInActionPlan: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets: {
    type: "currency",
    initialValue: null,
  },
  descriptionOfBiodiversityOffsetsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
    isLastChild: true,
  },
  localAndIndigenousKnowledgeAndOrNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfListOfKeyStakeholdersInvolvedInKeyActionAndHowTheyAreInvolvedKeyStakeholdersNegativelyOrPositivelyImpactedByKeyActionAndHowTheyAreImpactedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfNeedForAppropriateConsultationsAndNeedToRespectDecisionsOfAffectedCommunitiesExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfWhetherKeyActionMayInduceSignificantNegativeSustainabilityImpactsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  keyActionOccurrenceBiodiversityAndEcosystems: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  keyActionPlanIsCarriedOutOnlyByUndertakingUsingItsResources: {
    type: "boolean",
    initialValue: false,
  },
  keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributes: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
    isLastChild: true,
  },
  descriptionOfHowKeyActionContributesToSystemwideChangeExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
};

export type E5_KEY = keyof IActionDataE5;
export const E5_ORDER: E5_KEY[] = [
  "addressedMatters",
  "actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndOrWater",
  "actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates",
  "actionCoversApplicationOfCircularDesignAndOrHigherRatesOfEitherReuseRepairRefurbishingRemanufactureRepurposingOrRecycling",
  "actionCoversApplicationOfCircularBusinessPractices",
  "actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain",
  "actionCoversOptimisationOfWasteManagementInLineWithWasteHierarchy",
  "actionContributesToCollectiveAction",
  "descriptionOfContributionToCircularEconomy",
  "descriptionOfOtherStakeholdersInvolved",
  "descriptionOfOrganisationOfProject",
];
export const E5: { [key in keyof IActionDataE5]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e5,
  actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndOrWater: {
    type: "boolean",
    initialValue: false,
  },
  actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates: {
    type: "boolean",
    initialValue: false,
  },
  actionCoversApplicationOfCircularDesignAndOrHigherRatesOfEitherReuseRepairRefurbishingRemanufactureRepurposingOrRecycling:
    {
      type: "boolean",
      initialValue: false,
    },
  actionCoversApplicationOfCircularBusinessPractices: {
    type: "boolean",
    initialValue: false,
  },
  actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain: {
    type: "boolean",
    initialValue: false,
  },
  actionCoversOptimisationOfWasteManagementInLineWithWasteHierarchy: {
    type: "boolean",
    initialValue: false,
  },
  actionContributesToCollectiveAction: {
    type: "boolean",
    initialValue: false,
  },
  descriptionOfContributionToCircularEconomy: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfOtherStakeholdersInvolved: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfOrganisationOfProject: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
};

export type S1_KEYS = keyof IActionDataS1;
export const S1_ORDER: S1_KEYS[] = ["addressedMatters"];
export const S1: Record<S1_KEYS, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s1,
};

export type S2_KEYS = keyof IActionDataS2;
export const S2_ORDER: S2_KEYS[] = ["addressedMatters"];
export const S2: Record<S2_KEYS, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s2,
};

export type S3_KEYS = keyof IActionDataS3;
export const S3_ORDER: S3_KEYS[] = ["addressedMatters"];
export const S3: Record<S3_KEYS, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s3,
};

export type S4_KEYS = keyof IActionDataS4;
export const S4_ORDER: S4_KEYS[] = ["addressedMatters"];
export const S4: Record<S4_KEYS, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s4,
};

export type E1And4_KEY = keyof IActionDataE1And4;
export const E1And4_ORDER: E1And4_KEY[] = [
  "explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToRelevantLineItemsOrNotesInFinancialStatementsExplanatory",
  "explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToKeyPerformanceIndicatorsRequiredUnderCommissionDelegatedRegulationEU2021NO2178Explanatory",
  "explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToCapExPlanRequiredByCommissionDelegatedRegulationEU2021NO2178Explanatory",
];
export const E1And4: Record<keyof IActionDataE1And4, IMDRInput> = {
  explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToRelevantLineItemsOrNotesInFinancialStatementsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToKeyPerformanceIndicatorsRequiredUnderCommissionDelegatedRegulationEU2021NO2178Explanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToCapExPlanRequiredByCommissionDelegatedRegulationEU2021NO2178Explanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export const ALL_SECTIONS_CONFIG = {
  general: GENERAL,
  e1: E1,
  e2: E2,
  e3: E3,
  e4: E4,
  e5: E5,
  s1: S1,
  s2: S2,
  s3: S3,
  s4: S4,
  e1and4: E1And4,
};

export const ALL_SECTIONS_ORDER = {
  general: GENERAL_ORDER,
  e1: E1_ORDER,
  e2: E2_ORDER,
  e3: E3_ORDER,
  e4: E4_ORDER,
  e5: E5_ORDER,
  s1: S1_ORDER,
  s2: S2_ORDER,
  s3: S3_ORDER,
  s4: S4_ORDER,
  e1and4: E1And4_ORDER,
};
