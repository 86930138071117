import { FC, PropsWithChildren, useMemo } from "react";
import {
  InitialConfigType,
  InitialEditorStateType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import "./lexical.styles.css";
import { LEXICAL_THEME } from "./lexical.constants";
import { COMMON_LEXICAL_NODES } from "@netcero/netcero-common";
import * as Sentry from "@sentry/react";

const handleError = (error: Error) => {
  console.error("Lexical error", error);
  Sentry.captureException(error, {
    data: {
      netceroAppFeature: "LexicalEditor",
    },
  });
};

interface ILexicalBaseEditorProps {
  initialEditorState?: InitialEditorStateType;
}

/**
 * Base editor for the Lexical editor. Initializes default settings (theme, error handling).
 * Provide Plugins as needed and as you would with the default LexicalComposer.
 * @param initialEditorState - initial editor state
 * @param children
 * @constructor
 */
export const LexicalBaseEditor: FC<PropsWithChildren<ILexicalBaseEditorProps>> = ({
  initialEditorState,
  children,
}) => {
  const initialConfig: InitialConfigType = useMemo(
    () => ({
      namespace: "NetCeroEditor",
      theme: LEXICAL_THEME,
      onError: handleError,
      editable: false,
      editorState: initialEditorState,
      nodes: [...COMMON_LEXICAL_NODES],
    }),
    [initialEditorState],
  );

  return <LexicalComposer initialConfig={initialConfig}>{children}</LexicalComposer>;
};
