import { IESRSTopic } from "@netcero/netcero-core-api-client";

export interface IGroupedESRSTopics {
  environmental: IESRSTopic[];
  social: IESRSTopic[];
  governance: IESRSTopic[];
  other: IESRSTopic[];
}

export class DMADashboardUtilities {
  /**
   * Group esrs topics by their area.
   * @param esrsTopics the topics to group
   */
  public static groupTopicsByArea(esrsTopics: IESRSTopic[]): IGroupedESRSTopics {
    return {
      environmental: esrsTopics.filter((esrsTopic) => esrsTopic.topic.startsWith("ESRS E")),
      social: esrsTopics.filter((esrsTopic) => esrsTopic.topic.startsWith("ESRS S")),
      governance: esrsTopics.filter((esrsTopic) => esrsTopic.topic.startsWith("ESRS G")),
      other: esrsTopics.filter(
        (esrsTopic) =>
          !esrsTopic.topic.startsWith("ESRS E") &&
          !esrsTopic.topic.startsWith("ESRS S") &&
          !esrsTopic.topic.startsWith("ESRS G"),
      ),
    };
  }
}
