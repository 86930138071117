/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * esrs:TimeHorizonsUnderWhichKeyActionIsToBeCompleted
 * @export
 * @enum {string}
 */

export const ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum = {
  ShorttermMember: "esrs:ShorttermMember",
  MediumtermMember: "esrs:MediumtermMember",
  LongtermMember: "esrs:LongtermMember",
} as const;

export type ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum =
  (typeof ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum)[keyof typeof ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum];
