import {
  IDataEntryObject,
  IOrganizationStructureDetailed,
  IOrganizationStructureDetailedOneOf,
} from "@netcero/netcero-core-api-client";
import { FC, ReactNode } from "react";
import { OrganizationStructureIsDraftNotice } from "./organization-structure-is-draft-notice.components";

interface IOrganizationStructureDraftWrapperProps {
  organizationId: string;
  recordingPeriodId: string;
  organizationStructure: IOrganizationStructureDetailed;
  build: (structure: IDataEntryObject) => ReactNode;
}

/**
 * Only calls the build function if the organization structure is not a draft.
 * Otherwise, displays the OrganizationStructureIsDraftNotice.
 * @param organizationId Organization the structure belongs to
 * @param recordingPeriodId Recording Period the structure belongs to
 * @param organizationStructure Organization Structure to display if not draft
 * @param build
 */
export const OrganizationStructureDraftWrapper: FC<IOrganizationStructureDraftWrapperProps> = ({
  organizationId,
  recordingPeriodId,
  organizationStructure,
  build,
}) => {
  return organizationStructure.isDraft ? (
    <OrganizationStructureIsDraftNotice
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
    />
  ) : (
    build((organizationStructure as IOrganizationStructureDetailedOneOf).structure)
  );
};
