import { FC } from "react";
import { useTranslation } from "react-i18next";
import { SUPPORT_COLORS } from "../../../theme/theme";
import { BaseStatusIndicatorChip } from "../../common/components/base-status-indicator-chip.component";

interface IMaterialityStatusChipProps {
  isMateriality: boolean;
}

export const MaterialityStatusChip: FC<IMaterialityStatusChipProps> = ({ isMateriality }) => {
  const { t } = useTranslation("dma_dashboard");

  const materialityText = isMateriality
    ? t("materiality_value_true")
    : t("materiality_value_false");

  const backgroundColor = isMateriality ? SUPPORT_COLORS.green : SUPPORT_COLORS.red;

  return <BaseStatusIndicatorChip backgroundColor={backgroundColor} label={materialityText} />;
};
