import {
  IManualStakeholderFeedbackSource,
  IStakeholderFeedbackType,
} from "@netcero/netcero-core-api-client";

export const ORDERED_FEEDBACK_TYPES = [
  IStakeholderFeedbackType.Material,
  IStakeholderFeedbackType.Financial,
];

export const ORDERED_FEEDBACK_SOURCES = [
  IManualStakeholderFeedbackSource.Interview,
  IManualStakeholderFeedbackSource.Workshop,
  IManualStakeholderFeedbackSource.Survey,
  IManualStakeholderFeedbackSource.Discussion,
];
