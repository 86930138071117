import * as React from "react";
import { FC } from "react";
import { MenuItem, Select, SvgIcon, Typography, TypographyProps } from "@mui/material";
import { ChevronDownIcon } from "../constants/tabler-icon.constants";

interface IOption {
  value: string;
  display: string;
}

interface ITypographySelectComponentProps {
  value?: string;
  onValueChange: (value: string) => void;
  options: IOption[];
  typographyVariant: TypographyProps["variant"];
  typographyComponent?: TypographyProps["component"];
}

export const TypographySelectComponent: FC<ITypographySelectComponentProps> = ({
  value,
  onValueChange,
  options,
  typographyVariant,
  typographyComponent,
}) => {
  return (
    <Select
      variant="standard"
      value={value}
      onChange={(event) => onValueChange(event.target.value as string)}
      renderValue={(value) => {
        const targetOption = options.find((o) => o.value === value);
        return (
          // For whatever reason the Typography component does not accept the component prop directly (due to it being option/undefined)
          <Typography variant={typographyVariant} pr={2} {...{ component: typographyComponent }}>
            {targetOption?.display ?? "OPTION NOT FOUND!"}
          </Typography>
        );
      }}
      IconComponent={() => (
        <SvgIcon
          sx={{
            ml: -4,
            pointerEvents: "none",
            fill: "transparent",
            fontSize: 32, // TODO: Once dynamic dropdown arrow sizes are needed calculated them in the component based upon the typrography variant
          }}
          component={ChevronDownIcon}
        />
      )}
      disableUnderline
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.display}
        </MenuItem>
      ))}
    </Select>
  );
};
