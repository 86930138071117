import { FC, ReactNode, useMemo } from "react";
import { EnvironmentUtilities } from "../utilities/environment.utilities";
import { useKeycloakOrganizationData } from "../../user/hooks/keycloak-organization-data.hook";
import { useCurrentOrganization } from "../../organizations/use-current-organization.hook";

interface IHideChildrenInProductionComponentProps {
  children: ReactNode;
}

export const HideChildrenInProductionAndNoFullAccessComponent: FC<
  IHideChildrenInProductionComponentProps
> = ({ children }) => {
  // TODO: This is a quick and dirty for implementation for modules based on orgs, WILL BE REMOVED
  const currentOrganization = useCurrentOrganization();
  const organizationData = useKeycloakOrganizationData(currentOrganization?.id ?? "");

  const doesCurrentOrgHaveFullAccess = useMemo(
    () => organizationData?.attributes.full_access?.at(0) === "true" || false,
    [organizationData?.attributes.full_access],
  );

  // Only return null if in production and the current organization does not have full access
  if (EnvironmentUtilities.IS_PRODUCTION && !doesCurrentOrgHaveFullAccess) {
    return null;
  }

  return children;
};
