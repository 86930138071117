import { IInputParameterValue } from "@netcero/netcero-core-api-client";
import { InputParameterValuesPerKey } from "./input-parameters.public-types";
import { LOCKED_INPUT_PARAMETERS } from "./constants";

export class InputParametersUtilities {
  public static getInputParameterValuesConfigurationLookup(values: IInputParameterValue[]) {
    return values.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.key]: curr,
      }),
      {} as InputParameterValuesPerKey,
    );
  }
}

export function isInputParameterLocked(inputParameterKey: string) {
  return LOCKED_INPUT_PARAMETERS.has(inputParameterKey);
}
