import { Alert, AlertTitle } from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
} from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface IDMACategoryFeedbackWarningsProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
}
export const DMACategoryFeedbackWarnings: FC<IDMACategoryFeedbackWarningsProps> = ({
  dmaCategory,
}) => {
  const { t } = useTranslation("stakeholder_feedback_common");

  const showCategoryOpenWarning =
    dmaCategory.materialState === IDMACategoryState.Open &&
    dmaCategory.financialState === IDMACategoryState.Open;

  const showMaterialImpactsWIPWarning =
    dmaCategory.materialState === IDMACategoryState.WorkInProgress;
  const showFinancialEffectsWIPWarning =
    dmaCategory.financialState === IDMACategoryState.WorkInProgress;

  return (
    <>
      {/* Open Warning */}
      {showCategoryOpenWarning && (
        <Alert severity="warning">
          <AlertTitle>{t("title_warning", { ns: "common" })}</AlertTitle>
          {t("category_open_warning")}
        </Alert>
      )}

      {/* Material Impacts Warning */}
      {showMaterialImpactsWIPWarning && (
        <Alert severity="warning">
          <AlertTitle>{t("title_warning", { ns: "common" })}</AlertTitle>
          {t("material_impacts_drafts_warning")}
        </Alert>
      )}

      {/* Financial Effects Warning */}
      {showFinancialEffectsWIPWarning && (
        <Alert severity="warning">
          <AlertTitle>{t("title_warning", { ns: "common" })}</AlertTitle>
          {t("financial_effects_drafts_warning")}
        </Alert>
      )}
    </>
  );
};
