import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getEsrsPhaseInQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => ["esrs-phase-in", organizationId, recordingPeriodId, dataEntryObjectId];

export interface IEsrsPhaseInQueryData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  enabled?: boolean;
}

export const useEsrsPhaseInQuery = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  enabled = true,
}: IEsrsPhaseInQueryData) => {
  return useQuery({
    queryKey: getEsrsPhaseInQueryKey(organizationId, recordingPeriodId, dataEntryObjectId),
    queryFn: async () => {
      return CoreApiService.ESRSTopicPhaseInDecisionsApi.getPhaseInDecisionsForDeo(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled,
  });
};
