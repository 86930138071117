import {
  IDataEntryObjectInputParameterValue,
  IInputParameterValue,
  IInputParameterValueMetaData,
} from "@netcero/netcero-core-api-client";

type AllSimpleTypes = IInputParameterValueMetaData["type"];
type ValidSimpleTypes = Extract<AllSimpleTypes, "text" | "options" | "nested-options">;

type VerifiedValue = Pick<IDataEntryObjectInputParameterValue, "valuesPerKey">;

export class DataEntryObjectInputParameterTableValuesVerification {
  private static readonly VALID_SIMPLE_TYPES: IInputParameterValueMetaData["type"][] = [
    "text",
    "options",
    "nested-options",
  ];

  private static isSimpleTypeSupported(
    type: IInputParameterValueMetaData["type"],
  ): type is ValidSimpleTypes {
    return DataEntryObjectInputParameterTableValuesVerification.VALID_SIMPLE_TYPES.includes(type);
  }

  private static isValueConfigurationSupported(vd: IInputParameterValue): boolean {
    // never valid if the type is not "simple"
    if (vd.valueConfiguration.type !== "simple") {
      return false;
    }

    // Also not valid if the type is not included in the array of valid types
    if (
      !DataEntryObjectInputParameterTableValuesVerification.isSimpleTypeSupported(
        vd.valueConfiguration.configuration.type,
      )
    ) {
      return false;
    }
    // Finally, ensure that "options" and "nested-options" only support single values (so that the value is a string as
    // well)
    return !(
      ((vd.valueConfiguration.configuration.type === "options" ||
        vd.valueConfiguration.configuration.type === "nested-options") &&
        vd.valueConfiguration.configuration.multiple) ||
      (vd.valueConfiguration.configuration.type === "text" &&
        vd.valueConfiguration.configuration.multipart)
    );
  }

  private static checkRelevantValueDefinitions(
    amountOfColumns: number,
    relevantValueDefinitions: IInputParameterValue[],
  ) {
    // make sure that all specified columns were found
    if (relevantValueDefinitions.length !== amountOfColumns) {
      throw new Error(
        "Not all value definitions were found for the specified unique columns! This is probably a bug.",
      );
      // make sure that only "verifyable" columns were specified
    } else if (
      relevantValueDefinitions.some(
        (vd) =>
          !DataEntryObjectInputParameterTableValuesVerification.isValueConfigurationSupported(vd),
      )
    ) {
      throw new Error("Encountered invalid type configuration for validation!");
    }
  }

  public static areValuesUnique(
    uniqueColumns: string[],
    valueDefinitions: IInputParameterValue[],
    values: VerifiedValue[],
  ) {
    return (
      DataEntryObjectInputParameterTableValuesVerification.determineDuplicateValues(
        uniqueColumns,
        valueDefinitions,
        values,
        // only valid if no duplicates were found
      ).length === 0
    );
  }

  public static determineDuplicateValues(
    uniqueColumns: string[],
    valueDefinitions: IInputParameterValue[],
    values: VerifiedValue[],
  ) {
    const relevantValueDefinitions = valueDefinitions.filter((vd) =>
      uniqueColumns.includes(vd.key),
    );

    // make sure that the columns are valid
    DataEntryObjectInputParameterTableValuesVerification.checkRelevantValueDefinitions(
      uniqueColumns.length,
      relevantValueDefinitions,
    );

    type StoredValue = string | undefined;
    // undefined is replaced with null since undefined can't be serialized
    type SerializedValue = string | null;

    const computeCacheKey = (value: VerifiedValue) => {
      const relevantValues = uniqueColumns
        .map((c) => value.valuesPerKey[c]!)
        .map((v) => (v.value as StoredValue) ?? null);
      return JSON.stringify(relevantValues);
    };

    const groupedValues = values.reduce((acc, curr) => {
      const computedKey = computeCacheKey(curr);
      const previous = acc.get(computedKey);

      if (previous === undefined) {
        acc.set(computedKey, [curr]);
      } else {
        previous.push(curr);
      }

      return acc;
    }, new Map<string, VerifiedValue[]>());

    return Array.from(groupedValues.entries())
      .filter(([, value]) => value.length > 1)
      .map(
        ([key]) =>
          (JSON.parse(key) as SerializedValue[]).map((v) => v ?? undefined) as StoredValue[],
      );
  }
}
