import { FC } from "react";
import { IconButton } from "@mui/material";
import {
  CollapseRightSidebarIcon,
  ExpandRightSidebarIcon,
} from "../constants/tabler-icon.constants";

interface IExpandRightIconButtonProps {
  expanded: boolean;
  onClick: VoidFunction;
}

export const ExpandRightIconButton: FC<IExpandRightIconButtonProps> = ({ expanded, onClick }) => {
  return (
    <IconButton onClick={onClick}>
      {expanded ? <ExpandRightSidebarIcon /> : <CollapseRightSidebarIcon />}
    </IconButton>
  );
};
