import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IManualStakeholderFeedback,
  IStakeholderFeedbackType,
} from "@netcero/netcero-core-api-client";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ChevronDownIcon,
  DeleteIcon,
  EditIcon,
} from "../../../common/constants/tabler-icon.constants";
import { stopPropagationAnd } from "../../../common/functions/stop-propagation-and.function";
import { useResolveStakeholder } from "../../../stakeholder/use-resolve-stakeholder.hook";
import { useDeleteManualFeedbackMutation } from "../../mutations/dma-category-manual-feedback.mutations";
import { HOVER_BACKGROUND_COLOR } from "../../../../theme/theme";
import { ConfirmDialogTextBody } from "../../../common/dialogs/variants/confirm.dialog";

interface IDMACategoryManualFeedbackListProps {
  manualFeedbackEntries: IManualStakeholderFeedback[];
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsTopicId: string;
  disabled?: boolean;
  onEdit: (manualFeedback: IManualStakeholderFeedback) => void;
}

export const DMACategoryManualFeedbackList: FC<IDMACategoryManualFeedbackListProps> = ({
  manualFeedbackEntries,
  dmaCategory,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  esrsTopicId,
  disabled,
  onEdit,
}) => {
  const { t } = useTranslation("dma_category_manual_feedback_list");

  const deleteFeedbackMutation = useDeleteManualFeedbackMutation();

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState({
    open: false,
    manualFeedback: null as IManualStakeholderFeedback | null,
  });

  const handleDeleteFeedback = (manualFeedback: IManualStakeholderFeedback) => {
    deleteFeedbackMutation.reset();
    setShowConfirmDeleteDialog({
      open: true,
      manualFeedback,
    });
  };

  const handleConfirmDelete = async (confirm: boolean) => {
    if (confirm) {
      await deleteFeedbackMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId: dmaCategory?.id ?? "",
        feedbackId: showConfirmDeleteDialog.manualFeedback!.id,
      });
    }
    // Close Dialog
    setShowConfirmDeleteDialog((state) => ({ ...state, open: false }));
  };

  return (
    <>
      <ConfirmDialogTextBody
        open={showConfirmDeleteDialog.open}
        onClose={handleConfirmDelete}
        title={t("title_confirm_delete_dialog")}
        content={t("content_confirm_delete_dialog")}
        loading={deleteFeedbackMutation.isPending}
        error={deleteFeedbackMutation.error}
        disabled={deleteFeedbackMutation.isPending}
        actionType="delete"
      />

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>{t("header_stakeholder")}</TableCell>
            <TableCell>{t("header_type")}</TableCell>
            <TableCell>{t("header_source")}</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {manualFeedbackEntries.map((manualFeedback) => (
            <DMACategoryManualFeedbackListItem
              key={manualFeedback.id}
              manualFeedback={manualFeedback}
              dmaCategory={dmaCategory}
              organizationId={organizationId}
              disabled={disabled}
              onEdit={() => onEdit(manualFeedback)}
              onDelete={() => handleDeleteFeedback(manualFeedback)}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

// Table Item

interface IDMACategoryManualFeedbackListItemProps {
  manualFeedback: IManualStakeholderFeedback;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  organizationId: string;
  disabled?: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const DMACategoryManualFeedbackListItem: FC<IDMACategoryManualFeedbackListItemProps> = ({
  manualFeedback,
  dmaCategory,
  organizationId,
  disabled,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation("dma_category_manual_feedback_list");

  const resolveStakeholder = useResolveStakeholder(organizationId);

  const stakeholdersText = manualFeedback.stakeholders
    .map(resolveStakeholder)
    .map(
      (stakeholder) =>
        `${stakeholder?.name}${stakeholder?.organization ? ` (${stakeholder.organization})` : ""}`,
    )
    .join(", ");

  const [expanded, setExpanded] = useState(false);

  // Render Item

  const canEdit =
    (manualFeedback.feedbackType === IStakeholderFeedbackType.Material &&
      dmaCategory?.materialState !== IDMACategoryState.Verified) ||
    (manualFeedback.feedbackType === IStakeholderFeedbackType.Financial &&
      dmaCategory?.financialState !== IDMACategoryState.Verified);

  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          "&:hover": {
            cursor: "pointer",
            backgroundColor: HOVER_BACKGROUND_COLOR,
          },
        }}
        onClick={() => setExpanded((curr) => !curr)}
      >
        <TableCell>
          <IconButton
            // this prevents the circle from showing up
            // icon is still wrapped in button as this applies some preferable styling
            disableRipple={true}
            size="small"
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 300ms",
            }}
          >
            <ChevronDownIcon />
          </IconButton>
        </TableCell>
        <TableCell>{stakeholdersText}</TableCell>
        <TableCell>
          {t(`type_${manualFeedback.feedbackType}`, {
            ns: "manual_stakeholder_feedback_common",
          })}
        </TableCell>
        <TableCell>
          {manualFeedback.feedbackSource
            ? t(`source_${manualFeedback.feedbackSource}`, {
                ns: "manual_stakeholder_feedback_common",
              })
            : "-"}
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" gap={1}>
            <Tooltip title={t("edit", { ns: "buttons" })}>
              <span>
                <IconButton
                  size="small"
                  onClick={stopPropagationAnd(onEdit)}
                  disabled={disabled || !canEdit}
                >
                  <EditIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("delete", { ns: "buttons" })}>
              <span>
                <IconButton
                  size="small"
                  onClick={stopPropagationAnd(onDelete)}
                  disabled={disabled || !canEdit}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={expanded}>
            <Box p={2}>
              <Typography fontWeight="bold" mb={1}>
                {t("title_feedback")}
              </Typography>
              <Typography>{manualFeedback.feedback}</Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
