import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { IIntercomReferenceBase } from "@netcero/netcero-common";
import { IDataEntryObject, IDMAEffectAreaType, IESRSTopic } from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, ControllerRenderProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckboxWithArrayValueAndLabel } from "../../common/components/checkbox-with-array-value-and.label";
import MultipleSelectChip from "../../common/components/multiple-chip.component";
import { IconSize } from "../../common/constants/tabler-icon.constants";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { DataEntryObjectsAppUtilities } from "../../data-entry-objects/data-entry-objects-app.utilities";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { DataEntryObjectPickerDialog } from "../dialogs/data-entry-organisations/data-entry-object-picker.dialog";
import { IInternalFinancialEffectData } from "../financial-effects/internal-financial-effect-data.types";

type IIROAreasCheckboxesRelevantProps = Pick<
  IInternalFinancialEffectData,
  "areas" | "associatedDataEntryObjects"
>;

interface IIROAreasCheckboxesComponentProps extends IIntercomReferenceBase {
  dataEntryObject: IDataEntryObject;
  disabled?: boolean;
  esrsTopic: IESRSTopic | null;
  entityType: "impact" | "effect";
}

interface ICheckboxProps {
  area: IDMAEffectAreaType;
  field: ControllerRenderProps<IIROAreasCheckboxesRelevantProps, "areas">;
  disabled: boolean | undefined;
}

const idToName = (fieldValue: string[], dataEntryObject: IDataEntryObject): string[] => {
  return fieldValue
    .map((id) => DataEntryObjectsAppUtilities.getDataEntryObjectNameBasedOnId(dataEntryObject, id))
    .filter((name): name is string => name !== undefined);
};

const CheckboxInput = ({ area, field, disabled }: ICheckboxProps) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  return (
    <CheckboxWithArrayValueAndLabel
      label={t(`${area}_name`)}
      value={field.value}
      checkboxValue={area}
      onChange={field.onChange}
      checkboxProps={{ size: "small" }}
      disabled={disabled}
    />
  );
};

export const IROAreasCheckboxesComponent: FC<IIROAreasCheckboxesComponentProps> = ({
  dataEntryObject,
  disabled,
  esrsTopic,
  entityType,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  const {
    isOpen: isDataEntryDialogOpen,
    openDialog: openDataEntryDialog,
    closeDialog: closeDataEntryDialog,
  } = useDialogStateWithoutData();

  const {
    control,
    trigger,
    watch,
    clearErrors,
    formState: { submitCount },
  } = useFormContext<IIROAreasCheckboxesRelevantProps>();

  useEffect(() => {
    // Skip if not submitted yet (no errors should be shown yet)
    if (submitCount < 1) {
      clearErrors(["areas", "associatedDataEntryObjects"]);
      return;
    }
    void trigger("areas");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("associatedDataEntryObjects"), submitCount, trigger, clearErrors]);

  return (
    <Controller
      control={control}
      name="areas"
      rules={{
        validate: (value, formValues) => {
          if (value.length === 0 && formValues.associatedDataEntryObjects.length === 0) {
            return t("error_effect_area_required");
          }

          return true;
        },
      }}
      render={({ field, fieldState: { error } }) => {
        return (
          <Box px={1}>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">
                {t("areas_select_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}
              </Typography>
              {/* Only show if esrs topic is not null, since tooltips are bound to a specific topic */}
              {esrsTopic && (
                <IntercomReferenceWidget
                  entityType={entityType}
                  identifier={`dialog_subheading_affected_areas_create_help.${esrsTopic.identifier}`}
                  iconSize={IconSize.Smaller}
                />
              )}
            </Box>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
            <Grid container alignItems="center" spacing={4}>
              <Grid item xs={3}>
                <CheckboxInput
                  area={IDMAEffectAreaType.UpstreamValueChain}
                  field={field}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={6}>
                {/* Add padding to increase distance between deo picker and downstream checkbox */}
                <Box pr={2}>
                  <Controller
                    control={control}
                    name="associatedDataEntryObjects"
                    render={({ field }) => {
                      return (
                        <>
                          <MultipleSelectChip
                            label={t(`own-organization_name`)}
                            onSelectClick={openDataEntryDialog}
                            chipNames={idToName(field.value, dataEntryObject)}
                            fullWidth
                          />
                          <DataEntryObjectPickerDialog
                            open={isDataEntryDialogOpen}
                            onClose={closeDataEntryDialog}
                            title={t("own-organization_name")}
                            dataEntryObject={dataEntryObject}
                            checkedIds={field.value}
                            onChangeCheckedIds={field.onChange}
                            disabled={disabled}
                          />
                        </>
                      );
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <CheckboxInput
                  area={IDMAEffectAreaType.DownstreamValueChain}
                  field={field}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Box>
        );
      }}
    />
  );
};
