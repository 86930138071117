import { FC, useMemo } from "react";
import { IDMACategoryWithEffectsAndChildren } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { useESRSTopicContext } from "./esrs-topic.context";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "./dma.queries";
import { DMACategoryUtilities } from "./dialogs/dma-categories/dma-category.utilities";
import { Badge, Button, IconButton, Tooltip } from "@mui/material";
import {
  AddDmaCategorySummaryIcon,
  AddDmaSubTopicIcon,
  CommentIcon,
  EditIcon,
} from "../common/constants/tabler-icon.constants";

interface IDMACategoryActionsProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
  onEdit: VoidFunction;
  canEdit: boolean;
  showEdit: boolean;
  onShowFeedback?: VoidFunction;
  onAddSummary?: VoidFunction;
  onAddChild?: VoidFunction;
  onOptOut?: VoidFunction;
}

export const DMACategoryActions: FC<IDMACategoryActionsProps> = ({
  dmaCategory,
  onEdit,
  canEdit,
  onShowFeedback,
  onAddSummary,
  onAddChild,
  onOptOut,
  showEdit,
}) => {
  const { t } = useTranslation("double_materiality_assessment_sub_category_component", {
    keyPrefix: "dma_category_actions_component",
  });

  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  // Load stakeholder feedbacks to display the count in the badge
  const stakeholderFeedbacksQuery = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  // Count the feedbacks that are not pending as we only want to display the amount of those
  const feedbackCount = useMemo(() => {
    return stakeholderFeedbacksQuery.data?.stakeholderFeedbacks.filter(
      (feedback) => DMACategoryUtilities.getFeedbackState(feedback) !== "pending",
    ).length;
  }, [stakeholderFeedbacksQuery.data]);

  // Also load manual feedbacks, so they are cached when opening the feedback dialog
  useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  return (
    <>
      {showEdit && (
        <Tooltip title={t("tooltip_edit")}>
          <span>
            <IconButton size="small" onClick={onEdit} disabled={!canEdit}>
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onShowFeedback && (
        <Tooltip title={t("tooltip_stakeholder_feedback")}>
          <Badge color="primary" badgeContent={feedbackCount} overlap="circular">
            <IconButton size="small" onClick={onShowFeedback}>
              <CommentIcon />
            </IconButton>
          </Badge>
        </Tooltip>
      )}
      {onAddSummary && (
        <Tooltip title={canEdit ? t("tooltip_add_summary") : t("tooltip_add_summary_disabled")}>
          <span>
            <IconButton size="small" onClick={onAddSummary} disabled={!canEdit}>
              <AddDmaCategorySummaryIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onAddChild && (
        <Tooltip title={canEdit ? t("tooltip_add_child") : t("tooltip_add_child_disabled")}>
          <span>
            <IconButton size="small" onClick={onAddChild} disabled={!canEdit}>
              <AddDmaSubTopicIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onOptOut && (
        <Button
          variant="outlined"
          size="small"
          color="error"
          onClick={onOptOut}
          sx={{ minWidth: 124 }}
        >
          {t("button_category_irrelevant")}
        </Button>
      )}
    </>
  );
};
