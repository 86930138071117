import { Box, IconButton, Tooltip } from "@mui/material";
import { TargetPathVerification } from "@netcero/netcero-common";
import { ITargetPath, ITargetPathState } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowBarDownIcon,
  ArrowBarUpIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
} from "../../common/constants/tabler-icon.constants";
import { stopPropagationAnd } from "../../common/functions/stop-propagation-and.function";

interface ITargetPathActionsComponentProps {
  targetPath: ITargetPath;
  disabled?: boolean;
  onEdit: (targetPath: ITargetPath) => void;
  onToggleCommit: (targetPath: ITargetPath) => void;
  onClone: (targetPath: ITargetPath) => void;
  onDelete: (targetPath: ITargetPath) => void;
  hideUnavailableActions?: boolean;
}

export const TargetPathActionsComponent: FC<ITargetPathActionsComponentProps> = ({
  targetPath,
  disabled,
  onEdit,
  onDelete,
  onToggleCommit,
  onClone,
  hideUnavailableActions,
}) => {
  const canTargetPathBeEdited = useMemo(
    () => TargetPathVerification.canTargetPathBeEdited(targetPath),
    [targetPath],
  );

  const editDisabled = useMemo(
    () => disabled || !canTargetPathBeEdited,
    [canTargetPathBeEdited, disabled],
  );

  const showActions = useMemo(
    () => !(editDisabled && hideUnavailableActions),
    [editDisabled, hideUnavailableActions],
  );

  const { t } = useTranslation("target_path_actions_component");

  return targetPath.state === ITargetPathState.Fulfilled ? null : (
    <Box>
      {/* Edit target-path */}
      {showActions && (
        <Tooltip title={t(`action_tooltips.edit${editDisabled ? "_disabled" : ""}`)}>
          <span>
            <IconButton
              size="small"
              onClick={stopPropagationAnd(() => onEdit(targetPath))}
              disabled={editDisabled}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {/* Toggle committed status */}
      <Tooltip
        title={
          !TargetPathVerification.canTargetPathBeCommitted(targetPath)
            ? t("action_tooltips.commit_disabled")
            : t(
                `action_tooltips.${
                  targetPath.state === ITargetPathState.Committed ? "cease" : "commit"
                }`,
              )
        }
      >
        <span>
          <IconButton
            size="small"
            onClick={stopPropagationAnd(() => onToggleCommit(targetPath))}
            disabled={disabled || !TargetPathVerification.canTargetPathBeCommitted(targetPath)}
          >
            {targetPath.state === ITargetPathState.Committed ? (
              <ArrowBarDownIcon />
            ) : (
              <ArrowBarUpIcon />
            )}
          </IconButton>
        </span>
      </Tooltip>
      {/* Clone target-path */}
      <Tooltip title={t(`action_tooltips.clone`)}>
        <span>
          <IconButton
            size="small"
            onClick={stopPropagationAnd((event) => onClone(targetPath))}
            disabled={disabled}
          >
            <CopyIcon />
          </IconButton>
        </span>
      </Tooltip>
      {/* Delete target path */}
      {showActions && (
        <Tooltip title={t(`action_tooltips.delete${editDisabled ? "_disabled" : ""}`)}>
          <span>
            <IconButton
              size="small"
              onClick={stopPropagationAnd(() => onDelete(targetPath))}
              disabled={editDisabled}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
