import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getAppliedDistributionCriteriaQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
) => [
  "distribution-criteria",
  organizationId,
  "recording-period",
  recordingPeriodId,
  "application",
];

/**
 * Fetches all applied distribution criteria for a given organization and recording period
 * @param organizationId
 * @param recordingPeriodId
 */
export const useAppliedDistributionCriteriaQuery = (
  organizationId: string,
  recordingPeriodId: string,
) => {
  return useQuery({
    queryKey: getAppliedDistributionCriteriaQueryKey(organizationId, recordingPeriodId),
    queryFn: async () => {
      return CoreApiService.AppliedDistributionCriteriaApi.getAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
  });
};
