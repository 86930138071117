import { FC } from "react";
import { useSearchUsersQueryInternal } from "../../modules/user/users.queries";
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { useDebounce } from "@uidotdev/usehooks";
import { UserTableFilters } from "./user-table-filters.component";
import { UserUtilities } from "@netcero/netcero-common";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";

interface IUsersTableProps {
  organizationId: string;
}

export const UserTable: FC<IUsersTableProps> = ({ organizationId }) => {
  const { t } = useTranslation("users_table");

  const { watch } = useFormContext<UserTableFilters>();
  const searchValue = watch("searchValue");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const usersQuery = useSearchUsersQueryInternal(
    organizationId,
    debouncedSearchValue,
    UserUtilities.sortUsersArrayByFullName,
  );

  return (
    <QueryWrapper
      query={usersQuery}
      build={(users) =>
        users.length === 0 ? (
          <Box>{t("no_users_found")}</Box>
        ) : (
          <Table size="small" sx={{ mt: -2 }}>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("first_name")}</StyledTableCell>
                <StyledTableCell>{t("last_name")}</StyledTableCell>
                <StyledTableCell>{t("email")}</StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <StyledTableCell>{user.firstName}</StyledTableCell>
                  <StyledTableCell>{user.lastName}</StyledTableCell>
                  <StyledTableCell>{user.email}</StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      }
    />
  );
};

const StyledTableCell = styled(TableCell)({
  padding: "16px",
});
