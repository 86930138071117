import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IDMACategoryWithEffectsAndChildren } from "@netcero/netcero-core-api-client";
import { AssociatedIROsSelectComponent } from "./associated-iros-select.component";

// (extract financialEffectIds / materialImpactIds for MDR schemas)
interface IFormValues {
  financialEffectIds: string[];
  materialImpactIds: string[];
}

interface IAssociatedIROsControllersComponentProps {
  dmaCategories: IDMACategoryWithEffectsAndChildren[];
  disabled: boolean;
  label: string;
}

export const AssociatedIROsControllersComponent: FC<IAssociatedIROsControllersComponentProps> = ({
  disabled,
  label,
  dmaCategories,
}) => {
  const { control } = useFormContext<IFormValues>();
  return (
    <Controller
      control={control}
      name="materialImpactIds"
      render={({ field: materialImpactField }) => (
        <Controller
          control={control}
          name="financialEffectIds"
          render={({ field: financialEffectField }) => (
            <AssociatedIROsSelectComponent
              label={label}
              dmaCategories={dmaCategories}
              financialEffectIds={financialEffectField.value}
              materialImpactIds={materialImpactField.value}
              onMaterialImpactIdsChange={materialImpactField.onChange}
              onFinancialEffectIdsChange={financialEffectField.onChange}
              // disable if any mutations are running
              disabled={disabled}
            />
          )}
        />
      )}
    />
  );
};
