import { Box, Divider, IconButton, Skeleton, Tooltip } from "@mui/material";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IIntercomEntity,
  IPolicy,
  IPolicyData,
  IPolicyGeneralData,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  ALL_SECTIONS_CONFIG,
  ALL_SECTIONS_ORDER,
  GENERAL,
  S_VALUES_ONLY,
} from "../policy-inputs.constants";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { PolicyTopicComponent } from "./policy-topic.component";
import { MdrUtilities } from "../../minimum-disclosure-requirements-common/mdr.utilities";
import { PoliciesUtilities, WithEqualUndefinedAndNullRecursive } from "@netcero/netcero-common";
import { useDMACategoriesQuery } from "../../double-materiality-assessment/dma.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { PoliciesAppUtilities, PolicySDataOnly } from "../policies-app.utilities";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import { MdrTopicBreadcrumb } from "../../minimum-disclosure-requirements-common/components/mdr-topic-breadcrumb.component";
import { MdrAssociatedUsersAndSourcesInputs } from "../../minimum-disclosure-requirements-common/components/mdr-associated-users-and-sources-inputs.component";
import { IPolicyDataInternal } from "../policies.types";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";

interface IPolicyEditDialogPropsBase {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IPolicyData | null) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  rootDataEntryObjectId: string;
  recordingPeriodId: string;
}

interface IPolicyEditDialogPropsBaseCreate extends IPolicyEditDialogPropsBase {
  policy?: undefined;
  onDelete?: undefined;
}

interface IPolicyEditDialogPropsBaseEdit extends IPolicyEditDialogPropsBase {
  policy: IPolicy | undefined | null;
  onDelete: (policyId: string) => void;
}

type IPolicyEditDialogProps = IPolicyEditDialogPropsBaseCreate | IPolicyEditDialogPropsBaseEdit;

const getFormDefaultValues = (
  policy: IPolicy | undefined | null,
  identifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
): IPolicyDataInternal => {
  const identifierKey = PoliciesAppUtilities.getIdentifierKeyForTopic(identifier);

  const result: IPolicyDataInternal = {
    ...(policy ? PoliciesUtilities.convertPolicyToPolicyData(policy) : {}),
    materialImpactIds: policy?.materialImpactIds ?? [],
    financialEffectIds: policy?.financialEffectIds ?? [],
    general: MdrUtilities.getDefaultValuesForConfiguration<IPolicyGeneralData>(
      GENERAL,
      policy?.general,
    ),
    associatedTopics: policy?.associatedTopics ?? [identifier],
    responsibleUserId: policy?.responsibleUserId ?? null,
    contributingUserIds: policy?.contributingUserIds ?? [],
    sourceIds: policy?.sourceIds ?? [],
  };

  if (
    // S Sections
    PoliciesAppUtilities.isSTopic(identifierKey)
  ) {
    // S Sections general
    result.s = MdrUtilities.getDefaultValuesForConfiguration<PolicySDataOnly>(
      ALL_SECTIONS_CONFIG["s"],
      policy?.s,
    );
    // S Sections specifics
    result.s[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      policy?.s?.[identifierKey],
    ) as never;
  } else if (
    // E Sections
    PoliciesAppUtilities.isETopic(identifierKey)
  ) {
    result[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
      ALL_SECTIONS_CONFIG[identifierKey],
      policy?.[identifierKey],
    ) as never;
  }

  return result;
};
export const PolicyEditDialog: FC<IPolicyEditDialogProps> = ({
  disabled,
  onClose,
  onDelete,
  error,
  open,
  loading,
  policy,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
}) => {
  const mode = policy ? "edit" : "create";
  const identifierKey = useMemo(
    () => PoliciesAppUtilities.getIdentifierKeyForTopic(topicIdentifier),
    [topicIdentifier],
  );
  const { t } = useTranslation("data_entry_object_policy_component");

  const dmaCategoriesQuery = useDMACategoriesQuery(
    organizationId,
    recordingPeriodId,
    rootDataEntryObjectId,
  );

  const useFormResult = useForm<IPolicyDataInternal>({
    defaultValues: getFormDefaultValues(policy, topicIdentifier),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useFormResult;

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(policy, topicIdentifier));
    }
  }, [open, reset, policy, topicIdentifier]);

  const handleEmitData = useCallback(
    (data: IPolicyDataInternal) => {
      const result = {
        ...data,
        responsibleUserId: data.responsibleUserId ?? undefined,
        general: MdrUtilities.convertToApiPayload(data.general, GENERAL),
      };

      if (PoliciesAppUtilities.isSTopic(identifierKey)) {
        result.s = {
          ...data.s!,
          ...MdrUtilities.convertToApiPayload<WithEqualUndefinedAndNullRecursive<S_VALUES_ONLY>>(
            data.s!,
            ALL_SECTIONS_CONFIG["s"],
          ),
          [identifierKey]: MdrUtilities.convertToApiPayload(
            data.s![identifierKey],
            ALL_SECTIONS_CONFIG[identifierKey],
          ),
        };
      } else if (PoliciesAppUtilities.isETopic(identifierKey)) {
        result[identifierKey] = MdrUtilities.convertToApiPayload(
          data[identifierKey],
          ALL_SECTIONS_CONFIG[identifierKey],
        ) as never;
      } else {
        // Do nothing here as those topics have no topic-specific data, i.e. they don't require further processing
      }
      // This is fine since all updated/changed properties are being converted properly (by convertToApiPayload)
      onClose(result as IPolicyData);
    },
    [identifierKey, onClose],
  );

  return (
    <EditDialogWrapper
      open={open}
      mode={mode}
      loading={loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      disabled={disabled}
      dialogProps={{
        fullWidth: true,
        maxWidth: "xl",
      }}
      title={
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1}>
            {/* Breadcrumbs */}
            <MdrTopicBreadcrumb topicIdentifier={topicIdentifier} />
            {/* Actual title*/}
            <span>
              <FormatTranslation
                i18nKey={mode === "create" ? "create_policy" : "edit_policy"}
                t={t}
                values={{ title: policy?.general.name }}
              />
              {mode === "create" && (
                <IntercomReferenceWidget
                  entityType={IIntercomEntity.Policy}
                  identifier={`dialog_heading_create_help.${topicIdentifier}`}
                  renderIfNoArticleFound={
                    <IntercomReferenceWidget
                      entityType={IIntercomEntity.Policy}
                      identifier="dialog_heading_create_help.common"
                    />
                  }
                />
              )}
            </span>
          </Box>
          {/* Delete button */}
          {onDelete && policy && (
            <>
              <Divider orientation="vertical" flexItem />
              <Tooltip title={t("delete_policy_tooltip")}>
                <span>
                  <IconButton onClick={() => onDelete(policy.id)} disabled={loading}>
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </>
          )}
        </Box>
      }
    >
      {/* General section (includes associated entities) */}
      <FormProvider {...useFormResult}>
        {/* Linked Users & Sources */}
        <MdrAssociatedUsersAndSourcesInputs organizationId={organizationId} disabled={disabled} />
        {/* General Inputs */}
        <QueryWrapper
          query={dmaCategoriesQuery}
          loadingOverride={() => <Skeleton variant="rounded" height={56} sx={{ my: 2 }} />}
          build={(dmaCategories) => (
            <PolicyTopicComponent
              topicPrefix="general"
              control={control}
              inputs={ALL_SECTIONS_ORDER["general"]}
              inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
              watch={watch}
              disabled={!!disabled}
              dmaCategories={dmaCategories}
            />
          )}
        />
      </FormProvider>

      {PoliciesAppUtilities.hasSpecificInputs(identifierKey) && (
        <>
          <Divider sx={{ my: 3 }} />
          {PoliciesAppUtilities.isSTopic(identifierKey) ? (
            <>
              <PolicyTopicComponent
                sectionTitle={t("section_titles.esrs_s")}
                topicPrefix="s"
                control={control}
                inputs={ALL_SECTIONS_ORDER["s"]}
                inputsMetaData={ALL_SECTIONS_CONFIG["s"]}
                watch={watch}
                disabled={!!disabled}
              />
              <Divider sx={{ my: 3 }} />
              <PolicyTopicComponent
                sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
                topicPrefix={`s.${identifierKey}`}
                control={control}
                inputs={ALL_SECTIONS_ORDER[identifierKey]}
                inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
                watch={watch}
                disabled={!!disabled}
              />
            </>
          ) : (
            <PolicyTopicComponent
              sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
              topicPrefix={identifierKey}
              control={control}
              inputs={ALL_SECTIONS_ORDER[identifierKey]}
              inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
              watch={watch}
              disabled={!!disabled}
            />
          )}
        </>
      )}
    </EditDialogWrapper>
  );
};
