import { FC, PropsWithChildren } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { EditorContainer } from "../components/styled.components";

interface IEditorContainerEditableOnClickProps {
  disabled?: boolean;
}

export const EditorContainerEditableOnClick: FC<
  PropsWithChildren<IEditorContainerEditableOnClickProps>
> = ({ disabled, children }) => {
  const [editor] = useLexicalComposerContext();

  return (
    <EditorContainer
      className="editor-container"
      onClick={() => {
        // Do not set editable if disabled
        if (disabled) {
          return;
        }
        // Set editable
        editor.setEditable(true);
        editor.focus();
      }}
    >
      {children}
    </EditorContainer>
  );
};
