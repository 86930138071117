import { FormControl, IconButton, TextField, Tooltip } from "@mui/material";
import { FC } from "react";
import { MessageXIcon } from "../../common/constants/tabler-icon.constants";

export interface INoteInputComponentProps {
  note: string;
  disabled?: boolean;
  onChange: (newNote: string) => void;
  onClearNote: () => void;
  toolTipRemoveNote: string;
}

export const NoteInputComponent: FC<INoteInputComponentProps> = ({
  onClearNote,
  note,
  disabled,
  onChange,
  toolTipRemoveNote,
}) => {
  return (
    <FormControl variant="outlined" size="medium" fullWidth>
      <TextField
        variant="outlined"
        size="small" // This is a hack to reduce the padding around the button
        value={note}
        onChange={(e) => onChange(e.target.value)}
        multiline={true}
        maxRows={5}
        InputProps={{
          startAdornment: (
            <Tooltip title={toolTipRemoveNote}>
              <span>
                <IconButton
                  disabled={disabled}
                  size="medium"
                  onClick={onClearNote}
                  sx={{ my: -0.1, mr: 1 }}
                >
                  <MessageXIcon />
                </IconButton>
              </span>
            </Tooltip>
          ),
        }}
      />
    </FormControl>
  );
};
