import { FC, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { KEY_ENTER_COMMAND, SerializedEditorState } from "lexical";
import { LexicalPriority } from "../lexical.constants";

interface ISaveOnCtrlEnterPluginProps {
  onSave: (tree: SerializedEditorState) => void;
}

export const SaveOnCtrlEnterPlugin: FC<ISaveOnCtrlEnterPluginProps> = ({ onSave }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (event) => {
          if (event && (event.ctrlKey || event.metaKey)) {
            editor.blur();
            onSave(editor.getEditorState().toJSON());
            return true;
          }
          return false;
        },
        LexicalPriority.LOW,
      ),
    [editor, onSave],
  );

  return null;
};
