import { FC } from "react";
import { Typography } from "@mui/material";

export interface IOptionsSelectPlaceholderProps {
  placeholder?: string;
}

export const OptionsSelectPlaceholder: FC<IOptionsSelectPlaceholderProps> = ({ placeholder }) => {
  return placeholder && <Typography sx={{ opacity: 0.5 }}>{placeholder}</Typography>;
};
