import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueBasicSuccessMessageData,
} from "../../app-snackbar/app-snackbar.interfaces";

export const UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USER_IDS_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData =
  {
    type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_CONTRIBUTING_USERS,
  };

export const UPDATE_INPUT_PARAMETER_CONTRIBUTING_USER_IDS_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData =
  {
    type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_CONTRIBUTING_USERS,
  };

export const UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER_ID_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData =
  {
    type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_RESPONSIBLE_USER,
  };

export const UPDATE_INPUT_PARAMETER_RESPONSIBLE_USER_ID_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData =
  {
    type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_RESPONSIBLE_USER,
  };

export const UPDATE_DR_VALUE_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_VALUE,
};
export const UPDATE_IP_VALUE_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_VALUE,
};

export const RESET_DR_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.RESET_DISCLOSURE_REQUIREMENT,
};
export const SUBMIT_DR_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT,
};
export const APPROVE_DR_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT,
};
export const REJECT_DR_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT,
};

export const RESET_IP_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.RESET_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER,
};
export const SUBMIT_IP_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.SUBMIT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER,
};
export const APPROVE_IP_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.APPROVE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER,
};
export const REJECT_IP_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.REJECT_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER,
};

export const UPDATE_GAP_ANALYSIS_AVAILABILITY_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_AVAILABILITY,
};
export const UPDATE_GAP_ANALYSIS_DATA_COLLECTION_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData =
  {
    type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_GAP_ANALYSIS_DATA_COLLECTION,
  };

export const UPDATE_DISCLOSURE_REQUIREMENT_SOURCES_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData =
  {
    type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_SOURCES,
  };
export const UPDATE_INPUT_PARAMETER_SOURCES_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.UPDATE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER_SOURCES,
};
