import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getSourcesQueryKey } from "../sources/sources.queries";

export const getSourcesForInputParameterQueryKey = (
  organizationId: string | undefined,
  recordingPeriodId: string | undefined,
  dataEntryObjectId: string | undefined,
  inputParameterId: string | undefined,
) => [
  ...getSourcesQueryKey(organizationId!),
  recordingPeriodId,
  dataEntryObjectId,
  inputParameterId,
];

export const useSourcesForInputParameterQuery = (
  organizationId: string | undefined,
  recordingPeriodId: string | undefined,
  dataEntryObjectId: string | undefined,
  inputParameterId: string | undefined,
) => {
  return useQuery({
    queryKey: getSourcesForInputParameterQueryKey(
      organizationId!,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
    ),
    queryFn: () =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.getDataEntryObjectInputParameterSources(
        organizationId!,
        recordingPeriodId!,
        dataEntryObjectId!,
        inputParameterId!,
      )
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!organizationId && !!recordingPeriodId && !!dataEntryObjectId && !!inputParameterId,
  });
};
