import { useFormatHumanReadableDateDifference } from "./format-human-readable-date-difference.hook";
import { useCallback } from "react";
import { DateTime, Duration } from "luxon";
import { useFormatHumanReadableDate } from "./format-human-readable-date.hook";

const MAX_DATE_DIFF_FOR_AGO = Duration.fromObject({ days: 1 });

export function isInMaxDateDiffForAgo(date: Date): boolean {
  const dateTime = DateTime.fromJSDate(date);
  const dateDiff = dateTime.diffNow().negate();
  return dateDiff < MAX_DATE_DIFF_FOR_AGO;
}

export const useFormatHumanReadableDateOrDiff = () => {
  const formatHumanReadableDateDifference = useFormatHumanReadableDateDifference();
  const formatHumanReadableDate = useFormatHumanReadableDate();

  return useCallback(
    (date: Date) => {
      return isInMaxDateDiffForAgo(date)
        ? formatHumanReadableDateDifference(date)
        : formatHumanReadableDate(date);
    },
    [formatHumanReadableDate, formatHumanReadableDateDifference],
  );
};
