import { FC } from "react";
import { IDMADashboardChartsSectionProps } from "../dma-dashboard-charts-section.component";
import { DMAMatrixChartAllTopics } from "./dma-matrix-chart-all-topics.component";
import { DMAMatrixChartTopic } from "./dma-matrix-chart-topic.component";
import { DMAMatrixChartCategory } from "./dma-matrix-chart-category.component";

interface IDMAMatrixChartProps extends IDMADashboardChartsSectionProps {
  height?: string;
}
export const DMAMatrixChart: FC<IDMAMatrixChartProps> = ({
  groupedESRSTopics,
  esrsTopic,
  dmaCategory,
  height,
  onSelectTopic,
  onSelectCategory,
}) => {
  if (esrsTopic && dmaCategory) {
    return (
      <DMAMatrixChartCategory
        esrsTopic={esrsTopic}
        dmaCategory={dmaCategory}
        height={height}
        onSelectCategory={(categoryId) => {
          onSelectCategory(esrsTopic.id, categoryId);
        }}
      />
    );
  }

  if (esrsTopic) {
    return (
      <DMAMatrixChartTopic
        esrsTopic={esrsTopic}
        height={height}
        onSelectCategory={(categoryId) => {
          onSelectCategory(esrsTopic.id, categoryId);
        }}
      />
    );
  }

  if (groupedESRSTopics) {
    return (
      <DMAMatrixChartAllTopics
        groupedESRSTopics={groupedESRSTopics}
        height={height}
        onSelectTopic={onSelectTopic}
      />
    );
  }
};
