import { FC } from "react";
import { Avatar, SxProps, Theme } from "@mui/material";
import { NoUserIcon } from "../../common/constants/tabler-icon.constants";

interface INoUserAvatarProps {
  /** DEFAULT 30 */
  avatarDiameter?: number;
  sx?: SxProps<Theme>;
}

export const NoUserAvatar: FC<INoUserAvatarProps> = ({ avatarDiameter = 30, sx }) => {
  return (
    <Avatar
      sx={{
        height: avatarDiameter,
        width: avatarDiameter,
        fontSize: avatarDiameter / 2,
        p: 0.5,
        ...sx,
      }}
    >
      <NoUserIcon />
    </Avatar>
  );
};
