import {
  IBaseReferenceBudgetData,
  IUpdateReferenceBudgetRequest,
} from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import { getReferenceBudgetsQueryKey } from "../queries/reference-budgets.queries";
import { IOrganizationIdentity } from "../../organizations/organizations.mutations";

interface IReferenceBudgetData extends IOrganizationIdentity {
  payload: IBaseReferenceBudgetData;
}

interface IReferenceBudgetIdentity extends IOrganizationIdentity {
  referenceBudgetId: string;
}

type ICreateReferenceBudgetData = IReferenceBudgetData;

export const useCreateReferenceBudgetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, payload }: ICreateReferenceBudgetData) => {
      return CoreApiService.ReferenceBudgetsApi.createReferenceBudget(organizationId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getReferenceBudgetsQueryKey(variables.organizationId),
      });
    },
  });
};

interface IUpdateReferenceBudgetData extends IReferenceBudgetIdentity {
  payload: IUpdateReferenceBudgetRequest;
}

export const useUpdateReferenceBudgetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      referenceBudgetId,
      payload,
    }: IUpdateReferenceBudgetData) => {
      return CoreApiService.ReferenceBudgetsApi.updateReferenceBudget(
        organizationId,
        referenceBudgetId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getReferenceBudgetsQueryKey(variables.organizationId),
      });
    },
  });
};

type IDeleteReferenceBudgetData = IReferenceBudgetIdentity;

export const useDeleteReferenceBudgetMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, referenceBudgetId }: IDeleteReferenceBudgetData) => {
      return CoreApiService.ReferenceBudgetsApi.deleteReferenceBudget(
        organizationId,
        referenceBudgetId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getReferenceBudgetsQueryKey(variables.organizationId),
      });
    },
  });
};
