import {
  IDataEntryObjectBase,
  IDataEntryObjectBaseObjectTypeEnum,
} from "@netcero/netcero-core-api-client";

export class DataEntryObjectVerification {
  public static doesObjectTypeAllowBooleanChanges(
    type: IDataEntryObjectBaseObjectTypeEnum,
  ): boolean {
    return type === "organization" || type === "grouping";
  }

  public static canPartOfValueChainBeSet(
    operationalControl: IDataEntryObjectBase["operationalControl"],
  ) {
    return operationalControl === false;
  }
}
