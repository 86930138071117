import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForAction,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IAction,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
} from "@netcero/netcero-core-api-client";
import { useActionsQuery } from "../actions.queries";
import { ActionsTable } from "./actions-table.component";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { MdrInputHandler } from "../../minimum-disclosure-requirements-common/components/mdr-input-handler.component";

interface IActionsListProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForAction>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForAction) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  disabled?: boolean;
}

export const ActionsList: FC<IActionsListProps> = (props) => {
  const actionsQuery = useActionsQuery(props.organizationId, props.recordingPeriodId);

  return (
    <QueryWrapper
      query={actionsQuery}
      build={(actions) => <ActionsListInternal actions={actions} {...props} />}
    />
  );
};

interface IActionsListInternalProps extends IActionsListProps {
  actions: IAction[];
}

const ActionsListInternal: FC<IActionsListInternalProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  actions,
  value,
  onChange,
  topicIdentifier,
  disabled,
}) => {
  const filteredActions = useMemo(
    () => actions.filter((action) => action.associatedTopics.includes(topicIdentifier)),
    [actions, topicIdentifier],
  );

  const { t } = useTranslation("data_entry_object_action_component");

  return (
    <MdrInputHandler
      value={value}
      onChange={onChange}
      buttonPresentText={t("present_actions")}
      buttonNotPresentText={t("no_present_actions")}
      noticeChosenToNotDefine={t("notice_chosen_to_not_define_actions")}
      buttonChooseToAdoptText={t("actions_adopted")}
      disabled={disabled}
      mdrList={
        <ActionsTable
          actions={filteredActions}
          topicIdentifier={topicIdentifier}
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          dataEntryObjectId={dataEntryObjectId}
          rootDataEntryObjectId={rootDataEntryObjectId}
          onChange={onChange}
          disabled={disabled}
        />
      }
    />
  );
};
