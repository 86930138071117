/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { ICreateInputParameterRecordingStructure400Response } from "../models";
// @ts-ignore
import { ICreateInputParameterRecordingStructure403Response } from "../models";
// @ts-ignore
import { ICreateInputParameterRecordingStructure404Response } from "../models";
// @ts-ignore
import { ICreateInputParameterRecordingStructure409Response } from "../models";
// @ts-ignore
import { ICreateReferenceBudget404Response } from "../models";
// @ts-ignore
import { IGetAllInputParameterRecordingStructures200Response } from "../models";
// @ts-ignore
import { IGetAllInputParametersOfInputParameterRecordingStructure200Response } from "../models";
// @ts-ignore
import { IGetInputParameterRecordingStructureDetails404Response } from "../models";
// @ts-ignore
import { IInputParameterRecordingStructure } from "../models";
// @ts-ignore
import { IInputParameterRecordingStructureData } from "../models";
// @ts-ignore
import { IUpdateInputParameterRecordingStructure404Response } from "../models";
/**
 * InputParameterRecordingStructureApi - axios parameter creator
 * @export
 */
export const InputParameterRecordingStructureApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Creates a new input parameter recording structure for the given recording period.
     * @summary Create a new input parameter recording structure for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInputParameterRecordingStructure: async (
      organizationId: string,
      recordingPeriodId: string,
      iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createInputParameterRecordingStructure", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "createInputParameterRecordingStructure",
        "recordingPeriodId",
        recordingPeriodId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/input-parameter-recording-structures`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iInputParameterRecordingStructureData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes an existing input parameter recording structure.
     * @summary Deletes an existing input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInputParameterRecordingStructure: async (
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteInputParameterRecordingStructure", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "deleteInputParameterRecordingStructure",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'inputParameterRecordingStructureId' is not null or undefined
      assertParamExists(
        "deleteInputParameterRecordingStructure",
        "inputParameterRecordingStructureId",
        inputParameterRecordingStructureId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/input-parameter-recording-structures/{inputParameterRecordingStructureId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"inputParameterRecordingStructureId"}}`,
            encodeURIComponent(String(inputParameterRecordingStructureId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all input parameter recording structures for the given recording period.
     * @summary Get all input parameter recording structures for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInputParameterRecordingStructures: async (
      organizationId: string,
      recordingPeriodId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "getAllInputParameterRecordingStructures",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getAllInputParameterRecordingStructures",
        "recordingPeriodId",
        recordingPeriodId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/input-parameter-recording-structures`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all input parameters which are used in the given input parameter recording structure.
     * @summary Get all input parameters for the given input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInputParametersOfInputParameterRecordingStructure: async (
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "getAllInputParametersOfInputParameterRecordingStructure",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getAllInputParametersOfInputParameterRecordingStructure",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'inputParameterRecordingStructureId' is not null or undefined
      assertParamExists(
        "getAllInputParametersOfInputParameterRecordingStructure",
        "inputParameterRecordingStructureId",
        inputParameterRecordingStructureId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/input-parameter-recording-structures/{inputParameterRecordingStructureId}/input-parameters`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"inputParameterRecordingStructureId"}}`,
            encodeURIComponent(String(inputParameterRecordingStructureId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all details for the given input parameter recording structure.
     * @summary Get input parameter recording structure details.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInputParameterRecordingStructureDetails: async (
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "getInputParameterRecordingStructureDetails",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getInputParameterRecordingStructureDetails",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'inputParameterRecordingStructureId' is not null or undefined
      assertParamExists(
        "getInputParameterRecordingStructureDetails",
        "inputParameterRecordingStructureId",
        inputParameterRecordingStructureId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/input-parameter-recording-structures/{inputParameterRecordingStructureId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"inputParameterRecordingStructureId"}}`,
            encodeURIComponent(String(inputParameterRecordingStructureId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates an existing input parameter recording structure.
     * @summary Updates an existing input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInputParameterRecordingStructure: async (
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateInputParameterRecordingStructure", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateInputParameterRecordingStructure",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'inputParameterRecordingStructureId' is not null or undefined
      assertParamExists(
        "updateInputParameterRecordingStructure",
        "inputParameterRecordingStructureId",
        inputParameterRecordingStructureId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/input-parameter-recording-structures/{inputParameterRecordingStructureId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"inputParameterRecordingStructureId"}}`,
            encodeURIComponent(String(inputParameterRecordingStructureId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iInputParameterRecordingStructureData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InputParameterRecordingStructureApi - functional programming interface
 * @export
 */
export const InputParameterRecordingStructureApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    InputParameterRecordingStructureApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new input parameter recording structure for the given recording period.
     * @summary Create a new input parameter recording structure for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInputParameterRecordingStructure>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          iInputParameterRecordingStructureData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Deletes an existing input parameter recording structure.
     * @summary Deletes an existing input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all input parameter recording structures for the given recording period.
     * @summary Get all input parameter recording structures for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllInputParameterRecordingStructures(
      organizationId: string,
      recordingPeriodId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetAllInputParameterRecordingStructures200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllInputParameterRecordingStructures(
          organizationId,
          recordingPeriodId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all input parameters which are used in the given input parameter recording structure.
     * @summary Get all input parameters for the given input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllInputParametersOfInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetAllInputParametersOfInputParameterRecordingStructure200Response>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllInputParametersOfInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all details for the given input parameter recording structure.
     * @summary Get input parameter recording structure details.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInputParameterRecordingStructureDetails(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInputParameterRecordingStructure>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getInputParameterRecordingStructureDetails(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates an existing input parameter recording structure.
     * @summary Updates an existing input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IInputParameterRecordingStructure>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          iInputParameterRecordingStructureData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * InputParameterRecordingStructureApi - factory interface
 * @export
 */
export const InputParameterRecordingStructureApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InputParameterRecordingStructureApiFp(configuration);
  return {
    /**
     * Creates a new input parameter recording structure for the given recording period.
     * @summary Create a new input parameter recording structure for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
      options?: any,
    ): AxiosPromise<IInputParameterRecordingStructure> {
      return localVarFp
        .createInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          iInputParameterRecordingStructureData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes an existing input parameter recording structure.
     * @summary Deletes an existing input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all input parameter recording structures for the given recording period.
     * @summary Get all input parameter recording structures for the given recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInputParameterRecordingStructures(
      organizationId: string,
      recordingPeriodId: string,
      options?: any,
    ): AxiosPromise<IGetAllInputParameterRecordingStructures200Response> {
      return localVarFp
        .getAllInputParameterRecordingStructures(organizationId, recordingPeriodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all input parameters which are used in the given input parameter recording structure.
     * @summary Get all input parameters for the given input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllInputParametersOfInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options?: any,
    ): AxiosPromise<IGetAllInputParametersOfInputParameterRecordingStructure200Response> {
      return localVarFp
        .getAllInputParametersOfInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all details for the given input parameter recording structure.
     * @summary Get input parameter recording structure details.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInputParameterRecordingStructureDetails(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      options?: any,
    ): AxiosPromise<IInputParameterRecordingStructure> {
      return localVarFp
        .getInputParameterRecordingStructureDetails(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates an existing input parameter recording structure.
     * @summary Updates an existing input parameter recording structure.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
     * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInputParameterRecordingStructure(
      organizationId: string,
      recordingPeriodId: string,
      inputParameterRecordingStructureId: string,
      iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
      options?: any,
    ): AxiosPromise<IInputParameterRecordingStructure> {
      return localVarFp
        .updateInputParameterRecordingStructure(
          organizationId,
          recordingPeriodId,
          inputParameterRecordingStructureId,
          iInputParameterRecordingStructureData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * InputParameterRecordingStructureApi - interface
 * @export
 * @interface InputParameterRecordingStructureApi
 */
export interface InputParameterRecordingStructureApiInterface {
  /**
   * Creates a new input parameter recording structure for the given recording period.
   * @summary Create a new input parameter recording structure for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApiInterface
   */
  createInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IInputParameterRecordingStructure>;

  /**
   * Deletes an existing input parameter recording structure.
   * @summary Deletes an existing input parameter recording structure.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApiInterface
   */
  deleteInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Returns all input parameter recording structures for the given recording period.
   * @summary Get all input parameter recording structures for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApiInterface
   */
  getAllInputParameterRecordingStructures(
    organizationId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetAllInputParameterRecordingStructures200Response>;

  /**
   * Returns all input parameters which are used in the given input parameter recording structure.
   * @summary Get all input parameters for the given input parameter recording structure.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApiInterface
   */
  getAllInputParametersOfInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetAllInputParametersOfInputParameterRecordingStructure200Response>;

  /**
   * Returns all details for the given input parameter recording structure.
   * @summary Get input parameter recording structure details.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApiInterface
   */
  getInputParameterRecordingStructureDetails(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IInputParameterRecordingStructure>;

  /**
   * Updates an existing input parameter recording structure.
   * @summary Updates an existing input parameter recording structure.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApiInterface
   */
  updateInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IInputParameterRecordingStructure>;
}

/**
 * InputParameterRecordingStructureApi - object-oriented interface
 * @export
 * @class InputParameterRecordingStructureApi
 * @extends {BaseAPI}
 */
export class InputParameterRecordingStructureApi
  extends BaseAPI
  implements InputParameterRecordingStructureApiInterface
{
  /**
   * Creates a new input parameter recording structure for the given recording period.
   * @summary Create a new input parameter recording structure for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApi
   */
  public createInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
    options?: AxiosRequestConfig,
  ) {
    return InputParameterRecordingStructureApiFp(this.configuration)
      .createInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        iInputParameterRecordingStructureData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes an existing input parameter recording structure.
   * @summary Deletes an existing input parameter recording structure.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApi
   */
  public deleteInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    options?: AxiosRequestConfig,
  ) {
    return InputParameterRecordingStructureApiFp(this.configuration)
      .deleteInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all input parameter recording structures for the given recording period.
   * @summary Get all input parameter recording structures for the given recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApi
   */
  public getAllInputParameterRecordingStructures(
    organizationId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ) {
    return InputParameterRecordingStructureApiFp(this.configuration)
      .getAllInputParameterRecordingStructures(organizationId, recordingPeriodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all input parameters which are used in the given input parameter recording structure.
   * @summary Get all input parameters for the given input parameter recording structure.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApi
   */
  public getAllInputParametersOfInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    options?: AxiosRequestConfig,
  ) {
    return InputParameterRecordingStructureApiFp(this.configuration)
      .getAllInputParametersOfInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all details for the given input parameter recording structure.
   * @summary Get input parameter recording structure details.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApi
   */
  public getInputParameterRecordingStructureDetails(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    options?: AxiosRequestConfig,
  ) {
    return InputParameterRecordingStructureApiFp(this.configuration)
      .getInputParameterRecordingStructureDetails(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates an existing input parameter recording structure.
   * @summary Updates an existing input parameter recording structure.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} inputParameterRecordingStructureId The id of the input parameter recording structure to use.
   * @param {IInputParameterRecordingStructureData} [iInputParameterRecordingStructureData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InputParameterRecordingStructureApi
   */
  public updateInputParameterRecordingStructure(
    organizationId: string,
    recordingPeriodId: string,
    inputParameterRecordingStructureId: string,
    iInputParameterRecordingStructureData?: IInputParameterRecordingStructureData,
    options?: AxiosRequestConfig,
  ) {
    return InputParameterRecordingStructureApiFp(this.configuration)
      .updateInputParameterRecordingStructure(
        organizationId,
        recordingPeriodId,
        inputParameterRecordingStructureId,
        iInputParameterRecordingStructureData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
