import { FC } from "react";
import { Box, Divider } from "@mui/material";
import { AssociatedUsersDialogInputs } from "../../users/components/associated-users-dialog-inputs.component";
import { LinkedSourcesDialogInput } from "../../sources/components/linked-sources-dialog-input.component";

interface IMdrAssociatedUsersAndSourcesInputsProps {
  organizationId: string;
  disabled: boolean | undefined;
}

export const MdrAssociatedUsersAndSourcesInputs: FC<IMdrAssociatedUsersAndSourcesInputsProps> = ({
  organizationId,
  disabled,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={2} mb={2}>
      <AssociatedUsersDialogInputs organizationId={organizationId} disabled={disabled} />
      <LinkedSourcesDialogInput organizationId={organizationId} disabled={disabled} />
      <Divider sx={{ mx: -3 }} />
    </Box>
  );
};
