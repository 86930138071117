import { FC, useMemo } from "react";
import { Box, Divider } from "@mui/material";
import { IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement } from "../../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { FillInHelperIrosList } from "./fill-in-helper-iros-list.component";
import { IDataEntryObject, IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";

interface IFillInHelpersComponentProps {
  disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement;
  esrsTopicIdentifier: IESRSTopicIdentifier;
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObject: IDataEntryObject;
  dataEntryObjectId: string;
}

export const FillInHelpersComponent: FC<IFillInHelpersComponentProps> = ({
  disclosureRequirement,
  organizationId,
  recordingPeriodId,
  rootDataEntryObject,
  dataEntryObjectId,
  esrsTopicIdentifier,
}) => {
  // This is a preparation for more fill in helpers to be added in the future
  // TODO: Remove this comment after adding more fill in helpers (will be obvious then)
  const isAnyHelperShown = useMemo(
    () => disclosureRequirement.fillInHelpers.showIROList !== "none",
    [disclosureRequirement.fillInHelpers],
  );

  if (!isAnyHelperShown) {
    return null;
  }

  return (
    <Box>
      {disclosureRequirement.fillInHelpers.showIROList !== "none" && (
        <>
          <Divider />
          <Box p={2}>
            <FillInHelperIrosList
              type={disclosureRequirement.fillInHelpers.showIROList}
              esrsTopic={esrsTopicIdentifier}
              organizationId={organizationId}
              recordingPeriodId={recordingPeriodId}
              dataEntryObjectId={rootDataEntryObject.id} // Only show IROs for root DEO
              rootDataEntryObject={rootDataEntryObject}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
