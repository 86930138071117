/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IComment } from "../models";
// @ts-ignore
import { ICreateComment404Response } from "../models";
// @ts-ignore
import { ICreateCommentRequest } from "../models";
// @ts-ignore
import { IDeleteComment409Response } from "../models";
// @ts-ignore
import { IGetComments404Response } from "../models";
// @ts-ignore
import { IGetCommentsResponse } from "../models";
// @ts-ignore
import { ISortOrder } from "../models";
/**
 * CommentsApi - axios parameter creator
 * @export
 */
export const CommentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Used to create a new comment for the given entity
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ICreateCommentRequest} iCreateCommentRequest Used to create a new comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment: async (
      organizationId: string,
      iCreateCommentRequest: ICreateCommentRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createComment", "organizationId", organizationId);
      // verify required parameter 'iCreateCommentRequest' is not null or undefined
      assertParamExists("createComment", "iCreateCommentRequest", iCreateCommentRequest);
      const localVarPath = `/organizations/{organizationId}/comments`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iCreateCommentRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to delete a comment
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} commentId The id of the comment to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment: async (
      organizationId: string,
      commentId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteComment", "organizationId", organizationId);
      // verify required parameter 'commentId' is not null or undefined
      assertParamExists("deleteComment", "commentId", commentId);
      const localVarPath = `/organizations/{organizationId}/comments/{commentId}`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Used to fetch the comments for the given entity as specified by the query.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ISortOrder} sort Determines the order in which the comments are sorted. The &#x60;createdAt&#x60; property is used to compare comments with each other.
     * @param {string} query Determines the entity for which comments are fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComments: async (
      organizationId: string,
      sort: ISortOrder,
      query: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getComments", "organizationId", organizationId);
      // verify required parameter 'sort' is not null or undefined
      assertParamExists("getComments", "sort", sort);
      // verify required parameter 'query' is not null or undefined
      assertParamExists("getComments", "query", query);
      const localVarPath = `/organizations/{organizationId}/comments`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (sort !== undefined) {
        localVarQueryParameter["sort"] = sort;
      }

      if (query !== undefined) {
        localVarQueryParameter["query"] = query;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CommentsApi - functional programming interface
 * @export
 */
export const CommentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Used to create a new comment for the given entity
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ICreateCommentRequest} iCreateCommentRequest Used to create a new comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createComment(
      organizationId: string,
      iCreateCommentRequest: ICreateCommentRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IComment>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(
        organizationId,
        iCreateCommentRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to delete a comment
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} commentId The id of the comment to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteComment(
      organizationId: string,
      commentId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(
        organizationId,
        commentId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Used to fetch the comments for the given entity as specified by the query.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ISortOrder} sort Determines the order in which the comments are sorted. The &#x60;createdAt&#x60; property is used to compare comments with each other.
     * @param {string} query Determines the entity for which comments are fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getComments(
      organizationId: string,
      sort: ISortOrder,
      query: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetCommentsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getComments(
        organizationId,
        sort,
        query,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CommentsApi - factory interface
 * @export
 */
export const CommentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommentsApiFp(configuration);
  return {
    /**
     *
     * @summary Used to create a new comment for the given entity
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ICreateCommentRequest} iCreateCommentRequest Used to create a new comment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createComment(
      organizationId: string,
      iCreateCommentRequest: ICreateCommentRequest,
      options?: any,
    ): AxiosPromise<IComment> {
      return localVarFp
        .createComment(organizationId, iCreateCommentRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to delete a comment
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} commentId The id of the comment to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteComment(organizationId: string, commentId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteComment(organizationId, commentId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Used to fetch the comments for the given entity as specified by the query.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {ISortOrder} sort Determines the order in which the comments are sorted. The &#x60;createdAt&#x60; property is used to compare comments with each other.
     * @param {string} query Determines the entity for which comments are fetched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getComments(
      organizationId: string,
      sort: ISortOrder,
      query: string,
      options?: any,
    ): AxiosPromise<IGetCommentsResponse> {
      return localVarFp
        .getComments(organizationId, sort, query, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CommentsApi - interface
 * @export
 * @interface CommentsApi
 */
export interface CommentsApiInterface {
  /**
   *
   * @summary Used to create a new comment for the given entity
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {ICreateCommentRequest} iCreateCommentRequest Used to create a new comment.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApiInterface
   */
  createComment(
    organizationId: string,
    iCreateCommentRequest: ICreateCommentRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IComment>;

  /**
   *
   * @summary Used to delete a comment
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} commentId The id of the comment to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApiInterface
   */
  deleteComment(
    organizationId: string,
    commentId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   *
   * @summary Used to fetch the comments for the given entity as specified by the query.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {ISortOrder} sort Determines the order in which the comments are sorted. The &#x60;createdAt&#x60; property is used to compare comments with each other.
   * @param {string} query Determines the entity for which comments are fetched
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApiInterface
   */
  getComments(
    organizationId: string,
    sort: ISortOrder,
    query: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetCommentsResponse>;
}

/**
 * CommentsApi - object-oriented interface
 * @export
 * @class CommentsApi
 * @extends {BaseAPI}
 */
export class CommentsApi extends BaseAPI implements CommentsApiInterface {
  /**
   *
   * @summary Used to create a new comment for the given entity
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {ICreateCommentRequest} iCreateCommentRequest Used to create a new comment.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public createComment(
    organizationId: string,
    iCreateCommentRequest: ICreateCommentRequest,
    options?: AxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .createComment(organizationId, iCreateCommentRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to delete a comment
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} commentId The id of the comment to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public deleteComment(organizationId: string, commentId: string, options?: AxiosRequestConfig) {
    return CommentsApiFp(this.configuration)
      .deleteComment(organizationId, commentId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Used to fetch the comments for the given entity as specified by the query.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {ISortOrder} sort Determines the order in which the comments are sorted. The &#x60;createdAt&#x60; property is used to compare comments with each other.
   * @param {string} query Determines the entity for which comments are fetched
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommentsApi
   */
  public getComments(
    organizationId: string,
    sort: ISortOrder,
    query: string,
    options?: AxiosRequestConfig,
  ) {
    return CommentsApiFp(this.configuration)
      .getComments(organizationId, sort, query, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
