import { Typography, Box, Button } from "@mui/material";
import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ConfigurationOverviewSectionContainer } from "../../modules/configuration/common/configuration-overview-section-container.component";

interface IConfigurationSectionProps {
  title: string;
  description: string;
  url: string;
  buttonLabel: string;
}

export const ConfigurationSection: FC<IConfigurationSectionProps> = ({
  title,
  description,
  url,
  buttonLabel,
}) => {
  return (
    <ConfigurationOverviewSectionContainer>
      <Typography variant="h3" component="h2">
        {title}
      </Typography>
      <Box display="flex" gap={2} mt={2}>
        <Typography flex={1} mt={0.5}>
          {description}
        </Typography>
        <Button variant="contained" to={url} component={RouterLink}>
          {buttonLabel}
        </Button>
      </Box>
    </ConfigurationOverviewSectionContainer>
  );
};
