import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@mui/material";
import {
  IBaseTargetPathDataWithoutReferenceBudget,
  ITargetPath,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { SingleClickTextFieldComponent } from "../../common/inputs/single-click-textfield.component";
import {
  DialogCancelButton,
  DialogCreateButton,
} from "../../common/dialogs/dialog-button.components";

const getFormDefaultValues = (
  targetPath: ITargetPath,
): IBaseTargetPathDataWithoutReferenceBudget => {
  return {
    name: `${targetPath.name} - Kopie`,
  };
};

interface ITargetPathCloneDialogProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseTargetPathDataWithoutReferenceBudget | null) => void;
  targetPath: ITargetPath;
  // Used to enforce name unique constraint
  allTargetPaths: ITargetPath[];
}

export const TargetPathCloneDialog: FC<ITargetPathCloneDialogProps> = ({
  targetPath,
  disabled,
  onClose,
  error,
  open,
  loading,
  allTargetPaths,
}) => {
  const { t } = useTranslation("target_path_clone_dialog");

  const {
    control,
    formState: { isDirty, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<IBaseTargetPathDataWithoutReferenceBudget>({
    defaultValues: getFormDefaultValues(targetPath),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(targetPath));
    }
  }, [open, reset, targetPath]);

  const handleEmitData = (data: IBaseTargetPathDataWithoutReferenceBudget) => {
    const payload: IBaseTargetPathDataWithoutReferenceBudget = {
      name: data.name.trim(),
    };
    onClose(payload);
  };

  return (
    <Dialog open={open} onClose={!isDirty ? () => onClose(null) : undefined} fullWidth>
      <DialogTitle>{t("title")}</DialogTitle>
      {loading && <LinearProgress />}
      <DialogContent>
        {error && <ErrorTextComponent error={error} />}
        <Box display="flex" flexDirection="column" gap={2} mt={1}>
          {/* Name Input */}
          <Controller
            control={control}
            name="name"
            rules={{
              required: t("errors.name_required"),
              validate: (value) => {
                if (allTargetPaths.find((t) => t.name === value)) {
                  return t("errors.name_unique");
                }
                return undefined;
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <SingleClickTextFieldComponent
                label={t("labels.name")}
                {...field}
                error={!!error}
                helperText={error?.message}
                disabled={disabled}
                disableAutoSelect={!!dirtyFields.name}
              />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
          {t(isDirty ? "cancel" : "close", { ns: "buttons" })}
        </DialogCancelButton>
        <DialogCreateButton onClick={handleSubmit(handleEmitData)} disabled={disabled}>
          {t("create", { ns: "buttons" })}
        </DialogCreateButton>
      </DialogActions>
    </Dialog>
  );
};
