import { useEffect, useState } from "react";
import i18n from "i18next";
import { IContentLanguage } from "@netcero/netcero-core-api-client";

export const useCurrentLanguage = () => {
  const [language, setLanguage] = useState<IContentLanguage>(i18n.language as IContentLanguage);

  // track changes to language
  useEffect(() => {
    i18n.on("languageChanged", (newLanguage) => {
      setLanguage(newLanguage as IContentLanguage);
    });
  }, []);

  return language;
};
