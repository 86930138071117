import { ICountryCodeAlpha2Enum } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ICountrySelectBaseComponentProps {
  label?: string;
  fullWidth?: boolean;
}

interface ICountrySelectComponentPropsNullable extends ICountrySelectBaseComponentProps {
  type: "nullable";
  value: ICountryCodeAlpha2Enum | null;
  onValueChange: (value: ICountryCodeAlpha2Enum | null) => void;
}

interface ICountrySelectComponentPropsNotNullable extends ICountrySelectBaseComponentProps {
  type: "not-nullable";
  value: ICountryCodeAlpha2Enum;
  onValueChange: (value: ICountryCodeAlpha2Enum) => void;
}

export type ICountrySelectComponentProps =
  | ICountrySelectComponentPropsNullable
  | ICountrySelectComponentPropsNotNullable;

export const CountrySelectComponent: FC<ICountrySelectComponentProps> = ({
  type,
  onValueChange,
  value,
  label,
  fullWidth,
}) => {
  const { t } = useTranslation("common", { keyPrefix: "countries" });
  return (
    <Autocomplete
      value={value}
      disableClearable={type === "not-nullable"}
      fullWidth={fullWidth}
      renderInput={(params) => (
        <TextField
          {...params}
          required={type === "not-nullable"}
          label={label}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <Box
                component="img"
                loading="lazy"
                width="20"
                src={`/flags/${value?.toLowerCase()}.png`}
                alt=""
                px={1}
              />
            ),
          }}
        />
      )}
      options={Object.values(ICountryCodeAlpha2Enum)}
      autoHighlight
      getOptionLabel={(option) => t(option)}
      renderOption={(props, option) => (
        <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
          <img loading="lazy" width="20" src={`/flags/${option.toLowerCase()}.png`} alt="" />
          {t(option)}
        </Box>
      )}
      onChange={(event, value) => {
        if (value === null && type === "nullable") {
          onValueChange(null);
          return;
        }

        onValueChange(value as ICountryCodeAlpha2Enum);
      }}
    />
  );
};
