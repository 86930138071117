import { ITargetPathGoalForVerification } from "./target-path-goals.types";

export class TargetPathGoalsVerification {
  public static hasGoalWithSameYear(
    goals: ITargetPathGoalForVerification[],
    year: number,
    skip?: string,
  ) {
    return !!goals.find((goal) => goal.targetYear === year && goal.id !== skip);
  }
}
