import {
  IAbsoluteOrRelativeTargetEnum,
  IGreenhouseGasesCoveredByEmissionReductionTargetEnum,
  ILayerInMitigationHierarchyForBiodiversityEcosystemsEnum,
  ILayerInWasteHierarchyEnum,
  IListOfMetricsEnum,
  ITargetDataE1,
  ITargetDataE2,
  ITargetDataE2ToE5,
  ITargetDataE3,
  ITargetDataE4,
  ITargetDataE5,
  ITargetDataGeneral,
  ITargetDataSDetails,
  ITargetDataSS1,
  ITargetDataSS2,
  ITargetDataSS3,
  ITargetDataSS4,
  ITargetRelationshipToImpactsRisksAndOpportunitiesEnum,
} from "@netcero/netcero-core-api-client";
import { ISpecialTopicSectionInputIdentifier } from "../minimum-disclosure-requirements-common/components/mdr-topic-section.component";
import { IMDRInput } from "../minimum-disclosure-requirements-common/mdr-input.types";
import { MdrInputUtilities } from "../minimum-disclosure-requirements-common/mdr-input.utilities";
import { TargetEDataOnly, TargetSDataOnly } from "./targets-app.utilities";

type GENERAL_KEY = keyof ITargetDataGeneral;
export const GENERAL_ORDER: (GENERAL_KEY | ISpecialTopicSectionInputIdentifier)[] = [
  "name",
  "disclosureOfMeasurableOutcomeorientedAndTimeboundTargetExplanatory",
  "iros",
  "policies",
  "actions",
  "measurableTargetAbsoluteValue",
  "measurableTargetPercentage",
  "absoluteOrRelativeTarget",
  "esrsMetricsAndMonetaryAmountsUsedForTarget",
  "descriptionOfScopeOfTargetExplanatory",
  "baselineValueOfMeasurableTargetAbsoluteValue",
  "baseYearFromWhichProgressIsMeasured",
  "periodToWhichTargetAppliesStartYear",
  "periodToWhichTargetAppliesEndYear",
  "descriptionOfMethodologiesAndSignificantAssumptionsUsedToDefineTargetExplanatory",
  "stakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatter",
  "disclosureOfHowStakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatterExplanatory",
  "descriptionOfAnyChangesInTargetAndCorrespondingMetricsOrUnderlyingMeasurementMethodologiesSignificantAssumptionsLimitationsSourcesAndAdoptedProcessesToCollectDataExplanatory",
  "descriptionOfPerformanceAgainstDisclosedTargetExplanatory",
  "measurableTargetCurrentProgressAbsoluteValue",
  "measurableTargetCurrentProgressPercentage",
  "progressIsInLineWithWhatHadBeenInitiallyPlanned",
];
export const GENERAL: Record<GENERAL_KEY, IMDRInput> = {
  name: MdrInputUtilities.TITLE_INPUT_CONFIGURATION,
  disclosureOfMeasurableOutcomeorientedAndTimeboundTargetExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  measurableTargetAbsoluteValue: MdrInputUtilities.NUMBER_WITH_UNIT_INPUT_CONFIGURATION,
  measurableTargetPercentage: {
    type: "number",
    numberType: "percentage",
    initialValue: null,
  },
  absoluteOrRelativeTarget: {
    type: "enum",
    mode: "single",
    initialValue: null,
    values: Object.values(IAbsoluteOrRelativeTargetEnum),
  },
  esrsMetricsAndMonetaryAmountsUsedForTarget: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IListOfMetricsEnum),
  },
  descriptionOfScopeOfTargetExplanatory: MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  baselineValueOfMeasurableTargetAbsoluteValue:
    MdrInputUtilities.NUMBER_WITH_UNIT_INPUT_CONFIGURATION,
  baseYearFromWhichProgressIsMeasured: {
    type: "year",
    initialValue: null,
  },
  periodToWhichTargetAppliesStartYear: {
    type: "year",
    initialValue: null,
  },
  periodToWhichTargetAppliesEndYear: {
    type: "year",
    initialValue: null,
  },
  descriptionOfMethodologiesAndSignificantAssumptionsUsedToDefineTargetExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  stakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatter:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  disclosureOfHowStakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatterExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  descriptionOfAnyChangesInTargetAndCorrespondingMetricsOrUnderlyingMeasurementMethodologiesSignificantAssumptionsLimitationsSourcesAndAdoptedProcessesToCollectDataExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  descriptionOfPerformanceAgainstDisclosedTargetExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  measurableTargetCurrentProgressAbsoluteValue:
    MdrInputUtilities.NUMBER_WITH_UNIT_INPUT_CONFIGURATION,
  measurableTargetCurrentProgressPercentage: {
    type: "number",
    numberType: "percentage",
    initialValue: null,
  },
  progressIsInLineWithWhatHadBeenInitiallyPlanned: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
};

type E_KEY = keyof TargetEDataOnly;
export const E_ORDER: E_KEY[] = [
  "targetRelatedToEnvironmentalMattersIsBasedOnConclusiveScientificEvidence",
];
export const E: Record<E_KEY, IMDRInput> = {
  targetRelatedToEnvironmentalMattersIsBasedOnConclusiveScientificEvidence:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
};

type E1_KEY = keyof ITargetDataE1;
export const E1_ORDER: E1_KEY[] = [
  "addressedMatters",
  "descriptionOfHowItHasBeenEnsuredThatBaselineValueIsRepresentativeInTermsOfActivitiesCoveredAndInfluencesFromExternalFactorsExplanatory",
  "descriptionOfHowNewBaselineValueAffectsNewTargetItsAchievementAndPresentationOfProgressOverTimeExplanatory",
  "disclosureOfPastProgressMadeInMeetingTargetBeforeCurrentBaseYearExplanatory",
  "targetIsDerivedUsingSectoralDecarbonisationPathway",
  "disclosureOfFrameworkAndMethodologyThatHasBeenUsedToDetermineGHGEmissionReductionTargetUnderlyingClimateAndPolicyScenariosForTargetHowFutureDevelopmentsHaveBeenConsideredAndHowTheseWillPotentiallyImpactGHGEmissionsAndEmissionsReductionsExplanatory",
  "explanationOfHowTargetIsCompatibleWithLimitingOfGlobalWarmingToOneAndHalfDegreesCelsiusInLineWithParisAgreementExplanatory",
  "ghgEmissionReductionTargetIsScienceBasedAndCompatibleWithLimitingGlobalWarmingToOneAndHalfDegreesCelsius",
  "targetHasBeenExternallyAssured",
  "descriptionOfExpectedDecarbonisationLeversAndTheirOverallQuantitativeContributionsToAchieveGHGEmissionReductionTargetExplanatory",
  "newTechnologiesArePlannedToBeAdoptedToAchieveGHGEmissionReductionTarget",
  "diverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLevers",
  "disclosureOfHowDiverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLeversExplanatory",
  "explanationOfHowConsistencyOfGHGEmissionReductionTargetWithGHGInventoryBoundariesHasBeenEnsuredExplanatory",
  "greenhouseGasesCoveredByEmissionReductionTarget",
];
export const E1: Record<E1_KEY, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e1,
  descriptionOfHowItHasBeenEnsuredThatBaselineValueIsRepresentativeInTermsOfActivitiesCoveredAndInfluencesFromExternalFactorsExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  descriptionOfHowNewBaselineValueAffectsNewTargetItsAchievementAndPresentationOfProgressOverTimeExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  disclosureOfPastProgressMadeInMeetingTargetBeforeCurrentBaseYearExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  targetIsDerivedUsingSectoralDecarbonisationPathway: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  disclosureOfFrameworkAndMethodologyThatHasBeenUsedToDetermineGHGEmissionReductionTargetUnderlyingClimateAndPolicyScenariosForTargetHowFutureDevelopmentsHaveBeenConsideredAndHowTheseWillPotentiallyImpactGHGEmissionsAndEmissionsReductionsExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  explanationOfHowTargetIsCompatibleWithLimitingOfGlobalWarmingToOneAndHalfDegreesCelsiusInLineWithParisAgreementExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  ghgEmissionReductionTargetIsScienceBasedAndCompatibleWithLimitingGlobalWarmingToOneAndHalfDegreesCelsius:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetHasBeenExternallyAssured: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  descriptionOfExpectedDecarbonisationLeversAndTheirOverallQuantitativeContributionsToAchieveGHGEmissionReductionTargetExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  newTechnologiesArePlannedToBeAdoptedToAchieveGHGEmissionReductionTarget:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  diverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLevers:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  disclosureOfHowDiverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLeversExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  explanationOfHowConsistencyOfGHGEmissionReductionTargetWithGHGInventoryBoundariesHasBeenEnsuredExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  greenhouseGasesCoveredByEmissionReductionTarget: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IGreenhouseGasesCoveredByEmissionReductionTargetEnum),
  },
};

type E2_TO_E5_KEY = keyof ITargetDataE2ToE5;
export const E2_TO_E5_ORDER: E2_TO_E5_KEY[] = [
  "ecologicalThresholdsAndOrEntityspecificAllocationsWereTakenIntoConsiderationWhenSettingTarget",
  "disclosureOfEcologicalThresholdsIdentifiedAndMethodologyUsedToIdentifyEcologicalThresholdsExplanatory",
  "ecologicalThresholdsAreEntityspecific",
  "disclosureOfHowEcologicalEntityspecificThresholdsWereDeterminedExplanatory",
  "disclosureOfHowResponsibilityForRespectingIdentifiedEcologicalThresholdsIsAllocatedExplanatory",
];
export const E2_TO_E5: Record<E2_TO_E5_KEY, IMDRInput> = {
  ecologicalThresholdsAndOrEntityspecificAllocationsWereTakenIntoConsiderationWhenSettingTarget:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  disclosureOfEcologicalThresholdsIdentifiedAndMethodologyUsedToIdentifyEcologicalThresholdsExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  ecologicalThresholdsAreEntityspecific: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  disclosureOfHowEcologicalEntityspecificThresholdsWereDeterminedExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  disclosureOfHowResponsibilityForRespectingIdentifiedEcologicalThresholdsIsAllocatedExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
};

type E2_KEY = keyof ITargetDataE2;
export const E2_ORDER: E2_KEY[] = [
  "addressedMatters",
  "targetRelatesToPreventionAndControlOfAirPollutantsAndRespectiveSpecificLoads",
  "targetRelatesToEitherPreventionOrControlOfEmissionsToWaterAndOrToRespectiveSpecificLoads",
  "targetRelatesToEitherPreventionOrControlOfPollutionToSoilAndOrToRespectiveSpecificLoads",
  "targetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcern",
  "disclosureOfHowTargetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcernExplanatory",
  "targetIsMandatoryRequiredByLegislation",
  "targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForPollutionPreventionAndControl",
  "targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForPollutionPreventionAndControl",
];
export const E2: Record<E2_KEY, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e2,
  targetRelatesToPreventionAndControlOfAirPollutantsAndRespectiveSpecificLoads:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToEitherPreventionOrControlOfEmissionsToWaterAndOrToRespectiveSpecificLoads:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToEitherPreventionOrControlOfPollutionToSoilAndOrToRespectiveSpecificLoads:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcern:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  disclosureOfHowTargetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcernExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  targetIsMandatoryRequiredByLegislation: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForPollutionPreventionAndControl:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForPollutionPreventionAndControl:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
};

type E3_KEY = keyof ITargetDataE3;
export const E3_ORDER: E3_KEY[] = [
  "addressedMatters",
  "targetRelatesToManagementOfMaterialImpactsRisksAndOpportunitiesRelatedToAreasAtWaterRiskIncludingImprovementOfWaterQuality",
  "targetRelatesToResponsibleManagementOfMarineResourcesImpactsRisksAndOpportunitiesIncludingNatureAndOrQuantityOfMarineResourcesrelatedCommoditiesUsedByUndertaking",
  "targetRelatesToReductionOfWaterConsumptionIncludingInAreasAtWaterRiskAndOrInAreasOfHighWaterstress",
  "targetRelatesToReductionOfWaterWithdrawals",
  "targetRelatesToReductionOfWaterDischarges",
  "targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForWaterAndMarineResources",
  "targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForWaterAndMarineResources",
];
export const E3: Record<E3_KEY, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e3,
  targetRelatesToManagementOfMaterialImpactsRisksAndOpportunitiesRelatedToAreasAtWaterRiskIncludingImprovementOfWaterQuality:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToResponsibleManagementOfMarineResourcesImpactsRisksAndOpportunitiesIncludingNatureAndOrQuantityOfMarineResourcesrelatedCommoditiesUsedByUndertaking:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToReductionOfWaterConsumptionIncludingInAreasAtWaterRiskAndOrInAreasOfHighWaterstress:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToReductionOfWaterWithdrawals: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToReductionOfWaterDischarges: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForWaterAndMarineResources:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForWaterAndMarineResources:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
};

type E4_KEY = keyof ITargetDataE4;
export const E4_ORDER: E4_KEY[] = [
  "addressedMatters",
  "targetIsInformedByAndOrAlignedWithKunmingmontrealGlobalBiodiversityFramework",
  "targetIsInformedByAndOrAlignedWithRelevantAspectsOfEuBiodiversityStrategyFor2030",
  "targetIsInformedByAndOrAlignedWithOtherBiodiversityAndEcosystemrelatedNationalPoliciesAndLegislation",
  "biodiversityOffsetsWereUsedInSettingTarget",
  "layersInMitigationHierarchyToWhichTargetCanBeAllocatedBiodiversityAndEcosystems",
  "targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForBiodiversity",
  "targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForBiodiversity",
];
export const E4: Record<E4_KEY, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e4,
  targetIsInformedByAndOrAlignedWithKunmingmontrealGlobalBiodiversityFramework:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetIsInformedByAndOrAlignedWithRelevantAspectsOfEuBiodiversityStrategyFor2030:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetIsInformedByAndOrAlignedWithOtherBiodiversityAndEcosystemrelatedNationalPoliciesAndLegislation:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  biodiversityOffsetsWereUsedInSettingTarget: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  layersInMitigationHierarchyToWhichTargetCanBeAllocatedBiodiversityAndEcosystems: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForBiodiversityEcosystemsEnum),
  },
  targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForBiodiversity:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForBiodiversity:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
};

type E5_KEY = keyof ITargetDataE5;
export const E5_ORDER: E5_KEY[] = [
  "addressedMatters",
  "targetRelatesToIncreaseOfCircularProductDesign",
  "targetRelatesToIncreaseOfCircularMaterialUseRate",
  "targetRelatesToMinimisationOfPrimaryRawMaterial",
  "targetRelatesToSustainableSourcingAndOrUseOfRenewableResources",
  "targetRelatesToWasteManagement",
  "layersInWasteHierarchyToWhichTargetRelates",
  "targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForCircularEconomy",
  "targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForCircularEconomy",
];
export const E5: Record<E5_KEY, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e5,
  targetRelatesToIncreaseOfCircularProductDesign: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToIncreaseOfCircularMaterialUseRate: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToMinimisationOfPrimaryRawMaterial: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToSustainableSourcingAndOrUseOfRenewableResources:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetRelatesToWasteManagement: MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  layersInWasteHierarchyToWhichTargetRelates: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInWasteHierarchyEnum),
  },
  targetAddressesShortcomingsRelatedToSubstantialContributionCriteriaForCircularEconomy:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
  targetAddressesShortcomingsRelatedToDoNoSignificantHarmCriteriaForCircularEconomy:
    MdrInputUtilities.BOOLEAN_INPUT_CONFIGURATION,
};

type S_KEY = keyof TargetSDataOnly;
export const S_ORDER: S_KEY[] = ["targetRelationshipToImpactsRisksAndOpportunities"];
export const S: Record<S_KEY, IMDRInput> = {
  targetRelationshipToImpactsRisksAndOpportunities: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ITargetRelationshipToImpactsRisksAndOpportunitiesEnum),
  },
};

type S_COMMON_KEY = keyof ITargetDataSDetails;
export const S_COMMON_ORDER: (S_COMMON_KEY | "addressedMatters")[] = [
  "addressedMatters",
  "disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory",
  "disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory",
  "disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory",
];
export const S_COMMON: Record<S_COMMON_KEY, IMDRInput> = {
  disclosureOfIntendedOutcomesToBeAchievedInLivesOfPeopleInOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusersExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  disclosureOfStabilityOfTargetOverTimeInTermsOfDefinitionsAndMethodologiesToEnableComparabilityExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
  disclosureOfStandardsOrCommitmentsOnWhichTargetIsBasedExplanatory:
    MdrInputUtilities.TEXT_AREA_INPUT_CONFIGURATION,
};

export const S1: Record<keyof ITargetDataSS1, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s1,
  ...S_COMMON,
};

export const S2: Record<keyof ITargetDataSS2, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s2,
  ...S_COMMON,
};

export const S3: Record<keyof ITargetDataSS3, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s3,
  ...S_COMMON,
};

export const S4: Record<keyof ITargetDataSS4, IMDRInput> = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s4,
  ...S_COMMON,
};

export const ALL_SECTIONS_ORDER = {
  general: GENERAL_ORDER,
  e: E_ORDER,
  e1: E1_ORDER,
  e2: E2_ORDER,
  e3: E3_ORDER,
  e4: E4_ORDER,
  e5: E5_ORDER,
  e2ToE5: E2_TO_E5_ORDER,
  s: S_ORDER,
  s1: S_COMMON_ORDER,
  s2: S_COMMON_ORDER,
  s3: S_COMMON_ORDER,
  s4: S_COMMON_ORDER,
};
export const ALL_SECTIONS_CONFIG = {
  general: GENERAL,
  e: E,
  e1: E1,
  e2: E2,
  e3: E3,
  e4: E4,
  e5: E5,
  e2ToE5: E2_TO_E5,
  s: S,
  s1: S1,
  s2: S2,
  s3: S3,
  s4: S4,
};
