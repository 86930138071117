import { FC } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { ESRSTopicUtilities } from "@netcero/netcero-common";
import { IInputParameterValueMetaDataEsrsTopicIdentifierEnum } from "@netcero/netcero-core-api-client";
import { useRenderESRSTopicNameForIdentifier } from "../../double-materiality-assessment/hooks/render-esrs-topic-name-for-identifier.hook";

interface IMdrTopicBreadcrumbProps {
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
}

export const MdrTopicBreadcrumb: FC<IMdrTopicBreadcrumbProps> = ({ topicIdentifier }) => {
  const renderTopicName = useRenderESRSTopicNameForIdentifier();

  return (
    <Breadcrumbs sx={{ mb: 1 }}>
      <Typography variant="inherit">{`${ESRSTopicUtilities.convertTopicToDisplayTopic(
        topicIdentifier,
      )} - ${renderTopicName(topicIdentifier)}`}</Typography>
    </Breadcrumbs>
  );
};
