import { FC, useCallback, useMemo } from "react";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import { ILocalRecordingPeriod } from "../../../recording-periods/recording-periods.utilities";
import {
  IDataEntryObjectInputParameterStatus,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";
import { DrValueEditorDirect } from "./dr-value-editor-direct.component";
import { DrValueEditorTable } from "./dr-value-editor-table.component";
import { IManageDataEntryObjectTableValueHandleSaveCallback } from "../../input-components/table/manage-data-entry-object-table-value.hook";
import { useDialogState } from "../../../common/dialogs/dialog-state.hook";
import { ConfirmDialog } from "../../../common/dialogs/variants/confirm.dialog";
import { useTranslation } from "react-i18next";

const STATUS_REQUIRING_VALUE_CHANGE_CONFIRMATION: IDataEntryObjectInputParameterStatus[] = [
  IDataEntryObjectInputParameterStatus.Done,
];

interface IConfirmDialogData {
  confirm: VoidFunction;
  cancel: VoidFunction;
}

export interface IDrValueEditProps {
  deoInputParameter: ILocalDataEntryObjectInputParameter;
  organizationId: string;
  recordingPeriod: ILocalRecordingPeriod;
  rootDataEntryObjectId: string;
  dataEntryObjectId: string;
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  onCreate: (data: ILocalDataEntryObjectInputParameterValueData) => Promise<void>;
  onUpdate: (valueId: string, data: ILocalDataEntryObjectInputParameterValueData) => Promise<void>;
  onDelete: (valueId: string) => Promise<void>;
  onTableUpdate: IManageDataEntryObjectTableValueHandleSaveCallback;
  disabled?: boolean;
}

export const DrValueEditor: FC<IDrValueEditProps> = ({
  deoInputParameter,
  onCreate,
  onUpdate,
  onDelete,
  onTableUpdate,
  ...props
}) => {
  const { t } = useTranslation("data_entry_object_values_overview_esrs_component");

  const {
    isOpen: showConfirmDialog,
    data: confirmActionCallbacks,
    openDialog: openConfirmDialog,
    closeDialog: closeConfirmDialog,
  } = useDialogState<IConfirmDialogData>();

  const InputComponent = useMemo(() => {
    switch (deoInputParameter.inputParameter.uiConfiguration.displayType) {
      case "direct":
        return DrValueEditorDirect;
      case "table":
        return DrValueEditorTable;
    }

    return null;
  }, [deoInputParameter.inputParameter.uiConfiguration.displayType]);

  const handleChangeEvents = useCallback(
    (changeAction: () => Promise<void>) => {
      if (STATUS_REQUIRING_VALUE_CHANGE_CONFIRMATION.includes(deoInputParameter.status)) {
        // Show Confirm Dialog
        return new Promise<void>((resolve, reject) => {
          // Open Confirm Dialog
          openConfirmDialog({
            confirm: async () => resolve(await changeAction()),
            cancel: () => reject("User cancelled"),
          });
        });
      } else {
        return changeAction();
      }
    },
    [deoInputParameter.status, openConfirmDialog],
  );

  const handleCloseConfirmDialog = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        confirmActionCallbacks?.confirm();
      } else {
        confirmActionCallbacks?.cancel();
      }
      closeConfirmDialog();
    },
    [confirmActionCallbacks, closeConfirmDialog],
  );

  return (
    <>
      {/* Confirm Change Dialog */}
      <ConfirmDialog
        open={showConfirmDialog}
        onClose={handleCloseConfirmDialog}
        title={t("update_valid_confirm_state_change_dialog.title")}
        content={t("update_valid_confirm_state_change_dialog.content")}
      />

      {/* Input Component */}
      {InputComponent ? (
        <InputComponent
          deoInputParameter={deoInputParameter}
          onCreate={(data) => handleChangeEvents(() => onCreate(data))}
          onUpdate={(valueId, data) => handleChangeEvents(() => onUpdate(valueId, data))}
          onDelete={(valueId) => handleChangeEvents(() => onDelete(valueId))}
          onTableUpdate={(created, updated, deleted) =>
            handleChangeEvents(() => onTableUpdate(created, updated, deleted))
          }
          {...props}
        />
      ) : (
        "ERROR: Unsupported display type"
      )}
    </>
  );
};
