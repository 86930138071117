import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecordingPeriodRecordingStructuresQuery } from "../../modules/recording-structures/recording-structures.queries";
import {
  IGetAllInputParameterRecordingStructures200Response,
  IIntercomEntity,
} from "@netcero/netcero-core-api-client";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { GapAnalysisTopicsOverview } from "../../modules/gap-analysis/components/gap-analysis-topics-overview.component";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { Box } from "@mui/material";
import { useIntercomReferencesForEntityQuery } from "../../modules/intercom-references/intercom-references.queries";
import {
  IIntercomReferencesContextState,
  IntercomReferencesProvider,
} from "../../modules/intercom-references/intercom-references.context";
import { InputParameterRecordingStructuresUtilities } from "../../modules/input-parameter-recording-structures/input-parameter-recording-structures.utilities";

export function getGapAnalysisPageUrl(
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) {
  return (
    `/organizations/${organizationId}` +
    `/recording-periods/${recordingPeriodId}` +
    `/gap-analysis/data-entry-objects/${dataEntryObjectId}`
  );
}

function selectEsrsStructuresOnly(data: IGetAllInputParameterRecordingStructures200Response) {
  return data.recordingStructures
    .filter(InputParameterRecordingStructuresUtilities.isEsrsRecordingStructureByIdentifier)
    .sort(InputParameterRecordingStructuresUtilities.compareRecordingStructures);
}

export const GapAnalysisPage: FC = () => {
  const { organizationId, recordingPeriodId, dataEntryObjectId } = useParams();

  // Setup Header & Sidebar

  const currentOrganization = useCurrentOrganization();

  const navigate = useNavigate();
  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: currentOrganization
        ? {
            organizationId: currentOrganization.id,
            onChange: (organizationId) => {
              navigate(`/organizations/${organizationId}`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [currentOrganization, organizationId, sideBarOrganizationNavItems, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const recordingStructuresQuery = useRecordingPeriodRecordingStructuresQuery(
    organizationId!,
    recordingPeriodId!,
    selectEsrsStructuresOnly,
  );

  const disclosureRequirementsIntercomReferencesQuery = useIntercomReferencesForEntityQuery(
    IIntercomEntity.DisclosureRequirement,
  );
  const intercomReferencesState: IIntercomReferencesContextState = useMemo(
    () => ({
      [IIntercomEntity.DisclosureRequirement]: disclosureRequirementsIntercomReferencesQuery.data,
    }),
    [disclosureRequirementsIntercomReferencesQuery.data],
  );

  return (
    <IntercomReferencesProvider state={intercomReferencesState}>
      <QueryWrapper
        query={recordingStructuresQuery}
        centerLoader
        build={(recordingStructures) => (
          <Box p={2} display="flex" justifyContent="center">
            <GapAnalysisTopicsOverview
              organizationId={organizationId!}
              recordingPeriodId={recordingPeriodId!}
              dataEntryObjectId={dataEntryObjectId!}
              esrsRecordingStructures={recordingStructures}
            />
          </Box>
        )}
      />
    </IntercomReferencesProvider>
  );
};
