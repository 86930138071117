import { FC, MutableRefObject, useEffect } from "react";
import { SerializedEditorState } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { LEXICAL_EMPTY_EDITOR_STATE } from "../lexical.constants";

interface ISubscribeToContentProps {
  content: SerializedEditorState | undefined | null;
  resetRef?: MutableRefObject<VoidFunction>;
}

export const SubscribeToContent: FC<ISubscribeToContentProps> = ({ content, resetRef }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (resetRef) {
      resetRef.current = () => {
        editor.setEditorState(editor.parseEditorState(content ?? LEXICAL_EMPTY_EDITOR_STATE));
        editor.setEditable(false);
      };
    }
  }, [content, editor, resetRef]);

  useEffect(() => {
    if (content) {
      editor.setEditorState(editor.parseEditorState(content));
    }
  }, [editor, content]);

  return null;
};
