import { FC } from "react";
import { LinearProgress } from "@mui/material";

interface IDialogLoadingIndicatorProps {
  loading: boolean;
}

export const DialogLoadingIndicator: FC<IDialogLoadingIndicatorProps> = ({ loading }) => {
  return loading && <LinearProgress />;
};
