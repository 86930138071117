import {
  IDMACategoryWithEffectsAndFeedbacksAndChildren,
  IStakeholderFeedbackDetails,
} from "@netcero/netcero-core-api-client";
import { ITableOfContentsEntry } from "../../common/components/table-of-contents.component";
import { useCallback, useMemo } from "react";
import { useRenderESRSTopicNameForIdentifier } from "../../double-materiality-assessment/hooks/render-esrs-topic-name-for-identifier.hook";
import { useRenderDMACategoryName } from "../../double-materiality-assessment/hooks/render-dma-category-name.hook";
import { ESRSTopicUtilities } from "@netcero/netcero-common";
import { FeedbackIndicator } from "../components/stakeholder-feedback-category.component";
import { StakeholderFeedbackUtilities } from "../utilities/stakeholder-feedback.utitilities";

export const useGetTocEntriesFromStakeholderFeedbackDetails = (
  details: IStakeholderFeedbackDetails,
): ITableOfContentsEntry[] => {
  const renderEsrsTopicName = useRenderESRSTopicNameForIdentifier();
  const renderDMACategoryName = useRenderDMACategoryName();

  const convertCategory = useCallback(
    (category: IDMACategoryWithEffectsAndFeedbacksAndChildren): ITableOfContentsEntry => {
      const categoryCompoundStatus = StakeholderFeedbackUtilities.getCompoundCategoryStatus(
        category,
        category.feedback.length > 0,
      );

      return {
        id: category.id,
        title: renderDMACategoryName(category),
        endAdornment: <FeedbackIndicator status={categoryCompoundStatus} />,
        children: category.children.map((child) => convertCategory(child)),
      };
    },
    [renderDMACategoryName],
  );

  return useMemo(() => {
    return details.esrsTopics.map(({ topic, dmaCategories }) => ({
      id: topic.identifier,
      title: `${ESRSTopicUtilities.convertTopicToDisplayTopic(
        topic.identifier,
      )} - ${renderEsrsTopicName(topic.identifier)}`,
      children: dmaCategories.map((category) => convertCategory(category)),
    }));
  }, [convertCategory, details.esrsTopics, renderEsrsTopicName]);
};
