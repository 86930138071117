import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";

export const getDmaStatusQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => ["dma-status", organizationId, recordingPeriodId, dataEntryObjectId];

/**
 * Gets the DMA status for a given data entry object
 * @param organizationId The organization id
 * @param recordingPeriodId The recording period id
 * @param dataEntryObjectId The data entry object id
 * @param enabled
 * @returns Query result
 */
export const useDmaStatusQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string | null,
  enabled = true,
) =>
  useQuery({
    enabled: !!dataEntryObjectId && enabled,
    queryKey: getDmaStatusQueryKey(organizationId, recordingPeriodId, dataEntryObjectId!),
    queryFn: () =>
      CoreApiService.ESRSTopicsApi.getDmaStatus(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId!,
      )
        .then((req) => req())
        .then((res) => res.data),
  });
