import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { useTranslation } from "react-i18next";
import {
  IDataEntryObjectInputParameterExclusion,
  IDataEntryObjectInputParameterExclusionReason,
} from "@netcero/netcero-core-api-client";
import { ORDERED_DEO_IP_EXCLUSION_REASONS } from "../data-entry-object-input-parameter.constants";
import {
  useExcludeDataEntryObjectInputParameterMutation,
  useIncludeDataEntryObjectInputParameterMutation,
} from "../data-entry-object-input-parameters.mutations";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import { InputParameterRecordingEsrsStructuresUtilities } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "../../common/dialogs/dialog-button.components";
import { useUserContext } from "../../user/user.context";

interface IDataEntryObjectInputParameterExclusionDialogWithMutationProps {
  open: boolean;
  mode: "input-parameter" | "disclosure-requirement";
  onClose: () => void;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
  dataEntryObjectInputParameter: ILocalDataEntryObjectInputParameter;
}

export const DataEntryObjectInputParameterExclusionDialogWithMutation: FC<
  IDataEntryObjectInputParameterExclusionDialogWithMutationProps
> = ({
  open,
  mode,
  onClose,
  dataEntryObjectInputParameter,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  recordingStructureId,
}) => {
  const { t } = useTranslation("data_entry_object_input_parameter_exclusion_dialog");
  const { user } = useUserContext();
  const { wrapApiPromise } = useAppSnackbar();

  const excludeMutation = useExcludeDataEntryObjectInputParameterMutation();
  const includeMutation = useIncludeDataEntryObjectInputParameterMutation();

  useEffect(() => {
    if (open) {
      // be sure to reset mutations to prevent error from showing up on reopen
      includeMutation.reset();
      excludeMutation.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const isLoading = useMemo(
    () => excludeMutation.isPending || includeMutation.isPending,
    [excludeMutation.isPending, includeMutation.isPending],
  );

  const isError = useMemo(
    () => excludeMutation.isError ?? includeMutation.isError,
    [excludeMutation.isError, includeMutation.isError],
  );

  const handleExclude = useCallback(
    async (payload: IDataEntryObjectInputParameterExclusion) => {
      await wrapApiPromise(
        excludeMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          recordingStructureId,
          inputParameterId: dataEntryObjectInputParameter.inputParameter.id,
          payload,
        }),
        {
          type:
            mode === "disclosure-requirement"
              ? BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT
              : BasicSnackbarApiActionType.EXCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER,
        },
      );
      // Close Dialog
      onClose();
    },
    [
      wrapApiPromise,
      mode,
      excludeMutation,
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      recordingStructureId,
      dataEntryObjectInputParameter.inputParameter.id,
      onClose,
    ],
  );

  // Include Logic

  const handleInclude = useCallback(async () => {
    await wrapApiPromise(
      includeMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        recordingStructureId,
        inputParameterId: dataEntryObjectInputParameter.inputParameter.id,
      }),
      {
        type:
          mode === "disclosure-requirement"
            ? BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT
            : BasicSnackbarApiActionType.INCLUDE_DISCLOSURE_REQUIREMENT_INPUT_PARAMETER,
      },
    );
    // Close Dialog
    onClose();
  }, [
    wrapApiPromise,
    includeMutation,
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    recordingStructureId,
    dataEntryObjectInputParameter.inputParameter.id,
    mode,
    onClose,
  ]);

  const isEligibleForPhaseIn = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.isInputParameterEligibleForPhaseIn(
        dataEntryObjectInputParameter.inputParameter,
      ),
    [dataEntryObjectInputParameter.inputParameter],
  );

  const canInclude = useMemo(
    () =>
      !dataEntryObjectInputParameter.responsibleUserId ||
      dataEntryObjectInputParameter.responsibleUserId === user?.userProfile.id,
    [dataEntryObjectInputParameter.responsibleUserId, user],
  );

  return (
    <DataEntryObjectInputParameterExclusionDialog
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      error={isError ? excludeMutation.error ?? includeMutation.error : undefined}
      value={dataEntryObjectInputParameter.exclude}
      isEligibleForPhaseIn={isEligibleForPhaseIn}
      cannotIncludeReason={
        canInclude
          ? null
          : t("notice_inclusion_only_possible_as_responsible", {
              ns: "data_entry_object_input_parameter_common",
            })
      }
      onExclude={handleExclude}
      onInclude={handleInclude}
    />
  );
};

function getDefaultValuesForForm(
  exclusionData: IDataEntryObjectInputParameterExclusion | undefined,
): IDataEntryObjectInputParameterExclusion {
  return {
    reason: exclusionData?.reason ?? IDataEntryObjectInputParameterExclusionReason.OtherReason,
    explanation: exclusionData?.explanation ?? "",
  };
}

interface IDataEntryObjectInputParameterExclusionDialogProps {
  open: boolean;
  onClose: () => void;
  isLoading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  value: IDataEntryObjectInputParameterExclusion | undefined;
  isEligibleForPhaseIn: boolean;
  /** If this property has a value the include button will be disabled and the value will be shown as a tooltip */
  cannotIncludeReason?: string | null;
  onExclude: (data: IDataEntryObjectInputParameterExclusion) => void;
  onInclude: () => void;
}

export const DataEntryObjectInputParameterExclusionDialog: FC<
  IDataEntryObjectInputParameterExclusionDialogProps
> = ({
  open,
  onClose,
  isLoading,
  error,
  disabled,
  value,
  isEligibleForPhaseIn,
  cannotIncludeReason,
  onExclude,
  onInclude,
}) => {
  const { t } = useTranslation("data_entry_object_input_parameter_exclusion_dialog");

  const isExcluded = useMemo(() => !!value, [value]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
    watch,
    trigger,
  } = useForm<IDataEntryObjectInputParameterExclusion>({
    defaultValues: getDefaultValuesForForm(value),
  });
  const reason = watch("reason");
  const isExplanationRequired =
    reason === IDataEntryObjectInputParameterExclusionReason.OtherReason;

  // Trigger validation of explanation on reason change
  useEffect(() => {
    void trigger("explanation");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reason]);

  useEffect(() => {
    reset(getDefaultValuesForForm(value));
  }, [value, reset]);

  // Phase In is only available for DR eligible DEO IPs
  const availableExclusionReasons = useMemo(() => {
    return isEligibleForPhaseIn
      ? ORDERED_DEO_IP_EXCLUSION_REASONS
      : ORDERED_DEO_IP_EXCLUSION_REASONS.filter(
          (reason) => reason !== IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn,
        );
  }, [isEligibleForPhaseIn]);

  const handleSave = useCallback(
    (data: IDataEntryObjectInputParameterExclusion) => {
      void onExclude({
        ...data,
        explanation: data.explanation?.trim() || undefined,
      });
    },
    [onExclude],
  );

  // Include Logic

  const [showConfirmIncludeDialog, setShowConfirmIncludeDialog] = useState(false);

  const handleInclude = useCallback(() => {
    setShowConfirmIncludeDialog(true);
  }, []);

  const handleCloseConfirmIncludeDialog = useCallback(
    (confirm: boolean) => {
      if (confirm) {
        onInclude();
      }
      setShowConfirmIncludeDialog(false);
    },
    [onInclude],
  );

  return (
    <>
      {/* Confirm Include Dialog */}
      <ConfirmDialogTextBody
        open={showConfirmIncludeDialog}
        onClose={handleCloseConfirmIncludeDialog}
        title={t("include_confirm_dialog_title")}
        content={t("include_confirm_dialog_body")}
      />

      {/* Dialog */}
      <Dialog open={open} onClose={!isDirty ? onClose : undefined} fullWidth maxWidth="md">
        <DialogTitle>
          {t(isExcluded ? "dialog_title_already_excluded" : "dialog_title_exclude")}
        </DialogTitle>
        {isLoading && <LinearProgress />}
        <DialogContent>
          <Box display="flex" flexDirection="column" gap={2} py={1}>
            {error && <ErrorTextComponent error={error} />}
            {/* Reason Dropdown */}
            <Controller
              control={control}
              name="reason"
              render={({ field }) => (
                <FormControl sx={{ mr: "auto", minWidth: 260 }}>
                  <InputLabel id="exclusion-reason-label">{t("label_exclusion_reason")}</InputLabel>
                  <Select
                    required
                    labelId="exclusion-reason-labels"
                    label={t("label_exclusion_reason")}
                    disabled={disabled || isExcluded}
                    {...field}
                  >
                    {availableExclusionReasons.map((exclusionReason) => (
                      <MenuItem key={exclusionReason} value={exclusionReason}>
                        {t(`exclusion_reasons.${exclusionReason}`, {
                          ns: "data_entry_object_input_parameter_common",
                        })}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {/* Explanation Textfield */}
            <Controller
              control={control}
              name="explanation"
              rules={{
                required: isExplanationRequired ? t("error_explanation_required") : undefined,
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label={
                    isExplanationRequired ? t("label_explanation") : t("label_explanation_optional")
                  }
                  fullWidth
                  error={error !== undefined}
                  helperText={error?.message}
                  disabled={disabled || isExcluded}
                  multiline
                  minRows={5}
                  maxRows={5}
                  required={isExplanationRequired}
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          {/* Include Button */}
          {isExcluded && (
            <Tooltip title={cannotIncludeReason} placement="right">
              <Box mr="auto">
                <DialogConfirmButton
                  onClick={handleInclude}
                  disabled={disabled || !!cannotIncludeReason}
                >
                  {t("button_include")}
                </DialogConfirmButton>
              </Box>
            </Tooltip>
          )}
          {/* Cancel Button */}
          <DialogCancelButton onClick={() => onClose()} disabled={disabled}>
            {t(isExcluded ? "close" : "cancel", { ns: "buttons" })}
          </DialogCancelButton>
          {/* Confirm Button */}
          {!isExcluded && (
            <DialogConfirmButton onClick={handleSubmit(handleSave)} disabled={disabled}>
              {t("button_confirm_exclude")}
            </DialogConfirmButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
