import {
  IGroundsForDiscriminationEnum,
  ILayerInWasteHierarchy,
  IPolicyDataE1,
  IPolicyDataE2,
  IPolicyDataE3,
  IPolicyDataE4,
  IPolicyDataE5,
  IPolicyDataS,
  IPolicyDataS1,
  IPolicyDataS2,
  IPolicyDataS3,
  IPolicyDataS4,
  IPolicyGeneralData,
  ISustainableDevelopmentGoalEnum,
} from "@netcero/netcero-core-api-client";
import { IMDRInput } from "../minimum-disclosure-requirements-common/mdr-input.types";
import { WithoutNestedObjects } from "@netcero/netcero-common";
import { ISpecialTopicSectionInputIdentifier } from "../minimum-disclosure-requirements-common/components/mdr-topic-section.component";
import { MdrInputUtilities } from "../minimum-disclosure-requirements-common/mdr-input.utilities";

type GENERAL_KEY = keyof IPolicyGeneralData;
export const GENERAL_ORDER: (GENERAL_KEY | ISpecialTopicSectionInputIdentifier)[] = [
  "name",
  "iros",
  "descriptionOfKeyContentsOfPolicyExplanatory",
  "descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory",
  "descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory",
  "referenceToThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicy",
  "descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory",
  "policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementIt",
  "explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementItExplanatory",
];
export const GENERAL: { [key in GENERAL_KEY]: IMDRInput } = {
  name: {
    type: "text",
    initialValue: "",
    required: true,
    multiline: false,
  },
  descriptionOfKeyContentsOfPolicyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfScopeOfPolicyOrOfItsExclusionsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfMostSeniorLevelInOrganisationThatIsAccountableForImplementationOfPolicyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  referenceToThirdpartyStandardsOrInitiativesThatAreRespectedThroughImplementationOfPolicy: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  descriptionOfConsiderationGivenToInterestsOfKeyStakeholdersInSettingPolicyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementIt: {
    type: "boolean",
    initialValue: false,
  },
  explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementItExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E1_KEY = keyof IPolicyDataE1;
export const E1_ORDER: E1_KEY[] = ["addressedMatters"];
export const E1: { [key in E1_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e1,
};

export type E2_KEY = keyof IPolicyDataE2;
export const E2_ORDER: E2_KEY[] = [
  "addressedMatters",
  "policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil",
  "policyEitherAddressesSubstitutingOrMinimisingUseOfSubstancesOfConcernAndOrPhasingOutSubstancesOfVeryHighConcern",
  "policyAddressesAvoidingIncidentsAndOrEmergencySituationsAndIfWhenTheyOccurItEitherControlsOrLimitsTheirImpactOnPeopleAndEnvironment",
];
export const E2: { [key in E2_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e2,
  policyAddressesMitigatingNegativeImpactsRelatedToPollutionOfAirWaterAndSoil: {
    type: "boolean",
    initialValue: false,
  },
  policyEitherAddressesSubstitutingOrMinimisingUseOfSubstancesOfConcernAndOrPhasingOutSubstancesOfVeryHighConcern:
    {
      type: "boolean",
      initialValue: false,
    },
  policyAddressesAvoidingIncidentsAndOrEmergencySituationsAndIfWhenTheyOccurItEitherControlsOrLimitsTheirImpactOnPeopleAndEnvironment:
    {
      type: "boolean",
      initialValue: false,
    },
};

export type E3_KEY = keyof IPolicyDataE3;
export const E3_ORDER: E3_KEY[] = [
  "addressedMatters",
  "policyAddressesWaterManagement",
  "policyAddressesEitherUseOrSourcingOfWaterAndOrMarineResourcesInOwnOperations",
  "policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater",
  "policyAddressesPreventionAndOrAbatementOfWaterPollutionResultingFromActivities",
  "policyAddressesEitherProductOrServiceDesignInViewOfAddressingWaterrelatedIssuesAndOrPreservingMarineResources",
  "policyAddressesCommitmentToReducingMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndOrAlongUpstreamAndDownstreamValueChain",
  "policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems",
  "policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources",
  "policyAimsAtEnhancingProtectionAndOrImprovingAquaticEnvironment",
  "policyPromotesGoodEnvironmentalStatusOfMarineWater",
  "policyPromotesReductionOfWaterWithdrawalsAndOrWaterDischarges",
  "policyContributesEitherToGoodEcologicalAndOrChemicalQualityOfSurfaceWaterBodiesOrToGoodChemicalQualityAndOrQuantityOfGroundwaterBodies",
  "policyEitherMinimisesMaterialImpactsAndRisksOrImplementsMitigationMeasuresThatAimAtMaintainingValueAndFunctionalityOfPriorityServicesAndOrToIncreaseResourceEfficiencyInOwnOperations",
  "policyAvoidsImpactsOnAffectedCommunities",
];
export const E3: { [key in E3_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e3,
  policyAddressesWaterManagement: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesEitherUseOrSourcingOfWaterAndOrMarineResourcesInOwnOperations: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesPreventionAndOrAbatementOfWaterPollutionResultingFromActivities: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesEitherProductOrServiceDesignInViewOfAddressingWaterrelatedIssuesAndOrPreservingMarineResources:
    {
      type: "boolean",
      initialValue: false,
    },
  policyAddressesCommitmentToReducingMaterialWaterConsumptionInAreasAtWaterRiskInOwnOperationsAndOrAlongUpstreamAndDownstreamValueChain:
    {
      type: "boolean",
      initialValue: false,
    },
  policyPreventsFurtherDeteriorationAndProtectsAndEnhancesStatusOfWaterBodiesAndAquaticEcosystems: {
    type: "boolean",
    initialValue: false,
  },
  policyPromotesSustainableWaterUseBasedOnLongtermProtectionOfAvailableWaterResources: {
    type: "boolean",
    initialValue: false,
  },
  policyAimsAtEnhancingProtectionAndOrImprovingAquaticEnvironment: {
    type: "boolean",
    initialValue: false,
  },
  policyPromotesGoodEnvironmentalStatusOfMarineWater: {
    type: "boolean",
    initialValue: false,
  },
  policyPromotesReductionOfWaterWithdrawalsAndOrWaterDischarges: {
    type: "boolean",
    initialValue: false,
  },
  policyContributesEitherToGoodEcologicalAndOrChemicalQualityOfSurfaceWaterBodiesOrToGoodChemicalQualityAndOrQuantityOfGroundwaterBodies:
    {
      type: "boolean",
      initialValue: false,
    },
  policyEitherMinimisesMaterialImpactsAndRisksOrImplementsMitigationMeasuresThatAimAtMaintainingValueAndFunctionalityOfPriorityServicesAndOrToIncreaseResourceEfficiencyInOwnOperations:
    {
      type: "boolean",
      initialValue: false,
    },
  policyAvoidsImpactsOnAffectedCommunities: {
    type: "boolean",
    initialValue: false,
  },
};

export type E4_KEY = keyof IPolicyDataE4;
export const E4_ORDER: E4_KEY[] = [
  "addressedMatters",
  "biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndOrEcosystemsrelatedImpacts",
  "biodiversityAndEcosystemsrelatedPolicyRelatesToEitherMaterialDependenciesOrMaterialPhysicalAndOrTransitionRisksAndOpportunities",
  "biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain",
  "biodiversityAndEcosystemsrelatedPolicyAddressesEitherProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainAndOrEnhanceConditionsForBiodiversity",
  "biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndOrEcosystemsrelatedImpacts",
  "disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory",
  "disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory",
  "disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory",
  "sustainableDevelopmentGoalsToWhichPolicyIsConnected",
  "disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory",
  "disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory",
  "disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory",
  "thirdpartyStandardOfConductUsedInPolicyIsEitherObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesOrRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances",
  "thirdpartyStandardOfConductUsedInPolicyIsEitherDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsAndNoGroupHoldingUndueAuthorityAndOrVetoPowerOverContent",
  "thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime",
  "thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008",
  "thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice",
];
export const E4: { [key in E4_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e4,
  biodiversityAndEcosystemsrelatedPolicyRelatesToMaterialBiodiversityAndOrEcosystemsrelatedImpacts:
    {
      type: "boolean",
      initialValue: false,
    },
  biodiversityAndEcosystemsrelatedPolicyRelatesToEitherMaterialDependenciesOrMaterialPhysicalAndOrTransitionRisksAndOpportunities:
    {
      type: "boolean",
      initialValue: false,
    },
  biodiversityAndEcosystemsrelatedPolicySupportsTraceabilityOfProductsComponentsAndRawMaterialsWithMaterialActualOrPotentialImpactsOnBiodiversityAndEcosystemsAlongValueChain:
    {
      type: "boolean",
      initialValue: false,
    },
  biodiversityAndEcosystemsrelatedPolicyAddressesEitherProductionSourcingOrConsumptionFromEcosystemsThatAreManagedToMaintainAndOrEnhanceConditionsForBiodiversity:
    {
      type: "boolean",
      initialValue: false,
    },
  biodiversityAndEcosystemsrelatedPolicyAddressesSocialConsequencesOfBiodiversityAndOrEcosystemsrelatedImpacts:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowPolicyLimitsProcurementFromSuppliersThatCannotDemonstrateThatTheyAreNotContributingToSignificantDamageToProtectedAreasOrKeyBiodiversityAreasExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isChild: true,
    },
  disclosureOfHowPolicyRefersToRecognisedStandardsOrThirdpartyCertificationsOverseenByRegulatorsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isChild: true,
    },
  disclosureOfHowPolicyAddressesRawMaterialsOriginatingFromEcosystemsThatHaveBeenManagedToMaintainOrEnhanceConditionsForBiodiversityExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isChild: true,
      isLastChild: true,
    },
  sustainableDevelopmentGoalsToWhichPolicyIsConnected: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ISustainableDevelopmentGoalEnum),
  },
  disclosureOfHowPolicyEnablesToAvoidNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndRelatedUpstreamAndDownstreamValueChainExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfHowPolicyEnablesToReduceAndMinimiseNegativeImpactsOnBiodiversityAndEcosystemsInOwnOperationsAndThroughoutUpstreamAndDownstreamValueChainThatCannotBeAvoidedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfHowPolicyEnablesToRestoreAndRehabilitateDegradedEcosystemsOrRestoreClearedEcosystemsFollowingExposureToImpactsThatCannotBeCompletelyAvoidedAndOrMinimisedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  thirdpartyStandardOfConductUsedInPolicyIsEitherObjectiveAndAchievableBasedOnScientificApproachToIdentifyingIssuesOrRealisticInAssessingHowTheseIssuesCanBeAddressedUnderVarietyOfPracticalCircumstances:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyIsEitherDevelopedOrMaintainedThroughProcessOfOngoingConsultationWithRelevantStakeholdersWithBalancedInputFromAllRelevantStakeholderGroupsAndNoGroupHoldingUndueAuthorityAndOrVetoPowerOverContent:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyEncouragesStepwiseApproachAndContinuousImprovementInStandardAndItsApplicationOfBetterManagementPracticesAndRequiresEstablishmentOfMeaningfulTargetsAndSpecificMilestonesToIndicateProgressAgainstPrinciplesAndCriteriaOverTime:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyIsVerifiableThroughIndependentCertifyingOrVerifyingBodiesWhichHaveDefinedAndRigorousAssessmentProceduresThatAvoidConflictsOfInterestAndAreCompliantWithIsoGuidanceOnAccreditationAndVerificationProceduresOrArticle52OfRegulationEcNo765NO2008:
    {
      type: "boolean",
      initialValue: false,
    },
  thirdpartyStandardOfConductUsedInPolicyConformsToIsealCodeOfGoodPractice: {
    type: "boolean",
    initialValue: false,
  },
};

export type E5_KEY = keyof IPolicyDataE5;
export const E5_ORDER: E5_KEY[] = [
  "addressedMatters",
  "layersInWasteHierarchyThatPolicyAddresses",
  "policyAddressesTransitioningAwayFromUseOfVirginResources",
  "policyAddressesSustainableSourcingAndOrUseOfRenewableResources",
  "policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteReuseRepairRefurbishRemanufactureAndRepurposeOverWasteTreatmentRecycling",
];
export const E5: { [key in E5_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.e5,
  layersInWasteHierarchyThatPolicyAddresses: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInWasteHierarchy),
  },
  policyAddressesTransitioningAwayFromUseOfVirginResources: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesSustainableSourcingAndOrUseOfRenewableResources: {
    type: "boolean",
    initialValue: false,
  },
  policyAddressesPrioritisationOfAvoidanceOrMinimisationWasteReuseRepairRefurbishRemanufactureAndRepurposeOverWasteTreatmentRecycling:
    {
      type: "boolean",
      initialValue: false,
    },
};

export type S_VALUES_ONLY = WithoutNestedObjects<IPolicyDataS>;
export type S_KEY = keyof S_VALUES_ONLY;
export const S_ORDER: S_KEY[] = [
  "groundsForDiscriminationSpecificallyCoveredByPolicy",
  "referenceForIdentificationOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusers",
  "specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace",
  "disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory",
  "disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsAsWellAsExplanationOfHowPotentialBarriersForDisseminationAreIdentifiedAndRemovedExplanatory",
];
export const S: { [key in S_KEY]: IMDRInput } = {
  groundsForDiscriminationSpecificallyCoveredByPolicy: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IGroundsForDiscriminationEnum),
  },
  referenceForIdentificationOfAspectsOfPolicyThatSatisfyRequirementsOfDisclosureOfPoliciesRelatedToOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndusers:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfCommunicationToolsAndChannelsAimedAtEnsuringThatPolicyIsAccessibleAndThatDifferentAudiencesUnderstandItsImplicationsAsWellAsExplanationOfHowPotentialBarriersForDisseminationAreIdentifiedAndRemovedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type S1_KEY = keyof IPolicyDataS1;
export const S1_ORDER: S1_KEY[] = ["addressedMatters"];
export const S1: { [key in S1_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s1,
};

export type S2_KEY = keyof IPolicyDataS2;
export const S2_ORDER: S2_KEY[] = ["addressedMatters"];
export const S2: { [key in S2_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s2,
};

export type S3_KEY = keyof IPolicyDataS3;
export const S3_ORDER: S3_KEY[] = ["addressedMatters"];
export const S3: { [key in S3_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s3,
};

export type S4_KEY = keyof IPolicyDataS4;
export const S4_ORDER: S4_KEY[] = ["addressedMatters"];
export const S4: { [key in S4_KEY]: IMDRInput } = {
  addressedMatters: MdrInputUtilities.ADDRESSED_MATTERS_INPUT.s4,
};

export const ALL_SECTIONS_CONFIG = {
  general: GENERAL,
  e1: E1,
  e2: E2,
  e3: E3,
  e4: E4,
  e5: E5,
  s: S,
  s1: S1,
  s2: S2,
  s3: S3,
  s4: S4,
};

export const ALL_SECTIONS_ORDER = {
  general: GENERAL_ORDER,
  e1: E1_ORDER,
  e2: E2_ORDER,
  e3: E3_ORDER,
  e4: E4_ORDER,
  e5: E5_ORDER,
  s: S_ORDER,
  s1: S1_ORDER,
  s2: S2_ORDER,
  s3: S3_ORDER,
  s4: S4_ORDER,
};
