import { IDMACategoryWithEffectsAndChildren, IESRSTopic } from "@netcero/netcero-core-api-client";
import { useCallback, useState } from "react";
import {
  DMACategoryDialogMode,
  IDMACategoryDialogsState,
} from "./dma-category-editing-dialogs.component";

/**
 * Hook to manage the state of the DMA Category Editing Dialogs
 */
export const useDMACategoryEditingDialogsState = () => {
  const [dmaCategoryEditingDialogsState, setDMACategoryEditingDialogsState] =
    useState<IDMACategoryDialogsState>({});

  const openCreateDMACategoryDialog = useCallback(
    (esrsTopic: IESRSTopic, parentCategoryId?: string | null) => {
      setDMACategoryEditingDialogsState({
        mode: DMACategoryDialogMode.Create,
        esrsTopic,
        parentCategoryId,
      });
    },
    [],
  );

  const openEditDMACategoryDialog = useCallback(
    (esrsTopic: IESRSTopic, category: IDMACategoryWithEffectsAndChildren) => {
      setDMACategoryEditingDialogsState({
        mode: DMACategoryDialogMode.Edit,
        esrsTopic,
        category,
      });
    },
    [],
  );

  const closeDMACategoryDialogs = useCallback(() => {
    setDMACategoryEditingDialogsState((state) => ({ ...state, mode: null }));
  }, []);

  return {
    dmaCategoryEditingDialogsState,
    openCreateDMACategoryDialog,
    openEditDMACategoryDialog,
    closeDMACategoryDialogs,
  };
};
