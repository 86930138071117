import { IKeycloakUserClientConfiguration, IKeycloakUserProfile } from "./keycloak-user-api.types";

interface IFetchOptions {
  searchParams?: URLSearchParams;
  body?: unknown;
}

export class KeycloakUserApiClient {
  // Note: If more handwritten API clients are needed, consider extracting the common logic to a base class.
  // like generateApiUrlForConfiguration, getHeaders, fetch, etc

  /**
   * Generates the URL for the Keycloak API based on the configuration.
   * @param baseUrl
   * @param realm
   * @param path
   * @param options
   * @private
   */
  private static generateApiUrlForConfiguration(
    { baseUrl, realm }: Pick<IKeycloakUserClientConfiguration, "baseUrl" | "realm">,
    path?: string,
    options?: {
      searchParams?: URLSearchParams;
    },
  ) {
    const baseUrlObject = new URL(baseUrl);
    const url = new URL(`${baseUrlObject.toString()}realms/${realm}${path ? `/${path}` : ""}`);
    if (options?.searchParams) {
      url.search = options.searchParams.toString();
    }
    return url;
  }

  /**
   * Generates the headers for the Keycloak API.
   * @param token
   * @private
   */
  private static getHeaders(token: string) {
    return {
      "content-type": "application/json",
      authorization: `Bearer ${token}`,
    };
  }

  private static async fetch(
    configuration: IKeycloakUserClientConfiguration,
    method: "GET" | "POST" | "PUT" | "DELETE",
    path?: string,
    options?: IFetchOptions,
  ) {
    return fetch(
      KeycloakUserApiClient.generateApiUrlForConfiguration(configuration, path, options),
      {
        method,
        headers: KeycloakUserApiClient.getHeaders(configuration.token),
        body: options?.body ? JSON.stringify(options.body) : undefined,
      },
    ).then((res) => (res.ok ? res : Promise.reject(res)));
  }

  private static parseJson<TResult>(res: Response) {
    return res.json() as Promise<TResult>;
  }

  // Actual API Calls

  public static readAccount(configuration: IKeycloakUserClientConfiguration) {
    return KeycloakUserApiClient.fetch(configuration, "GET", "account").then(
      KeycloakUserApiClient.parseJson<IKeycloakUserProfile>,
    );
  }

  public static updateAccount(
    configuration: IKeycloakUserClientConfiguration,
    account: IKeycloakUserProfile,
  ) {
    return KeycloakUserApiClient.fetch(configuration, "POST", "account", {
      body: account,
    });
  }

  // Instance Logic

  constructor(private configuration: IKeycloakUserClientConfiguration) {}

  public readAccount() {
    return KeycloakUserApiClient.readAccount(this.configuration);
  }

  public updateAccount(account: IKeycloakUserProfile) {
    return KeycloakUserApiClient.updateAccount(this.configuration, account);
  }
}
