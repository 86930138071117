import { FC } from "react";
import { IntercomWrapper } from "../../modules/intercom/intercom-wrapper.component";
import { Outlet } from "react-router-dom";

export const StakeholderFeedbacksLayout: FC = () => {
  return (
    <IntercomWrapper anonymous>
      <Outlet />
    </IntercomWrapper>
  );
};
