import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

export interface IDefaultSearchParamsValuesItem {
  key: string;
  value: string | string[];
}

/**
 * Utility hook to initialize the search params with default values or to update the
 * search params with default values if they are unset. (uses replace option when setting params)
 * @param searchParamsValues The searchParams and values to use.
 * Hook will NOT listen for changes (however it will of course always use the latest value on changes)
 */
export function useDefaultSearchParamsValues(
  ...searchParamsValues: IDefaultSearchParamsValuesItem[]
) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check if there are any changes
    const hasUnitializedParams = searchParamsValues.some((sp) => {
      return searchParams.get(sp.key) === null;
    });

    if (hasUnitializedParams) {
      setSearchParams(
        (params) => {
          searchParamsValues.forEach((sp) => {
            if (Array.isArray(sp.value)) {
              const currentValues = params.getAll(sp.key);
              if (currentValues.length === 0) {
                // Delete not necessary since already empty (length < 1)
                sp.value.forEach((val) => params.append(sp.key, val));
              }
            } else {
              if (params.get(sp.key) === null) {
                params.set(sp.key, sp.value);
              }
            }
          });
          return params;
        },
        { replace: true },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);
}
