import React, { createContext, FC, PropsWithChildren, useContext } from "react";

export interface IESRSTopicContextData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  esrsTopicId: string;
}

interface IESRSTopicContextProps {
  value: IESRSTopicContextData;
}

const ESRSTopicContext = createContext<IESRSTopicContextData | null>(null);

export const ESRSTopicContextProvider: FC<PropsWithChildren<IESRSTopicContextProps>> = ({
  value,
  children,
}) => {
  return <ESRSTopicContext.Provider value={value}>{children}</ESRSTopicContext.Provider>;
};

export const useESRSTopicContext = (): IESRSTopicContextData => {
  const context = useContext(ESRSTopicContext);
  if (!context) {
    throw new Error("useESRSTopicContext must be used within a ESRSTopicContextProvider");
  }
  return context;
};
