import { useCallback } from "react";
import { IESRSTopic } from "@netcero/netcero-core-api-client";
import { useRenderESRSTopicNameForIdentifier } from "./render-esrs-topic-name-for-identifier.hook";

type IESRSTopicForTranslate = Pick<IESRSTopic, "identifier">;

export type IESRSTopicNameRenderer = (
  esrsTopic: IESRSTopicForTranslate | null | undefined,
) => string;

export const useRenderESRSTopicName: () => IESRSTopicNameRenderer = () => {
  const translateIdentifier = useRenderESRSTopicNameForIdentifier();
  return useCallback(
    (esrsTopic: IESRSTopicForTranslate | null | undefined) =>
      translateIdentifier(esrsTopic?.identifier),
    [translateIdentifier],
  );
};
