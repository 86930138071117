import { Dispatch, FC } from "react";
import { SearchIcon } from "../constants/tabler-icon.constants";
import { StandardTextFieldProps, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ISearchTextInputProps {
  search: string;
  onSearchChange: Dispatch<string>;
  placeholderOverride?: string;
  textFieldProps?: StandardTextFieldProps;
}

export const SearchTextInput: FC<ISearchTextInputProps> = ({
  search,
  onSearchChange,
  placeholderOverride,
  textFieldProps,
}) => {
  const { t } = useTranslation("common");

  return (
    <TextField
      variant="standard"
      size="small"
      placeholder={placeholderOverride ?? t("placeholders.search")}
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
      value={search}
      onChange={(event) => onSearchChange(event.currentTarget.value)}
      // Stop event propagation so menu items are not focused when typing
      onKeyDown={(event) => event.stopPropagation()}
      fullWidth
      {...textFieldProps}
    />
  );
};
