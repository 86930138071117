import { FC } from "react";
import { useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { Box } from "@mui/material";
import { useRecordingPeriodsForStakeholderQuery } from "../../modules/stakeholder-feedbacks/stakeholder-feedbacks.queries";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";
import { StakeholderFeedbackOverviewComponent } from "../../modules/stakeholder-feedbacks/components/stakeholder-feedback-overview.component";

export const StakeholderFeedbackPage: FC = () => {
  const { stakeholderId } = useParams();
  const recordingPeriodsQuery = useRecordingPeriodsForStakeholderQuery(stakeholderId!);

  return (
    <Box p={4}>
      <QueryWrapper
        query={recordingPeriodsQuery}
        loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
        build={(recordingPeriods) => (
          <StakeholderFeedbackOverviewComponent
            stakeholderId={stakeholderId!}
            stakeholderRecordingPeriods={recordingPeriods}
          />
        )}
      />
    </Box>
  );
};
