import { FC } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ITargetPath } from "@netcero/netcero-core-api-client";
import { useNavigate } from "react-router-dom";
import { TargetPathActionsComponent } from "./target-path-actions.component";

interface ITargetPathsEditListComponentProps {
  targetPaths: ITargetPath[];
  onEdit: (targetPath: ITargetPath) => void;
  onDelete: (targetPath: ITargetPath) => void;
  onToggleCommit: (targetPath: ITargetPath) => void;
  onClone: (targetPath: ITargetPath) => void;
  disabled?: boolean;
  organizationId: string;
  hideUnavailableActions?: boolean;
}

export const TargetPathsEditListComponent: FC<ITargetPathsEditListComponentProps> = ({
  targetPaths,
  onEdit,
  onDelete,
  onToggleCommit,
  onClone,
  disabled,
  organizationId,
  hideUnavailableActions,
}) => {
  const { t } = useTranslation("target_paths_edit_list_component");
  const navigate = useNavigate();

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("headers.name")}</TableCell>
          <TableCell>{t("headers.reference_budget_name")}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {targetPaths.map((tp) => (
          <TableRow
            key={tp.id}
            onClick={() => {
              navigate(`/organizations/${organizationId}/target-paths/${tp.id}`);
            }}
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            <TableCell>{tp.name}</TableCell>
            <TableCell>{tp.referenceBudget.name}</TableCell>
            {/* Actions for the current row */}
            <TableCell align="right">
              <TargetPathActionsComponent
                targetPath={tp}
                onEdit={onEdit}
                onToggleCommit={onToggleCommit}
                onClone={onClone}
                onDelete={onDelete}
                disabled={disabled}
                hideUnavailableActions={hideUnavailableActions}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
