import { IGetAllSourcesResponse, ISource } from "@netcero/netcero-core-api-client";

export class SourcesUtilities {
  /**
   * Converts the API response to a local response.
   * @param response The original API response.
   */
  public static convertAllSourcesResponseToLocal(response: IGetAllSourcesResponse): ISource[] {
    return response.sources.map((source) => source);
  }

  public static filterSources(search: string, sources: ISource[]) {
    const searchLowerCase = search.toLowerCase();
    return sources.filter((source) => source.name.toLowerCase().includes(searchLowerCase));
  }
}
