import { Box, IconButton, TableCell, TableRow, Tooltip } from "@mui/material";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "../../common/constants/tabler-icon.constants";
import { IUpdateDistributionCriteriaValueData } from "../applied-distribution-criteria.mutations";
import { AppliedDistributionCriteriaTableValueCellComponent } from "./applied-distribution-criteria-table-value-cell.component";

interface IAppliedDistributionCriteriaTableRowComponentProps {
  data: IDataEntryObject;
  level?: number;
  expanded: string[];
  onExpand: (id: string) => void;
  onCollapse: (id: string) => void;
  currentCriteria: IDistributionCriterionWithApplicationStatus;
  onUpdateValue: (
    value: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId" | "payload">,
  ) => void;
  onDeleteValue: (value: Pick<IUpdateDistributionCriteriaValueData, "dataEntryObjectId">) => void;
}

export const AppliedDistributionCriteriaTableRowComponent: FC<
  IAppliedDistributionCriteriaTableRowComponentProps
> = ({
  data,
  level = 0,
  expanded,
  onExpand,
  onCollapse,
  currentCriteria,
  onUpdateValue,
  onDeleteValue,
}) => {
  const { t } = useTranslation("dma_dashboard");

  const isExpanded = useMemo(() => expanded.includes(data.id), [data.id, expanded]);
  const hasChildren = useMemo(() => data.children.length > 0, [data]);

  return (
    <>
      {/* Table Row itself */}
      <TableRow>
        <TableCell
          sx={{ "&:hover": { cursor: "pointer" } }}
          onClick={
            hasChildren
              ? () => {
                  if (isExpanded) {
                    onCollapse(data.id);
                  } else {
                    onExpand(data.id);
                  }
                }
              : undefined
          }
        >
          <Box display="flex" alignItems="center" ml={level * 2}>
            <Tooltip title={!hasChildren ? t("tooltip_no_children") : ""}>
              <span>
                <IconButton
                  size="small"
                  sx={{
                    transform: isExpanded ? "rotate(180deg)" : "rotate(0deg)",
                    transition: "transform 300ms",
                  }}
                  disableRipple={true}
                  disabled={!hasChildren}
                >
                  <ChevronDownIcon />
                </IconButton>
              </span>
            </Tooltip>
            {/*<Box component="span" fontWeight={isHighlighted ? "bold" : undefined}>*/}
            <Box component="span" fontWeight="bold">
              {data.name}
            </Box>
          </Box>
        </TableCell>
        {/* Values */}
        <TableCell>
          <AppliedDistributionCriteriaTableValueCellComponent
            dataEntryObject={data}
            currentCriteria={currentCriteria}
            onUpdateValue={onUpdateValue}
            onDeleteValue={onDeleteValue}
          />
        </TableCell>
      </TableRow>
      {isExpanded &&
        data.children.map((child) => (
          <AppliedDistributionCriteriaTableRowComponent
            key={child.id}
            data={child}
            level={level + 1}
            expanded={expanded}
            onExpand={onExpand}
            onCollapse={onCollapse}
            currentCriteria={currentCriteria}
            onUpdateValue={onUpdateValue}
            onDeleteValue={onDeleteValue}
          />
        ))}
    </>
  );
};
