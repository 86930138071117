import { FieldValues } from "react-hook-form";
import { IMDRTextInput } from "../../mdr-input.types";
import { useMemo } from "react";
import { Box, TextField, Tooltip } from "@mui/material";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";

export function TextInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
  disabled,
}: IMDRControlledInputProps<T, IMDRTextInput>) {
  const multilineProps = useMemo(
    () =>
      valueMetaData.multiline
        ? {
            multiline: true,
            minRows: 3,
            maxRows: 5,
          }
        : {},
    [valueMetaData.multiline],
  );

  return (
    <>
      <Box pl={valueMetaData.isChild || isConditional ? 6 : 0}>
        <TextField
          {...field}
          label={
            <Tooltip title={label}>
              <span style={{ fontWeight: valueMetaData.isParent ? "bold" : undefined }}>
                {label}
              </span>
            </Tooltip>
          }
          variant="outlined"
          fullWidth={valueMetaData.multiline}
          error={!!error}
          helperText={error ? error.message : null}
          disabled={disabled}
          sx={{
            width: !valueMetaData.multiline ? NON_FULL_WIDTH_WIDTH : undefined,
            maxWidth: "100%",
          }}
          {...multilineProps}
        />
      </Box>
      {valueMetaData.isLastChild && <Box />}
    </>
  );
}
