import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  IBaseTargetPathData,
  ICreateTargetPathRequest,
  ITargetPath,
  ITargetPathGoalTemplateType,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ILocalReferenceBudget } from "../../reference-budgets/interfaces/reference-budgets.interfaces";
import Checkbox from "@mui/material/Checkbox";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";

const getFormDefaultValues = (
  availableReferenceBudgets: ILocalReferenceBudget[],
  targetPath: ITargetPath | null,
): ICreateTargetPathRequest => {
  return {
    name: targetPath?.name ?? "",
    referenceBudgetId: targetPath?.referenceBudgetId ?? availableReferenceBudgets[0].id,
    goalsTemplate: targetPath === null ? ITargetPathGoalTemplateType.ParisConform : undefined,
  };
};

interface ITargetPathEditDialogBaseProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  availableReferenceBudgets: ILocalReferenceBudget[];
  // Used to enforce name unique constraint
  otherTargetPaths: ITargetPath[];
}

interface ICreateTargetPathEditDialogProps extends ITargetPathEditDialogBaseProps {
  mode: "create";
  targetPath: null;
  onClose: (data: ICreateTargetPathRequest | null) => void;
}

interface IEditTargetPathEditDialogProps extends ITargetPathEditDialogBaseProps {
  mode: "edit";
  targetPath: ITargetPath;
  onClose: (data: IBaseTargetPathData | null) => void;
}

type ITargetPathEditDialogProps = ICreateTargetPathEditDialogProps | IEditTargetPathEditDialogProps;

export const TargetPathEditDialog: FC<ITargetPathEditDialogProps> = ({
  targetPath,
  disabled,
  onClose,
  mode,
  error,
  open,
  loading,
  availableReferenceBudgets,
  otherTargetPaths,
}) => {
  const { t } = useTranslation("target_path_edit_dialog");

  const [useTemplate, setUseTemplate] = useState<boolean>(true);

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<ICreateTargetPathRequest>({
    defaultValues: getFormDefaultValues(availableReferenceBudgets, targetPath),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(availableReferenceBudgets, targetPath));
      setUseTemplate(true);
    }
  }, [availableReferenceBudgets, open, reset, targetPath]);

  const handleEmitData = (data: ICreateTargetPathRequest) => {
    const basePayload: IBaseTargetPathData = {
      name: data.name.trim(),
      referenceBudgetId: data.referenceBudgetId,
    };

    if (mode === "create") {
      onClose({ ...basePayload, goalsTemplate: useTemplate ? data.goalsTemplate : undefined });
    } else {
      onClose(basePayload);
    }
  };

  return (
    <EditDialogWrapper
      open={open}
      title={t(mode === "edit" ? "title_edit" : "title_create")}
      mode={mode}
      loading={loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      disabled={disabled}
      dialogProps={{ fullWidth: true }}
    >
      <Box display="flex" flexDirection="column" gap={2} mt={1}>
        {/* Name Input */}
        <Controller
          control={control}
          name="name"
          rules={{
            required: t("errors.name_required"),
            validate: (value) => {
              if (otherTargetPaths.find((t) => t.name === value)) {
                return t("errors.name_unique");
              }
              return undefined;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("labels.name")}
              {...field}
              error={!!error}
              helperText={error?.message}
              disabled={disabled}
            />
          )}
        />
        {/* Reference Budget Select */}
        <Controller
          name="referenceBudgetId"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <FormControl fullWidth error={!!error} disabled={disabled}>
              <InputLabel id="reference-budget-label">{t("labels.reference_budget")}</InputLabel>
              <Select
                labelId="reference-budget-label"
                value={field.value}
                label={t("labels.reference_budget")}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                error={!!error}
              >
                {availableReferenceBudgets.map((rb) => (
                  <MenuItem key={rb.id} value={rb.id}>
                    {rb.name}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />

        {/* Template Checkbox + Select */}
        {mode === "create" && (
          <>
            {/* Checkbox */}
            <Tooltip
              title={t("tooltips.use_template")}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -20],
                      },
                    },
                  ],
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={useTemplate}
                    onChange={(event, checked) => setUseTemplate(checked)}
                  />
                }
                label={t("labels.use_template")}
              />
            </Tooltip>
            {/* Select for template */}
            {useTemplate && (
              <Controller
                name="goalsTemplate"
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>{t("labels.template")}</InputLabel>
                    <Select {...field} label={t("labels.template")}>
                      {Object.values(ITargetPathGoalTemplateType).map((v) => (
                        <MenuItem key={v} value={v}>
                          {t(`goals.${v}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            )}
          </>
        )}
      </Box>
    </EditDialogWrapper>
  );
};
