/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IAddressedMatterE1Enum = {
  ClimateChangeAdaptationMember: "esrs:ClimateChangeAdaptationMember",
  ClimateChangeMitigationMember: "esrs:ClimateChangeMitigationMember",
  EnergyEfficiencyMember: "esrs:EnergyEfficiencyMember",
  RenewableEnergyDeploymentMember: "esrs:RenewableEnergyDeploymentMember",
  OtherClimateChangeSubtopicMember: "esrs:OtherClimateChangeSubtopicMember",
} as const;

export type IAddressedMatterE1Enum =
  (typeof IAddressedMatterE1Enum)[keyof typeof IAddressedMatterE1Enum];
