import { useParams } from "react-router-dom";
import { useMemo } from "react";
import {
  ReferenceRevenueIn,
  useTargetPathsQuery,
} from "../../target-paths/queries/target-paths.queries";
import { ITargetPath, ITargetPathState } from "@netcero/netcero-core-api-client";

interface ICurrentTargetPathResponse {
  targetPath: ITargetPath;
  currentOrganizationTargetPath: ITargetPath | null;
  allTargetPaths: ITargetPath[];
}

export const useCurrentTargetPath = (
  referenceRevenueIn: ReferenceRevenueIn = ReferenceRevenueIn.EuroCents,
): ICurrentTargetPathResponse | null => {
  const { organizationId, targetPathId } = useParams();
  const targetPathsQuery = useTargetPathsQuery(
    organizationId!,
    !!organizationId,
    referenceRevenueIn,
  );

  return useMemo((): ICurrentTargetPathResponse | null => {
    if (targetPathsQuery.isSuccess && targetPathsQuery.data) {
      const targetPath =
        targetPathsQuery.data.targetPaths.find((tp) => tp.id === targetPathId) ?? null;

      if (targetPath === null) {
        return null;
      }

      // if the current path has been committed, no other path can exist
      // if the current path has not been committed, search for a possible other path that has been committed
      // no need to skip the id since we know for a fact that the current path can not be returned for the query
      // currentPath.state === Committed
      const currentOrganizationTargetPath: ITargetPath | null =
        targetPath.state === ITargetPathState.Committed
          ? null
          : targetPathsQuery.data.targetPaths.find((t) => t.state === ITargetPathState.Committed) ??
            null;

      return {
        targetPath,
        currentOrganizationTargetPath,
        allTargetPaths: targetPathsQuery.data.targetPaths,
      };
    } else {
      return null;
    }
  }, [targetPathsQuery.isSuccess, targetPathsQuery.data, targetPathId]);
};
