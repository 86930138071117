import { FC } from "react";
import { TextField, TextFieldProps } from "@mui/material";

interface ISingleClickTextFieldComponentProps {
  disableAutoSelect?: boolean;
}

export const SingleClickTextFieldComponent: FC<
  ISingleClickTextFieldComponentProps & TextFieldProps
> = (props) => {
  return (
    <TextField
      onFocus={(event) => {
        if (!props.disableAutoSelect) {
          event.target.select();
        }
        props.onFocus?.(event);
      }}
      {...props}
    />
  );
};
