import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";
import { ILocalInputParameterRecordingStructureESRS } from "../../input-parameter-recording-structures/local-input-parameter-recording-structure.interfaces";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
  IESRSTopicPhaseInDecision,
  IOrganization,
} from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameter } from "../interfaces/local-data-entry-object-values.interfaces";
import { InputParameterRecordingEsrsStructuresUtilities } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";
import { Alert, Box, Fade, Grid, Typography } from "@mui/material";
import { DeoEsrsFilterBarComponent } from "./deo-esrs-filter-bar.component";
import { DeoEsrsTableOfContents } from "./deo-esrs-table-of-contents.component";
import {
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { useSearchParamStateBasic } from "../../common/hooks/use-search-param-state.hook";
import { useDefaultSearchParamsValues } from "../../common/hooks/use-default-search-param-value";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { DeoEsrsValuesOverviewContent } from "./deo-esrs-values-overview-content.component";
import { DeoEsrsInputContextProvider, IDeoEsrsInputContextData } from "./deo-esrs-input.context";
import { useDeoEsrsApiActions } from "./deo-esrs-actions.hook";
import { useScrollPosition } from "../../common/hooks/use-scroll-position.hook";
import { useConditionalDisplayInputParameters } from "../hooks/conditional-display-input-parameters.hook";
import { RouterUtilities } from "../../common/utilities/router.utilities";
import { useTopicMateriality } from "../hooks/topic-materiality.hook";
import { useTranslation } from "react-i18next";
import { DisclosureRequirementCard } from "./esrs-common-components.constants";
import { DrInputParametersList } from "./dr-input-parameters-list.component";
import { PHASE_IN_COLORS } from "../../../theme/theme";

const SECTION_ID_QUERY_KEY = "sectionId";
const SECTION_ID_PHASE_IN = "phaseIn";

function createDisclosureRequirementOrInputParameterUrl(
  sectionId: string,
  disclosureRequirementOrInputParameterId?: string,
) {
  const url = new URL(window.location.toString());
  url.searchParams.set(SECTION_ID_QUERY_KEY, sectionId);
  if (disclosureRequirementOrInputParameterId) {
    url.hash = disclosureRequirementOrInputParameterId;
  } else {
    url.hash = "";
  }

  return url;
}

const createDisclosureRequirementUrl = (
  section: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
  disclosureRequirement?: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
) =>
  createDisclosureRequirementOrInputParameterUrl(
    section.id,
    disclosureRequirement?.id ?? undefined,
  ).toString();

const createInputParameterUrl = (
  sectionId: string,
  inputParameter: ILocalDataEntryObjectInputParameter,
) =>
  createDisclosureRequirementOrInputParameterUrl(
    sectionId,
    inputParameter.inputParameter.id,
  ).toString();

interface IDataEntryObjectESRSValuesOverviewComponentProps {
  organization: IOrganization;
  recordingPeriod: ILocalRecordingPeriod;
  recordingStructure: ILocalInputParameterRecordingStructureESRS;
  organizationStructure: IDataEntryObject;
  phaseInDecisions: IESRSTopicPhaseInDecision[] | null;
  dataEntryObject: IDataEntryObject;
  dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[];
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  onChangeDataEntryObject: (dataEntryObjectId: string) => void;
}

export const DeoEsrsValuesOverviewComponent: FC<
  IDataEntryObjectESRSValuesOverviewComponentProps
> = ({
  organization,
  recordingPeriod,
  recordingStructure,
  organizationStructure,
  phaseInDecisions,
  dataEntryObject,
  dataEntryObjectInputParameters,
  availableDistributionCriteria,
  onChangeDataEntryObject,
}) => {
  const { t } = useTranslation("filter_bar");
  const translateContent = useTranslateContent();
  const [searchParams, setSearchParams] = useSearchParams();

  // Check if PhaseIn is in use
  const isUsingPhaseIn = useMemo(
    () =>
      phaseInDecisions?.find(
        (decision) => decision.esrsTopicIdentifier === recordingStructure.categoryIdentifier,
      )?.makesUseOfPhaseIn,
    [phaseInDecisions, recordingStructure.categoryIdentifier],
  );

  const [expandedDisclosureRequirementIds, setExpandedDisclosureRequirementIds] = useState<
    string[]
  >([]);
  const expandDisclosureRequirement = useCallback(
    (disclosureRequirementId: string) =>
      setExpandedDisclosureRequirementIds((prev) =>
        Array.from(new Set([...prev, disclosureRequirementId])),
      ),
    [],
  );

  const [expandedInputParameterIds, setExpandedInputParameterIds] = useState<string[]>([]);
  const expandInputParameter = useCallback(
    (inputParameterId: string) =>
      setExpandedInputParameterIds((prev) => Array.from(new Set([...prev, inputParameterId]))),
    [],
  );

  // Conditional Input Parameters
  const { conditionalDisplayInputParametersLookup } = useConditionalDisplayInputParameters(
    dataEntryObjectInputParameters,
  );

  // Hydrate, filter and clean up the structure with the data entry object input parameters
  const hydratedStructureGroup = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.hydrateStructureStructureGroup(
        recordingStructure.structure,
        dataEntryObjectInputParameters,
      ),
    [dataEntryObjectInputParameters, recordingStructure.structure],
  );

  const filteredStructureGroup = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.filterStructureGroup(
        hydratedStructureGroup,
        searchParams,
      ),
    [hydratedStructureGroup, searchParams],
  );

  const [completeHydratedStructureGroup /*, incompleteDRs, incompleteIPs */] = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.cleanUpStructureGroup(filteredStructureGroup),
    [filteredStructureGroup],
  );

  // Handle State Change (Router Query)

  const [viewedSectionId, setViewedSectionId] = useSearchParamStateBasic(
    SECTION_ID_QUERY_KEY,
    isUsingPhaseIn ? SECTION_ID_PHASE_IN : completeHydratedStructureGroup.sections[0]?.id ?? "",
    false,
  );

  useEffect(() => {
    if (
      !completeHydratedStructureGroup.sections.some((section) => section.id === viewedSectionId)
    ) {
      setViewedSectionId(
        isUsingPhaseIn ? SECTION_ID_PHASE_IN : completeHydratedStructureGroup.sections[0]?.id ?? "",
      );
    }
  }, [
    completeHydratedStructureGroup.sections,
    viewedSectionId,
    setViewedSectionId,
    isUsingPhaseIn,
  ]);

  const viewedSection = useMemo(
    () => completeHydratedStructureGroup.sections.find((s) => s.id === viewedSectionId) ?? null,
    [completeHydratedStructureGroup.sections, viewedSectionId],
  );
  useDefaultSearchParamsValues({
    key: SECTION_ID_QUERY_KEY,
    value: isUsingPhaseIn
      ? SECTION_ID_PHASE_IN
      : completeHydratedStructureGroup.sections[0]?.id ?? "",
  });

  // Get options for filter bar

  const contributingUserIds = useMemo(() => {
    const ids = dataEntryObjectInputParameters.flatMap((ip) => ip.contributingUserIds);
    return Array.from(new Set(ids));
  }, [dataEntryObjectInputParameters]);

  const responsibleUserIds = useMemo(() => {
    const ids = dataEntryObjectInputParameters
      .map((ip) => ip.responsibleUserId)
      .filter((responsibleUserId): responsibleUserId is string => responsibleUserId !== undefined);
    return Array.from(new Set(ids));
  }, [dataEntryObjectInputParameters]);

  // Next and Previous Section

  const [previousSection, nextSection] = useMemo(() => {
    const currentSectionIndex = completeHydratedStructureGroup.sections.findIndex(
      (s) => s.id === viewedSectionId,
    );
    const previousSection =
      currentSectionIndex > 0
        ? completeHydratedStructureGroup.sections[currentSectionIndex - 1]
        : null;
    const nextSection =
      currentSectionIndex < completeHydratedStructureGroup.sections.length - 1
        ? completeHydratedStructureGroup.sections[currentSectionIndex + 1]
        : null;

    return [previousSection, nextSection];
  }, [completeHydratedStructureGroup.sections, viewedSectionId]);

  const navigate = useNavigate();

  const handleChangeSection = useCallback(
    (
      newSection: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection | null,
    ) => {
      if (!newSection) {
        return undefined;
      }
      return () => {
        if (newSection.disclosureRequirements.length > 0) {
          const newUrl = createDisclosureRequirementOrInputParameterUrl(
            newSection.id,
            newSection.disclosureRequirements[0].id,
          );
          navigate({
            pathname: newUrl.pathname,
            search: newUrl.search,
            hash: newUrl.hash,
          });
        }
      };
    },
    [navigate],
  );

  // Handle viewed Disclosure Requirement

  const location = useLocation();
  const [viewedDisclosureRequirementId, setViewedDisclosureRequirementId] = useState(
    viewedSection?.disclosureRequirements[0]?.id ?? null,
  );

  const handleChangeViewedDisclosureRequirementOrInputParameter = useCallback(
    (disclosureRequirementOrInputParameterId: string) => {
      // Handle PhaseIn
      if (viewedSectionId === SECTION_ID_PHASE_IN) {
        // Expand IP
        expandInputParameter(disclosureRequirementOrInputParameterId);
        // Scroll to IP
        setTimeout(() => {
          const phaseInInputParameterElement = document.getElementById(
            disclosureRequirementOrInputParameterId,
          );
          phaseInInputParameterElement?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 500);
        return;
      }

      // Normal Structure
      // Only works if viewedSection was found
      if (!viewedSection) {
        return;
      }

      const disclosureRequirement = viewedSection.disclosureRequirements.find(
        (dr) =>
          // Find DR with id
          dr.id === disclosureRequirementOrInputParameterId ||
          // If not found, check if IP in DR matches
          dr.inputParameters.some(
            (drIp) => drIp.parameterId === disclosureRequirementOrInputParameterId,
          ),
      );
      if (!disclosureRequirement) {
        return;
      }

      // Ensure the DR is expanded
      expandDisclosureRequirement(disclosureRequirement.id);
      const isDisclosureRequirement =
        disclosureRequirement.id === disclosureRequirementOrInputParameterId;
      if (!isDisclosureRequirement) {
        expandInputParameter(disclosureRequirementOrInputParameterId);
      }

      setTimeout(
        () => {
          // Find Element and scroll to
          const disclosureOrInputParameterCardElement = document.getElementById(
            disclosureRequirementOrInputParameterId,
          );
          if (disclosureOrInputParameterCardElement) {
            // If disclosure requirement, scroll to element directly
            if (isDisclosureRequirement) {
              disclosureOrInputParameterCardElement.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            } else {
              window.scrollTo({
                top:
                  disclosureOrInputParameterCardElement.getBoundingClientRect().y +
                  window.scrollY -
                  72,
                behavior: "smooth",
              });
            }
          } else {
            // If not found, update the state
            setViewedDisclosureRequirementId(disclosureRequirement.id);
          }
        },
        isDisclosureRequirement ? 0 : 500,
      );
    },
    // This is wanted, since this should only be triggered when the viewedSection actually changes (not the object)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expandDisclosureRequirement, expandInputParameter, viewedSectionId, viewedSection?.id],
  );

  const handleChangeDisclosureRequirementInToC = useCallback(
    (
      _: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
      disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
    ) => handleChangeViewedDisclosureRequirementOrInputParameter(disclosureRequirement.id),
    [handleChangeViewedDisclosureRequirementOrInputParameter],
  );

  // Update Scroll Position on hash change
  useEffect(() => {
    const disclosureRequirementOrInputParameterId =
      RouterUtilities.readUuidFromLocationFragment(location);
    if (disclosureRequirementOrInputParameterId) {
      void handleChangeViewedDisclosureRequirementOrInputParameter(
        disclosureRequirementOrInputParameterId,
      );
    }
  }, [handleChangeViewedDisclosureRequirementOrInputParameter, location]);

  // Find the currently viewed Disclosure Requirement
  const viewedDisclosureRequirement = useMemo(
    () =>
      viewedSection?.disclosureRequirements.find((dr) => dr.id === viewedDisclosureRequirementId) ??
      null,
    [viewedDisclosureRequirementId, viewedSection],
  );

  // Scroll Position Tracking (ToC selected Item)

  const handleScrollPositionChange = useMemo(() => {
    const headerIds = viewedSection?.disclosureRequirements.map((dr) => dr.id) ?? [];
    return () => {
      const disclosureCardElements = headerIds.map((id) => document.getElementById(id));
      const disclosureCardElementsBoundingRects = disclosureCardElements
        .map((el) => el?.getBoundingClientRect())
        .reverse();

      const disclosureCardElementIndex = disclosureCardElementsBoundingRects.findIndex(
        (rect) => rect && rect.top < 56,
      );
      // -1 to get the previous element (currently scrolling inside of this element)
      const activeDisclosureElementIndex =
        disclosureCardElementIndex === -1
          ? 0 // Use first Element if no element is active
          : disclosureCardElementsBoundingRects.length - 1 - disclosureCardElementIndex;
      // Update the viewed disclosure requirement
      setViewedDisclosureRequirementId(
        viewedSection?.disclosureRequirements[activeDisclosureElementIndex]?.id ?? null,
      );
    };
  }, [viewedSection]);
  useScrollPosition(16, handleScrollPositionChange);
  // Trigger on hash change
  useEffect(() => {
    handleScrollPositionChange();
  }, [handleScrollPositionChange, location.hash]);

  // Mutations and similar
  const {
    handleUpdateDisclosureRequirementContributingUserIds,
    handleUpdateInputParameterContributingUserIds,
    handleUpdateDisclosureRequirementResponsibleUserId,
    handleUpdateInputParameterResponsibleUserId,
    handleCreateDisclosureRequirementValue,
    handleUpdateDisclosureRequirementValue,
    handleDeleteDisclosureRequirementValue,
    handleCreateInputParameterValue,
    handleUpdateInputParameterValue,
    handleDeleteInputParameterValue,
    handleUpdateTableValue,
    handleResetDisclosureRequirement,
    handleSubmitDisclosureRequirement,
    handleApproveDisclosureRequirement,
    handleRejectDisclosureRequirement,
    handleResetInputParameter,
    handleSubmitInputParameter,
    handleApproveInputParameter,
    handleRejectInputParameter,
    isLoading,
  } = useDeoEsrsApiActions(organization, recordingPeriod, recordingStructure, dataEntryObject);

  const deoEsrsInputContextValue: IDeoEsrsInputContextData = useMemo(
    () => ({
      // Common Data
      organization,
      recordingPeriod,
      organizationStructure,
      dataEntryObject,
      recordingStructureId: recordingStructure.id,
      recordingStructure,
      availableDistributionCriteria,
      conditionalDisplayInputParametersLookup,
      // Handlers
      handleDisclosureRequirementContributingUserIdsChange:
        handleUpdateDisclosureRequirementContributingUserIds,
      handleInputParameterContributingUserIdsChange: handleUpdateInputParameterContributingUserIds,
      handleDisclosureRequirementResponsibleUsersChange:
        handleUpdateDisclosureRequirementResponsibleUserId,
      handleInputParameterResponsibleUsersChange: handleUpdateInputParameterResponsibleUserId,
      handleCreateDrValue: handleCreateDisclosureRequirementValue,
      handleUpdateDrValue: handleUpdateDisclosureRequirementValue,
      handleDeleteDrValue: handleDeleteDisclosureRequirementValue,
      handleCreateIPValue: handleCreateInputParameterValue,
      handleUpdateIPValue: handleUpdateInputParameterValue,
      handleDeleteIPValue: handleDeleteInputParameterValue,
      handleResetDisclosureRequirement: handleResetDisclosureRequirement,
      handleSubmitDisclosureRequirement: handleSubmitDisclosureRequirement,
      handleApproveDisclosureRequirement: handleApproveDisclosureRequirement,
      handleRejectDisclosureRequirement: handleRejectDisclosureRequirement,
      handleUpdateTableValue,
      handleResetInputParameter: handleResetInputParameter,
      handleSubmitInputParameter: handleSubmitInputParameter,
      handleApproveInputParameter: handleApproveInputParameter,
      handleRejectInputParameter: handleRejectInputParameter,
      createDisclosureRequirementUrl: (dr) =>
        viewedSection ? createDisclosureRequirementUrl(viewedSection, dr) : "",
      createInputParameterUrl: (ip) =>
        viewedSectionId ? createInputParameterUrl(viewedSectionId, ip) : "",
      // State
      expandedDisclosureRequirementIds,
      setExpandedDisclosureRequirementIds,
      expandedInputParameterIds,
      setExpandedInputParameterIds,
      // State
      isLoading,
    }),
    [
      organization,
      recordingPeriod,
      organizationStructure,
      dataEntryObject,
      recordingStructure,
      availableDistributionCriteria,
      conditionalDisplayInputParametersLookup,
      handleUpdateDisclosureRequirementContributingUserIds,
      handleUpdateInputParameterContributingUserIds,
      handleUpdateDisclosureRequirementResponsibleUserId,
      handleUpdateInputParameterResponsibleUserId,
      handleCreateDisclosureRequirementValue,
      handleUpdateDisclosureRequirementValue,
      handleDeleteDisclosureRequirementValue,
      handleCreateInputParameterValue,
      handleUpdateInputParameterValue,
      handleDeleteInputParameterValue,
      handleResetDisclosureRequirement,
      handleSubmitDisclosureRequirement,
      handleApproveDisclosureRequirement,
      handleRejectDisclosureRequirement,
      handleUpdateTableValue,
      handleResetInputParameter,
      handleSubmitInputParameter,
      handleApproveInputParameter,
      handleRejectInputParameter,
      expandedDisclosureRequirementIds,
      expandedInputParameterIds,
      isLoading,
      viewedSection,
      viewedSectionId,
    ],
  );

  const isTopicMaterial = useTopicMateriality({
    organizationId: organization.id,
    recordingPeriodId: recordingPeriod.id,
    dataEntryObjectId: dataEntryObject.id,
    categoryIdentifier: recordingStructure.categoryIdentifier,
    allowIncomplete: false,
  });

  const phaseInDrInputParameters: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[] =
    useMemo(
      () =>
        completeHydratedStructureGroup.phaseIn?.inputParameters.map((ip) => ({
          level: 0,
          parameterId: ip.inputParameter.id,
          inputParameter: ip,
        })) ?? [],
      [completeHydratedStructureGroup.phaseIn?.inputParameters],
    );

  return (
    <DeoEsrsInputContextProvider value={deoEsrsInputContextValue}>
      <Box display="flex" flexDirection="column" gap={2} mx="auto" maxWidth={1800}>
        {/* Title */}
        <Typography variant="h4" component="h1">
          {translateContent(recordingStructure.name)}
        </Typography>
        {/* Filter Bar */}
        <DeoEsrsFilterBarComponent
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          organizationStructure={organizationStructure}
          organization={organization}
          dataEntryObject={dataEntryObject}
          onChangeDataEntryObject={onChangeDataEntryObject}
          contributingUserIds={contributingUserIds}
          responsibleUserIds={responsibleUserIds}
        />
        {!isUsingPhaseIn ? (
          // Normal Structure - No Phase In
          <>
            {/* Display message if no sections are available */}
            {completeHydratedStructureGroup.sections.length === 0 && (
              <Box px={1}>
                <Typography>{t("no_matching_results")}</Typography>
              </Box>
            )}
            {/* Main Content */}
            <Grid container spacing={4}>
              {/* Table of Contents */}
              <Grid item xs={3}>
                <Box
                  position="sticky"
                  maxHeight="100dvh"
                  style={{ overflowY: "auto" }}
                  top={0}
                  pt={2}
                  pb={4}
                >
                  <DeoEsrsTableOfContents
                    esrsStructure={completeHydratedStructureGroup}
                    activeDisclosureRequirementId={viewedDisclosureRequirement?.id ?? null}
                    generateDisclosureRequirementUrl={createDisclosureRequirementUrl}
                    onChangeDisclosureRequirement={handleChangeDisclosureRequirementInToC}
                  />
                </Box>
              </Grid>
              {/* DRs (main Content) */}
              {viewedSection && (
                <Fade key={viewedSection.id} in appear>
                  <Grid item xs={9} pb={10}>
                    <DeoEsrsValuesOverviewContent
                      section={viewedSection}
                      onPreviousSection={handleChangeSection(previousSection)}
                      onNextSection={handleChangeSection(nextSection)}
                      materiality={isTopicMaterial}
                    />
                  </Grid>
                </Fade>
              )}
            </Grid>
          </>
        ) : (
          // Phase In Structure
          <>
            {/* No matches for Filters Notice */}
            {completeHydratedStructureGroup.phaseIn?.inputParameters.length === 0 && (
              <Box px={1}>
                <Typography>{t("no_matching_results")}</Typography>
              </Box>
            )}
            {/* Phase In Card */}
            <DisclosureRequirementCard isExpanded>
              <Box display="flex" flexDirection="column" gap={2} p={2}>
                {/* Title */}
                <Typography variant="h6" component="h3" px={1}>
                  {t("phase_in_title", {
                    ns: "data_entry_object_values_overview_esrs_component",
                  })}
                </Typography>
                {/* Phase In Info Card */}
                <Alert
                  severity="info"
                  sx={{
                    color: PHASE_IN_COLORS.color,
                    backgroundColor: PHASE_IN_COLORS.backgroundColor,
                    ".MuiAlert-icon": { color: PHASE_IN_COLORS.color },
                  }}
                >
                  {t("phase_in_info_box.text", {
                    ns: "data_entry_object_values_overview_esrs_component",
                  })}
                </Alert>
                {/* Phase In Input Parameters */}
                <DrInputParametersList
                  disclosureRequirementInputParameters={phaseInDrInputParameters}
                  drIsExcluded={false}
                />
              </Box>
            </DisclosureRequirementCard>
          </>
        )}
      </Box>
    </DeoEsrsInputContextProvider>
  );
};
