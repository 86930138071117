import { PolicyEditDialog } from "./policy-edit.dialog";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IPolicy,
  IPolicyData,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo } from "react";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import {
  useCreatePolicyMutation,
  useDeletePolicyMutation,
  useUpdatePolicyMutation,
} from "../policies.mutations";
import { useTranslation } from "react-i18next";
import { DataEntryObjectInputParameterValueDefinitionForPolicy } from "@netcero/netcero-common";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import {
  IMdrEntityData,
  MdrEntityTable,
} from "../../minimum-disclosure-requirements-common/components/mdr-entity-table.component";

interface IPoliciesTableProps {
  policies: IPolicy[];
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForPolicy) => void;
  disabled?: boolean;
}

export const PoliciesTable: FC<IPoliciesTableProps> = ({
  policies,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_policy_component");

  const createPolicyMutation = useCreatePolicyMutation();
  const updatePolicyMutation = useUpdatePolicyMutation();
  const deletePolicyMutation = useDeletePolicyMutation();

  const policiesEntitiesData: IMdrEntityData<IPolicy>[] = useMemo(
    () =>
      policies.map((policy) => ({
        id: policy.id,
        title: policy.general.name,
        responsibleUserId: policy.responsibleUserId,
        data: policy,
      })),
    [policies],
  );

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();
  useResetMutationsOnOpen(isCreateOpen, createPolicyMutation);

  const {
    isOpen: isEditOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: policyData,
  } = useDialogState<IPolicy>();
  useResetMutationsOnOpen(isEditOpen, updatePolicyMutation);

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialogDialog,
    closeDialog: closeDeleteDialog,
    data: deletePolicyId,
  } = useDialogState<string>();
  useResetMutationsOnOpen(isDeleteDialogOpen, deletePolicyMutation);

  const handleCreate = useCallback(
    async (payload: IPolicyData | null) => {
      if (payload !== null) {
        await createPolicyMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          payload,
        });
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createPolicyMutation, dataEntryObjectId, organizationId, recordingPeriodId],
  );

  const handleEdit = useCallback(
    async (payload: IPolicyData | null) => {
      if (payload !== null) {
        await updatePolicyMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          policyId: policyData?.id ?? "",
          payload,
        });
      }
      closeEditDialog();
    },
    [
      closeEditDialog,
      dataEntryObjectId,
      updatePolicyMutation,
      organizationId,
      policyData?.id,
      recordingPeriodId,
    ],
  );

  const handleDelete = useCallback(
    async (policyId: string | null, confirm: boolean | undefined) => {
      if (!policyId || !confirm) {
        closeDeleteDialog();
        return;
      }
      await deletePolicyMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        policyId,
      });
      closeDeleteDialog();
      closeEditDialog();
    },
    [
      closeDeleteDialog,
      closeEditDialog,
      dataEntryObjectId,
      deletePolicyMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  return (
    <>
      {/* Dialogs */}
      <PolicyEditDialog
        open={isCreateOpen}
        loading={createPolicyMutation.isPending}
        onClose={handleCreate}
        error={createPolicyMutation.error}
        disabled={createPolicyMutation.isPending}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
      />
      <PolicyEditDialog
        open={isEditOpen}
        loading={updatePolicyMutation.isPending}
        onClose={handleEdit}
        onDelete={(policyId: string) => openDeleteDialogDialog(policyId)}
        error={updatePolicyMutation.error}
        disabled={updatePolicyMutation.isPending}
        policy={policyData}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
      />
      <ConfirmDialogTextBody
        open={isDeleteDialogOpen}
        error={deletePolicyMutation.error}
        loading={deletePolicyMutation.isPending}
        disabled={deletePolicyMutation.isPending}
        onClose={(confirm: boolean) => handleDelete(deletePolicyId!, confirm)}
        title={t("confirm_dialog.title")}
        content={t("confirm_dialog.body")}
        confirmOverride={t("confirm_dialog.button_confirm")}
      />

      {/* Content */}
      <MdrEntityTable
        organizationId={organizationId}
        entities={policiesEntitiesData}
        onEdit={(entity) => openEditDialog(entity.data)}
        noEntitiesPresentNotice={t("no_policies_defined")}
        buttonChooseNoEntitiesForTopicText={t("button_choose_no_policies_for_topic")}
        onChooseNoEntitiesForTopic={() => onChange(false)}
        tooltipAddNew={t("tooltip_add_policy")}
        onAddNew={openCreateDialog}
        disabled={disabled}
      />
    </>
  );
};
