import { useTranslation } from "react-i18next";
import { useTranslateValidationError } from "../../value-acquisition/translate-validation-error.hook";
import { useCallback, useMemo } from "react";
import {
  IValuesErrorsEntry,
  IValuesErrorsPerKey,
} from "../utilities/data-entry-object-values.utilities";

export const useTranslateValueError = () => {
  const { t: valueValidationErrors } = useTranslation("value_validation_errors");
  const { translateValidationError } = useTranslateValidationError();

  return useCallback(
    (error: IValuesErrorsEntry | undefined) => {
      if (error === undefined) {
        return undefined;
      }

      return typeof error === "string"
        ? valueValidationErrors(error)
        : translateValidationError(error.error, error.inputType);
    },
    [valueValidationErrors, translateValidationError],
  );
};

export const useTranslatedValuesErrors = (errors: IValuesErrorsPerKey) => {
  const translateError = useTranslateValueError();

  return useMemo(() => {
    return Object.entries(errors).reduce(
      (acc, [key, error]) => ({
        ...acc,
        [key]: translateError(error),
      }),
      {},
    );
  }, [errors, translateError]);
};
