import { Autocomplete, Checkbox, TextField } from "@mui/material";
import {
  IInputParameterValueMetaDataOptions,
  IInputParameterValueMetaDataOptionsOption,
} from "@netcero/netcero-core-api-client";
import { Dispatch, FC, useMemo } from "react";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";
import {
  alwaysArray,
  DataEntryObjectInputParameterValueDefinitionForOptions,
  DataEntryObjectInputParameterValueDefinitionForOptionsMultiple,
  DataEntryObjectInputParameterValueDefinitionForOptionsSingle,
  generateLookUp,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete/Autocomplete";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";

interface IOptionsInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>;
  onChange: (
    value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptions>,
  ) => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataOptions;
  disableMaxWidth?: boolean;
  label?: string;
  required?: boolean;
}

export const OptionsInputComponent: FC<IOptionsInputComponentProps> = (props) => {
  const optionsLookup = useMemo(
    () => generateLookUp(props.metaData.options, (option) => option.value),
    [props.metaData.options],
  );

  if (props.metaData.multiple) {
    return (
      <MultipleOptionsInput
        {...props}
        value={
          props.value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptionsMultiple>
        }
        optionsLookup={optionsLookup}
      />
    );
  } else {
    return (
      <SingleOptionsInput
        {...props}
        value={
          props.value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptionsSingle>
        }
        optionsLookup={optionsLookup}
      />
    );
  }
};

interface IOptionsInputPropsBase<T>
  extends Omit<IOptionsInputComponentProps, "value" | "onChange"> {
  value: T;
  onChange: Dispatch<T>;
  optionsLookup: Record<
    IInputParameterValueMetaDataOptionsOption["value"],
    IInputParameterValueMetaDataOptionsOption
  >;
}

type IMultipleOptionsInputProps = IOptionsInputPropsBase<
  OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptionsMultiple>
>;

const MultipleOptionsInput: FC<IMultipleOptionsInputProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  label,
  required,
  optionsLookup,
}) => {
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      value={value ? value.map((v) => optionsLookup[v]) : []}
      onChange={(_, newValue) => {
        onChange(newValue ? alwaysArray(newValue).map((v) => v.value) : []);
      }}
      options={metaData.options}
      getOptionLabel={(option) => translateOptionalContent(option.name) ?? "Error: Unknown value"}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.value} style={{ ...props.style, borderRadius: 8 }}>
          <Checkbox style={{ marginRight: 8 }} checked={selected} />
          <LineClampTypographyWithTooltip>
            {translateContent(option.name)}
          </LineClampTypographyWithTooltip>
        </li>
      )}
      renderInput={(params) => (
        <AutocompleteTextInput params={params} {...{ variant, label, error, required, metaData }} />
      )}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined }}
      disabled={disabled}
    />
  );
};

type ISingleOptionsInputProps = IOptionsInputPropsBase<
  OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForOptionsSingle>
>;

const SingleOptionsInput: FC<ISingleOptionsInputProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  label,
  required,
  optionsLookup,
}) => {
  const translateOptionalContent = useTranslateOptionalContent();

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      value={value ? optionsLookup[value] : null}
      onChange={(_, newValue) => {
        onChange(newValue?.value ?? undefined);
      }}
      options={metaData.options}
      getOptionLabel={(option) => translateOptionalContent(option.name) ?? "ERROR: Unknown value"}
      renderInput={(params) => (
        <AutocompleteTextInput params={params} {...{ variant, label, error, required, metaData }} />
      )}
      fullWidth
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined }}
      disabled={disabled}
    />
  );
};

interface IAutocompleteTextInputProps
  extends Pick<
    IOptionsInputComponentProps,
    "variant" | "label" | "error" | "required" | "metaData"
  > {
  params: AutocompleteRenderInputParams;
}

const AutocompleteTextInput: FC<IAutocompleteTextInputProps> = ({
  variant,
  label,
  error,
  params,
  required,
  metaData,
}) => {
  const variantProps = useVariantFormProps(variant);
  const sxProps = useVariantSxStyles(variant);
  const translateOptionalContent = useTranslateOptionalContent();

  const placeholderText = useMemo(
    () => translateOptionalContent(metaData.placeholder),
    [metaData.placeholder, translateOptionalContent],
  );

  return (
    <TextField
      {...params}
      {...variantProps}
      label={label}
      InputLabelProps={{
        ...params.InputLabelProps,
        shrink: !!placeholderText || undefined,
      }}
      required={required}
      InputProps={{
        ...params.InputProps,
        sx: {
          ...sxProps,
          minWidth: 260,
        },
      }}
      error={!!error}
      helperText={error}
      placeholder={placeholderText}
    />
  );
};
