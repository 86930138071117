import { FC, useRef, useState } from "react";
import { LinkedSourcesChip } from "./linked-sources-chip.component";
import { ISource } from "@netcero/netcero-core-api-client";
import { LinkedSourcesCheckboxMenu } from "./linked-sources-checkbox-menu.component";

interface ILinkedSourcesChipWithMenuProps {
  linkedSourceIds: string[];
  sources: ISource[];
  onChange: (linkedSourceIds: string[]) => void;
  organizationId: string;
  disabled?: boolean;
}

export const LinkedSourcesChipWithMenu: FC<ILinkedSourcesChipWithMenuProps> = ({
  linkedSourceIds,
  sources,
  onChange,
  organizationId,
  disabled,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const chipRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* Menu */}
      <LinkedSourcesCheckboxMenu
        open={showMenu}
        onClose={() => setShowMenu(false)}
        anchorElement={chipRef.current}
        sources={sources}
        linkedSourceIds={linkedSourceIds}
        onChange={onChange}
        organizationId={organizationId}
        disabled={disabled}
      />

      {/* Chip */}
      <LinkedSourcesChip
        ref={chipRef}
        linkedSourceIds={linkedSourceIds}
        open={showMenu}
        onClick={() => setShowMenu(true)}
        showChevron
        disabled={disabled}
      />
    </>
  );
};
