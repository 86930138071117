import { FC } from "react";
import { Box } from "@mui/material";
import { ITranslatableError } from "../hooks/translate-error.hook";
import { ErrorTextComponent } from "../components/error-text.component";

interface IDialogErrorTextProps {
  error: ITranslatableError | undefined | null;
}

export const DialogErrorText: FC<IDialogErrorTextProps> = ({ error }) => {
  return (
    error && (
      <Box mb={2}>
        <ErrorTextComponent error={error} />
      </Box>
    )
  );
};
