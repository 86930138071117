import Keycloak from "keycloak-js";
import { EnvironmentUtilities } from "./modules/common/utilities/environment.utilities";

export const KEYCLOAK_URL = EnvironmentUtilities.KEYCLOAK_URL;
export const KEYCLOAK_REALM = EnvironmentUtilities.KEYCLOAK_REALM;
export const KEYCLOAK_CLIENT_ID = EnvironmentUtilities.KEYCLOAK_CLIENT_ID;

const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
});

export default keycloak;
