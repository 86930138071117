import { Box, SvgIcon, Typography } from "@mui/material";
import { IIROState } from "@netcero/netcero-core-api-client";
import { IconProps } from "@tabler/icons-react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DMA_IRO_STATE_COLORS, DMA_IRO_STATE_ICONS } from "./dma.constants";

interface IIROStateIndicatorProps {
  state: IIROState;
}
export const IROStateIndicator: FC<IIROStateIndicatorProps> = ({ state }) => {
  const { t } = useTranslation("dma_common");

  const [color, Icon] = useMemo(() => {
    return [DMA_IRO_STATE_COLORS[state], DMA_IRO_STATE_ICONS[state] as FC<IconProps>];
  }, [state]);

  return (
    <Box display="flex" alignItems="end">
      <SvgIcon color={color}>
        <Icon />
      </SvgIcon>
      {/* TODO: subtitle1 isn't fitting here, update at some point */}
      <Typography variant="subtitle1">{t(`iro_state_${state}`)}</Typography>
    </Box>
  );
};
