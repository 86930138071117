import { Dispatch, FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IPolicy } from "@netcero/netcero-core-api-client";
import { DialogCloseButton } from "../../../common/dialogs/dialog-button.components";
import { sorted } from "@netcero/netcero-common";
import { useDebounce } from "@uidotdev/usehooks";
import { MdrEsrsTopicIndicators } from "../../../minimum-disclosure-requirements-common/components/mdr-esrs-topic-indicator.component";
import { SearchTextInput } from "../../../common/components/search-text-input.component";
import { LineClampTypographyWithTooltip } from "../../../common/components/line-clamp-typography.component";

interface IPoliciesSelectDialogProps {
  open: boolean;
  title: string;
  onClose: () => void;
  policies: IPolicy[];
  selectedPoliciesIds: string[];
  onChange: Dispatch<string[]>;
}

export const PoliciesSelectDialog: FC<IPoliciesSelectDialogProps> = ({
  open,
  title,
  onClose,
  policies,
  selectedPoliciesIds,
  onChange,
}) => {
  const { t } = useTranslation("policies_select_dialog");

  const handleChangePolicy = useCallback(
    (policy: IPolicy) => {
      if (selectedPoliciesIds.includes(policy.id)) {
        onChange(selectedPoliciesIds.filter((id) => id !== policy.id));
      } else {
        onChange(sorted([...selectedPoliciesIds, policy.id]));
      }
    },
    [selectedPoliciesIds, onChange],
  );

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (!open) {
      setTimeout(() => setSearch(""), 100);
    }
  }, [open]);
  const debouncedSearch = useDebounce(search, 250);

  const filteredPolicies = useMemo(
    () =>
      policies.filter((policy) =>
        policy.general.name.toLowerCase().includes(debouncedSearch.toLowerCase()),
      ),
    [policies, debouncedSearch],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {/* Search */}
        <SearchTextInput
          search={search}
          onSearchChange={setSearch}
          textFieldProps={{
            sx: { mt: 0.5, mb: 2 },
          }}
        />

        {/* Table */}
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t("table_header_name")}</TableCell>
              <TableCell align="right">{t("table_header_topics")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPolicies.map((policy) => (
              <PoliciesSelectDialogTableRow
                key={policy.id}
                policy={policy}
                selectedPoliciesIds={selectedPoliciesIds}
                onChange={() => handleChangePolicy(policy)}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <DialogCloseButton onClick={onClose}>{t("close", { ns: "buttons" })}</DialogCloseButton>
      </DialogActions>
    </Dialog>
  );
};

interface IPoliciesSelectDialogTableRowProps {
  policy: IPolicy;
  selectedPoliciesIds: string[];
  onChange: VoidFunction;
}

const PoliciesSelectDialogTableRow: FC<IPoliciesSelectDialogTableRowProps> = ({
  policy,
  selectedPoliciesIds,
  onChange,
}) => {
  const isSelected = useMemo(
    () => selectedPoliciesIds.includes(policy.id),
    [policy.id, selectedPoliciesIds],
  );

  return (
    <TableRow>
      <TableCell onClick={onChange} sx={{ width: 74, cursor: "pointer" }}>
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell onClick={onChange} sx={{ cursor: "pointer" }}>
        <LineClampTypographyWithTooltip maxLines={3}>
          {policy.general.name}
        </LineClampTypographyWithTooltip>
      </TableCell>
      <TableCell onClick={onChange} sx={{ cursor: "pointer" }}>
        <Box display="flex" flexWrap="wrap" gap={1} justifyContent="end">
          <MdrEsrsTopicIndicators topics={policy.associatedTopics} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
