import { Chip, ChipProps, Skeleton } from "@mui/material";
import { IStakeholder } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { useStakeholdersQuery } from "./stakeholder.queries";

interface IStakeholderChipProps {
  stakeholder: IStakeholder;
  size?: ChipProps["size"];
  onDelete?: () => void;
}
/** There also is a FetchingStakeholderChip, which automatically fetches the stakeholder for a given id */
export const StakeholderChip: FC<IStakeholderChipProps> = ({ stakeholder, size, onDelete }) => {
  return (
    <Chip
      variant="outlined"
      size={size}
      label={`${stakeholder.name}${
        stakeholder.organization ? ` (${stakeholder.organization})` : ""
      }`}
      onDelete={onDelete}
    />
  );
};

// Fetching Stakeholder Chip

interface IFetchingStakeholderChipProps {
  organizationId: string;
  stakeholderId: string;
  size?: ChipProps["size"];
  onDelete?: () => void;
}
export const FetchingStakeholderChip: FC<IFetchingStakeholderChipProps> = ({
  organizationId,
  stakeholderId,
  size,
  onDelete,
}) => {
  const stakeholdersQuery = useStakeholdersQuery(organizationId);

  const stakeholder = useMemo(
    () => stakeholdersQuery.data?.stakeholders.find((s) => s.id === stakeholderId),
    [stakeholdersQuery.data, stakeholderId],
  );

  return stakeholder ? (
    <StakeholderChip size={size} stakeholder={stakeholder} onDelete={onDelete} />
  ) : (
    <Skeleton variant="rounded" height={22} width={140} />
  );
};
