export enum ESRSTopicIdentifier {
  ESRS_2 = "esrs_2",
  ESRS_E1 = "esrs_e1",
  ESRS_E2 = "esrs_e2",
  ESRS_E3 = "esrs_e3",
  ESRS_E4 = "esrs_e4",
  ESRS_E5 = "esrs_e5",
  ESRS_S1 = "esrs_s1",
  ESRS_S2 = "esrs_s2",
  ESRS_S3 = "esrs_s3",
  ESRS_S4 = "esrs_s4",
  ESRS_G1 = "esrs_g1",
  ESRS_O = "esrs_o",
}

export interface IESRSTopicMetadata {
  supportsPhaseIn: boolean;
}
