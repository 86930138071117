import { IAbsoluteTargetPathGoal, IRelativeTargetPathGoal } from "@netcero/netcero-core-api-client";
import { TargetPathGoalType } from "./target-path-goals.public-types";

export class TargetPathGoalsDefaults {
  public static getDefaultsForGoalType(type: TargetPathGoalType) {
    return type === "absolute"
      ? TargetPathGoalsDefaults.getDefaultAbsoluteConfig()
      : TargetPathGoalsDefaults.getDefaultRelativeConfig();
  }

  public static getDefaultAbsoluteConfig(): IAbsoluteTargetPathGoal {
    return {
      type: "absolute",
      reductionScope1: { type: "inPercent", value: 0 },
      reductionScope2: { type: "inPercent", value: 0 },
      reductionScope3: { type: "inPercent", value: 0 },
    };
  }

  public static getDefaultRelativeConfig(): IRelativeTargetPathGoal {
    return {
      type: "relative",
      targetRevenue: {
        // "toValue" is the default since it works regardless of whether a reference revenue is given
        type: "toValue",
        value: 0,
      },
      intensities: {
        intensityScope1: 0,
        intensityScope2: 0,
        intensityScope3: 0,
      },
    };
  }
}
