import { Box, DialogContentText, TextField } from "@mui/material";
import { IESRSTopic, IOptOutOfDmaCategoryRequest } from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { useRenderESRSTopicName } from "../hooks/render-esrs-topic-name.hook";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";

interface IESRSTopicOptOutDialogProps {
  open: boolean;
  esrsTopic: IESRSTopic | null;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IOptOutOfDmaCategoryRequest | null) => void;
}

/**
 * @param open Whether the dialog is open or not
 * @param esrsTopic The ESRSTopic to opt out of
 * @param loading Whether the dialog should indicate that it is loading
 * @param error An error message to display
 * @param disabled Whether the dialog is disabled or not
 * @param onClose Callback to close the dialog. If the user confirms
 * the opt-out, the callback will be called with the opt-out request.
 * If the user cancels, the callback will be called with null.
 */
export const EsrsTopicOptOutDialog: FC<IESRSTopicOptOutDialogProps> = ({
  open,
  esrsTopic,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("esrs_topic_opt_out_confirm_dialog");

  const isInEditMode = !!esrsTopic?.recordedESRSTopic?.optOut;

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<IOptOutOfDmaCategoryRequest>({
    defaultValues: {
      optOut: true,
      optOutReason: "",
    },
  });

  useEffect(() => {
    if (open) {
      // Reset the form when the dialog is opened
      reset({
        optOut: true,
        optOutReason: esrsTopic?.recordedESRSTopic?.optOutReason ?? "",
      });
    }
  }, [open, esrsTopic, reset]);

  const renderTopicName = useRenderESRSTopicName();

  return (
    <EditDialogWrapper
      open={open}
      title={t(isInEditMode ? "title_edit" : "title_opt_out", {
        name: renderTopicName(esrsTopic),
        topic: esrsTopic?.topic,
      })}
      mode={isInEditMode ? "edit" : "create"}
      loading={!!loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(onClose)}
      error={error}
      dialogProps={{ fullWidth: true, maxWidth: "lg" }}
      disabled={disabled}
    >
      <Box display="flex" flexDirection="column" gap={2} pt={1}>
        {error && (
          <Box my={1}>
            <ErrorTextComponent error={error} />
          </Box>
        )}
        {!isInEditMode && (
          <DialogContentText>
            {t(`content_text`, { name: renderTopicName(esrsTopic), topic: esrsTopic?.topic })}
          </DialogContentText>
        )}
        <Controller
          control={control}
          name="optOutReason"
          rules={{ required: t("opt_out_reason_required") }}
          render={({ field, fieldState: { error: fieldError } }) => (
            <TextField
              {...field}
              label={t("opt_out_reason_label")}
              variant="outlined"
              multiline
              minRows={3}
              error={!!fieldError}
              helperText={fieldError?.message}
              disabled={disabled}
            />
          )}
        />
      </Box>
    </EditDialogWrapper>
  );
};
