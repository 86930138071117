import { CircularProgress } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { ErrorTextComponent } from "./error-text.component";
import { CenteredCircularProgress } from "./centered-circular-progress.component";
import { UseQueryResult } from "@tanstack/react-query";

type GenericBase = readonly unknown[];
type QueryResultMapped<T> = { [I in keyof T]: UseQueryResult<T[I]> };

interface IQueriesWrapperProps<T extends GenericBase> {
  queries: QueryResultMapped<T>;
  build: (results: T) => ReactNode;
  loadingOverride?: (loadingResult: QueryResultMapped<T>) => ReactNode;
  errorOverride?: (errorResult: QueryResultMapped<T>) => ReactNode;
  centerLoader?: boolean;
}

export function QueriesWrapper<T extends GenericBase>({
  queries,
  build,
  loadingOverride,
  errorOverride,
  centerLoader,
}: IQueriesWrapperProps<T>) {
  const isLoading = useMemo(() => queries.some((query) => query.isLoading), [queries]);
  const error = useMemo(() => queries.find((query) => query.isError)?.error ?? null, [queries]);
  const hasData = useMemo(() => queries.every((query) => query.data !== undefined), [queries]);

  const loadingComponent =
    loadingOverride?.(queries) ??
    (centerLoader ? <CenteredCircularProgress /> : <CircularProgress />);

  if (isLoading) {
    return loadingComponent;
  } else if (error) {
    return errorOverride?.(queries) ?? <ErrorTextComponent error={error} />;
  }
  return hasData ? build(queries.map((query) => query.data) as unknown as T) : loadingComponent;
}
