import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { RecordingPeriodsEditListComponent } from "../../modules/recording-periods/recording-periods-edit-list.component";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";

export const RecordingPeriodsConfigurationPage: FC = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();

  const currentOrganization = useCurrentOrganization();
  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId);

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/recording-periods`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/recording-periods`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  return (
    <Box p={4}>
      {currentOrganization && (
        <QueryWrapper
          query={recordingPeriodsQuery}
          loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
          build={(data) => (
            <RecordingPeriodsEditListComponent
              organization={currentOrganization}
              recordingPeriods={data.recordingPeriods}
            />
          )}
        />
      )}
    </Box>
  );
};
