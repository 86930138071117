import {
  IDMACategoryWithEffectsAndChildren,
  IDMACategoryWithEffectsAndFeedbacksAndChildren,
  IStakeholderFeedback,
  IStakeholderFeedbackDetails,
  ISubmitStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo } from "react";
import { Box, Button, Fade, Grid, Typography } from "@mui/material";
import { StakeholderFeedbackCategoryComponent } from "./stakeholder-feedback-category.component";
import { StakeholderFeedbackDialog } from "../dialogs/stakeholder-feedback.dialog";
import { useDialogState } from "../../common/dialogs/dialog-state.hook";
import { StakeholderFeedbackUtilities } from "../utilities/stakeholder-feedback.utitilities";
import { useSubmitStakeholderFeedbackMutation } from "../stakeholder-feedbacks.mutations";
import { useTranslation } from "react-i18next";
import {
  IGetLinkForEntry,
  TableOfContents,
} from "../../common/components/table-of-contents.component";
import { useGetTocEntriesFromStakeholderFeedbackDetails } from "../hooks/get-toc-entries-from-stakeholder-feedback-details.hook";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { generateStakeholderFeedbackOverviewLink } from "./stakeholder-feedback-overview.component";
import { ChevronLeftIcon, ChevronRightIcon } from "../../common/constants/tabler-icon.constants";

const TOPIC_ID_SEARCH_PARAM_KEY = "topicId";
const CATEGORY_ID_SEARCH_PARAM_KEY = "categoryId";

function getUrlForStakeholderFeedbacksComponent(
  stakeholderId: string,
  recordingPeriodId: string,
  topicId: string,
  categoryId: string,
) {
  const url = generateStakeholderFeedbackOverviewLink(stakeholderId, recordingPeriodId);
  url.searchParams.set(TOPIC_ID_SEARCH_PARAM_KEY, topicId);
  url.searchParams.set(CATEGORY_ID_SEARCH_PARAM_KEY, categoryId);
  return url;
}

interface IStakeholderFeedbacksComponentProps {
  stakeholderId: string;
  recordingPeriodId: string;
  details: IStakeholderFeedbackDetails;
}

interface IStakeholderFeedbackDialogState {
  feedback: IStakeholderFeedback;
  category: Pick<
    IDMACategoryWithEffectsAndChildren,
    "id" | "name" | "materiality" | "optOutReason"
  >;
  create: boolean;
}

export const StakeholderFeedbacksComponent: FC<IStakeholderFeedbacksComponentProps> = ({
  stakeholderId,
  details,
  recordingPeriodId,
}) => {
  const { t } = useTranslation("stakeholder_feedbacks_component");
  const [searchParams] = useSearchParams();

  // Feedback dialog
  const { isOpen, openDialog, closeDialog, data } =
    useDialogState<IStakeholderFeedbackDialogState>();

  const submitStakeholderFeedbackMutation = useSubmitStakeholderFeedbackMutation();

  const handleSubmitFeedbackDialog = useCallback(
    async (data: { categoryId: string; feedback: ISubmitStakeholderFeedbackRequest } | null) => {
      if (data) {
        await submitStakeholderFeedbackMutation.mutateAsync({
          stakeholderId,
          dmaCategoryId: data.categoryId,
          payload: data.feedback,
          recordingPeriodId,
        });
      }
      closeDialog();
    },
    [submitStakeholderFeedbackMutation, closeDialog, stakeholderId, recordingPeriodId],
  );

  const tableOfContentsEntries = useGetTocEntriesFromStakeholderFeedbackDetails(details);
  const getTableOfContentsEntryLink: IGetLinkForEntry = useCallback(
    (_, [topicEntry, topCategoryEntry]) => {
      return getUrlForStakeholderFeedbacksComponent(
        stakeholderId,
        recordingPeriodId,
        topicEntry.id,
        topCategoryEntry?.id ?? topicEntry.children?.[0].id ?? "",
      ).toString();
    },
    [recordingPeriodId, stakeholderId],
  );

  const viewedTopic = useMemo(() => {
    return (
      details.esrsTopics.find(
        (t) => t.topic.identifier === searchParams.get(TOPIC_ID_SEARCH_PARAM_KEY),
      ) ?? (details.esrsTopics.length > 0 ? details.esrsTopics[0] : undefined)
    );
  }, [searchParams, details.esrsTopics]);
  const viewedCategory = useMemo(() => {
    return (
      viewedTopic?.dmaCategories.find(
        (cat) => cat.id === searchParams.get(CATEGORY_ID_SEARCH_PARAM_KEY),
      ) ??
      (viewedTopic?.dmaCategories[0] as IDMACategoryWithEffectsAndFeedbacksAndChildren | undefined)
    );
  }, [searchParams, viewedTopic?.dmaCategories]);

  const [previous, next] = useMemo(() => {
    if (!viewedTopic || !viewedCategory) {
      return [null, null];
    }
    const esrsTopicIndex = details.esrsTopics.findIndex(
      (t) => t.topic.identifier === viewedTopic.topic.identifier,
    );
    const dmaCategoryIndex = viewedTopic.dmaCategories.findIndex((c) => c.id === viewedCategory.id);

    let previousEsrsTopic = details.esrsTopics[esrsTopicIndex];
    let previousCategory = viewedTopic.dmaCategories[dmaCategoryIndex - 1];
    if (!previousCategory) {
      previousEsrsTopic = details.esrsTopics[esrsTopicIndex - 1] ?? null;
      previousCategory =
        previousEsrsTopic?.dmaCategories[previousEsrsTopic.dmaCategories.length - 1] ?? null;
    }

    let nextEsrsTopic = details.esrsTopics[esrsTopicIndex];
    let nextCategory = viewedTopic.dmaCategories[dmaCategoryIndex + 1];
    if (!nextCategory) {
      nextEsrsTopic = details.esrsTopics[esrsTopicIndex + 1] ?? null;
      nextCategory = nextEsrsTopic?.dmaCategories[0] ?? null;
    }

    return [
      previousCategory
        ? {
            category: previousCategory,
            topic: previousEsrsTopic,
          }
        : null,
      nextCategory ? { category: nextCategory, topic: nextEsrsTopic } : null,
    ];
  }, [details.esrsTopics, viewedCategory, viewedTopic]);

  return (
    <>
      <StakeholderFeedbackDialog
        open={isOpen}
        onClose={handleSubmitFeedbackDialog}
        feedback={data?.feedback ?? null}
        category={data ? data.category : null}
        createMode={data?.create || false}
        loading={submitStakeholderFeedbackMutation.isPending}
        disabled={submitStakeholderFeedbackMutation.isPending}
        error={
          submitStakeholderFeedbackMutation.isError ? submitStakeholderFeedbackMutation.error : null
        }
      />
      <Box width="100%">
        {/* Display message if no feedback has been requested yet*/}
        {details.esrsTopics.length === 0 || !viewedCategory ? (
          <Typography mt={3}>{t("no_feedback_requested")}</Typography>
        ) : (
          /* Render feedback */
          <Grid container spacing={4}>
            {/* ToC */}
            <Grid item xs={4} md={3}>
              <Box pt={4} position="sticky" top={-16} maxHeight="100dvh" sx={{ overflowY: "auto" }}>
                <TableOfContents
                  entries={tableOfContentsEntries}
                  activeEntryId={viewedCategory.id}
                  getLinkForEntry={getTableOfContentsEntryLink}
                  maxLevels={1}
                />
              </Box>
            </Grid>
            <Grid item xs={8} md={9}>
              <Fade key={viewedCategory.id} in appear>
                <Box>
                  <StakeholderFeedbackCategoryComponent
                    stakeholderId={stakeholderId}
                    category={viewedCategory}
                    onShowFeedbackDialog={(feedback, category) => {
                      submitStakeholderFeedbackMutation.reset();
                      openDialog({
                        feedback,
                        create: !StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted(feedback),
                        category,
                      });
                    }}
                    disabled={submitStakeholderFeedbackMutation.isPending}
                  />
                </Box>
              </Fade>
              {/* Back and Forward Buttons */}
              <Box display="flex">
                {previous && (
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to={getUrlForStakeholderFeedbacksComponent(
                      stakeholderId,
                      recordingPeriodId,
                      previous.topic.topic.identifier,
                      previous.category.id,
                    ).toString()}
                  >
                    <ChevronLeftIcon />
                    {t("buttons.previous")}
                  </Button>
                )}
                <Box flex={1} />
                {next && (
                  <Button
                    variant="outlined"
                    component={RouterLink}
                    to={getUrlForStakeholderFeedbacksComponent(
                      stakeholderId,
                      recordingPeriodId,
                      next.topic.topic.identifier,
                      next.category.id,
                    ).toString()}
                  >
                    {t("buttons.next")}
                    <ChevronRightIcon />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
};
