import { Box, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { FormatTranslation } from "../../../common/components/format-translation.component";
import {
  DialogCancelButton,
  DialogConfirmButton,
} from "../../../common/dialogs/dialog-button.components";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAMaterialImpact,
} from "@netcero/netcero-core-api-client";
import { useESRSTopicsQuery } from "../../dma.queries";
import { QueryWrapper } from "../../../common/components/query-wrapper.component";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";
import { SelectChangeEvent } from "@mui/material/Select";
import { BaseAsyncActionDialog } from "../../../common/dialogs/variants/base-async-action.dialog";
import { IImpactOrEffectType } from "../../common/types/impact-or-effect.type";

interface IMoveImpactOrEffectConfirmDialogProps {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  type: IImpactOrEffectType;
  effectOrImpact: IDMAMaterialImpact | IDMAFinancialEffect | null;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  onClose: (selectedCategory: string | null) => void;
  dmaCategory?: IDMACategoryWithEffectsAndChildren | null;
}

export const MoveImpactOrEffectConfirmDialog: FC<IMoveImpactOrEffectConfirmDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  type,
  effectOrImpact,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  onClose,
  dmaCategory,
}) => {
  const { t } = useTranslation("move_iro_to_other_topic_dialog");

  const [selectedTopic, setSelectedTopic] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");

  // ESRS Topic Select
  const topicsQuery = useESRSTopicsQuery(
    organizationId ?? "",
    recordingPeriodId ?? "",
    dataEntryObjectId,
    (data) => data.esrsTopics,
  );
  const handleSelectEsrsTopic = useCallback((topicId: string) => {
    setSelectedTopic(topicId);
    setSelectedCategory("");
  }, []);

  // Reset form on open
  useEffect(() => {
    if (open) {
      setSelectedTopic("");
      setSelectedCategory("");
    }
  }, [open]);

  // Other
  const confirmButtonDisabled = useMemo(
    () => !selectedTopic || !selectedCategory || disabled || selectedCategory === dmaCategory?.id,
    [disabled, dmaCategory?.id, selectedCategory, selectedTopic],
  );

  function handleSelectCategoryChange(evt: SelectChangeEvent<string>) {
    setSelectedCategory(evt.target?.value);
  }

  const renderName = useRenderDMACategoryName();

  function isCategoryVerifiedOrShared(category: IDMACategoryWithEffectsAndChildren) {
    if (type === "material_impact") {
      return category.materialState === "verified";
    } else if (type === "financial_effect") {
      return category.financialState === "verified";
    }
  }

  function isCategoryDisabledWithReason(category: IDMACategoryWithEffectsAndChildren): {
    isDisabled: boolean;
    reason: string;
  } {
    // Check if category is already verified
    if (isCategoryVerifiedOrShared(category)) {
      return {
        isDisabled: true,
        reason: t("category_disabled_reason_already_verified"),
      };
    }

    // Check if category matches the current DMA category
    if (category.id === dmaCategory?.id) {
      return {
        isDisabled: true,
        reason: t("category_disabled_reason_same_category"),
      };
    }

    // Default case: category is not disabled
    return {
      isDisabled: false,
      reason: "",
    };
  }

  return (
    <BaseAsyncActionDialog
      open={open}
      onClose={!selectedTopic ? () => onClose(null) : undefined} // Allow closing dialog as long as no topic is selected yet
      title={
        <FormatTranslation
          i18nKey={`title_${type}`}
          t={t}
          values={{ name: effectOrImpact?.title }}
        />
      }
      loading={loading}
      error={error}
      content={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            pt: 1,
          }}
        >
          <Typography>{t(`content_text_${type}`, { name: effectOrImpact?.title })}</Typography>
          <QueryWrapper
            query={topicsQuery}
            build={(esrsTopics) => (
              <>
                {/*ESRS Topic Select */}
                <FormControl variant="outlined" sx={{ mt: 1 }} fullWidth>
                  <InputLabel>{t("choose_esrs_topic_label")}</InputLabel>
                  <Select
                    value={selectedTopic ?? undefined}
                    onChange={(evt) => handleSelectEsrsTopic(evt.target?.value)}
                    label={t("choose_esrs_topic_label")}
                    disabled={loading}
                  >
                    <MenuItem value="">
                      <em>{t("choose_esrs_topic_label")}</em>
                    </MenuItem>
                    {esrsTopics.map((topic) => {
                      const isDisabled = topic.recordedESRSTopic?.dmaCategories.every((category) =>
                        isCategoryVerifiedOrShared(category),
                      );

                      return isDisabled ? (
                        <Tooltip title={t("topic_disabled_reason")} key={topic.id}>
                          <span>
                            <MenuItem key={topic.id} value={topic.id} disabled={isDisabled}>
                              {topic.name}
                            </MenuItem>
                          </span>
                        </Tooltip>
                      ) : (
                        <MenuItem key={topic.id} value={topic.id}>
                          {topic.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {/* DMA Category Select */}
                {selectedTopic && (
                  <FormControl variant="outlined" sx={{ mt: 1 }} fullWidth>
                    <InputLabel>{t("choose_category_label")}</InputLabel>
                    <Select
                      value={selectedCategory ?? undefined}
                      onChange={handleSelectCategoryChange}
                      label={t("choose_category_label")}
                      disabled={loading}
                    >
                      <MenuItem value="">
                        <em>{t("choose_category_label")}</em>
                      </MenuItem>
                      {esrsTopics
                        .find((topic) => topic.id === selectedTopic)
                        ?.recordedESRSTopic?.dmaCategories.map((category) => {
                          const { isDisabled, reason: disabledReason } =
                            isCategoryDisabledWithReason(category);

                          return isDisabled ? (
                            <Tooltip title={disabledReason} key={category.id}>
                              <span>
                                <MenuItem
                                  key={category.id}
                                  value={category.id}
                                  disabled={isDisabled}
                                >
                                  {renderName(category)}
                                </MenuItem>
                              </span>
                            </Tooltip>
                          ) : (
                            <MenuItem key={category.id} value={category.id}>
                              {renderName(category)}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                )}
              </>
            )}
          />
        </Box>
      }
      actions={
        <>
          <DialogCancelButton onClick={() => onClose(null)} disabled={disabled}>
            {t("cancel", { ns: "buttons" })}
          </DialogCancelButton>
          <DialogConfirmButton
            onClick={() => onClose(selectedCategory)}
            disabled={confirmButtonDisabled}
          >
            {t("move", { ns: "buttons" })}
          </DialogConfirmButton>
        </>
      }
    />
  );
};
