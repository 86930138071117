import { FC, useCallback, useMemo } from "react";
import { IHydratedCompletedInputParameterRecordingStructureGroupESRSDisclosureRequirement } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { Box, TableCell, TableRow } from "@mui/material";
import { useTranslateOptionalContent } from "../../content-translation/hooks/translate-content.hook";
import { useGapAnalysisContext } from "../gap-analysis.context";
import { IconSize } from "../../common/constants/tabler-icon.constants";
import { DisclosureRequirementInfoIcon } from "../../data-entry-object-values/esrs/disclosure-requirement-info-icon.component";
import { DataEntryObjectInputParameterStatusIndicatorWithMenu } from "../../data-entry-objects-input-parameters/components/data-entry-object-input-parameter-status-indicator-with-menu.component";
import { InputParameterRecordingEsrsStructuresUtilities } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { EsrsPhaseInChip } from "../../esrs-phase-in/components/esrs-phase-in-indicator-chip.component";
import { DataCollectionSelect } from "./inputs/data-collection-select.component";
import {
  IDataEntryObjectInputParameterGapAnalysisAvailability,
  IDataEntryObjectInputParameterGapAnalysisDataCollection,
} from "@netcero/netcero-core-api-client";
import { AvailabilityIndicatorWithMenu } from "./inputs/availability-indicator-with-menu.component";
import { LinkedSourcesDataEntryObjectInputParameterChipWithMenuWithQuery } from "../../data-entry-object-values/components/linked-sources-data-entry-object-input-parameter-chip-with-menu.component";
import { UPDATE_DISCLOSURE_REQUIREMENT_SOURCES_ACTION } from "../../data-entry-object-values/esrs/esrs-api-actions.constants";
import { SingleOrganizationUserAvatarPicker } from "../../user/components/single-organization-user-avatar-picker.component";
import { useTranslation } from "react-i18next";

interface IGapAnalysisDisclosureRequirementTableRowProps {
  disclosureRequirement: IHydratedCompletedInputParameterRecordingStructureGroupESRSDisclosureRequirement;
}

export const GapAnalysisDisclosureRequirementTableRow: FC<
  IGapAnalysisDisclosureRequirementTableRowProps
> = ({ disclosureRequirement }) => {
  const {
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    recordingStructureId,
    handleUpdateResponsibleUser,
    handleResetDisclosureRequirement,
    handleSubmitDisclosureRequirement,
    handleApproveDisclosureRequirement,
    handleRejectDisclosureRequirement,
    handleUpdateGapAnalysisAvailability,
    handleUpdateGapAnalysisDataCollection,
    isLoading,
  } = useGapAnalysisContext();

  const { t } = useTranslation("data_entry_object_values_overview_common");
  const translateOptionalContent = useTranslateOptionalContent();

  const { disclosureRequirementInputParameter: drIp } = disclosureRequirement;

  const supportsPhaseIn = useMemo(
    () =>
      InputParameterRecordingEsrsStructuresUtilities.isInputParameterEligibleForPhaseIn(
        drIp.inputParameter,
      ),
    [drIp.inputParameter],
  );

  const handleDataCollectionChange = useCallback(
    (dataCollection: IDataEntryObjectInputParameterGapAnalysisDataCollection | null) => {
      void handleUpdateGapAnalysisDataCollection(drIp, dataCollection);
    },
    [drIp, handleUpdateGapAnalysisDataCollection],
  );

  const handleAvailabilityChange = useCallback(
    (availability: IDataEntryObjectInputParameterGapAnalysisAvailability | null) => {
      void handleUpdateGapAnalysisAvailability(drIp, availability);
    },
    [drIp, handleUpdateGapAnalysisAvailability],
  );

  return (
    <TableRow>
      <TableCell>
        <Box display="flex" alignItems="center">
          <LineClampTypographyWithTooltip variant="inherit" maxLines={2}>
            {translateOptionalContent(drIp.inputParameter.name)}
          </LineClampTypographyWithTooltip>
          <DisclosureRequirementInfoIcon
            disclosureRequirement={disclosureRequirement}
            iconSize={IconSize.Smaller}
          />
        </Box>
        {supportsPhaseIn && (
          <Box display="flex">
            <EsrsPhaseInChip />
          </Box>
        )}
      </TableCell>
      <TableCell>
        <DataCollectionSelect
          value={drIp.gapAnalysis.dataCollection ?? null}
          onChange={handleDataCollectionChange}
          disabled={isLoading}
          selectProps={{ size: "small" }}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="start">
          <AvailabilityIndicatorWithMenu
            value={drIp.gapAnalysis.availability ?? null}
            onChange={handleAvailabilityChange}
            disabled={isLoading}
          />
        </Box>
      </TableCell>
      <TableCell>
        <LinkedSourcesDataEntryObjectInputParameterChipWithMenuWithQuery
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          dataEntryObjectId={dataEntryObjectId}
          inputParameterId={drIp.inputParameter.id}
          snackbarMessage={UPDATE_DISCLOSURE_REQUIREMENT_SOURCES_ACTION}
          disabled={isLoading}
        />
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="start">
          <DataEntryObjectInputParameterStatusIndicatorWithMenu
            type="disclosure-requirement"
            organizationId={organizationId}
            recordingPeriodId={recordingPeriodId}
            dataEntryObjectId={dataEntryObjectId}
            recordingStructureId={recordingStructureId}
            dataEntryObjectInputParameter={
              disclosureRequirement.disclosureRequirementInputParameter
            }
            onReset={() => handleResetDisclosureRequirement(drIp.inputParameter)}
            onSubmit={() => handleSubmitDisclosureRequirement(drIp.inputParameter)}
            onApprove={() => handleApproveDisclosureRequirement(drIp.inputParameter)}
            onReject={() => handleRejectDisclosureRequirement(drIp.inputParameter)}
            disabled={isLoading}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box display="flex" justifyContent="center">
          <SingleOrganizationUserAvatarPicker
            value={drIp.responsibleUserId ?? null}
            onChange={(userId) => handleUpdateResponsibleUser(drIp, userId ?? null)}
            noUserOption
            organizationId={organizationId}
            disabled={isLoading}
            tooltipNoUserSelected={t("add_responsible_user_tooltip")}
            noUsersAvailableText={t("no_users_available_for_responsible_user")}
            noUserOptionText={t("no_responsible_user_value_text")}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
};
