import { Box } from "@mui/material";
import {
  IBaseTargetPathGoalData,
  IBaseTargetPathGoalDataWithoutValues,
  IReferenceBudget,
  ITargetPathGoal,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { TargetPathGoalsUtilities, TargetPathGoalsVerification } from "@netcero/netcero-common";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";

const getFormDefaultValues = (
  targetPathGoal: ITargetPathGoal | null,
  allGoals: ITargetPathGoal[],
  referenceBudget: IReferenceBudget,
): IBaseTargetPathGoalDataWithoutValues => {
  return {
    targetYear:
      targetPathGoal?.targetYear ??
      TargetPathGoalsUtilities.getNextAvailableGoalDate(
        allGoals,
        referenceBudget.referenceYear,
        targetPathGoal?.id,
      ),
  };
};

interface ITargetPathGoalEditDialogBaseProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseTargetPathGoalDataWithoutValues | null) => void;
  // Used to enforce year unique constraint
  allGoals: ITargetPathGoal[];
  referenceBudget: IReferenceBudget;
}

interface ICreateTargetPathGoalEditDialogProps extends ITargetPathGoalEditDialogBaseProps {
  mode: "create";
  targetPathGoal: null;
}

interface IEditTargetPathGoalEditDialogProps extends ITargetPathGoalEditDialogBaseProps {
  mode: "edit";
  targetPathGoal: ITargetPathGoal;
}

type ITargetPathGoalEditDialogProps =
  | ICreateTargetPathGoalEditDialogProps
  | IEditTargetPathGoalEditDialogProps;

export const TargetPathGoalEditDialog: FC<ITargetPathGoalEditDialogProps> = ({
  targetPathGoal,
  disabled,
  onClose,
  mode,
  error,
  open,
  loading,
  allGoals,
  referenceBudget,
}) => {
  const { t } = useTranslation("target_path_goal_edit_dialog_component");

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<IBaseTargetPathGoalData>({
    defaultValues: getFormDefaultValues(targetPathGoal, allGoals, referenceBudget),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(targetPathGoal, allGoals, referenceBudget));
    }
  }, [allGoals, open, referenceBudget, reset, targetPathGoal]);

  const handleEmitData = (data: IBaseTargetPathGoalData) => {
    const payload: IBaseTargetPathGoalDataWithoutValues = {
      targetYear: data.targetYear,
    };
    onClose(payload);
  };

  const minDate = useMemo(
    () => DateTime.now().set({ year: referenceBudget.referenceYear + 1 }),
    [referenceBudget.referenceYear],
  );

  return (
    <EditDialogWrapper
      open={open}
      title={t(mode === "edit" ? "title_edit" : "title_create")}
      mode={mode}
      loading={!!loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      disabled={disabled}
      dialogProps={{ fullWidth: true }}
    >
      <Box display="flex" flexDirection="column" gap={2} mt={1}>
        {/* Target Year */}
        <Controller
          rules={{
            min: {
              value: minDate.year,
              message: t("errors.date.minDate", { referenceYear: referenceBudget.referenceYear }),
            },
            validate: (selectedYear) => {
              if (
                TargetPathGoalsVerification.hasGoalWithSameYear(
                  allGoals,
                  selectedYear,
                  targetPathGoal?.id,
                )
              ) {
                return t("errors.date.targetForYearAlreadyDefined", { targetYear: selectedYear });
              }
              return undefined;
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              minDate={minDate}
              value={DateTime.now().set({ year: field.value })}
              onChange={(date) => {
                if (date !== null) {
                  field.onChange(date.year);
                }
              }}
              label={t("labels.target_year")}
              disabled={disabled}
              slotProps={{
                textField: {
                  error: !!error,
                  helperText: error?.message,
                  fullWidth: true,
                  required: true,
                },
              }}
              views={["year"]}
              shouldDisableYear={(year) =>
                TargetPathGoalsVerification.hasGoalWithSameYear(
                  allGoals,
                  year.year,
                  targetPathGoal?.id,
                )
              }
            />
          )}
          name="targetYear"
          control={control}
        />
      </Box>
    </EditDialogWrapper>
  );
};
