/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IInputParameterValueMetaDataEsrsTopicIdentifierEnum = {
  E1: "esrs_e1",
  E2: "esrs_e2",
  E3: "esrs_e3",
  E4: "esrs_e4",
  E5: "esrs_e5",
  S1: "esrs_s1",
  S2: "esrs_s2",
  S3: "esrs_s3",
  S4: "esrs_s4",
  G1: "esrs_g1",
} as const;

export type IInputParameterValueMetaDataEsrsTopicIdentifierEnum =
  (typeof IInputParameterValueMetaDataEsrsTopicIdentifierEnum)[keyof typeof IInputParameterValueMetaDataEsrsTopicIdentifierEnum];
