import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  IBaseManualStakeholderFeedbackData,
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IManualStakeholderFeedback,
  IManualStakeholderFeedbackSource,
  IStakeholderFeedbackType,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { StakeholderAddButton } from "../../../stakeholder/stakeholder-add-button.component";
import { FetchingStakeholderChip } from "../../../stakeholder/stakeholder-chip.component";
import {
  ORDERED_FEEDBACK_SOURCES,
  ORDERED_FEEDBACK_TYPES,
} from "../../common/dma-category-manual-feedback.constants";
import { OpenInNewTabIcon } from "../../../common/constants/tabler-icon.constants";
import { EditDialogWrapper } from "../../../common/dialogs/variants/edit-dialog.wrapper";

interface IInternalManualFeedbackData
  extends Omit<IBaseManualStakeholderFeedbackData, "feedbackSource"> {
  feedbackSource: IManualStakeholderFeedbackSource | "";
}

function getDefaultFormValues(
  dmaCategory: IDMACategoryWithEffectsAndChildren | null,
  manualFeedback?: IManualStakeholderFeedback | null,
): IInternalManualFeedbackData {
  const defaultFeedbackType =
    dmaCategory?.materialState !== IDMACategoryState.Verified
      ? IStakeholderFeedbackType.Material
      : IStakeholderFeedbackType.Financial;

  return {
    feedback: manualFeedback?.feedback ?? "",
    stakeholders: manualFeedback?.stakeholders ?? [],
    feedbackType: manualFeedback?.feedbackType ?? defaultFeedbackType,
    feedbackSource: manualFeedback?.feedbackSource ?? "",
  };
}

interface IDMACategoryManualFeedbackEditDialogProps {
  open: boolean;
  manualFeedback?: IManualStakeholderFeedback | null;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  organizationId: string;
  onClose: (data: IBaseManualStakeholderFeedbackData | null) => void;
}

export const DMACategoryManualFeedbackEditDialog: FC<IDMACategoryManualFeedbackEditDialogProps> = ({
  open,
  manualFeedback,
  dmaCategory,
  loading,
  error,
  disabled,
  organizationId,
  onClose,
}) => {
  const { t } = useTranslation("dma_category_manual_feedback_edit_dialog");

  const isEditing = !!manualFeedback;

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<IInternalManualFeedbackData>({
    defaultValues: getDefaultFormValues(dmaCategory, manualFeedback),
  });

  useEffect(() => {
    if (open) {
      reset(getDefaultFormValues(dmaCategory, manualFeedback));
    }
  }, [open, dmaCategory, manualFeedback, reset]);

  // Emit Data

  const handleEmitData = (data: IInternalManualFeedbackData) => {
    onClose({
      feedback: data.feedback.trim(),
      stakeholders: data.stakeholders,
      feedbackType: data.feedbackType,
      feedbackSource: data.feedbackSource || undefined,
    });
  };

  return (
    <EditDialogWrapper
      open={open}
      title={
        <Box display="flex" alignItems="start">
          {t(isEditing ? "title_edit" : "title_create")}
          <Button
            component={RouterLink}
            to={`/organizations/${organizationId}/configuration/stakeholder`}
            target="_blank"
            variant="contained"
            startIcon={<OpenInNewTabIcon />}
            disabled={disabled}
            sx={{ ml: "auto" }}
          >
            {t("button_manage_stakeholders")}
          </Button>
        </Box>
      }
      mode={isEditing ? "edit" : "create"}
      loading={loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      disabled={disabled}
      dialogProps={{ fullWidth: true, maxWidth: "lg" }}
    >
      <Box display="flex" flexDirection="column" gap={2} pt={1}>
        {/* Feedback Type */}
        <Controller
          control={control}
          name="feedbackType"
          rules={{
            validate: (value) => {
              if (value.length < 1) {
                return t("error_stakeholders_required");
              }
            },
          }}
          render={({ field }) => (
            <FormControl sx={{ maxWidth: 260 }} fullWidth>
              <InputLabel id="feedback-type-label">{t("label_feedback_type")}</InputLabel>
              <Select
                variant="outlined"
                labelId="feedback-type-label"
                id="feedback-type-select"
                value={field.value}
                label={t("label_feedback_type")}
                onChange={(evt) => field.onChange(evt.target.value)}
                disabled={disabled}
              >
                {ORDERED_FEEDBACK_TYPES.map((type) => {
                  const disabled =
                    (type === "material" &&
                      dmaCategory?.materialState === IDMACategoryState.Verified) ||
                    (type === "financial" &&
                      dmaCategory?.financialState === IDMACategoryState.Verified);

                  return (
                    <MenuItem key={type} value={type} disabled={disabled}>
                      {t(`type_${type}`, { ns: "manual_stakeholder_feedback_common" })}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        />

        {/* Feedback Source */}
        <Controller
          control={control}
          name="feedbackSource"
          render={({ field }) => (
            <FormControl sx={{ maxWidth: 260 }} fullWidth>
              <InputLabel id="feedback-type-label">{t("label_feedback_source")}</InputLabel>
              <Select
                variant="outlined"
                labelId="feedback-source-label"
                id="feedback-source-select"
                value={field.value}
                label={t("label_feedback_source")}
                onChange={(evt) => field.onChange(evt.target.value)}
                disabled={disabled}
              >
                <MenuItem value="">
                  {t("source_none", { ns: "manual_stakeholder_feedback_common" })}
                </MenuItem>
                {ORDERED_FEEDBACK_SOURCES.map((source) => (
                  <MenuItem key={source} value={source}>
                    {t(`source_${source}`, { ns: "manual_stakeholder_feedback_common" })}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Divider sx={{ mx: -3 }} />

        {/* Stakeholders */}
        <Controller
          control={control}
          name="stakeholders"
          rules={{
            validate: (value) => {
              if (value.length < 1) {
                return t("error_stakeholders_required");
              }
            },
          }}
          render={({ field, fieldState: { error: formError } }) => (
            <Box py={1}>
              <Box display="flex" alignItems="baseline" gap={2}>
                <Typography>{t("label_stakeholders")}</Typography>
                {field.value.length < 1 && (
                  <Typography variant="body2" color="text.secondary">
                    {t("no_stakeholders_selected_notice")}
                  </Typography>
                )}
                <Box display="flex" alignItems="baseline" gap={1} flexWrap="wrap">
                  {field.value.map((stakeholderId) => (
                    <FetchingStakeholderChip
                      key={stakeholderId}
                      stakeholderId={stakeholderId}
                      organizationId={organizationId}
                      onDelete={() => {
                        if (disabled) {
                          return;
                        }
                        field.onChange(field.value.filter((s) => s !== stakeholderId));
                      }}
                    />
                  ))}
                  <StakeholderAddButton
                    organizationId={organizationId}
                    emptyMessage={t("no_stakeholders_available")}
                    disabled={disabled}
                    excludeStakeholderIds={field.value}
                    onSelect={(stakeholder) => field.onChange([...field.value, stakeholder.id])}
                  />
                </Box>
              </Box>
              {formError && <FormHelperText error>{formError?.message}</FormHelperText>}
            </Box>
          )}
        />

        {/* Feedback */}
        <Controller
          control={control}
          name="feedback"
          rules={{
            validate: (value) => {
              if (value.length < 1) {
                return t("error_feedback_required");
              }
            },
          }}
          render={({ field, fieldState: { error: formError } }) => (
            <TextField
              {...field}
              fullWidth
              multiline
              minRows={3}
              label={t("label_feedback")}
              error={!!formError}
              helperText={formError?.message}
              disabled={disabled}
            />
          )}
        />
      </Box>
    </EditDialogWrapper>
  );
};
