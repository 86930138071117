import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { FC } from "react";
import { AppModule } from "@netcero/netcero-common";

interface IModuleNotAccessibleErrorProps {
  module: AppModule;
}

export const ModuleNotAccessibleError: FC<IModuleNotAccessibleErrorProps> = () => {
  const { t } = useTranslation("module_access");

  return (
    <Typography color="error" textAlign="center">
      {t("error_module_not_accessible_to_organization")}
    </Typography>
  );
};
