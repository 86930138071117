import { useRecordingPeriodOrganizationStructureQuery } from "../../organization-structures/organization-structures.queries";
import { useMemo } from "react";

export const useOrganizationRootStructureId = (
  organizationId: string,
  recordingPeriodId: string,
) => {
  const recordingPeriodOrganizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId,
    recordingPeriodId,
  );

  return useMemo(() => {
    const data = recordingPeriodOrganizationStructureQuery.data;

    if (!data) {
      return null; // Return null if data is not available yet
    }

    if ("structure" in data) {
      return data.structure.id; // Return `structure` for IOrganizationStructureDetailedOneOf
    } else {
      return null;
    }
  }, [recordingPeriodOrganizationStructureQuery.data]);
};
