import { Box, SvgIcon, Tooltip, Typography } from "@mui/material";
import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../common/components/tree-group-container.component";
import { InfoIcon } from "../common/constants/tabler-icon.constants";
import { ILocalRecordingPeriod } from "../recording-periods/recording-periods.utilities";
import { OrganizationUsersComponent } from "../user/components/organization-users.component";
import { DataEntryObjectValueEditComponent } from "./thg/value-editing/data-entry-object-value-edit.component";
import {
  ILocalDataEntryObjectInputParameter,
  ILocalDataEntryObjectInputParameterValueData,
} from "./interfaces/local-data-entry-object-values.interfaces";
import { useTranslateContent } from "../content-translation/hooks/translate-content.hook";
import { DataEntryObjectValueInfoComponent } from "./components/data-entry-object-input-info.component";
import {
  DataEntryObjectValuesUtilities,
  IDependencyMap,
} from "./utilities/data-entry-object-values.utilities";

interface IDataEntryObjectValuesGroupInputParameterComponentProps {
  dataEntryObjectInputParameter: ILocalDataEntryObjectInputParameter;
  availableDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  recordingPeriod: ILocalRecordingPeriod;
  disabled?: boolean;
  onCreate: (value: ILocalDataEntryObjectInputParameterValueData) => Promise<void>;
  onUpdate: (id: string, value: ILocalDataEntryObjectInputParameterValueData) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
  dataEntryObject: IDataEntryObject;
  organizationId: string;
  rootDataEntryObjectId: string;
  onAssignedUsersChange: (
    inputParameter: ILocalDataEntryObjectInputParameter,
    assignedUsers: string[],
  ) => void;
  inputParameterId: string;
  dependencyMap: IDependencyMap;
}

export const DataEntryObjectValuesGroupInputParameterComponent: FC<
  IDataEntryObjectValuesGroupInputParameterComponentProps
> = ({
  dataEntryObjectInputParameter,
  recordingPeriod,
  disabled,
  onCreate,
  onUpdate,
  onDelete,
  availableDistributionCriteria,
  dataEntryObject,
  organizationId,
  rootDataEntryObjectId,
  onAssignedUsersChange,
  inputParameterId,
  dependencyMap,
}) => {
  const { t } = useTranslation("data_entry_object_values_group_input_parameter_component");
  const translateContent = useTranslateContent();

  const canAddNewValue =
    // Only allow adding new values if active
    dataEntryObjectInputParameter.isActive &&
    // AND if additional values can be recorded
    !(
      dataEntryObjectInputParameter.inputParameter.recordingMetadata.recordingInterval ===
        "single_value_per_recording_period" &&
      dataEntryObjectInputParameter.recordedValues.length > 0
    );

  const [newValue, setNewValue] = useState<ILocalDataEntryObjectInputParameterValueData>(
    DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(
      dataEntryObjectInputParameter.inputParameter,
    ),
  );

  const resetNewValue = () => {
    setNewValue(
      DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(
        dataEntryObjectInputParameter.inputParameter,
      ),
    );
  };

  const handleCreate = async (value: ILocalDataEntryObjectInputParameterValueData) => {
    await onCreate({
      enteredForDateStart: value.enteredForDateStart,
      enteredForDateEnd: value.enteredForDateEnd,
      valuesPerKey: value.valuesPerKey,
      note: value.note,
      distributionCriterionId: value.distributionCriterionId,
      dataQuality: value.dataQuality,
      sourceIds: value.sourceIds,
    });
    resetNewValue();
  };

  const handleUpdate = async (id: string, value: ILocalDataEntryObjectInputParameterValueData) => {
    await onUpdate(id, {
      enteredForDateStart: value.enteredForDateStart,
      enteredForDateEnd: value.enteredForDateEnd,
      valuesPerKey: value.valuesPerKey,
      note: value.note,
      distributionCriterionId: value.distributionCriterionId,
      dataQuality: value.dataQuality,
      sourceIds: value.sourceIds,
    });
  };

  const handleDelete = async (id: string) => {
    await onDelete(id);
  };

  return (
    <Box>
      <Box display="flex" gap={1}>
        {/* General IP information */}
        <Typography variant="h6" component="span">
          {translateContent(dataEntryObjectInputParameter.inputParameter.name)}
        </Typography>
        {dataEntryObjectInputParameter.inputParameter.description && (
          <Tooltip
            title={translateContent(dataEntryObjectInputParameter.inputParameter.description)}
            placement="right"
          >
            <SvgIcon color="action" fontSize="medium" sx={{ mt: 0.5 }}>
              <InfoIcon />
            </SvgIcon>
          </Tooltip>
        )}
      </Box>

      {/* Info Component */}
      <DataEntryObjectValueInfoComponent
        inputParameterId={inputParameterId}
        dependencyMap={dependencyMap}
      />

      {/* Responsible Users */}
      <Box>
        <OrganizationUsersComponent
          values={dataEntryObjectInputParameter.contributingUserIds}
          organizationId={organizationId}
          onChange={(values) => onAssignedUsersChange(dataEntryObjectInputParameter, values)}
          emptyMessageAddButton={t("no_more_contributing_users_to_assign")}
          emptyMessage={t("no_contributing_users")}
          tooltipAddButton={t("add_contributing_user_tooltip")}
          disabled={disabled}
        />
      </Box>

      {/* Current Values*/}
      <TreeGroupContainer pt={3} pb={2} mb={2}>
        {/* Existing Values */}
        {dataEntryObjectInputParameter.recordedValues.map((recordedValue) => (
          <Box key={recordedValue.id} mb={4}>
            <DataEntryObjectValueEditComponent
              mode="edit"
              availableDistributionCriteria={availableDistributionCriteria}
              inputParameter={dataEntryObjectInputParameter.inputParameter}
              recordedValue={recordedValue}
              recordingPeriod={recordingPeriod}
              onSave={(newData) => handleUpdate(recordedValue.id, newData)}
              onDelete={() => handleDelete(recordedValue.id)}
              disabled={disabled}
              dataEntryObject={dataEntryObject}
              rootDataEntryObjectId={rootDataEntryObjectId}
              organizationId={organizationId}
            />
          </Box>
        ))}

        {/* Add new Value Logic */}
        {canAddNewValue && newValue !== null && (
          <DataEntryObjectValueEditComponent
            mode="create"
            availableDistributionCriteria={availableDistributionCriteria}
            inputParameter={dataEntryObjectInputParameter.inputParameter}
            recordedValue={newValue}
            recordingPeriod={recordingPeriod}
            onSave={(newData) => handleCreate(newData)}
            onCancel={resetNewValue}
            disabled={disabled}
            subscribeToChanges
            dataEntryObject={dataEntryObject}
            rootDataEntryObjectId={rootDataEntryObjectId}
            organizationId={organizationId}
          />
        )}
      </TreeGroupContainer>
    </Box>
  );
};
