import { FC } from "react";
import { Tab, Tabs } from "@mui/material";
import { IESRSTopic, IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";

interface IIROsESRSTopicsTabsProps {
  selectedTopic: IESRSTopicIdentifier | null;
  esrsTopics: IESRSTopic[];
  onSelectTopic: (topic: IESRSTopicIdentifier) => void;
}

export const IROsESRSTopicsTabs: FC<IIROsESRSTopicsTabsProps> = ({
  selectedTopic,
  esrsTopics,
  onSelectTopic,
}) => {
  return (
    <Tabs variant="fullWidth" value={selectedTopic}>
      {esrsTopics.map((topic) => (
        <Tab
          key={topic.identifier}
          value={topic.identifier}
          label={
            <span>
              {topic.topic.split(" ")[0]}
              <br />
              {topic.topic.split(" ")[1]}
            </span>
          }
          onClick={() => onSelectTopic(topic.identifier)}
          sx={{
            bgcolor: topic.identifier === selectedTopic ? "#00000008" : undefined,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        />
      ))}
    </Tabs>
  );
};
