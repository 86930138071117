import {
  IDataEntryObject,
  IOrganizationStructureDetailedOneOf,
  IOrganizationStructureDetailedOneOf1,
} from "@netcero/netcero-core-api-client";
import { useMemo } from "react";
import { TreeUtilities } from "../../common/utilities/tree.utilities";

type IOrganizationStructure =
  | IOrganizationStructureDetailedOneOf
  | IOrganizationStructureDetailedOneOf1
  | undefined;

export type IDataEntryObjectLookup = Record<string, IDataEntryObject>;

export const useDEOLookup = (structure: IOrganizationStructure): IDataEntryObjectLookup =>
  useMemo(
    () =>
      structure === undefined || structure.isDraft
        ? {}
        : TreeUtilities.createRecursiveLookup<IDataEntryObject>(
            (structure as IOrganizationStructureDetailedOneOf).structure,
            (item) => item.id,
            (item) => item.children,
          ),
    [structure],
  );
