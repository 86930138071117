import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IDistributionCriterion } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon, EditIcon } from "../common/constants/tabler-icon.constants";

interface IDistributionCriteriaEditListProps {
  distributionCriteria: IDistributionCriterion[];
  onDelete: (distributionCriteria: IDistributionCriterion) => void;
  onEdit: (distributionCriteria: IDistributionCriterion) => void;
}

export const DistributionCriteriaEditList: FC<IDistributionCriteriaEditListProps> = ({
  distributionCriteria,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation("distribution_criteria_configuration");

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("header_name")}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {distributionCriteria.map((dc) => (
          <TableRow key={dc.id}>
            <TableCell>{dc.name}</TableCell>
            <TableCell align="right">
              <Box>
                <IconButton size="small" onClick={() => onEdit(dc)}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(dc)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
