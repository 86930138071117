import { IAuditLog } from "@netcero/netcero-core-api-client";
import { FC, useMemo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import { AuditLoggingUtilities } from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import ReactJson from "react-json-view";

interface IAuditLoggingComparisonViewComponentProps {
  auditLog: IAuditLog;
}

export const AuditLoggingComparisonViewComponent: FC<IAuditLoggingComparisonViewComponentProps> = ({
  auditLog,
}) => {
  const diff = useMemo(
    () =>
      AuditLoggingUtilities.getDiffForObjects(auditLog.metaData.before, auditLog.metaData.after),
    [auditLog.metaData.after, auditLog.metaData.before],
  );

  return (
    <Box display="flex" flexDirection="row" gap={2} padding={1} paddingY={2}>
      {/* Before view */}
      <ComparisonBoxComponent
        type="before"
        value={auditLog.metaData.before !== undefined ? diff.before : null}
      />
      {/* After view */}
      <ComparisonBoxComponent
        type="after"
        value={auditLog.metaData.after !== undefined ? diff.after : null}
      />
    </Box>
  );
};

interface IComparisonBoxComponentProps {
  type: "before" | "after";
  value: object | null;
}

const ComparisonBoxComponent: FC<IComparisonBoxComponentProps> = ({ value, type }) => {
  const { t } = useTranslation("audit_logging_comparison_view_component");

  const dataAvailable = useMemo(() => value !== null, [value]);

  return (
    <Paper sx={{ flex: 1, padding: 4 }}>
      <Box display="flex" flexDirection="column" gap={1} flex={1} minHeight={100}>
        <Typography variant="h4">{t(`headers.${type}`)}</Typography>
        {/* No Data available*/}
        {!dataAvailable && (
          <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
            {t("no_data_available")}
          </Box>
        )}
        {/* Data available --> Render as JSON */}
        {dataAvailable && <ReactJson src={value!} name={null} collapsed={1} />}
      </Box>
    </Paper>
  );
};
