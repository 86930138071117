import { FC, useCallback } from "react";
import {
  IAbsoluteTargetPathGoal,
  IReferenceBudget,
  ITargetPathGoalValues,
} from "@netcero/netcero-core-api-client";
import { Box } from "@mui/material";
import {
  ITargetPathGoalValueChangeInputComponentProps,
  TargetPathGoalValueChangeInputComponent,
} from "./target-path-goal-value-change-input.component";
import { useTranslation } from "react-i18next";
import {
  AbsoluteTargetPathGoalScopes,
  TargetPathGoalChangeType,
  TargetPathGoalsCalculations,
  TargetPathGoalsUtilities,
} from "@netcero/netcero-common";
import { EvaluationFormatUtilities } from "../../evaluation/utilities/evaluation-format.utilities";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { useReductionTerm } from "../hooks/use-reduction-term.hook";

interface IAbsoluteTargetPathGoalEditComponentProps {
  values: IAbsoluteTargetPathGoal;
  referenceBudget: IReferenceBudget;
  onValueChange: (values: ITargetPathGoalValues) => void;
  disabled?: boolean;
}

interface IAbsoluteTargetPathGoalValueChangeInputComponentProps
  extends Omit<
    ITargetPathGoalValueChangeInputComponentProps,
    "unit" | "translations" | "helperTextTextField" | "labelTextField" | "tooltipTextField"
  > {
  emissionsForScope: number;
  scope: AbsoluteTargetPathGoalScopes;
}

const AbsoluteTargetPathGoalValueChangeInputComponent: FC<
  IAbsoluteTargetPathGoalValueChangeInputComponentProps
> = (props) => {
  const { t } = useTranslation("absolute_target_path_goal_edit_component");

  const changeValueToType: TargetPathGoalChangeType | null =
    props.emissionsForScope === 0
      ? null
      : props.change.type !== "inPercent"
      ? "inPercent"
      : "byValue";

  const changedValue =
    changeValueToType === null
      ? null
      : TargetPathGoalsCalculations.calculateAbsoluteChangeTypeTransition(
          props.change.value,
          props.emissionsForScope,
          props.change.type,
          changeValueToType,
        );

  const reductionTerm = useReductionTerm(changedValue ?? 0);

  return (
    <TargetPathGoalValueChangeInputComponent
      {...props}
      translations={{
        inPercent: t("translations.in_percent"),
        byValue: t("translations.by_value"),
        toValue: t("translations.to_value"),
      }}
      unit="tco2eq"
      disabled={props.disabled || props.emissionsForScope === 0}
      helperTextTextField={
        props.emissionsForScope === 0
          ? t("helper_text_no_value")
          : t("helper_text", {
              referenceBudget: EvaluationFormatUtilities.formatCO2TonValueUnicode(
                props.emissionsForScope,
              ),
            })
      }
      labelTextField={t(`scopes.scope_${props.scope}`)}
      tooltipTextField={
        changeValueToType === null
          ? t("tooltips.no_value")
          : changeValueToType === "inPercent"
          ? t("tooltips.reduction_as_percent", {
              percentage: FormatUtilities.formatFractionAsPercentage(
                changedValue! * reductionTerm.multiplyBy,
              ),
              reductionTerm: reductionTerm.translation,
            })
          : t("tooltips.reduction_as_by_value", {
              value: FormatUtilities.formatNumber(changedValue! * reductionTerm.multiplyBy),
              reductionTerm: reductionTerm.translation,
            })
      }
    />
  );
};

export const AbsoluteTargetPathGoalEditComponent: FC<IAbsoluteTargetPathGoalEditComponentProps> = ({
  values,
  onValueChange,
  disabled,
  referenceBudget,
}) => {
  // Convenience
  const emissionsForScope = useCallback(
    (scope: AbsoluteTargetPathGoalScopes) =>
      TargetPathGoalsUtilities.getEmissionsSumForAbsoluteTargetPathGoalScope(
        referenceBudget.calculatedValues.sumsPerScope,
        scope,
      ),
    [referenceBudget.calculatedValues.sumsPerScope],
  );

  // Event handlers
  const handleChangeTypeChange = useCallback(
    (
      oldType: TargetPathGoalChangeType,
      newType: TargetPathGoalChangeType,
      scope: AbsoluteTargetPathGoalScopes,
    ) => {
      onValueChange({
        ...values,
        [scope]: {
          value: TargetPathGoalsCalculations.calculateAbsoluteChangeTypeTransition(
            values[scope].value,
            emissionsForScope(scope),
            oldType,
            newType,
          ),
          type: newType,
        },
      });
    },
    [emissionsForScope, onValueChange, values],
  );
  const handleValueChange = useCallback(
    (value: number, scope: AbsoluteTargetPathGoalScopes) => {
      onValueChange({ ...values, [scope]: { value, type: values[scope].type } });
    },
    [onValueChange, values],
  );

  // Convenience
  const typeChangeWithScope = useCallback(
    (scope: AbsoluteTargetPathGoalScopes) =>
      (oldType: TargetPathGoalChangeType, newType: TargetPathGoalChangeType) => {
        handleChangeTypeChange(oldType, newType, scope);
      },
    [handleChangeTypeChange],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <AbsoluteTargetPathGoalValueChangeInputComponent
        change={values.reductionScope1}
        emissionsForScope={emissionsForScope("reductionScope1")}
        onChangeTypeChange={typeChangeWithScope("reductionScope1")}
        onValueChange={(value) => handleValueChange(value, "reductionScope1")}
        disabled={disabled}
        scope="reductionScope1"
      />
      <AbsoluteTargetPathGoalValueChangeInputComponent
        change={values.reductionScope2}
        emissionsForScope={emissionsForScope("reductionScope2")}
        onChangeTypeChange={typeChangeWithScope("reductionScope2")}
        onValueChange={(value) => handleValueChange(value, "reductionScope2")}
        disabled={disabled}
        scope="reductionScope2"
      />
      <AbsoluteTargetPathGoalValueChangeInputComponent
        change={values.reductionScope3}
        emissionsForScope={emissionsForScope("reductionScope3")}
        onChangeTypeChange={typeChangeWithScope("reductionScope3")}
        onValueChange={(value) => handleValueChange(value, "reductionScope3")}
        disabled={disabled}
        scope="reductionScope3"
      />
    </Box>
  );
};
