import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ActionsInputSelect } from "../../actions/components/actions-select-input/actions-select-input.component";
import { IAction } from "@netcero/netcero-core-api-client";

interface IAssociatedActionsFormData {
  actionIds: string[];
}

interface IAssociatedActionsWithControllerProps {
  label: string;
  actions: IAction[];
}

export const AssociatedActionsWithController: FC<IAssociatedActionsWithControllerProps> = ({
  label,
  actions,
}) => {
  const { control } = useFormContext<IAssociatedActionsFormData>();

  return (
    <Controller
      control={control}
      name="actionIds"
      render={({ field: { value, onChange } }) => (
        <ActionsInputSelect
          label={label}
          actions={actions}
          actionsIds={value}
          onChange={onChange}
        />
      )}
    />
  );
};
