import { PropsWithChildren } from "react";
import { FeatureFlag } from "../constants/feature-flags.constants";
import { useIsFeatureFlagEnabled } from "../hooks/is-feature-flag-enabled.hook";

type RequireFeatureFlagProps = {
  featureFlag: FeatureFlag;
};

export const RequireFeatureFlag: React.FC<PropsWithChildren<RequireFeatureFlagProps>> = ({
  featureFlag,
  children,
}) => {
  const isFeatureFlagEnabled = useIsFeatureFlagEnabled(featureFlag);

  if (!isFeatureFlagEnabled) {
    return null;
  }

  return children;
};
