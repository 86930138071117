/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IAddManualFeedback409Response } from "../models";
// @ts-ignore
import { IBaseStakeholderData } from "../models";
// @ts-ignore
import { IDeleteStakeholder409Response } from "../models";
// @ts-ignore
import { IGetAllInputParameterValueListValues404Response } from "../models";
// @ts-ignore
import { IGetAllStakeholdersResponse } from "../models";
// @ts-ignore
import { IGetStakeholderFeedbackDetails404Response } from "../models";
// @ts-ignore
import { IStakeholder } from "../models";
// @ts-ignore
import { IStakeholderFeedback } from "../models";
// @ts-ignore
import { IStakeholderFeedbackDetails } from "../models";
// @ts-ignore
import { IStakeholderRecordingPeriods } from "../models";
// @ts-ignore
import { ISubmitStakeholderFeedback400Response } from "../models";
// @ts-ignore
import { ISubmitStakeholderFeedback404Response } from "../models";
// @ts-ignore
import { ISubmitStakeholderFeedbackRequest } from "../models";
// @ts-ignore
import { IUpdateStakeholder404Response } from "../models";
/**
 * StakeholdersApi - axios parameter creator
 * @export
 */
export const StakeholdersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new stakeholder for the given organization.
     * @summary Creates a new stakeholder for the given organization.
     * @param {string} organizationId The id of the organization to create the stakeholders for.
     * @param {IBaseStakeholderData} iBaseStakeholderData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStakeholder: async (
      organizationId: string,
      iBaseStakeholderData: IBaseStakeholderData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("createStakeholder", "organizationId", organizationId);
      // verify required parameter 'iBaseStakeholderData' is not null or undefined
      assertParamExists("createStakeholder", "iBaseStakeholderData", iBaseStakeholderData);
      const localVarPath = `/organizations/{organizationId}/stakeholders`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseStakeholderData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes a single stakeholder.
     * @summary Deletes a single stakeholder.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStakeholder: async (
      organizationId: string,
      stakeholderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("deleteStakeholder", "organizationId", organizationId);
      // verify required parameter 'stakeholderId' is not null or undefined
      assertParamExists("deleteStakeholder", "stakeholderId", stakeholderId);
      const localVarPath = `/organizations/{organizationId}/stakeholders/{stakeholderId}`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"stakeholderId"}}`, encodeURIComponent(String(stakeholderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all stakeholders for an organization.
     * @summary Get all stakeholders for an organization.
     * @param {string} organizationId The id of the organization to get the stakeholders for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStakeholders: async (
      organizationId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getAllStakeholders", "organizationId", organizationId);
      const localVarPath = `/organizations/{organizationId}/stakeholders`.replace(
        `{${"organizationId"}}`,
        encodeURIComponent(String(organizationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieves detailed information about the feedback of a stakeholder.
     * @summary Retrieves detailed information about the feedback of a stakeholder.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {string} recordingPeriodId The recording period to retrieve the information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStakeholderFeedbackDetails: async (
      stakeholderId: string,
      recordingPeriodId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stakeholderId' is not null or undefined
      assertParamExists("getStakeholderFeedbackDetails", "stakeholderId", stakeholderId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getStakeholderFeedbackDetails", "recordingPeriodId", recordingPeriodId);
      const localVarPath = `/stakeholders/{stakeholderId}/feedback`.replace(
        `{${"stakeholderId"}}`,
        encodeURIComponent(String(stakeholderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      if (recordingPeriodId !== undefined) {
        localVarQueryParameter["recordingPeriodId"] = recordingPeriodId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Retrieves all recording periods that can be selected by the stakeholder.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStakeholderRecordingPeriods: async (
      stakeholderId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stakeholderId' is not null or undefined
      assertParamExists("getStakeholderRecordingPeriods", "stakeholderId", stakeholderId);
      const localVarPath = `/stakeholders/{stakeholderId}/recording-periods`.replace(
        `{${"stakeholderId"}}`,
        encodeURIComponent(String(stakeholderId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submit the feedback of a stakeholder for a specific DMA category.
     * @summary Submit the feedback of a stakeholder for a specific DMA category.
     * @param {string} stakeholderId The id of the stakeholder who submits the feedback.
     * @param {string} dmaCategoryId The id of the DMA category for which the stakeholder submits the feedback.
     * @param {ISubmitStakeholderFeedbackRequest} iSubmitStakeholderFeedbackRequest Used to submit feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitStakeholderFeedback: async (
      stakeholderId: string,
      dmaCategoryId: string,
      iSubmitStakeholderFeedbackRequest: ISubmitStakeholderFeedbackRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'stakeholderId' is not null or undefined
      assertParamExists("submitStakeholderFeedback", "stakeholderId", stakeholderId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("submitStakeholderFeedback", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iSubmitStakeholderFeedbackRequest' is not null or undefined
      assertParamExists(
        "submitStakeholderFeedback",
        "iSubmitStakeholderFeedbackRequest",
        iSubmitStakeholderFeedbackRequest,
      );
      const localVarPath = `/stakeholders/{stakeholderId}/feedback/dma-categories/{dmaCategoryId}`
        .replace(`{${"stakeholderId"}}`, encodeURIComponent(String(stakeholderId)))
        .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iSubmitStakeholderFeedbackRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates a single stakeholder.
     * @summary Updates a single stakeholder.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {IBaseStakeholderData} [iBaseStakeholderData] The request to update a stakeholder.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStakeholder: async (
      organizationId: string,
      stakeholderId: string,
      iBaseStakeholderData?: IBaseStakeholderData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("updateStakeholder", "organizationId", organizationId);
      // verify required parameter 'stakeholderId' is not null or undefined
      assertParamExists("updateStakeholder", "stakeholderId", stakeholderId);
      const localVarPath = `/organizations/{organizationId}/stakeholders/{stakeholderId}`
        .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
        .replace(`{${"stakeholderId"}}`, encodeURIComponent(String(stakeholderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iBaseStakeholderData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * StakeholdersApi - functional programming interface
 * @export
 */
export const StakeholdersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = StakeholdersApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new stakeholder for the given organization.
     * @summary Creates a new stakeholder for the given organization.
     * @param {string} organizationId The id of the organization to create the stakeholders for.
     * @param {IBaseStakeholderData} iBaseStakeholderData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createStakeholder(
      organizationId: string,
      iBaseStakeholderData: IBaseStakeholderData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStakeholder>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createStakeholder(
        organizationId,
        iBaseStakeholderData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Deletes a single stakeholder.
     * @summary Deletes a single stakeholder.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteStakeholder(
      organizationId: string,
      stakeholderId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteStakeholder(
        organizationId,
        stakeholderId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all stakeholders for an organization.
     * @summary Get all stakeholders for an organization.
     * @param {string} organizationId The id of the organization to get the stakeholders for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllStakeholders(
      organizationId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IGetAllStakeholdersResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStakeholders(
        organizationId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Retrieves detailed information about the feedback of a stakeholder.
     * @summary Retrieves detailed information about the feedback of a stakeholder.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {string} recordingPeriodId The recording period to retrieve the information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStakeholderFeedbackDetails(
      stakeholderId: string,
      recordingPeriodId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStakeholderFeedbackDetails>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStakeholderFeedbackDetails(
        stakeholderId,
        recordingPeriodId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Retrieves all recording periods that can be selected by the stakeholder.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStakeholderRecordingPeriods(
      stakeholderId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStakeholderRecordingPeriods>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStakeholderRecordingPeriods(
        stakeholderId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Submit the feedback of a stakeholder for a specific DMA category.
     * @summary Submit the feedback of a stakeholder for a specific DMA category.
     * @param {string} stakeholderId The id of the stakeholder who submits the feedback.
     * @param {string} dmaCategoryId The id of the DMA category for which the stakeholder submits the feedback.
     * @param {ISubmitStakeholderFeedbackRequest} iSubmitStakeholderFeedbackRequest Used to submit feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitStakeholderFeedback(
      stakeholderId: string,
      dmaCategoryId: string,
      iSubmitStakeholderFeedbackRequest: ISubmitStakeholderFeedbackRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStakeholderFeedback>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.submitStakeholderFeedback(
        stakeholderId,
        dmaCategoryId,
        iSubmitStakeholderFeedbackRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates a single stakeholder.
     * @summary Updates a single stakeholder.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {IBaseStakeholderData} [iBaseStakeholderData] The request to update a stakeholder.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateStakeholder(
      organizationId: string,
      stakeholderId: string,
      iBaseStakeholderData?: IBaseStakeholderData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IStakeholder>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateStakeholder(
        organizationId,
        stakeholderId,
        iBaseStakeholderData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * StakeholdersApi - factory interface
 * @export
 */
export const StakeholdersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = StakeholdersApiFp(configuration);
  return {
    /**
     * Creates a new stakeholder for the given organization.
     * @summary Creates a new stakeholder for the given organization.
     * @param {string} organizationId The id of the organization to create the stakeholders for.
     * @param {IBaseStakeholderData} iBaseStakeholderData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createStakeholder(
      organizationId: string,
      iBaseStakeholderData: IBaseStakeholderData,
      options?: any,
    ): AxiosPromise<IStakeholder> {
      return localVarFp
        .createStakeholder(organizationId, iBaseStakeholderData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes a single stakeholder.
     * @summary Deletes a single stakeholder.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteStakeholder(
      organizationId: string,
      stakeholderId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteStakeholder(organizationId, stakeholderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all stakeholders for an organization.
     * @summary Get all stakeholders for an organization.
     * @param {string} organizationId The id of the organization to get the stakeholders for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStakeholders(
      organizationId: string,
      options?: any,
    ): AxiosPromise<IGetAllStakeholdersResponse> {
      return localVarFp
        .getAllStakeholders(organizationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieves detailed information about the feedback of a stakeholder.
     * @summary Retrieves detailed information about the feedback of a stakeholder.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {string} recordingPeriodId The recording period to retrieve the information for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStakeholderFeedbackDetails(
      stakeholderId: string,
      recordingPeriodId: string,
      options?: any,
    ): AxiosPromise<IStakeholderFeedbackDetails> {
      return localVarFp
        .getStakeholderFeedbackDetails(stakeholderId, recordingPeriodId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Retrieves all recording periods that can be selected by the stakeholder.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStakeholderRecordingPeriods(
      stakeholderId: string,
      options?: any,
    ): AxiosPromise<IStakeholderRecordingPeriods> {
      return localVarFp
        .getStakeholderRecordingPeriods(stakeholderId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Submit the feedback of a stakeholder for a specific DMA category.
     * @summary Submit the feedback of a stakeholder for a specific DMA category.
     * @param {string} stakeholderId The id of the stakeholder who submits the feedback.
     * @param {string} dmaCategoryId The id of the DMA category for which the stakeholder submits the feedback.
     * @param {ISubmitStakeholderFeedbackRequest} iSubmitStakeholderFeedbackRequest Used to submit feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitStakeholderFeedback(
      stakeholderId: string,
      dmaCategoryId: string,
      iSubmitStakeholderFeedbackRequest: ISubmitStakeholderFeedbackRequest,
      options?: any,
    ): AxiosPromise<IStakeholderFeedback> {
      return localVarFp
        .submitStakeholderFeedback(
          stakeholderId,
          dmaCategoryId,
          iSubmitStakeholderFeedbackRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates a single stakeholder.
     * @summary Updates a single stakeholder.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} stakeholderId The id of the stakeholder to use.
     * @param {IBaseStakeholderData} [iBaseStakeholderData] The request to update a stakeholder.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateStakeholder(
      organizationId: string,
      stakeholderId: string,
      iBaseStakeholderData?: IBaseStakeholderData,
      options?: any,
    ): AxiosPromise<IStakeholder> {
      return localVarFp
        .updateStakeholder(organizationId, stakeholderId, iBaseStakeholderData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * StakeholdersApi - interface
 * @export
 * @interface StakeholdersApi
 */
export interface StakeholdersApiInterface {
  /**
   * Creates a new stakeholder for the given organization.
   * @summary Creates a new stakeholder for the given organization.
   * @param {string} organizationId The id of the organization to create the stakeholders for.
   * @param {IBaseStakeholderData} iBaseStakeholderData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  createStakeholder(
    organizationId: string,
    iBaseStakeholderData: IBaseStakeholderData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IStakeholder>;

  /**
   * Deletes a single stakeholder.
   * @summary Deletes a single stakeholder.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  deleteStakeholder(
    organizationId: string,
    stakeholderId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Returns all stakeholders for an organization.
   * @summary Get all stakeholders for an organization.
   * @param {string} organizationId The id of the organization to get the stakeholders for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  getAllStakeholders(
    organizationId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetAllStakeholdersResponse>;

  /**
   * Retrieves detailed information about the feedback of a stakeholder.
   * @summary Retrieves detailed information about the feedback of a stakeholder.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {string} recordingPeriodId The recording period to retrieve the information for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  getStakeholderFeedbackDetails(
    stakeholderId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IStakeholderFeedbackDetails>;

  /**
   *
   * @summary Retrieves all recording periods that can be selected by the stakeholder.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  getStakeholderRecordingPeriods(
    stakeholderId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IStakeholderRecordingPeriods>;

  /**
   * Submit the feedback of a stakeholder for a specific DMA category.
   * @summary Submit the feedback of a stakeholder for a specific DMA category.
   * @param {string} stakeholderId The id of the stakeholder who submits the feedback.
   * @param {string} dmaCategoryId The id of the DMA category for which the stakeholder submits the feedback.
   * @param {ISubmitStakeholderFeedbackRequest} iSubmitStakeholderFeedbackRequest Used to submit feedback for a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  submitStakeholderFeedback(
    stakeholderId: string,
    dmaCategoryId: string,
    iSubmitStakeholderFeedbackRequest: ISubmitStakeholderFeedbackRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IStakeholderFeedback>;

  /**
   * Updates a single stakeholder.
   * @summary Updates a single stakeholder.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {IBaseStakeholderData} [iBaseStakeholderData] The request to update a stakeholder.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApiInterface
   */
  updateStakeholder(
    organizationId: string,
    stakeholderId: string,
    iBaseStakeholderData?: IBaseStakeholderData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IStakeholder>;
}

/**
 * StakeholdersApi - object-oriented interface
 * @export
 * @class StakeholdersApi
 * @extends {BaseAPI}
 */
export class StakeholdersApi extends BaseAPI implements StakeholdersApiInterface {
  /**
   * Creates a new stakeholder for the given organization.
   * @summary Creates a new stakeholder for the given organization.
   * @param {string} organizationId The id of the organization to create the stakeholders for.
   * @param {IBaseStakeholderData} iBaseStakeholderData
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public createStakeholder(
    organizationId: string,
    iBaseStakeholderData: IBaseStakeholderData,
    options?: AxiosRequestConfig,
  ) {
    return StakeholdersApiFp(this.configuration)
      .createStakeholder(organizationId, iBaseStakeholderData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes a single stakeholder.
   * @summary Deletes a single stakeholder.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public deleteStakeholder(
    organizationId: string,
    stakeholderId: string,
    options?: AxiosRequestConfig,
  ) {
    return StakeholdersApiFp(this.configuration)
      .deleteStakeholder(organizationId, stakeholderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all stakeholders for an organization.
   * @summary Get all stakeholders for an organization.
   * @param {string} organizationId The id of the organization to get the stakeholders for.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public getAllStakeholders(organizationId: string, options?: AxiosRequestConfig) {
    return StakeholdersApiFp(this.configuration)
      .getAllStakeholders(organizationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieves detailed information about the feedback of a stakeholder.
   * @summary Retrieves detailed information about the feedback of a stakeholder.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {string} recordingPeriodId The recording period to retrieve the information for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public getStakeholderFeedbackDetails(
    stakeholderId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ) {
    return StakeholdersApiFp(this.configuration)
      .getStakeholderFeedbackDetails(stakeholderId, recordingPeriodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Retrieves all recording periods that can be selected by the stakeholder.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public getStakeholderRecordingPeriods(stakeholderId: string, options?: AxiosRequestConfig) {
    return StakeholdersApiFp(this.configuration)
      .getStakeholderRecordingPeriods(stakeholderId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submit the feedback of a stakeholder for a specific DMA category.
   * @summary Submit the feedback of a stakeholder for a specific DMA category.
   * @param {string} stakeholderId The id of the stakeholder who submits the feedback.
   * @param {string} dmaCategoryId The id of the DMA category for which the stakeholder submits the feedback.
   * @param {ISubmitStakeholderFeedbackRequest} iSubmitStakeholderFeedbackRequest Used to submit feedback for a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public submitStakeholderFeedback(
    stakeholderId: string,
    dmaCategoryId: string,
    iSubmitStakeholderFeedbackRequest: ISubmitStakeholderFeedbackRequest,
    options?: AxiosRequestConfig,
  ) {
    return StakeholdersApiFp(this.configuration)
      .submitStakeholderFeedback(
        stakeholderId,
        dmaCategoryId,
        iSubmitStakeholderFeedbackRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates a single stakeholder.
   * @summary Updates a single stakeholder.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} stakeholderId The id of the stakeholder to use.
   * @param {IBaseStakeholderData} [iBaseStakeholderData] The request to update a stakeholder.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof StakeholdersApi
   */
  public updateStakeholder(
    organizationId: string,
    stakeholderId: string,
    iBaseStakeholderData?: IBaseStakeholderData,
    options?: AxiosRequestConfig,
  ) {
    return StakeholdersApiFp(this.configuration)
      .updateStakeholder(organizationId, stakeholderId, iBaseStakeholderData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
