import { FC, useMemo } from "react";
import { IInputParameter, IInputParameterValueMetaData } from "@netcero/netcero-core-api-client";
import { SvgIcon, Tooltip } from "@mui/material";
import {
  ActionIcon,
  BooleanInputIcon,
  ComplexInputIcon,
  CurrencyInputIcon,
  DateInputIcon,
  NumericInputIcon,
  OptionsInputIcon,
  PolicyInputIcon,
  TableInputIcon,
  TargetInputIcon,
  TextInputIcon,
  ValueWithUnitInputIcon,
} from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";

export type ESRSInputParameterType =
  | IInputParameterValueMetaData["type"]
  | "table"
  | "complex"
  | "phase-in";

interface IEsrsInputParameterTypeIndicatorProps {
  inputParameter: IInputParameter;
}

/**
 * Icon to display the type of input parameter. Only first value is used to determine the type.
 * @param inputParameter
 * @constructor
 */
export const EsrsInputParameterTypeIndicator: FC<IEsrsInputParameterTypeIndicatorProps> = ({
  inputParameter,
}) => {
  // Tables
  if (inputParameter.uiConfiguration.displayType === "table") {
    return <InputTypeIndicator type="table" />;
  }

  // Complex Input Parameters (multiple inputs)
  if (inputParameter.values.length > 1) {
    return <InputTypeIndicator type="complex" />;
  }

  const firstValue = inputParameter.values[0];
  if (!firstValue) {
    return "ERROR: No values";
  }

  // Normal Inputs
  if (firstValue.valueConfiguration.type === "simple") {
    return <InputTypeIndicator type={firstValue.valueConfiguration.configuration.type} />;
  }

  // Phase In
  if (firstValue.valueConfiguration.type === "esrs-topic-phase-in") {
    return <InputTypeIndicator type="phase-in" />;
  }

  return "ERROR: Unsupported";
};

interface IInputTypeIndicatorProps {
  type: ESRSInputParameterType;
}

const InputTypeIndicator: FC<IInputTypeIndicatorProps> = ({ type }) => {
  const { t } = useTranslation("input_parameter_types");

  const icon = useMemo(() => {
    switch (type) {
      case "text":
        return TextInputIcon;
      case "currency":
        return CurrencyInputIcon;
      case "number":
        return NumericInputIcon;
      case "boolean":
        return BooleanInputIcon;
      case "date":
      case "date-range":
      case "year":
        return DateInputIcon;
      case "options":
      case "nested-options":
        return OptionsInputIcon;
      case "table":
        return TableInputIcon;
      case "complex":
        return ComplexInputIcon;
      case "policy":
        return PolicyInputIcon;
      case "phase-in":
        return TableInputIcon;
      case "value-with-unit":
        return ValueWithUnitInputIcon;
      case "action":
        return ActionIcon;
      case "target":
        return TargetInputIcon;
    }
  }, [type]);

  return (
    <Tooltip title={t(type)} placement="right">
      <SvgIcon
        component={icon}
        color="action"
        fontSize="small"
        sx={{ mr: 0.5, fill: "transparent" }}
      />
    </Tooltip>
  );
};
