import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface IOrganizationStructureIsDraftNoticeProps {
  organizationId: string;
  recordingPeriodId: string;
  noticeText?: string;
}

/**
 * Displays a notice that the organization structure is a draft and links to the configuration page.
 * @param organizationId Organization the structure belongs to
 * @param recordingPeriodId Recording Period the structure belongs to
 * @param noticeText The text that should be displayed
 */
export const OrganizationStructureIsDraftNotice: FC<IOrganizationStructureIsDraftNoticeProps> = ({
  organizationId,
  recordingPeriodId,
  noticeText,
}) => {
  const { t } = useTranslation("organization_structure_is_draft_notice");

  return (
    <Box textAlign="center">
      <Typography my={4} textAlign="center">
        {noticeText ?? t("organization_structure_is_draft")}
      </Typography>

      <Button
        component={Link}
        to={`/organizations/${organizationId}/configuration/recording-periods/${recordingPeriodId}/organization-structure`}
        variant="outlined"
        sx={{ mb: 4 }}
      >
        {t("to_configuration_text")}
      </Button>
    </Box>
  );
};
