import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  DatabaseExportIcon,
  DeleteIcon,
  EditIcon,
} from "../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../common/utilities/format.utilities";
import { EvaluationFormatUtilities } from "../evaluation/utilities/evaluation-format.utilities";
import { ILocalReferenceBudget } from "./interfaces/reference-budgets.interfaces";

interface IReferenceBudgetsEditListComponentProps {
  referenceBudgets: ILocalReferenceBudget[];
  onEdit: (referenceBudget: ILocalReferenceBudget) => void;
  onRefresh: (referenceBudget: ILocalReferenceBudget) => void;
  onDelete: (referenceBudget: ILocalReferenceBudget) => void;
  disabled?: boolean;
}

export const ReferenceBudgetsEditListComponent: FC<IReferenceBudgetsEditListComponentProps> = ({
  referenceBudgets,
  onEdit,
  onRefresh,
  onDelete,
  disabled,
}) => {
  const { t } = useTranslation("reference_budgets_edit_list_component");
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t("headers.name")}</TableCell>
          <TableCell>{t("headers.reference_year")}</TableCell>
          <TableCell>{t("headers.budget_per_year")}</TableCell>
          <TableCell>{t("headers.reference_revenue")}</TableCell>
          <TableCell>{t("headers.values_fetched_at")}</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {referenceBudgets.map((rb) => (
          <TableRow key={rb.id}>
            {/* Name */}
            <TableCell>{rb.name}</TableCell>
            {/* Reference Year */}
            <TableCell>{rb.referenceYear}</TableCell>
            {/* Reference Budget */}
            <TableCell>
              {EvaluationFormatUtilities.formatCO2TonValue(rb.calculatedValues.sum)}
            </TableCell>
            {/* Reference Revenue */}
            <TableCell>
              {rb.referenceRevenue === undefined
                ? "-"
                : FormatUtilities.formatCurrency(rb.referenceRevenue / 100)}
            </TableCell>
            {/* Values fetched at */}
            <TableCell>{FormatUtilities.formatDateTime(rb.valuesFetchedAt)}</TableCell>
            {/* Actions for the current row */}
            <TableCell align="right">
              <Box>
                {/* Edit reference budget */}
                <Tooltip title={t("action_tooltips.edit")}>
                  <span>
                    <IconButton size="small" onClick={() => onEdit(rb)} disabled={disabled}>
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {/* Refresh data for reference budget */}
                <Tooltip title={t("action_tooltips.refresh")}>
                  <span>
                    <IconButton size="small" onClick={() => onRefresh(rb)} disabled={disabled}>
                      <DatabaseExportIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {/* Delete reference budget */}
                <Tooltip title={t("action_tooltips.delete")}>
                  <span>
                    <IconButton size="small" onClick={() => onDelete(rb)} disabled={disabled}>
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
