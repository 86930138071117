import { ITargetPathGoalForVerification } from "./target-path-goals.types";
import { SumPerScope } from "../reference-budgets/reference-budget.types";
import {
  AbsoluteTargetPathGoalScopes,
  RelativeTargetPathGoalScopes,
} from "./target-path-goals.public-types";
import { IReferenceBudget } from "@netcero/netcero-core-api-client";
import { ScopeKeys } from "../emissions";

export class TargetPathGoalsUtilities {
  public static getNextAvailableGoalDate(
    goals: ITargetPathGoalForVerification[],
    referenceYear: number,
    skip?: string,
  ) {
    // no goals are present --> start with the year after the reference year
    if (goals.length === 0) {
      return referenceYear + 1;
    }

    // goals are present --> get max year (excluding the skip, if present) and increase by one
    const usedYears = new Set(goals.filter((g) => g.id !== skip).map((g) => g.targetYear));

    // starting with the referenceYear + 1, determine the next year that is not already used
    let i = referenceYear + 1;

    for (; usedYears.has(i); i++) {
      // just used to increase i
    }

    return i;
  }

  public static getEmissionsSumForAbsoluteTargetPathGoalScope(
    emissions: SumPerScope | IReferenceBudget["calculatedValues"]["sumsPerScope"],
    goalScope: AbsoluteTargetPathGoalScopes,
  ) {
    switch (goalScope) {
      case "reductionScope1":
        return emissions[ScopeKeys.Scope1] ?? 0;
      case "reductionScope2":
        return emissions[ScopeKeys.Scope2] ?? 0;
      case "reductionScope3":
        return emissions[ScopeKeys.Scope3] ?? 0;
    }
  }

  public static getEmissionsSumForRelativeTargetPathGoalScope(
    emissions: SumPerScope | IReferenceBudget["calculatedValues"]["sumsPerScope"],
    goalScope: RelativeTargetPathGoalScopes,
  ) {
    switch (goalScope) {
      case "intensityScope1":
        return emissions[ScopeKeys.Scope1] ?? 0;
      case "intensityScope2":
        return emissions[ScopeKeys.Scope2] ?? 0;
      case "intensityScope3":
        return emissions[ScopeKeys.Scope3] ?? 0;
    }
  }
}
