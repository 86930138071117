import { Box, SvgIcon, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { BarElement, CategoryScale, Chart, LinearScale, Tooltip } from "chart.js";
import { FC, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { PointFilledIcon } from "../../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { EvaluationFormatUtilities } from "../utilities/evaluation-format.utilities";
import { ChartColorPalette } from "./../../../theme/charts-theme";
import { IEmissionsScopesSumsResult } from "./emissions.utilities";

Chart.register(BarElement, CategoryScale, LinearScale);
Chart.register([Tooltip]);

interface IEmissionsByScopeBarChartProps {
  title: string;
  scopesResult: IEmissionsScopesSumsResult;
  onClickDataEntryObject?: () => void;
}
export const EmissionsByScopeBarChart: FC<IEmissionsByScopeBarChartProps> = ({
  title,
  scopesResult,
  onClickDataEntryObject,
}) => {
  const { t } = useTranslation("emissions_by_scope_bar_charts");

  const percentages = useMemo(() => {
    // Return 0 if NaN
    if (scopesResult.total === 0) {
      return {
        scope1: 0,
        scope2: 0,
        scope3: 0,
      };
    }

    return {
      scope1: (scopesResult.scope1 / scopesResult.total) * 100,
      scope2: (scopesResult.scope2 / scopesResult.total) * 100,
      scope3: (scopesResult.scope3 / scopesResult.total) * 100,
    };
  }, [scopesResult]);

  const scopeSumsArray = [scopesResult.scope1, scopesResult.scope2, scopesResult.scope3];
  const scopePercentagesArray = [percentages.scope1, percentages.scope2, percentages.scope3];

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
      {scopesResult.total === 0 ? (
        <Box minHeight={280} display="flex" alignItems="center" justifyContent="center">
          <Typography>{t("empty")}</Typography>
        </Box>
      ) : (
        <Box minHeight={280} maxWidth={150}>
          <Bar
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label(tooltipItem) {
                      const invertedIndex =
                        scopePercentagesArray.length - tooltipItem.datasetIndex - 1;

                      const scopeName = t(
                        ["title_scope1", "title_scope2", "title_scope3"][invertedIndex],
                      );
                      const value = scopeSumsArray[invertedIndex];
                      const percentage = scopePercentagesArray[invertedIndex];

                      return [
                        ` ${scopeName}`,
                        EvaluationFormatUtilities.formatCO2TonValueUnicode(value),
                        ` ${FormatUtilities.formatPercentage(percentage)}  %`,
                      ];
                    },
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                  ticks: {
                    display: false,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    display: false,
                  },
                  border: {
                    display: false,
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
            data={{
              labels: [""],
              datasets: scopePercentagesArray
                .map((percentage, index) => ({
                  label: t(["title_scope1", "title_scope2", "title_scope3"][index]),
                  data: [percentage],
                  backgroundColor: ChartColorPalette[index % ChartColorPalette.length],
                }))
                .reverse(),
            }}
          />
        </Box>
      )}
      {/* DEO Name */}
      <Typography
        textAlign="center"
        fontWeight="bold"
        my="auto"
        sx={{
          ":hover": {
            textDecoration: onClickDataEntryObject ? "underline" : undefined,
          },
          cursor: onClickDataEntryObject ? "pointer" : undefined,
        }}
        onClick={() => onClickDataEntryObject?.()}
      >
        {title}
      </Typography>

      {/* Table */}
      <Table size="small" sx={{ width: "unset" }}>
        <TableBody>
          {/* Scopes */}
          {scopePercentagesArray.map((percentage, index) => (
            <TableRow key={index}>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                <Box display="flex" alignItems="center">
                  <SvgIcon
                    sx={{ color: ChartColorPalette[index % ChartColorPalette.length], mr: 1 }}
                  >
                    <PointFilledIcon />
                  </SvgIcon>
                  <Box ml="auto">
                    {EvaluationFormatUtilities.formatCO2TonValueOnly(scopeSumsArray[index])}
                  </Box>
                </Box>
              </TableCell>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                ({FormatUtilities.formatPercentage(percentage)} %)
              </TableCell>
            </TableRow>
          ))}
          {/* Total */}
          <TableRow>
            <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
              <Box fontWeight="bold">
                {EvaluationFormatUtilities.formatCO2TonValueOnly(scopesResult.total)}
              </Box>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
