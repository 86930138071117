import { getDataEntryObjectValuesQueryKey } from "../queries/data-entry-object-values.queries";
import { QueryClient, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  IDataEntryObjectInputParameterGapAnalysis,
  IInputParameter,
} from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameterValueData } from "../interfaces/local-data-entry-object-values.interfaces";
import { CoreApiService } from "../../core-api/core-api.service";
import { DataEntryObjectValuesUtilities } from "../utilities/data-entry-object-values.utilities";

const invalidateQueries = async (
  queryClient: QueryClient,
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
  recordingStructureId: string,
) => {
  // Invalidate Cached Entered Values
  await queryClient.invalidateQueries({
    queryKey: getDataEntryObjectValuesQueryKey(
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      recordingStructureId,
    ),
  });

  // Invalidate Cached Evaluations
  void queryClient.invalidateQueries({
    queryKey: ["dataEntryObjectEvaluation", organizationId, dataEntryObjectId],
  });
};

interface IDataEntryObjectValuesMutationDataCommon {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  recordingStructureId: string;
}

export const useCreateDataEntryObjectInputParameterValueMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      mutationData: {
        inputParameter: IInputParameter;
        valueData: ILocalDataEntryObjectInputParameterValueData;
      } & IDataEntryObjectValuesMutationDataCommon,
    ) => {
      return CoreApiService.DataEntryObjectInputParameterValueApi.submitDataEntryObjectInputParameterValue(
        mutationData.organizationId,
        mutationData.recordingPeriodId,
        mutationData.dataEntryObjectId,
        mutationData.inputParameter.id,
        DataEntryObjectValuesUtilities.convertLocalDataEntryObjectInputParametersValueDataToApi(
          mutationData.valueData,
        ),
      ).then((req) => req());
    },
    onSuccess: async (_, mutationData) =>
      await invalidateQueries(
        queryClient,
        mutationData.organizationId,
        mutationData.recordingPeriodId,
        mutationData.dataEntryObjectId,
        mutationData.recordingStructureId,
      ),
  });
};

export const useUpdateDataEntryObjectInputParameterValueMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      mutationData: {
        inputParameter: IInputParameter;
        valueId: string;
        valueData: ILocalDataEntryObjectInputParameterValueData;
      } & IDataEntryObjectValuesMutationDataCommon,
    ) => {
      return CoreApiService.DataEntryObjectInputParameterValueApi.updateDataEntryObjectInputParameterValue(
        mutationData.organizationId,
        mutationData.recordingPeriodId,
        mutationData.dataEntryObjectId,
        mutationData.inputParameter.id,
        mutationData.valueId,
        DataEntryObjectValuesUtilities.convertLocalDataEntryObjectInputParametersValueDataToApi(
          mutationData.valueData,
        ),
      ).then((req) => req());
    },
    onSuccess: async (_, mutationData) =>
      await invalidateQueries(
        queryClient,
        mutationData.organizationId,
        mutationData.recordingPeriodId,
        mutationData.dataEntryObjectId,
        mutationData.recordingStructureId,
      ),
  });
};

export const useDeleteDataEntryObjectInputParameterValueMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      mutationData: {
        inputParameter: IInputParameter;
        valueId: string;
      } & IDataEntryObjectValuesMutationDataCommon,
    ) => {
      return CoreApiService.DataEntryObjectInputParameterValueApi.deleteDataEntryObjectInputParameterValue(
        mutationData.organizationId,
        mutationData.recordingPeriodId,
        mutationData.dataEntryObjectId,
        mutationData.inputParameter.id,
        mutationData.valueId,
      ).then((req) => req());
    },
    onSuccess: async (_, mutationData) =>
      await invalidateQueries(
        queryClient,
        mutationData.organizationId,
        mutationData.recordingPeriodId,
        mutationData.dataEntryObjectId,
        mutationData.recordingStructureId,
      ),
  });
};

interface IUpdateGapAnalysisInformation extends IDataEntryObjectValuesMutationDataCommon {
  inputParameterId: string;
  payload: IDataEntryObjectInputParameterGapAnalysis;
}

export const useUpdateGapAnalysisInformation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      inputParameterId,
      payload,
    }: IUpdateGapAnalysisInformation) =>
      CoreApiService.DataEntryObjectInputParameterRecordingApi.updateGapAnalysis(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (
      _,
      { organizationId, recordingPeriodId, dataEntryObjectId, recordingStructureId },
    ) =>
      invalidateQueries(
        queryClient,
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        recordingStructureId,
      ),
  });
};
