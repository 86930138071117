import { FC, ReactNode } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DialogErrorText } from "../dialog-error-text.component";
import { DialogLoadingIndicator } from "../dialog-loading-indicator.component";
import { DialogCommonProps, IDialogWithError, OverrideDialogProps } from "../types/common.types";

export interface IBaseAsyncActionDialogProps extends DialogCommonProps, IDialogWithError {
  title?: ReactNode;
  loading: boolean;
  content: ReactNode;
  actions: ReactNode;
  dialogProps?: OverrideDialogProps;
}

export const BaseAsyncActionDialog: FC<IBaseAsyncActionDialogProps> = ({
  open,
  onClose,
  title,
  loading,
  error,
  content,
  actions,
  dialogProps,
}) => {
  return (
    <Dialog open={open} onClose={onClose} {...dialogProps}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogLoadingIndicator loading={loading} />
      <DialogContent>
        <DialogErrorText error={error} />
        {content}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
