import { IBaseDistributionCriterionData } from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getDistributionCriteriaQueryKey } from "./distribution-criteria.queries";

interface ICreateDistributionCriterionMutationData {
  organizationId: string;
  payload: IBaseDistributionCriterionData;
}

/**
 * Mutation to create a new distribution criterion
 */
export const useCreateDistributionCriterionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICreateDistributionCriterionMutationData) => {
      return CoreApiService.DistributionCriteriaApi.createDistributionCriterion(
        data.organizationId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getDistributionCriteriaQueryKey(variables.organizationId),
      });
    },
  });
};

interface IUpdateDistributionCriterionMutationData {
  organizationId: string;
  distributionCriterionId: string;
  payload: IBaseDistributionCriterionData;
}

/**
 * Mutation to update a distribution criterion
 */

export const useUpdateDistributionCriterionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateDistributionCriterionMutationData) => {
      return CoreApiService.DistributionCriteriaApi.updateDistributionCriterion(
        data.organizationId,
        data.distributionCriterionId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getDistributionCriteriaQueryKey(variables.organizationId),
      });
    },
  });
};

interface IDeleteDistributionCriterionMutationData {
  organizationId: string;
  distributionCriterionId: string;
}

/**
 * Mutation to delete a distribution criterion
 */

export const useDeleteDistributionCriterionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IDeleteDistributionCriterionMutationData) => {
      return CoreApiService.DistributionCriteriaApi.deleteDistributionCriterion(
        data.organizationId,
        data.distributionCriterionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getDistributionCriteriaQueryKey(variables.organizationId),
      });
    },
  });
};
