import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IActionData } from "@netcero/netcero-core-api-client";
import { getActionsQueryKey } from "./actions.queries";

interface IActionDataCommon {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

interface ICreateActionData extends IActionDataCommon {
  payload: IActionData;
}

interface IUpdateActionData extends IActionDataCommon {
  actionId: string;
  payload: IActionData;
}

interface IDeleteActionData extends IActionDataCommon {
  actionId: string;
}

export const useCreateActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      payload,
    }: ICreateActionData) => {
      return CoreApiService.ActionsApi.createAction(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getActionsQueryKey(variables.organizationId, variables.recordingPeriodId),
      }),
  });
};

export const useUpdateActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      actionId,
      payload,
    }: IUpdateActionData) => {
      return CoreApiService.ActionsApi.updateAction(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        actionId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getActionsQueryKey(variables.organizationId, variables.recordingPeriodId),
      }),
  });
};

export const useDeleteActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      actionId,
    }: IDeleteActionData) => {
      return CoreApiService.ActionsApi.deleteAction(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        actionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getActionsQueryKey(variables.organizationId, variables.recordingPeriodId),
      }),
  });
};
