import { FC } from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AssociatedUsersDialogInputs } from "../../users/components/associated-users-dialog-inputs.component";
import { LinkedSourcesDialogInput } from "../../sources/components/linked-sources-dialog-input.component";

interface IIroDialogTopSectionInputsProps {
  organizationId: string;
  disabled: boolean | undefined;
}

export const IroDialogTopSectionInputs: FC<IIroDialogTopSectionInputsProps> = ({
  organizationId,
  disabled,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Linked Users section */}
      <AssociatedUsersDialogInputs
        organizationId={organizationId}
        disabled={disabled}
        explanationAssignedUsers={t("explanation_assigned_users")}
      />
      {/* Linked Sources */}
      <LinkedSourcesDialogInput organizationId={organizationId} disabled={disabled} />
    </Box>
  );
};
