import { Box, Button, Typography } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface INoCriteriaConfiguredComponentProps {
  organizationId: string;
}

export const NoCriteriaConfiguredComponent: FC<INoCriteriaConfiguredComponentProps> = ({
  organizationId,
}) => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_component", {
    keyPrefix: "no_criteria_configured",
  });

  const navigate = useNavigate();

  return (
    <Box display="flex" alignItems="center" mt={8} flexDirection="column" gap={2}>
      <Typography variant="subtitle1">{t("text")}</Typography>
      <Button
        variant="contained"
        endIcon={<ArrowRightIcon />}
        onClick={() => {
          navigate(`/organizations/${organizationId}/configuration/distribution-criteria`);
        }}
      >
        {t("button_text")}
      </Button>
    </Box>
  );
};
