import { Card, styled } from "@mui/material";

export const StyledCard = styled(Card)(({ theme }) => ({
  border: "none",
  borderRadius: `${theme.spacing(3)}`,
  boxShadow: "rgba(0, 0, 0, 0.05) 0px 2px 10px 5px",
}));

export const StyledCardWithOnHoverShadow = styled(StyledCard)(({ theme }) => ({
  ":hover": {
    transition: "box-shadow 300ms",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 15px 5px",
  },
}));
