import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { FC, ReactElement, ReactNode } from "react";
import {
  DataEntryObjectInputParameterValueDefinitionForDate,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";
import { DateView } from "@mui/x-date-pickers/models/views";

export interface IBaseDateComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDate>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForDate) => void;
  disabled?: boolean;
  error?: string;
  min?: DateTime | string;
  max?: DateTime | string;
  startAdornment?: ReactElement | null;
  label?: ReactNode;
  required?: boolean;
  views?: readonly DateView[];
}

export const dateTimeOrStringToDate = (date?: DateTime | string) => {
  if (date === undefined) {
    return undefined;
  }
  return typeof date === "string" ? DateTime.fromISO(date) : date;
};

export const BaseDateComponent: FC<IBaseDateComponentProps> = ({
  variant,
  disabled,
  error,
  value,
  onChange,
  max,
  min,
  startAdornment,
  label,
  required,
  views,
}) => {
  const variantProps = useVariantFormProps(variant);
  const variantSxStyles = useVariantSxStyles(variant);

  return (
    <DatePicker
      label={label}
      value={value === undefined ? null : DateTime.fromISO(value)}
      minDate={dateTimeOrStringToDate(min)}
      maxDate={dateTimeOrStringToDate(max)}
      onChange={(date) => {
        if (date === null) {
          onChange("");
          return;
        }
        onChange(date.toISODate() || "");
      }}
      disabled={disabled}
      slotProps={{
        field: {
          clearable: !required,
          onClear: () => onChange(""),
        },
        textField: {
          ...variantProps,
          helperText: error,
          error: error !== undefined,
          sx: {
            minWidth: 240,
            width: "100%",
          },
          InputProps: {
            startAdornment: startAdornment,
            sx: variantSxStyles,
          },
        },
        openPickerButton: {
          size: "medium",
        },
        openPickerIcon: {
          fontSize: "medium",
        },
      }}
      views={views}
    />
  );
};
