import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_DE } from "./de.translations";
import { TRANSLATIONS_EN } from "./en.translations";
import { LS_CURRENT_LOCALE_KEY } from "../modules/common/constants/localstorage.constants";
import { AVAILABLE_LANGUAGE_KEYS, FALLBACK_LANGUAGE } from "@netcero/netcero-common";

void i18n.use(initReactI18next).init({
  resources: {
    de: TRANSLATIONS_DE,
    en: TRANSLATIONS_EN,
  },
  defaultNS: "common",
  lng:
    localStorage.getItem(LS_CURRENT_LOCALE_KEY) ||
    navigator.language.split("-")[0] ||
    FALLBACK_LANGUAGE,
  supportedLngs: AVAILABLE_LANGUAGE_KEYS,
  fallbackLng: FALLBACK_LANGUAGE,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

i18n.on("languageChanged", (lng) => {
  localStorage.setItem(LS_CURRENT_LOCALE_KEY, lng);
});

export default i18n;
