import { FC, useCallback } from "react";
import { SearchableMenu } from "../../common/components/searchable-menu.component";
import { useTranslation } from "react-i18next";
import { SourcesUtilities } from "../sources.utilities";
import { ISource } from "@netcero/netcero-core-api-client";
import { SourcesMenuBottomSection } from "./sources-menu-bottom-section.component";
import { sorted, SourcesVerification } from "@netcero/netcero-common";
import { Box, Checkbox, IconButton, MenuItem, Tooltip } from "@mui/material";
import { LineClampTypographyWithTooltip } from "../../common/components/line-clamp-typography.component";
import { SourceCannotBeReferencedWarningIcon } from "./source-cannot-be-reference-warning.component";
import { Link as RouterLink } from "react-router-dom";
import { createSourcesConfigurationPageLink } from "../../../pages/configuration/sources-configuration.page";
import { OpenInNewTabIcon } from "../../common/constants/tabler-icon.constants";

interface ILinkedSourcesCheckboxMenuProps {
  open: boolean;
  onClose: () => void;
  anchorElement: HTMLElement | null;
  sources: ISource[];
  linkedSourceIds: string[];
  onChange: (linkedSourceIds: string[]) => void;
  organizationId: string;
  onlyAllowReferencableSources?: boolean;
  disabled?: boolean;
}

export const LinkedSourcesCheckboxMenu: FC<ILinkedSourcesCheckboxMenuProps> = ({
  open,
  onClose,
  anchorElement,
  sources,
  linkedSourceIds,
  onChange,
  organizationId,
  onlyAllowReferencableSources,
  disabled,
}) => {
  const { t } = useTranslation("linked_sources");

  const handleClickSource = useCallback(
    (source: ISource) => () => {
      onChange(
        linkedSourceIds.includes(source.id)
          ? linkedSourceIds.filter((id) => id !== source.id)
          : sorted([...linkedSourceIds, source.id]),
      );
    },
    [linkedSourceIds, onChange],
  );

  return (
    <SearchableMenu
      anchorEl={anchorElement}
      show={open}
      onClose={onClose}
      options={sources}
      filterOptions={SourcesUtilities.filterSources}
      noOptionsPresentText={t("no_sources_available")}
      noOptionsFoundText={t("no_sources_found")}
      buildMenuItem={(source) => {
        const isSelected = linkedSourceIds.includes(source.id);
        const isSourceReferencable = SourcesVerification.canSourceBeReferenced(source);
        const disabledSource = disabled || (onlyAllowReferencableSources && !isSourceReferencable);

        return (
          <Tooltip
            key={source.id}
            title={!isSourceReferencable ? t("warnings.cannot_be_linked", { ns: "sources" }) : ""}
            placement="right"
          >
            <span>
              <MenuItem
                onClick={handleClickSource(source)}
                disabled={disabledSource}
                sx={{ gap: 0.5 }}
              >
                <Checkbox size="small" checked={isSelected} disabled={disabledSource} />
                <LineClampTypographyWithTooltip style={{ flex: 1 }}>
                  {source.name}
                </LineClampTypographyWithTooltip>
                {!isSourceReferencable && (
                  <Box display="flex" alignItems="center">
                    <SourceCannotBeReferencedWarningIcon />
                  </Box>
                )}
                <IconButton
                  size="small"
                  component={RouterLink}
                  to={createSourcesConfigurationPageLink(organizationId, source.id).toString()}
                  target="_blank"
                  onClick={(event) => event.stopPropagation()}
                >
                  <OpenInNewTabIcon />
                </IconButton>
              </MenuItem>
            </span>
          </Tooltip>
        );
      }}
      bottomSection={<SourcesMenuBottomSection organizationId={organizationId} />}
    />
  );
};
