import {
  IBaseTargetPathData,
  IBaseTargetPathDataWithoutReferenceBudget,
  ITargetPath,
} from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import { getTargetPathsQueryKey } from "../queries/target-paths.queries";
import { IOrganizationIdentity } from "../../organizations/organizations.mutations";

interface ITargetPathData extends IOrganizationIdentity {
  payload: IBaseTargetPathData;
}

export interface ITargetPathIdentity extends IOrganizationIdentity {
  targetPathId: string;
}

type ICreateTargetPathData = ITargetPathData;

export const useCreateTargetPathMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, payload }: ICreateTargetPathData) => {
      return CoreApiService.TargetPathsApi.createTargetPath(organizationId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};

interface IUpdateTargetPathData extends ITargetPathIdentity, ITargetPathData {}

export const useUpdateTargetPathMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, targetPathId, payload }: IUpdateTargetPathData) => {
      return CoreApiService.TargetPathsApi.updateTargetPath(organizationId, targetPathId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};

type IDeleteTargetPathData = ITargetPathIdentity;

export const useDeleteTargetPathMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, targetPathId }: IDeleteTargetPathData) => {
      return CoreApiService.TargetPathsApi.deleteTargetPath(organizationId, targetPathId)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};

type IToggleTargetPathData = ITargetPathIdentity;

export const useToggleTargetPathMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, targetPathId }: IToggleTargetPathData) => {
      return CoreApiService.TargetPathsApi.toggleTargetPathCommitted(organizationId, targetPathId)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};

interface ICloneTargetPathData extends ITargetPathIdentity {
  payload: IBaseTargetPathDataWithoutReferenceBudget;
}

export const useCloneTargetPathMutation = (
  onMutationSuccess?: (targetPath: ITargetPath) => void,
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, targetPathId, payload }: ICloneTargetPathData) => {
      return CoreApiService.TargetPathsApi.cloneTargetPath(organizationId, targetPathId, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
      onMutationSuccess?.(data);
    },
  });
};
