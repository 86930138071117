import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ConfirmDialog } from "../common/dialogs/variants/confirm.dialog";

interface IOrganizationStructureDraftCommitDialogFormData {
  userUnderstand: boolean;
}

export interface IOrganizationStructureDraftCommitDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error;
  onClose: (commit: true | null) => void;
  disabled?: boolean;
}

export const OrganizationStructureDraftCommitDialog: FC<
  IOrganizationStructureDraftCommitDialogProps
> = ({ open, loading, error, onClose, disabled }) => {
  const { t } = useTranslation("organization_structure_draft_commit_dialog");

  const { control, handleSubmit, reset } = useForm<IOrganizationStructureDraftCommitDialogFormData>(
    {
      defaultValues: {
        userUnderstand: false,
      },
    },
  );
  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open, reset]);

  return (
    <ConfirmDialog
      open={open}
      onConfirm={handleSubmit(() => onClose(true))}
      onCancel={() => onClose(null)}
      title={t("title")}
      loading={loading}
      error={error}
      disabled={disabled}
      actionType="publish"
      dialogProps={{ maxWidth: "sm" }}
      content={
        <Box>
          <Typography variant="body1">{t("warning_text")}</Typography>
          <Controller
            control={control}
            name="userUnderstand"
            rules={{
              required: t("understand.errors.required"),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormControl required error={!!error} component="fieldset" sx={{ mt: 4 }}>
                <FormControlLabel
                  label={t("understand.label")}
                  control={
                    <Checkbox checked={value} onChange={(_, checked) => onChange(checked)} />
                  }
                />
                {error && <FormHelperText>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Box>
      }
    />
  );
};
