import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { UpdateListener } from "lexical/LexicalEditor";

export const useListenToEditorUpdate = (cb: UpdateListener) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerUpdateListener(cb);
  }, [editor, cb]);
};
