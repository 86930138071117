import {
  IBaseCommentData,
  ICommentLinkMetadata,
  ICommentRelations,
} from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getCommentsQueryKeyWithoutOrder } from "./comments.queries";

// This interface is solely used to invalidate the cached comments
interface ICommentQueryIdentity {
  organizationId: string;
  relations: ICommentRelations;
}

interface ICommentData extends ICommentQueryIdentity {
  payload: IBaseCommentData;
  linkMetadata: ICommentLinkMetadata;
}

interface ICommentIdentity extends ICommentQueryIdentity {
  commentId: string;
}

export const useCreateCommentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ payload, relations, linkMetadata, organizationId }: ICommentData) => {
      if (relations.entity !== linkMetadata.entity) {
        throw new Error(
          `Entity specified in relations (${relations.entity}) does not match the entity in the linkMetadata (${linkMetadata.entity}), this is probably a bug!`,
        );
      }

      return CoreApiService.CommentsApi.createComment(organizationId, {
        data: payload,
        relations,
        linkMetadata,
      })
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: getCommentsQueryKeyWithoutOrder(variables.organizationId, variables.relations),
      }),
  });
};

export const useDeleteCommentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ commentId, organizationId }: ICommentIdentity) => {
      return CoreApiService.CommentsApi.deleteComment(organizationId, commentId)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (_, variables) =>
      queryClient.invalidateQueries({
        queryKey: getCommentsQueryKeyWithoutOrder(variables.organizationId, variables.relations),
      }),
  });
};
