import {
  IInputParameter,
  IInputParameterValue,
  IInputParameterValueConfiguration,
  IInputParameterValueConfigurationSimple,
  IInputParameterValueMetaData,
  IInputParameterValueMetaDataOptions,
} from "@netcero/netcero-core-api-client";

const DIRECT_SAVE_VALUE_TYPES: IInputParameterValueMetaData["type"][] = [
  "policy",
  "action",
  "target",
];

export interface ITableStaticColumnValueDefinitionConfiguration
  extends Omit<IInputParameterValueConfigurationSimple, "configuration"> {
  configuration: IInputParameterValueMetaDataOptions;
}

export interface ITableStaticColumnValueDefinition
  extends Omit<IInputParameterValue, "valueConfiguration"> {
  valueConfiguration: ITableStaticColumnValueDefinitionConfiguration;
}

export class EsrsValueEditingUtilities {
  public static doesInputParameterStoreValue(inputParameter: IInputParameter) {
    return (
      inputParameter.values[0] &&
      EsrsValueEditingUtilities.doesInputParameterValueStoreValue(
        inputParameter.values[0].valueConfiguration,
      )
    );
  }

  public static doesInputParameterValueStoreValue(
    valueConfiguration: IInputParameterValueConfiguration,
  ): valueConfiguration is IInputParameterValueConfigurationSimple {
    return valueConfiguration.type === "simple";
  }

  public static isInputParameterDirectSave(inputParameter: IInputParameter) {
    if (
      !inputParameter.values[0] ||
      !EsrsValueEditingUtilities.doesInputParameterValueStoreValue(
        inputParameter.values[0].valueConfiguration,
      )
    ) {
      return false;
    }

    return DIRECT_SAVE_VALUE_TYPES.includes(
      inputParameter.values[0]?.valueConfiguration.configuration.type,
    );
  }

  public static getUniqueColumns(inputParameter: IInputParameter) {
    return inputParameter.metaData.esrs?.tableInformation?.valuesRelateToValuesOfKeys ?? null;
  }

  public static isStaticTableInputParameter(inputParameter: IInputParameter) {
    return inputParameter.metaData.esrs?.tableInformation?.renderRowForPrimaryKeyVariants === true;
  }

  public static getStaticTableColumnDefinition(
    inputParameter: IInputParameter,
  ): ITableStaticColumnValueDefinition | null {
    if (!EsrsValueEditingUtilities.isStaticTableInputParameter(inputParameter)) {
      return null;
    }
    const uniqueColumnsConfiguration = EsrsValueEditingUtilities.getUniqueColumns(inputParameter);
    if (uniqueColumnsConfiguration?.length !== 1) {
      console.error(
        "Unexpected state encountered! Static Table with more than one or no unique column configuration!",
      );
      return null;
    }
    const identifier = uniqueColumnsConfiguration[0];
    const valueDefinition = inputParameter.values.find((val) => val.key === identifier);
    if (!valueDefinition) {
      console.error(
        "Unexpected state encountered! Static Table with unique column configuration but no value definition found!",
      );
      return null;
    }
    if (
      !EsrsValueEditingUtilities.doesInputParameterValueStoreValue(
        valueDefinition.valueConfiguration,
      ) ||
      valueDefinition.valueConfiguration.configuration.type !== "options"
    ) {
      console.error(
        "Unexpected state encountered! Static Table with unique column configuration but value definition is not enum!",
      );
      return null;
    }
    // This is fine since we checked for the type above
    return valueDefinition as ITableStaticColumnValueDefinition;
  }
}
