import { useCallback, useMemo, useState } from "react";

export interface IPaginationState {
  currentPage: number;
  rowsPerPage: number;
}

export type IPaginationCurrentPageHandler = (currentPage: number) => void;
export type IPaginationRowsPerPageHandler = (rowsPerPage: number) => void;

export interface IUsePaginationStateResult {
  state: IPaginationState;
  setCurrentPage: IPaginationCurrentPageHandler;
  setRowsPerPage: IPaginationRowsPerPageHandler;
}

export const usePaginationState = (
  startWithPage: number = 0,
  startWithRowsPerPage: number = 10,
): IUsePaginationStateResult => {
  const [state, setState] = useState<IPaginationState>({
    currentPage: startWithPage,
    rowsPerPage: startWithRowsPerPage,
  });

  const setCurrentPage: IPaginationCurrentPageHandler = useCallback((page: number) => {
    setState((prev) => ({ ...prev, currentPage: page }));
  }, []);

  const setRowsPerPage: IPaginationRowsPerPageHandler = useCallback((rowsPerPage: number) => {
    setState({ currentPage: 0, rowsPerPage: rowsPerPage });
  }, []);

  return useMemo(
    () => ({
      state,
      setCurrentPage,
      setRowsPerPage,
    }),
    [setCurrentPage, setRowsPerPage, state],
  );
};
