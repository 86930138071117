import { Dispatch, FC } from "react";
import { MenuItem, Select } from "@mui/material";
import { SORTED_DATA_COLLECTION_OPTIONS } from "../../gap-analysis.constants";
import { IDataEntryObjectInputParameterGapAnalysisDataCollection } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { SelectProps } from "@mui/material/Select";

interface IDataCollectionSelectProps {
  value: IDataEntryObjectInputParameterGapAnalysisDataCollection | null;
  onChange: Dispatch<IDataEntryObjectInputParameterGapAnalysisDataCollection | null>;
  disabled?: boolean;
  selectProps?: SelectProps;
}

export const DataCollectionSelect: FC<IDataCollectionSelectProps> = ({
  value,
  onChange,
  disabled,
  selectProps,
}) => {
  const { t } = useTranslation("gap_analysis", { keyPrefix: "data_collection" });

  return (
    <Select
      variant="standard"
      value={value ?? ""}
      onChange={(event) =>
        onChange(
          (event.target.value ||
            null) as IDataEntryObjectInputParameterGapAnalysisDataCollection | null,
        )
      }
      fullWidth
      displayEmpty
      disabled={disabled}
      {...selectProps}
    >
      {/* Empty Option */}
      <MenuItem value="" selected={value === null}>
        {t("null")}
      </MenuItem>
      {/* Options */}
      {SORTED_DATA_COLLECTION_OPTIONS.map((option) => (
        <MenuItem key={option} value={option}>
          {t(option)}
        </MenuItem>
      ))}
    </Select>
  );
};
