import { RecursiveKeyOf } from "../common";
import { ITargetData } from "@netcero/netcero-core-api-client";
import { MDRDependencies } from "../mdrs/mdrs.public-types";
import { MDRUtilities } from "../mdrs/mdrs.utilities";

export type KeysForTarget = RecursiveKeyOf<
  Omit<
    ITargetData,
    | "materialImpactIds"
    | "financialEffectIds"
    | "actionIds"
    | "policyIds"
    | "dataEntryObjectId"
    | "recordingPeriodId"
  >
>;
type IDependenciesForTargets = MDRDependencies<KeysForTarget>;

const createBooleanEntry = MDRUtilities.createBooleanMDRDependencyFactory<KeysForTarget>();

export const DEPENDENCIES_FOR_TARGET: IDependenciesForTargets = {
  "e.e1.targetIsDerivedUsingSectoralDecarbonisationPathway": createBooleanEntry(
    "e.targetRelatedToEnvironmentalMattersIsBasedOnConclusiveScientificEvidence",
  ),
  "e.e1.disclosureOfFrameworkAndMethodologyThatHasBeenUsedToDetermineGHGEmissionReductionTargetUnderlyingClimateAndPolicyScenariosForTargetHowFutureDevelopmentsHaveBeenConsideredAndHowTheseWillPotentiallyImpactGHGEmissionsAndEmissionsReductionsExplanatory":
    createBooleanEntry(
      "e.targetRelatedToEnvironmentalMattersIsBasedOnConclusiveScientificEvidence",
    ),
  "general.disclosureOfHowStakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatterExplanatory":
    createBooleanEntry(
      "general.stakeholdersHaveBeenInvolvedInTargetSettingForEachMaterialSustainabilityMatter",
    ),
  "e.e1.disclosureOfHowDiverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLeversExplanatory":
    createBooleanEntry(
      "e.e1.diverseRangeOfClimateScenariosHaveBeenConsideredToDetectRelevantEnvironmentalSocietalTechnologyMarketAndPolicyrelatedDevelopmentsAndDetermineDecarbonisationLevers",
    ),
  "e.e2.disclosureOfHowTargetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcernExplanatory":
    createBooleanEntry(
      "e.e2.targetRelatesToEitherPreventionOrControlOfSubstancesOfConcernAndOrSubstancesOfVeryHighConcern",
    ),
  "e.e2ToE5.disclosureOfEcologicalThresholdsIdentifiedAndMethodologyUsedToIdentifyEcologicalThresholdsExplanatory":
    createBooleanEntry(
      "e.e2ToE5.ecologicalThresholdsAndOrEntityspecificAllocationsWereTakenIntoConsiderationWhenSettingTarget",
    ),
  "e.e2ToE5.disclosureOfHowEcologicalEntityspecificThresholdsWereDeterminedExplanatory":
    createBooleanEntry("e.e2ToE5.ecologicalThresholdsAreEntityspecific"),
};

export const TARGET_INPUTS_DEPENDED_UPON: KeysForTarget[] = Array.from(
  new Set(Object.values(DEPENDENCIES_FOR_TARGET).map((d) => d.dependsUpon)),
);
