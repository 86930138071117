import { Box, useTheme } from "@mui/material";
import { TargetPathGoalsCalculations } from "@netcero/netcero-common";
import { IReferenceBudget, ITargetPathGoal } from "@netcero/netcero-core-api-client";
import { IconProps } from "@tabler/icons-react";
import { FC, useMemo } from "react";
import { ArrowDownIcon, ArrowUpIcon, IconSize } from "../../common/constants/tabler-icon.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";

export interface ITargetPathGoalEmissionsChangeTableCellComponentProps {
  goal: ITargetPathGoal;
  referenceBudget: IReferenceBudget;
}

export const TargetPathGoalEmissionsChangeTableCellComponent: FC<
  ITargetPathGoalEmissionsChangeTableCellComponentProps
> = ({ goal, referenceBudget }) => {
  const theme = useTheme();

  const change = useMemo(
    () => TargetPathGoalsCalculations.getTotalEmissionsChangeForGoal(goal, referenceBudget) * 100,
    [goal, referenceBudget],
  );

  const isDecrease = useMemo(() => change > 0, [change]);

  const iconProps = useMemo((): IconProps => {
    return {
      size: IconSize.Small,
      stroke: 3,
      color: isDecrease ? theme.palette.success.main : theme.palette.error.main,
    };
  }, [isDecrease, theme.palette.error.main, theme.palette.success.main]);

  return (
    <Box display="flex" flexDirection="row" gap={1} alignItems="center">
      {isDecrease ? <ArrowDownIcon {...iconProps} /> : <ArrowUpIcon {...iconProps} />}
      {FormatUtilities.formatPercentage(Math.abs(change))} %
    </Box>
  );
};
