import { Box, Button, Chip, IconButton, Tooltip, Typography } from "@mui/material";
import {
  IDMACategoryState,
  IDMACategoryWithEffectsAndChildren,
  IStakeholderFeedbackType,
  IVerifyStakeholderFeedbackRequest,
} from "@netcero/netcero-core-api-client";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckAllIcon, InfoIcon, UndoIcon } from "../../../common/constants/tabler-icon.constants";
import { DMACategoryFeedbackUtilities } from "../../common/dma-category-feedback.utilities";
import { useESRSTopicContext } from "../../esrs-topic.context";
import {
  useAllManualFeedbacksForDmaCategoryQuery,
  useAllStakeholderFeedbacksForCategoryQuery,
} from "../../dma.queries";
import { InfoDialogText } from "../../../common/dialogs/variants/info.dialog";

interface IDMACategoryValidationButtonsProps {
  dmaCategory: IDMACategoryWithEffectsAndChildren;
  disabled?: boolean;
  onVerify: (payload: IVerifyStakeholderFeedbackRequest) => void;
}

export const DMACategoryValidationButtons: FC<IDMACategoryValidationButtonsProps> = ({
  dmaCategory,
  disabled,
  onVerify,
}) => {
  const { t } = useTranslation("dma_category_stakeholder_feedback_dialog");

  const [showHelpDialog, setShowHelpDialog] = useState(false);

  const { organizationId, recordingPeriodId, dataEntryObjectId, esrsTopicId } =
    useESRSTopicContext();

  const stakeholderFeedbacks = useAllStakeholderFeedbacksForCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const manualStakeholderFeedbacks = useAllManualFeedbacksForDmaCategoryQuery(
    organizationId,
    recordingPeriodId,
    dataEntryObjectId,
    esrsTopicId,
    dmaCategory.id,
  );

  const canValidateMaterial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.hasSubmittedFeedbackForType(
        stakeholderFeedbacks.data?.stakeholderFeedbacks ?? [],
        manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks ?? [],
        IStakeholderFeedbackType.Material,
      ) && dmaCategory.materialState === IDMACategoryState.Shared,
    [
      stakeholderFeedbacks.data?.stakeholderFeedbacks,
      manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks,
      dmaCategory.materialState,
    ],
  );
  const canValidateFinancial = useMemo(
    () =>
      DMACategoryFeedbackUtilities.hasSubmittedFeedbackForType(
        stakeholderFeedbacks.data?.stakeholderFeedbacks ?? [],
        manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks ?? [],
        IStakeholderFeedbackType.Financial,
      ) && dmaCategory.financialState === IDMACategoryState.Shared,
    [
      stakeholderFeedbacks.data?.stakeholderFeedbacks,
      manualStakeholderFeedbacks.data?.manualStakeholderFeedbacks,
      dmaCategory.financialState,
    ],
  );

  return (
    <>
      <InfoDialogText
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
        title={t("title_validation_help")}
        content={t("content_validation_help")}
      />

      <Box display="flex" alignItems="center" gap={1}>
        <Typography variant="h6">{t("header_validation")}</Typography>
        <IconButton size="small" onClick={() => setShowHelpDialog(true)}>
          <InfoIcon />
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" flexWrap="wrap" gap={2}>
        {/* Material State */}
        {dmaCategory.materialState === IDMACategoryState.Verified ? (
          <Tooltip title={t("tooltip_undo_verified")}>
            <span>
              <Chip
                variant="outlined"
                label={t("material_verified")}
                color="success"
                deleteIcon={<UndoIcon />}
                disabled={disabled}
                onClick={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Material,
                  });
                }}
                onDelete={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Material,
                  });
                }}
                sx={{ pl: 0.75 }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={!canValidateMaterial ? t("tooltip_steps_to_verify_material") : ""}>
            <span>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckAllIcon />}
                onClick={() =>
                  onVerify({
                    verified: true,
                    feedbackType: IStakeholderFeedbackType.Material,
                  })
                }
                disabled={!canValidateMaterial || disabled}
              >
                {t("button_validate_material")}
              </Button>
            </span>
          </Tooltip>
        )}

        {dmaCategory.financialState === IDMACategoryState.Verified ? (
          <Tooltip title={t("tooltip_undo_verified")}>
            <span>
              <Chip
                variant="outlined"
                label={t("financial_verified")}
                color="success"
                deleteIcon={<UndoIcon />}
                disabled={disabled}
                onClick={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  });
                }}
                onDelete={() => {
                  onVerify({
                    verified: false,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  });
                }}
                sx={{ pl: 0.75 }}
              />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={!canValidateFinancial ? t("tooltip_steps_to_verify_financial") : ""}>
            <span>
              <Button
                variant="outlined"
                color="success"
                startIcon={<CheckAllIcon />}
                onClick={() =>
                  onVerify({
                    verified: true,
                    feedbackType: IStakeholderFeedbackType.Financial,
                  })
                }
                disabled={!canValidateFinancial || disabled}
              >
                {t("button_validate_financial")}
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </>
  );
};
