import { FC, useMemo } from "react";
import { IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement } from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.interfaces";
import { IIntercomEntity } from "@netcero/netcero-core-api-client";
import { SvgIcon, Tooltip } from "@mui/material";
import { IconSize, InfoIcon } from "../../common/constants/tabler-icon.constants";
import { IntercomReferenceWidget } from "../../intercom-references/intercom-reference.widget";
import { useTranslateOptionalContent } from "../../content-translation/hooks/translate-content.hook";

interface IDisclosureRequirementInfoIconProps {
  disclosureRequirement: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement;
  iconSize?: IconSize;
}

export const DisclosureRequirementInfoIcon: FC<IDisclosureRequirementInfoIconProps> = ({
  disclosureRequirement,
  iconSize,
}) => {
  const translateContent = useTranslateOptionalContent();

  const infoTooltip = useMemo(
    () => translateContent(disclosureRequirement.info),
    [translateContent, disclosureRequirement.info],
  );

  return (
    <IntercomReferenceWidget
      entityType={IIntercomEntity.DisclosureRequirement}
      identifier={disclosureRequirement.id}
      iconSize={iconSize}
      renderIfNoArticleFound={
        infoTooltip && (
          <Tooltip title={infoTooltip} placement="left">
            <SvgIcon
              component={InfoIcon}
              color="action"
              fontSize="medium"
              sx={{ mr: 0.5, fill: "transparent" }}
            />
          </Tooltip>
        )
      }
    />
  );
};
