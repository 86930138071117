import { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AppModule } from "@netcero/netcero-common";
import { useHasOrganizationModuleAccess } from "../../modules/organization-module-access/has-organization-module-access.hook";
import { ModuleNotAccessibleError } from "../../modules/organization-module-access/module-not-accessible-error.component";
import { Box } from "@mui/material";

interface IRequireModuleAccessLayoutProps {
  module: AppModule;
}

export const RequireModuleAccessLayout: FC<IRequireModuleAccessLayoutProps> = ({ module }) => {
  const { organizationId } = useParams();
  const hasAccess = useHasOrganizationModuleAccess(organizationId ?? "", module);

  return hasAccess ? (
    <Outlet />
  ) : (
    <Box flex={1} display="flex" alignItems="center" justifyContent="center">
      <ModuleNotAccessibleError module={module} />
    </Box>
  );
};
