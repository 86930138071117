import { Dispatch, FC, useCallback, useRef, useState } from "react";
import { Menu, MenuItem } from "@mui/material";
import { SORTED_AVAILABILITY_OPTIONS } from "../../gap-analysis.constants";
import { IDataEntryObjectInputParameterGapAnalysisAvailability } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { AvailabilityIndicator } from "../availability-indicator.component";

interface IDataCollectionSelectProps {
  value: IDataEntryObjectInputParameterGapAnalysisAvailability | null;
  onChange: Dispatch<IDataEntryObjectInputParameterGapAnalysisAvailability | null>;
  disabled?: boolean;
}

export const AvailabilityIndicatorWithMenu: FC<IDataCollectionSelectProps> = ({
  value,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("gap_analysis", { keyPrefix: "availability" });
  const indicatorRef = useRef<HTMLDivElement>(null);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleClickOption = useCallback(
    (option: IDataEntryObjectInputParameterGapAnalysisAvailability | null) => () => {
      // Only emit change on actual change
      if (option !== value) {
        onChange(option);
      }
      setIsMenuOpen(false);
    },
    [onChange, value],
  );

  return (
    <>
      {/* Select Menu */}
      <Menu anchorEl={indicatorRef.current} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        {/* Empty Option */}
        <MenuItem value="" selected={value === null} onClick={handleClickOption(null)}>
          {t("null")}
        </MenuItem>
        {/* Options */}
        {SORTED_AVAILABILITY_OPTIONS.map((option) => (
          <MenuItem
            key={option}
            value={option}
            selected={value === option}
            onClick={handleClickOption(option)}
          >
            {t(option)}
          </MenuItem>
        ))}
      </Menu>

      {/* Availability Indicator */}
      <AvailabilityIndicator
        ref={indicatorRef}
        status={value}
        onClick={() => setIsMenuOpen(true)}
        shouldRenderChevronIcon
        disabled={disabled}
      />
    </>
  );
};
