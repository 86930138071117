import { FieldValues } from "react-hook-form";
import { IMDREnumInput } from "../../mdr-input.types";
import { Box } from "@mui/material";
import { HierarchicalDropdownEnum } from "../hierarchical-dropdown/hierarchical-dropdown.component";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";

export function EnumInput<T extends FieldValues>({
  label,
  field: { onChange, value },
  valueMetaData,
  translateEnumValue,
  disabled,
}: IMDRControlledInputProps<T, IMDREnumInput> & {
  translateEnumValue: (value: string) => string;
}) {
  return (
    <Box maxWidth={valueMetaData.mode === "single" ? NON_FULL_WIDTH_WIDTH : undefined}>
      <HierarchicalDropdownEnum
        type={valueMetaData.mode}
        // The `as never` is so typescript will not complain about the mode being "single" and the emptyOption being a boolean
        emptyOption={
          (valueMetaData.mode === "single" ? !valueMetaData.required : undefined) as never
        }
        label={label}
        translateValueLabel={translateEnumValue}
        values={valueMetaData.values}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </Box>
  );
}
