import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
  SvgIcon,
  Typography,
} from "@mui/material";
import {
  IDataEntryObject,
  IDataEntryObjectData,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChartColorPalette } from "../../../theme/charts-theme";
import { FilterIcon, PointFilledIcon } from "../../common/constants/tabler-icon.constants";
import { Predicate } from "../../common/interfaces/predicate.type";
import { EvaluationUtilities, IStructureOverviewResult } from "../utilities/evaluation.utilities";
import { DEOEmissionsByCategoryBarChart } from "./deo-emissions-by-category-bar-chart.component";
import { EmissionsUtilities } from "./emissions.utilities";
import { GroupedCalculationResult } from "@netcero/netcero-common";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

export interface IEmissionsByCategoryDEOBarsListCalculationResult {
  isRoot: boolean;
  hasChildren: boolean;
  dataEntryObject: IDataEntryObjectData;
  results: IStructureOverviewResult;
  totalSum: number;
}

interface IEmissionsByCategoryDEOBarsListProps {
  generalResult: GroupedCalculationResult;
  currentDataEntryObject: IDataEntryObject;
  structure: IInputParameterRecordingStructureGroupTHGSC;
  onSelectChildGroup: (childGroupIndex: number) => void;
  isChildCategorySelectable?: Predicate<IInputParameterRecordingStructureGroupTHGSC>;
  onSelectChildDataEntryObject: (dataEntryObject: IDataEntryObjectData) => void;
  isChildDataEntryObjectSelectable?: Predicate<IEmissionsByCategoryDEOBarsListCalculationResult>;
}

/**
 * Shows Bar Charts per Data Entry Object displaying the percentage of emissions for each structure group (SC)
 * @param dataEntryObjectResult Data Entry Object Results from API
 * @param structure Structure Group
 * @param onSelectChildGroup Callback when a child group is selected
 * @param isChildCategorySelectable Predicate to determine if a child category is selectable
 * @param onSelectChildDataEntryObject Callback when a child Data Entry Object is selected
 * @param isChildDataEntryObjectSelectable Predicate to determine if a child Data Entry Object is selectable
 */
export const EmissionsByCategoryDEOBarsList: FC<IEmissionsByCategoryDEOBarsListProps> = ({
  generalResult,
  currentDataEntryObject,
  structure,
  onSelectChildGroup,
  isChildCategorySelectable = () => true,
  onSelectChildDataEntryObject,
  isChildDataEntryObjectSelectable = () => true,
}) => {
  const { t } = useTranslation("deo_emissions_by_category_bar_chart");
  const translateContent = useTranslateContent();

  /**
   * Calculated structure sums per data entry object.
   * Root DEO is at index 0, children DEOs are at index 1+
   */
  const categoriesSumsPerDataEntryObject: IEmissionsByCategoryDEOBarsListCalculationResult[] =
    useMemo(() => {
      const rootCatResults = EvaluationUtilities.sumUpCalculationResultsForStructureGroupOverview(
        generalResult,
        currentDataEntryObject,
        structure,
        false, // Do not include children
      );
      const rootTotalSum =
        EmissionsUtilities.sumUpEmissionsResults(rootCatResults.structure.sums) +
        rootCatResults.firstLevelChildren.reduce(
          (prev, curr) => prev + EmissionsUtilities.sumUpEmissionsResults(curr.sums),
          0,
        );

      const rootDEOResult = {
        isRoot: true,
        hasChildren: currentDataEntryObject.children.length > 0,
        dataEntryObject: currentDataEntryObject,
        results: rootCatResults,
        totalSum: rootTotalSum,
      };

      const childrenResults = currentDataEntryObject.children.map((child) => {
        const childResult = EvaluationUtilities.sumUpCalculationResultsForStructureGroupOverview(
          generalResult,
          child,
          structure,
          true, // Include children
        );

        const childSum =
          EmissionsUtilities.sumUpEmissionsResults(childResult.structure.sums) +
          childResult.firstLevelChildren.reduce(
            (prev, curr) => prev + EmissionsUtilities.sumUpEmissionsResults(curr.sums),
            0,
          );

        return {
          isRoot: false,
          hasChildren: child.children.length > 0,
          dataEntryObject: child,
          results: childResult,
          totalSum: childSum,
        };
      });

      return [rootDEOResult, ...childrenResults];
    }, [currentDataEntryObject, generalResult, structure]);

  /** * Only show root structure group if at least one deo has a sum not equal to 0 */
  const showRootStructureGroup = useMemo(
    () =>
      categoriesSumsPerDataEntryObject.some(
        (result) => EmissionsUtilities.sumUpEmissionsResults(result.results.structure.sums) !== 0,
      ),
    [categoriesSumsPerDataEntryObject],
  );

  /** Categories to be shown in legend (root structure group may be hidden if no values are recorded directly for it) */
  const categoriesListedInLegend = useMemo(
    () => [...(showRootStructureGroup ? [structure] : []), ...structure.children],
    [showRootStructureGroup, structure],
  );

  /** Largest to smallest emitter */
  const sortedDEOResults = useMemo(
    () =>
      categoriesSumsPerDataEntryObject.sort((a, b) =>
        b.isRoot ? 1 : a.isRoot ? -1 : b.totalSum - a.totalSum,
      ),
    [categoriesSumsPerDataEntryObject],
  );

  /* Selectable Categories */
  const selectableCategories = useMemo(
    () =>
      categoriesListedInLegend.map((c, index) => {
        // Selected category is never selectable
        if (index === 0 && showRootStructureGroup) {
          return false;
        }

        return isChildCategorySelectable(c);
      }),
    [showRootStructureGroup, categoriesListedInLegend, isChildCategorySelectable],
  );

  // Filter Checkboxes

  const filterButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [shownDEOs, setShownDEOs] = useState<string[]>([]);

  // Reset shown DEOs when results change
  useEffect(() => {
    setShownDEOs(
      sortedDEOResults
        .filter((result) => result.totalSum !== 0)
        .map((result) => result.dataEntryObject.id),
    );
  }, [sortedDEOResults]);

  const shownDEOResults = useMemo(
    () => sortedDEOResults.filter((result) => shownDEOs.includes(result.dataEntryObject.id)),
    [sortedDEOResults, shownDEOs],
  );

  const handleShowFilterMenu = () => {
    setShowFilterMenu(true);
  };
  const handleCloseFilterMenu = () => {
    setShowFilterMenu(false);
  };

  const hasAnyEmissions = useMemo(
    () => categoriesSumsPerDataEntryObject.some((result) => result.totalSum !== 0),
    [categoriesSumsPerDataEntryObject],
  );

  return (
    <Box display="flex" gap={4}>
      {/* Legend */}
      <Box pt={2} pr={4}>
        <Box position="sticky" top={16}>
          <Box display="flex" flexDirection="column" gap={2}>
            {categoriesListedInLegend.map((category, index) => (
              <Box
                key={`${translateContent(category.name)}-${index}`}
                display="flex"
                alignItems="center"
                gap={2}
              >
                <SvgIcon sx={{ color: ChartColorPalette[index % ChartColorPalette.length] }}>
                  <PointFilledIcon />
                </SvgIcon>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{
                    ":hover": {
                      textDecoration: selectableCategories[index] ? "underline" : "none",
                    },
                    cursor: selectableCategories[index] ? "pointer" : "default",
                  }}
                  onClick={
                    selectableCategories[index]
                      ? () => onSelectChildGroup(showRootStructureGroup ? index - 1 : index)
                      : undefined
                  }
                >
                  {translateContent(category.name)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>

      <Divider orientation="vertical" flexItem />

      {/* Main Content */}
      <Box flex={1} pt={2}>
        {/* Filter Checkboxes */}
        <Box mb={4}>
          <Button onClick={handleShowFilterMenu} ref={filterButtonRef}>
            <Badge
              color="primary"
              badgeContent={shownDEOs.length !== sortedDEOResults.length ? shownDEOs.length : null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              showZero
            >
              <SvgIcon sx={{ mr: 1 }}>
                <FilterIcon />
              </SvgIcon>
            </Badge>
            {t("button_filter")}
          </Button>

          {/* Empty Message */}
          {!hasAnyEmissions && (
            <Typography textAlign="center" mt={2}>
              {t("no_deo_with_emissions_notice")}
            </Typography>
          )}

          <Menu
            open={showFilterMenu}
            onClose={handleCloseFilterMenu}
            anchorEl={filterButtonRef.current}
          >
            <FormGroup sx={{ px: 2 }}>
              {sortedDEOResults.map((result) => (
                <FormControlLabel
                  key={result.dataEntryObject.id}
                  control={
                    <Checkbox
                      checked={shownDEOs.includes(result.dataEntryObject.id)}
                      onChange={(_, checked) => {
                        if (checked) {
                          setShownDEOs((prev) => [...prev, result.dataEntryObject.id]);
                        } else {
                          setShownDEOs((prev) =>
                            prev.filter((id) => id !== result.dataEntryObject.id),
                          );
                        }
                      }}
                    />
                  }
                  label={
                    result.dataEntryObject.name +
                    (result.totalSum === 0 ? ` ${t("deo_result_no_emissions")}` : "")
                  }
                />
              ))}
            </FormGroup>
          </Menu>
        </Box>

        {/* Bar Charts */}
        <Box>
          <Grid container spacing={2}>
            {shownDEOResults.map((result) => (
              <Grid
                key={result.dataEntryObject.id}
                item
                xs={12}
                md={6}
                lg={4}
                xl={3}
                display="flex"
                justifyContent="center"
              >
                <DEOEmissionsByCategoryBarChart
                  deoCategoryEvaluationResult={result}
                  showStructureRootGroup={showRootStructureGroup}
                  onClickChildGroup={onSelectChildGroup}
                  onClickDataEntryObject={
                    !result.isRoot && isChildDataEntryObjectSelectable(result)
                      ? onSelectChildDataEntryObject
                      : undefined
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
