import { IIntercomEntity } from "@netcero/netcero-core-api-client";
import { IntercomReferencesForEntity } from "@netcero/netcero-common";
import { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo } from "react";

export type IIntercomReferencesContextState = Partial<
  Record<IIntercomEntity, IntercomReferencesForEntity>
>;

const IntercomReferencesContext = createContext<IIntercomReferencesContextState>({});

export const useIntercomReferences = (target: IIntercomEntity) => {
  const context = useContext(IntercomReferencesContext);
  return useMemo(() => {
    const value = context[target];

    if (value === undefined) {
      console.error(`Did not find references for target "${target}"!`);
      return {};
    }

    return value;
  }, [context, target]);
};

export const useIntercomReferenceForEntity = (target: IIntercomEntity) => {
  const references = useIntercomReferences(target);
  return useCallback(
    (identifier: string): number | null => {
      return references[identifier] ?? null;
    },
    [references],
  );
};

interface IIntercomReferencesProviderProps {
  state: IIntercomReferencesContextState;
}

export const IntercomReferencesProvider: FC<
  PropsWithChildren<IIntercomReferencesProviderProps>
> = ({ state, children }) => {
  return (
    <IntercomReferencesContext.Provider value={state}>
      {children}
    </IntercomReferencesContext.Provider>
  );
};
