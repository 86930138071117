import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { useMutation } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IBaseTargetData } from "@netcero/netcero-core-api-client";
import { getTargetsQueryKey } from "./targets.queries";
import { IDataEntryObjectIdentity } from "../data-entry-objects/data-entry-objects.mutations";

interface ITargetIdentity extends IDataEntryObjectIdentity {
  targetId: string;
}

interface ICreateTargetData extends IDataEntryObjectIdentity {
  payload: IBaseTargetData;
}

export const useCreateTargetMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      payload,
    }: ICreateTargetData) =>
      CoreApiService.TargetsApi.createTarget(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, { organizationId, recordingPeriodId }, context) =>
      invalidateQueries({
        awaitQueryKeys: [getTargetsQueryKey(organizationId, recordingPeriodId)],
      }),
  });
};

interface IUpdateTargetData extends ITargetIdentity {
  payload: IBaseTargetData;
}

export const useUpdateTargetMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      targetId,
      payload,
    }: IUpdateTargetData) =>
      CoreApiService.TargetsApi.updateTarget(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        targetId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, { organizationId, recordingPeriodId }, context) =>
      invalidateQueries({
        awaitQueryKeys: [getTargetsQueryKey(organizationId, recordingPeriodId)],
      }),
  });
};

interface IDeleteTargetData extends ITargetIdentity {}

export const useDeleteTargetMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      targetId,
    }: IDeleteTargetData) =>
      CoreApiService.TargetsApi.deleteTarget(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        targetId,
      )
        .then((req) => req())
        .then((res) => res.data),
    onSuccess: (data, { organizationId, recordingPeriodId }, context) =>
      invalidateQueries({
        awaitQueryKeys: [getTargetsQueryKey(organizationId, recordingPeriodId)],
      }),
  });
};
