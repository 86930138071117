import { useKeycloakOrganizationData } from "../user/hooks/keycloak-organization-data.hook";
import { useMemo } from "react";

export const useHasOrganizationFullAccess = (organizationId: string) => {
  const keycloakOrganizationData = useKeycloakOrganizationData(organizationId);

  return useMemo(() => {
    if (!keycloakOrganizationData) {
      return false;
    }
    return keycloakOrganizationData.attributes.full_access?.[0] === "true";
  }, [keycloakOrganizationData]);
};
