import { Box, Tooltip, useTheme } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  MessageCheckIcon,
  MessageReportIcon,
  TimerIcon,
} from "../../../common/constants/tabler-icon.constants";
import { IFeedbackState } from "./dma-category.utilities";

interface IDMACategoryFeedbackIndicator {
  feedbackState: IFeedbackState;
}
export const DMACategoryFeedbackIndicator: FC<IDMACategoryFeedbackIndicator> = ({
  feedbackState,
}) => {
  const theme = useTheme();
  const { t } = useTranslation("stakeholder_feedback_common");

  const content = useMemo(() => {
    switch (feedbackState) {
      case IFeedbackState.NotRequested:
        return <span>-</span>;
      case IFeedbackState.Accepted:
        return <CheckIcon color={`${theme.palette.success.main}`} />;
      case IFeedbackState.AcceptedWithComment:
        return <MessageCheckIcon color={`${theme.palette.success.main}`} />;
      case IFeedbackState.ChangesRequested:
        return <MessageReportIcon color={`${theme.palette.warning.main}`} />;
      case IFeedbackState.Pending:
        return <TimerIcon color={`${theme.palette.action.active}`} />;
      default:
        return <span>Error</span>;
    }
  }, [feedbackState, theme.palette]);

  return (
    <Tooltip title={t(`tooltip_feedback_${feedbackState}`)}>
      <Box display="flex">{content}</Box>
    </Tooltip>
  );
};
