/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IAllStakeholderFeedbacksResponse } from "../models";
// @ts-ignore
import { IGetAllStakeholderFeedbacks404Response } from "../models";
// @ts-ignore
import { IRequestEsrsCategoryFeedback404Response } from "../models";
// @ts-ignore
import { IRequestEsrsCategoryFeedback409Response } from "../models";
// @ts-ignore
import { IRequestFeedbackForDmaCategoryRequest } from "../models";
// @ts-ignore
import { IRevokeEsrsCategoryFeedbackRequest404Response } from "../models";
// @ts-ignore
import { IRevokeEsrsCategoryFeedbackRequest409Response } from "../models";
// @ts-ignore
import { IVerifyStakeholderFeedback404Response } from "../models";
// @ts-ignore
import { IVerifyStakeholderFeedback409Response } from "../models";
// @ts-ignore
import { IVerifyStakeholderFeedbackRequest } from "../models";
/**
 * DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi - axios parameter creator
 * @export
 */
export const DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get all (non-manual) stakeholder feedbacks for a DMA category.
     * @summary Get all stakeholder feedbacks for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStakeholderFeedbacks: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("getAllStakeholderFeedbacks", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("getAllStakeholderFeedbacks", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("getAllStakeholderFeedbacks", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("getAllStakeholderFeedbacks", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("getAllStakeholderFeedbacks", "dmaCategoryId", dmaCategoryId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/stakeholder-feedback`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Request feedback for a DMA category.
     * @summary Request feedback for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestEsrsCategoryFeedback: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("requestEsrsCategoryFeedback", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("requestEsrsCategoryFeedback", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("requestEsrsCategoryFeedback", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("requestEsrsCategoryFeedback", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("requestEsrsCategoryFeedback", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iRequestFeedbackForDmaCategoryRequest' is not null or undefined
      assertParamExists(
        "requestEsrsCategoryFeedback",
        "iRequestFeedbackForDmaCategoryRequest",
        iRequestFeedbackForDmaCategoryRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/request-feedback`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRequestFeedbackForDmaCategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Revoke feedback request for a DMA category.
     * @summary Revoke feedback request for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeEsrsCategoryFeedbackRequest: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("revokeEsrsCategoryFeedbackRequest", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "revokeEsrsCategoryFeedbackRequest",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "revokeEsrsCategoryFeedbackRequest",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("revokeEsrsCategoryFeedbackRequest", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("revokeEsrsCategoryFeedbackRequest", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iRequestFeedbackForDmaCategoryRequest' is not null or undefined
      assertParamExists(
        "revokeEsrsCategoryFeedbackRequest",
        "iRequestFeedbackForDmaCategoryRequest",
        iRequestFeedbackForDmaCategoryRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/revoke-feedback`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iRequestFeedbackForDmaCategoryRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Verify the stakeholder feedback for a given DMA category.
     * @summary Verify the stakeholder feedback for a given DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to verify feedback for.
     * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyStakeholderFeedback: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists("verifyStakeholderFeedback", "organizationId", organizationId);
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists("verifyStakeholderFeedback", "recordingPeriodId", recordingPeriodId);
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists("verifyStakeholderFeedback", "dataEntryObjectId", dataEntryObjectId);
      // verify required parameter 'esrsTopicId' is not null or undefined
      assertParamExists("verifyStakeholderFeedback", "esrsTopicId", esrsTopicId);
      // verify required parameter 'dmaCategoryId' is not null or undefined
      assertParamExists("verifyStakeholderFeedback", "dmaCategoryId", dmaCategoryId);
      // verify required parameter 'iVerifyStakeholderFeedbackRequest' is not null or undefined
      assertParamExists(
        "verifyStakeholderFeedback",
        "iVerifyStakeholderFeedbackRequest",
        iVerifyStakeholderFeedbackRequest,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/esrs-topics/{esrsTopicId}/dma-categories/{dmaCategoryId}/verify-stakeholder-feedback`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"esrsTopicId"}}`, encodeURIComponent(String(esrsTopicId)))
          .replace(`{${"dmaCategoryId"}}`, encodeURIComponent(String(dmaCategoryId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iVerifyStakeholderFeedbackRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi - functional programming interface
 * @export
 */
export const DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFp = function (
  configuration?: Configuration,
) {
  const localVarAxiosParamCreator =
    DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all (non-manual) stakeholder feedbacks for a DMA category.
     * @summary Get all stakeholder feedbacks for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllStakeholderFeedbacks(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IAllStakeholderFeedbacksResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllStakeholderFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Request feedback for a DMA category.
     * @summary Request feedback for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestEsrsCategoryFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestEsrsCategoryFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iRequestFeedbackForDmaCategoryRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Revoke feedback request for a DMA category.
     * @summary Revoke feedback request for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async revokeEsrsCategoryFeedbackRequest(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.revokeEsrsCategoryFeedbackRequest(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iRequestFeedbackForDmaCategoryRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Verify the stakeholder feedback for a given DMA category.
     * @summary Verify the stakeholder feedback for a given DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to verify feedback for.
     * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifyStakeholderFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifyStakeholderFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iVerifyStakeholderFeedbackRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi - factory interface
 * @export
 */
export const DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFp(configuration);
  return {
    /**
     * Get all (non-manual) stakeholder feedbacks for a DMA category.
     * @summary Get all stakeholder feedbacks for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllStakeholderFeedbacks(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      options?: any,
    ): AxiosPromise<IAllStakeholderFeedbacksResponse> {
      return localVarFp
        .getAllStakeholderFeedbacks(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Request feedback for a DMA category.
     * @summary Request feedback for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestEsrsCategoryFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .requestEsrsCategoryFeedback(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iRequestFeedbackForDmaCategoryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Revoke feedback request for a DMA category.
     * @summary Revoke feedback request for a DMA category.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object to use.
     * @param {string} esrsTopicId The id of the ESRS topic to use.
     * @param {string} dmaCategoryId The id of the category to use.
     * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    revokeEsrsCategoryFeedbackRequest(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .revokeEsrsCategoryFeedbackRequest(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iRequestFeedbackForDmaCategoryRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Verify the stakeholder feedback for a given DMA category.
     * @summary Verify the stakeholder feedback for a given DMA category.
     * @param {string} organizationId The id of the organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
     * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
     * @param {string} dmaCategoryId The id of the category to verify feedback for.
     * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifyStakeholderFeedback(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      esrsTopicId: string,
      dmaCategoryId: string,
      iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .verifyStakeholderFeedback(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          esrsTopicId,
          dmaCategoryId,
          iVerifyStakeholderFeedbackRequest,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi - interface
 * @export
 * @interface DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
 */
export interface DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiInterface {
  /**
   * Get all (non-manual) stakeholder feedbacks for a DMA category.
   * @summary Get all stakeholder feedbacks for a DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiInterface
   */
  getAllStakeholderFeedbacks(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IAllStakeholderFeedbacksResponse>;

  /**
   * Request feedback for a DMA category.
   * @summary Request feedback for a DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiInterface
   */
  requestEsrsCategoryFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Revoke feedback request for a DMA category.
   * @summary Revoke feedback request for a DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiInterface
   */
  revokeEsrsCategoryFeedbackRequest(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Verify the stakeholder feedback for a given DMA category.
   * @summary Verify the stakeholder feedback for a given DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
   * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
   * @param {string} dmaCategoryId The id of the category to verify feedback for.
   * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiInterface
   */
  verifyStakeholderFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi - object-oriented interface
 * @export
 * @class DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
 * @extends {BaseAPI}
 */
export class DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
  extends BaseAPI
  implements DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiInterface
{
  /**
   * Get all (non-manual) stakeholder feedbacks for a DMA category.
   * @summary Get all stakeholder feedbacks for a DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
   */
  public getAllStakeholderFeedbacks(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFp(this.configuration)
      .getAllStakeholderFeedbacks(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Request feedback for a DMA category.
   * @summary Request feedback for a DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to request feedback for a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
   */
  public requestEsrsCategoryFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFp(this.configuration)
      .requestEsrsCategoryFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iRequestFeedbackForDmaCategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Revoke feedback request for a DMA category.
   * @summary Revoke feedback request for a DMA category.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object to use.
   * @param {string} esrsTopicId The id of the ESRS topic to use.
   * @param {string} dmaCategoryId The id of the category to use.
   * @param {IRequestFeedbackForDmaCategoryRequest} iRequestFeedbackForDmaCategoryRequest Used to revoke feedback request for a DMA category for a given data entry object.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
   */
  public revokeEsrsCategoryFeedbackRequest(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iRequestFeedbackForDmaCategoryRequest: IRequestFeedbackForDmaCategoryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFp(this.configuration)
      .revokeEsrsCategoryFeedbackRequest(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iRequestFeedbackForDmaCategoryRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Verify the stakeholder feedback for a given DMA category.
   * @summary Verify the stakeholder feedback for a given DMA category.
   * @param {string} organizationId The id of the organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the category belongs to.
   * @param {string} esrsTopicId The id of the ESRS topic the category belongs to.
   * @param {string} dmaCategoryId The id of the category to verify feedback for.
   * @param {IVerifyStakeholderFeedbackRequest} iVerifyStakeholderFeedbackRequest Used to verify stakeholder feedback.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DoubleMaterialityAssessmentCategoryStakeholderFeedbackApi
   */
  public verifyStakeholderFeedback(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    esrsTopicId: string,
    dmaCategoryId: string,
    iVerifyStakeholderFeedbackRequest: IVerifyStakeholderFeedbackRequest,
    options?: AxiosRequestConfig,
  ) {
    return DoubleMaterialityAssessmentCategoryStakeholderFeedbackApiFp(this.configuration)
      .verifyStakeholderFeedback(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        esrsTopicId,
        dmaCategoryId,
        iVerifyStakeholderFeedbackRequest,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
