import { forwardRef } from "react";
import { IDataEntryObjectInputParameterStatus } from "@netcero/netcero-core-api-client";
import {
  DATA_ENTRY_OBJECT_INPUT_PARAMETER_EXCLUDED_COLOR,
  DATA_ENTRY_OBJECT_INPUT_PARAMETER_STATUS_COLORS,
} from "../data-entry-object-input-parameter.constants";
import { useTranslation } from "react-i18next";
import { BaseStatusIndicator } from "../../common/components/base-status-indicator.component";

type DataEntryObjectInputParameterStatusIndicatorProps = {
  onClick?: VoidFunction;
  disabled?: boolean;
  isMenuOpen?: boolean;
  shouldRenderChevronIcon?: boolean;
} & (
  | {
      status: IDataEntryObjectInputParameterStatus;
      excluded?: false;
    }
  | {
      status?: IDataEntryObjectInputParameterStatus;
      excluded: true;
    }
);

export const DataEntryObjectInputParameterStatusIndicator = forwardRef<
  HTMLDivElement,
  DataEntryObjectInputParameterStatusIndicatorProps
>(function DataEntryObjectInputParameterStatusIndicator(
  { status, excluded, onClick, disabled, isMenuOpen, shouldRenderChevronIcon },
  ref,
) {
  const { t } = useTranslation("data_entry_object_input_parameter_common");

  const statusColor = excluded
    ? DATA_ENTRY_OBJECT_INPUT_PARAMETER_EXCLUDED_COLOR
    : DATA_ENTRY_OBJECT_INPUT_PARAMETER_STATUS_COLORS[status];

  const isFilled = !excluded;

  return (
    <BaseStatusIndicator
      ref={ref}
      onClick={onClick}
      color={statusColor}
      label={t(excluded ? "excluded_status" : `status.${status}.name`)}
      isFilled={isFilled}
      disabled={disabled}
      isMenuOpen={isMenuOpen}
      shouldRenderChevronIcon={shouldRenderChevronIcon}
    />
  );
});
