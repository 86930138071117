import {
  IDMACategoryWithEffectsAndFeedbacksAndChildren,
  IStakeholderFeedback,
} from "@netcero/netcero-core-api-client";

export enum FeedbackIndicatorStatusEnum {
  Submitted = "submitted",
  NotSubmitted = "not-submitted",
  ParentNotSubmitted = "parent-not-submitted",
  ParentSubmitted = "parent-submitted",
}

export class StakeholderFeedbackUtilities {
  public static hasFeedbackBeenSubmitted(feedback: IStakeholderFeedback) {
    return feedback.assessment !== undefined || feedback.feedback !== undefined || feedback.agreed;
  }

  public static hasAllFeedbackBeenSubmitted(feedback: IStakeholderFeedback[]) {
    return feedback.every((f) => StakeholderFeedbackUtilities.hasFeedbackBeenSubmitted(f));
  }

  public static haveAllChildrenBeenSubmitted(
    children: IDMACategoryWithEffectsAndFeedbacksAndChildren[],
  ) {
    for (const child of children) {
      // make sure that all current feedbacks have been submitted
      if (
        child.feedback !== null &&
        child.feedback !== undefined &&
        child.feedback.length > 0 &&
        !StakeholderFeedbackUtilities.hasAllFeedbackBeenSubmitted(child.feedback)
      ) {
        return false;
      }
      // make sure that all children have been submitted
      if (
        child.children !== undefined &&
        child.children !== null &&
        !StakeholderFeedbackUtilities.haveAllChildrenBeenSubmitted(child.children)
      ) {
        return false;
      }
    }
    return true;
  }

  public static getCompoundCategoryStatus(
    category: IDMACategoryWithEffectsAndFeedbacksAndChildren,
    hasToGiveFeedback: boolean,
    parentCategoryStatus?: FeedbackIndicatorStatusEnum,
  ): FeedbackIndicatorStatusEnum {
    if (hasToGiveFeedback) {
      return StakeholderFeedbackUtilities.haveAllChildrenBeenSubmitted([category])
        ? FeedbackIndicatorStatusEnum.Submitted
        : FeedbackIndicatorStatusEnum.NotSubmitted;
    } else {
      if (parentCategoryStatus === undefined) {
        console.warn("Does not have to give feedback + has no parent - this should never happen!");
      }
      return parentCategoryStatus === FeedbackIndicatorStatusEnum.Submitted
        ? FeedbackIndicatorStatusEnum.ParentSubmitted
        : FeedbackIndicatorStatusEnum.ParentNotSubmitted;
    }
  }
}
