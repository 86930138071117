export const ESRS_TOPIC_QUERY_PARAM = "esrsTopicId";
export const DMA_CATEGORY_QUERY_PARAM = "dmaCategoryId";
export const FILTER_BY_USER_QUERY_PARAM = "filterByUserId";
export const EDIT_FINANCIAL_EFFECT_QUERY_PARAM = "editFinancialEffectId";
export const EDIT_MATERIAL_IMPACT_QUERY_PARAM = "editMaterialImpactId";
export const VIEWED_COMMENT_QUERY_PARAM = "viewedCommentId";

export const APP_QUERY_PARAMS = {
  ESRS_TOPIC_QUERY_PARAM,
  DMA_CATEGORY_QUERY_PARAM,
  FILTER_BY_USER_QUERY_PARAM,
  EDIT_FINANCIAL_EFFECT_QUERY_PARAM,
  EDIT_MATERIAL_IMPACT_QUERY_PARAM,
  VIEWED_COMMENT_QUERY_PARAM,
};
