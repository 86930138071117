import { FC } from "react";
import { Alert, AlertTitle } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ITargetPathIsCommittedInfoComponentProps {}

export const TargetPathIsCommittedInfoComponent: FC<
  ITargetPathIsCommittedInfoComponentProps
> = () => {
  const { t } = useTranslation("target_path_is_committed_info_component");
  return (
    <Alert severity="info" variant="outlined">
      <AlertTitle>{t("title")}</AlertTitle>
      {t("text")}
    </Alert>
  );
};
