import { useMutation } from "@tanstack/react-query";
import { IDataEntryObjectData } from "@netcero/netcero-core-api-client";
import { CoreApiService } from "../core-api/core-api.service";
import { getRecordingPeriodOrganizationStructureQueryKey } from "../organization-structures/organization-structures.queries";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";

export interface IDataEntryObjectIdentity {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
}

interface IUpdateDataEntryObjectData extends IDataEntryObjectIdentity {
  payload: IDataEntryObjectData;
}

export const useUpdateDataEntryObjectMutation = () => {
  const invalidateQueries = useInvalidateQueriesByKeys();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      payload,
    }: IUpdateDataEntryObjectData) => {
      return CoreApiService.DataEntryObjectApi.updateDataEntryObject(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        {
          name: payload.name,
          description: payload.description || undefined,
          inheritsValuesFrom: payload.inheritsValuesFrom,
          objectType: payload.objectType,
          partOfValueChain: payload.partOfValueChain,
          operationalControl: payload.operationalControl,
          financiallyConsolidated: payload.financiallyConsolidated,
          country: payload.country,
          shareHeldByParent: payload.shareHeldByParent,
        },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, { organizationId, recordingPeriodId }, context) =>
      invalidateQueries({
        awaitQueryKeys: [
          getRecordingPeriodOrganizationStructureQueryKey(organizationId, recordingPeriodId),
        ],
      }),
  });
};
