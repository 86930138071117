import { ISource, ISourceFileState } from "@netcero/netcero-core-api-client";

type SourceForVerification = Pick<ISource, "description" | "files">;

export class SourcesVerification {
  public static canSourceBeReferenced(source: SourceForVerification): boolean;
  public static canSourceBeReferenced(source: SourceForVerification, error: Error): void;

  public static canSourceBeReferenced(
    source: SourceForVerification,
    errorIfInvalid?: Error,
  ): boolean | void {
    const canSourceBeUsed =
      source.description !== undefined ||
      source.files.some((f) => f.state === ISourceFileState.UploadSuccessful);

    if (errorIfInvalid === undefined) {
      return canSourceBeUsed;
    }

    if (!canSourceBeUsed) {
      throw errorIfInvalid;
    }
  }
}
