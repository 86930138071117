/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IApplyAppliedDistributionCriteriaForRecordingPeriod404Response } from "../models";
// @ts-ignore
import { IApplyAppliedDistributionCriteriaForRecordingPeriod409Response } from "../models";
// @ts-ignore
import { ICreateAppliedDistributionCriteriaForRecordingPeriod404Response } from "../models";
// @ts-ignore
import { ICreateAppliedDistributionCriteriaForRecordingPeriod409Response } from "../models";
// @ts-ignore
import { ICreateReferenceBudget404Response } from "../models";
// @ts-ignore
import { IDeleteAppliedDistributionCriteriaForRecordingPeriod404Response } from "../models";
// @ts-ignore
import { IDeleteAppliedDistributionCriteriaForRecordingPeriod409Response } from "../models";
// @ts-ignore
import { IGetAppliedDistributionCriteriaForRecordingPeriodResponse } from "../models";
// @ts-ignore
import { IRemoveAppliedDistributionCriteriaApplicationForRecordingPeriod409Response } from "../models";
/**
 * AppliedDistributionCriteriaApi - axios parameter creator
 * @export
 */
export const AppliedDistributionCriteriaApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Apply the applied distribution criteria for a recording period
     * @summary Apply the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAppliedDistributionCriteriaForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "applyAppliedDistributionCriteriaForRecordingPeriod",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "applyAppliedDistributionCriteriaForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'distributionCriteriaId' is not null or undefined
      assertParamExists(
        "applyAppliedDistributionCriteriaForRecordingPeriod",
        "distributionCriteriaId",
        distributionCriteriaId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/applied-distribution-criteria/{distributionCriteriaId}/apply`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"distributionCriteriaId"}}`,
            encodeURIComponent(String(distributionCriteriaId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create the applied distribution criteria for a recording period
     * @summary Create the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAppliedDistributionCriteriaForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "createAppliedDistributionCriteriaForRecordingPeriod",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "createAppliedDistributionCriteriaForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'distributionCriteriaId' is not null or undefined
      assertParamExists(
        "createAppliedDistributionCriteriaForRecordingPeriod",
        "distributionCriteriaId",
        distributionCriteriaId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/applied-distribution-criteria/{distributionCriteriaId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"distributionCriteriaId"}}`,
            encodeURIComponent(String(distributionCriteriaId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete the applied distribution criteria for a recording period, as well as all configured values
     * @summary Delete the applied distribution criteria for a recording period, as well as all configured values
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAppliedDistributionCriteriaForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "deleteAppliedDistributionCriteriaForRecordingPeriod",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "deleteAppliedDistributionCriteriaForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'distributionCriteriaId' is not null or undefined
      assertParamExists(
        "deleteAppliedDistributionCriteriaForRecordingPeriod",
        "distributionCriteriaId",
        distributionCriteriaId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/applied-distribution-criteria/{distributionCriteriaId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"distributionCriteriaId"}}`,
            encodeURIComponent(String(distributionCriteriaId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint retrieves all criteria that is configured for the current organization, populated with the application values of the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppliedDistributionCriteriaForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "getAppliedDistributionCriteriaForRecordingPeriod",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "getAppliedDistributionCriteriaForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/applied-distribution-criteria`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Remove the application of the applied distribution criteria for a recording period
     * @summary Remove the application of the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAppliedDistributionCriteriaApplicationForRecordingPeriod: async (
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "removeAppliedDistributionCriteriaApplicationForRecordingPeriod",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "removeAppliedDistributionCriteriaApplicationForRecordingPeriod",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'distributionCriteriaId' is not null or undefined
      assertParamExists(
        "removeAppliedDistributionCriteriaApplicationForRecordingPeriod",
        "distributionCriteriaId",
        distributionCriteriaId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/applied-distribution-criteria/{distributionCriteriaId}/apply`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(
            `{${"distributionCriteriaId"}}`,
            encodeURIComponent(String(distributionCriteriaId)),
          );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AppliedDistributionCriteriaApi - functional programming interface
 * @export
 */
export const AppliedDistributionCriteriaApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AppliedDistributionCriteriaApiAxiosParamCreator(configuration);
  return {
    /**
     * Apply the applied distribution criteria for a recording period
     * @summary Apply the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async applyAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.applyAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Create the applied distribution criteria for a recording period
     * @summary Create the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Delete the applied distribution criteria for a recording period, as well as all configured values
     * @summary Delete the applied distribution criteria for a recording period, as well as all configured values
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint retrieves all criteria that is configured for the current organization, populated with the application values of the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IGetAppliedDistributionCriteriaForRecordingPeriodResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Remove the application of the applied distribution criteria for a recording period
     * @summary Remove the application of the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AppliedDistributionCriteriaApi - factory interface
 * @export
 */
export const AppliedDistributionCriteriaApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AppliedDistributionCriteriaApiFp(configuration);
  return {
    /**
     * Apply the applied distribution criteria for a recording period
     * @summary Apply the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    applyAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .applyAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Create the applied distribution criteria for a recording period
     * @summary Create the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .createAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete the applied distribution criteria for a recording period, as well as all configured values
     * @summary Delete the applied distribution criteria for a recording period, as well as all configured values
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint retrieves all criteria that is configured for the current organization, populated with the application values of the specified recording period.
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAppliedDistributionCriteriaForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      options?: any,
    ): AxiosPromise<IGetAppliedDistributionCriteriaForRecordingPeriodResponse> {
      return localVarFp
        .getAppliedDistributionCriteriaForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Remove the application of the applied distribution criteria for a recording period
     * @summary Remove the application of the applied distribution criteria for a recording period
     * @param {string} organizationId The id of the organization the entity belongs to.
     * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
     * @param {string} distributionCriteriaId The id of the distribution criteria to use.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
      organizationId: string,
      recordingPeriodId: string,
      distributionCriteriaId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
          organizationId,
          recordingPeriodId,
          distributionCriteriaId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AppliedDistributionCriteriaApi - interface
 * @export
 * @interface AppliedDistributionCriteriaApi
 */
export interface AppliedDistributionCriteriaApiInterface {
  /**
   * Apply the applied distribution criteria for a recording period
   * @summary Apply the applied distribution criteria for a recording period
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApiInterface
   */
  applyAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Create the applied distribution criteria for a recording period
   * @summary Create the applied distribution criteria for a recording period
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApiInterface
   */
  createAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Delete the applied distribution criteria for a recording period, as well as all configured values
   * @summary Delete the applied distribution criteria for a recording period, as well as all configured values
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApiInterface
   */
  deleteAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * This endpoint retrieves all criteria that is configured for the current organization, populated with the application values of the specified recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApiInterface
   */
  getAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IGetAppliedDistributionCriteriaForRecordingPeriodResponse>;

  /**
   * Remove the application of the applied distribution criteria for a recording period
   * @summary Remove the application of the applied distribution criteria for a recording period
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApiInterface
   */
  removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;
}

/**
 * AppliedDistributionCriteriaApi - object-oriented interface
 * @export
 * @class AppliedDistributionCriteriaApi
 * @extends {BaseAPI}
 */
export class AppliedDistributionCriteriaApi
  extends BaseAPI
  implements AppliedDistributionCriteriaApiInterface
{
  /**
   * Apply the applied distribution criteria for a recording period
   * @summary Apply the applied distribution criteria for a recording period
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApi
   */
  public applyAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ) {
    return AppliedDistributionCriteriaApiFp(this.configuration)
      .applyAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriteriaId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create the applied distribution criteria for a recording period
   * @summary Create the applied distribution criteria for a recording period
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApi
   */
  public createAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ) {
    return AppliedDistributionCriteriaApiFp(this.configuration)
      .createAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriteriaId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete the applied distribution criteria for a recording period, as well as all configured values
   * @summary Delete the applied distribution criteria for a recording period, as well as all configured values
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApi
   */
  public deleteAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ) {
    return AppliedDistributionCriteriaApiFp(this.configuration)
      .deleteAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriteriaId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint retrieves all criteria that is configured for the current organization, populated with the application values of the specified recording period.
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApi
   */
  public getAppliedDistributionCriteriaForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    options?: AxiosRequestConfig,
  ) {
    return AppliedDistributionCriteriaApiFp(this.configuration)
      .getAppliedDistributionCriteriaForRecordingPeriod(organizationId, recordingPeriodId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Remove the application of the applied distribution criteria for a recording period
   * @summary Remove the application of the applied distribution criteria for a recording period
   * @param {string} organizationId The id of the organization the entity belongs to.
   * @param {string} recordingPeriodId The id of the recording period the entity belongs to.
   * @param {string} distributionCriteriaId The id of the distribution criteria to use.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AppliedDistributionCriteriaApi
   */
  public removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
    organizationId: string,
    recordingPeriodId: string,
    distributionCriteriaId: string,
    options?: AxiosRequestConfig,
  ) {
    return AppliedDistributionCriteriaApiFp(this.configuration)
      .removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriteriaId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
