import { ICommentLinkMetadata } from "@netcero/netcero-core-api-client";
import { UUID } from "../common";
import { AppUrlUtilities, VIEWED_COMMENT_QUERY_PARAM } from "../app-urls";

export class CommentUtilities {
  /**
   * This method generates the link to the given entity for the given base (domain).
   * @param origin The domain for which the link should be generated
   * @param metadata The metadata to use for generation
   */
  public static generateEntityLink(origin: string, metadata: ICommentLinkMetadata): URL {
    switch (metadata.entity) {
      // IROs
      case "material-impact":
      case "financial-effect": {
        return AppUrlUtilities.getUrlForRecordingPeriodDma({
          origin,
          organizationId: metadata.organizationId,
          recordingPeriodId: metadata.recordingPeriodId,
          esrsTopicId: metadata.esrsTopicId,
          dmaCategoryId: metadata.topLevelDmaCategoryId,
          ...(metadata.entity === "material-impact"
            ? {
                editMaterialImpactId: metadata.materialImpactId,
              }
            : {
                editFinancialEffectId: metadata.financialEffectId,
              }),
        });
      }
    }
  }

  /**
   * This method generates the link to the given comment within the given entity for the given base (domain).
   * @param origin The domain for which the link should be generated
   * @param metadata The metadata to use for generation
   * @param commentId The comment for which the link should be generated
   */
  public static generateEntityLinkForComment(
    origin: string,
    metadata: ICommentLinkMetadata,
    commentId: UUID,
  ): URL {
    const url = CommentUtilities.generateEntityLink(origin, metadata);
    url.searchParams.append(VIEWED_COMMENT_QUERY_PARAM, commentId);
    return url;
  }
}
