import { IFinancialEffectHorizonCalculatorData } from "../types/financial-effect-horizon-calculator-data.interface";
import { IFinancialEffectCalculatorData } from "../types/financial-effect-calculator-data.type";
import { FinancialEffectCalculator } from "./financial-effect.calculator";
import { IBaseCalculatorContext } from "../types/base-calculator-context.interface";

export class FinancialEffectHorizonsCalculator {
  private readonly shortTerm: IFinancialEffectCalculatorData;
  private readonly mediumTerm: IFinancialEffectCalculatorData;
  private readonly longTerm: IFinancialEffectCalculatorData;

  constructor(
    horizons: IFinancialEffectHorizonCalculatorData,
    private readonly context: IBaseCalculatorContext,
  ) {
    Object.assign(this, horizons);
  }

  private getCalculators(): FinancialEffectCalculator[] {
    return [this.shortTerm, this.mediumTerm, this.longTerm].map(
      (horizon) => new FinancialEffectCalculator(horizon, this.context),
    );
  }

  public calculateSeverity(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateSeverity()));
  }

  public calculateMaterialityDegree(): number {
    return Math.max(...this.getCalculators().map((calc) => calc.calculateMaterialityDegree()));
  }
}
