import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { ESRS_CATEGORIES } from "./esrs-dma-categories.constant";
import { RecursiveUtilities } from "../../common";

export const generateDMACategoryTranslations = (language: IContentLanguage) => {
  const flat = ESRS_CATEGORIES.flatMap((c) => c.children).flatMap((c) =>
    RecursiveUtilities.flattenRecursiveStructureDown(c),
  );
  return flat.reduce((acc, curr) => {
    const value = curr.name[language];

    if (value === undefined) {
      // eslint-disable-next-line
      console.error(`Translation for ${language} not found for DMA category ${curr.identifier}!`);
    } else {
      acc[curr.identifier] = value;
    }

    return acc;
  }, {} as Record<string, string>);
};

export const generateESRSTopicTranslations = (language: IContentLanguage) => {
  return ESRS_CATEGORIES.reduce((acc, curr) => {
    const value = curr.name[language];

    if (value === undefined) {
      // eslint-disable-next-line
      console.error(`Translation for ${language} not found for DMA category ${curr.identifier}!`);
    } else {
      acc[curr.identifier] = value;
    }

    return acc;
  }, {} as Record<string, string>);
};
