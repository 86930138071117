import { Box, FormControl, FormHelperText, IconButton, TextField, Tooltip } from "@mui/material";
import {
  IInputParameterValueMetaDataText,
  IInputParameterValueMetaDataTextMultiLine,
} from "@netcero/netcero-core-api-client";
import { FC, FocusEvent, FocusEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AddIcon, RemoveIcon } from "../../common/constants/tabler-icon.constants";
import {
  DataEntryObjectInputParameterValueDefinitionForText,
  DataEntryObjectInputParameterValueDefinitionForTextSingle,
  DataEntryObjectInputParameterValuesVerification,
  OptionalDataEntryObjectInputParameterValueDefinition,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
  useVariantSxStyles,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";

interface ITextInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForText>;
  onChange: (value: OptionalDataEntryObjectInputParameterValueDefinition) => void;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataText;
  label?: string;
  required?: boolean;
  disableMaxWidth?: boolean;
  autoFocus?: boolean;
}

export const TextInputComponent: FC<ITextInputComponentProps> = ({
  variant,
  value,
  onChange,
  onSubmit,
  disabled,
  error,
  metaData,
  label,
  required,
  disableMaxWidth,
  autoFocus,
}) => {
  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const typedValue = event.currentTarget.value as string;
    onChange(
      DataEntryObjectInputParameterValuesVerification.sanitizeValue(typedValue, metaData, false),
    );
  };

  if (metaData.multipart) {
    return (
      // <MultiTextInputComponent
      //   value={value}
      //   onChange={onChange}
      //   onBlur={onBlur}
      //   onSubmit={onSubmit}
      //   disabled={disabled}
      //   error={error}
      //   startAdornment={startAdornment}
      //   label={label}
      //   required={required}
      // />
      <>Currently not supported</>
    );
  } else {
    return (
      <SingleTextInputComponent
        variant={variant}
        value={
          value as OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForTextSingle>
        }
        onChange={onChange}
        onBlur={handleBlur}
        onSubmit={onSubmit}
        disabled={disabled}
        error={error}
        label={label}
        required={required}
        disableMaxWidth={disableMaxWidth}
        multiLineText={metaData.multiLine}
        autoFocus={autoFocus}
      />
    );
  }
};

// Internal Components

interface IMultiTextInputComponentProps {
  // TODO: adapt to new values if ever needed again
  variant: IDataEntryObjectValueInputVariant;
  value: string;
  onChange: (value: string) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  label?: string;
  required?: boolean;
  multiLineText?: IInputParameterValueMetaDataTextMultiLine;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MultiTextInputComponent: FC<IMultiTextInputComponentProps> = ({
  variant,
  value,
  onChange,
  onBlur,
  onSubmit,
  disabled,
  error,
  label,
  required,
  multiLineText,
}) => {
  const { t } = useTranslation("multi_text_input_component");

  const [entries, setEntries] = useState<string[]>(JSON.parse(value || `[]`));
  useEffect(() => {
    setEntries(JSON.parse(value || `[]`));
  }, [value]);
  useEffect(() => {
    const result = JSON.stringify(entries);
    if (result !== value) {
      onChange(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entries, onChange]);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {entries.map((entry, index) => (
        <Box key={index} display="flex" alignItems="start" mb={label ? 2 : 1}>
          <SingleTextInputComponent
            variant={variant}
            // Empty elements don't make a whole lot of sense
            required={true}
            value={entry}
            onChange={(value) => {
              const newEntries = [...entries];
              newEntries[index] = value;
              setEntries(newEntries);
            }}
            onBlur={onBlur}
            error={error}
            hideError={index !== entries.length - 1}
            disabled={disabled}
            onSubmit={onSubmit}
            multiLineText={multiLineText}
            label={label}
          />
          <Tooltip title={t("remove_entry")}>
            <span>
              <IconButton
                size="medium"
                disabled={disabled}
                onClick={() => {
                  const newEntries = [...entries];
                  newEntries.splice(index, 1);
                  setEntries(newEntries);
                }}
                sx={{ mt: 1 }}
              >
                <RemoveIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Box>
      ))}
      <Tooltip title={t("add_entry")}>
        <Box ml="auto" mt={1}>
          <IconButton
            size="medium"
            disabled={disabled}
            onClick={() => {
              setEntries((prev) => [...prev, ""]);
            }}
          >
            <AddIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </Box>
  );
};

interface ISingleTextInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForTextSingle>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForTextSingle) => void;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onSubmit?: () => void;
  disabled?: boolean;
  error?: string;
  hideError?: boolean;
  label?: string;
  required?: boolean;
  disableMaxWidth?: boolean;
  multiLineText?: IInputParameterValueMetaDataTextMultiLine;
  autoFocus?: boolean;
}

const SingleTextInputComponent: FC<ISingleTextInputComponentProps> = ({
  variant,
  value,
  onChange,
  onBlur,
  onSubmit,
  disabled,
  error,
  hideError,
  label,
  required,
  disableMaxWidth,
  multiLineText,
  autoFocus,
}) => {
  const { t } = useTranslation("text_input");
  const stylesSx = useVariantSxStyles(variant);
  const inputVariantProps = useVariantFormProps(variant);

  return (
    <FormControl
      {...inputVariantProps}
      error={!!error}
      required={required}
      sx={{ maxWidth: !disableMaxWidth ? 260 : undefined }}
      fullWidth
    >
      <TextField
        required={required}
        label={label}
        {...inputVariantProps}
        placeholder={t("placeholder")}
        value={value ?? ""}
        disabled={disabled}
        error={!!error}
        onChange={(e) => onChange(e.target.value)}
        onBlur={onBlur}
        multiline={true}
        autoFocus={autoFocus}
        maxRows={multiLineText ? multiLineText.maxLines : undefined}
        minRows={multiLineText ? multiLineText.minLines : undefined}
        onKeyDown={(e) => {
          if (e.code === "Enter" || e.code === "NumpadEnter") {
            onSubmit?.();
          }
        }}
        InputProps={{
          sx: stylesSx,
        }}
      />
      {error && !hideError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
