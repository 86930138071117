import { IDMAConfiguration } from "@netcero/netcero-core-api-client";
import { IBaseCalculatorContext } from "../types/base-calculator-context.interface";

export class DmaConfigurationUtilities {
  public static materialContextFromConfig(config: IDMAConfiguration): IBaseCalculatorContext {
    return {
      materialityThreshold: config.thresholdImpact,
      probabilityOfOccurrenceSteps: config.probabilityOfOccurrenceStepsMaterial ?? null,
    };
  }

  public static financialContextFromConfig(config: IDMAConfiguration): IBaseCalculatorContext {
    return {
      materialityThreshold: config.thresholdEffect,
      probabilityOfOccurrenceSteps: config.probabilityOfOccurrenceStepsFinancial ?? null,
    };
  }
}
