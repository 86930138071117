import { useEffect, useState } from "react";

/**
 * Hook to observe the size of an element. Using a ref for the element might not work reliably in all cases. (use state there instead)
 * State is already provided in the useObserveSizeWithState hook (which wraps this hook).
 * @param element The element to observe
 * @param cb The callback to call when the size changes
 */
export const useObserveSize = (element: HTMLElement | null, cb: ResizeObserverCallback) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(cb);

    // Subscribe to resize events
    if (element) {
      resizeObserver.observe(element);
    } else {
      console.debug("Ref is not available for observing size!");
    }

    // Destroy on unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [element, cb]);
};

export const useObserveSizeWithState = (cb: ResizeObserverCallback) => {
  const [element, setElement] = useState<HTMLElement | null>(null);
  useObserveSize(element, cb);
  return setElement;
};
