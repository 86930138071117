import { FC, useCallback } from "react";
import { Button, Tooltip } from "@mui/material";
import { DownloadIcon } from "../../common/constants/tabler-icon.constants";
import { useTranslation } from "react-i18next";
import { useCurrentLanguage } from "../../content-translation/hooks/current-language.hook";
import { CoreApiService } from "../../core-api/core-api.service";
import { DmaUtilities } from "@netcero/netcero-common";
import { FileUtilities } from "../../common/utilities/file.utilities";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import {
  BasicSnackbarApiActionType,
  IAppSnackbarEnqueueBasicSuccessMessageData,
} from "../../app-snackbar/app-snackbar.interfaces";

export type DmaExportType = "iros" | "stakeholder-feedback";

interface IDmaExportButtonComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  download: DmaExportType;
}

const EXPORT_DMA_IROS_ACTION: IAppSnackbarEnqueueBasicSuccessMessageData = {
  type: BasicSnackbarApiActionType.EXPORT_DMA_IROS,
};

export const DmaExportButtonComponent: FC<IDmaExportButtonComponentProps> = ({
  download,
  recordingPeriodId,
  dataEntryObjectId,
  organizationId,
}) => {
  const { t } = useTranslation("dma_export_button_component");
  const currentLanguage = useCurrentLanguage();
  const appSnackbar = useAppSnackbar();

  const handleDownload = useCallback(async () => {
    await appSnackbar.wrapApiPromise(async () => {
      const request = await CoreApiService.DMAApi.exportDmaIros(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        currentLanguage,
      );
      const result = await request();
      // rather hacky, but the generator determines that the return type is "File",
      // while in reality, a string is returned...
      const asBlob = new Blob([result.data as unknown as string], { type: "text/csv" });
      FileUtilities.downloadBlobAsFile(DmaUtilities.getIroExportFileName(), asBlob);
    }, EXPORT_DMA_IROS_ACTION);
  }, [appSnackbar, currentLanguage, dataEntryObjectId, organizationId, recordingPeriodId]);

  return (
    <Tooltip
      title={t("button_tooltip", {
        context: download,
        currentLanguage: t(`translationsPerLanguage.${currentLanguage}`, {
          ns: "language",
        }),
      })}
    >
      <Button variant="outlined" startIcon={<DownloadIcon />} onClick={handleDownload}>
        {t("button_content", { context: download })}
      </Button>
    </Tooltip>
  );
};
