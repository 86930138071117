import { useCallback } from "react";
import { IDMACategory } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";

type CategoryForTranslate = Pick<IDMACategory, "name" | "static">;

export type IDMACategoryNameRenderer = (
  dmaCategory: CategoryForTranslate | null | undefined,
) => string;

export const useRenderDMACategoryName = (): IDMACategoryNameRenderer => {
  const { t } = useTranslation("dma_categories");
  return useCallback(
    (dmaCategory: CategoryForTranslate | null | undefined) => {
      if (dmaCategory === null || dmaCategory === undefined) {
        return "";
      }

      return dmaCategory.static !== undefined ? t(dmaCategory.static.identifier) : dmaCategory.name;
    },
    [t],
  );
};
