import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useFormatHumanReadableDate = () => {
  const { i18n } = useTranslation();
  const format = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.language, {
        dateStyle: "long",
        timeStyle: "short",
      }),
    [i18n.language],
  );
  return format.format;
};
