import { Box, Button, Typography } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export interface IConfigureCriterionComponentProps {
  onClick: () => void;
}

export const ConfigureCriterionComponent: FC<IConfigureCriterionComponentProps> = ({ onClick }) => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_component", {
    keyPrefix: "configure_criterion",
  });
  return (
    <Box display="flex" alignItems="center" mt={8} flexDirection="column" gap={2}>
      <Typography variant="subtitle1">{t("text")}</Typography>
      <Button variant="contained" endIcon={<ArrowRightIcon />} onClick={onClick}>
        {t("button_text")}
      </Button>
    </Box>
  );
};
