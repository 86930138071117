import {
  IDMAFinancialEffectType,
  IDMAFinancialEffectWithCalculatedValues,
  IDMAMaterialImpactWithCalculatedValues,
  IESRSTopic,
  IESRSTopicIdentifier,
} from "@netcero/netcero-core-api-client";
import { IroEffect, IIrosListEntry } from "../impacts-risks-opportunitites.types";
import { TreeUtilities } from "../../../common/utilities/tree.utilities";
import { IroType } from "@netcero/netcero-common";

const EFFECT_TYPE_LOOKUP: Record<IDMAFinancialEffectType, IroType> = {
  risk: "risk",
  chance: "opportunity",
};

const EFFECT_EFFECT_LOOKUP: Record<IDMAFinancialEffectType, IroEffect> = {
  risk: "negative",
  chance: "positive",
};

export class IROsListUtilities {
  public static convertImpactsToListEntries(
    esrsTopic: IESRSTopicIdentifier,
    impacts: IDMAMaterialImpactWithCalculatedValues[],
  ): IIrosListEntry[] {
    return impacts.map((impact) => IROsListUtilities.convertImpactToListEntry(esrsTopic, impact));
  }

  public static convertImpactToListEntry(
    esrsTopic: IESRSTopicIdentifier,
    impact: IDMAMaterialImpactWithCalculatedValues,
  ): IIrosListEntry {
    const horizons = [
      impact.horizons.shortTerm,
      impact.horizons.mediumTerm,
      impact.horizons.longTerm,
    ];
    return {
      id: impact.id,
      topicIdentifier: esrsTopic,
      title: impact.title,
      type: "impact",
      effect: horizons.map((horizon) =>
        horizon.irreversibility === undefined ? "positive" : "negative",
      ) as [IroEffect, IroEffect, IroEffect],
      materiality: impact.materialityDegree,
      isMaterial: impact.material,
    };
  }

  public static convertEffectsToListEntries(
    esrsTopic: IESRSTopicIdentifier,
    effects: IDMAFinancialEffectWithCalculatedValues[],
  ): IIrosListEntry[] {
    return effects.map((effect) => IROsListUtilities.convertEffectToListEntry(esrsTopic, effect));
  }

  public static convertEffectToListEntry(
    esrsTopic: IESRSTopicIdentifier,
    effect: IDMAFinancialEffectWithCalculatedValues,
  ): IIrosListEntry {
    return {
      id: effect.id,
      topicIdentifier: esrsTopic,
      title: effect.title,
      type: EFFECT_TYPE_LOOKUP[effect.effectType],
      effect: [EFFECT_EFFECT_LOOKUP[effect.effectType]],
      materiality: effect.materialityDegree,
      isMaterial: effect.material,
    };
  }

  public static convertTopicsToIroListEntries(esrsTopics: IESRSTopic[]) {
    const materialImpacts: IDMAMaterialImpactWithCalculatedValues[] = [];
    const financialEffects: IDMAFinancialEffectWithCalculatedValues[] = [];
    const irosListEntries: IIrosListEntry[] = [];

    esrsTopics.forEach((esrsTopic) => {
      const dmaCategories = esrsTopic.recordedESRSTopic?.dmaCategories ?? [];

      const impacts = TreeUtilities.flatMapMultiple(
        dmaCategories,
        (dmaCategory) => dmaCategory.children,
        (item) => item.materialImpacts,
      ).flat();
      const effects = TreeUtilities.flatMapMultiple(
        dmaCategories,
        (dmaCategory) => dmaCategory.children,
        (item) => item.financialEffects,
      ).flat();

      materialImpacts.push(...impacts);
      financialEffects.push(...effects);
      irosListEntries.push(
        ...IROsListUtilities.convertImpactsToListEntries(esrsTopic.identifier, impacts),
        ...IROsListUtilities.convertEffectsToListEntries(esrsTopic.identifier, effects),
      );
    });

    return {
      irosListEntries,
      materialImpacts,
      financialEffects,
    };
  }
}
