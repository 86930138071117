export enum ErrorCode {
  // General errors
  "general.entity_not_found" = "general.entity_not_found",
  "general.internal_server_error" = "general.internal_server_error",

  // Auth errors
  "auth.unauthorized" = "auth.unauthorized",

  // Input Parameter errors
  "input_parameter.not_found" = "input_parameter.not_found",

  // Organization errors
  "organization.not_found" = "organization.not_found",

  // Recording Period
  "recording_period.not_found" = "recording_period.not_found",
  "recording_period.previous_structure_is_draft" = "recording_period.previous_structure_is_draft",
  "recording_period.value_would_move_outside_scope" = "recording_period.value_would_move_outside_scope",
  "recording_period.overlaps_with_existing_recording_period" = "recording_period.overlaps_with_existing_recording_period",

  // Recording Model errors
  "recording_model.not_found" = "recording_model.not_found",

  // Recording Model Templates Group errors
  "recording_model_templates_group.not_found" = "recording_model_templates_group.not_found",

  // Input Parameter Recording Structure Template errors
  "input_parameter_recording_structure_template.not_found" = "input_parameter_recording_structure_template.not_found",
  "input_parameter_recording_structure_template.no_organization_license" = "input_parameter_recording_structure_template.no_organization_license",

  // Input Parameter Recording Structure errors
  "input_parameter_recording_structure.not_found" = "input_parameter_recording_structure.not_found",
  "input_parameter_recording_structure.input_parameter_used_more_than_once" = "input_parameter_recording_structure.input_parameter_used_more_than_once",
  "input_parameter_recording_structure.input_parameter_not_found" = "input_parameter_recording_structure.input_parameter_not_found",
  "input_parameter_recording_structure.order_in_use" = "input_parameter_recording_structure.order_in_use",
  "input_parameter_recording_structure.of_wrong_type" = "input_parameter_recording_structure.of_wrong_type",
  "input_parameter_recording_structure.no_organization_license" = "input_parameter_recording_structure.no_organization_license",

  // Data entry object errors
  "data_entry_object.not_found" = "data_entry_object.not_found",
  "data_entry_object.next_already_set" = "data_entry_object.next_already_set",

  // Data entry object input parameter errors
  "data_entry_object_input_parameter.not_found" = "data_entry_object_input_parameter.not_found",
  "data_entry_object_input_parameter.state_transition_not_possible" = "data_entry_object_input_parameter.state_transition_not_possible",
  "data_entry_object_input_parameter.explanation_required" = "data_entry_object_input_parameter.explanation_required",
  "data_entry_object_input_parameter.current_user_is_not_responsible" = "data_entry_object_input_parameter.current_user_is_not_responsible",
  "data_entry_object_input_parameter.already_excluded" = "data_entry_object_input_parameter.already_excluded",
  "data_entry_object_input_parameter.not_yet_excluded" = "data_entry_object_input_parameter.not_yet_excluded",
  "data_entry_object_input_parameter.phase_in_only_possible_for_eligible_dr" = "data_entry_object_input_parameter.phase_in_only_possible_for_eligible_dr",

  // Organization structure errors
  "organization_structure.not_found" = "organization_structure.not_found",
  "organization_structure.not_yet_committed" = "organization_structure.not_yet_committed",
  "organization_structure.already_committed" = "organization_structure.already_committed",
  "organization_structure.duplicate_ids" = "organization_structure.duplicate_ids",
  "organization_structure.id_doesnt_belong_to_previous_structure" = "organization_structure.id_doesnt_belong_to_previous_structure",

  // Input Parameter Recording Method errors
  "input_parameter_recording_method.not_found" = "input_parameter_recording_method.not_found",

  // Data Entry Object Input Parameter Value errors
  "data_entry_object_input_parameter_value.bad_value" = "data_entry_object_input_parameter_value.bad_value",
  "data_entry_object_input_parameter_value.value_already_recorded_for_period" = "data_entry_object_input_parameter_value.value_already_recorded_for_period",
  "data_entry_object_input_parameter_value.not_found" = "data_entry_object_input_parameter_value.not_found",
  "data_entry_object_input_parameter_value.value_outside_recording_period" = "data_entry_object_input_parameter_value.value_outside_recording_period",
  "data_entry_object_input_parameter_value.does_not_support_dc" = "data_entry_object_input_parameter_value.does_not_support_dc",
  "data_entry_object_input_parameter_value.dc_is_not_applied" = "data_entry_object_input_parameter_value.dc_is_not_applied",
  "data_entry_object_input_parameter_value.invalid_date_range" = "data_entry_object_input_parameter_value.invalid_date_range",
  "data_entry_object_input_parameter_value.no_date_allowed" = "data_entry_object_input_parameter_value.no_date_allowed",
  "data_entry_object_input_parameter_value.data_quality_not_supported" = "data_entry_object_input_parameter_value.data_quality_not_supported",
  "data_entry_object_input_parameter_value.duplicate_identifying_table_values" = "data_entry_object_input_parameter_value.duplicate_identifying_table_values",

  // Dma Category errors
  "dma_category.not_found" = "dma_category.not_found",
  "dma_category.has_children" = "dma_category.has_children",
  "dma_category.has_associated_financial_effects" = "dma_category.has_associated_financial_effects",
  "dma_category.has_associated_material_impacts" = "dma_category.has_associated_material_impacts",
  "dma_category.opt_out_of_category_with_iros" = "dma_category.opt_out_of_category_with_iros",
  "dma_category.opt_out_without_reason" = "dma_category.opt_out_without_reason",
  "dma_category.opt_in_with_reason" = "dma_category.opt_in_with_reason",
  "dma_category.has_opted_out" = "dma_category.has_opted_out",
  "dma_category.no_feedback_found" = "dma_category.no_feedback_found",
  "dma_category.in_invalid_state" = "dma_category.in_invalid_state",
  "dma_category.already_verified" = "dma_category.already_verified",
  "dma_category.has_associated_feedback" = "dma_category.has_associated_feedback",
  "dma_category.cannot_be_altered" = "dma_category.cannot_be_altered",

  // Dma Effect errors
  "dma_effect.reason_was_not_provided" = "dma_effect.reason_was_not_provided",
  "dma_effect.reason_was_provided_without_override" = "dma_effect.reason_was_provided_without_override",
  "dma_effect.associated_data_entry_object_is_not_child" = "dma_effect.associated_data_entry_object_is_not_child",
  "dma_effect.can_only_be_moved_within_deo" = "dma_effect.can_only_be_moved_within_deo",

  // Financial effect errors
  "dma_financial_effect.not_found" = "dma_financial_effect.not_found",
  "dma_financial_effect.not_responsible_user" = "dma_financial_effect.not_responsible_user",
  "dma_financial_effect.incomplete_for_shared" = "dma_financial_effect.incomplete_for_shared",
  "dma_financial_effect.already_published" = "dma_financial_effect.already_published",
  "dma_financial_effect.not_shared" = "dma_financial_effect.not_shared",
  "dma_financial_effect.referenced_in_policies" = "dma_financial_effect.referenced_in_policies",
  "dma_financial_effect.referenced_in_actions" = "dma_financial_effect.referenced_in_actions",

  // Material impact errors
  "dma_material_impact.not_found" = "dma_material_impact.not_found",
  "dma_material_impact.human_rights_was_not_provided" = "dma_material_impact.human_rights_was_not_provided",
  "dma_material_impact.human_rights_was_provided_without_irreversibility" = "dma_material_impact.human_rights_was_provided_without_irreversibility",
  "dma_material_impact.not_responsible_user" = "dma_material_impact.not_responsible_user",
  "dma_material_impact.already_published" = "dma_material_impact.already_published",
  "dma_material_impact.incomplete_for_shared" = "dma_material_impact.incomplete_for_shared",
  "dma_material_impact.not_shared" = "dma_material_impact.not_shared",
  "dma_material_impact.referenced_in_policies" = "dma_material_impact.referenced_in_policies",
  "dma_material_impact.referenced_in_actions" = "dma_material_impact.referenced_in_actions",

  // ESRS Topic errors
  "esrs_topic.not_found" = "esrs_topic.not_found",
  "esrs_topic.does_not_support_custom_categories" = "esrs_topic.does_not_support_custom_categories",
  "esrs_topic.does_not_support_phase_in" = "esrs_topic.does_not_support_phase_in",

  // Recorded ESRS Topic errors
  "recorded_esrs_topic.opt_out_of_topic_with_categories" = "recorded_esrs_topic.opt_out_of_topic_with_categories",
  "recorded_esrs_topic.does_not_exist" = "recorded_esrs_topic.does_not_exist",
  "recorded_esrs_topic.category_is_not_child" = "recorded_esrs_topic.category_is_not_child",
  "recorded_esrs_topic.opt_out_without_reason" = "recorded_esrs_topic.opt_out_without_reason",
  "recorded_esrs_topic.opt_in_with_reason" = "recorded_esrs_topic.opt_in_with_reason",

  // Dma execution errors
  "dma_execution.invalid_category" = "dma_execution.invalid_category",
  "dma_execution.feedback_missing" = "dma_execution.feedback_missing",

  // DEOIP Attribution
  "data_entry_object_input_parameter.attribution_reason_not_present" = "data_entry_object_input_parameter.attribution_reason_not_present",

  // Stakeholder errors
  "stakeholder.not_found" = "stakeholder.not_found",
  "stakeholder.has_associated_feedback" = "stakeholder.has_associated_feedback",

  // Stakeholder feedback errors
  "stakeholder_feedback.not_found" = "stakeholder_feedback.not_found",
  "stakeholder_feedback.invalid" = "stakeholder_feedback.invalid",
  "stakeholder_feedback.already_submitted" = "stakeholder_feedback.already_submitted",
  "stakeholder_feedback.already_requested" = "stakeholder_feedback.already_requested",

  // Manual stakeholder feedback errors
  "manual_stakeholder_feedback.not_found" = "manual_stakeholder_feedback.not_found",

  // DMA configuration errors
  "dma_configuration.steps_not_ascending" = "dma_configuration.steps_not_ascending",
  "dma_configuration.not_found" = "dma_configuration.not_found",
  "dma_configuration.already_exists" = "dma_configuration.already_exists",

  // Distribution criterion errors
  "distribution_criterion.not_found" = "distribution_criterion.not_found",
  "distribution_criterion.already_in_use" = "distribution_criterion.already_in_use",

  // Applied distribution criterion errors
  "applied_distribution_criterion.not_found" = "applied_distribution_criterion.not_found",
  "applied_distribution_criterion.already_exists" = "applied_distribution_criterion.already_exists",
  "applied_distribution_criterion.is_incomplete" = "applied_distribution_criterion.is_incomplete",
  "applied_distribution_criterion.already_used_for_value" = "applied_distribution_criterion.already_used_for_value",
  "applied_distribution_criterion.already_applied" = "applied_distribution_criterion.already_applied",

  // Data entry object distribution criterion errors
  "data_entry_object_distribution_criterion.not_found" = "data_entry_object_distribution_criterion.not_found",

  // Organization structure errors
  "organization_structure.is_in_draft_mode" = "organization_structure.is_in_draft_mode",
  "organization_structure.is_still_ongoing" = "organization_structure.is_still_ongoing",

  // Root Data Entry Object
  "root_data_entry_object.not_found" = "root_data_entry_object.not_found",

  // Reference Budget errors
  "reference_budget.not_found" = "reference_budget.not_found",
  "reference_budget.is_used_in_target_path" = "reference_budget.is_used_in_target_path",
  "reference_budget.reference_year_not_within_recording_periods" = "reference_budget.reference_year_not_within_recording_periods",
  "reference_budget.not_all_target_paths_in_draft_state" = "reference_budget.not_all_target_paths_in_draft_state",
  "reference_budget.recording_structure_does_not_allow_emissions_evaluation" = "reference_budget.recording_structure_does_not_allow_emissions_evaluation",

  // Target Paths
  "target_path.not_found" = "target_path.not_found",
  "target_path.state_prohibits_operation" = "target_path.state_prohibits_operation",
  "target_path.does_not_meet_criteria_for_commit" = "target_path.does_not_meet_criteria_for_commit",

  // Target Path Goals
  "target_path_goal.not_found" = "target_path_goal.not_found",
  "target_path_goal.already_exists_for_year" = "target_path_goal.already_exists_for_year",
  "target_path_goal.invalid_target_year" = "target_path_goal.invalid_target_year",
  "target_path_goal.invalid_goal_type_for_referenced_budget_error" = "target_path_goal.invalid_goal_type_for_referenced_budget_error",

  // Sources
  "source.not_found" = "source.not_found",
  "source.does_not_meet_requirements" = "source.does_not_meet_requirements",
  "source.is_referenced" = "source.is_referenced",

  // Source Files
  "source_file.not_found" = "source_file.not_found",
  "source_file.invalid_state" = "source_file.invalid_state",
  "source_file.invalid_mime_type" = "source_file.invalid_mime_type",

  // S3
  "s3_file.not_found" = "s3_file.not_found",

  // Evaluation
  "evaluation.failed" = "evaluation.failed",

  // Policies
  "policy.not_found" = "policy.not_found",

  // Disclosure Requirements
  "disclosure_requirement.not_found" = "disclosure_requirement.not_found",

  // Actions
  "action.not_found" = "action.not_found",

  // Targets
  "target.not_found" = "target.not_found",

  // Comments
  "comment.not_found" = "comment.not_found",
  "comment.user_not_author" = "comment.user_not_author",

  // Users
  "user.not_found" = "user.not_found",

  // Data Entry Object DMA Configuration
  "data_entry_object_dma_configuration.dma_has_already_been_executed" = "data_entry_object_dma_configuration.dma_has_already_been_executed",
}
