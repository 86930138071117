import { Box, Button, Menu, MenuItem, SvgIcon } from "@mui/material";
import { IIROState } from "@netcero/netcero-core-api-client";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DMA_IRO_STATE_COLORS, DMA_IRO_STATE_ICONS, ORDERED_DMA_IRO_STATES } from "./dma.constants";
import { IROStateIndicator } from "./iro-state-indicator.component";

interface IIROStateSelectProps {
  value: IIROState;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (value: IIROState) => void;
}
export const IROStateSelect: FC<IIROStateSelectProps> = ({
  value,
  disabled,
  readOnly,
  onChange,
}) => {
  const { t } = useTranslation("dma_common");
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [showMenu, setShowMenu] = useState(false);

  const handleChangeValue = (newState: IIROState) => {
    onChange(newState);
    setShowMenu(false);
  };

  if (readOnly) {
    return <IROStateIndicator state={value} />;
  }

  return (
    <>
      <Menu open={showMenu} anchorEl={buttonRef.current} onClose={() => setShowMenu(false)}>
        {ORDERED_DMA_IRO_STATES.map((state) => (
          <MenuItem key={state} onClick={() => handleChangeValue(state)} sx={{ pl: 1, pr: 2 }}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <SvgIcon
                fontSize="small"
                component={DMA_IRO_STATE_ICONS[state]}
                color={DMA_IRO_STATE_COLORS[state] as "success" | "warning"}
              />
              {t(`iro_state_${state}`)}
            </Box>
          </MenuItem>
        ))}
      </Menu>

      <Button
        ref={buttonRef}
        variant="outlined"
        size="small"
        color="inherit"
        onClick={() => setShowMenu(true)}
        disabled={disabled}
        // TODO: Quick and dirty color change, maybe update later
        sx={{ pl: 0.5, borderColor: "#0000004D" }}
      >
        <IROStateIndicator state={value} />
      </Button>
    </>
  );
};
