import { ORDERED_MDR_UNITS_GHG_EMISSIONS, ORDERED_MDR_UNITS_MASS } from "./mdr-unit.constants";

export enum MdrUnitGroup {
  MASS = "mass",
  ENERGY = "energy",
  ENERGY_PER_MONETARY = "energy_per_monetary",
  GHG_EMISSIONS = "ghg_emissions",
  GHG_EMISSIONS_PER_MONETARY = "ghg_emissions_per_monetary",
  VOLUME = "volume",
  VOLUME_PER_MONETARY = "volume_per_monetary",
  AREA = "area",
  MONETARY = "monetary",
}

export type IMdrUnitMass = (typeof ORDERED_MDR_UNITS_MASS)[number];
export type IMdrUnitGhgEmissions = (typeof ORDERED_MDR_UNITS_GHG_EMISSIONS)[number];
