import { SliderProps } from "@mui/material";
import { IDMAConfiguration } from "@netcero/netcero-core-api-client";
import { DMAUtilities } from "@netcero/netcero-dma";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DMASlider } from "./dma-slider.component";
import { DMA_SLIDER_VALUES_NAMES } from "./dma.constants";
import { FormatUtilities } from "../../common/utilities/format.utilities";

function getValueNameTranslationKeyDefault(value: number) {
  const valueName = DMA_SLIDER_VALUES_NAMES[value as keyof typeof DMA_SLIDER_VALUES_NAMES];
  return valueName ? `slider_value_extent_${valueName}` : "ERROR";
}

interface IDMAPotentialExtentSliderProps extends SliderProps {
  dmaConfiguration: IDMAConfiguration;
}

/**
 * Slider for the potential extent of the financial effect (display marks as currency if configured)
 * @param dmaConfiguration The current DMA configuration
 */
export const DMAPotentialExtentSlider: FC<IDMAPotentialExtentSliderProps> = ({
  dmaConfiguration,
  ...props
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  const getValueNameTranslationKey = useCallback(
    (value: number) => {
      if (dmaConfiguration.potentialExtentSteps) {
        return FormatUtilities.formatCurrency(
          DMAUtilities.getPotentialExtentCurrencyEquivalent(
            dmaConfiguration.potentialExtentSteps,
            value,
          ),
        );
      } else {
        return t(getValueNameTranslationKeyDefault(value));
      }
    },
    [dmaConfiguration, t],
  );

  return <DMASlider valueLabelFormat={(value) => getValueNameTranslationKey(value)} {...props} />;
};
