import { ScopeKeys } from "@netcero/netcero-common";
import { useImmer } from "use-immer";
import { useCallback, useMemo } from "react";
import { IEChartLegendLabelTogglePayload } from "../../common/charts/base-chart.component";
import { useTranslation } from "react-i18next";

type ScopeLabelState = { [key in ScopeKeys]: boolean };

const useFindKeyForTranslation = () => {
  const { t } = useTranslation("target_path_diagram_component", { keyPrefix: "scopes" });

  const inverseTranslationLookup = Object.values(ScopeKeys).reduce(
    (acc, curr) => ({ ...acc, [t(curr)]: curr }),
    {} as Record<string, string>,
  );

  return useCallback(
    (translation: string) => {
      return (inverseTranslationLookup[translation] ?? null) as ScopeKeys | null;
    },
    [inverseTranslationLookup],
  );
};

export const useManageScopeLabels = () => {
  const findKeyForTranslation = useFindKeyForTranslation();
  const [state, setState] = useImmer<ScopeLabelState>({
    [ScopeKeys.Scope1]: true,
    [ScopeKeys.Scope2]: true,
    [ScopeKeys.Scope3]: true,
  });

  const setScopeValue = useCallback(
    (payload: IEChartLegendLabelTogglePayload) => {
      setState((draft) => {
        const translationKey = findKeyForTranslation(payload.seriesName);
        if (translationKey === null) {
          return;
        }
        draft[translationKey] = payload.newState;
      });
    },
    [findKeyForTranslation, setState],
  );

  const activeScopes: ScopeKeys[] = useMemo(
    () =>
      Object.entries(state)
        // Only pick active labels
        .filter(([scope, state]) => state)
        // retrieve scopes only
        .map(([scope, state]) => scope as ScopeKeys),
    [state],
  );

  return {
    setScopeValue,
    activeScopes,
  };
};
