import { IInputParameterRecordingStructureGroupTHGSC } from "@netcero/netcero-core-api-client";
import { TreeUtilities } from "../common/utilities/tree.utilities";

export interface IInputParameterRecordingStructureGroupWithInternalIds
  extends IInputParameterRecordingStructureGroupTHGSC {
  id: string;
  children: IInputParameterRecordingStructureGroupWithInternalIds[];
}

export class RecordingStructuresUtilities {
  /**
   * Hydrate structure with internal ids. Only used in ui to keep track of the structure.
   * @param tree The tree to hydrate
   * @param id The id of the current node
   * @returns The hydrated tree
   */
  public static hydrateStructureWithInternalIds(
    tree: IInputParameterRecordingStructureGroupTHGSC,
    id: string = "R",
  ): IInputParameterRecordingStructureGroupWithInternalIds {
    return {
      ...tree,
      id: id,
      children: tree.children.map((child, index) =>
        RecordingStructuresUtilities.hydrateStructureWithInternalIds(child, `${id}.${index}`),
      ),
    };
  }

  /**
   * Get path of Groups from root to specified child.
   * @param tree Root of the tree to start from
   * @param childId Id of the child
   * @returns Path of Groups from root to specified child
   */
  public static getStructureGroupsPathForToChild(
    tree: IInputParameterRecordingStructureGroupWithInternalIds,
    childId: string,
  ): IInputParameterRecordingStructureGroupWithInternalIds[] | null {
    return TreeUtilities.getPathToTreeChild(
      tree,
      (group) => group.children,
      (group) => group.id === childId,
    );
  }

  /**
   * Get the selected child in the tree.
   * @param tree The tree to look for the child in
   * @param childId The id of the child
   * @returns The selected child or null if the child is not found
   */
  public static findGroupById(
    tree: IInputParameterRecordingStructureGroupWithInternalIds,
    childId: string,
  ) {
    return TreeUtilities.getChildInTree(
      tree,
      (group) => group.children,
      (group) => group.id === childId,
    );
  }
}
