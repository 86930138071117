import { IRecursiveStructureResult, RecursiveUtilities } from "@netcero/netcero-common";
import { IInputParameterValueMetaDataNestedOptionsOption } from "@netcero/netcero-core-api-client";
import { useMemo } from "react";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface IFilteredOptionsProps {
  parents: Record<string, string>;
  searchTerm: string | null;
  items: IRecursiveStructureResult<IInputParameterValueMetaDataNestedOptionsOption>[];
}

export const useFilteredOptions = ({ parents, searchTerm, items }: IFilteredOptionsProps) => {
  const translateContent = useTranslateContent();
  return useMemo(() => {
    if (searchTerm === null) {
      return items;
    }

    // ensure conversion does not have to be done on every iteration
    const searchTermLower = searchTerm.toLowerCase();
    // collection of all values that should be included
    const matchedValues = new Set<string>();

    for (const item of items) {
      // determine current name for item + check whether it matches
      const name = translateContent(item.item.name);

      if (!name.toLowerCase().includes(searchTermLower)) {
        continue;
      }

      // add item itself + all children to the result set
      RecursiveUtilities.flattenRecursiveStructureDown(item.item).forEach((i) =>
        matchedValues.add(i.value),
      );

      const addParents = (startWith: string) => {
        const parent = parents[startWith];
        if (parent !== undefined) {
          matchedValues.add(parent);
          addParents(parent);
        }
      };

      // add parents to the result set (for proper display)
      addParents(item.item.value);
    }

    return items.filter((i) => matchedValues.has(i.item.value));
  }, [items, parents, searchTerm, translateContent]);
};
