import { useCallback, useState } from "react";
import {
  IDMACategoryWithEffectsAndChildren,
  IDMAFinancialEffect,
  IDMAFinancialEffectWithCalculatedValues,
  IDMAMaterialImpact,
  IDMAMaterialImpactWithCalculatedValues,
} from "@netcero/netcero-core-api-client";
import {
  IMoveFinancialEffectToOtherTopicData,
  IMoveMaterialImpactToOtherTopicData,
  useMoveFinancialEffectToOtherTopicMutation,
  useMoveMaterialImpactToOtherTopicMutation,
} from "../mutations/dma.mutations";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";

type DialogState<T extends IDMAFinancialEffect | IDMAMaterialImpact> = {
  open: boolean;
  dmaCategory: IDMACategoryWithEffectsAndChildren | null;
  effectOrImpact: T | null;
};

type IMoveMaterialImpactOrFinancialEffectMutation =
  | ReturnType<typeof useMoveFinancialEffectToOtherTopicMutation>
  | ReturnType<typeof useMoveMaterialImpactToOtherTopicMutation>;

export const useIroMoveDialogState = <
  T extends IDMAFinancialEffect | IDMAMaterialImpact,
  K extends IMoveMaterialImpactOrFinancialEffectMutation,
>(
  mutation: K,
  onSuccessCallback?: () => void,
) => {
  const [dialogState, setDialogState] = useState<DialogState<T>>({
    open: false,
    dmaCategory: null,
    effectOrImpact: null,
  });

  const { wrapApiPromise } = useAppSnackbar();

  const handleOpenDialog = useCallback(
    (category: IDMACategoryWithEffectsAndChildren, effectOrImpact: T) => {
      mutation.reset();
      setDialogState({
        open: true,
        dmaCategory: category,
        effectOrImpact: effectOrImpact,
      });
    },
    [mutation],
  );

  const handleCloseDialog = useCallback(
    async (
      targetDMACategoryId: string | null,
      args: Parameters<K["mutateAsync"]>[0],
      message: BasicSnackbarApiActionType,
    ) => {
      if (targetDMACategoryId) {
        await wrapApiPromise<
          IDMAFinancialEffectWithCalculatedValues | IDMAMaterialImpactWithCalculatedValues
        >(
          mutation.mutateAsync(
            args as IMoveFinancialEffectToOtherTopicData & IMoveMaterialImpactToOtherTopicData,
          ),
          { type: message },
        );

        // Execute success callback if provided
        if (onSuccessCallback) {
          onSuccessCallback();
        }
      }
      setDialogState((state) => ({ ...state, open: false }));
    },
    [mutation, onSuccessCallback, wrapApiPromise],
  );

  return {
    dialogState,
    handleOpenDialog,
    handleCloseDialog,
  };
};
