import {
  IDataEntryObjectBaseObjectTypeEnum,
  IDataEntryObjectData,
} from "@netcero/netcero-core-api-client";

export class DataEntryObjectsCommonUtilities {
  static readonly DefaultShareHeldByParent = 1;
  static readonly DoesDEOTypeSupportShare: IDataEntryObjectBaseObjectTypeEnum[] = [
    IDataEntryObjectBaseObjectTypeEnum.Organization,
    IDataEntryObjectBaseObjectTypeEnum.Grouping,
  ];

  public static doesTypeSupportShareHeldByParent(
    objectType: IDataEntryObjectBaseObjectTypeEnum,
  ): boolean {
    return DataEntryObjectsCommonUtilities.DoesDEOTypeSupportShare.includes(objectType);
  }

  /**
   * Returns true if the share held by parent deviates from the default value.
   * @param dataEntryObject The data entry object.
   */
  public static doesShareHeldByParentDeviate(dataEntryObject: IDataEntryObjectData) {
    return (
      dataEntryObject.shareHeldByParent !== DataEntryObjectsCommonUtilities.DefaultShareHeldByParent
    );
  }

  /**
   * Returns the default properties to be used for a new data entry object of the specified type.
   * @param objectType The object type.
   */
  public static getBooleanDefaultPropertiesValues(
    objectType: IDataEntryObjectBaseObjectTypeEnum,
  ): Pick<
    IDataEntryObjectData,
    "financiallyConsolidated" | "operationalControl" | "partOfValueChain"
  > {
    switch (objectType) {
      case IDataEntryObjectBaseObjectTypeEnum.Organization:
      case IDataEntryObjectBaseObjectTypeEnum.Grouping:
        return {
          financiallyConsolidated: true,
          operationalControl: true,
          partOfValueChain: undefined,
        };
      default:
        return {
          financiallyConsolidated: undefined,
          operationalControl: undefined,
          partOfValueChain: undefined,
        };
    }
  }
}
