import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  TargetPathGoalsCalculations,
  TargetPathGoalsDefaults,
  TargetPathVerification,
} from "@netcero/netcero-common";
import {
  IBaseTargetPathGoalData,
  IBaseTargetPathGoalDataWithoutValues,
  ITargetPath,
  ITargetPathGoal,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { LinearProgressWithPlaceholderComponent } from "../../common/components/linear-progress-with-placeholder.component";
import { DeleteIcon, EditIcon } from "../../common/constants/tabler-icon.constants";
import { stopPropagationAnd } from "../../common/functions/stop-propagation-and.function";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { EvaluationFormatUtilities } from "../../evaluation/utilities/evaluation-format.utilities";
import { IOrganizationIdentity } from "../../organizations/organizations.mutations";
import {
  useCreateTargetPathGoalMutation,
  useDeleteTargetPathGoalMutation,
  useUpdateTargetPathGoalMutation,
} from "../mutations/target-path-goals.mutations";
import { TargetPathGoalEditDialog } from "./target-path-goal-edit-dialog.component";
import { TargetPathGoalEditComponent } from "./target-path-goal-edit.component";
import { TargetPathGoalEmissionsChangeTableCellComponent } from "./target-path-goal-emissions-change-table-cell.component";

interface ITargetPathGoalsEditListComponentProps extends IOrganizationIdentity {
  targetPath: ITargetPath;
}

interface IDialogState {
  goal: ITargetPathGoal;
}

export const TargetPathGoalsEditListComponent: FC<ITargetPathGoalsEditListComponentProps> = ({
  targetPath,
  organizationId,
}) => {
  const { t } = useTranslation("target_path_goals_edit_list_component");

  // Mutations
  const createTargetPathGoalMutation = useCreateTargetPathGoalMutation();
  const updateTargetPathGoalMutation = useUpdateTargetPathGoalMutation();
  const updateTargetPathGoalMutationForValues = useUpdateTargetPathGoalMutation();
  const deleteTargetPathGoalMutation = useDeleteTargetPathGoalMutation();

  const resetMutations = useCallback(() => {
    createTargetPathGoalMutation.reset();
    updateTargetPathGoalMutation.reset();
    deleteTargetPathGoalMutation.reset();
  }, [createTargetPathGoalMutation, deleteTargetPathGoalMutation, updateTargetPathGoalMutation]);

  // Component state
  const [openItem, setOpenItem] = useState<string | null>(
    targetPath.goals.length > 0 ? targetPath.goals[0].id : null,
  );

  const {
    isOpen: isCreateOpen,
    openDialog: openCreate,
    closeDialog: closeCreate,
  } = useDialogStateWithoutData();

  const {
    isOpen: isEditOpen,
    openDialog: openEdit,
    closeDialog: closeEdit,
    data: editDialogData,
  } = useDialogState<IDialogState>();

  const {
    isOpen: isDeleteOpen,
    openDialog: openDelete,
    closeDialog: closeDelete,
    data: deleteDialogData,
  } = useDialogState<IDialogState>();

  // Event handler
  const handleCloseCreateDialog = useCallback(
    async (data: IBaseTargetPathGoalDataWithoutValues | null) => {
      if (data !== null) {
        const previousGoal: ITargetPathGoal | null =
          [...targetPath.goals]
            .filter((g) => g.targetYear < data.targetYear)
            .sort((a, b) => a.targetYear - b.targetYear)[0] ?? null;

        await createTargetPathGoalMutation.mutateAsync({
          organizationId,
          targetPathId: targetPath.id,
          payload: {
            targetYear: data.targetYear,
            targetValues:
              previousGoal !== null
                ? previousGoal.targetValues
                : TargetPathGoalsDefaults.getDefaultAbsoluteConfig(),
          },
        });
      }
      closeCreate();
    },
    [closeCreate, createTargetPathGoalMutation, organizationId, targetPath.goals, targetPath.id],
  );

  const handleCloseEditDialog = useCallback(
    async (data: IBaseTargetPathGoalDataWithoutValues | null) => {
      if (data !== null && editDialogData !== undefined) {
        await updateTargetPathGoalMutation.mutateAsync({
          organizationId,
          targetPathId: targetPath.id,
          targetPathGoalId: editDialogData.goal.id,
          payload: {
            targetYear: data.targetYear,
            targetValues: editDialogData.goal.targetValues,
          },
        });
      }
      closeEdit();
    },
    [closeEdit, editDialogData, organizationId, targetPath.id, updateTargetPathGoalMutation],
  );

  const handleCloseDeleteDialog = useCallback(
    async (confirm: boolean) => {
      if (confirm && deleteDialogData !== undefined) {
        await deleteTargetPathGoalMutation.mutateAsync({
          organizationId,
          targetPathId: targetPath.id,
          targetPathGoalId: deleteDialogData.goal.id,
        });
      }
      closeDelete();
    },
    [closeDelete, deleteDialogData, deleteTargetPathGoalMutation, organizationId, targetPath.id],
  );

  const handleTargetPathGoalChanged = useCallback(
    async (data: IBaseTargetPathGoalData, targetPathGoalId: string) => {
      await updateTargetPathGoalMutationForValues.mutateAsync({
        organizationId,
        targetPathId: targetPath.id,
        targetPathGoalId,
        payload: data,
      });
    },
    [organizationId, targetPath.id, updateTargetPathGoalMutationForValues],
  );

  const isLoading =
    createTargetPathGoalMutation.isPending ||
    updateTargetPathGoalMutation.isPending ||
    deleteTargetPathGoalMutation.isPending ||
    updateTargetPathGoalMutationForValues.isPending;

  return (
    <Box display="flex" flexDirection="column" gap={2} alignItems="flex-start">
      {/* Header + Create Button*/}
      <Typography variant="h5">{t("title")}</Typography>

      {/* Loading + Error for value update*/}
      <LinearProgressWithPlaceholderComponent
        shown={updateTargetPathGoalMutationForValues.isPending}
        sx={{ width: "100%" }}
      />
      {updateTargetPathGoalMutationForValues.isError && (
        <ErrorTextComponent error={updateTargetPathGoalMutationForValues.error} />
      )}

      {/* Button for creating a new configuration */}
      {TargetPathVerification.canTargetPathBeEdited(targetPath) && (
        <Button
          variant="contained"
          onClick={() => {
            resetMutations();
            openCreate();
          }}
        >
          {t("buttons.create")}
        </Button>
      )}

      {/* Create Dialog */}
      <TargetPathGoalEditDialog
        mode="create"
        targetPathGoal={null}
        open={isCreateOpen}
        onClose={handleCloseCreateDialog}
        allGoals={targetPath.goals}
        referenceBudget={targetPath.referenceBudget}
        disabled={isLoading}
        loading={isLoading}
        error={createTargetPathGoalMutation.error}
      />

      {/* Edit Dialog */}
      {editDialogData && (
        <TargetPathGoalEditDialog
          mode="edit"
          targetPathGoal={editDialogData.goal}
          open={isEditOpen}
          onClose={handleCloseEditDialog}
          allGoals={targetPath.goals}
          referenceBudget={targetPath.referenceBudget}
          disabled={isLoading}
          loading={isLoading}
          error={updateTargetPathGoalMutation.error}
        />
      )}

      {/* Delete Confirm Dialog */}
      <ConfirmDialogTextBody
        open={isDeleteOpen}
        error={deleteTargetPathGoalMutation.error}
        loading={isLoading}
        disabled={isLoading}
        onClose={handleCloseDeleteDialog}
        title={t("confirm.title")}
        content={t("confirm.body")}
      />

      {/* The actual item list*/}
      <Table size="small">
        <TableHead>
          <TableRow>
            {/* Cell for arrow */}
            <TableCell width="5%" />
            <TableCell width="30%">{t("headers.target_year")}</TableCell>
            <TableCell width="30%">{t("headers.reduction_to_reference_year")}</TableCell>
            <TableCell width="30%">{t("headers.emissions_in_target_year")}</TableCell>
            {/* Cell for actions */}
            <TableCell width="5%" />
          </TableRow>
        </TableHead>
        <TableBody>
          {targetPath.goals.map((tg) => (
            <TargetPathGoalsEditListRowComponent
              key={tg.id}
              goal={tg}
              targetPath={targetPath}
              onArrowPressed={(goalId) => {
                setOpenItem((previous) => (previous === goalId ? null : goalId));
              }}
              isOpen={tg.id === openItem}
              onEdit={(goal) => {
                openEdit({ goal });
              }}
              onDelete={(goal) => {
                openDelete({ goal });
              }}
              disabled={isLoading || !TargetPathVerification.canTargetPathBeEdited(targetPath)}
              onTargetPathGoalChange={(goal) => handleTargetPathGoalChanged(goal, tg.id)}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

interface ITargetPathGoalsEditListRowComponentProps {
  goal: ITargetPathGoal;
  targetPath: ITargetPath;
  onArrowPressed: (goalId: string) => void;
  isOpen: boolean;
  disabled?: boolean;
  onEdit: (goal: ITargetPathGoal) => void;
  onDelete: (goal: ITargetPathGoal) => void;
  onTargetPathGoalChange: (goal: IBaseTargetPathGoalData) => void;
}

const TargetPathGoalsEditListRowComponent: FC<ITargetPathGoalsEditListRowComponentProps> = ({
  goal,
  targetPath,
  onArrowPressed,
  isOpen,
  disabled,
  onEdit,
  onDelete,
  onTargetPathGoalChange,
}) => {
  const { t } = useTranslation("target_path_goals_edit_list_component");
  return (
    <>
      <TableRow
        sx={{
          "& > *": {
            // TODO: fix border
            borderBottom: "none",
          },
          "&:hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => onArrowPressed(goal.id)}
      >
        {/* Button for collapse */}
        <TableCell>
          <IconButton aria-label="expand row" size="small" disableRipple={true}>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        {/* The target year*/}
        <TableCell component="th" scope="row">
          {goal.targetYear}
        </TableCell>

        <TableCell component="th" scope="row">
          <TargetPathGoalEmissionsChangeTableCellComponent
            goal={goal}
            referenceBudget={targetPath.referenceBudget}
          />
        </TableCell>

        <TableCell component="th" scope="row">
          {EvaluationFormatUtilities.formatCO2TonValueUnicode(
            TargetPathGoalsCalculations.convertGoalToEmissions(goal, targetPath.referenceBudget)
              .sum,
          )}
        </TableCell>

        {/* Actions for the current row */}
        <TableCell align="right">
          <Box display="flex" flexDirection="row">
            {TargetPathVerification.canTargetPathBeEdited(targetPath) && (
              <>
                {/* Edit target-path goal */}
                <Tooltip title={t("action_tooltips.edit")}>
                  <span>
                    <IconButton
                      size="small"
                      onClick={stopPropagationAnd(() => onEdit(goal))}
                      disabled={disabled}
                    >
                      <EditIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                {/* Delete target path goal */}
                <Tooltip title={t("action_tooltips.delete")}>
                  <span>
                    <IconButton
                      size="small"
                      onClick={stopPropagationAnd(() => onDelete(goal))}
                      disabled={disabled}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </>
            )}
          </Box>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "& > *": {
            borderTop: "none",
          },
        }}
      >
        <TableCell colSpan={5} style={{ paddingTop: 15, paddingBottom: 15 }}>
          <Collapse in={isOpen}>
            <TargetPathGoalEditComponent
              goal={goal}
              targetPath={targetPath}
              onTargetPathGoalChange={onTargetPathGoalChange}
              disabled={disabled}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
