import { IHorizonsEnum } from "@netcero/netcero-core-api-client";

/** For internal use only - for UI Ordering `ORDERED_HORIZONS_VALUES` should be used */
export const HORIZONS_VALUES = Object.values(IHorizonsEnum);

/** For UI use - ordered in proper display order */
export const ORDERED_HORIZONS_VALUES = [
  IHorizonsEnum.ShortTerm,
  IHorizonsEnum.MediumTerm,
  IHorizonsEnum.LongTerm,
];
