import { FieldValues } from "react-hook-form";
import { IMDRBooleanInput } from "../../mdr-input.types";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { IMDRControlledInputProps } from "./mdr-input-component.factory";

export function BooleanInput<T extends FieldValues>({
  label,
  isConditional,
  field: { onChange, value, ref },
  disabled,
}: IMDRControlledInputProps<T, IMDRBooleanInput>) {
  return (
    <Box pl={isConditional ? 6 : 0}>
      <FormControlLabel
        control={<Switch checked={value} onChange={onChange} ref={ref} disabled={disabled} />}
        label={label}
      />
    </Box>
  );
}
