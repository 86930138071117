/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from "../configuration";
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "../common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "../base";
// @ts-ignore
import { IDataEntryObjectInputParameterValue } from "../models";
// @ts-ignore
import { IDataEntryObjectInputParameterValueData } from "../models";
// @ts-ignore
import { IDeleteDataEntryObjectInputParameterValue404Response } from "../models";
// @ts-ignore
import { ISubmitDataEntryObjectInputParameterValue400Response } from "../models";
// @ts-ignore
import { ISubmitDataEntryObjectInputParameterValue404Response } from "../models";
// @ts-ignore
import { ISubmitDataEntryObjectInputParameterValue409Response } from "../models";
// @ts-ignore
import { IUpdateDataEntryObjectInputParameterValue404Response } from "../models";
/**
 * DataEntryObjectInputParameterValueApi - axios parameter creator
 * @export
 */
export const DataEntryObjectInputParameterValueApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Delete an existing Value for an Input Parameter of a Data Entry Object.
     * @summary Delete an existing Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value was submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value was submitted for.
     * @param {string} valueId The id of the Value to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataEntryObjectInputParameterValue: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      valueId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectInputParameterValue",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectInputParameterValue",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectInputParameterValue",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "deleteDataEntryObjectInputParameterValue",
        "inputParameterId",
        inputParameterId,
      );
      // verify required parameter 'valueId' is not null or undefined
      assertParamExists("deleteDataEntryObjectInputParameterValue", "valueId", valueId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/values/{valueId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)))
          .replace(`{${"valueId"}}`, encodeURIComponent(String(valueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Submits a new Value for an Input Parameter of a Data Entry Object.
     * @summary Submit a new Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
     * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitDataEntryObjectInputParameterValue: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "submitDataEntryObjectInputParameterValue",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "submitDataEntryObjectInputParameterValue",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "submitDataEntryObjectInputParameterValue",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "submitDataEntryObjectInputParameterValue",
        "inputParameterId",
        inputParameterId,
      );
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/values`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDataEntryObjectInputParameterValueData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Updates an existing Value for an Input Parameter of a Data Entry Object.
     * @summary Update an existing Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
     * @param {string} valueId The id of the Value to be updated.
     * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObjectInputParameterValue: async (
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      valueId: string,
      iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterValue",
        "organizationId",
        organizationId,
      );
      // verify required parameter 'recordingPeriodId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterValue",
        "recordingPeriodId",
        recordingPeriodId,
      );
      // verify required parameter 'dataEntryObjectId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterValue",
        "dataEntryObjectId",
        dataEntryObjectId,
      );
      // verify required parameter 'inputParameterId' is not null or undefined
      assertParamExists(
        "updateDataEntryObjectInputParameterValue",
        "inputParameterId",
        inputParameterId,
      );
      // verify required parameter 'valueId' is not null or undefined
      assertParamExists("updateDataEntryObjectInputParameterValue", "valueId", valueId);
      const localVarPath =
        `/organizations/{organizationId}/recording-periods/{recordingPeriodId}/data-entry-objects/{dataEntryObjectId}/input-parameters/{inputParameterId}/values/{valueId}`
          .replace(`{${"organizationId"}}`, encodeURIComponent(String(organizationId)))
          .replace(`{${"recordingPeriodId"}}`, encodeURIComponent(String(recordingPeriodId)))
          .replace(`{${"dataEntryObjectId"}}`, encodeURIComponent(String(dataEntryObjectId)))
          .replace(`{${"inputParameterId"}}`, encodeURIComponent(String(inputParameterId)))
          .replace(`{${"valueId"}}`, encodeURIComponent(String(valueId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication openId required

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iDataEntryObjectInputParameterValueData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataEntryObjectInputParameterValueApi - functional programming interface
 * @export
 */
export const DataEntryObjectInputParameterValueApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    DataEntryObjectInputParameterValueApiAxiosParamCreator(configuration);
  return {
    /**
     * Delete an existing Value for an Input Parameter of a Data Entry Object.
     * @summary Delete an existing Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value was submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value was submitted for.
     * @param {string} valueId The id of the Value to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDataEntryObjectInputParameterValue(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      valueId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.deleteDataEntryObjectInputParameterValue(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          valueId,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Submits a new Value for an Input Parameter of a Data Entry Object.
     * @summary Submit a new Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
     * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async submitDataEntryObjectInputParameterValue(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IDataEntryObjectInputParameterValue>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.submitDataEntryObjectInputParameterValue(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iDataEntryObjectInputParameterValueData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Updates an existing Value for an Input Parameter of a Data Entry Object.
     * @summary Update an existing Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
     * @param {string} valueId The id of the Value to be updated.
     * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDataEntryObjectInputParameterValue(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      valueId: string,
      iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IDataEntryObjectInputParameterValue>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateDataEntryObjectInputParameterValue(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          valueId,
          iDataEntryObjectInputParameterValueData,
          options,
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DataEntryObjectInputParameterValueApi - factory interface
 * @export
 */
export const DataEntryObjectInputParameterValueApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DataEntryObjectInputParameterValueApiFp(configuration);
  return {
    /**
     * Delete an existing Value for an Input Parameter of a Data Entry Object.
     * @summary Delete an existing Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value was submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value was submitted for.
     * @param {string} valueId The id of the Value to be deleted.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDataEntryObjectInputParameterValue(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      valueId: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteDataEntryObjectInputParameterValue(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          valueId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Submits a new Value for an Input Parameter of a Data Entry Object.
     * @summary Submit a new Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
     * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    submitDataEntryObjectInputParameterValue(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
      options?: any,
    ): AxiosPromise<IDataEntryObjectInputParameterValue> {
      return localVarFp
        .submitDataEntryObjectInputParameterValue(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          iDataEntryObjectInputParameterValueData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates an existing Value for an Input Parameter of a Data Entry Object.
     * @summary Update an existing Value for an Input Parameter of a Data Entry Object.
     * @param {string} organizationId The id of the Organization the data entry object belongs to.
     * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
     * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
     * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
     * @param {string} valueId The id of the Value to be updated.
     * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDataEntryObjectInputParameterValue(
      organizationId: string,
      recordingPeriodId: string,
      dataEntryObjectId: string,
      inputParameterId: string,
      valueId: string,
      iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
      options?: any,
    ): AxiosPromise<IDataEntryObjectInputParameterValue> {
      return localVarFp
        .updateDataEntryObjectInputParameterValue(
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          inputParameterId,
          valueId,
          iDataEntryObjectInputParameterValueData,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DataEntryObjectInputParameterValueApi - interface
 * @export
 * @interface DataEntryObjectInputParameterValueApi
 */
export interface DataEntryObjectInputParameterValueApiInterface {
  /**
   * Delete an existing Value for an Input Parameter of a Data Entry Object.
   * @summary Delete an existing Value for an Input Parameter of a Data Entry Object.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the value was submitted for.
   * @param {string} inputParameterId The id of the Input Parameter the value was submitted for.
   * @param {string} valueId The id of the Value to be deleted.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterValueApiInterface
   */
  deleteDataEntryObjectInputParameterValue(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    valueId: string,
    options?: AxiosRequestConfig,
  ): AxiosPromise<void>;

  /**
   * Submits a new Value for an Input Parameter of a Data Entry Object.
   * @summary Submit a new Value for an Input Parameter of a Data Entry Object.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
   * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
   * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterValueApiInterface
   */
  submitDataEntryObjectInputParameterValue(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDataEntryObjectInputParameterValue>;

  /**
   * Updates an existing Value for an Input Parameter of a Data Entry Object.
   * @summary Update an existing Value for an Input Parameter of a Data Entry Object.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
   * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
   * @param {string} valueId The id of the Value to be updated.
   * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterValueApiInterface
   */
  updateDataEntryObjectInputParameterValue(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    valueId: string,
    iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
    options?: AxiosRequestConfig,
  ): AxiosPromise<IDataEntryObjectInputParameterValue>;
}

/**
 * DataEntryObjectInputParameterValueApi - object-oriented interface
 * @export
 * @class DataEntryObjectInputParameterValueApi
 * @extends {BaseAPI}
 */
export class DataEntryObjectInputParameterValueApi
  extends BaseAPI
  implements DataEntryObjectInputParameterValueApiInterface
{
  /**
   * Delete an existing Value for an Input Parameter of a Data Entry Object.
   * @summary Delete an existing Value for an Input Parameter of a Data Entry Object.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the value was submitted for.
   * @param {string} inputParameterId The id of the Input Parameter the value was submitted for.
   * @param {string} valueId The id of the Value to be deleted.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterValueApi
   */
  public deleteDataEntryObjectInputParameterValue(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    valueId: string,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterValueApiFp(this.configuration)
      .deleteDataEntryObjectInputParameterValue(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        valueId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Submits a new Value for an Input Parameter of a Data Entry Object.
   * @summary Submit a new Value for an Input Parameter of a Data Entry Object.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
   * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
   * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterValueApi
   */
  public submitDataEntryObjectInputParameterValue(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterValueApiFp(this.configuration)
      .submitDataEntryObjectInputParameterValue(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        iDataEntryObjectInputParameterValueData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates an existing Value for an Input Parameter of a Data Entry Object.
   * @summary Update an existing Value for an Input Parameter of a Data Entry Object.
   * @param {string} organizationId The id of the Organization the data entry object belongs to.
   * @param {string} recordingPeriodId The id of the recording period the data entry object belongs to.
   * @param {string} dataEntryObjectId The id of the data entry object the value is being submitted for.
   * @param {string} inputParameterId The id of the Input Parameter the value is being submitted for.
   * @param {string} valueId The id of the Value to be updated.
   * @param {IDataEntryObjectInputParameterValueData} [iDataEntryObjectInputParameterValueData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataEntryObjectInputParameterValueApi
   */
  public updateDataEntryObjectInputParameterValue(
    organizationId: string,
    recordingPeriodId: string,
    dataEntryObjectId: string,
    inputParameterId: string,
    valueId: string,
    iDataEntryObjectInputParameterValueData?: IDataEntryObjectInputParameterValueData,
    options?: AxiosRequestConfig,
  ) {
    return DataEntryObjectInputParameterValueApiFp(this.configuration)
      .updateDataEntryObjectInputParameterValue(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        inputParameterId,
        valueId,
        iDataEntryObjectInputParameterValueData,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
