import {
  IGetAllRecordingPeriodsResponse,
  IRecordingPeriod,
} from "@netcero/netcero-core-api-client";
import { DateTime } from "luxon";

export interface ILocalGetAllRecordingPeriodsResponse
  extends Omit<IGetAllRecordingPeriodsResponse, "recordingPeriods"> {
  recordingPeriods: ILocalRecordingPeriod[];
}

export interface ILocalRecordingPeriod extends Omit<IRecordingPeriod, "startDate" | "endDate"> {
  startDate: Date;
  endDate: Date;
}

export class RecordingPeriodsUtilities {
  /**
   * Converts the API response to a local response.
   * @param response The original API response.
   */
  public static convertAllRecordingPeriodsResponseToLocal(
    response: IGetAllRecordingPeriodsResponse,
  ): ILocalGetAllRecordingPeriodsResponse {
    return {
      ...response,
      recordingPeriods: response.recordingPeriods.map((recordingPeriod) =>
        RecordingPeriodsUtilities.convertApiToLocalRecordingPeriod(recordingPeriod),
      ),
    };
  }

  /**
   * Converts an API recording period to a local recording period.
   * @param recordingPeriod The API recording period to convert to a local recording period.
   */
  public static convertApiToLocalRecordingPeriod(
    recordingPeriod: IRecordingPeriod,
  ): ILocalRecordingPeriod {
    return {
      ...recordingPeriod,
      startDate: new Date(recordingPeriod.startDate),
      endDate: new Date(recordingPeriod.endDate),
    };
  }

  /**
   * Get the default recording period boundaries for a given business year.
   * @param endOfPreviousRecordingPeriod End of the previous recording period. Will be used as startDate if it is after the generated start date.
   * @returns The start and end date of the recording period.
   */
  public static getDefaultRecordingPeriodBoundaries(endOfPreviousRecordingPeriod?: Date) {
    // Get the current date and time
    const now = DateTime.local();

    // Initialize startDate and endDate to the business year boundaries
    let startDate = DateTime.local(now.year, 1, 1).startOf("day");
    let endDate = DateTime.local(now.year, 12, 31).endOf("day");

    // If endOfPreviousRecordingPeriod is defined, check if it is after the generated startDate
    if (endOfPreviousRecordingPeriod) {
      const endOfPreviousRecordingPeriodDateTime = DateTime.fromJSDate(
        endOfPreviousRecordingPeriod,
      );

      // Set the startDate to the end of the previous recording period plus one day and the endDate to one year after
      startDate = endOfPreviousRecordingPeriodDateTime.plus({ day: 1 }).startOf("day");
      endDate = endOfPreviousRecordingPeriodDateTime.plus({ year: 1 }).endOf("day");
    }

    // Return the calculated startDate and endDate as JavaScript Date objects
    return {
      startDate: startDate.toJSDate(),
      endDate: endDate.toJSDate(),
    };
  }
}
