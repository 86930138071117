import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { LookUp } from "@netcero/netcero-common";
import { useMemo } from "react";

export const useBuildUserLookup = (users: IUserRepresentation[]): LookUp<IUserRepresentation> => {
  return useMemo(
    () =>
      users.reduce((acc, curr) => {
        if (curr.id !== undefined) {
          acc[curr.id] = curr;
        }
        return acc;
      }, {} as LookUp<IUserRepresentation>),
    [users],
  );
};
