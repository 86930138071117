import {
  Box,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TreeGroupContainer } from "../../common/components/tree-group-container.component";
import {
  CategoryMinusIcon,
  CategoryPlusIcon,
  ChevronDownIcon,
  DotsIcon,
  IconSize,
  SquareMinusIcon,
  SquarePlusIcon,
} from "../../common/constants/tabler-icon.constants";
import { DataEntryObjectInputParameterConfigurationComponent } from "./data-entry-object-input-parameter-configuration.component";
import {
  DataEntryObjectInputParametersUtilities,
  IDataEntryObjectInputParameterModelRecordingGroup,
  IDataEntryObjectRecordingInputParameter,
} from "../data-entry-object-input-parameters.utilities";
import {
  useTranslateContent,
  useTranslateOptionalContent,
} from "../../content-translation/hooks/translate-content.hook";

interface IDataEntryObjectInputParametersSelectGroupComponent {
  inputParametersGroup: IDataEntryObjectInputParameterModelRecordingGroup;
  onChange: (
    newInputParameterConfiguration:
      | IDataEntryObjectRecordingInputParameter[]
      | IDataEntryObjectRecordingInputParameter,
  ) => void;
  disabled: boolean;
  prefix?: string;
  isRootStructure?: boolean;
}
export const DataEntryObjectInputParametersSelectGroupComponent: FC<
  IDataEntryObjectInputParametersSelectGroupComponent
> = ({ inputParametersGroup, onChange, disabled, prefix = "", isRootStructure }) => {
  const { t } = useTranslation("data_entry_object_input_parameters_select_group_component");
  const translateContent = useTranslateContent();
  const translateOptionalContent = useTranslateOptionalContent();

  const { groupInfo, inputParameterConfigurations, children } = inputParametersGroup;

  const menuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const [expanded, setExpanded] = useState(isRootStructure || false);
  const handleClickCategory = () => setExpanded((curr) => !curr);

  const handleCloseMenu = () => setMenuOpen(false);

  const updateConfigurations = (
    configurations: IDataEntryObjectRecordingInputParameter[],
    newActiveState: boolean,
  ) => {
    const updates: IDataEntryObjectRecordingInputParameter[] = configurations
      .filter((configuration) => configuration.isActive !== newActiveState)
      .map((configuration) => ({
        ...configuration,
        isActive: newActiveState,
      }));
    onChange(updates);
  };

  const handleSelectAll = () => {
    updateConfigurations(inputParametersGroup.inputParameterConfigurations, true);
    handleCloseMenu();
  };

  const handleDeselectAll = () => {
    updateConfigurations(inputParametersGroup.inputParameterConfigurations, false);
    handleCloseMenu();
  };

  const handleSelectAllCategory = () => {
    updateConfigurations(
      DataEntryObjectInputParametersUtilities.collectAllInputParameterConfigurationsOfGroup(
        inputParametersGroup,
      ),
      true,
    );
    handleCloseMenu();
  };

  const handleDeselectAllCategory = () => {
    updateConfigurations(
      DataEntryObjectInputParametersUtilities.collectAllInputParameterConfigurationsOfGroup(
        inputParametersGroup,
      ),
      false,
    );
    handleCloseMenu();
  };

  return (
    <>
      {/* Menu Definition */}
      <Menu anchorEl={menuRef.current} open={menuOpen} onClose={handleCloseMenu}>
        <MenuItem onClick={handleSelectAll}>
          <ListItemIcon>
            <SquarePlusIcon size={IconSize.Small} />
          </ListItemIcon>
          <ListItemText>{t("button_select_all")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeselectAll}>
          <ListItemIcon>
            <SquareMinusIcon size={IconSize.Small} />
          </ListItemIcon>
          <ListItemText>{t("button_deselect_all")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSelectAllCategory}>
          <ListItemIcon>
            <CategoryPlusIcon size={IconSize.Small} />
          </ListItemIcon>
          <ListItemText>{t("button_category_select_all")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeselectAllCategory}>
          <ListItemIcon>
            <CategoryMinusIcon size={IconSize.Small} />
          </ListItemIcon>
          <ListItemText>{t("button_category_deselect_all")}</ListItemText>
        </MenuItem>
      </Menu>

      {/* Box with actual content */}
      <Box>
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
          <Typography variant="h5" component="span" onClick={handleClickCategory} flex={1} pt={2}>
            {prefix} {translateContent(groupInfo.name)}
          </Typography>
          <Tooltip title={t("button_settings")}>
            <span>
              <IconButton
                ref={menuRef}
                size="medium"
                onClick={() => setMenuOpen(true)}
                disabled={disabled}
              >
                <DotsIcon />
              </IconButton>
            </span>
          </Tooltip>
          <IconButton
            size="medium"
            sx={{ rotate: expanded ? "0" : "180deg", transition: "0.3s ease" }}
            onClick={handleClickCategory}
          >
            <ChevronDownIcon />
          </IconButton>
        </Box>
        <Divider />

        <Collapse in={expanded}>
          <TreeGroupContainer py={2}>
            {translateOptionalContent(groupInfo.description) && (
              <Typography>{translateOptionalContent(groupInfo.description)}</Typography>
            )}

            {inputParameterConfigurations.length > 0 && (
              <Box>
                {inputParameterConfigurations.map((inputParameterConfiguration) => (
                  <DataEntryObjectInputParameterConfigurationComponent
                    key={inputParameterConfiguration.inputParameter.id}
                    inputParameterConfiguration={inputParameterConfiguration}
                    onChange={(data) => onChange(data)}
                    disabled={disabled}
                  />
                ))}
              </Box>
            )}

            {children.length > 0 && (
              <Box my={2} pl={1}>
                {children.map((childGroup, index) => (
                  <DataEntryObjectInputParametersSelectGroupComponent
                    key={index} // Index is fine here, since order will not change
                    inputParametersGroup={childGroup}
                    onChange={onChange}
                    disabled={disabled}
                    prefix={prefix && `${prefix ? `${prefix}.` : ""}${index + 1}`}
                  />
                ))}
              </Box>
            )}
          </TreeGroupContainer>
        </Collapse>
      </Box>
    </>
  );
};
