import { IDataEntryObjectDMAConfiguration } from "@netcero/netcero-core-api-client";

export class DataEntryObjectDmaConfigurationUtilities {
  /**
   * Determines whether the DMA of the given config has already been completed.
   * @param config The config to examine
   */
  public static hasDMABeenCompleted(config: IDataEntryObjectDMAConfiguration) {
    return config.completedAt !== undefined;
  }
}
