import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IRecordingModelsResponse } from "@netcero/netcero-core-api-client";

export function useRecordingModelsQuery<T = IRecordingModelsResponse>(
  organizationId: string,
  select?: (data: IRecordingModelsResponse) => T,
) {
  return useQuery({
    queryKey: ["recordingModels", organizationId],
    queryFn: () =>
      CoreApiService.RecordingModelApi.getAllRecordingModels(organizationId)
        .then((req) => req())
        .then((res) => res.data),
    select,
  });
}
