import { CommonLogger, ICommonLogger } from "@netcero/logger";
import { KeycloakUserApiClient } from "../api";
import { AVAILABLE_LANGUAGE_KEY } from "@netcero/netcero-common";

export async function updateLocale(
  keycloakUserApiClient: KeycloakUserApiClient,
  locale: AVAILABLE_LANGUAGE_KEY,
  logger: ICommonLogger = CommonLogger,
): Promise<void> {
  try {
    // Fetch latest profile to avoid resetting other changed values
    // (there is no patch or just locale update)
    const currentProfile = await keycloakUserApiClient.readAccount();
    // Skip if locale is already set
    if (currentProfile.attributes?.locale?.includes(locale)) {
      return;
    }

    const updatedProfile = { ...currentProfile, attributes: { ...currentProfile.attributes } };
    updatedProfile.attributes.locale = [locale];
    // Await is necessary so error handling works here!
    await keycloakUserApiClient.updateAccount(updatedProfile);
  } catch (error) {
    logger.error(`Failed to update user locale to "${locale}":`, error);
    throw error;
  }
}
