import {
  IAction,
  IActionData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { DataEntryObjectInputParameterValueDefinitionForAction } from "@netcero/netcero-common";
import {
  useCreateActionMutation,
  useDeleteActionMutation,
  useUpdateActionMutation,
} from "../actions.mutations";
import { ActionEditDialog } from "./action-edit.dialog";
import { ConfirmDialog } from "../../common/dialogs/variants/confirm.dialog";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import {
  IMdrEntityData,
  MdrEntityTable,
} from "../../minimum-disclosure-requirements-common/components/mdr-entity-table.component";

interface IActionsTableProps {
  actions: IAction[];
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForAction) => void;
  disabled?: boolean;
}

export const ActionsTable: FC<IActionsTableProps> = ({
  actions,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_action_component");

  const actionsEntitiesData: IMdrEntityData<IAction>[] = useMemo(
    () =>
      actions.map((action) => ({
        id: action.id,
        title: action.general.name,
        responsibleUserId: action.responsibleUserId,
        data: action,
      })),
    [actions],
  );

  const createActionMutation = useCreateActionMutation();
  const updateActionMutation = useUpdateActionMutation();
  const deleteActionMutation = useDeleteActionMutation();

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();
  useResetMutationsOnOpen(isCreateOpen, createActionMutation);

  const {
    isOpen: isEditOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: actionData,
  } = useDialogState<IAction>();
  useResetMutationsOnOpen(isEditOpen, updateActionMutation);

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialogDialog,
    closeDialog: closeDeleteDialog,
    data: deleteActionId,
  } = useDialogState<string>();
  useResetMutationsOnOpen(isDeleteDialogOpen, deleteActionMutation);

  const handleCreate = useCallback(
    async (payload: IActionData | null) => {
      if (payload !== null) {
        await createActionMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          payload,
        });
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createActionMutation, dataEntryObjectId, organizationId, recordingPeriodId],
  );

  const handleEdit = useCallback(
    async (payload: IActionData | null) => {
      if (payload !== null) {
        await updateActionMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          actionId: actionData?.id ?? "",
          payload,
        });
      }
      closeEditDialog();
    },
    [
      actionData?.id,
      closeEditDialog,
      dataEntryObjectId,
      updateActionMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  const handleDelete = useCallback(
    async (actionId: string | null, confirm: boolean | undefined) => {
      if (!actionId || !confirm) {
        closeDeleteDialog();
        return;
      }
      await deleteActionMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        actionId,
      });
      closeDeleteDialog();
      closeEditDialog();
    },
    [
      closeDeleteDialog,
      closeEditDialog,
      dataEntryObjectId,
      deleteActionMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  return (
    <>
      {/* Dialogs */}
      <ActionEditDialog
        open={isCreateOpen}
        loading={createActionMutation.isPending}
        onClose={handleCreate}
        error={createActionMutation.error}
        disabled={createActionMutation.isPending}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
      />
      <ActionEditDialog
        open={isEditOpen}
        loading={updateActionMutation.isPending}
        onClose={handleEdit}
        onDelete={openDeleteDialogDialog}
        error={updateActionMutation.error}
        disabled={updateActionMutation.isPending}
        action={actionData}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
      />
      <ConfirmDialog
        open={isDeleteDialogOpen}
        error={deleteActionMutation.error}
        loading={deleteActionMutation.isPending}
        disabled={deleteActionMutation.isPending}
        onClose={(confirm: boolean) => handleDelete(deleteActionId!, confirm)}
        title={t("confirm_dialog.title")}
        content={t("confirm_dialog.body")}
        confirmOverride={t("confirm_dialog.button_confirm")}
      />

      {/* Content */}
      <MdrEntityTable
        organizationId={organizationId}
        entities={actionsEntitiesData}
        onEdit={(entity) => openEditDialog(entity.data)}
        noEntitiesPresentNotice={t("no_actions_defined")}
        buttonChooseNoEntitiesForTopicText={t("button_choose_no_actions_for_topic")}
        onChooseNoEntitiesForTopic={() => onChange(false)}
        tooltipAddNew={t("tooltip_add_action")}
        onAddNew={openCreateDialog}
        disabled={disabled}
      />
    </>
  );
};
