import { FormatUtilities } from "../../common/utilities/format.utilities";

export class EvaluationFormatUtilities {
  public static CO2TonValueUnit = "t CO\u2082eq";

  public static formatCO2TonValueOnly(value: number) {
    return FormatUtilities.formatFixedDecimal(value, 0);
  }

  public static formatCO2TonValue(value: number) {
    return (
      <>
        {EvaluationFormatUtilities.formatCO2TonValueOnly(value)} t CO<sub>2</sub>eq
      </>
    );
  }

  public static formatCO2TonValueUnicode(value: number) {
    return ` ${EvaluationFormatUtilities.formatCO2TonValueOnly(value)} ${
      EvaluationFormatUtilities.CO2TonValueUnit
    }`;
  }
}
