import { Box, TextField } from "@mui/material";
import {
  IBaseDistributionCriterionData,
  IDistributionCriterion,
} from "@netcero/netcero-core-api-client";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { EditDialogWrapper } from "../common/dialogs/variants/edit-dialog.wrapper";

const getFormDefaultValues = (
  distributionCriteria?: IDistributionCriterion | null,
): IBaseDistributionCriterionData => {
  return {
    name: distributionCriteria?.name ?? "",
  };
};

interface IDistributionCriterionEditDialogProps {
  open: boolean;
  distributionCriterion?: IDistributionCriterion | null;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IBaseDistributionCriterionData | null) => void;
}

export const DistributionCriterionEditDialog: FC<IDistributionCriterionEditDialogProps> = ({
  open,
  distributionCriterion,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("distribution_criteria_edit_dialog");

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = useForm<IBaseDistributionCriterionData>({
    defaultValues: getFormDefaultValues(distributionCriterion),
  });

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(distributionCriterion));
    }
  }, [open, distributionCriterion, reset]);

  const handleEmitData = (data: IBaseDistributionCriterionData) => {
    onClose({
      name: data.name.trim(),
    });
  };

  return (
    <EditDialogWrapper
      open={open}
      title={t(distributionCriterion ? "title_edit" : "title_create")}
      mode={distributionCriterion ? "edit" : "create"}
      loading={loading}
      hasChanges={isDirty}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      disabled={disabled}
      dialogProps={{ fullWidth: true }}
    >
      <Box display="flex" flexDirection="column" gap={2} mt={1}>
        {/* Name Input */}
        <Controller
          control={control}
          name="name"
          rules={{ required: t("error_name_required") }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={t("label_name")}
              {...field}
              error={!!error}
              helperText={error?.message}
              disabled={disabled}
            />
          )}
        />
      </Box>
    </EditDialogWrapper>
  );
};
