import { FC, useMemo } from "react";
import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IContentLanguageSelectComponentProps {
  value: IContentLanguage | null;
  onValueChange: (value: IContentLanguage | null) => void;
  required: boolean;
  fullWidth?: boolean;
  minWidth?: number;
  label?: string;
}

export const ContentLanguageSelectComponent: FC<IContentLanguageSelectComponentProps> = ({
  value,
  onValueChange,
  label,
  required,
  fullWidth,
  minWidth,
}) => {
  const { t } = useTranslation("language");

  const currentLabel = useMemo(() => label ?? t("label"), [label, t]);

  return (
    <FormControl fullWidth={fullWidth} sx={{ minWidth }}>
      <InputLabel>{currentLabel}</InputLabel>
      <Select
        value={value ?? ""}
        label={currentLabel}
        onChange={(event) => {
          const value = event.target.value as string;
          // convert "" to null
          onValueChange((value || null) as IContentLanguage | null);
        }}
      >
        {/* Not required --> render empty option */}
        {!required && (
          <MenuItem value="">
            <em>{t("name_empty_option", { ns: "options_input_component" })}</em>
          </MenuItem>
        )}

        {/* Render option for every defined language */}
        {Object.values(IContentLanguage).map((l) => (
          <MenuItem key={l} value={l}>
            {t(`translationsPerLanguage.${l}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
