import { UseMutationResult } from "@tanstack/react-query";
import { useEffect } from "react";

export const useResetMutationsOnOpen = (
  open: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...mutations: UseMutationResult<any, any, any, any>[]
) => {
  useEffect(() => {
    if (open) {
      mutations.forEach((mutation) => {
        mutation.reset();
      });
    }
    // No need to watch mutations array for changes as we're only using the reset method and
    // not any data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
};
