import { Icon } from "@iconify/react";
import { Box, CardContent, Link, Typography } from "@mui/material";
import React, { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { StyledCardWithOnHoverShadow } from "../common/components/styled-card.component";

export const GenericRecordingStructureCard: FC<{
  id: string;
  title: string;
  subtitle?: string;
  iconName: string;
  url: string;
  additionalContent?: React.ReactNode;
}> = ({ id, title, subtitle, iconName, url, additionalContent }) => {
  return (
    // TODO: Migrate to tabler icons at some point
    <StyledCardWithOnHoverShadow id={id} sx={{ height: "100%" }}>
      <CardContent sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Link
          component={RouterLink}
          to={url}
          underline="none"
          color="inherit"
          sx={{ height: "100%" }}
        >
          <Box display="flex" alignItems="center" gap={2} height="100%">
            <Box flex={1} alignSelf="start">
              <Typography>
                <Icon icon={iconName} height={32} />
              </Typography>
              <Typography variant="h3">{title}</Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {subtitle}
              </Typography>
            </Box>
            <Box>
              <Icon icon="ci:caret-right-sm" height="3rem" />
            </Box>
          </Box>
        </Link>
        {additionalContent}
      </CardContent>
    </StyledCardWithOnHoverShadow>
  );
};
