import { useParams } from "react-router-dom";
import { useRecordingPeriodsQuery } from "./recording-periods.queries";
import { useMemo } from "react";

export const useCurrentRecordingPeriod = () => {
  const { organizationId, recordingPeriodId } = useParams();

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const currentRecordingPeriod = useMemo(() => {
    if (recordingPeriodsQuery.isSuccess && recordingPeriodsQuery.data) {
      return (
        recordingPeriodsQuery.data.recordingPeriods.find(
          (recordingPeriod) => recordingPeriod.id === recordingPeriodId,
        ) ?? null
      );
    } else {
      return null;
    }
  }, [recordingPeriodId, recordingPeriodsQuery]);

  return currentRecordingPeriod;
};
