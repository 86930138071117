export enum CountryCodeAlpha2Enum {
  //
  // Officially assigned code elements
  // total: 249
  //

  /** Andorra */
  AD = "AD",

  /** United Arab Emirates */
  AE = "AE",

  /** Afghanistan */
  AF = "AF",

  /** Antigua and Barbuda */
  AG = "AG",

  /** Anguilla */
  AI = "AI",

  /** Albania */
  AL = "AL",

  /** Armenia */
  AM = "AM",

  /** Angola */
  AO = "AO",

  /** Antarctica */
  AQ = "AQ",

  /** Argentina */
  AR = "AR",

  /** American Samoa */
  AS = "AS",

  /** Austria */
  AT = "AT",

  /** Australia */
  AU = "AU",

  /** Aruba */
  AW = "AW",

  /** Åland Islands */
  AX = "AX",

  /** Azerbaijan */
  AZ = "AZ",

  /** Bosnia and Herzegovina */
  BA = "BA",

  /** Barbados */
  BB = "BB",

  /** Bangladesh */
  BD = "BD",

  /** Belgium */
  BE = "BE",

  /** Burkina Faso */
  BF = "BF",

  /** Bulgaria */
  BG = "BG",

  /** Bahrain */
  BH = "BH",

  /** Burundi */
  BI = "BI",

  /** Benin */
  BJ = "BJ",

  /** Saint Barthélemy */
  BL = "BL",

  /** Bermuda */
  BM = "BM",

  /** Brunei Darussalam */
  BN = "BN",

  /** Bolivia (Plurinational State of) */
  BO = "BO",

  /** Bonaire, Sint Eustatius and Saba */
  BQ = "BQ",

  /** Brazil */
  BR = "BR",

  /** Bahamas */
  BS = "BS",

  /** Bhutan */
  BT = "BT",

  /** Bouvet Island */
  BV = "BV",

  /** Botswana */
  BW = "BW",

  /** Belarus */
  BY = "BY",

  /** Belize */
  BZ = "BZ",

  /** Canada */
  CA = "CA",

  /** Cocos (Keeling) Islands */
  CC = "CC",

  /** Congo, Democratic Republic of the */
  CD = "CD",

  /** Central African Republic */
  CF = "CF",

  /** Congo */
  CG = "CG",

  /** Switzerland */
  CH = "CH",

  /** Côte d'Ivoire */
  CI = "CI",

  /** Cook Islands */
  CK = "CK",

  /** Chile */
  CL = "CL",

  /** Cameroon */
  CM = "CM",

  /** China */
  CN = "CN",

  /** Colombia */
  CO = "CO",

  /** Costa Rica */
  CR = "CR",

  /** Cuba */
  CU = "CU",

  /** Cabo Verde */
  CV = "CV",

  /** Curaçao */
  CW = "CW",

  /** Christmas Island */
  CX = "CX",

  /** Cyprus */
  CY = "CY",

  /** Czechia */
  CZ = "CZ",

  /** Germany */
  DE = "DE",

  /** Djibouti */
  DJ = "DJ",

  /** Denmark */
  DK = "DK",

  /** Dominica */
  DM = "DM",

  /** Dominican Republic */
  DO = "DO",

  /** Algeria */
  DZ = "DZ",

  /** Ecuador */
  EC = "EC",

  /** Estonia */
  EE = "EE",

  /** Egypt */
  EG = "EG",

  /** Western Sahara */
  EH = "EH",

  /** Eritrea */
  ER = "ER",

  /** Spain */
  ES = "ES",

  /** Ethiopia */
  ET = "ET",

  /** Finland */
  FI = "FI",

  /** Fiji */
  FJ = "FJ",

  /** Falkland Islands (Malvinas) */
  FK = "FK",

  /** Micronesia (Federated States of) */
  FM = "FM",

  /** Faroe Islands */
  FO = "FO",

  /** France */
  FR = "FR",

  /** Gabon */
  GA = "GA",

  /** United Kingdom of Great Britain and Northern Ireland */
  GB = "GB",

  /** Grenada */
  GD = "GD",

  /** Georgia */
  GE = "GE",

  /** French Guiana */
  GF = "GF",

  /** Guernsey */
  GG = "GG",

  /** Ghana */
  GH = "GH",

  /** Gibraltar */
  GI = "GI",

  /** Greenland */
  GL = "GL",

  /** Gambia */
  GM = "GM",

  /** Guinea */
  GN = "GN",

  /** Guadeloupe */
  GP = "GP",

  /** Equatorial Guinea */
  GQ = "GQ",

  /** Greece */
  GR = "GR",

  /** South Georgia and the South Sandwich Islands */
  GS = "GS",

  /** Guatemala */
  GT = "GT",

  /** Guam */
  GU = "GU",

  /** Guinea-Bissau */
  GW = "GW",

  /** Guyana */
  GY = "GY",

  /** Hong Kong */
  HK = "HK",

  /** Heard Island and McDonald Islands */
  HM = "HM",

  /** Honduras */
  HN = "HN",

  /** Croatia */
  HR = "HR",

  /** Haiti */
  HT = "HT",

  /** Hungary */
  HU = "HU",

  /** Indonesia */
  ID = "ID",

  /** Ireland */
  IE = "IE",

  /** Israel */
  IL = "IL",

  /** Isle of Man */
  IM = "IM",

  /** India */
  IN = "IN",

  /** British Indian Ocean Territory */
  IO = "IO",

  /** Iraq */
  IQ = "IQ",

  /** Iran (Islamic Republic of) */
  IR = "IR",

  /** Iceland */
  IS = "IS",

  /** Italy */
  IT = "IT",

  /** Jersey */
  JE = "JE",

  /** Jamaica */
  JM = "JM",

  /** Jordan */
  JO = "JO",

  /** Japan */
  JP = "JP",

  /** Kenya */
  KE = "KE",

  /** Kyrgyzstan */
  KG = "KG",

  /** Cambodia */
  KH = "KH",

  /** Kiribati */
  KI = "KI",

  /** Comoros */
  KM = "KM",

  /** Saint Kitts and Nevis */
  KN = "KN",

  /** Korea (Democratic People's Republic of) */
  KP = "KP",

  /** Korea, Republic of */
  KR = "KR",

  /** Kuwait */
  KW = "KW",

  /** Cayman Islands */
  KY = "KY",

  /** Kazakhstan */
  KZ = "KZ",

  /** Lao People's Democratic Republic */
  LA = "LA",

  /** Lebanon */
  LB = "LB",

  /** Saint Lucia */
  LC = "LC",

  /** Liechtenstein */
  LI = "LI",

  /** Sri Lanka */
  LK = "LK",

  /** Liberia */
  LR = "LR",

  /** Lesotho */
  LS = "LS",

  /** Lithuania */
  LT = "LT",

  /** Luxembourg */
  LU = "LU",

  /** Latvia */
  LV = "LV",

  /** Libya */
  LY = "LY",

  /** Morocco */
  MA = "MA",

  /** Monaco */
  MC = "MC",

  /** Moldova, Republic of */
  MD = "MD",

  /** Montenegro */
  ME = "ME",

  /** Saint Martin (French part) */
  MF = "MF",

  /** Madagascar */
  MG = "MG",

  /** Marshall Islands */
  MH = "MH",

  /** North Macedonia */
  MK = "MK",

  /** Mali */
  ML = "ML",

  /** Myanmar */
  MM = "MM",

  /** Mongolia */
  MN = "MN",

  /** Macao */
  MO = "MO",

  /** Northern Mariana Islands */
  MP = "MP",

  /** Martinique */
  MQ = "MQ",

  /** Mauritania */
  MR = "MR",

  /** Montserrat */
  MS = "MS",

  /** Malta */
  MT = "MT",

  /** Mauritius */
  MU = "MU",

  /** Maldives */
  MV = "MV",

  /** Malawi */
  MW = "MW",

  /** Mexico */
  MX = "MX",

  /** Malaysia */
  MY = "MY",

  /** Mozambique */
  MZ = "MZ",

  /** Namibia */
  NA = "NA",

  /** New Caledonia */
  NC = "NC",

  /** Niger */
  NE = "NE",

  /** Norfolk Island */
  NF = "NF",

  /** Nigeria */
  NG = "NG",

  /** Nicaragua */
  NI = "NI",

  /** Netherlands */
  NL = "NL",

  /** Norway */
  NO = "NO",

  /** Nepal */
  NP = "NP",

  /** Nauru */
  NR = "NR",

  /** Niue */
  NU = "NU",

  /** New Zealand */
  NZ = "NZ",

  /** Oman */
  OM = "OM",

  /** Panama */
  PA = "PA",

  /** Peru */
  PE = "PE",

  /** French Polynesia */
  PF = "PF",

  /** Papua New Guinea */
  PG = "PG",

  /** Philippines */
  PH = "PH",

  /** Pakistan */
  PK = "PK",

  /** Poland */
  PL = "PL",

  /** Saint Pierre and Miquelon */
  PM = "PM",

  /** Pitcairn */
  PN = "PN",

  /** Puerto Rico */
  PR = "PR",

  /** Palestine, State of */
  PS = "PS",

  /** Portugal */
  PT = "PT",

  /** Palau */
  PW = "PW",

  /** Paraguay */
  PY = "PY",

  /** Qatar */
  QA = "QA",

  /** Réunion */
  RE = "RE",

  /** Romania */
  RO = "RO",

  /** Serbia */
  RS = "RS",

  /** Russian Federation */
  RU = "RU",

  /** Rwanda */
  RW = "RW",

  /** Saudi Arabia */
  SA = "SA",

  /** Solomon Islands */
  SB = "SB",

  /** Seychelles */
  SC = "SC",

  /** Sudan */
  SD = "SD",

  /** Sweden */
  SE = "SE",

  /** Singapore */
  SG = "SG",

  /** Saint Helena, Ascension and Tristan da Cunha */
  SH = "SH",

  /** Slovenia */
  SI = "SI",

  /** Svalbard and Jan Mayen */
  SJ = "SJ",

  /** Slovakia */
  SK = "SK",

  /** Sierra Leone */
  SL = "SL",

  /** San Marino */
  SM = "SM",

  /** Senegal */
  SN = "SN",

  /** Somalia */
  SO = "SO",

  /** Suriname */
  SR = "SR",

  /** South Sudan */
  SS = "SS",

  /** Sao Tome and Principe */
  ST = "ST",

  /** El Salvador */
  SV = "SV",

  /** Sint Maarten (Dutch part) */
  SX = "SX",

  /** Syrian Arab Republic */
  SY = "SY",

  /** Eswatini */
  SZ = "SZ",

  /** Turks and Caicos Islands */
  TC = "TC",

  /** Chad */
  TD = "TD",

  /** French Southern Territories */
  TF = "TF",

  /** Togo */
  TG = "TG",

  /** Thailand */
  TH = "TH",

  /** Tajikistan */
  TJ = "TJ",

  /** Tokelau */
  TK = "TK",

  /** Timor-Leste */
  TL = "TL",

  /** Turkmenistan */
  TM = "TM",

  /** Tunisia */
  TN = "TN",

  /** Tonga */
  TO = "TO",

  /** Turkey */
  TR = "TR",

  /** Trinidad and Tobago */
  TT = "TT",

  /** Tuvalu */
  TV = "TV",

  /** Taiwan, Province of China */
  TW = "TW",

  /** Tanzania, United Republic of */
  TZ = "TZ",

  /** Ukraine */
  UA = "UA",

  /** Uganda */
  UG = "UG",

  /** United States Minor Outlying Islands */
  UM = "UM",

  /** United States of America */
  US = "US",

  /** Uruguay */
  UY = "UY",

  /** Uzbekistan */
  UZ = "UZ",

  /** Holy See */
  VA = "VA",

  /** Saint Vincent and the Grenadines */
  VC = "VC",

  /** Venezuela (Bolivarian Republic of) */
  VE = "VE",

  /** Virgin Islands (British) */
  VG = "VG",

  /** Virgin Islands (U.S.) */
  VI = "VI",

  /** Viet Nam */
  VN = "VN",

  /** Vanuatu */
  VU = "VU",

  /** Wallis and Futuna */
  WF = "WF",

  /** Samoa */
  WS = "WS",

  /** Yemen */
  YE = "YE",

  /** Mayotte */
  YT = "YT",

  /** South Africa */
  ZA = "ZA",

  /** Zambia */
  ZM = "ZM",

  /** Zimbabwe */
  ZW = "ZW",

  // //
  // // Exceptional reservations
  // // total: 13
  // //
  // /** Ascension Island */
  // AC = "AC",
  //
  // /** Clipperton Island */
  // CP = "CP",
  //
  // /** Island of Sark */
  // CQ = "CQ",
  //
  // /** Diego Garcia */
  // DG = "DG",
  //
  // /** Ceuta, Melilla */
  // EA = "EA",
  //
  // /** European Union */
  // EU = "EU",
  //
  // /** Eurozone */
  // EZ = "EZ",
  //
  // /** France, Metropolitan */
  // FX = "FX",
  //
  // /** Canary Islands */
  // IC = "IC",
  //
  // /** USSR */
  // SU = "SU",
  //
  // /** Tristan da Cunha */
  // TA = "TA",
  //
  // /** United Kingdom */
  // UK = "UK",
  //
  // /** United Nations */
  // UN = "UN",
  //
  // //
  // // Transitional reservations
  // // total: 7
  // //
  //
  // /**
  //  * Netherlands Antilles
  //  * @Reserved from 2010-12 to 2060-12
  //  * @To Divided to BQ and SX
  //  */
  // AN = "AN",
  //
  // /**
  //  * Burma
  //  * @Reserved from 1989-12 to 2039-12
  //  * @To Name changed to MM
  //  */
  // BU = "BU",
  //
  // /**
  //  * Serbia and Montenegro
  //  * @Reserved from 2006-09 to 2056-09
  //  * @To Divided to ME and RS
  //  */
  // CS = "CS",
  //
  // /**
  //  * Neutral Zone
  //  * @Reserved from 1993-07 to 2043-07
  //  * @To Divided between IQ and SA
  //  */
  // NT = "NT",
  //
  // /**
  //  * East Timor
  //  * @Reserved from 2002-05 to 2052-05
  //  * @To Name changed to TL
  //  */
  // TP = "TP",
  //
  // /**
  //  * Yugoslavia
  //  * @Reserved from 2003-07 to 2053-07
  //  */
  // YU = "YU",
  //
  // /**
  //  * Zaire
  //  * @Reserved from 1997-07 to 2047-07
  //  * @To Name changed to CD
  //  */
  // ZR = "ZR",
}
