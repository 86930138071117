import { IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";
import { ESRSTopicIdentifier } from "./esrs-topic.public-types";

export class ESRSTopicUtilities {
  public static isTopicRecorded(topic: { recordings: unknown[]; templates: unknown[] }): boolean {
    return topic.recordings.length === 0 && topic.templates.length > 0;
  }

  public static convertTopicToDisplayTopic(topic: IESRSTopicIdentifier) {
    return topic.replace("_", " ").toLocaleUpperCase();
  }

  public static convertTopicToDisplayTopicShort(topic: IESRSTopicIdentifier) {
    return topic.split("_")[1].toLocaleUpperCase();
  }

  public static convertApiESRSTopicToDao(
    esrsTopicIdentifier: IESRSTopicIdentifier,
  ): ESRSTopicIdentifier {
    switch (esrsTopicIdentifier) {
      case IESRSTopicIdentifier._2:
        return ESRSTopicIdentifier.ESRS_2;
      case IESRSTopicIdentifier.E1:
        return ESRSTopicIdentifier.ESRS_E1;
      case IESRSTopicIdentifier.E2:
        return ESRSTopicIdentifier.ESRS_E2;
      case IESRSTopicIdentifier.E3:
        return ESRSTopicIdentifier.ESRS_E3;
      case IESRSTopicIdentifier.E4:
        return ESRSTopicIdentifier.ESRS_E4;
      case IESRSTopicIdentifier.E5:
        return ESRSTopicIdentifier.ESRS_E5;
      case IESRSTopicIdentifier.S1:
        return ESRSTopicIdentifier.ESRS_S1;
      case IESRSTopicIdentifier.S2:
        return ESRSTopicIdentifier.ESRS_S2;
      case IESRSTopicIdentifier.S3:
        return ESRSTopicIdentifier.ESRS_S3;
      case IESRSTopicIdentifier.S4:
        return ESRSTopicIdentifier.ESRS_S4;
      case IESRSTopicIdentifier.G1:
        return ESRSTopicIdentifier.ESRS_G1;
      case IESRSTopicIdentifier.O:
        return ESRSTopicIdentifier.ESRS_O;
    }
  }
}
