import { forwardRef, RefCallback } from "react";
import { IMaskInput } from "react-imask";

export interface IMaskedInputProps {
  mask: string | RegExp;
  definitions: unknown;
  onChange: (value: string) => void;
}

export const MaskedInput = forwardRef<unknown, IMaskedInputProps>(function MaskedInput(
  { mask, definitions, onChange, ...others },
  ref,
) {
  return (
    <IMaskInput
      {...others}
      mask={mask as string}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      definitions={definitions as any}
      inputRef={ref as RefCallback<HTMLTextAreaElement | HTMLInputElement>}
      onAccept={(val) => onChange(val as string)}
    />
  );
});
