import { IOrganizationStructurePayload } from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getRecordingPeriodOrganizationStructureQueryKey } from "./organization-structures.queries";

export const useUpdateOrganizationStructureMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      payload,
    }: {
      organizationId: string;
      recordingPeriodId: string;
      payload: IOrganizationStructurePayload;
    }) => {
      return CoreApiService.OrganizationStructureApi.updateOrganizationStructureForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        {
          structureDraft: payload.structureDraft,
        },
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getRecordingPeriodOrganizationStructureQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};
