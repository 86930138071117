import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, Chip } from "@mui/material";
import { ChevronDownIcon, IconSize } from "../../common/constants/tabler-icon.constants";

interface ILinkedSourcesChipProps {
  linkedSourceIds: string[];
  showChevron?: boolean;
  open?: boolean;
  onClick?: VoidFunction;
  disabled?: boolean;
}

export const LinkedSourcesChip = forwardRef<HTMLDivElement, ILinkedSourcesChipProps>(
  function LinkedSourcesChip({ linkedSourceIds, showChevron, open, onClick, disabled }, ref) {
    const { t } = useTranslation("linked_sources");
    return (
      <Chip
        ref={ref}
        label={
          <Box display="flex" gap={0.5}>
            {t("selected_sources_count", { count: linkedSourceIds.length })}
            {showChevron && (
              <ChevronDownIcon
                size={IconSize.Smaller}
                style={{
                  transform: open ? "rotate(180deg)" : undefined,
                }}
              />
            )}
          </Box>
        }
        onClick={onClick}
        disabled={disabled}
      />
    );
  },
);
