import { Configuration, OrganizationMembershipsApiFp } from "@netcero/phase-two-api-client";
import { KEYCLOAK_URL, KEYCLOAK_REALM } from "../../keycloak";

const PHASE_TWO_API_CONFIGURATION: Configuration = {
  basePath: KEYCLOAK_URL.replace(/\/$/, "") + "/realms", // Remove trailing slash (if present)
  isJsonMime: (mime: string) => mime === "application/json",
};

export class PhaseTwoApiService {
  static RealmId = KEYCLOAK_REALM;

  static OrganizationMembershipsApi = OrganizationMembershipsApiFp(PHASE_TWO_API_CONFIGURATION);

  static get DefaultRequestPayload() {
    return {
      realmId: PhaseTwoApiService.RealmId,
    };
  }
}
