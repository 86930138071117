import { Box, CircularProgress } from "@mui/material";

interface ICenteredCircularProgress {
  minHeight?: string | number;
}

export const CenteredCircularProgress = ({ minHeight }: ICenteredCircularProgress) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight={minHeight ?? ""}
    >
      <CircularProgress />
    </Box>
  );
};
