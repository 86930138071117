import { useQuery } from "@tanstack/react-query";
import { PhaseTwoApiService } from "../phase-two-api/phase-two-api.service";
import { useKeycloakOrganizationId } from "./hooks/keycloak-organization-id.hook";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { useCallback } from "react";

const getSearchUsersQueryKey = (keycloakOrganizationId: string, search?: string) => [
  "users",
  keycloakOrganizationId,
  search,
];

export function useSearchUsersQuery<T = IUserRepresentation[]>(
  keycloakOrganizationId: string | null | undefined,
  search?: string,
  select?: (data: IUserRepresentation[]) => T,
) {
  return useQuery({
    queryKey: getSearchUsersQueryKey(keycloakOrganizationId!, search),
    queryFn: async () => {
      // TODO: Add pagination
      return PhaseTwoApiService.OrganizationMembershipsApi.getOrganizationMemberships(
        PhaseTwoApiService.RealmId,
        keycloakOrganizationId!,
        search,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    enabled: !!keycloakOrganizationId,
    select,
  });
}

export function useSearchUsersQueryInternal<T = IUserRepresentation[]>(
  organizationId: string,
  search?: string,
  select?: (data: IUserRepresentation[]) => T,
) {
  const keycloakOrganizationId = useKeycloakOrganizationId(organizationId);
  return useSearchUsersQuery(keycloakOrganizationId, search, select);
}

export function useGetSingleUserQueryInternal(
  organizationId: string,
  userId: string | undefined | null,
) {
  const selectUser = useCallback(
    (users: IUserRepresentation[]) => users.find((u) => u.id === userId) ?? null,
    [userId],
  );
  return useSearchUsersQueryInternal(organizationId, undefined, selectUser);
}
