import { IDMAConfigurationPotentialExtendSteps } from "@netcero/netcero-core-api-client";
import { IFinancialEffectCalculatorData } from "../types/financial-effect-calculator-data.type";
import { IBaseCalculator } from "./base-calculator.interface";
import { DMAUtilities } from "../utilities/dma.utilities";
import { DMA_MATERIALITY_MULTIPLIER } from "../constants/dma.constants";
import { BaseCalculator, ResolvedProbability } from "./base-calculator";
import { IBaseCalculatorContext } from "../types/base-calculator-context.interface";

export class FinancialEffectCalculator extends BaseCalculator implements IBaseCalculator {
  constructor(
    private readonly data: IFinancialEffectCalculatorData,
    context: IBaseCalculatorContext,
  ) {
    super(context);
  }

  private get materialityMultiplier(): number {
    return DMA_MATERIALITY_MULTIPLIER;
  }

  private resolveProbabilityForData(resolve: ResolvedProbability) {
    return this.resolveProbabilityValue(this.data.probabilityOfOccurrence, resolve);
  }

  public calculateSeverity(): number {
    return this.data.potentialExtent;
  }

  public calculateSeverityCurrency(
    potentialExtentSteps: IDMAConfigurationPotentialExtendSteps,
  ): number {
    const euroEquivalent = DMAUtilities.getPotentialExtentCurrencyEquivalent(
      potentialExtentSteps,
      this.data.potentialExtent,
    );

    return (
      euroEquivalent *
      // Important: Euro-Calculations should still use custom probability values!
      this.resolveProbabilityForData("with-custom")
    );
  }

  public calculateMaterialityDegree(): number {
    const materialityDegree = BaseCalculator.normalizeValue(
      (this.calculateSeverity() + this.resolveProbabilityForData("without-custom")) / 2,
    );

    return materialityDegree * this.materialityMultiplier;
  }

  public calculateMateriality(): boolean {
    return this.calculateMaterialityDegree() >= this.context.materialityThreshold;
  }
}
