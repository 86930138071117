import { Box, Breadcrumbs, IconButton, Menu, MenuItem } from "@mui/material";
import { FC, useMemo, useRef, useState } from "react";
import { ChevronDownIcon } from "../../common/constants/tabler-icon.constants";
import { Predicate } from "../../common/interfaces/predicate.type";
import {
  IInputParameterRecordingStructureGroupWithInternalIds,
  RecordingStructuresUtilities,
} from "../recording-structures.utilities";
import { useTranslateContent } from "../../content-translation/hooks/translate-content.hook";

interface IRecordingStructureGroupsBreadcrumbsProps {
  recordingStructureRootGroup: IInputParameterRecordingStructureGroupWithInternalIds;
  selectedGroup: IInputParameterRecordingStructureGroupWithInternalIds;
  onSelectGroup: (group: IInputParameterRecordingStructureGroupWithInternalIds) => void;
  isRecordingGroupSelectable?: Predicate<IInputParameterRecordingStructureGroupWithInternalIds>;
}
export const RecordingStructureGroupsBreadcrumbs: FC<IRecordingStructureGroupsBreadcrumbsProps> = ({
  recordingStructureRootGroup,
  selectedGroup,
  onSelectGroup,
  isRecordingGroupSelectable,
}) => {
  const pathToGroup = useMemo(() => {
    return RecordingStructuresUtilities.getStructureGroupsPathForToChild(
      recordingStructureRootGroup,
      selectedGroup.id,
    );
  }, [recordingStructureRootGroup, selectedGroup]);

  const [hasSelectableChildren, children] = useMemo(() => {
    const children = pathToGroup?.[pathToGroup.length - 1]?.children;
    if (!children) {
      return [false, []];
    }

    const result = children.map((child) => isRecordingGroupSelectable?.(child) ?? true);
    return [result.some((isSelectable) => isSelectable), children];
  }, [pathToGroup, isRecordingGroupSelectable]);

  return (
    <Breadcrumbs>
      {pathToGroup
        ? pathToGroup.map((group, index) => (
            <RecordingStructureGroupsBreadcrumb
              key={group.id}
              onSelectChild={(selectedGroup) => onSelectGroup(selectedGroup)}
              onSelectParent={() => onSelectGroup(group)}
              recordingGroup={group}
              dropdownOptions={pathToGroup[index - 1]?.children}
              isRecordingGroupSelectable={isRecordingGroupSelectable}
            />
          ))
        : "ERROR"}
      {hasSelectableChildren && (
        <RecordingStructureGroupsBreadcrumb
          onSelectChild={(selectedGroup) => onSelectGroup(selectedGroup)}
          dropdownOptions={children}
          isRecordingGroupSelectable={isRecordingGroupSelectable}
        />
      )}
    </Breadcrumbs>
  );
};

interface IRecordingStructureGroupsBreadcrumbProps {
  recordingGroup?: IInputParameterRecordingStructureGroupWithInternalIds | null;
  dropdownOptions?: IInputParameterRecordingStructureGroupWithInternalIds[] | null;
  onSelectChild: (group: IInputParameterRecordingStructureGroupWithInternalIds) => void;
  onSelectParent?: () => void;
  isRecordingGroupSelectable?: Predicate<IInputParameterRecordingStructureGroupWithInternalIds>;
}
const RecordingStructureGroupsBreadcrumb: FC<IRecordingStructureGroupsBreadcrumbProps> = ({
  recordingGroup,
  dropdownOptions,
  onSelectChild,
  onSelectParent,
  isRecordingGroupSelectable,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const translateContent = useTranslateContent();

  const [showMenu, setShowMenu] = useState(false);

  const handleShowDropdown = () => {
    setShowMenu(true);
  };

  const handleClose = (
    selectedChildGroup: IInputParameterRecordingStructureGroupWithInternalIds | null,
  ) => {
    if (selectedChildGroup) {
      onSelectChild(selectedChildGroup);
    }
    setShowMenu(false);
  };

  const [hasSelectableChildren, selectableChildren] = useMemo(() => {
    if (!dropdownOptions) {
      return [false, []];
    }

    const result = dropdownOptions.map((child) => isRecordingGroupSelectable?.(child) ?? true);
    return [result.some((isSelectable) => isSelectable), result];
  }, [dropdownOptions, isRecordingGroupSelectable]);

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1}>
        {recordingGroup && (
          <Box
            onClick={onSelectParent}
            sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
          >
            {translateContent(recordingGroup.name)}
          </Box>
        )}
        {hasSelectableChildren && (
          <IconButton size="small" onClick={handleShowDropdown} ref={buttonRef}>
            <ChevronDownIcon />
          </IconButton>
        )}
      </Box>

      <Menu
        anchorEl={buttonRef.current}
        open={showMenu}
        onClose={() => handleClose(null)}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        {dropdownOptions?.map((childGroup, index) => (
          <MenuItem
            key={childGroup.id}
            onClick={() => onSelectChild(childGroup)}
            disabled={!selectableChildren[index]}
            sx={{ display: "flex", gap: 1 }}
          >
            {translateContent(childGroup.name)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
