import { Dispatch, FC } from "react";
import { IAction } from "@netcero/netcero-core-api-client";
import { ActionsSelectDialog } from "./actions-select.dialog";
import { useDialogStateWithoutData } from "../../../common/dialogs/dialog-state.hook";
import { ActionsMultipleSelectChip } from "./actions-multiple-select-chip.component";

interface IActionsSelectInputProps {
  label: string;
  actions: IAction[];
  actionsIds: string[];
  onChange: Dispatch<string[]>;
}

export const ActionsInputSelect: FC<IActionsSelectInputProps> = ({
  label,
  actions,
  actionsIds,
  onChange,
}) => {
  const {
    isOpen: showSelectDialog,
    openDialog: openSelectDialog,
    closeDialog: closeSelectDialog,
  } = useDialogStateWithoutData();

  return (
    <>
      {/* Dialog */}
      <ActionsSelectDialog
        open={showSelectDialog}
        title={label}
        onClose={closeSelectDialog}
        actions={actions}
        selectedActionsIds={actionsIds}
        onChange={onChange}
      />

      {/* Chip Select */}
      <ActionsMultipleSelectChip
        label={label}
        actions={actions}
        selectedActionIds={actionsIds}
        onSelectClick={openSelectDialog}
      />
    </>
  );
};
