import { IDistributionCriterionWithApplicationStatus } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export interface IDistributionCriterionDropdownComponentProps {
  // Criterion
  currentCriterion: IDistributionCriterionWithApplicationStatus | null;
  appliedDistributionCriteria: IDistributionCriterionWithApplicationStatus[];
  setCurrentCriterion: (criterionId: string) => void;
}

export const DistributionCriterionDropdownComponent: FC<
  IDistributionCriterionDropdownComponentProps
> = ({ currentCriterion, setCurrentCriterion, appliedDistributionCriteria }) => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_component", {
    keyPrefix: "dropdowns.distribution_criterion_dropdown",
  });
  // no current criterion would mean, that no criteria could be found --> do not display dropdown either
  return currentCriterion === null ? null : (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      flexWrap={{ xs: "wrap", lg: "nowrap" }}
      gap={2}
    >
      {/* Structure Select */}
      <FormControl fullWidth>
        <InputLabel id="distribution-criterion-select-label">{t("label")}</InputLabel>
        <Select
          labelId="distribution-criterion-select-label"
          id="distribution-criterion-select"
          value={currentCriterion.id}
          label={t("label")}
          onChange={(event) => setCurrentCriterion(event.target.value)}
        >
          {appliedDistributionCriteria.map((criterion) => (
            <MenuItem key={criterion.id} value={criterion.id}>
              <Box display="flex">
                <span>{criterion.name}</span>
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
