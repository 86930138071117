import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { PoliciesInputSelect } from "../../policies/components/policies-select-input/policies-select-input.component";
import { IPolicy } from "@netcero/netcero-core-api-client";

interface IAssociatedPoliciesFormData {
  policyIds: string[];
}

interface IAssociatedPoliciesWithControllerProps {
  label: string;
  policies: IPolicy[];
}

export const AssociatedPoliciesWithController: FC<IAssociatedPoliciesWithControllerProps> = ({
  label,
  policies,
}) => {
  const { control } = useFormContext<IAssociatedPoliciesFormData>();

  return (
    <Controller
      control={control}
      name="policyIds"
      render={({ field: { value, onChange } }) => (
        <PoliciesInputSelect
          label={label}
          policies={policies}
          policiesIds={value}
          onChange={onChange}
        />
      )}
    />
  );
};
