import { styled } from "@mui/material";
import { StyledCard } from "../../common/components/styled-card.component";

export const DisclosureRequirementCard = styled(StyledCard, {
  shouldForwardProp: (propName) => propName !== "isExpanded",
})<{
  isExpanded: boolean;
}>(({ theme, isExpanded }) => ({
  my: isExpanded ? theme.spacing(2) : 0,
  transition: "box-shadow 300ms, margin 300ms ease",
  ":hover": {
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 15px 5px",
  },
  position: "relative",
  overflow: "unset",
}));
