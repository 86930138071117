import { AppModule } from "@netcero/netcero-common";
import { useMemo } from "react";
import { useOrganizationModules } from "./organization-modules.hook";

export const useHasOrganizationModuleAccess = (organizationId: string, module: AppModule) => {
  const availableModules = useOrganizationModules(organizationId);

  return useMemo(() => {
    return availableModules && availableModules?.includes(module);
  }, [availableModules, module]);
};
