import { FC } from "react";
import { Menu, MenuItem, PopoverProps } from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UserItem } from "./user-item.component";
import { UserAvatar } from "./user-avatar.component";

interface IPropsCommon {
  show: boolean;
  anchorEl?: PopoverProps["anchorEl"];
  noUsersAvailableText: string;
  onClose: VoidFunction;
  users: IUserRepresentation[] | null | undefined;
  activeUserId?: string | null | undefined;
}

interface IPropsAllowNull extends IPropsCommon {
  noUserOption: true;
  noUserOptionText: string;
  onSelect: (user: IUserRepresentation | null) => void;
}

interface IPropsDisallowNull extends IPropsCommon {
  noUserOption?: false;
  noUserOptionText?: undefined;
  onSelect: (user: IUserRepresentation) => void;
}

type IUserSelectMenuProps = IPropsAllowNull | IPropsDisallowNull;

export const UserSelectMenu: FC<IUserSelectMenuProps> = ({
  show,
  anchorEl,
  noUsersAvailableText,
  users,
  activeUserId,
  onClose,
  noUserOptionText,
  onSelect,
}) => {
  return (
    <Menu open={show} anchorEl={anchorEl} onClose={onClose}>
      {/* No User Option*/}
      {noUserOptionText && (
        <MenuItem
          selected={activeUserId === null}
          onClick={() => onSelect(null)}
          sx={{ display: "flex", gap: 1 }}
        >
          <UserAvatar user={null} />
          {noUserOptionText}
        </MenuItem>
      )}

      {/* Show empty message if no users to show */}
      {users && users.length < 1 && <MenuItem disabled>{noUsersAvailableText}</MenuItem>}

      {/* List Users as Items */}
      {users?.map((user) => (
        <MenuItem key={user.id} onClick={() => onSelect(user)} selected={activeUserId === user.id}>
          <UserItem user={user} />
        </MenuItem>
      ))}
    </Menu>
  );
};
