import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IDMACategory } from "@netcero/netcero-core-api-client";
import { IConfirmDialogProps } from "../../../common/dialogs/types/confirm-dialog-props.interface";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";
import { ConfirmDialogTextBody } from "../../../common/dialogs/variants/confirm.dialog";

interface IDeleteDMACategoryConfirmDialogProps extends IConfirmDialogProps {
  dmaCategory?: IDMACategory | null;
}

export const DeleteDMACategoryConfirmDialog: FC<IDeleteDMACategoryConfirmDialogProps> = ({
  onConfirm,
  open,
  loading,
  onCancel,
  error,
  disabled,
  dmaCategory,
}) => {
  const { t } = useTranslation("dma_category_delete_confirm_dialog");
  const renderName = useRenderDMACategoryName();

  return (
    <ConfirmDialogTextBody
      open={open}
      onConfirm={onConfirm}
      onCancel={onCancel}
      disabled={disabled}
      error={error}
      loading={loading}
      actionType="delete"
      title={t("title", { name: renderName(dmaCategory) })}
      content={t("content_text", { name: renderName(dmaCategory) })}
    />
  );
};
