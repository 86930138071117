import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { QueryWrapper } from "../../modules/common/components/query-wrapper.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useDistributionCriteriaQuery } from "../../modules/distribution-criteria/distribution-criteria.queries";
import { DistributionCriteriaConfiguration } from "../../modules/distribution-criteria/distribution-criteria-configuration.component";
import { CenteredCircularProgress } from "../../modules/common/components/centered-circular-progress.component";

interface IDistributionCriteriaConfigurationPageProps {}

export const DistributionCriteriaConfigurationPage: FC<
  IDistributionCriteriaConfigurationPageProps
> = () => {
  const { organizationId } = useParams();

  const navigate = useNavigate();

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/distribution-criteria`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/distribution-criteria`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const distributionCriteriaQuery = useDistributionCriteriaQuery(organizationId);

  return (
    <Box p={4}>
      <QueryWrapper
        query={distributionCriteriaQuery}
        loadingOverride={() => <CenteredCircularProgress minHeight="calc(100vh - 64px)" />}
        build={(distributionCriteria) => (
          <DistributionCriteriaConfiguration
            organizationId={organizationId ?? ""}
            distributionCriteriaResponse={distributionCriteria}
          />
        )}
      />
    </Box>
  );
};
