import { RecursiveKeyOf } from "../common";
import { IPolicyData } from "@netcero/netcero-core-api-client";
import { MDRDependencies } from "../mdrs";
import { MDRUtilities } from "../mdrs/mdrs.utilities";

export type KeysForPolicy = RecursiveKeyOf<
  Omit<IPolicyData, "materialImpactIds" | "financialEffectIds">
>;
type IDependenciesForPolicy = MDRDependencies<KeysForPolicy>;

const createBooleanEntry = MDRUtilities.createBooleanMDRDependencyFactory<KeysForPolicy>();

export const DEPENDENCIES_FOR_POLICY: IDependenciesForPolicy = {
  "general.explanationOfHowPolicyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementItExplanatory":
    createBooleanEntry(
      "general.policyIsMadeAvailableToPotentiallyAffectedStakeholdersAndOrStakeholdersWhoNeedToHelpImplementIt",
    ),
  "e3.policyAddressesEitherUseOrSourcingOfWaterAndOrMarineResourcesInOwnOperations":
    createBooleanEntry("e3.policyAddressesWaterManagement"),
  "e3.policyAddressesWaterTreatmentAsStepTowardsMoreSustainableSourcingOfWater": createBooleanEntry(
    "e3.policyAddressesWaterManagement",
  ),
  "e3.policyAddressesPreventionAndOrAbatementOfWaterPollutionResultingFromActivities":
    createBooleanEntry("e3.policyAddressesWaterManagement"),
  "s.disclosureOfSpecificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceExplanatory":
    createBooleanEntry(
      "s.specificPolicyCommitmentsRelatedToInclusionOrPositiveActionForPeopleFromGroupsAtParticularRiskOfVulnerabilityInOwnWorkforceAreInPlace",
    ),
};
export const POLICY_INPUTS_DEPENDED_UPON: KeysForPolicy[] = Array.from(
  new Set(Object.values(DEPENDENCIES_FOR_POLICY).map((d) => d.dependsUpon)),
);
