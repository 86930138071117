import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { OrganizationStructurePageWrapperHelper } from "../common/organization-structure-page-wrapper.helper";
import { getGapAnalysisPageUrl } from "./gap-analysis.page";

export const RedirectToRootDataEntryObjectGapAnalysisPage: FC = () => {
  const { organizationId, recordingPeriodId } = useParams();

  return (
    <OrganizationStructurePageWrapperHelper
      buildPage={(structure) => (
        <Navigate
          to={getGapAnalysisPageUrl(organizationId!, recordingPeriodId!, structure.id)}
          replace
        />
      )}
    />
  );
};
