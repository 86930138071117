import { IESRSTopicForInsert } from "../dma-categories.public-types";
import { IContentLanguage } from "@netcero/netcero-core-api-client";
import { ESRSTopicIdentifier } from "../../esrs-topics";

export const ESRS_CATEGORIES: IESRSTopicForInsert[] = [
  {
    identifier: ESRSTopicIdentifier.ESRS_2,
    name: {
      [IContentLanguage.De]: "Allgemeine Angaben",
      [IContentLanguage.En]: "General disclosures",
    },
    topicESRS: "ESRS 2",
    children: [],
    isInternal: true,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_E1,
    topicESRS: "ESRS E1",
    name: { [IContentLanguage.De]: "Klimawandel", [IContentLanguage.En]: "Climate Change" },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Klimaschutz",
          [IContentLanguage.En]: "Climate change mitigation",
        },
        identifier: "esrs_e1_climate_protection",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Anpassung an den Klimawandel",
          [IContentLanguage.En]: "Climate change adaption",
        },
        identifier: "esrs_e1_adaption_to_climate_change",
        children: [],
      },
      {
        name: { [IContentLanguage.De]: "Energie", [IContentLanguage.En]: "Energy" },
        identifier: "esrs_e1_energy",
        children: [],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_E2,
    topicESRS: "ESRS E2",
    name: { [IContentLanguage.De]: "Umweltverschmutzung", [IContentLanguage.En]: "Pollution" },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Luftverschmutzung",
          [IContentLanguage.En]: "Pollution of air",
        },
        identifier: "esrs_e2_air_pollution",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Wasserverschmutzung",
          [IContentLanguage.En]: "Pollution of water",
        },
        identifier: "esrs_e2_water_pollution",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Bodenverschmutzung",
          [IContentLanguage.En]: "Pollution of soil",
        },
        identifier: "esrs_e2_ground_pollution",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Verschmutzung von lebenden Organismen und Nahrungsressourcen",
          [IContentLanguage.En]: "Pollution of living organisms and food resources",
        },
        identifier: "esrs_e2_pollution_of_organisms",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]:
            "Besorgniserregende Stoffe und besonders besorgniserregende Stoffe",
          [IContentLanguage.En]: "Substances of Concern and Substances of Very High Concern",
        },
        identifier: "esrs_e2_alarming_substances",
        children: [],
      },
      {
        name: { [IContentLanguage.De]: "Mikroplastik", [IContentLanguage.En]: "Microplastics" },
        identifier: "esrs_e2_micro_plastic",
        children: [],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_E3,
    topicESRS: "ESRS E3",
    name: {
      [IContentLanguage.De]: "Wasser- und Meeresressourcen",
      [IContentLanguage.En]: "Water and marine resources",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Wasserverbrauch",
          [IContentLanguage.En]: "Water consumption",
        },
        identifier: "esrs_e3_water_consumption",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Wasserentnahme",
          [IContentLanguage.En]: "Water withdrawals",
        },
        identifier: "esrs_e3_water_withdrawal",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Ableitung von Wasser",
          [IContentLanguage.En]: "Water discharges",
        },
        identifier: "esrs_e3_drain_of_water",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Ableitung von Wasser in die Ozeane",
          [IContentLanguage.En]: "Water discharges in the oceans",
        },
        identifier: "esrs_e3_drain_of_water_into_oceans",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Gewinnung und Nutzung von Meeresressourcen",
          [IContentLanguage.En]: "Extraction and use of marine resources",
        },
        identifier: "esrs_e3_usage_of_sea_resources",
        children: [],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_E4,
    topicESRS: "ESRS E4",
    name: {
      [IContentLanguage.De]: "Biologische Vielfalt und Ökosysteme",
      [IContentLanguage.En]: "Biodiversity and Ecosystems",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Direkte Ursachen des Biodiversitätsverlusts",
          [IContentLanguage.En]: "Direct impact drivers of biodiversity loss",
        },
        identifier: "esrs_e4_direct_causes_of_biodiversity_loss",
        children: [
          {
            name: { [IContentLanguage.De]: "Klimawandel", [IContentLanguage.En]: "Climate change" },
            identifier: "esrs_e4_climate_change",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]:
                "Landnutzungsänderungen, Süßwasser- und Meeresnutzungsänderungen",
              [IContentLanguage.En]: "Land-use change, fresh water-use change and sea-use change",
            },
            identifier: "esrs_e4_changes_in_usage",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Direkte Ausbeutung",
              [IContentLanguage.En]: "Direct exploitation",
            },
            identifier: "esrs_e4_direct_exploitation",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Invasive gebietsfremde Arten",
              [IContentLanguage.En]: "Invasive alien species",
            },
            identifier: "esrs_e4_invasive_species",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Umweltverschmutzung",
              [IContentLanguage.En]: "Pollution",
            },
            identifier: "esrs_e4_environmental_pollution",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Sonstige", [IContentLanguage.En]: "Other" },
            identifier: "esrs_e4_other",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Auswirkungen auf den Zustand der Arten",
          [IContentLanguage.En]: "Impact on the extent and condition of ecosystems",
        },
        identifier: "esrs_e4_impact_on_condition_of_species",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Populationsgröße von Arten",
              [IContentLanguage.En]: "Species population size",
            },
            identifier: "esrs_e4_population_size_of_species",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Globales Ausrottungsrisiko von Arten",
              [IContentLanguage.En]: "Species global extinction risk",
            },
            identifier: "esrs_e4_global_extinction_risk_of_species",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Auswirkungen auf den Umfang und den Zustand von Ökosystemen",
          [IContentLanguage.En]: "Impact on the extent and condition of ecosystems",
        },
        identifier: "esrs_e4_impact_on_condition_of_ecosystems",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Landdegradation",
              [IContentLanguage.En]: "Land degradation",
            },
            identifier: "esrs_e4_land_degradation",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Wüstenbildung",
              [IContentLanguage.En]: "Desertification",
            },
            identifier: "esrs_e4_desertification",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Bodenversiegelung",
              [IContentLanguage.En]: "Soil sealing",
            },
            identifier: "esrs_e4_floor_sealant",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Auswirkungen und Abhängigkeiten von Ökosystemdienstleistungen",
          [IContentLanguage.En]: "Impacts and dependencies on ecosystem services",
        },
        identifier: "esrs_e4_impacts_and_dependencies_on_ecosystem_services",
        children: [],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_E5,
    topicESRS: "ESRS E5",
    name: {
      [IContentLanguage.De]: "Kreislaufwirtschaft",
      [IContentLanguage.En]: "Circular Economy",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Ressourcenzuflüsse, einschließlich Ressourcennutzung",
          [IContentLanguage.En]: "Resource inflows, including resource use",
        },
        identifier: "esrs_e5_resource_influxes",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]:
            "Ressourcenabflüsse im Zusammenhang mit Produkten und Dienstleistungen",
          [IContentLanguage.En]: "Resource outflows related to products and services",
        },
        identifier: "esrs_e5_resource_effluxes",
        children: [],
      },
      {
        name: { [IContentLanguage.De]: "Abfälle", [IContentLanguage.En]: "Waste" },
        identifier: "esrs_e5_waste",
        children: [],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_S1,
    topicESRS: "ESRS S1",
    name: { [IContentLanguage.De]: "Eigene Belegschaft", [IContentLanguage.En]: "Own Workforce" },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Arbeitsbedingungen",
          [IContentLanguage.En]: "Working conditions",
        },
        identifier: "esrs_s1_working_conditions",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Sichere Beschäftigung",
              [IContentLanguage.En]: "Secure employment",
            },
            identifier: "esrs_s1_safe_employment",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Arbeitszeit", [IContentLanguage.En]: "Working time" },
            identifier: "esrs_s1_working_hours",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Angemessene Entlohnung",
              [IContentLanguage.En]: "Adequate wages",
            },
            identifier: "esrs_s1_apt_pay",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Sozialer Dialog",
              [IContentLanguage.En]: "Social dialog",
            },
            identifier: "esrs_s1_social_dialogue",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]:
                "Vereinigungsfreiheit, Existenz von Betriebsräten und Rechte der Arbeitnehmer:innen auf Information, Anhörung und Mitbestimmung",
              [IContentLanguage.En]:
                "Freedom of association, the existence of works councils and the information, consultation and participation rights of workers",
            },
            identifier: "esrs_s1_freedom_of_association",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Tarifverhandlungen",
              [IContentLanguage.En]:
                "Collective bargaining, including rate of workers covered by collective agreements",
            },
            identifier: "esrs_s1_bargaining",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Vereinbarkeit von Berufs- und Privatleben",
              [IContentLanguage.En]: "Work-life balance",
            },
            identifier: "esrs_s1_work_life_balance",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Gesundheitsschutz und Sicherheit",
              [IContentLanguage.En]: "Health and safety",
            },
            identifier: "esrs_s1_health_protection",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Gleichbehandlung und Chancengleichheit",
          [IContentLanguage.En]: "Equal treatment and opportunities for all",
        },
        identifier: "esrs_s1_equality_of_opportunity",
        children: [
          {
            name: {
              [IContentLanguage.De]:
                "Gleichstellung der Geschlechter und gleicher Lohn für gleiche Arbeit",
              [IContentLanguage.En]: "Gender equality and equal pay for work of equal value",
            },
            identifier: "esrs_s1_equal_treatment_of_genders",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Schulungen und Kompetenzentwicklung",
              [IContentLanguage.En]: "Training and skills development",
            },
            identifier: "esrs_s1_training_courses",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Beschäftigung und Inklusion von Menschen mit Behinderung",
              [IContentLanguage.En]: "Employment and inclusion of persons with disabilities",
            },
            identifier: "esrs_s1_inclusion_of_people_with_disabilities",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Maßnahmen gegen Gewalt und Belästigung am Arbeitsplatz",
              [IContentLanguage.En]: "Measures against violence and harassment in the workplace",
            },
            identifier: "esrs_s1_actions_against_violence",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Vielfalt", [IContentLanguage.En]: "Diversity" },
            identifier: "esrs_s1_diversity",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Sonstige Arbeitsrechte",
          [IContentLanguage.En]: "Other work-related rights",
        },
        identifier: "esrs_s1_labour_law",
        children: [
          {
            name: { [IContentLanguage.De]: "Kinderarbeit", [IContentLanguage.En]: "Child labor" },
            identifier: "esrs_s1_child_labour",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Zwangsarbeit", [IContentLanguage.En]: "Forced labor" },
            identifier: "esrs_s1_force_labour",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Angemessene Unterbringung",
              [IContentLanguage.En]: "Adequate housing",
            },
            identifier: "esrs_s1_apt_accommodation",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Datenschutz", [IContentLanguage.En]: "Privacy" },
            identifier: "esrs_s1_data_privacy",
            children: [],
          },
        ],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_S2,
    topicESRS: "ESRS S2",
    name: {
      [IContentLanguage.De]: "Arbeitskräfte in der Wertschöpfungskette",
      [IContentLanguage.En]: "Workforce in the value chain",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Arbeitsbedingungen",
          [IContentLanguage.En]: "Working conditions",
        },
        identifier: "esrs_s2_working_conditions",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Sichere Beschäftigung",
              [IContentLanguage.En]: "Secure employment",
            },
            identifier: "esrs_s2_safe_employment",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Arbeitszeit", [IContentLanguage.En]: "Working time" },
            identifier: "esrs_s2_working_hours",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Angemessene Entlohnung",
              [IContentLanguage.En]: "Adequate wages",
            },
            identifier: "esrs_s2_apt_pay",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Sozialer Dialog",
              [IContentLanguage.En]: "Social dialog",
            },
            identifier: "esrs_s2_social_dialogue",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]:
                "Vereinigungsfreiheit, einschließlich Existenz von Betriebsräten",
              [IContentLanguage.En]:
                "Freedom of association, including the existence of works councils",
            },
            identifier: "esrs_s2_freedom_of_association",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Tarifverhandlungen",
              [IContentLanguage.En]: "Collective bargaining",
            },
            identifier: "esrs_s2_bargaining",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Vereinbarkeit von Berufs- und Privatleben",
              [IContentLanguage.En]: "Work-life balance",
            },
            identifier: "esrs_s2_work_life_balance",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Gesundheitsschutz und Sicherheit",
              [IContentLanguage.En]: "Health and safety",
            },
            identifier: "esrs_s2_health_protection",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Gleichbehandlung und Chancengleichheit",
          [IContentLanguage.En]: "Equal treatment and opportunities for all",
        },
        identifier: "esrs_s2_equality_of_opportunity",
        children: [
          {
            name: {
              [IContentLanguage.De]:
                "Gleichstellung der Geschlechter und gleicher Lohn für gleiche Arbeit",
              [IContentLanguage.En]: "Gender equality and equal pay for equal work",
            },
            identifier: "esrs_s2_equal_treatment_of_genders",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Schulungen und Kompetenzentwicklung",
              [IContentLanguage.En]: "Training and skills development",
            },
            identifier: "esrs_s2_training_courses",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Beschäftigung und Inklusion von Menschen mit Behinderung",
              [IContentLanguage.En]: "The employment and inclusion of persons with disabilities",
            },
            identifier: "esrs_s2_inclusion_of_people_with_disabilities",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Maßnahmen gegen Gewalt und Belästigung am Arbeitsplatz",
              [IContentLanguage.En]: "Measures against violence and harassment in the workplace",
            },
            identifier: "esrs_s2_actions_against_violence",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Vielfalt", [IContentLanguage.En]: "Diversity" },
            identifier: "esrs_s2_diversity",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Sonstige Arbeitsrechte",
          [IContentLanguage.En]: "Other work-related rights",
        },
        identifier: "esrs_s2_labour_law",
        children: [
          {
            name: { [IContentLanguage.De]: "Kinderarbeit", [IContentLanguage.En]: "Child labor" },
            identifier: "esrs_s2_child_labour",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Zwangsarbeit", [IContentLanguage.En]: "Forced labor" },
            identifier: "esrs_s2_forced_labour",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Angemessene Unterbringung",
              [IContentLanguage.En]: "Adequate housing",
            },
            identifier: "esrs_s2_apt_accommodation",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Wasser- und Sanitäreinrichtungen",
              [IContentLanguage.En]: "Water and sanitation",
            },
            identifier: "esrs_s2_sanitation",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Datenschutz", [IContentLanguage.En]: "Privacy" },
            identifier: "esrs_s2_data_privacy",
            children: [],
          },
        ],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_S3,
    topicESRS: "ESRS S3",
    name: {
      [IContentLanguage.De]: "Betroffene Gemeinschaften",
      [IContentLanguage.En]: "Affected Communities",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]:
            "Wirtschaftliche, soziale und kulturelle Rechte von Gemeinschaften",
          [IContentLanguage.En]: "Communities' economic, socialand cultural rights",
        },
        identifier: "esrs_s3_social_rights_of_communities",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Angemessene Unterbringung",
              [IContentLanguage.En]: "Adequate housing",
            },
            identifier: "esrs_s3_apt_accommodation",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Angemessene Ernährung",
              [IContentLanguage.En]: "Adequate food",
            },
            identifier: "esrs_s3_apt_nutrition",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Wasser- und Sanitäreinrichtungen",
              [IContentLanguage.En]: "Water and sanitation",
            },
            identifier: "esrs_s3_sanitation",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Bodenbezogenen Auswirkungen",
              [IContentLanguage.En]: "Land-related impacts",
            },
            identifier: "esrs_s3_impacts_affecting_soil",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Sicherheitsbezogenen Auswirkungen",
              [IContentLanguage.En]: "Security-related impacts",
            },
            identifier: "esrs_s3_impacts_affecting_safety",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Bürgerrechte und politische Rechte von Gemeinschaften",
          [IContentLanguage.En]: "Communities' civil and political rights",
        },
        identifier: "esrs_s3_political_rights_of_communities",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Meinungsfreiheit",
              [IContentLanguage.En]: "Freedom of Expression",
            },
            identifier: "esrs_s3_freedom_of_speech",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Versammlungsfreiheit",
              [IContentLanguage.En]: "Freedom of assembly",
            },
            identifier: "esrs_s3_freedom_of_assembly",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Auswirkungen für Verteidiger:innen der Menschenrechte",
              [IContentLanguage.En]: "Impact on human rights defenders",
            },
            identifier: "esrs_s3_impacts_on_protectors_of_human_rights",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Rechte indigener Völker",
          [IContentLanguage.En]: "Rights of indigenous peoples",
        },
        identifier: "esrs_s3_rights_indigenous_folk",
        children: [
          {
            name: {
              [IContentLanguage.De]:
                "Freiwillige und in Kenntnis der Sachlage erteilte vorherige Zustimmung",
              [IContentLanguage.En]: "Free, prior and informed consent",
            },
            identifier: "esrs_s3_approval",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Selbstbestimmung",
              [IContentLanguage.En]: "Self-determination",
            },
            identifier: "esrs_s3_self_determination",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Kulturelle Rechte",
              [IContentLanguage.En]: "Cultural rights",
            },
            identifier: "esrs_s3_cultural_rights",
            children: [],
          },
        ],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_S4,
    topicESRS: "ESRS S4",
    name: {
      [IContentLanguage.De]: "Verbraucher und Endnutzer",
      [IContentLanguage.En]: "consumers and end-users",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]:
            "Informationsbezogene Auswirkungen für Verbraucher:innen und/oder Endnutzer:innen",
          [IContentLanguage.En]: "Information-related impacts for consumers and/or end-users",
        },
        identifier: "esrs_s4_information_related_impacts_for_consumers",
        children: [
          {
            name: { [IContentLanguage.De]: "Datenschutz", [IContentLanguage.En]: "Privacy" },
            identifier: "esrs_s4_data_privacy",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Meinungsfreiheit",
              [IContentLanguage.En]: "Freedom of expression",
            },
            identifier: "esrs_s4_freedom_of_speech",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Zugang zu (hochwertigen) Informationen",
              [IContentLanguage.En]: "Access to (quality) information",
            },
            identifier: "esrs_s4_access_to_information",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]:
            "Persönliche Sicherheit von Verbraucher:innen und/oder Endnutzer:innen",
          [IContentLanguage.En]: "Personal safety of consumers and/or end-users",
        },
        identifier: "esrs_s4_safety_of_consumers",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Gesundheitsschutz und Sicherheit",
              [IContentLanguage.En]: "Health and safety",
            },
            identifier: "esrs_s4_health_protection",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Persönliche Sicherheit",
              [IContentLanguage.En]: "Security of a person",
            },
            identifier: "esrs_s4_personal_safety",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Kinderschutz",
              [IContentLanguage.En]: "Protection of children",
            },
            identifier: "esrs_s4_child_protection",
            children: [],
          },
        ],
      },
      {
        name: {
          [IContentLanguage.De]: "Soziale Inklusion von Verbraucher:innen und/oder Endnutzer:innen",
          [IContentLanguage.En]: "Social inclusion of consumers and/or end-users",
        },
        identifier: "esrs_s4_social_inclusion_of_consumers",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Nichtdiskriminierung",
              [IContentLanguage.En]: "Non-discrimination",
            },
            identifier: "esrs_s4_non_discrimination",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Zugang zu Produkten und Dienstleistungen",
              [IContentLanguage.En]: "Access to products and services",
            },
            identifier: "esrs_s4_access_to_products",
            children: [],
          },
          {
            name: {
              [IContentLanguage.De]: "Verantwortliche Vermarktungspraktiken",
              [IContentLanguage.En]: "Responsible marketing practices",
            },
            identifier: "esrs_s4_responsible_marketing_methods",
            children: [],
          },
        ],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_G1,
    topicESRS: "ESRS G1",
    name: {
      [IContentLanguage.De]: "Unternehmenspolitik",
      [IContentLanguage.En]: "Business conduct",
    },
    children: [
      {
        name: {
          [IContentLanguage.De]: "Unternehmenskultur",
          [IContentLanguage.En]: "Corporate culture",
        },
        identifier: "esrs_g1_corporate_culture",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Schutz von Hinweisgeber:innen (Whistleblower)",
          [IContentLanguage.En]: "Protection of Whistle-blowers",
        },
        identifier: "esrs_g1_whistleblower_protection",
        children: [],
      },
      {
        name: { [IContentLanguage.De]: "Tierschutz", [IContentLanguage.En]: "Animal welfare" },
        identifier: "esrs_g1_animal_protection",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Politisches Engagement",
          [IContentLanguage.En]: "Political engagement and lobbying activities",
        },
        identifier: "esrs_g1_political_commitment",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]:
            "Management der Beziehungen zu Lieferanten, einschließlich Zahlungspraktiken",
          [IContentLanguage.En]:
            "Management of relationships with suppliers, including payment practices",
        },
        identifier: "esrs_g1_management_of_vendor_relations",
        children: [],
      },
      {
        name: {
          [IContentLanguage.De]: "Korruption und Bestechung",
          [IContentLanguage.En]: "Corruption and bribery",
        },
        identifier: "esrs_g1_corruption",
        children: [
          {
            name: {
              [IContentLanguage.De]: "Vermeidung und Aufdeckung einschließlich Schulung",
              [IContentLanguage.En]: "Prevention and detection including training",
            },
            identifier: "esrs_g1_corruption_prevention",
            children: [],
          },
          {
            name: { [IContentLanguage.De]: "Vorkommnisse", [IContentLanguage.En]: "Incidents" },
            identifier: "esrs_g1_corruption_occurrences",
            children: [],
          },
        ],
      },
    ],
    isInternal: false,
  },
  {
    identifier: ESRSTopicIdentifier.ESRS_O,
    topicESRS: "ESRS O",
    name: {
      [IContentLanguage.De]: "Organisationsspezifisch",
      [IContentLanguage.En]: "Organization Specific",
    },
    children: [],
    isInternal: false,
  },
];
