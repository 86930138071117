import {
  IDataEntryObjectInputParameterExclusionReason,
  IDataEntryObjectInputParameterStatus,
} from "@netcero/netcero-core-api-client";
import { SUPPORT_COLORS } from "../../theme/theme";

export const DATA_ENTRY_OBJECT_INPUT_PARAMETER_STATUS_COLORS: Record<
  IDataEntryObjectInputParameterStatus,
  string
> = {
  [IDataEntryObjectInputParameterStatus.Open]: SUPPORT_COLORS.gray,
  [IDataEntryObjectInputParameterStatus.InProgress]: SUPPORT_COLORS.yellow,
  [IDataEntryObjectInputParameterStatus.InReview]: SUPPORT_COLORS.blue,
  [IDataEntryObjectInputParameterStatus.Done]: SUPPORT_COLORS.green,
};
export const DATA_ENTRY_OBJECT_INPUT_PARAMETER_EXCLUDED_COLOR = SUPPORT_COLORS.darkGray;

export const ORDERED_DEO_IP_EXCLUSION_REASONS: IDataEntryObjectInputParameterExclusionReason[] = [
  IDataEntryObjectInputParameterExclusionReason.OtherReason,
  IDataEntryObjectInputParameterExclusionReason.NonMaterial,
  IDataEntryObjectInputParameterExclusionReason.ApplyPhaseIn,
];
