import { FC, useMemo, useState } from "react";
import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import { MaskedInput } from "../../common/components/masked-input.component";
import {
  RelativeTargetPathGoalScopes,
  TargetPathGoalsCalculations,
  TargetPathGoalsUtilities,
} from "@netcero/netcero-common";
import {
  IReferenceBudget,
  IRelativeTargetPathGoalIntensities,
} from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { useReductionTerm } from "../hooks/use-reduction-term.hook";
import { ReferenceIntensityComponent } from "./reference-intensity.component";

interface ITargetIntensityInputComponentProps {
  disabled?: boolean;
  onValueChange: (value: number) => void;
  scope: RelativeTargetPathGoalScopes;
  intensities: IRelativeTargetPathGoalIntensities;
  referenceBudget: IReferenceBudget;
  absoluteTargetRevenue: number;
}

export const TargetIntensityInputComponent: FC<ITargetIntensityInputComponentProps> = ({
  disabled,
  onValueChange,
  intensities,
  scope,
  referenceBudget,
  absoluteTargetRevenue,
}) => {
  const { t } = useTranslation("target_intensity_input_component");

  const [value, setValue] = useState<number>(intensities[scope]);

  const emissionsForScope = useMemo(() => {
    return TargetPathGoalsUtilities.getEmissionsSumForRelativeTargetPathGoalScope(
      referenceBudget.calculatedValues.sumsPerScope,
      scope,
    );
  }, [referenceBudget.calculatedValues.sumsPerScope, scope]);

  const noEmissions = useMemo(() => emissionsForScope === 0, [emissionsForScope]);

  const relativeReduction = noEmissions
    ? null
    : TargetPathGoalsCalculations.convertIntensityToRelativeScopeValue(
        intensities,
        scope,
        absoluteTargetRevenue,
        emissionsForScope,
      ).value * 100;

  const reduction = useReductionTerm(relativeReduction ?? 0);

  return (
    <Box>
      <Tooltip
        title={
          noEmissions
            ? t("tooltip_no_value")
            : t("tooltip_value", {
                absoluteValue: FormatUtilities.formatFixedDecimal(
                  TargetPathGoalsCalculations.convertIntensityToAbsoluteScopeValue(
                    intensities,
                    scope,
                    absoluteTargetRevenue,
                  ).value,
                  2,
                ),
                relativeReduction: FormatUtilities.formatFixedDecimal(
                  relativeReduction! * reduction.multiplyBy,
                  2,
                ),
                reductionTerm: reduction.translation,
              })
        }
        placement="left"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-10, 0],
                },
              },
            ],
          },
        }}
      >
        <TextField
          fullWidth
          value={value.toString()}
          onChange={(value) => {
            const typedValue = value as unknown as string;
            if (typedValue !== "") {
              setValue(parseFloat(typedValue));
            } else {
              setValue(0);
            }
          }}
          helperText={
            noEmissions ? (
              t("helper_text_no_value")
            ) : (
              <ReferenceIntensityComponent
                referenceBudget={referenceBudget}
                totalEmissions={emissionsForScope}
                noFormat
                isSum={false}
              />
            )
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                tCO<sub>2</sub>eq / €
              </InputAdornment>
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: MaskedInput as any,
            inputProps: {
              mask: Number,
              scale: 2,
              unmask: true,
              eager: true,
              thousandsSeparator: ".",
              padFractionalZeros: false,
            },
          }}
          label={t(`scopes.${scope}`)}
          disabled={disabled || noEmissions}
          onBlur={() => {
            if (value !== intensities[scope]) {
              onValueChange(value);
            }
          }}
        />
      </Tooltip>
    </Box>
  );
};
