import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ILocalDataEntryObjectInputParameterValue,
  ILocalDataEntryObjectInputParameterValueData,
} from "../../interfaces/local-data-entry-object-values.interfaces";
import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameter,
} from "@netcero/netcero-core-api-client";
import {
  DataEntryObjectValuesUtilities,
  IValuesErrorsPerKey,
} from "../../utilities/data-entry-object-values.utilities";
import {
  DataEntryObjectInputParameterTableValuesVerification,
  DataEntryObjectInputParameterValuesVerification,
} from "@netcero/netcero-common";
import { EsrsValueEditingUtilities } from "../../esrs/value-editing/esrs-value-editing.utilities";

export type IDuplicateValueError = Record<string, string | undefined>;
export type IDuplicateValuesErrors = IDuplicateValueError[];

const findMatchingRowNormal =
  (recordedValues: ILocalDataEntryObjectInputParameterValue[]) =>
  (_: ILocalDataEntryObjectInputParameterValueData, rowIndex: number) => {
    return recordedValues[rowIndex];
  };

const findMatchingRowStatic =
  (recordedValues: ILocalDataEntryObjectInputParameterValue[], staticIdentifierColumnKey: string) =>
  (rowValue: ILocalDataEntryObjectInputParameterValueData, _: number) => {
    return recordedValues.find(
      (recordedValue) =>
        recordedValue.valuesPerKey[staticIdentifierColumnKey]?.value ===
        rowValue.valuesPerKey[staticIdentifierColumnKey]?.value,
    );
  };

interface IManageDataEntryObjectTableValueSettings {
  initialIsEditing?: boolean;
  inputParameter: IInputParameter;
  recordedValues: ILocalDataEntryObjectInputParameterValue[];
  /** Subscribe to changes of recordedValues DEFAULT: `true` */
  subscribeToRecordedValues?: boolean;
}

export type IManageDataEntryObjectTableValueHandleSaveCallback = (
  createdRows: ILocalDataEntryObjectInputParameterValueData[],
  updatedRows: ILocalDataEntryObjectInputParameterValue[],
  deleteRows: ILocalDataEntryObjectInputParameterValue[],
) => Promise<void>;

export const useManageDataEntryObjectTableValue = ({
  initialIsEditing,
  inputParameter,
  recordedValues,
  subscribeToRecordedValues = true,
}: IManageDataEntryObjectTableValueSettings) => {
  // General info
  // Unique Columns
  const uniqueColumnsConfiguration = useMemo(
    () => EsrsValueEditingUtilities.getUniqueColumns(inputParameter),
    [inputParameter],
  );
  // Static Table
  const isStaticTable = useMemo(
    () => EsrsValueEditingUtilities.isStaticTableInputParameter(inputParameter),
    [inputParameter],
  );
  const staticIdentifierColumnValueDefinition = useMemo(
    () => EsrsValueEditingUtilities.getStaticTableColumnDefinition(inputParameter),
    [inputParameter],
  );

  // State
  const [isEditing, setIsEditing] = useState(initialIsEditing ?? false);
  const handleStartEditing = useCallback(() => setIsEditing(true), []);

  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const showErrors = useMemo(() => triedSubmitting, [triedSubmitting]);

  // Helpers

  const sanitizeRowValue = useCallback(
    (valuesPerKey: ILocalDataEntryObjectInputParameterValueData["valuesPerKey"]) =>
      DataEntryObjectInputParameterValuesVerification.sanitizeValues(
        valuesPerKey,
        inputParameter.values,
      ),
    [inputParameter.values],
  );

  // Value Handling

  const createValuesForTable = useCallback(() => {
    // Static Table
    if (staticIdentifierColumnValueDefinition) {
      const staticColumnValues =
        staticIdentifierColumnValueDefinition.valueConfiguration.configuration.options;
      const defaultRowValues =
        DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(inputParameter);
      return staticColumnValues.map(
        (
          staticColumnValue,
        ):
          | ILocalDataEntryObjectInputParameterValueData
          | ILocalDataEntryObjectInputParameterValue => {
          const alreadyExistingValue = recordedValues.find(
            (recordedValue) =>
              recordedValue.valuesPerKey[staticIdentifierColumnValueDefinition.key]?.value ===
              staticColumnValue.value,
          );
          return (
            alreadyExistingValue ?? {
              ...defaultRowValues,
              valuesPerKey: {
                ...defaultRowValues.valuesPerKey,
                [staticIdentifierColumnValueDefinition.key]: {
                  type: "simple",
                  value: staticColumnValue.value,
                },
              },
            }
          );
        },
      );
    }
    // Default
    return recordedValues;
  }, [inputParameter, recordedValues, staticIdentifierColumnValueDefinition]);

  const [values, setValues] = useState<
    (ILocalDataEntryObjectInputParameterValue | ILocalDataEntryObjectInputParameterValueData)[]
  >(createValuesForTable());

  const actualTableValues = useMemo(() => {
    if (!staticIdentifierColumnValueDefinition) {
      return values;
    }

    return values.filter((value) =>
      Object.entries(value.valuesPerKey).some(
        ([key, value]) =>
          key !== staticIdentifierColumnValueDefinition.key && value?.value !== undefined,
      ),
    );
  }, [values, staticIdentifierColumnValueDefinition]);

  const handleValueChange = useCallback(
    (
      rowIndex: number,
      valueKey: string,
      newValue: IDataEntryObjectInputParameterValueValueForKey,
    ) => {
      setValues((prevValues) => {
        const newValues = [...prevValues];

        newValues[rowIndex] = {
          ...newValues[rowIndex],
          valuesPerKey: {
            ...newValues[rowIndex].valuesPerKey,
            [valueKey]: newValue,
          },
        };

        return newValues;
      });
    },
    [],
  );

  /** Beware this ONLY checks for actual values in the IP - not for date, note etc */
  const checkIfRowHasChanges = useCallback(
    (
      row: ILocalDataEntryObjectInputParameterValue | ILocalDataEntryObjectInputParameterValueData,
    ) => {
      // New rows always have changes
      if ((row as ILocalDataEntryObjectInputParameterValue).id === undefined) {
        return true;
      }
      const recordedRow = recordedValues.find(
        (recordedRow) => recordedRow.id === (row as ILocalDataEntryObjectInputParameterValue).id,
      );
      if (!recordedRow) {
        console.error(
          "Unexpected state encountered! Recorded row not found for row with id",
          (row as ILocalDataEntryObjectInputParameterValue).id,
        );
        return true;
      }

      const sanitizedRowValues = sanitizeRowValue(row.valuesPerKey);
      return Object.keys(sanitizedRowValues).some(
        (key) => sanitizedRowValues[key]?.value !== recordedRow.valuesPerKey[key]?.value,
      );
    },
    [recordedValues, sanitizeRowValue],
  );

  const hasChanges = useMemo(() => {
    if (actualTableValues.length !== recordedValues.length) {
      return true;
    }
    // Setup resolver for finding matching recorded value (to check for changes later)
    const findMatchingRecordedValue = staticIdentifierColumnValueDefinition
      ? findMatchingRowStatic(recordedValues, staticIdentifierColumnValueDefinition.key)
      : findMatchingRowNormal(recordedValues);
    // Check every row and value for changes
    return actualTableValues.some((rowValues, rowIndex) => {
      const recordedRowValues = findMatchingRecordedValue(rowValues, rowIndex);
      // If no matching row could be found always assume changes
      if (!recordedRowValues) {
        return true;
      }
      // Sanitize values and check for changes
      const sanitizedRowValues = sanitizeRowValue(rowValues.valuesPerKey);
      return Object.keys(sanitizedRowValues).some(
        (key) => rowValues.valuesPerKey[key]?.value !== recordedRowValues.valuesPerKey[key]?.value,
      );
    });
  }, [actualTableValues, recordedValues, staticIdentifierColumnValueDefinition, sanitizeRowValue]);

  // Subscribe to changes logic
  useEffect(() => {
    if (subscribeToRecordedValues && !isEditing) {
      setValues(createValuesForTable());
    }
  }, [subscribeToRecordedValues, createValuesForTable, isEditing]);

  // Error Handling

  const [rowsErrors, setRowsErrors] = useState<Partial<IValuesErrorsPerKey[]>>([]);
  const hasErrors = useMemo(
    () => rowsErrors.some((rowErrors) => rowErrors && Object.keys(rowErrors).length > 0),
    [rowsErrors],
  );

  const [duplicateValuesErrors, setDuplicateValuesErrors] = useState<IDuplicateValuesErrors>([]);

  const verifyAndSetRowErrors = useCallback(() => {
    const sanitizedRowsValues = actualTableValues.map((rowValues) =>
      sanitizeRowValue(rowValues.valuesPerKey),
    );
    // Validate Rows themselves
    const rowsErrors = sanitizedRowsValues.map((sanitizedValues) => {
      return DataEntryObjectValuesUtilities.getErrorsForValues(inputParameter, sanitizedValues);
    });
    setRowsErrors(rowsErrors);

    // Overall Table Validation
    let duplicatesResult: IDuplicateValuesErrors = [];
    if (uniqueColumnsConfiguration) {
      // Only check if configuration is present
      const validatorResult =
        DataEntryObjectInputParameterTableValuesVerification.determineDuplicateValues(
          uniqueColumnsConfiguration,
          inputParameter.values,
          sanitizedRowsValues.map((sanitizedRowValues) => ({ valuesPerKey: sanitizedRowValues })),
        );
      duplicatesResult = validatorResult.map((result) =>
        result.reduce(
          (acc, curr, idx) => ({
            ...acc,
            [uniqueColumnsConfiguration[idx]]: curr,
          }),
          {} as IDuplicateValueError,
        ),
      );
    }
    setDuplicateValuesErrors(duplicatesResult);

    return (
      duplicatesResult.length === 0 &&
      !rowsErrors.some((rowsErrors) => Object.keys(rowsErrors).length > 0)
    );
  }, [inputParameter, sanitizeRowValue, uniqueColumnsConfiguration, actualTableValues]);

  // Run Validation on every change when triedSubmitting
  useEffect(() => {
    if (triedSubmitting) {
      verifyAndSetRowErrors();
    }
  }, [triedSubmitting, verifyAndSetRowErrors]);

  // General Handling

  const handleAddRow = useCallback(() => {
    setValues((prevValues) => [
      ...prevValues,
      DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(inputParameter),
    ]);
    handleStartEditing();
  }, [handleStartEditing, inputParameter]);

  const handleDeleteRow = useCallback(
    (rowIndex: number) => {
      setIsEditing(true);
      setValues((prevValues) => {
        if (!staticIdentifierColumnValueDefinition) {
          return prevValues.filter((_, index) => index !== rowIndex);
        }

        return prevValues.map((value, index) => {
          if (index !== rowIndex) {
            return value;
          } else {
            const newValue =
              DataEntryObjectValuesUtilities.createEmptyValueFromInputParameter(inputParameter);
            return {
              ...newValue,
              valuesPerKey: {
                ...newValue.valuesPerKey,
                [staticIdentifierColumnValueDefinition.key]: {
                  type: "simple",
                  value: value.valuesPerKey[staticIdentifierColumnValueDefinition.key]?.value,
                },
              },
            };
          }
        });
      });
    },
    [inputParameter, staticIdentifierColumnValueDefinition],
  );

  const handleSubmit = useCallback(
    (cb: IManageDataEntryObjectTableValueHandleSaveCallback) => {
      return async () => {
        setTriedSubmitting(true);
        if (verifyAndSetRowErrors()) {
          // Find newly created rows
          const createdRows: ILocalDataEntryObjectInputParameterValueData[] = actualTableValues
            .filter((value) => (value as ILocalDataEntryObjectInputParameterValue).id === undefined)
            .map((value) => ({
              ...value,
              valuesPerKey: sanitizeRowValue(value.valuesPerKey),
            }));
          // Find updated rows
          const preExistingValues = actualTableValues.filter(
            (value) => (value as ILocalDataEntryObjectInputParameterValue).id !== undefined,
          ) as ILocalDataEntryObjectInputParameterValue[]; // This is fine since the check if an id exists is done above
          const updatedRows = preExistingValues.filter((value) => checkIfRowHasChanges(value));
          // Find deleted Rows
          const deletedRows = recordedValues.filter(
            (recordedRow) =>
              actualTableValues.findIndex(
                (value) =>
                  recordedRow.id === (value as ILocalDataEntryObjectInputParameterValue).id,
              ) === -1,
          );

          try {
            await cb(createdRows, updatedRows, deletedRows);
            setIsEditing(false);
            setTriedSubmitting(false);
          } catch (e) {
            // Nothing to do here since handled elsewhere
            // Expected when user cancels state transition due to state change
          }
        }
      };
    },
    [
      verifyAndSetRowErrors,
      actualTableValues,
      recordedValues,
      sanitizeRowValue,
      checkIfRowHasChanges,
    ],
  );

  const handleReset = useCallback(() => {
    setValues(createValuesForTable());
    setIsEditing(false);
    setTriedSubmitting(false);
  }, [createValuesForTable]);

  // Return Result

  return useMemo(
    () => ({
      state: {
        isEditing,
        values,
        rowsErrors: showErrors ? rowsErrors : [],
        duplicateValuesErrors: showErrors ? duplicateValuesErrors : [],
      },
      formState: {
        isStaticTable,
        staticIdentifierColumnValueDefinition,
        hasErrors: showErrors ? hasErrors : false,
        hasChanges,
      },
      handlers: {
        handleStartEditing,
        handleValueChange,
        handleAddRow,
        handleDeleteRow,
        handleSubmit,
        handleReset,
      },
    }),
    [
      isEditing,
      values,
      showErrors,
      rowsErrors,
      duplicateValuesErrors,
      isStaticTable,
      hasErrors,
      hasChanges,
      handleStartEditing,
      handleValueChange,
      handleAddRow,
      handleDeleteRow,
      handleSubmit,
      handleReset,
      staticIdentifierColumnValueDefinition,
    ],
  );
};
