import { FC, useCallback, useState } from "react";
import { Box, Button, Collapse } from "@mui/material";
import { useLexicalEditable } from "@lexical/react/useLexicalEditable";
import { useTranslation } from "react-i18next";
import { useListenToEditorUpdate } from "../hooks/listen-to-editor-updates.hook";
import { UpdateListener } from "lexical/LexicalEditor";
import { $getRoot, SerializedEditorState } from "lexical";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

interface ISaveAndCancelButtonsProps {
  onSave: (tree: SerializedEditorState) => Promise<void>;
  onCancel: () => void;
  disabled?: boolean;
}

export const SaveAndCancelButtons: FC<ISaveAndCancelButtonsProps> = ({
  onSave,
  onCancel,
  disabled,
}) => {
  const { t } = useTranslation("buttons");
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();

  const [hasContent, setHasContent] = useState(false);
  const handleEditorUpdate: UpdateListener = useCallback(({ editorState }) => {
    editorState.read(() => {
      const root = $getRoot();
      setHasContent(root.getAllTextNodes().some((node) => node.getTextContentSize() > 0));
    });
  }, []);
  useListenToEditorUpdate(handleEditorUpdate);

  const handleSave = useCallback(async () => {
    await onSave(editor.getEditorState().toJSON());
  }, [editor, onSave]);

  return (
    <Collapse in={isEditable} unmountOnExit timeout={200}>
      <Box display="flex" justifyContent="end" px={1} pt={1} gap={1}>
        <Button size="small" onClick={onCancel} disabled={disabled}>
          {t("cancel")}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleSave}
          disabled={!hasContent || disabled}
        >
          {t("save")}
        </Button>
      </Box>
    </Collapse>
  );
};
