import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import {
  IDataEntryObject,
  IInputParameterRecordingStructureGroupTHGSC,
} from "@netcero/netcero-core-api-client";
import { ArcElement, Chart, Tooltip } from "chart.js";
import { FC, useMemo } from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ChartColorPalette } from "../../../theme/charts-theme";
import { IconSize, PointFilledIcon } from "../../common/constants/tabler-icon.constants";
import { Predicate } from "../../common/interfaces/predicate.type";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { EvaluationFormatUtilities } from "../utilities/evaluation-format.utilities";
import { EvaluationUtilities } from "../utilities/evaluation.utilities";
import { EmissionsUtilities } from "./emissions.utilities";
import { GroupedCalculationResult } from "@netcero/netcero-common";

Chart.register(ArcElement);
Chart.register([Tooltip]);

interface ITotalEmissionsByChildDeoPieChartProps {
  generalResult: GroupedCalculationResult;
  currentDataEntryObject: IDataEntryObject;
  structure: IInputParameterRecordingStructureGroupTHGSC;
  onSelectChild: (idForClickEvent: string) => void;
  isChildSelectable?: Predicate<IDataEntryObject>;
}

export const TotalEmissionsByChildDeoPieChart: FC<ITotalEmissionsByChildDeoPieChartProps> = ({
  generalResult,
  currentDataEntryObject,
  structure,
  onSelectChild,
  isChildSelectable = () => true,
}) => {
  const { t } = useTranslation("total_emissions_pie_chart_component");
  const theme = useTheme();

  const emissionsSumsByDataEntryObject = useMemo(() => {
    // Calculate sum of root data entry object
    const ownResults = EvaluationUtilities.sumUpTotalsForDataEntryObjectAndRecordingStructureGroup(
      generalResult,
      currentDataEntryObject,
      structure,
      false, // Ignore children (they will be shown in pie chart)
    );
    const ownEmissionsSum = EmissionsUtilities.sumUpEmissionsResults(ownResults);

    const childrenSums = currentDataEntryObject.children.map((child) => {
      const results = EvaluationUtilities.sumUpTotalsForDataEntryObjectAndRecordingStructureGroup(
        generalResult,
        child,
        structure,
        true, // Include all children
      );
      const emissionsSum = EmissionsUtilities.sumUpEmissionsResults(results);

      return {
        idForClickEvent: isChildSelectable(child) ? child.id : null,
        dataEntryObject: child,
        emissionsSum,
      };
    });

    return [
      // Only add root when not 0
      ...(ownEmissionsSum !== 0
        ? [
            {
              idForClickEvent: null, // used for click event (parent does not do anything)
              dataEntryObject: currentDataEntryObject,
              emissionsSum: ownEmissionsSum,
            },
          ]
        : []),
      ...childrenSums,
    ];
  }, [generalResult, currentDataEntryObject, structure, isChildSelectable]);

  const totalEmissions = useMemo(() => {
    let sum = 0;

    emissionsSumsByDataEntryObject.forEach((entry) => {
      sum += entry.emissionsSum;
    });

    return sum;
  }, [emissionsSumsByDataEntryObject]);

  const percentages = useMemo(() => {
    return emissionsSumsByDataEntryObject.map((entry) => entry.emissionsSum / totalEmissions);
  }, [emissionsSumsByDataEntryObject, totalEmissions]);

  return (
    <Box display="flex" gap={4} justifyContent="center" flex={1} paddingX={4}>
      <Box width={260} height={260} alignSelf="stretch">
        {totalEmissions === 0 ? (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography>{t("empty")}</Typography>
          </Box>
        ) : (
          <Pie
            data={{
              labels: emissionsSumsByDataEntryObject.map((entry) => entry.dataEntryObject.name),
              datasets: [
                {
                  label: "",
                  data: emissionsSumsByDataEntryObject.map((entry) => entry.emissionsSum),
                  borderColor: theme.palette.divider,
                  backgroundColor: ChartColorPalette,
                },
              ],
            }}
            options={{
              onClick: (event, elements, chart) => {
                const elementIndex = elements[0]?.index;
                const id = emissionsSumsByDataEntryObject[elementIndex]?.idForClickEvent ?? null;

                if (id !== null) {
                  onSelectChild(id);
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  enabled: true,
                  callbacks: {
                    label(tooltipItem) {
                      return [
                        EvaluationFormatUtilities.formatCO2TonValueUnicode(
                          emissionsSumsByDataEntryObject[tooltipItem.dataIndex].emissionsSum,
                        ),
                        ` ${FormatUtilities.formatPercentage(
                          percentages[tooltipItem.dataIndex] * 100,
                        )}  %`,
                      ];
                    },
                  },
                },
              },
            }}
          />
        )}
      </Box>
      <Box alignSelf="start" flex={1}>
        <Table>
          <TableBody>
            {/* Categories Rows */}
            {emissionsSumsByDataEntryObject.map((entry, index) => (
              <TableRow key={index}>
                <TableCell width="100%">
                  <Box display="flex" gap={1}>
                    <SvgIcon sx={{ color: ChartColorPalette[index % ChartColorPalette.length] }}>
                      <PointFilledIcon size={IconSize.Medium} />
                    </SvgIcon>
                    <Typography
                      onClick={
                        entry.idForClickEvent !== null
                          ? () => onSelectChild(entry.idForClickEvent!)
                          : undefined
                      }
                      sx={{
                        cursor: entry.idForClickEvent !== null ? "pointer" : undefined,
                        ":hover":
                          entry.idForClickEvent !== null
                            ? { textDecoration: "underline" }
                            : undefined,
                      }}
                    >
                      {entry.dataEntryObject.name}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Typography whiteSpace="nowrap">
                    {EvaluationFormatUtilities.formatCO2TonValueOnly(entry.emissionsSum)}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Typography whiteSpace="nowrap">
                    ({FormatUtilities.formatPercentage(percentages[index] * 100)} %)
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {/* Total Emissions Row */}
            <TableRow>
              <TableCell width="100%">
                <Typography fontWeight="bold">{t("total_emissions")}</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight="bold" textAlign="right" whiteSpace="nowrap">
                  {EvaluationFormatUtilities.formatCO2TonValueOnly(totalEmissions)}
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </Box>
  );
};
