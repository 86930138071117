import { IEvaluationsEnum } from "@netcero/netcero-core-api-client";
import { useMemo } from "react";
import { FormatUtilities } from "../../common/utilities/format.utilities";

interface IEvaluationLinkHookProps {
  organizationId: string;
  recordingPeriodId: string;
  evaluation: IEvaluationsEnum;
  recordingStructureId: string;
  dataEntryObjectId?: string;
}

export const useEvaluationLink = ({
  organizationId,
  recordingPeriodId,
  evaluation,
  recordingStructureId,
  dataEntryObjectId,
}: IEvaluationLinkHookProps) => {
  return useMemo(() => {
    const baseSegments = [
      "organizations",
      organizationId,
      "recording-periods",
      recordingPeriodId,
      "evaluations",
      evaluation,
      "recording-structures",
      recordingStructureId,
    ];

    if (dataEntryObjectId !== undefined) {
      baseSegments.push(...["data-entry-objects", dataEntryObjectId]);
    }

    return FormatUtilities.generateDynamicUrl(baseSegments);
  }, [organizationId, recordingPeriodId, evaluation, recordingStructureId, dataEntryObjectId]);
};
