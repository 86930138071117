import { IDMACategory } from "@netcero/netcero-core-api-client";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRenderDMACategoryName } from "../../hooks/render-dma-category-name.hook";
import { ConfirmDialogTextBody } from "../../../common/dialogs/variants/confirm.dialog";

interface IDMACategoryOptBackInConfirmDialogProps {
  open: boolean;
  category: IDMACategory | null;
  loading?: boolean;
  disabled?: boolean;
  error?: Error | null;
  onClose: (data: IDMACategory | null) => void;
}

export const DMACategoryOptBackInConfirmDialog: FC<IDMACategoryOptBackInConfirmDialogProps> = ({
  open,
  category,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("dma_category_opt_back_in_confirm_dialog");
  const renderName = useRenderDMACategoryName();

  return (
    <ConfirmDialogTextBody
      open={open}
      loading={loading}
      error={error}
      title={t("title")}
      content={t("content", { name: renderName(category) })}
      confirmOverride={t("button_confirm")}
      onClose={(confirm) => onClose(confirm ? category : null)}
      disabled={disabled}
    />
  );
};
