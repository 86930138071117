import { FieldValues } from "react-hook-form";
import { IMDRNumberInput } from "../../mdr-input.types";
import { useCallback, useMemo } from "react";
import { Box, InputAdornment, TextField, Tooltip } from "@mui/material";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";
import { NumberUtilities } from "@netcero/netcero-common";

export function NumberInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
}: IMDRControlledInputProps<T, IMDRNumberInput>) {
  const numberProps = useMemo(
    () => ({
      type: "number",
      inputProps: {
        step: valueMetaData.precision !== undefined ? Math.pow(10, -valueMetaData.precision) : 0,
        min: valueMetaData.min,
        max: valueMetaData.max,
      },
    }),
    [valueMetaData.min, valueMetaData.max, valueMetaData.precision],
  );

  const endAdornment = useMemo(() => {
    switch (valueMetaData.numberType) {
      case "percentage":
        return <InputAdornment position="end">%</InputAdornment>;
      default:
        return null;
    }
  }, [valueMetaData.numberType]);

  const handleChange = useCallback(
    (value: string) => {
      // Emit empty
      if (value === "") {
        field.onChange(null);
        return;
      }
      const number =
        valueMetaData.precision !== undefined
          ? // Handle precision - we know this is a value since value is not "" (previous check)
            +NumberUtilities.extractNumberFromStringWithPrecision(value, valueMetaData.precision)!
          : +value;
      // Handle Percentage
      if (valueMetaData.numberType === "percentage") {
        // Do NOT forward events with invalid (out of range) values
        if (number > 100 || number < 0) {
          field.onChange(field.value);
          return;
        }
      }
      // Normal
      field.onChange(number);
    },
    [field, valueMetaData.numberType, valueMetaData.precision],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        pl={valueMetaData.isChild || isConditional ? 6 : 0}
      >
        <TextField
          {...field}
          value={field.value ?? ""}
          onChange={(evt) => handleChange(evt.currentTarget.value)}
          label={
            <Tooltip title={label}>
              <span style={{ fontWeight: valueMetaData.isParent ? "bold" : undefined }}>
                {label}
              </span>
            </Tooltip>
          }
          variant="outlined"
          error={!!error}
          helperText={error ? error.message : null}
          {...numberProps}
          InputProps={{ endAdornment }}
          sx={{ maxWidth: NON_FULL_WIDTH_WIDTH }}
        />
      </Box>
      {valueMetaData.isLastChild && <Box />}
    </>
  );
}
