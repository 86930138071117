import { useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";

// Interface to define the structure of the combined state returned by the hook
interface ICombinedQueryState {
  isLoading: boolean;
  isError: boolean;
  error?: Error | null;
}

/**
 * Custom hook to combine the loading, error, and error message states of multiple queries
 * @param queries - An array of query results to combine. Note: The size of the queries array should NOT change.
 */
export const useCombineQueryState = (queries: UseQueryResult[]): ICombinedQueryState => {
  // Determine if any of the queries are still loading
  const isLoading = useMemo(
    () => queries.some((query) => query.isLoading), // Returns true if at least one query is loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
    queries,
  );

  // Determine if any of the queries have encountered an error
  const isError = useMemo(
    () => queries.some((query) => query.isError), // Returns true if at least one query has an error
    // eslint-disable-next-line react-hooks/exhaustive-deps
    queries,
  );

  // Get the first error object if any query has encountered an error, otherwise null
  const error = useMemo(
    () => (isError ? queries.find((query) => query.isError)?.error : null), // Only look for an error if isError is true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isError, ...queries],
  );

  // Return the combined state object
  return { isLoading, isError, error };
};
