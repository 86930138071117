import { useKeycloakUserApiClient } from "./hooks/keycloak-user-api.hook";
import { useMutation } from "@tanstack/react-query";
import { updateLocale } from "@netcero/keycloak-common";
import { useInvalidateQueriesByKeys } from "../common/hooks/invalidate-queries-by-keys.hook";
import { getUserAccountQueryKey } from "./user.queries";
import { useMemo } from "react";
import { AVAILABLE_LANGUAGE_KEY } from "@netcero/netcero-common";
import type { UseMutationOptions } from "@tanstack/react-query/src/types";

interface IUpdateUserLocaleMutationData {
  locale: AVAILABLE_LANGUAGE_KEY;
}

export const useUpdateUserLocaleMutation = (
  mutationOptions?: Pick<UseMutationOptions, "retry">,
) => {
  const keycloakClient = useKeycloakUserApiClient();
  const invalidateQueries = useInvalidateQueriesByKeys();
  const updateUserLocaleMutation = useMutation({
    mutationFn: ({ locale }: IUpdateUserLocaleMutationData) =>
      keycloakClient
        ? updateLocale(keycloakClient, locale)
        : Promise.reject("Keycloak client is not ready"),
    onSuccess: () => invalidateQueries({ awaitQueryKeys: [getUserAccountQueryKey()] }),
    ...mutationOptions,
  });

  return useMemo(
    () => ({
      isReady: !!keycloakClient,
      updateUserLocaleMutation,
    }),
    [keycloakClient, updateUserLocaleMutation],
  );
};
