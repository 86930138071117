import { LookUp, UUID } from "../common.public-types";

export const generateLookUp = <T extends object, K extends string | number | symbol>(
  entities: T[],
  idGetter: (entity: T) => K,
) => {
  return entities.reduce((acc, curr) => {
    acc[idGetter(curr)] = curr;
    return acc;
  }, {} as Record<K, T>); // TODO: Use Partial<> to handle undefined values (not always complete)
};

export const generateEntityLookUp = <T extends { id: UUID }>(entities: T[]) => {
  return entities.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {} as LookUp<T>); // TODO: Use Partial<LookUp<T>> instead of LookUp<T> to handle undefined values
};
