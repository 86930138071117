import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useMemo } from "react";

export const useIsError = <TError>(
  ...values: UseMutationResult<unknown, TError>[] | UseQueryResult<unknown, TError>[]
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isError = useMemo(() => values.some((m) => m.isError), [...values.map((m) => m.isError)]);
  const error = useMemo(
    () => (values as { isError: boolean; error: TError | null }[]).find((m) => m.isError)?.error,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...values.map((m) => m.error)],
  );

  return {
    isError,
    error,
  };
};
