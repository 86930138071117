import { FC, useCallback, useMemo } from "react";
import {
  IBaseTargetData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ITarget,
} from "@netcero/netcero-core-api-client";
import { DataEntryObjectInputParameterValueDefinitionForTarget } from "@netcero/netcero-common";
import { useTranslation } from "react-i18next";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { TargetEditDialog } from "../target-edit.dialog";
import {
  useCreateTargetMutation,
  useDeleteTargetMutation,
  useUpdateTargetMutation,
} from "../targets.mutations";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";
import { ConfirmDialog } from "../../common/dialogs/variants/confirm.dialog";
import {
  IMdrEntityData,
  MdrEntityTable,
} from "../../minimum-disclosure-requirements-common/components/mdr-entity-table.component";

interface ITargetsTableProps {
  targets: ITarget[];
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForTarget) => void;
  disabled?: boolean;
}

export const TargetsTable: FC<ITargetsTableProps> = ({
  targets,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_target_component");

  const targetsEntitiesData: IMdrEntityData<ITarget>[] = useMemo(
    () =>
      targets.map((target) => ({
        id: target.id,
        title: target.general.name,
        responsibleUserId: target.responsibleUserId,
        data: target,
      })),
    [targets],
  );

  const createTargetMutation = useCreateTargetMutation();
  const updateTargetMutation = useUpdateTargetMutation();
  const deleteTargetMutation = useDeleteTargetMutation();

  const {
    isOpen: showCreateDialog,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();
  useResetMutationsOnOpen(showCreateDialog, createTargetMutation);

  const {
    isOpen: showEditDialog,
    data: editDialogData,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
  } = useDialogState<ITarget>();
  useResetMutationsOnOpen(showEditDialog, updateTargetMutation);

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialogDialog,
    closeDialog: closeDeleteDialog,
    data: deleteTarget,
  } = useDialogState<ITarget>();
  useResetMutationsOnOpen(isDeleteDialogOpen, deleteTargetMutation);

  const handleCloseCreateDialog = useCallback(
    async (data: IBaseTargetData | null) => {
      if (data) {
        await createTargetMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          payload: data,
        });
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createTargetMutation, dataEntryObjectId, organizationId, recordingPeriodId],
  );

  const handleCloseEditDialog = useCallback(
    async (data: IBaseTargetData | null) => {
      // Cancel if no data
      if (!editDialogData) {
        return;
      }

      if (data) {
        await updateTargetMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          targetId: editDialogData?.id ?? "",
          payload: data,
        });
      }
      closeEditDialog();
    },
    [
      closeEditDialog,
      dataEntryObjectId,
      editDialogData,
      organizationId,
      recordingPeriodId,
      updateTargetMutation,
    ],
  );

  const handleDelete = useCallback(
    async (confirm: boolean | undefined) => {
      if (!deleteTarget || !confirm) {
        closeDeleteDialog();
        return;
      }
      await deleteTargetMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        targetId: deleteTarget.id,
      });
      closeDeleteDialog();
      closeEditDialog();
    },
    [
      closeDeleteDialog,
      closeEditDialog,
      dataEntryObjectId,
      deleteTarget,
      deleteTargetMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  return (
    <>
      {/* Create Dialog */}
      <TargetEditDialog
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
        open={showCreateDialog}
        topicIdentifier={topicIdentifier}
        onClose={handleCloseCreateDialog}
        loading={createTargetMutation.isPending}
        error={createTargetMutation.error}
        disabled={createTargetMutation.isPending}
      />
      {/* Edit Dialog */}
      <TargetEditDialog
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
        target={editDialogData}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
        onDelete={openDeleteDialogDialog}
        topicIdentifier={topicIdentifier}
        loading={updateTargetMutation.isPending}
        error={updateTargetMutation.error}
        disabled={updateTargetMutation.isPending}
      />
      {/* Confirm Delete Dialog */}
      <ConfirmDialog
        open={isDeleteDialogOpen}
        error={deleteTargetMutation.error}
        loading={deleteTargetMutation.isPending}
        disabled={deleteTargetMutation.isPending}
        onClose={(confirm: boolean) => handleDelete(confirm)}
        title={t("confirm_dialog.title")}
        content={t("confirm_dialog.body")}
        confirmOverride={t("confirm_dialog.button_confirm")}
      />

      {/* Content */}
      <MdrEntityTable
        organizationId={organizationId}
        entities={targetsEntitiesData}
        onEdit={(entity) => openEditDialog(entity.data)}
        noEntitiesPresentNotice={t("no_targets_defined")}
        buttonChooseNoEntitiesForTopicText={t("button_choose_no_targets_for_topic")}
        onChooseNoEntitiesForTopic={() => onChange(false)}
        tooltipAddNew={t("tooltip_add_target")}
        onAddNew={openCreateDialog}
        disabled={disabled}
      />
    </>
  );
};
