import { FC, useMemo } from "react";
import { Box, FormLabel, InputAdornment, Switch, TextField, Typography } from "@mui/material";
import { Control, Controller, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IInternalDMAConfigurationData, StepIndex } from "../dma-configuration.component";
import { ORDERED_DMA_PROBABILITY_SLIDER_VALUES } from "./dma.constants";
import { MaskedInput } from "../../common/components/masked-input.component";
import { IDMAConfigurationProbabilityOfOccurrenceSteps } from "@netcero/netcero-core-api-client";
import Decimal from "decimal.js-light";

const toUpper = (
  type: IDMAProbabilityOfOccurrenceComponentProps["type"],
): Capitalize<IDMAProbabilityOfOccurrenceComponentProps["type"]> => {
  return (type.charAt(0).toUpperCase() + type.slice(1)) as Capitalize<
    IDMAProbabilityOfOccurrenceComponentProps["type"]
  >;
};

interface IDMAProbabilityOfOccurrenceComponentProps {
  type: "material" | "financial";
  control: Control<IInternalDMAConfigurationData>;
  disabled?: boolean;
}

export const DMAProbabilityOfOccurrenceComponent: FC<IDMAProbabilityOfOccurrenceComponentProps> = ({
  type,
  control,
  disabled,
}) => {
  const { t } = useTranslation("dma_configuration");
  const name = useMemo(
    () =>
      `defineProbabilityOfOccurrenceSteps${toUpper(type)}` as
        | "defineProbabilityOfOccurrenceStepsFinancial"
        | "defineProbabilityOfOccurrenceStepsMaterial",
    [type],
  );

  const shouldShowSteps = useWatch({
    control,
    exact: true,
    name,
  });

  return (
    <>
      <Typography variant="h6" component="span" mt={2}>
        {t(`title_probability_of_occurrence_steps_${type}`)}
      </Typography>
      <Typography>{t(`description_probability_of_occurrence_steps_${type}`)}</Typography>

      <Box display="flex" alignItems="center" gap={2}>
        <FormLabel>{t("label_set_probability_of_occurrence_steps")}</FormLabel>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <Switch
              checked={field.value}
              onChange={(_, checked) => field.onChange(checked)}
              disabled={disabled}
            />
          )}
        />
      </Box>

      {shouldShowSteps &&
        [1, 2, 3, 4, 5].map((stepIndex) => (
          <ProbabilityOfOccurrenceInput
            key={stepIndex}
            control={control}
            stepIndex={stepIndex as StepIndex}
            disabled={disabled}
            type={type}
          />
        ))}
    </>
  );
};

interface IProbabilityOfOccurrenceInputProps {
  control: Control<IInternalDMAConfigurationData>;
  stepIndex: StepIndex;
  disabled?: boolean;
  type: IDMAProbabilityOfOccurrenceComponentProps["type"];
}

const ProbabilityOfOccurrenceInput: FC<IProbabilityOfOccurrenceInputProps> = ({
  control,
  stepIndex,
  disabled,
  type,
}) => {
  const { t } = useTranslation("dma_configuration");

  const correspondingSliderValue = useMemo(
    () => ORDERED_DMA_PROBABILITY_SLIDER_VALUES[stepIndex - 1],
    [stepIndex],
  );

  return (
    <Controller
      control={control}
      name={`probabilityOfOccurrenceSteps${toUpper(type)}.probabilityOfOccurrenceStep${stepIndex}`}
      rules={{
        required: t("error_probabilityOfOccurrence_step_required"),
        min: {
          value: 0,
          message: t("error_probabilityOfOccurrence_step_min"),
        },
        max: {
          value: 100,
          message: t("error_probabilityOfOccurrence_step_max"),
        },
        validate: (value, formData) => {
          if (value === null) {
            return t("error_probabilityOfOccurrence_step_required");
          }

          if (stepIndex > 1) {
            const previousStepIndex = (stepIndex - 1) as 1 | 2 | 3 | 4;
            const previousStepValues = formData[
              `probabilityOfOccurrenceSteps${toUpper(type)}`
            ] as unknown as IDMAConfigurationProbabilityOfOccurrenceSteps;

            const previousStepValue =
              previousStepValues[`probabilityOfOccurrenceStep${previousStepIndex}`];

            if (value <= (previousStepValue ?? Number.MIN_SAFE_INTEGER)) {
              return t("error_probabilityOfOccurrence_step_not_ascending");
            }
          }

          return true;
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          label={t(`slider_value_${correspondingSliderValue}`, {
            ns: "dma_impact_or_effect_edit_dialog_common",
          })}
          {...field}
          value={field.value !== null ? (field.value * 100).toString() : ""}
          onChange={(value: unknown) => {
            const realValue = value as string;
            if (realValue === "") {
              field.onChange(null);
              return;
            }
            field.onChange(new Decimal(realValue).div(100).toNumber());
          }}
          error={!!error}
          helperText={error?.message}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: MaskedInput as any,
            inputProps: {
              mask: Number,
              scale: 0,
              min: 0,
              max: 100,
              unmask: true,
              eager: true,
              style: {
                textAlign: "right",
              },
            },
          }}
          sx={{ width: 260 }}
          disabled={disabled}
        />
      )}
    />
  );
};
