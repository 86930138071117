import { Box, Slider, SliderProps } from "@mui/material";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DMA_SLIDER_VALUES_NAMES, ORDERED_DMA_SLIDER_VALUES } from "./dma.constants";

const MARKS = ORDERED_DMA_SLIDER_VALUES.map((value) => ({
  value: value,
}));

type IDMASliderValueNameTranslationPrefix =
  | "slider_value_"
  | "slider_value_extent_"
  | "slider_value_scale_"
  | "slider_value_irreversibility_";

interface IDMASliderProps extends SliderProps {
  valueNameTranslationPrefix?: IDMASliderValueNameTranslationPrefix;
  translationNS?: string;
}
/**
 * @param valueNameTranslationPrefix The prefix for the translation key of the value name
 * Default: "slider_value_"
 * @param translationNS The namespace of the translations
 * @param props
 */
export const DMASlider: FC<IDMASliderProps> = ({
  valueNameTranslationPrefix = "slider_value_",
  translationNS = "dma_impact_or_effect_edit_dialog_common",
  ...props
}) => {
  const { t } = useTranslation(translationNS);

  const getValueNameTranslationKey = useCallback(
    (value: number) => {
      const valueName = DMA_SLIDER_VALUES_NAMES[value as keyof typeof DMA_SLIDER_VALUES_NAMES];
      return valueName ? `${valueNameTranslationPrefix}${valueName}` : "ERROR";
    },
    [valueNameTranslationPrefix],
  );

  return (
    <Box flex={1} display="flex" alignItems="center" px={2}>
      <Slider
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => t(getValueNameTranslationKey(value))}
        min={ORDERED_DMA_SLIDER_VALUES[0]}
        max={ORDERED_DMA_SLIDER_VALUES[ORDERED_DMA_SLIDER_VALUES.length - 1]}
        marks={MARKS}
        step={null}
        {...props}
      />
    </Box>
  );
};
