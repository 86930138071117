import { ILocalReferenceBudget } from "../interfaces/reference-budgets.interfaces";
import { IReferenceBudget } from "@netcero/netcero-core-api-client";

export class ReferenceBudgetMappingUtilities {
  public static convertApiReferenceBudgetToLocal(
    apiReferenceBudget: IReferenceBudget,
  ): ILocalReferenceBudget {
    return {
      ...apiReferenceBudget,
      valuesFetchedAt: new Date(apiReferenceBudget.valuesFetchedAt),
    };
  }
}
