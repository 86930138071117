import { Box, CircularProgress, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorTextComponent } from "../../modules/common/components/error-text.component";
import { ISideBarConfiguration } from "../../modules/common/components/side-bar.component";
import { useCustomSideBarContent } from "../../modules/common/contexts/side-bar.context";
import { useOrganizationSideBarNavItems } from "../../modules/common/hooks/use-organization-side-bar-nav-items.hook";
import { useCurrentOrganization } from "../../modules/organizations/use-current-organization.hook";
import { useRecordingPeriodsQuery } from "../../modules/recording-periods/recording-periods.queries";
import { useCurrentRecordingPeriod } from "../../modules/recording-periods/use-current-recording-period.hook";
import { useRecordingPeriodOrganizationStructureQuery } from "../../modules/organization-structures/organization-structures.queries";
import { AppliedDistributionCriteriaConfigurationComponent } from "../../modules/applied-distribution-criteria/applied-distribution-criteria-configuration.component";
import { useAppliedDistributionCriteriaQuery } from "../../modules/applied-distribution-criteria/applied-distribution-criteria.queries";

interface IAppliedDistributionCriteriaConfigurationPageProps {}

export const AppliedDistributionCriteriaConfigurationPage: FC<
  IAppliedDistributionCriteriaConfigurationPageProps
> = () => {
  const { t } = useTranslation("applied_distribution_criteria_configuration_page");
  const navigate = useNavigate();

  const { organizationId, recordingPeriodId } = useParams();

  const currentOrganization = useCurrentOrganization();
  const currentRecordingPeriod = useCurrentRecordingPeriod();

  const recordingPeriodsQuery = useRecordingPeriodsQuery(organizationId!);

  const sideBarOrganizationNavItems = useOrganizationSideBarNavItems();
  const sideBarConfiguration = useMemo<ISideBarConfiguration>(
    () => ({
      organizationSelect: organizationId
        ? {
            organizationId: organizationId,
            onChange: (orgId) => {
              navigate(`/organizations/${orgId}/configuration/distribution-criteria`);
            },
          }
        : undefined,
      navigationItems: {
        activePath: `/organizations/${organizationId}/configuration/distribution-criteria`,
        items: sideBarOrganizationNavItems,
      },
    }),
    [sideBarOrganizationNavItems, organizationId, navigate],
  );
  useCustomSideBarContent(sideBarConfiguration);

  const organizationStructureQuery = useRecordingPeriodOrganizationStructureQuery(
    organizationId!,
    recordingPeriodId!,
  );

  const appliedDistributionCriteriaQuery = useAppliedDistributionCriteriaQuery(
    organizationId!,
    recordingPeriodId!,
  );

  return (
    <Box width={1600} maxWidth="100%" mx="auto" p={4}>
      <Typography variant="h1" mb={2}>
        {t("title")}
      </Typography>
      {organizationStructureQuery.isLoading ||
      recordingPeriodsQuery.isLoading ||
      appliedDistributionCriteriaQuery.isLoading ||
      currentOrganization === null ||
      currentRecordingPeriod === null ? (
        <CircularProgress /> // TODO: Add loading skeleton
      ) : organizationStructureQuery.isError ||
        recordingPeriodsQuery.isError ||
        appliedDistributionCriteriaQuery.isError ? (
        <ErrorTextComponent
          error={
            (organizationStructureQuery.error ||
              recordingPeriodsQuery.error ||
              appliedDistributionCriteriaQuery.error)!
          }
        />
      ) : (
        <AppliedDistributionCriteriaConfigurationComponent
          organization={currentOrganization!}
          recordingPeriods={recordingPeriodsQuery.data!.recordingPeriods}
          selectedRecordingPeriod={currentRecordingPeriod!}
          organizationStructure={organizationStructureQuery.data!}
          onChangeRecordingPeriod={(recordingPeriod) => {
            navigate(
              `/organizations/${organizationId}/configuration/recording-periods/${recordingPeriod.id}/distribution-criteria`,
            );
          }}
          appliedDistributionCriteria={appliedDistributionCriteriaQuery.data!.criteria}
        />
      )}
    </Box>
  );
};
