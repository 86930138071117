import { Breadcrumbs, Typography, Link } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

interface IUserPageBreadcrumbsProps {
  organizationId: string;
}

export const UserPageBreadcrumbs: FC<IUserPageBreadcrumbsProps> = ({ organizationId }) => {
  const { t } = useTranslation("side_bar_nav_items");
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="text.secondary"
        underline="hover"
        typography="body2"
        to={`/organizations/${organizationId}/configuration`}
        component={RouterLink}
      >
        {t("configuration")}
      </Link>
      <Typography variant="body2">{t("configuration_users")}</Typography>
    </Breadcrumbs>
  );
};
