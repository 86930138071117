import { IBaseTargetPathGoalData } from "@netcero/netcero-core-api-client";
import { ITargetPathIdentity } from "../../target-paths/mutations/target-paths.mutations";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";
import { getTargetPathsQueryKey } from "../../target-paths/queries/target-paths.queries";

export interface ITargetPathGoalIdentity extends ITargetPathIdentity {
  targetPathGoalId: string;
}

interface ICreateTargetPathGoalData extends ITargetPathIdentity {
  payload: IBaseTargetPathGoalData;
}

export const useCreateTargetPathGoalMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, targetPathId, payload }: ICreateTargetPathGoalData) => {
      return CoreApiService.TargetPathGoalsApi.createTargetPathGoal(
        organizationId,
        targetPathId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};

interface IUpdateTargetPathGoalData extends ITargetPathGoalIdentity {
  payload: IBaseTargetPathGoalData;
}

export const useUpdateTargetPathGoalMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      targetPathId,
      targetPathGoalId,
      payload,
    }: IUpdateTargetPathGoalData) => {
      return CoreApiService.TargetPathGoalsApi.updateTargetPathGoal(
        organizationId,
        targetPathId,
        targetPathGoalId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};

type IDeleteTargetPathGoalData = ITargetPathGoalIdentity;

export const useDeleteTargetPathGoalMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      targetPathId,
      targetPathGoalId,
    }: IDeleteTargetPathGoalData) => {
      return CoreApiService.TargetPathGoalsApi.deleteTargetPathGoal(
        organizationId,
        targetPathId,
        targetPathGoalId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getTargetPathsQueryKey(variables.organizationId),
      });
    },
  });
};
