import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IBaseStakeholderData } from "@netcero/netcero-core-api-client";
import { getStakeholdersQueryKey } from "./stakeholder.queries";

interface ICreateStakeholderMutationData {
  organizationId: string;
  payload: IBaseStakeholderData;
}

/**
 * Mutation to create a new stakeholder
 */
export const useCreateStakeholderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: ICreateStakeholderMutationData) => {
      return CoreApiService.StakeholdersApi.createStakeholder(data.organizationId, data.payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getStakeholdersQueryKey(variables.organizationId),
      });
    },
  });
};

interface IUpdateStakeholderMutationData {
  organizationId: string;
  stakeholderId: string;
  payload: IBaseStakeholderData;
}

/**
 * Mutation to update a stakeholder
 */
export const useUpdateStakeholderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IUpdateStakeholderMutationData) => {
      return CoreApiService.StakeholdersApi.updateStakeholder(
        data.organizationId,
        data.stakeholderId,
        data.payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getStakeholdersQueryKey(variables.organizationId),
      });
    },
  });
};

interface IDeleteStakeholderMutationData {
  organizationId: string;
  stakeholderId: string;
}

/**
 * Mutation to delete a stakeholder
 */
export const useDeleteStakeholderMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: IDeleteStakeholderMutationData) => {
      return CoreApiService.StakeholdersApi.deleteStakeholder(
        data.organizationId,
        data.stakeholderId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getStakeholdersQueryKey(variables.organizationId),
      });
    },
  });
};
