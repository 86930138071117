import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { RecordingPeriodsUtilities } from "./recording-periods.utilities";

export const getRecordingPeriodsQueryKey = (organizationId: string) => [
  "recordingPeriods",
  organizationId,
];
export const useRecordingPeriodsQuery = (
  organizationId: string | undefined,
  includeRecordingStructures: boolean = false,
) => {
  return useQuery({
    queryKey: getRecordingPeriodsQueryKey(organizationId!),
    queryFn: () =>
      CoreApiService.RecordingPeriodApi.getAllRecordingPeriods(
        organizationId!,
        includeRecordingStructures,
      )
        .then((req) => req())
        .then((res) =>
          RecordingPeriodsUtilities.convertAllRecordingPeriodsResponseToLocal(res.data),
        ),
    enabled: !!organizationId,
  });
};
