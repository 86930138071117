import { Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { ITranslatableError, useTranslateError } from "../hooks/translate-error.hook";

interface IErrorTextComponentProps {
  error: ITranslatableError;
}

export const ErrorTextComponent: FC<IErrorTextComponentProps> = ({ error }) => {
  const translateError = useTranslateError();

  const errorText = useMemo(() => translateError(error), [error, translateError]);

  return (
    <Typography variant="body1" color="error">
      {errorText}
    </Typography>
  );
};
