import { Box, IconButton, Tooltip } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CalendarMinusIcon } from "../../common/constants/tabler-icon.constants";
import { ILocalRecordingPeriod } from "../../recording-periods/recording-periods.utilities";

export interface IDataEntryObjectValueDataRangeEditComponentProps {
  start: DateTime | null;
  end: DateTime | null;
  disabled?: boolean;
  recordingPeriod: ILocalRecordingPeriod;
  onStartChange: (value: DateTime | null) => void;
  onEndChange: (value: DateTime | null) => void;
  onStartError: (type: DateErrorType | null, minDate: DateTime, maxDate: DateTime) => void;
  onEndError: (type: DateErrorType | null, minDate: DateTime, maxDate: DateTime) => void;
  startError: string | null;
  endError: string | null;
  onRemoveBothDates: () => void;
}

export type DateErrorType = "invalidDate" | "minDate" | "maxDate";

export const DataEntryObjectValueDateRangeEditComponent: FC<
  IDataEntryObjectValueDataRangeEditComponentProps
> = ({
  end,
  start,
  disabled,
  recordingPeriod,
  onStartChange,
  onEndChange,
  onStartError,
  onEndError,
  startError,
  endError,
  onRemoveBothDates,
}) => {
  const { t } = useTranslation("data_entry_object_values_edit");

  const maxDateStart = useMemo(
    () => (end !== null ? end : DateTime.fromJSDate(recordingPeriod.endDate)),
    [end, recordingPeriod.endDate],
  );

  const minDateEnd = useMemo(
    () => (start !== null ? start : DateTime.fromJSDate(recordingPeriod.startDate)),
    [recordingPeriod.startDate, start],
  );

  return (
    <Box display="flex" gap={2} mr={1} alignItems="start">
      <Tooltip title={t("remove_date_tooltip")}>
        <IconButton onClick={onRemoveBothDates} sx={{ mt: 1 }}>
          <CalendarMinusIcon />
        </IconButton>
      </Tooltip>
      <DataEntryObjectValueDateEditComponent
        value={start}
        disabled={disabled}
        recordingPeriod={recordingPeriod}
        onChange={onStartChange}
        minDate={DateTime.fromJSDate(recordingPeriod.startDate)}
        maxDate={maxDateStart}
        onError={onStartError}
        dateError={startError ?? undefined}
      />
      <DataEntryObjectValueDateEditComponent
        value={end}
        disabled={disabled}
        recordingPeriod={recordingPeriod}
        onChange={onEndChange}
        minDate={minDateEnd}
        maxDate={DateTime.fromJSDate(recordingPeriod.endDate)}
        onError={onEndError}
        dateError={endError ?? undefined}
      />
    </Box>
  );
};

interface IDataEntryObjectValueDateEditComponentProps {
  value: DateTime | null;
  recordingPeriod: ILocalRecordingPeriod;
  minDate: DateTime;
  maxDate: DateTime;
  onChange: (value: DateTime | null) => void;
  disabled?: boolean;
  dateError?: string;
  onError: (type: DateErrorType | null, minDate: DateTime, maxDate: DateTime) => void;
}

const DataEntryObjectValueDateEditComponent: FC<IDataEntryObjectValueDateEditComponentProps> = ({
  value,
  onChange,
  disabled,
  dateError,
  minDate,
  maxDate,
  onError,
}) => (
  <DatePicker
    value={value}
    minDate={minDate}
    maxDate={maxDate}
    onChange={onChange}
    disabled={disabled}
    onError={(error, value) => {
      if (error === null || error === "minDate" || error === "maxDate" || error === "invalidDate") {
        onError(error, minDate, maxDate);
      }
    }}
    slotProps={{
      field: {},
      textField: {
        variant: "outlined",
        size: "medium",
        helperText: dateError,
        error: dateError !== undefined,
        sx: {
          width: 150,
        },
      },
      openPickerButton: {
        size: "medium",
      },
      openPickerIcon: {
        fontSize: "medium",
      },
    }}
  />
);
