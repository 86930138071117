import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePoliciesQuery } from "../policies.queries";
import { PoliciesTable } from "./policies-table.component";
import {
  DataEntryObjectInputParameterValueDefinitionForPolicy,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IPolicy,
} from "@netcero/netcero-core-api-client";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { MdrInputHandler } from "../../minimum-disclosure-requirements-common/components/mdr-input-handler.component";

interface IPoliciesListProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForPolicy>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForPolicy) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  disabled?: boolean;
}

export const PoliciesList: FC<IPoliciesListProps> = (props) => {
  const policiesQuery = usePoliciesQuery(props.organizationId, props.recordingPeriodId);

  return (
    <QueryWrapper
      centerLoader
      query={policiesQuery}
      build={(policies) => <PoliciesListInternal policies={policies} {...props} />}
    />
  );
};

interface IPoliciesListInternalProps extends IPoliciesListProps {
  policies: IPolicy[];
}

const PoliciesListInternal: FC<IPoliciesListInternalProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  policies,
  value,
  onChange,
  topicIdentifier,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_policy_component");

  const filteredPolicies = useMemo(
    () => policies.filter((policy) => policy.associatedTopics.includes(topicIdentifier)),
    [topicIdentifier, policies],
  );

  return (
    <MdrInputHandler
      value={value}
      onChange={onChange}
      buttonPresentText={t("present_policies")}
      buttonNotPresentText={t("no_present_policies")}
      noticeChosenToNotDefine={t("notice_chosen_to_not_adopt_policies")}
      buttonChooseToAdoptText={t("button_undo_no_policies_adopted")}
      disabled={disabled}
      mdrList={
        <PoliciesTable
          policies={filteredPolicies}
          topicIdentifier={topicIdentifier}
          organizationId={organizationId}
          recordingPeriodId={recordingPeriodId}
          dataEntryObjectId={dataEntryObjectId}
          rootDataEntryObjectId={rootDataEntryObjectId}
          onChange={onChange}
          disabled={disabled}
        />
      }
    />
  );
};
