import { FC, useMemo } from "react";
import { Chip, Tooltip } from "@mui/material";
import { IESRSTopicIdentifier } from "@netcero/netcero-core-api-client";
import { ESRSTopicUtilities, ORDERED_ESRS_TOPICS } from "@netcero/netcero-common";
import { useRenderESRSTopicNameForIdentifier } from "../../double-materiality-assessment/hooks/render-esrs-topic-name-for-identifier.hook";

interface IMdrEsrsTopicIndicatorProps {
  topic: IESRSTopicIdentifier;
}

export const MdrEsrsTopicIndicator: FC<IMdrEsrsTopicIndicatorProps> = ({ topic }) => {
  const renderTopicName = useRenderESRSTopicNameForIdentifier();

  return (
    <Tooltip
      title={`${ESRSTopicUtilities.convertTopicToDisplayTopic(topic)} - ${renderTopicName(topic)}`}
    >
      <Chip label={ESRSTopicUtilities.convertTopicToDisplayTopicShort(topic)} />
    </Tooltip>
  );
};

interface IMdrEsrsTopicIndicatorsProps {
  topics: IESRSTopicIdentifier[];
}

/**
 * Sorted List of MdrEsrsTopicIndicator chips
 * @param topics
 * @constructor
 */
export const MdrEsrsTopicIndicators: FC<IMdrEsrsTopicIndicatorsProps> = ({ topics }) => {
  const sortedTopics = useMemo(
    () => ORDERED_ESRS_TOPICS.filter((topic) => topics.includes(topic)),
    [topics],
  );
  return sortedTopics.map((topic) => <MdrEsrsTopicIndicator key={topic} topic={topic} />);
};
