export enum DMASliderValues {
  VeryLow = 0.2,
  RatherLow = 0.4,
  Average = 0.6,
  RatherHigh = 0.8,
  VeryHigh = 1.0,
}

export enum DefaultDMAProbabilitySliderValues {
  VeryLow = 0.1,
  RatherLow = 0.3,
  Average = 0.5,
  RatherHigh = 0.7,
  VeryHigh = 0.9,
}
