import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../../core-api/core-api.service";

export const getListValuesQueryKey = (organizationId: string) => ["listValues", organizationId];

export const useListValuesQuery = (organizationId: string) => {
  return useQuery({
    queryKey: getListValuesQueryKey(organizationId),
    queryFn: async () => {
      const req = await CoreApiService.ListValuesApi.getAllInputParameterValueListValues(
        organizationId,
      );
      const res = await req();
      return res.data;
    },
  });
};
