import {
  IHydratedInputParametersModelStructureThgTree,
  IHydratedInputParametersModelStructureTreeThgGroup,
} from "./input-parameter-recording-thg-structures.interfaces";
import { IInputParameterRecordingStructureGroupTHGSC } from "@netcero/netcero-core-api-client";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures.utilities";

export class InputParameterRecordingThgStructuresUtilities {
  /**
   * Removes empty recording groups from the given tree
   * @param tree The tree to remove the empty recording groups from
   * @param removeEmptyDisabledParameters Whether to remove empty disabled parameters
   * @returns Tree without empty recording groups
   */
  public static removeEmptyRecordingGroupsFromThgTree(
    tree: IHydratedInputParametersModelStructureThgTree,
    removeEmptyDisabledParameters: boolean,
  ): IHydratedInputParametersModelStructureThgTree {
    return {
      ...tree,
      structure:
        InputParameterRecordingThgStructuresUtilities.removeEmptyRecordingGroupsFromThgGroup(
          tree.structure,
          removeEmptyDisabledParameters,
        ),
    };
  }

  /**
   * Remove empty recording groups from the given group (recursively - also removes empty child groups)
   * @param group Group to remove the empty recording groups from
   * @param removeEmptyDisabledParameters Whether to remove empty disabled parameters
   * @returns Group without empty recording groups
   */
  private static removeEmptyRecordingGroupsFromThgGroup(
    group: IHydratedInputParametersModelStructureTreeThgGroup,
    removeEmptyDisabledParameters: boolean,
  ): IHydratedInputParametersModelStructureTreeThgGroup {
    const inputParameters = group.inputParameters.filter(
      (inputParameter) =>
        !(
          removeEmptyDisabledParameters &&
          inputParameter.recordedValues.length === 0 &&
          !inputParameter.isActive
        ),
    );

    return {
      ...group,
      inputParameters,
      children: group.children
        .map((childGroup) =>
          InputParameterRecordingThgStructuresUtilities.removeEmptyRecordingGroupsFromThgGroup(
            childGroup,
            removeEmptyDisabledParameters,
          ),
        )
        .filter(
          (childGroup) => childGroup.inputParameters.length > 0 || childGroup.children.length > 0,
        ),
    };
  }

  /**
   * Hydrates the given input parameters model structure tree with the given input parameters
   * @param inputParameterRecordingStructure The input parameters recording structure tree to hydrate
   * @param dataEntryObjectInputParameters The input parameters to hydrate the tree with
   * @returns The hydrated input parameters model structure tree
   */
  public static hydrateInputParameterRecordingStructureThgTree(
    inputParameterRecordingStructure: IInputParameterRecordingStructureGroupTHGSC,
    dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
  ) {
    const inputParameterEntryLookupMap =
      InputParameterRecordingStructuresUtilities.getInputParameterEntryLookupMap(
        dataEntryObjectInputParameters,
      );

    const usedInputParameterIds = new Set<string>();

    const result: IHydratedInputParametersModelStructureThgTree = {
      orphanInputParameters: [],
      structure:
        InputParameterRecordingThgStructuresUtilities.convertThgRecordingGroupToThgTreeNode(
          inputParameterRecordingStructure,
          inputParameterEntryLookupMap,
          usedInputParameterIds,
        ),
    };

    dataEntryObjectInputParameters.forEach((inputParameterEntry) => {
      if (!usedInputParameterIds.has(inputParameterEntry.inputParameter.id)) {
        result.orphanInputParameters.push(inputParameterEntry);
      }
    });

    return result;
  }

  /**
   * Recursively converts the given recording group to a tree node by adding input parameters and settings properties.
   * @param recordingGroup Current recording group to convert
   * @param inputParameterEntryLookupMap Lookup map to get the input parameters from
   * @param usedInputParameterIds Tracks the used input parameter ids and warns if an input parameter is used twice
   * @returns The converted tree node
   */
  private static convertThgRecordingGroupToThgTreeNode(
    recordingGroup: IInputParameterRecordingStructureGroupTHGSC,
    inputParameterEntryLookupMap: Map<string, ILocalDataEntryObjectInputParameter>,
    usedInputParameterIds: Set<string>,
  ): IHydratedInputParametersModelStructureTreeThgGroup {
    recordingGroup.inputParameterIds.forEach((id) => {
      if (usedInputParameterIds.has(id)) {
        console.warn(
          `Input parameter with id ${id} was found at least twice in input parameters model structure!`,
        );
      }
      usedInputParameterIds.add(id);
    });

    return {
      recordingGroupInfo: {
        name: recordingGroup.name,
        description: recordingGroup.description,
      },
      inputParameters: recordingGroup.inputParameterIds
        .map((id) => inputParameterEntryLookupMap.get(id))
        .filter((parameter) => parameter !== undefined) as ILocalDataEntryObjectInputParameter[],
      children: recordingGroup.children.map((childGroup) =>
        InputParameterRecordingThgStructuresUtilities.convertThgRecordingGroupToThgTreeNode(
          childGroup,
          inputParameterEntryLookupMap,
          usedInputParameterIds,
        ),
      ),
    };
  }
}
