import {
  IDataEntryObject,
  IDataEntryObjectBase,
  IDataEntryObjectDraft,
} from "@netcero/netcero-core-api-client";

export class OrganizationStructuresUtilities {
  /**
   * Will generate "draft ids" for the draft data entry objects.
   */
  public static convertDraftDataEntryObjectsIntoDataEntryObjects(
    draft: IDataEntryObjectDraft,
    idPrefix = "D",
  ): IDataEntryObject {
    let idCounter = 1;

    return {
      ...draft,
      id: idPrefix,
      children: draft.children.map((child) =>
        OrganizationStructuresUtilities.convertDraftDataEntryObjectsIntoDataEntryObjects(
          child,
          `${idPrefix}_${idCounter++}`,
        ),
      ),
    };
  }

  /**
   * Strips all non API properties from the given tree.
   * @param tree DataEntryObject Tree to transform
   * @returns Transformed DataEntryObject Tree
   */
  public static transformTreeToApiPayload(tree: IDataEntryObjectDraft): IDataEntryObjectDraft {
    return {
      name: tree.name,
      description: tree.description,
      objectType: tree.objectType,
      inheritsValuesFrom: tree.inheritsValuesFrom,
      operationalControl: tree.operationalControl,
      financiallyConsolidated: tree.financiallyConsolidated,
      partOfValueChain: tree.partOfValueChain,
      country: tree.country,
      shareHeldByParent: tree.shareHeldByParent,
      children: tree.children.map((child) =>
        OrganizationStructuresUtilities.transformTreeToApiPayload(child),
      ),
    };
  }

  /**
   * This method recursively works through the tree and updates the data entry object with the given id.
   * @param dataEntryObjectId The id of the data entry object to update
   * @param dataEntryObjectData The data to update the data entry object with
   * @param tree The tree to update
   * @returns The updated tree
   */
  public static updateDataEntryObjectInDraftTree(
    dataEntryObjectId: string,
    dataEntryObjectData: IDataEntryObjectBase,
    tree: IDataEntryObject,
  ): IDataEntryObject {
    if (tree.id === dataEntryObjectId) {
      return {
        ...tree,
        ...dataEntryObjectData,
      };
    }

    return {
      ...tree,
      children: tree.children.map((child) =>
        OrganizationStructuresUtilities.updateDataEntryObjectInDraftTree(
          dataEntryObjectId,
          dataEntryObjectData,
          child,
        ),
      ),
    };
  }

  /**
   * Recursively works through the tree and adds the given data entry object to the data entry object with the given id.
   * @param parentDataEntryObjectId The id of the data entry object to add the child to
   * @param childDataEntryObject The data entry object to add
   * @param tree The tree to update
   */
  public static addDataEntryObjectToDraftTree(
    parentDataEntryObjectId: string,
    childDataEntryObject: IDataEntryObjectDraft,
    tree: IDataEntryObject,
  ): IDataEntryObjectDraft {
    if (tree.id === parentDataEntryObjectId) {
      return {
        ...tree,
        children: [...tree.children, childDataEntryObject],
      };
    }

    return {
      ...tree,
      children: tree.children.map((child) =>
        OrganizationStructuresUtilities.addDataEntryObjectToDraftTree(
          parentDataEntryObjectId,
          childDataEntryObject,
          child,
        ),
      ),
    };
  }

  /**
   * This method recursively works through the tree and removes the data entry object with the given id. (including its children)
   * @param removeDataEntryObjectWithId The id of the data entry object to remove
   * @param tree The tree to update
   * @returns The updated tree
   */
  public static removeDataEntryObjectFromDraftTree(
    removeDataEntryObjectWithId: string,
    tree: IDataEntryObject,
  ): IDataEntryObject | null {
    if (tree.id === removeDataEntryObjectWithId) {
      return null;
    }

    return {
      ...tree,
      children: tree.children
        .map((child) =>
          OrganizationStructuresUtilities.removeDataEntryObjectFromDraftTree(
            removeDataEntryObjectWithId,
            child,
          ),
        )
        .filter((child) => child !== null) as IDataEntryObject[],
    };
  }

  /**
   * Moves the given data entry object to the given parent data entry object in the draft tree structure.
   * @param dataEntryObjectToMove The data entry object to move
   * @param newParentId The id of the new parent data entry object
   * @param tree The tree to update
   * @returns The updated tree
   */
  public static moveDataEntryObjectInDraftTree(
    dataEntryObjectToMove: IDataEntryObject,
    newParentId: string,
    tree: IDataEntryObject,
  ) {
    const treeWithoutDataEntryObjectToMove =
      OrganizationStructuresUtilities.removeDataEntryObjectFromDraftTree(
        dataEntryObjectToMove.id,
        tree,
      );

    if (!treeWithoutDataEntryObjectToMove) {
      throw new Error("Could not find data entry object in tree!");
    }

    return OrganizationStructuresUtilities.addDataEntryObjectToDraftTree(
      newParentId,
      dataEntryObjectToMove,
      treeWithoutDataEntryObjectToMove,
    );
  }

  /**
   * Create a new draft from the given (currently active) tree.
   * Will set the inheritsValuesFrom to the id of the current data entry object and clone all other properties.
   * @param tree The tree to create a new draft from
   * @returns The new draft
   */
  public static createNewDraftFromTree(tree: IDataEntryObject): IDataEntryObjectDraft {
    return {
      ...tree,
      inheritsValuesFrom: [tree.id], // Set the inheritsValuesFrom to the id of the data entry object itself
      children: tree.children.map((child) =>
        OrganizationStructuresUtilities.createNewDraftFromTree(child),
      ),
    };
  }

  /**
   * Whether the given data entry object is a child of the given data entry object. (recursively to any depth)
   * @param parent The parent data entry object
   * @param childId The id of the child data entry object to be checked
   * @returns Whether the given data entry object is a child of the given data entry object.
   */
  public static isDataEntryObjectChildOfDataEntryObject(
    parent: IDataEntryObject,
    childId: string,
  ): boolean {
    if (parent.id === childId) {
      return true;
    }

    return parent.children.some((child) =>
      OrganizationStructuresUtilities.isDataEntryObjectChildOfDataEntryObject(child, childId),
    );
  }

  /**
   * Whether the given data entry object is a direct child of the given data entry object.
   * @param parent The parent data entry object
   * @param childId The id of the child data entry object to be checked if it is a direct child
   * @returns Whether the given data entry object is a direct child of the given data entry object.
   */
  public static isDataEntryObjectDirectChildOfDataEntryObject(
    parent: IDataEntryObject,
    childId: string,
  ): boolean {
    return parent.children.some((child) => child.id === childId);
  }
}
