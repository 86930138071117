import {
  useCloneTargetPathMutation,
  useCreateTargetPathMutation,
  useDeleteTargetPathMutation,
  useToggleTargetPathMutation,
  useUpdateTargetPathMutation,
} from "../mutations/target-paths.mutations";
import { useCallback } from "react";
import {
  useDialogState,
  useDialogStateWithoutData,
  useWrapOpenDialogHandlerWithReset,
} from "../../common/dialogs/dialog-state.hook";
import {
  IBaseTargetPathData,
  IBaseTargetPathDataWithoutReferenceBudget,
  IOrganization,
  ITargetPath,
  ITargetPathState,
} from "@netcero/netcero-core-api-client";
import { TargetPathCloneDialog } from "../components/target-path-clone-dialog.component";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useTranslation } from "react-i18next";
import { SetTargetPathAsOrganizationTargetPathConfirmDialogContentComponent } from "../components/set-target-path-as-organization-target-path-confirm-dialog-content.component";
import { TargetPathEditDialog } from "../components/target-path-edit.dialog";
import { ILocalReferenceBudget } from "../../reference-budgets/interfaces/reference-budgets.interfaces";

interface IEditDialogState {
  targetPath: ITargetPath;
}

interface IToggleDialogState extends IEditDialogState {
  currentOrganizationTargetPath: ITargetPath | null;
}

type IDeleteDialogState = IEditDialogState;
type ICloneDialogState = IEditDialogState;

export const useTargetPathDialogs = (
  organization: IOrganization,
  referenceBudgets: ILocalReferenceBudget[],
  targetPaths: ITargetPath[],
  onMutationSuccess?: {
    onCloneMutationSuccess?: (targetPath: ITargetPath) => void;
  },
) => {
  const { t } = useTranslation("target_path_dialogs");

  // Mutations
  const createTargetPathMutation = useCreateTargetPathMutation();
  const updateTargetPathMutation = useUpdateTargetPathMutation();
  const deleteTargetPathMutation = useDeleteTargetPathMutation();
  const toggleTargetPathMutation = useToggleTargetPathMutation();
  const cloneTargetPathMutation = useCloneTargetPathMutation(
    onMutationSuccess?.onCloneMutationSuccess,
  );

  const resetMutations = useCallback(() => {
    createTargetPathMutation.reset();
    updateTargetPathMutation.reset();
    deleteTargetPathMutation.reset();
    toggleTargetPathMutation.reset();
    cloneTargetPathMutation.reset();
  }, [
    createTargetPathMutation,
    deleteTargetPathMutation,
    toggleTargetPathMutation,
    updateTargetPathMutation,
    cloneTargetPathMutation,
  ]);

  const wrapWithReset = useWrapOpenDialogHandlerWithReset(resetMutations);

  // Component state
  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();

  const {
    isOpen: isEditDialogOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: editDialogData,
  } = useDialogState<IEditDialogState>();

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    data: deleteDialogData,
  } = useDialogState<IDeleteDialogState>();

  const {
    isOpen: isToggleDialogOpen,
    openDialog: openToggleDialog,
    closeDialog: closeToggleDialog,
    data: toggleDialogData,
  } = useDialogState<IToggleDialogState>();

  const {
    isOpen: isCloneDialogOpen,
    openDialog: openCloneDialog,
    closeDialog: closeCloneDialog,
    data: cloneDialogData,
  } = useDialogState<ICloneDialogState>();

  const isLoading =
    createTargetPathMutation.isPending ||
    updateTargetPathMutation.isPending ||
    deleteTargetPathMutation.isPending ||
    toggleTargetPathMutation.isPending ||
    cloneTargetPathMutation.isPending;

  // Event handlers
  const handleCreate = useCallback(
    async (data: IBaseTargetPathData | null) => {
      if (data !== null) {
        await createTargetPathMutation.mutateAsync({
          organizationId: organization.id,
          payload: data,
        });
      }
      closeCreateDialog();
    },
    [createTargetPathMutation, closeCreateDialog, organization.id],
  );

  const handleUpdate = useCallback(
    async (data: IBaseTargetPathData | null) => {
      if (data !== null && editDialogData !== undefined) {
        await updateTargetPathMutation.mutateAsync({
          organizationId: organization.id,
          targetPathId: editDialogData.targetPath.id,
          payload: data,
        });
      }
      closeEditDialog();
    },
    [closeEditDialog, editDialogData, organization.id, updateTargetPathMutation],
  );

  const handleDelete = useCallback(
    async (confirm: boolean) => {
      if (confirm && deleteDialogData !== undefined) {
        await deleteTargetPathMutation.mutateAsync({
          organizationId: organization.id,
          targetPathId: deleteDialogData.targetPath.id,
        });
      }
      closeDeleteDialog();
    },
    [closeDeleteDialog, deleteDialogData, deleteTargetPathMutation, organization.id],
  );

  const handleToggle = useCallback(
    async (confirm: boolean) => {
      if (confirm && toggleDialogData !== undefined) {
        await toggleTargetPathMutation.mutateAsync({
          organizationId: organization.id,
          targetPathId: toggleDialogData.targetPath.id,
        });
      }
      closeToggleDialog();
    },
    [closeToggleDialog, organization.id, toggleDialogData, toggleTargetPathMutation],
  );

  const handleClone = useCallback(
    async (data: IBaseTargetPathDataWithoutReferenceBudget | null) => {
      if (data !== null && cloneDialogData !== undefined) {
        await cloneTargetPathMutation.mutateAsync({
          organizationId: organization.id,
          targetPathId: cloneDialogData.targetPath.id,
          payload: data,
        });
      }
      closeCloneDialog();
    },
    [cloneDialogData, cloneTargetPathMutation, closeCloneDialog, organization.id],
  );

  // Render Methods
  const renderCloneDialog = useCallback(
    () =>
      cloneDialogData === undefined ? null : (
        <TargetPathCloneDialog
          targetPath={cloneDialogData.targetPath}
          open={isCloneDialogOpen}
          loading={isLoading}
          onClose={handleClone}
          error={cloneTargetPathMutation.error}
          disabled={isLoading}
          allTargetPaths={targetPaths}
        />
      ),
    [
      cloneDialogData,
      cloneTargetPathMutation.error,
      handleClone,
      isCloneDialogOpen,
      isLoading,
      targetPaths,
    ],
  );

  const renderDeleteConfirmDialog = useCallback(
    () => (
      <ConfirmDialogTextBody
        open={isDeleteDialogOpen}
        error={deleteTargetPathMutation.error}
        loading={isLoading}
        disabled={isLoading}
        onClose={handleDelete}
        title={t("confirm.title")}
        content={t("confirm.body")}
      />
    ),
    [deleteTargetPathMutation.error, handleDelete, isDeleteDialogOpen, isLoading, t],
  );

  const renderToggleConfirmDialog = useCallback(
    () =>
      toggleDialogData === undefined ? null : (
        <ConfirmDialogTextBody
          open={isToggleDialogOpen}
          error={toggleTargetPathMutation.error}
          loading={isLoading}
          disabled={isLoading}
          onClose={handleToggle}
          title={t(`confirm_toggle.for_state.${toggleDialogData.targetPath.state}.title`)}
          content={
            toggleDialogData?.targetPath.state === ITargetPathState.Committed ? (
              t(`confirm_toggle.for_state.${toggleDialogData.targetPath.state}.body`)
            ) : (
              <SetTargetPathAsOrganizationTargetPathConfirmDialogContentComponent
                currentTargetPath={toggleDialogData.currentOrganizationTargetPath}
                targetPath={toggleDialogData.targetPath}
              />
            )
          }
        />
      ),
    [
      handleToggle,
      isLoading,
      isToggleDialogOpen,
      t,
      toggleDialogData,
      toggleTargetPathMutation.error,
    ],
  );

  const renderCreateDialog = useCallback(
    () => (
      <TargetPathEditDialog
        mode="create"
        targetPath={null}
        open={isCreateOpen}
        loading={isLoading}
        onClose={handleCreate}
        availableReferenceBudgets={referenceBudgets}
        error={createTargetPathMutation.error}
        disabled={isLoading}
        otherTargetPaths={targetPaths}
      />
    ),
    [
      createTargetPathMutation.error,
      handleCreate,
      isCreateOpen,
      isLoading,
      referenceBudgets,
      targetPaths,
    ],
  );

  const renderEditDialog = useCallback(
    () =>
      editDialogData === undefined ? null : (
        <TargetPathEditDialog
          mode="edit"
          targetPath={editDialogData.targetPath}
          open={isEditDialogOpen}
          loading={isLoading}
          onClose={handleUpdate}
          availableReferenceBudgets={referenceBudgets}
          error={updateTargetPathMutation.error}
          disabled={isLoading}
          otherTargetPaths={targetPaths.filter((t) => t.id !== editDialogData.targetPath.id)}
        />
      ),
    [
      editDialogData,
      handleUpdate,
      isEditDialogOpen,
      isLoading,
      referenceBudgets,
      targetPaths,
      updateTargetPathMutation.error,
    ],
  );

  const renderAllDialogs = useCallback(
    () => (
      <>
        {/* Clone Dialog */}
        {renderCloneDialog()}

        {/* Delete Confirm Dialog */}
        {renderDeleteConfirmDialog()}

        {/* Toggle Confirm Dialog */}
        {renderToggleConfirmDialog()}

        {/* Create Dialog */}
        {renderCreateDialog()}

        {/* Edit Dialog */}
        {renderEditDialog()}
      </>
    ),
    [
      renderCloneDialog,
      renderCreateDialog,
      renderDeleteConfirmDialog,
      renderEditDialog,
      renderToggleConfirmDialog,
    ],
  );

  return {
    handlers: {
      // Handlers to open dialogs
      openCreateDialog: wrapWithReset(openCreateDialog),
      openEditDialog: wrapWithReset(openEditDialog),
      openToggleDialog: wrapWithReset(openToggleDialog),
      openCloneDialog: wrapWithReset(openCloneDialog),
      openDeleteDialog: wrapWithReset(openDeleteDialog),
    },
    render: {
      // Render functions for the dialogs
      renderCreateDialog,
      renderEditDialog,
      renderToggleConfirmDialog,
      renderCloneDialog,
      renderDeleteConfirmDialog,
      renderAllDialogs,
    },
    utility: {
      // Utility functions
      resetMutations,
    },
    state: {
      isLoading,
    },
  };
};
