import { useCallback, useMemo } from "react";
import { LS_SELECTED_DMA_CATEGORIES_KEY } from "../../common/constants/localstorage.constants";
import { useLocalStorage } from "@uidotdev/usehooks";

interface IPayload {
  esrsTopicId: string;
  dmaCategoryId: string | null;
}

// Key is the id of the DEO
type Storage = Record<string, IPayload>;

export const useSyncCategoryToLS = () => {
  const [storedValue, setStoredValue] = useLocalStorage<Storage>(
    LS_SELECTED_DMA_CATEGORIES_KEY,
    {},
  );

  /**
   * Saves a category id to the localStorage based on the data entry object id.
   *
   * @param dataEntryObjectId - The ID of the data entry object (key in localStorage)
   * @param payload - The payload object (value in localStorage)
   */
  const saveCategoryId = useCallback(
    (dataEntryObjectId: string, payload: IPayload) => {
      setStoredValue((prev) => ({
        ...prev,
        [dataEntryObjectId]: payload,
      }));
    },
    [setStoredValue],
  );

  /**
   * Retrieves the stored category ID for a specific data entry object.
   *
   * @param dataEntryObjectId - The ID of the data entry object to retrieve the category ID for
   * @returns {IPayload | null} The payload object if it exists, otherwise null
   */
  const getStoredCategoryId = useCallback(
    (dataEntryObjectId: string): IPayload | null => {
      return storedValue[dataEntryObjectId] ?? null;
    },
    [storedValue],
  );

  /**
   * Removes the settings for a specific data entry object from the localStorage.
   *
   * @param dataEntryObjectId - The ID of the data entry object to be removed from the localStorage
   */
  const clearSettingsForDataEntryObject = useCallback(
    (dataEntryObjectId: string) => {
      setStoredValue((prev) => {
        // Create a shallow copy of the previous storage
        const updatedStorage = { ...prev };

        // Remove the key from the copied object
        if (dataEntryObjectId in updatedStorage) {
          delete updatedStorage[dataEntryObjectId];
        }

        return updatedStorage;
      });
    },
    [setStoredValue],
  );

  return useMemo(
    () => ({ saveCategoryId, getStoredCategoryId, clearSettingsForDataEntryObject }),
    [saveCategoryId, getStoredCategoryId, clearSettingsForDataEntryObject],
  );
};
