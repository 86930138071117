import { IDiff, IOptionalDiff } from "./audit-logging.public-types";
import { isEqual } from "lodash";

export class AuditLoggingUtilities {
  /**
   * This method determines the properties that have changed between object a and b
   * @param a Value before
   * @param b Value after
   * @param diff The difference - just used for recursion, you do not need to specify this!
   * @private
   */
  public static getDiffForObjects<T extends object>(
    a: T | null | undefined,
    b: T | null | undefined,
    diff: IOptionalDiff<T> = {
      before: {},
      after: {},
    },
  ): IDiff<T> {
    for (const key of this.getAllKeysOfObjects(a, b)) {
      const valueForA = a?.[key];
      const valueForB = b?.[key];

      if (
        typeof valueForA === "object" &&
        valueForA !== null &&
        // nested arrays are not supported
        !Array.isArray(valueForA) &&
        typeof valueForB === "object" &&
        valueForB !== null &&
        // nested arrays are not supported
        !Array.isArray(valueForB)
      ) {
        // determine diff for nested object
        const currentDiff = this.getDiffForObjects<object>(valueForA, valueForB);
        if (
          Object.keys(currentDiff.before).length > 0 ||
          Object.keys(currentDiff.after).length > 0
        ) {
          // as any is fine here since the types do not quite work out for some reason
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          diff.before[key] = currentDiff.before as any;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          diff.after[key] = currentDiff.after as any;
        }
      } else {
        // not an object or is null + values are not equal --> set changed property
        if (!isEqual(valueForA, valueForB)) {
          // no need to replace with null or undefined as those should remain as they are!
          diff.before[key] = valueForA ?? null;
          // no need to replace with null or undefined as those should remain as they are!
          diff.after[key] = valueForB ?? null;
        }
      }
    }

    return diff as IDiff<T>;
  }

  public static getAllKeysOfObjects<T extends object>(
    ...objects: (T | null | undefined)[]
  ): (keyof T)[] {
    return Array.from(
      new Set(
        (objects.filter((o) => o !== undefined && o !== null) as T[]).flatMap((o) =>
          Object.entries(o)
            .filter(([, value]) => value !== undefined)
            .map(([key]) => key),
        ),
      ),
    ) as (keyof T)[];
  }
}
