import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";

export const getRecordingPeriodOrganizationStructureQueryKey = (
  organizationId: string,
  recordingPeriodId: string,
) => ["organization-structures", organizationId, recordingPeriodId];

/**
 * Fetches the organization structure for the given recording period
 * @param organizationId Organization to which the recording period belongs
 * @param recordingPeriodId Recording period for which to fetch the organization structure
 * @returns Organization structure for the given recording period
 */
export const useRecordingPeriodOrganizationStructureQuery = (
  organizationId: string,
  recordingPeriodId: string,
) => {
  return useQuery({
    queryKey: getRecordingPeriodOrganizationStructureQueryKey(organizationId, recordingPeriodId),
    queryFn: async () => {
      return CoreApiService.OrganizationStructureApi.getOrganizationStructureForRecordingPeriod(
        organizationId,
        recordingPeriodId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
  });
};
