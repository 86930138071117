import { FC, useCallback, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEsrsPhaseInQuery } from "../../esrs-phase-in/esrs-phase-in.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { IESRSTopicIdentifier, IESRSTopicPhaseInDecision } from "@netcero/netcero-core-api-client";
import { CenteredCircularProgress } from "../../common/components/centered-circular-progress.component";
import { useTranslation } from "react-i18next";
import { useUpdatePhaseInDecisionMutation } from "../../esrs-phase-in/esrs-phase-in.mutations";
import { useAppSnackbar } from "../../app-snackbar/app-snackbar.hook";
import { BasicSnackbarApiActionType } from "../../app-snackbar/app-snackbar.interfaces";
import {
  ESRSTopicUtilities,
  ORDERED_ESRS_TOPICS_THAT_SUPPORT_PHASE_IN,
} from "@netcero/netcero-common";
import { useRenderESRSTopicName } from "../../double-materiality-assessment/hooks/render-esrs-topic-name.hook";

interface IDataEntryObjectValueESRSPhaseInInputComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObjectId: string;
  dataEntryObjectId: string;
}

export const DataEntryObjectValueESRSPhaseInInputComponent: FC<
  IDataEntryObjectValueESRSPhaseInInputComponentProps
> = ({ organizationId, recordingPeriodId, rootDataEntryObjectId, dataEntryObjectId }) => {
  const notRootDataEntryObject = rootDataEntryObjectId !== dataEntryObjectId;
  const phaseInChoicesQuery = useEsrsPhaseInQuery({
    organizationId,
    recordingPeriodId,
    dataEntryObjectId: rootDataEntryObjectId,
  });

  return (
    <QueryWrapper
      query={phaseInChoicesQuery}
      loadingOverride={() => <CenteredCircularProgress minHeight={96} />}
      build={(phaseInDecisionsResponse) => (
        <DataEntryObjectValueEsrsPhaseInInputs
          decisions={phaseInDecisionsResponse.decisions}
          {...{
            notRootDataEntryObject,
            organizationId,
            recordingPeriodId,
            rootDataEntryObjectId,
            dataEntryObjectId,
          }}
        />
      )}
    />
  );
};

interface IDataEntryObjectValueEsrsPhaseInInputs
  extends IDataEntryObjectValueESRSPhaseInInputComponentProps {
  notRootDataEntryObject: boolean;
  decisions: IESRSTopicPhaseInDecision[];
}

const DataEntryObjectValueEsrsPhaseInInputs: FC<IDataEntryObjectValueEsrsPhaseInInputs> = ({
  notRootDataEntryObject,
  decisions,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
}) => {
  const { t } = useTranslation("data_entry_object_value_esrs_phase_in_input");
  const renderTopicName = useRenderESRSTopicName();
  const { wrapApiPromise } = useAppSnackbar();

  const phaseInIsInUse = useMemo(() => decisions.some((dec) => dec.makesUseOfPhaseIn), [decisions]);
  const decisionsLookup = useMemo(() => {
    return decisions.reduce(
      (acc, dec) => ({
        ...acc,
        [dec.esrsTopicIdentifier]: dec,
      }),
      {} as Record<IESRSTopicIdentifier, IESRSTopicPhaseInDecision | undefined>,
    );
  }, [decisions]);
  const [manualPhaseInIsInUse, setManualPhaseInIsInUse] = useState(phaseInIsInUse);

  const updatePhaseInDecisionMutation = useUpdatePhaseInDecisionMutation();
  const handleChangeTopicPhaseInDecision = useCallback(
    async (topic: IESRSTopicIdentifier, makesUseOfPhaseIn: boolean) => {
      return wrapApiPromise(
        updatePhaseInDecisionMutation.mutateAsync({
          organizationId,
          recordingPeriodId,
          dataEntryObjectId: rootDataEntryObjectId,
          payload: {
            makesUseOfPhaseIn,
            esrsTopicIdentifier: topic,
          },
        }),
        { type: BasicSnackbarApiActionType.UPDATE_PHASE_IN_DECISION },
      );
    },
    [
      organizationId,
      recordingPeriodId,
      rootDataEntryObjectId,
      updatePhaseInDecisionMutation,
      wrapApiPromise,
    ],
  );

  const switchTooltip = useMemo(() => {
    if (notRootDataEntryObject) {
      return t("notice_only_root_data_entry_object");
    }

    if (phaseInIsInUse) {
      return t("notice_phase_in_in_use");
    }

    return null; // No Tooltip
  }, [t, notRootDataEntryObject, phaseInIsInUse]);

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%" px={2} pb={1}>
      {/* Phase In is in Use Toggle */}
      <FormControlLabel
        control={
          <Tooltip title={switchTooltip} placement="top">
            <span>
              <Switch
                checked={manualPhaseInIsInUse}
                onChange={(_, checked) => setManualPhaseInIsInUse(checked)}
                disabled={notRootDataEntryObject || phaseInIsInUse}
              />
            </span>
          </Tooltip>
        }
        label={<Typography fontWeight="bold">{t("use_phase_in_toggle_label")}</Typography>}
      />
      {/* Show Topic Options */}
      <Collapse in={manualPhaseInIsInUse}>
        <Alert severity="info" sx={{ mb: 1, mx: 1 }}>
          {t("information_box_content")}
        </Alert>
        <Box display="flex" flexDirection="column" gap={1} px={2}>
          {ORDERED_ESRS_TOPICS_THAT_SUPPORT_PHASE_IN.map((topic) => (
            <FormControlLabel
              key={topic}
              control={
                <Checkbox
                  checked={decisionsLookup[topic]?.makesUseOfPhaseIn ?? false}
                  onChange={(_, checked) => handleChangeTopicPhaseInDecision(topic, checked)}
                />
              }
              label={`${ESRSTopicUtilities.convertTopicToDisplayTopic(topic)}: ${renderTopicName({
                identifier: topic,
              })}`}
              disabled={notRootDataEntryObject || !manualPhaseInIsInUse}
            />
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};
