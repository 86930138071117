import { FC, useCallback, useMemo } from "react";
import {
  IReferenceBudget,
  IRelativeTargetPathGoal,
  ITargetPathGoalValues,
} from "@netcero/netcero-core-api-client";
import { Box, Typography } from "@mui/material";
import {
  ITargetPathGoalValueChangeInputComponentProps,
  TargetPathGoalValueChangeInputComponent,
} from "./target-path-goal-value-change-input.component";
import { useTranslation } from "react-i18next";
import {
  RelativeTargetPathGoalScopes,
  TargetPathGoalChangeType,
  TargetPathGoalsCalculations,
} from "@netcero/netcero-common";
import { FormatUtilities } from "../../common/utilities/format.utilities";
import { TargetIntensityInputComponent } from "./target-intensity-input.component";
import { ReferenceIntensityComponent } from "./reference-intensity.component";

interface IRelativeTargetPathGoalEditComponentProps {
  targetYear: number;
  values: IRelativeTargetPathGoal;
  referenceBudget: IReferenceBudget;
  onValueChange: (values: ITargetPathGoalValues) => void;
  disabled?: boolean;
}

interface IRelativeTargetPathGoalValueChangeInputComponentProps
  extends Omit<
    ITargetPathGoalValueChangeInputComponentProps,
    "unit" | "translations" | "helperTextTextField" | "labelTextField" | "tooltipTextField"
  > {
  referenceRevenue: number | null;
}

const RelativeTargetPathGoalValueChangeInputComponent: FC<
  IRelativeTargetPathGoalValueChangeInputComponentProps
> = (props) => {
  const { t } = useTranslation("relative_target_path_goal_edit_component");
  const { referenceRevenue } = props;

  return (
    <TargetPathGoalValueChangeInputComponent
      {...props}
      translations={{
        inPercent: t("translations.in_percent"),
        byValue: t("translations.by_value"),
        toValue: t("translations.to_value"),
      }}
      unit="eur"
      labelTextField={t(
        `revenue.textfield_label_${props.change.type === "toValue" ? "absolute" : "growth"}`,
      )}
      helperTextTextField={t("revenue.helper_text", {
        referenceRevenue:
          referenceRevenue === null ? "-" : FormatUtilities.formatCurrency(referenceRevenue),
      })}
      dropdownDisabled={referenceRevenue === null}
      tooltipDropdown={
        referenceRevenue === null ? t("revenue.tooltip_dropdown_no_revenue") : undefined
      }
    />
  );
};

export const RelativeTargetPathGoalEditComponent: FC<IRelativeTargetPathGoalEditComponentProps> = ({
  values,
  onValueChange,
  referenceBudget,
  disabled,
  targetYear,
}) => {
  const { t } = useTranslation("relative_target_path_goal_edit_component");

  // Event handlers
  const handleChangeTypeChange = useCallback(
    (oldType: TargetPathGoalChangeType, newType: TargetPathGoalChangeType) => {
      onValueChange({
        ...values,
        targetRevenue: {
          value: TargetPathGoalsCalculations.calculateRelativeChangeTypeTransition(
            values.targetRevenue.value,
            referenceBudget.referenceRevenue ?? 0,
            oldType,
            newType,
            targetYear,
            referenceBudget.referenceYear,
          ),
          type: newType,
        },
      });
    },
    [
      onValueChange,
      referenceBudget.referenceRevenue,
      referenceBudget.referenceYear,
      targetYear,
      values,
    ],
  );

  const handleRevenueValueChange = useCallback(
    (value: number) => {
      onValueChange({ ...values, targetRevenue: { ...values.targetRevenue, value } });
    },
    [onValueChange, values],
  );

  const handleIntensityValueChange = useCallback(
    (value: number, scope: RelativeTargetPathGoalScopes) => {
      onValueChange({ ...values, intensities: { ...values.intensities, [scope]: value } });
    },
    [onValueChange, values],
  );

  const targetRevenue = useMemo(
    () =>
      TargetPathGoalsCalculations.calculateAbsoluteTargetRevenue(
        values.targetRevenue,
        referenceBudget.referenceRevenue ?? 0,
        targetYear,
        referenceBudget.referenceYear,
      ),
    [
      referenceBudget.referenceRevenue,
      referenceBudget.referenceYear,
      targetYear,
      values.targetRevenue,
    ],
  );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {/* Revenue */}
      <Typography variant="subtitle1">{t("headings.revenue")}</Typography>
      <RelativeTargetPathGoalValueChangeInputComponent
        change={values.targetRevenue}
        onChangeTypeChange={handleChangeTypeChange}
        onValueChange={handleRevenueValueChange}
        disabled={disabled}
        referenceRevenue={referenceBudget.referenceRevenue ?? null}
      />

      {/* (Target) Intensity */}
      <Typography variant="subtitle1">{t("headings.intensity")}</Typography>
      <ReferenceIntensityComponent
        referenceBudget={referenceBudget}
        totalEmissions={referenceBudget.calculatedValues.sum}
        isSum
        noFormat={false}
      />
      <TargetIntensityInputComponent
        scope="intensityScope1"
        referenceBudget={referenceBudget}
        intensities={values.intensities}
        disabled={disabled}
        onValueChange={(value) => handleIntensityValueChange(value, "intensityScope1")}
        absoluteTargetRevenue={targetRevenue}
      />
      <TargetIntensityInputComponent
        scope="intensityScope2"
        referenceBudget={referenceBudget}
        intensities={values.intensities}
        disabled={disabled}
        onValueChange={(value) => handleIntensityValueChange(value, "intensityScope2")}
        absoluteTargetRevenue={targetRevenue}
      />
      <TargetIntensityInputComponent
        scope="intensityScope3"
        referenceBudget={referenceBudget}
        intensities={values.intensities}
        disabled={disabled}
        onValueChange={(value) => handleIntensityValueChange(value, "intensityScope3")}
        absoluteTargetRevenue={targetRevenue}
      />
    </Box>
  );
};
