import { FC } from "react";
import { Navigate, useParams } from "react-router-dom";
import { OrganizationStructurePageWrapperHelper } from "../common/organization-structure-page-wrapper.helper";

export const RedirectToRootDataEntryObjectForValueInputPage: FC = () => {
  const { organizationId, recordingPeriodId, recordingStructureId } = useParams();

  return (
    <OrganizationStructurePageWrapperHelper
      buildPage={(structure) => (
        <Navigate
          to={
            `/organizations/${organizationId}/recording-periods/${recordingPeriodId}` +
            `/recording-structures/${recordingStructureId}/data-entry-objects/${structure.id}/`
          }
          replace
        />
      )}
    />
  );
};
