import { ILocalDataEntryObjectInputParameter } from "../interfaces/local-data-entry-object-values.interfaces";
import { useMemo } from "react";
import { InputParameterRecordingStructuresUtilities } from "../../input-parameter-recording-structures/input-parameter-recording-structures.utilities";

export type InputParameterDisplayInformationLookup = Record<
  string,
  IInputParameterDisplayInformation | undefined
>;

export interface IInputParameterDisplayInformation {
  dependentUponInputParameter: ILocalDataEntryObjectInputParameter;
  displayMode: "show" | "hidden_but_show_value" | "hide";
}

export const useConditionalDisplayInputParameters = (
  dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
) => {
  const inputParametersLookup = useMemo(
    () =>
      InputParameterRecordingStructuresUtilities.getInputParameterEntryLookupMap(
        dataEntryObjectInputParameters,
      ),
    [dataEntryObjectInputParameters],
  );

  return useMemo(() => {
    const conditionalDisplayInputParametersLookup: InputParameterDisplayInformationLookup = {};
    const notFoundInputParametersIds: string[] = [];

    dataEntryObjectInputParameters.forEach((ip) => {
      const displayCondition = ip.inputParameter.condition;
      // Skip all non conditional input parameters
      if (!displayCondition) {
        return;
      }

      const dependentUponInputParameter = inputParametersLookup.get(
        displayCondition.checkInputParameterId,
      );
      // Skip if the dependent input parameter is not found
      if (!dependentUponInputParameter) {
        // TODO: Remove this statement once notFoundInputParametersIds is shown somewhere in the UI / indicated to the user in some way
        console.warn(
          "Dependent input parameter not found for display condition!",
          displayCondition,
        );
        notFoundInputParametersIds.push(displayCondition.checkInputParameterId);
        return;
      }

      const conditionMet =
        dependentUponInputParameter.recordedValues.length > 0 &&
        dependentUponInputParameter.recordedValues[0].valuesPerKey[displayCondition.check.key] &&
        dependentUponInputParameter.recordedValues[0].valuesPerKey[displayCondition.check.key]!
          .value === displayCondition.check.value;

      conditionalDisplayInputParametersLookup[ip.inputParameter.id] = {
        dependentUponInputParameter,
        displayMode: conditionMet
          ? "show"
          : ip.recordedValues.length > 0 // If recorded value exists, show it
          ? "hidden_but_show_value"
          : "hide",
      };
    });

    return {
      conditionalDisplayInputParametersLookup,
      notFoundInputParametersIds,
    };
  }, [dataEntryObjectInputParameters, inputParametersLookup]);
};
