import {
  IDataEntryObject,
  IDistributionCriterionWithApplicationStatus,
} from "@netcero/netcero-core-api-client";

export class AppliedDistributionCriteriaUtilities {
  public static getAllIdsInTree(
    data: IDataEntryObject | IDataEntryObject[],
    allIds: string[] = [],
  ) {
    if (!Array.isArray(data)) {
      data = [data];
    }
    for (const item of data) {
      allIds.push(item.id);
      if (item.children !== undefined && item.children !== null && item.children.length > 0) {
        AppliedDistributionCriteriaUtilities.getAllIdsInTree(item.children, allIds);
      }
    }
    return allIds;
  }

  public static getSumOfValuesInTree(
    dataEntryObject: IDataEntryObject,
    criteria: IDistributionCriterionWithApplicationStatus,
  ) {
    const allRelevantIds = AppliedDistributionCriteriaUtilities.getAllIdsInTree(dataEntryObject);
    // sum up the values of the ids in the current tree
    return allRelevantIds.reduce((acc, id) => acc + (criteria.application[id] ?? 0), 0);
  }
}
