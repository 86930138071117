import { FC } from "react";
import { ChevronDownIcon } from "../constants/tabler-icon.constants";
import { IconButton, IconButtonProps } from "@mui/material";

interface IExpansionIconButtonProps {
  expanded: boolean;
  disabled?: boolean;
  iconButtonProps?: IconButtonProps;
  onClick?: () => void;
}

export const ExpansionIconButton: FC<IExpansionIconButtonProps> = ({
  expanded,
  disabled,
  iconButtonProps,
  onClick,
}) => {
  return (
    <IconButton
      disableRipple={disabled}
      onClick={onClick}
      {...iconButtonProps}
      sx={{
        transform: `rotate(${expanded ? 180 : 0}deg)`,
        transition: "transform 300ms",
        cursor: disabled ? "default" : undefined,
        ...iconButtonProps?.sx,
      }}
    >
      <ChevronDownIcon />
    </IconButton>
  );
};
