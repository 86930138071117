import { FC, ReactNode, createContext, useContext, useEffect, useState } from "react";
import { ISideBarConfiguration, ISideBarProps, SideBar } from "../components/side-bar.component";

interface ISideBarContext {
  sideBarConfiguration: ISideBarConfiguration;
  updateSideBarConfiguration: (sideBarConfiguration: ISideBarConfiguration) => void;
}
const InternalSideBarContext = createContext<ISideBarContext>({
  sideBarConfiguration: {},
  updateSideBarConfiguration: () => {},
});

interface ISideBarContextProps {
  children: ReactNode;
}
export const SideBarContext: FC<ISideBarContextProps> = ({ children }) => {
  const [sideBarConfiguration, updateSideBarConfiguration] = useState<ISideBarConfiguration>({});

  return (
    <InternalSideBarContext.Provider
      value={{
        sideBarConfiguration,
        updateSideBarConfiguration,
      }}
      children={children}
    />
  );
};

export const useSideBarContext = () => useContext(InternalSideBarContext);

type IContextBasedSideBarProps = Omit<ISideBarProps, "configuration">;
export const ContextBasedSideBar: FC<IContextBasedSideBarProps> = ({ ...props }) => {
  const { sideBarConfiguration } = useSideBarContext();

  return <SideBar {...props} configuration={sideBarConfiguration} />;
};

/**
 * Hook to update the Application SideBar configuration. This hook will also reset the SideBar configuration when the component unmounts.
 * @param sideBarConfiguration Configuration for the SideBars. Should probably be wrapped by a useMemo to avoid unnecessary re-renders or re-render loops.
 */
export const useCustomSideBarContent = (sideBarConfiguration: ISideBarConfiguration) => {
  const { updateSideBarConfiguration } = useSideBarContext();

  useEffect(() => {
    updateSideBarConfiguration(sideBarConfiguration);
    return () => updateSideBarConfiguration({});
  }, [sideBarConfiguration, updateSideBarConfiguration]);
};
