import { Box, FormControl, FormHelperText, InputLabel } from "@mui/material";
import { IInputParameterValueMetaDataDateRange } from "@netcero/netcero-core-api-client";
import { FC, useCallback, useMemo } from "react";
import { BaseDateComponent, dateTimeOrStringToDate } from "./base-date.component";
import {
  DataEntryObjectInputParameterValueDefinitionForDate,
  DataEntryObjectInputParameterValueDefinitionForDateRange,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IDataEntryObjectValueInputVariant,
  useVariantFormProps,
} from "../../data-entry-object-values/input-components/data-entry-object-value-input.component";

interface IDateRangeInputComponentProps {
  variant: IDataEntryObjectValueInputVariant;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDateRange>;
  onChange: (
    value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDateRange>,
  ) => void;
  disabled?: boolean;
  error?: string;
  metaData: IInputParameterValueMetaDataDateRange;
  disableMaxWidth?: boolean;
  stack?: boolean;
  required?: boolean;
  label?: string;
}

export const DateRangeInputComponent: FC<IDateRangeInputComponentProps> = ({
  variant,
  value,
  onChange,
  disabled,
  error,
  metaData,
  disableMaxWidth,
  stack,
  required,
  label,
}) => {
  const variantProps = useVariantFormProps(variant);

  // split the dates into the respective components
  const [valueStart, valueEnd] = useMemo(
    () => (value === undefined ? [undefined, undefined] : value),
    [value],
  );

  // fuses the dates back into one value for the change event
  const fuseDates = useCallback(
    (
      startDate: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDate>,
      endDate: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForDate>,
    ) => {
      if (startDate === undefined && endDate === undefined) {
        return undefined;
      }

      return [startDate, endDate] as [string, string];
    },
    [],
  );

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <Box position="absolute" top={-24} left={0}>
        {label && <InputLabel sx={{ color: error ? "error.main" : undefined }}>{label}</InputLabel>}
      </Box>
      <FormControl
        {...variantProps}
        error={!!error}
        sx={{ maxWidth: !disableMaxWidth ? 260 * 2 : undefined }}
      >
        <Box
          display="flex"
          flexDirection={stack ? "column" : "row"}
          alignItems="flex-start"
          justifyContent="flex-start"
          gap={1}
        >
          <BaseDateComponent
            variant={variant}
            required={required}
            value={valueStart}
            onChange={(newValue) => {
              onChange(fuseDates(newValue, valueEnd));
            }}
            disabled={disabled}
            error={error ? "" : undefined}
            min={metaData.valueLimits.min}
            // date has to be converted here since we have to subtract one day
            max={dateTimeOrStringToDate(
              valueEnd !== undefined ? valueEnd : metaData.valueLimits.max,
            )?.minus({ day: 1 })}
          />
          <BaseDateComponent
            variant={variant}
            required={required}
            value={valueEnd}
            onChange={(newValue) => {
              onChange(fuseDates(valueStart, newValue));
            }}
            disabled={disabled}
            error={error ? "" : undefined}
            // date has to be converted here since we have to add one day
            min={dateTimeOrStringToDate(
              valueStart !== undefined ? valueStart : metaData.valueLimits.min,
            )?.plus({ day: 1 })}
            max={metaData.valueLimits.max}
          />
        </Box>
        {error && <FormHelperText sx={{ marginLeft: 0 }}>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};
