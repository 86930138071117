/* tslint:disable */
/* eslint-disable */
/**
 * NetCero Core API
 * API for NetCero Core Services
 *
 * The version of the OpenAPI document: 0.1-TBU
 * Contact: technical-support@netcero.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */

export const IDataEntryObjectInputParameterExclusionReason = {
  NonMaterial: "non-material",
  OtherReason: "other-reason",
  ApplyPhaseIn: "apply-phase-in",
} as const;

export type IDataEntryObjectInputParameterExclusionReason =
  (typeof IDataEntryObjectInputParameterExclusionReason)[keyof typeof IDataEntryObjectInputParameterExclusionReason];
