import { FieldValues } from "react-hook-form";
import { IMDRYearInput } from "../../mdr-input.types";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { IMDRControlledInputProps, NON_FULL_WIDTH_WIDTH } from "./mdr-input-component.factory";

export function YearInput<T extends FieldValues>({
  valueMetaData,
  label,
  field,
  fieldState: { error },
  disabled,
}: IMDRControlledInputProps<T, IMDRYearInput>) {
  return (
    <DatePicker
      label={label}
      views={["year"]}
      value={field.value ? DateTime.local(field.value, 1, 1) : null}
      onChange={(value) => field.onChange(value?.year ?? null)}
      slotProps={{
        field: {
          clearable: !valueMetaData.required,
          onClear: () => field.onChange(null),
        },
        textField: {
          error: !!error,
          helperText: error?.message,
        },
      }}
      sx={{ maxWidth: NON_FULL_WIDTH_WIDTH }}
      disabled={disabled}
    />
  );
}
