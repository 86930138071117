import { FC } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CommentsIcon } from "../../common/constants/tabler-icon.constants";
import { useCommentsCountQuery } from "../comments.queries";
import { ICommentRelations } from "@netcero/netcero-core-api-client";
import { NumberBadgeInline } from "../../common/components/number-badge-inline.component";

interface ICommentsButtonPropsCommon {
  onClick: VoidFunction;
  disabled?: boolean;
}

interface ICommentsButtonProps extends ICommentsButtonPropsCommon {
  commentsCount?: number | null;
}

export const CommentsButton: FC<ICommentsButtonProps> = ({ onClick, commentsCount, disabled }) => {
  const { t } = useTranslation("comments");
  return (
    <Button
      variant="gray"
      startIcon={<CommentsIcon />}
      onClick={onClick}
      sx={{ pl: 2 }}
      disabled={disabled}
    >
      {/* Label */}
      {t("button.label")}
      {/* Comment Count */}
      <NumberBadgeInline count={commentsCount} />
    </Button>
  );
};

interface ICommentsButtonWithQueryProps extends ICommentsButtonPropsCommon {
  organizationId: string;
  relations: ICommentRelations;
}

export const CommentsButtonWithQuery: FC<ICommentsButtonWithQueryProps> = ({
  organizationId,
  relations,
  ...commonProps
}) => {
  const commentsCountQuery = useCommentsCountQuery(organizationId, relations);

  return <CommentsButton commentsCount={commentsCountQuery.data} {...commonProps} />;
};
