import { FC } from "react";
import {
  DataEntryObjectInputParameterSourcesHandlingWrapper,
  IDataEntryObjectInputParameterSourcesHandlingWrapperPublicProps,
} from "./data-entry-object-input-parameter-sources-handling-wrapper.component";
import { LinkedSourcesChipWithMenu } from "../../sources/components/linked-sources-chip-with-menu.component";
import { ISource } from "@netcero/netcero-core-api-client";
import { useSourcesQuery } from "../../sources/sources.queries";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useSourcesForInputParameterQuery } from "../../data-entry-objects-input-parameters/data-entry-object-input-parameter.query";
import { Skeleton } from "@mui/material";

interface ILinkedSourcesDataEntryObjectInputParameterChipWithMenuProps
  extends IDataEntryObjectInputParameterSourcesHandlingWrapperPublicProps {
  disabled?: boolean;
}

type INormalProps = ILinkedSourcesDataEntryObjectInputParameterChipWithMenuProps & {
  sources: ISource[];
};

export const LinkedSourcesDataEntryObjectInputParameterChipWithMenu: FC<INormalProps> = ({
  disabled,
  sources,
  ...props
}) => {
  return (
    <DataEntryObjectInputParameterSourcesHandlingWrapper
      {...props}
      render={(linkedSourcesResponse, handleUpdateLinkedSources, isPending) => {
        return (
          <LinkedSourcesChipWithMenu
            organizationId={props.organizationId}
            linkedSourceIds={linkedSourcesResponse.sourceIds}
            sources={sources}
            onChange={handleUpdateLinkedSources}
            disabled={disabled || isPending}
          />
        );
      }}
      loadingOverride={LoadingSkeletonComponent}
    />
  );
};

type IWithQueryProps = ILinkedSourcesDataEntryObjectInputParameterChipWithMenuProps;
export const LinkedSourcesDataEntryObjectInputParameterChipWithMenuWithQuery: FC<
  IWithQueryProps
> = (props) => {
  const sourcesQuery = useSourcesQuery(props.organizationId);

  // Also start fetching linked sources
  useSourcesForInputParameterQuery(
    props.organizationId,
    props.recordingPeriodId,
    props.dataEntryObjectId,
    props.inputParameterId,
  );

  return (
    <QueryWrapper
      query={sourcesQuery}
      loadingOverride={LoadingSkeletonComponent}
      build={(sources) => (
        <LinkedSourcesDataEntryObjectInputParameterChipWithMenu {...props} sources={sources} />
      )}
    />
  );
};

const LoadingSkeletonComponent = () => (
  <Skeleton variant="rounded" width={100} height={28} sx={{ borderRadius: 99999 }} />
);
