import { IUpdateDataEntryObjectDistributionCriteriaRequest } from "@netcero/netcero-core-api-client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getAppliedDistributionCriteriaQueryKey } from "./applied-distribution-criteria.queries";

export interface IUpdateDistributionCriteriaValueData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  distributionCriterionId: string;
  payload: IUpdateDataEntryObjectDistributionCriteriaRequest;
}

export const useUpdateDistributionCriteriaValueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      distributionCriterionId,
      payload,
    }: IUpdateDistributionCriteriaValueData) => {
      return CoreApiService.DataEntryObjectDistributionCriteriaApi.updateDataEntryObjectDistributionCriteria(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        distributionCriterionId,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAppliedDistributionCriteriaQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};

export interface IDeleteDistributionCriteriaValueData {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  distributionCriterionId: string;
}

export const useDeleteDistributionCriteriaValueMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      dataEntryObjectId,
      distributionCriterionId,
    }: IDeleteDistributionCriteriaValueData) => {
      return CoreApiService.DataEntryObjectDistributionCriteriaApi.deleteDataEntryObjectDistributionCriteria(
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        distributionCriterionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAppliedDistributionCriteriaQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};

interface IStartConfigurationData {
  organizationId: string;
  recordingPeriodId: string;
  distributionCriterionId: string;
}

export const useStartAppliedDistributionCriterionConfigurationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      distributionCriterionId,
    }: IStartConfigurationData) => {
      return CoreApiService.AppliedDistributionCriteriaApi.createAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriterionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAppliedDistributionCriteriaQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};

interface IRemoveConfigurationData {
  organizationId: string;
  recordingPeriodId: string;
  distributionCriterionId: string;
}

export const useRemoveAppliedDistributionCriterionConfigurationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      distributionCriterionId,
    }: IRemoveConfigurationData) => {
      return CoreApiService.AppliedDistributionCriteriaApi.deleteAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriterionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAppliedDistributionCriteriaQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};

interface IApplyConfigurationData {
  organizationId: string;
  recordingPeriodId: string;
  distributionCriterionId: string;
}

export const useApplyAppliedDistributionCriterionConfigurationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      distributionCriterionId,
    }: IApplyConfigurationData) => {
      return CoreApiService.AppliedDistributionCriteriaApi.applyAppliedDistributionCriteriaForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriterionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAppliedDistributionCriteriaQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};

interface IRemovedAppliedConfigurationData {
  organizationId: string;
  recordingPeriodId: string;
  distributionCriterionId: string;
}

export const useRemoveAppliedDistributionCriterionApplicationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      recordingPeriodId,
      distributionCriterionId,
    }: IRemovedAppliedConfigurationData) => {
      return CoreApiService.AppliedDistributionCriteriaApi.removeAppliedDistributionCriteriaApplicationForRecordingPeriod(
        organizationId,
        recordingPeriodId,
        distributionCriterionId,
      )
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: async (data, variables, context) => {
      return queryClient.invalidateQueries({
        queryKey: getAppliedDistributionCriteriaQueryKey(
          variables.organizationId,
          variables.recordingPeriodId,
        ),
      });
    },
  });
};
