import { IBaseCalculatorContext } from "../types/base-calculator-context.interface";
import { IIROProbabilityPredefinedValue } from "@netcero/netcero-core-api-client";
import { DMAUtilities } from "../utilities/dma.utilities";

export type ResolvedProbability = "with-custom" | "without-custom";

export class BaseCalculator {
  constructor(protected readonly context: IBaseCalculatorContext) {}

  protected resolveProbabilityValue(
    value: IIROProbabilityPredefinedValue,
    resolve: ResolvedProbability,
  ) {
    return DMAUtilities.getProbabilitySliderValue(
      value,
      // If values should be resolved without custom --> never pass probabilitySteps
      // to ensure that the default values are used at all times!
      resolve === "without-custom" ? null : this.context.probabilityOfOccurrenceSteps,
    );
  }

  protected static normalizeValue(value: number) {
    // Workaround to fix floating point precision issue
    // TODO: Investigate if this is enough or if we need something more sophisticated
    return +value.toPrecision(8);
  }
}
