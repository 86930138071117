import { Box, Card, CardContent, Link, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon, IconSize } from "../modules/common/constants/tabler-icon.constants";

export const NotFoundPage: React.FC = () => {
  const { t } = useTranslation("not_found");

  return (
    <Box
      sx={{
        height: "100vh",
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        pb: "25vh",
      }}
    >
      {/* TODO: Include logo and impressum here */}
      <Typography variant="h4" component="h1">
        {t("title")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          pt: 4,
        }}
      >
        <Link component={RouterLink} to="/" underline="none">
          <Card>
            <CardContent sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <HomeIcon size={IconSize.Larger} />
              <Typography component="h1">{t("go_to_dashboard")}</Typography>
            </CardContent>
          </Card>
        </Link>
      </Box>
    </Box>
  );
};
