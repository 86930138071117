import { FC, useMemo } from "react";
import {
  EligiblePhaseInDrsEfragIds,
  InputParameterRecordingEsrsStructuresUtilities,
} from "../../input-parameter-recording-structures/esrs/input-parameter-recording-esrs-structures.utilities";
import { Alert } from "@mui/material";
import { PHASE_IN_COLORS } from "../../../theme/theme";
import { useTranslation } from "react-i18next";
import { IInputParameter } from "@netcero/netcero-core-api-client";

interface IPhaseInExplanationAlertProps {
  drPhaseInExplanationKey: EligiblePhaseInDrsEfragIds | null | undefined;
}

export const PhaseInExplanationAlert: FC<IPhaseInExplanationAlertProps> = ({
  drPhaseInExplanationKey,
}) => {
  const { t } = useTranslation("dr_eligible_for_phase_in_explanations");

  return drPhaseInExplanationKey ? (
    <Alert
      severity="info"
      sx={{
        color: PHASE_IN_COLORS.color,
        backgroundColor: PHASE_IN_COLORS.backgroundColor,
        ".MuiAlert-icon": { color: PHASE_IN_COLORS.color },
      }}
    >
      {t(drPhaseInExplanationKey)}
    </Alert>
  ) : null;
};

interface IPhaseInExplanationAlertForInputParameterProps {
  inputParameter: IInputParameter | null | undefined;
}

export const PhaseInExplanationAlertForInputParameter: FC<
  IPhaseInExplanationAlertForInputParameterProps
> = ({ inputParameter }) => {
  const drPhaseInExplanationKey = useMemo(
    () =>
      inputParameter &&
      InputParameterRecordingEsrsStructuresUtilities.isInputParameterEligibleForPhaseIn(
        inputParameter,
      )
        ? InputParameterRecordingEsrsStructuresUtilities.getDisclosureRequirementTranslationKey(
            inputParameter,
          )
        : null,
    [inputParameter],
  );

  return <PhaseInExplanationAlert drPhaseInExplanationKey={drPhaseInExplanationKey} />;
};
