// Parameters that are locked and cannot be changed by the user
export const LOCKED_INPUT_PARAMETERS = new Set<string>([
  "e1efa38a-e1d1-4371-b99d-fd60121b28d6",
  "eeef169d-02d6-4c05-ab1f-5a6973ece00d",
  "02c311bd-1fcc-451c-83a3-727b65bc8be2",
  "cbc2a374-0438-4b85-b641-089588636cee",
  "d703d811-7d94-4e85-ba2e-7963e1fc53e5",
  "f2bc0456-4ba6-4a43-a51f-12b319296157",
  "ac4421ec-ab66-41ac-abb9-eacc58167904",
  "228a7102-8be4-4370-9608-04cea27c26b5",
  "b2a37f43-6a19-4fac-94c7-8b6254a5a56a",
  "ec694be4-9b6c-4cdd-b572-79f47a3fd7f5",
  "9882b34b-add9-46d6-89cd-71b6c66fb64f",
  "36263f1f-b89f-4e35-b131-b6d4eef89174",
  "faf8a468-81e4-46d1-87b1-0bfb52ea1947",
  "b6695d1c-e6e1-43e0-9297-9ea0c1af185c",
  "575b72da-5726-40cf-be04-dab1988619b3",
  "eed129db-93c0-46bd-a908-cbd53d27b81d",
  "a8cb6a85-a168-4486-8571-bf388e8334eb",
  "cccf8283-a4f0-492a-a826-72d5b84b6452",
  "b2753ccf-8486-4230-abe0-9c1331a35107",
  "37be8c13-e469-4f09-8ef5-c485bfd25bf5",
  "ea465727-b922-4acb-b959-4ac69d17be0e",
  "98e5f4f9-fbb5-436e-944d-4f08d2435538",
  "77748788-5cc5-40c5-9809-98d0fd60d6ee",
  "96aadb88-ac97-4be2-81f5-aa10d753f8ab",
  "c4a1dc42-2e47-4fed-b6fa-2575cef21c71",
  "bf2752d1-ba08-49dc-8b79-d604f6a40bf0",
  "1fc7a630-66b1-416a-afa9-ae3c02d4f9b8",
  "a425e3a9-ae80-4928-b59b-3774e7ebd577",
  "3a67ef4f-0fc6-4d61-9ded-2b64d84ef763",
  "f9771402-f252-46f6-9241-7d897662dfb8",
  "390842eb-66e9-42ac-82ba-7b29e682f617",
  "5bc0d954-c5ab-4689-9a08-97a2de971e00",
  "bc5e53a0-7f7c-4a3a-8fa6-d4fda2a87f15",
  "2d77e7c0-fc22-458d-bd15-75e8bfe6eb9a",
  "2f5b01fc-cef9-4b94-a0ec-9bc6b279711a",
  "ac565a16-c96b-4a76-9a47-8859be1be0f7",
  "76f78252-ad3d-443b-8e12-18fd08dd1de7",
  "afa0bdcd-7e5b-4e98-ae3b-a72928a45fb9",
  "adf92140-4da9-409f-b18d-82754094d856",
  "d715a7b2-e133-4201-8c95-34c7d16498b9",
  "edb48bfa-6a30-4397-b9e3-2c54e7dd69e2",
  "9ebbbfb1-0220-4d00-b1bb-4cc959d7870e",
  "65d6cbec-0f0a-44c0-b342-cc698e5edb01",
  "be3281a2-f62e-483e-8049-c043cedb17a0",
  "b61734a5-6776-4a3e-a44b-49b62ca625bc",
  "633e2d94-1093-4bee-8094-c1fd569e50c1",
  "11d9980a-aaae-434f-bf59-3bc59f689c29",
  "d542a8f0-354c-4d5f-a7d7-418a1c9014e6",
  "e7e0c417-22d1-4aa6-8eee-97f08f485ea3",
  "db97a9e9-605c-467b-ba0e-98e0490d0528",
  "46b98fb4-b1d5-4d91-b5d3-e1e477ea8448",
  "f957e99a-49cd-4fa8-a3cf-84ed33d4524b",
  "4556f55d-397a-4258-a2c5-a5bc9f3350e1",
  "c97e880d-1720-4e36-ad20-a080daccb6d1",
  "b1e87b59-e9d6-48c8-8451-fc7977bd9643",
  "f8ac808d-29e6-426b-975e-775c588ab90f",
  "d5399cbf-b268-4eca-b175-2a84cdcb3041",
  "92ccff15-377e-4b07-acae-0de69e793010",
  "e6e1f4ff-eb1c-4f2d-83eb-e7edcf3cc96c",
  "b403098a-22f2-4f10-b918-32a28e74f92d",
  "b3727b41-df13-4c6b-a4b0-2f87358d4b38",
  "1ea2912a-5586-44c7-bf3d-82431ed34a4c",
  "6864b860-ff09-4d0e-a4e2-fc5fc2bdf4f4",
  "d188b912-c6bf-4e04-add6-2e09c9c38e45",
  "74a54e41-b377-43a8-8fa9-638766d7b919",
  "ede5d0a1-ac1d-40c3-8d35-ddee1d28fc42",
  "d1487357-96a1-45a8-869e-50a2707afe33",
  "79a0e297-a386-411c-8766-4b3c62637bdf",
  "a0e423a2-e5a9-41e1-a102-87484d52906d",
  "f07edf88-2a3a-45dc-a500-fe9040a24c66",
  "6e1f3f3d-01ab-4bb9-977c-c56c6f6e10bd",
  "fd6812c5-f818-47cd-907f-0a81be5f3e50",
  "256c0567-3c8a-4f8d-a178-99eade91e1d2",
  "01d3d11e-8af6-4d5d-ad06-1116dcf04edb",
  "7030d56f-c979-4c45-b96c-2f2c748b6fbc",
  "37ed6f50-44dd-4abd-a01e-2d18160282d1",
  "68450f31-5b4c-438d-b312-ff5728fc9b1e",
  "f8191306-c171-401b-881b-0b3914a0fb91",
  "f0583523-0897-44c5-8c96-ed3593e73f66",
  "c5ee94bf-efa2-4c7b-ae28-92e4738ec674",
  "b5c76005-5683-41b2-9dfb-9faf164afd22",
  "e89d1fb5-35fe-4b35-963a-ce0ff6a55d9f",
  "5bf40324-41b5-42b0-9f20-f0c639982023",
  "a43ae0b6-e0b3-479a-b263-145c1620c409",
  "23461069-3863-46ac-baec-70d3b2f2fbd1",
  "3be50788-77d0-4dda-8efd-c1e805c2a4b2",
  "c870ad63-dc4f-434a-a5bd-efef7c285bb4",
  "d34ec70b-5543-4505-8096-88130c499ede",
  "130f0fa3-74f7-4bd5-88ef-72373194fdc3",
  "12059152-3dec-425b-b65a-fd2065ebbd00",
  "43a8afc4-d5db-4f33-9e60-37d29e65bf09",
  "4ef7219c-a063-47e8-9aa7-69a253597a26",
  "b888de48-e4e9-4d9c-a830-a61316f0f038",
]);
