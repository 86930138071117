import { AutocompleteTextInput, IFlattenedHierarchyEntry } from "./hierarchical-dropdown.component";
import { FC, useMemo } from "react";
import { Autocomplete } from "@mui/material";
import { LineClampTypographyWithTooltip } from "../../../common/components/line-clamp-typography.component";

interface IGroupedSingleDropdownProps {
  label: string;
  flattenedOptions: IFlattenedHierarchyEntry[];
  value: string;
  onChange: (newValue: string | null) => void;
  disabled: boolean;
  emptyOption?: boolean;
}

export const HierarchicalDropdownSingle: FC<IGroupedSingleDropdownProps> = ({
  label,
  emptyOption,
  value,
  onChange,
  flattenedOptions,
  disabled,
}) => {
  const selectedOption = useMemo(
    () => flattenedOptions.find((option) => option.item.value === value),
    [flattenedOptions, value],
  );

  const valueLabelLookup: Record<string, string> = useMemo(() => {
    const lookup: Record<string, string> = {};
    flattenedOptions.forEach((flattenedOption) => {
      lookup[flattenedOption.item.value] = flattenedOption.item.label;
    });
    return lookup;
  }, [flattenedOptions]);

  return (
    <Autocomplete
      autoHighlight
      autoSelect
      disableClearable={!emptyOption}
      value={selectedOption}
      onChange={(_, newValue) => onChange(newValue?.item.value ?? null)}
      options={flattenedOptions}
      getOptionLabel={(option) => valueLabelLookup[option.item.value]}
      renderOption={(props, option) => (
        <li {...props} key={option.item.value} style={{ ...props.style, borderRadius: 8 }}>
          <LineClampTypographyWithTooltip
            fontWeight={option.depth === 0 && option.item.children.length > 0 ? "bold" : "normal"}
          >
            {valueLabelLookup[option.item.value]}
          </LineClampTypographyWithTooltip>
        </li>
      )}
      renderInput={(params) => <AutocompleteTextInput params={params} {...{ label, disabled }} />}
      fullWidth
    />
  );
};
