import { FC, useCallback, useRef } from "react";
import { LexicalRichTextEditor } from "../../lexical/lexical-rich-text-editor.component";
import { LexicalToolbarPlugin } from "../../lexical/plugins/toolbar/lexical-toolbar-plugin.component";
import { useTranslation } from "react-i18next";
import { BoldButton } from "../../lexical/plugins/toolbar/bold-button.component";
import { ItalicButton } from "../../lexical/plugins/toolbar/italic-button.component";
import { UnderlineButton } from "../../lexical/plugins/toolbar/underline-button.component";
import { StrikethroughButton } from "../../lexical/plugins/toolbar/strikethrough-button.component";
import { SaveAndCancelButtons } from "../../lexical/plugins/save-and-cancel-buttons.component";
import { Box } from "@mui/material";
import { SerializedEditorState } from "lexical";
import { SubscribeToContent } from "../../lexical/plugins/subscribe-to-content.component";
import { SaveOnCtrlEnterPlugin } from "../../lexical/plugins/save-on-ctrl-enter-plugin.component";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { UsersMentionsPlugin } from "../../lexical/plugins/users-mentions-plugin.component";

interface ICommentTextEditorProps {
  onSave: (content: SerializedEditorState) => Promise<void>;
  users: IUserRepresentation[];
  content?: SerializedEditorState;
  disabled?: boolean;
}

export const CommentTextEditor: FC<ICommentTextEditorProps> = ({
  onSave,
  users,
  content,
  disabled,
}) => {
  const { t } = useTranslation("comments");

  const resetRef = useRef<VoidFunction>(() => {});

  const handleSave = useCallback(
    async (tree: SerializedEditorState) => {
      await onSave(tree);
      resetRef.current();
    },
    [onSave],
  );

  return (
    <Box>
      <LexicalRichTextEditor
        placeholder={t("text_editor.placeholder")}
        beforeInput={
          <LexicalToolbarPlugin>
            <BoldButton />
            <ItalicButton />
            <UnderlineButton />
            <StrikethroughButton />
          </LexicalToolbarPlugin>
        }
        inInnerEditor={
          <>
            <SubscribeToContent content={content} resetRef={resetRef} />
            <UsersMentionsPlugin users={users} />
          </>
        }
        afterEditor={
          <>
            <SaveAndCancelButtons
              onSave={handleSave}
              onCancel={() => resetRef.current()}
              disabled={disabled}
            />
            <SaveOnCtrlEnterPlugin onSave={handleSave} />
          </>
        }
        disabled={disabled}
      />
    </Box>
  );
};
