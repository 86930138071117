import { Box, IconButton, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  CheckIcon,
  CloseIcon,
  ReloadIcon,
  RemoveIcon,
} from "../../common/constants/tabler-icon.constants";
import { ComponentMode } from "../../common/types/component-mode.type";

interface IDataEntryObjectValueActionsComponentProps {
  // General component settings
  mode: ComponentMode;
  // Component UI
  disabled?: boolean;
  hasError?: boolean;
  hasChanges: boolean;
  // Event handlers
  onCancel?: () => void;
  onReset?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
}

export const DataEntryObjectValueActionsComponent: FC<
  IDataEntryObjectValueActionsComponentProps
> = ({ disabled, onCancel, hasChanges, onReset, mode, onSave, hasError, onDelete }) => {
  const { t } = useTranslation("data_entry_object_value_actions_component");
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {/* Basic actions */}
      {onCancel && (
        <Tooltip title={t("tooltips.cancel")}>
          <span>
            <IconButton disabled={disabled} size="medium" onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!onCancel && hasChanges && (
        <Tooltip title={t("reset")}>
          <span>
            <IconButton disabled={disabled} size="medium" onClick={onReset}>
              <ReloadIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {(hasChanges || mode === "create") && onSave && (
        <Tooltip title={t(`tooltips.${mode}`)}>
          <span>
            <IconButton
              disabled={disabled || hasError}
              size="medium"
              onClick={onSave}
              color="success"
            >
              <CheckIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title={t("tooltips.delete")}>
          <span>
            <IconButton disabled={disabled} size="medium" onClick={onDelete} color="error">
              <RemoveIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </Box>
  );
};
