import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Avatar, Skeleton, Tooltip } from "@mui/material";
import { useSortedOrganizationUsersHook } from "../hooks/sorted-organization-users.hook";
import { NoUserIcon } from "../../common/constants/tabler-icon.constants";
import { UserSelectMenu } from "./user-select-menu.component";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import {
  CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES,
  ClickableIndicatorWhiteOverlay,
} from "../../common/components/clickable-indicator-white-overlay.component";
import { UserUtilities } from "@netcero/netcero-common";

interface IPropsBase {
  /** default is 30 */
  avatarButtonDiameter?: number;
  organizationId: string;
  value: string | null;
  noUsersAvailableText: string;
  disabled?: boolean;
  tooltipNoUserSelected?: string;
}

interface IPropsAllowNull extends IPropsBase {
  noUserOption: true;
  noUserOptionText: string;
  onChange: (userId: string | null) => void;
}

interface IPropsDisallowNull extends IPropsBase {
  noUserOption?: false;
  noUserOptionText?: undefined;
  onChange: (userId: string) => void;
}

type ISingleOrganizationUserAvatarPickerProps = IPropsAllowNull | IPropsDisallowNull;

export const SingleOrganizationUserAvatarPicker: FC<ISingleOrganizationUserAvatarPickerProps> = ({
  avatarButtonDiameter = 30,
  organizationId,
  value,
  noUsersAvailableText,
  noUserOption,
  noUserOptionText,
  onChange,
  disabled,
  tooltipNoUserSelected,
}) => {
  const [sortedUsers, usersQuery] = useSortedOrganizationUsersHook(organizationId);
  const selectedUser = useMemo(
    () => usersQuery.data?.find((user) => user.id === value),
    [value, usersQuery.data],
  );

  const avatarRef = useRef<HTMLDivElement>(null);
  const [usersMenuOpen, setUsersMenuOpen] = useState(false);

  // Show user's full name if one is selected or value of prop tooltipNoUserSelected if no user is selected
  const tooltipTitle = useMemo(() => {
    if (selectedUser) {
      return `${selectedUser.firstName} ${selectedUser.lastName}`;
    }
    return tooltipNoUserSelected;
  }, [selectedUser, tooltipNoUserSelected]);

  const handleSelectItem = useCallback(
    (user: IUserRepresentation | null) => {
      setUsersMenuOpen(false);
      // If user is the same as the current value, do nothing
      if (user === value || user?.id === value) {
        return;
      }
      onChange((user?.id ?? null) as string); // "as" is fine here since typing is ensured by props type
    },
    [value, onChange],
  );

  if (usersQuery.isLoading) {
    return <Skeleton variant="circular" width={40} height={40} />;
  }

  return (
    <>
      {/* Menu */}
      <UserSelectMenu
        show={usersMenuOpen}
        anchorEl={avatarRef.current}
        onClose={() => setUsersMenuOpen(false)}
        users={sortedUsers}
        activeUserId={value}
        noUsersAvailableText={noUsersAvailableText}
        noUserOption={noUserOption as true}
        noUserOptionText={noUserOptionText as string}
        onSelect={handleSelectItem}
      />

      {/* Avatar */}
      <Tooltip title={tooltipTitle}>
        <Avatar
          ref={avatarRef}
          onClick={!disabled ? () => setUsersMenuOpen(true) : undefined}
          sx={{
            ...CLICKABLE_INDICATOR_WHITE_OVERLAY_PARENT_STYLES,
            width: avatarButtonDiameter,
            height: avatarButtonDiameter,
            fontSize: avatarButtonDiameter / 2,
            p: 0.5,
            // opacity: disabled ? 0.5 : undefined,
            backgroundColor: selectedUser ? UserUtilities.getColorByUser(selectedUser) : undefined,
          }}
        >
          {value ? (
            selectedUser ? (
              <>
                {selectedUser.firstName?.[0]}
                {selectedUser.lastName?.[0]}
              </>
            ) : (
              "?"
            )
          ) : (
            <NoUserIcon />
          )}
          {/* On Hover indicator */}
          <ClickableIndicatorWhiteOverlay disabled={disabled} />
        </Avatar>
      </Tooltip>
    </>
  );
};
