import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { getSourcesQueryKey } from "../sources/sources.queries";
import {
  ICreateSourceFilesRequest,
  ISignalSuccessfulFileUploadRequest,
} from "@netcero/netcero-core-api-client";
import axios, { AxiosError } from "axios";
import { ITranslatableError } from "../common/hooks/translate-error.hook";

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = null;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

interface ISourceRequestData {
  organizationId: string;
  id: string;
  payload: ICreateSourceFilesRequest;
  files?: FileList;
}

interface ISourceSignalSuccessData {
  organizationId: string;
  id: string;
  payload: ISignalSuccessfulFileUploadRequest;
}

export const useCreateSourceFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      organizationId,
      id,
      payload,
      files: fileListToUpload,
    }: ISourceRequestData) => {
      const sourceFileCreation = await CoreApiService.SourceFilesApi.createSourceFiles(
        organizationId,
        id,
        payload,
      )
        .then((req) => req())
        .then((res) => res.data);

      // init ids to delete
      const failedFileIds = [];
      const caughtErrors: ITranslatableError[] = [];

      for (const [index, file] of sourceFileCreation.createdFiles.entries()) {
        if (!fileListToUpload?.[index]) {
          continue;
        }

        try {
          const formData = new FormData();
          for (const [key, value] of Object.entries(file.presignedPost.fields)) {
            if (!value) {
              continue;
            }
            formData.append(key, value);
          }
          formData.append("Content-Type", fileListToUpload[index].type);
          formData.append("file", fileListToUpload[index]);

          await axiosInstance.post(file.presignedPost.url, formData, {
            headers: {
              Authorization: null,
            },
          });

          await CoreApiService.SourceFilesApi.signalSuccessfulFileUpload(organizationId, id, {
            fileIds: [file.id],
          })
            .then((req) => req())
            .then((res) => res.data);
        } catch (error) {
          // add failed ids to delete
          failedFileIds.push(file.id);

          if (error instanceof AxiosError && error.response?.data) {
            // Errors from S3 are always in XML format -> parse the ErrorCode
            const parser = new DOMParser();
            const parsedError = parser.parseFromString(error?.response?.data, "text/xml");
            const errorCode = parsedError.querySelector("Code")?.textContent;

            if (!errorCode) {
              continue;
            }
            caughtErrors.push({
              errorCode,
            });
          }
        }
      }

      if (failedFileIds.length > 0) {
        // send all failed ids to delete
        await CoreApiService.SourceFilesApi.deleteSourceFiles(organizationId, id, {
          fileIds: failedFileIds,
        })
          .then((req) => req())
          .then((res) => res.data);

        // It's fine to throw only the first error
        throw caughtErrors[0];
      }

      return sourceFileCreation;
    },
    onSettled: (data, error, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};

export const useDeleteSourceFileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ organizationId, id, payload }: ISourceSignalSuccessData) => {
      return CoreApiService.SourceFilesApi.deleteSourceFiles(organizationId, id, payload)
        .then((req) => req())
        .then((res) => res.data);
    },
    onSuccess: (data, variables) =>
      queryClient.invalidateQueries({
        queryKey: getSourcesQueryKey(variables.organizationId),
      }),
  });
};
