import { useMemo } from "react";
import { AuthenticationUtilities } from "@netcero/netcero-common";
import { useUserContext } from "../user.context";

export const useKeycloakOrganizationData = (organizationId: string) => {
  const { user } = useUserContext();

  return useMemo(() => {
    if (!user) {
      return null;
    }

    return AuthenticationUtilities.findOrganizationDataForNetCeroOrganizationId(
      user.jwtPayload,
      organizationId,
    );
  }, [organizationId, user]);
};
