import { Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "../../common/dialogs/variants/confirm.dialog";

interface IExecuteDMAConfirmDialogProps {
  open: boolean;
  loading?: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (confirm: boolean) => void;
}

export const ExecuteDMAConfirmDialog: FC<IExecuteDMAConfirmDialogProps> = ({
  open,
  loading,
  error,
  disabled,
  onClose,
}) => {
  const { t } = useTranslation("execute_dma_confirm_dialog");

  return (
    <ConfirmDialog
      open={open}
      onClose={(confirm) => onClose(confirm)}
      loading={loading}
      error={error}
      disabled={disabled}
      title={t("title")}
      content={
        <>
          <Typography whiteSpace="pre-line">{t("content_text")}</Typography>
          <Typography mt={4} fontWeight="bold">
            {t("notice_long_duration")}
          </Typography>
        </>
      }
      confirmOverride={t("button_confirm")}
    />
  );
};
