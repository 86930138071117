import { useMemo } from "react";
import { useUserJwt } from "./user-jwt.hook";
import { KEYCLOAK_REALM, KEYCLOAK_URL } from "../../../keycloak";
import { KeycloakUserApiClient } from "@netcero/keycloak-common";

/**
 * Hook to get the KeycloakUserApiClient.
 * WARNING: You probably do NOT want to use the API client directly but pass it along to actions in the keycloak-common library.
 * @throws error This hook will throw an error if the token is null. (not authenticated/no user context)
 */
export const useKeycloakUserApiClient = () => {
  const token = useUserJwt();

  return useMemo(() => {
    // Throw an error if the token is null
    if (token === null) {
      return null;
    }
    // Else initialize the KeycloakUserApiClient
    return new KeycloakUserApiClient({
      baseUrl: KEYCLOAK_URL,
      realm: KEYCLOAK_REALM,
      token,
    });
  }, [token]);
};
