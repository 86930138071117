import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { IRecordingModelTemplatesGroupsResponse } from "@netcero/netcero-core-api-client";

export function useRecordingModelTemplateGroups<T = IRecordingModelTemplatesGroupsResponse>(
  organizationId: string,
  recordingModelId: string | undefined,
  select?: (data: IRecordingModelTemplatesGroupsResponse) => T,
) {
  return useQuery({
    queryKey: ["templateGroups", organizationId, recordingModelId],
    queryFn: () =>
      CoreApiService.RecordingModelTemplateGroupApi.getAllTemplateGroups(
        organizationId,
        recordingModelId!,
      )
        .then((req) => req())
        .then((res) => res.data),
    enabled: !!recordingModelId,
    select,
  });
}
