import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { Box, Divider, SvgIcon, Typography } from "@mui/material";
import { useCommentsQuery } from "../comments.queries";
import {
  ICommentLinkMetadata,
  ICommentRelations,
  ISortOrder,
} from "@netcero/netcero-core-api-client";
import { QueryWrapper } from "../../common/components/query-wrapper.component";
import { useTranslation } from "react-i18next";
import { CommentEditorComponent } from "./create-new-comment.component";
import { useCreateCommentMutation, useDeleteCommentMutation } from "../comments.mutations";
import { SerializedEditorState } from "lexical";
import { Comment } from "./comment.component";
import { ILocalComment } from "../comments.types";
import { useUserContext } from "../../user/user.context";
import { ConfirmDialogTextBody } from "../../common/dialogs/variants/confirm.dialog";
import { useDialogState } from "../../common/dialogs/dialog-state.hook";
import { EmptyCommentsIcon } from "../../common/constants/tabler-icon.constants";
import { useSearchParams } from "react-router-dom";
import { VIEWED_COMMENT_QUERY_PARAM } from "@netcero/netcero-common";

interface ICommentsListProps {
  organizationId: string;
  relations: ICommentRelations;
  linkMetadata: ICommentLinkMetadata;
  order?: ISortOrder;
}

export const CommentsList: FC<ICommentsListProps> = (props) => {
  const commentsQuery = useCommentsQuery(props.organizationId, props.relations, ISortOrder.Asc);

  return (
    <QueryWrapper
      query={commentsQuery}
      centerLoader
      build={(commentsResponse) => (
        <CommentsListInternal comments={commentsResponse.comments} {...props} />
      )}
    />
  );
};

interface ICommentsListInternalProps extends ICommentsListProps {
  comments: ILocalComment[];
}

const CommentsListInternal: FC<ICommentsListInternalProps> = ({
  organizationId,
  comments,
  relations,
  linkMetadata,
  order,
}) => {
  const { user } = useUserContext();

  const [searchParams] = useSearchParams();
  const viewedCommentId = useMemo(
    () => searchParams.get(VIEWED_COMMENT_QUERY_PARAM),
    [searchParams],
  );

  const { t } = useTranslation("comments");
  const orderedComments = useMemo(
    () => (order === ISortOrder.Desc ? [...comments].reverse() : comments),
    [comments, order],
  );

  const scrollToLatestComment = useCallback((smooth = false) => {
    if (commentListContainerRef.current) {
      commentListContainerRef.current.scrollTo({
        top: commentListContainerRef.current.scrollHeight,
        behavior: smooth ? "smooth" : undefined,
      });
    }
  }, []);

  const commentListContainerRef = useRef<HTMLDivElement>();

  // Initially scroll to the bottom of the comments list or to the focused comment
  useEffect(() => {
    if (viewedCommentId) {
      const commentElement = document.getElementById(viewedCommentId);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      scrollToLatestComment();
    }
  }, [scrollToLatestComment, viewedCommentId]);

  const createCommentMutation = useCreateCommentMutation();

  const handleCreateComment = useCallback(
    async (content: SerializedEditorState) => {
      await createCommentMutation.mutateAsync({
        organizationId,
        relations,
        linkMetadata,
        payload: {
          content,
        },
      });
      // Scroll to the bottom of the comments list after creating a new comment
      scrollToLatestComment(true);
    },
    [createCommentMutation, linkMetadata, organizationId, relations, scrollToLatestComment],
  );

  const deleteCommentMutation = useDeleteCommentMutation();
  const {
    isOpen: showConfirmDeleteDialog,
    openDialog: openConfirmDeleteDialog,
    closeDialog: closeConfirmDeleteDialog,
    data: commentToDelete,
  } = useDialogState<ILocalComment>();

  const handleConfirmDeleteComment = useCallback(
    async (confirm: boolean) => {
      if (confirm) {
        if (commentToDelete) {
          await deleteCommentMutation.mutateAsync({
            organizationId,
            relations,
            commentId: commentToDelete.id,
          });
        }
      }
      // Always close the dialog
      closeConfirmDeleteDialog();
    },
    [closeConfirmDeleteDialog, commentToDelete, deleteCommentMutation, organizationId, relations],
  );

  return (
    <>
      {/* Confirm Delete Dialog */}
      <ConfirmDialogTextBody
        loading={deleteCommentMutation.isPending}
        error={deleteCommentMutation.isError ? deleteCommentMutation.error : undefined}
        disabled={deleteCommentMutation.isPending}
        open={showConfirmDeleteDialog}
        title={t("delete_dialog.title")}
        content={t("delete_dialog.content")}
        onClose={handleConfirmDeleteComment}
      />

      {/* Content */}
      <Box flex={1} display="flex" flexDirection="column" sx={{ overflowY: "auto" }}>
        {/* Empty Message */}
        {comments.length === 0 ? (
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={0.5}
            pb="30%"
          >
            <SvgIcon component={EmptyCommentsIcon} sx={{ fill: "transparent", mb: 1 }} />
            <Typography textAlign="center" fontWeight="bold">
              {t("notice_no_comments.first")}
            </Typography>
            <Typography variant="body2" textAlign="center">
              {t("notice_no_comments.second")}
            </Typography>
          </Box>
        ) : (
          /* List Comments */
          <Box ref={commentListContainerRef} flex={1} py={2} sx={{ overflowY: "auto" }}>
            <Box display="flex" flexDirection="column" gap={2} px={1}>
              {orderedComments.map((comment) => (
                <Comment
                  key={comment.id}
                  organizationId={organizationId}
                  comment={comment}
                  isFocused={viewedCommentId === comment.id}
                  onDelete={
                    user?.userProfile.id === comment.createdByUserId
                      ? openConfirmDeleteDialog
                      : undefined
                  }
                />
              ))}
            </Box>
          </Box>
        )}
        {/* New Comment Input Areas */}
        <Divider />
        <Box px={1} pb={1} pt={1.5}>
          <CommentEditorComponent
            organizationId={organizationId}
            onSave={handleCreateComment}
            disabled={createCommentMutation.isPending}
          />
        </Box>
      </Box>
    </>
  );
};
