import {
  IBaseDistributionCriterionData,
  IDistributionCriterion,
  IGetAllDistributionCriteriaResponse,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { DistributionCriteriaEditList } from "./distribution-criteria-edit-list.component";
import {
  useCreateDistributionCriterionMutation,
  useDeleteDistributionCriterionMutation,
  useUpdateDistributionCriterionMutation,
} from "./distribution-criteria.mutations";
import { useDialogState, useDialogStateWithoutData } from "../common/dialogs/dialog-state.hook";
import { DistributionCriterionEditDialog } from "./distribution-criteria-edit.dialog";
import { ConfirmDialogTextBody } from "../common/dialogs/variants/confirm.dialog";

interface IDistributionCriteriaConfigurationProps {
  organizationId: string;
  distributionCriteriaResponse: IGetAllDistributionCriteriaResponse;
}

export const DistributionCriteriaConfiguration: FC<IDistributionCriteriaConfigurationProps> = ({
  organizationId,
  distributionCriteriaResponse,
}) => {
  const { t } = useTranslation("distribution_criteria_configuration");

  const distributionCriteria = distributionCriteriaResponse.criteria;

  // Create DC Dialog

  const createDistributionCriterionMutation = useCreateDistributionCriterionMutation();

  const {
    isOpen: showCreateDialog,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();

  const handleCreateNewDistributionCriterion = () => {
    createDistributionCriterionMutation.reset();
    openCreateDialog();
  };

  const handleCloseCreateDistributionCriterionDialog = async (
    data: IBaseDistributionCriterionData | null,
  ) => {
    if (data) {
      await createDistributionCriterionMutation.mutateAsync({
        organizationId,
        payload: data,
      });
    }

    closeCreateDialog();
  };

  // Update DistributionCriterion Dialog
  const updateDistributionCriterionMutation = useUpdateDistributionCriterionMutation();

  const {
    isOpen: isUpdateDialogOpen,
    openDialog: openUpdateDialog,
    closeDialog: closeUpdateDialog,
    data: updateDialogData,
  } = useDialogState<{ distributionCriterion: IDistributionCriterion }>();

  const handleEditDistributionCriterion = useCallback(
    (stakeholder: IDistributionCriterion) => {
      updateDistributionCriterionMutation.reset();
      openUpdateDialog({ distributionCriterion: stakeholder });
    },
    [openUpdateDialog, updateDistributionCriterionMutation],
  );

  const handleCloseEditDistributionCriterionDialog = useCallback(
    async (data: IBaseDistributionCriterionData | null) => {
      if (data !== null && updateDialogData) {
        await updateDistributionCriterionMutation.mutateAsync({
          organizationId,
          distributionCriterionId: updateDialogData.distributionCriterion.id,
          payload: data,
        });
      }
      closeUpdateDialog();
    },
    [closeUpdateDialog, organizationId, updateDialogData, updateDistributionCriterionMutation],
  );

  // Delete DistributionCriterion Dialog
  const deleteDistributionCriterionMutation = useDeleteDistributionCriterionMutation();

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
    data,
  } = useDialogState<{ distributionCriterionId: string }>();

  const handleDeleteDistributionCriterion = useCallback(
    (distributionCriterion: IDistributionCriterion) => {
      // reset errors in case there were previous errors
      deleteDistributionCriterionMutation.reset();
      openDeleteDialog({ distributionCriterionId: distributionCriterion.id });
    },
    [openDeleteDialog, deleteDistributionCriterionMutation],
  );

  const handleCloseDeleteDistributionCriterionDialog = useCallback(
    async (confirm: boolean) => {
      if (confirm && data?.distributionCriterionId) {
        await deleteDistributionCriterionMutation.mutateAsync({
          organizationId,
          distributionCriterionId: data.distributionCriterionId,
        });
      }
      closeDeleteDialog();
    },
    [data, closeDeleteDialog, deleteDistributionCriterionMutation, organizationId],
  );

  return (
    <>
      {/* Create Dialog */}
      <DistributionCriterionEditDialog
        open={showCreateDialog}
        loading={createDistributionCriterionMutation.isPending}
        error={
          createDistributionCriterionMutation.isError
            ? createDistributionCriterionMutation.error
            : null
        }
        disabled={createDistributionCriterionMutation.isPending}
        onClose={handleCloseCreateDistributionCriterionDialog}
      />

      {/* Update Dialog */}
      <DistributionCriterionEditDialog
        open={isUpdateDialogOpen}
        loading={updateDistributionCriterionMutation.isPending}
        error={
          updateDistributionCriterionMutation.isError
            ? updateDistributionCriterionMutation.error
            : null
        }
        disabled={updateDistributionCriterionMutation.isPending}
        onClose={handleCloseEditDistributionCriterionDialog}
        distributionCriterion={updateDialogData?.distributionCriterion}
      />

      {/* Delete Dialog */}
      <ConfirmDialogTextBody
        loading={deleteDistributionCriterionMutation.isPending}
        disabled={deleteDistributionCriterionMutation.isPending}
        error={
          deleteDistributionCriterionMutation.isError
            ? deleteDistributionCriterionMutation.error
            : null
        }
        open={isDeleteDialogOpen}
        title={t("delete_dialog_title")}
        content={t("delete_dialog_body")}
        onClose={handleCloseDeleteDistributionCriterionDialog}
      />

      {/* Content */}
      <Box maxWidth={1600} mx="auto">
        <Box display="flex" gap={1} mb={2}>
          <Typography variant="h1">{t("title")}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          {/* Create Button */}
          <Button
            variant="contained"
            onClick={handleCreateNewDistributionCriterion}
            sx={{ mr: "auto" }}
          >
            {t("button_create")}
          </Button>
          {/* Distribution Criteria Table / Empty Notice */}
          {distributionCriteria.length < 1 ? (
            <Typography>{t("empty_notice")}</Typography>
          ) : (
            <DistributionCriteriaEditList
              distributionCriteria={distributionCriteria}
              onDelete={handleDeleteDistributionCriterion}
              onEdit={handleEditDistributionCriterion}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
